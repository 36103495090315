/*
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-07-26 13:54:33
 * @LastEditors: hechen
 * @LastEditTime: 2023-09-07 17:40:55
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\api\axios.js
 * @Descripttion: 
 */
import axios from 'axios';
import router from '../router/index.js';
import Vue from 'vue'
import {
    clearAllStore
} from '@/utils/store';
import store from '../store/index';

// 默认超时设置
axios.defaults.timeout = 10000000;

// http request 拦截器
axios.interceptors.request.use(
    (config) => {
        // 鉴权参数设置
        if (config.method === "get") {
            //get请求下 参数在params中，其他请求在data中
            config.params = config.params || {};
            let json = JSON.parse(JSON.stringify(config.params));
            //一些参数处理
        } else {
            config.data = config.data || {};
            //一些参数处理
        }
        return config;
    },
    (err) => {
        return Promise.reject(err);
    }
);

//http response 拦截器
axios.interceptors.response.use(
    (response) => {
        if (response.data.code == 1) {
            Vue.prototype.$message({
                type: "error",
                message: `${response.data.msg}`
            });
        }
        return response;
    },
    (error) => {
        if (error && error.response && error.response.status == 424) {
            Vue.prototype.$message({
                type: "warning",
                message: "登录信息过期，请重新登录"
            });
            store.commit("DEL_ALL_TAG");
            clearAllStore()
            router.push({
                path: "/login",
            });
        } else {
            Vue.prototype.$message({
                type: "error",
                message: error.response.data.msg
            });
        }
        return Promise.reject(error);
    }
);

export default axios