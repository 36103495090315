<template>
  <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNumberDefault"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="pageSizeDefault"
        layout="slot, sizes, prev, pager, next, jumper"
        :total="total"
      >
      <span class="page-text">显示第 1 到第 {{pageSize}} 条记录，总共 {{total}} 条记录</span>
  </el-pagination>
</template>

<script>
export default {
    name: 'dd-pagination',
    props: {
        total: {
            type: Number,
            default: 0
        },
        pageSize: {
            type: Number,
            default: 10
        },
        pageNumber: {
            type: Number,
            default: 1
        },
    },
    data() {
        return {
            pageSizeDefault: this.pageSize,
            pageNumberDefault: this.pageNumber
        }
    },
    watch: {
        pageSize(n,o) {
            this.pageSizeDefault = n;
        },
        pageNumber(n,o) {
            this.pageNumberDefault = n;
        }
    },
    methods: {
        handleSizeChange(val) {
            this.pageSizeDefault = val;
            this.$emit('page-change',this.pageSizeDefault,this.pageNumberDefault);
        },
        handleCurrentChange(val) {
            this.pageNumberDefault = val;
            this.$emit('page-change',this.pageSizeDefault,this.pageNumberDefault);
        }
    }
}
</script>

<style lang="less" scoped>
.page-text{
    color: #606266;
    font-weight: initial;
}
</style>