<template>
  <div>
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="search"
      ></dd-search-form>
    </dd-card>
    <dd-card title="申请单列表">
      <dd-table
        :topBtn="false"
        :columns="tableColumns"
        :data="tableDataSources"
        height="300"
        border
        show-summary
        :summary-method="getSummaries"
        show-page
        @select-change="handleSelectionChange"
        ref="test"
        :tableRef="tableRef"
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="pageSize"
            :page-number="pageNumber"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 申请单详情弹窗 -->
    <dd-popup
      inPopup
      width="70%"
      :show="diaexpenseDetail"
      title-name="申请单详情"
      @popup-close="diaexpenseDetail = false"
    >
      <detail-pop
        v-if="diaexpenseDetail"
        slot="main"
        ref="detailPop"
        :expenseApply="expenseApply"
      ></detail-pop>
    </dd-popup>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import detailPop from "../../ExpenseApply/components/expenseDetail.vue";
export default {
  name: "expense-apply",
  components: {
    detailPop,
  },
  props: {
    filterSelection: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      tableRef: "expleApplyPop",
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      // 表格选择数据集合
      multipleSelection: [],
      formData: {},
      formList: [
        {
          label: "单号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入单号",
        },
        {
          label: "项目",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
          title: true,
        },
        {
          label: "费用类型",
          value: "expenseTypeId",
          inputType: "cascader",
          optionsList: [],
          prop: {},
          placeholderText: "请选择费用类型",
        },
        {
          label: "时间段",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
        },
      ],
      tableColumns: [
        {
          title: "申请单号",
          key: "code",
          width: 200,
          align: "center",
          href: [
            {
              method: (row) => {
                this.handleRowHref(row);
              },
            },
          ],
        },
        {
          title: "申请日期",
          key: "applyTime",
          align: "center",
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          overflow: true,
        },
        {
          title: "项目编号",
          key: "prjCode",
          align: "center",
          overflow: true,
        },
        {
          title: "费用类型",
          key: "extenseTypeName",
          align: "center",
        },
        {
          title: "金额(元",
          key: "amount",
          align: "center",
        },
        {
          title: "申请人",
          key: "applier",
          align: "center",
        },
        {
          title: "费用承担部门",
          key: "expenseBearDepName",
          align: "center",
        },
      ],
      tableDataSources: [],
      //多选集合
      multipleSelectionId: [],
      //默认选择回显
      toggleSelectionData: [],
      //费用类型cascader级联选择器配置项
      expenseProp: {
        value: "id",
        label: "name",
        checkStrictly: true,
      },
      //默认选择状态
      filterSelection: [],
      loading: true,
      //详情查看显隐
      diaexpenseDetail: false,
      //子传父值（详情id，id）
      expenseApply: {},
      prop: {
        value: "id",
        label: "name",
      }, //费用类型cascader级联选择器配置项
      depList: [], //部门数据
      expenseType: [], //费用类型（一、二、三级）
    };
  },
  mounted() {
    this.getExpenseType();
    this.getTableDataList();
    //获取项目信息
    this.getProject();
  },
  methods: {
    handleRowHref(row) {
      this.expenseApply = {
        id: row.id,
      };
      this.diaexpenseDetail = true;
    },
    //默认选择回显
    toggleSelection(rows) {
      let { expleApplyPop } = this.$refs.test.$refs;
      // expleApplyPop.clearSelection();
      if (rows) {
        rows.forEach((row) => {
          expleApplyPop.toggleRowSelection(row);
        });
      }
    },
    // 弹框关闭事件
    popupClose() {
      this.$emit("popup-close", false);
    },
    handleSubmit() {},
    //查询
    handleSearchEvent(obj) {
      if (obj.expenseTypeId) {
        if (Array.isArray(obj.expenseTypeId)) {
          let num = Number(obj.expenseTypeId.length) - 1;
          obj.expenseTypeId = obj.expenseTypeId[num];
        } else {
          obj.expenseTypeId = obj.expenseTypeId;
        }
      }
      this.formData = obj;
      this.getTableDataList();
      this.pageNumber = 1;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.multipleSelectionId = val.id;
      // this.$refs.expleApplyPop.model = this.multipleSelectionId;
    },
    //获取默认选择状态回显数据
    getToggleSelection(data) {
      if (data) {
        this.toggleSelectionData = this.tableDataSources.filter((item1) =>
          data.find((item2) => item2.id === item1.id)
        );
        this.toggleSelection(this.toggleSelectionData);
      }
    },
    //初始化数据
    getTableDataList() {
      let current = this.pageNumber;
      let size = this.pageSize;
      postApi(
        `/finance/expenseApply/list/unselected?current=` +
          current +
          `&size=` +
          size,
        this.formData
      ).then((res) => {
        if (res.data.code == 0 && res.data.data) {
          if (res.data.data.records.length > 0) {
            res.data.data.records.forEach((item) => {
              item.amount = parseFloat(item.amount).toFixed(2);
            });
          }
          this.total = res.data.data.total;
          this.tableDataSources = res.data.data.records;
          this.getToggleSelection(this.filterSelection);
          this.loading = false;
        }
      });
    },
    // 计算表格合计行函数
    getSummaries(param) {
      this.$nextTick(() => {
        let { expleApplyPop } = this.$refs.test.$refs;
        expleApplyPop.doLayout();
      });
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        // 初始化合计行
        sums[index] = 0;
        // 递归求和
        this.getSum(data, column.property, sums, index);
        // 如果求和得到的结果是非数字,则显示--
        if (isNaN(sums[index])) {
          sums[index] = "";
        }
        if (index === 5) {
          sums[index] = parseFloat(sums[index]).toFixed(2);
        }
      });
      return sums;
    },
    getSum(tree, idx, sums, index) {
      if (tree && tree.length > 0) {
        tree.map((item) => {
          this.getSum(item.children, idx, sums, index);
          sums[index] += Number(item[idx]);
        });
      }
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} pageSize 每页显示条数
     * @param {*} pageNumber 第几页
     */
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.loading = true;
      this.getTableDataList();
    },
    getExpenseType() {
      //获取费用类型（一、二、三级树）
      getApi(`/finance/expense/getThFinType`)
        .then((res) => {
          this.formList.forEach((item) => {
            if (item.inputType == "cascader") {
              item.optionsList = res.data.data;
              item.prop = this.expenseProp;
            }
          });
        })
        .catch(() => {
          this.$message.error("请求出错");
        });
    },
    //获取项目信息
    getProject() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.projectCodeOptions = {
            value: "projectId",
            optionsList: data.data,
          }
          this.setOPtionsList(this.formList, this.options);
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
  },
};
</script>

<style>
.popup-spacer {
  width: 100%;
  height: 50px;
}
</style>