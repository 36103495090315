<template>
    <div class="main-container">
        <div class="head_wrap">
            <dd-card title="条件搜索" spacer>
                <dd-search-form :form-config="formList" @search-click="handleSearchEvent"
                    ref="ddSearchForm"></dd-search-form>
                    <dd-button class="back-btn" type="warning" size="medium" @click="handleBackPage">返回</dd-button>
            </dd-card>
        </div>
        <dd-card title="权限列表">
            <dd-table :columns="tableColumns" :data="tableData" max-height="530" isSort border :isSelection="false"
                 show-page>
                <template #table-btn>
                    <dd-button type="primary" size="medium" icon="add" @click="handleAdd">新增</dd-button>
                </template>
                <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handleCurrentChange" align="right">
                    </dd-pagination>
                </template>
            </dd-table>
        </dd-card>
        <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
            :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
            @handleColumnsChange="handleColumnsChange">
        </dd-filter-drawer>
        <!-- 新增页面弹窗 -->
        <dd-popup
            width="50%"
            height="45%"
            showBtn
            :saveBtn="true"
            :cancelBtn="true"
            :show="addPopupVisible"
            :title-name="poptitleName"
            @popup-close="handleClose"
            @popup-save="handleSave('addPopForm','baseForm')"
        >
            <add-child-pop v-if="addPopupVisible" slot="main" ref="addPop" :dictKey="dictKey" :dictType="dictType" :rowData="rowData"></add-child-pop>
        </dd-popup>
    </div>
</template>
    
<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
// 新增页面弹窗
import addChildPop from "./AddChildPop.vue";
export default {
    props: {
        rowId: {
            type: String,
            default: null,
        },
        dictKey: {
            type: String,
            default: null, 
        }
    },
    components: {
        addChildPop,
    },
    computed: {
    },
    data() {
        return {
            drawerVisible: false,
            formData: {},
            formList: [{
                    label: "权限名称",
                    value: "scopeName",
                    inputType: "text",
                    placeholderText: "请输入权限名称",
                },
                {
                    label: "权限编号",
                    value: "resourceCode",
                    inputType: "text",
                    placeholderText: "请输入权限编号",
                }
            ],
            tableColumns: [
                {
                    title: "权限名称",
                    key: "scopeName",
                    align: "center",
                },
                {
                    title: "权限编号",
                    key: "resourceCode",
                    align: "center",
                },
                {
                    title: "权限字段",
                    key: "scopeColumn",
                    align: "center",
                },
                {
                    title: "规则类型",
                    key: "scopeType",
                    align: "center",
                    formatter: (row) => {
                        return this.getState('dictType', row);
                    },
                },
                {
                    title: "可见字段",
                    key: "scopeField",
                    align: "center",
                },
                {
                    title: "权限类名",
                    key: "scopeClass",
                    align: "center",
                },
                {
                    title: "规则值",
                    key: "scopeValue",
                    align: "center",
                },
                {
                    title: "备注信息",
                    key: "remark",
                    align: "center",
                },
                {
                    title: "操作",
                    key: "handle",
                    align: "center",
                    event: [
                        {
                            show: "true",
                            label: "编辑",
                            icon: "el-icon-edit",
                            method: (row) => {
                                this.handleRowEditor(row);
                            },
                            type: "primary"
                        },
                        {
                            show: "true",
                            label: "删除",
                            icon: "el-icon-delete",
                            method: (row) => {
                                this.handleRowDel(row);
                            },
                            type: "danger",
                        },
                    ],
                },
            ],
            tableData: [],
            current: 1,
            size: 20,
            total: null,
            dictType: [],
            addPopupVisible: false,
            rowData: null,
            poptitleName: '新增'
        };
    },
    created() { },
    mounted() {
        this.getDataType();
        this.getTableData();
    },
    methods: {
        handleBackPage() {
           this.$emit('pageBack');
        },
        //条件查询表单--下拉选择数据填充（optionsList）
        setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.optionsList = options.optionsList;
                    }
                }
            });
        },
        // 获取数据字典类型
        getDataType() {
            getApi(`/admin/dict/key/type-scope`).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    this.dictType = data.data;
                }
            });
        },
        // 获取表格列表数据
        getTableData() {
            let { formData, current, size } = this;
            let params = {
                ...formData,
                current,
                size,
                menuId: this.rowId
            };
            getApi(`/admin/sysscopedata/page`, params).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    this.tableData = data.data.records;
                    this.total = data.data.total;
                }
            });
        },
        // 新增按钮事件
        handleAdd() {
            this.addPopupVisible = true;
            this.poptitleName = '新增';
        },
        // 新增弹框关闭事件
        handleClose() {
            this.rowData = null;
            this.addPopupVisible = false;
        },
        // 表格行内编辑事件
        handleRowEditor(row) {
            this.addPopupVisible = true;
            this.rowData = row;
            this.poptitleName = '编辑';
        },
        //列表单行删除
        handleRowDel(row) {
            this.$confirm(`确认删除该数据吗？`, "提示", {
                confirmButtonClass: "btnsearch",
                confirmButtonText: "确认",
                cancelButtonClass: "btnreset",
                cancelButtonText: "取消",
                showClose: true,
                type: "warning",
            }).then(() => {
                this.handleConfirmDelete(row.id);
            });
        },
        // 确认删除请求
        handleConfirmDelete(delId) {
            delApi(`/admin/sysscopedata/${delId}`).then((res) => {
                let { data } = res;
                if (data.code == 0) {
                    this.$message.success("删除成功");
                }
                this.getTableData();
            });
        },
        // 格式化类型
        getState(key, row) {
            let label = "";
            this[key].forEach(item => {
                if (item.value == row) {
                    label = item.label
                }
            })
            return label;
        },
        /* 搜索栏 */
        handleSearchEvent(obj) {
            this.formData = obj;
            this.current = 1;
            this.getTableData();
        },
        /* 分页页码回调 */
        handleCurrentChange(size, number) {
            this.current = number;
            this.size = size;
            this.getTableData();
        },
        // 新增确认事件
        handleSave(formName,formNameBase) {
            let flag = false;
            this.$refs.addPop.$refs[formName].$refs[formNameBase].validate((valid) => {
                if (valid) {
                    flag = true;
                }
            });
            let params = this.$refs.addPop.$refs[formName].formData;
            params.menuId = this.rowId;
            if(flag) {
                if(this.rowData != null) {
                    params.id = this.rowData.id;
                    putApi(`/admin/sysscopedata`,params).then(res => {
                        let { data } = res;
                        if(data.code == 0) {
                            this.$message.success("修改成功");
                            this.handleClose();
                            this.getTableData();
                        }
                    })
                }else {
                    postApi(`/admin/sysscopedata`,params).then(res => {
                        let { data } = res;
                        if(data.code == 0) {
                            this.$message.success("保存成功");
                            this.handleClose();
                            this.getTableData();
                        }
                    })
                }
            }
        }
    },
};
</script>
    
<style lang='less' scoped>
</style>