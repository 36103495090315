<!--
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-08-21 15:30:45
 * @LastEditors: hechen
 * @LastEditTime: 2023-08-21 15:58:09
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \sy-nature-web\src\App.vue
 * @Descripttion: 
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
  },
  methods: {
  
  },
};
</script>

<style lang="less">
#app {
  width: @width;
  height: @height;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>>
