<template>
  <div class="main-container">
    <dd-card title="任务分解管理列表" height="100%">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        border
        show-page
        :isSelection="false"
        :loading="loading"
      >
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { getApi, delApi, postApi, postDownBlobApi } from "@/api/request";
export default {
  props: {
    rowId: {
      type: String,
      default: "",
    },
    resolveType: {
      type: String,
      default: "",
    },
  },
  components: {},
  computed: {},
  data() {
    return {
      loading: true,
      tableColumns: [
        {
          title: "任务分解编号",
          key: "subplanCode",
          align: "center",
          width: 200,
        },
        {
          title: "根节点名称",
          key: "subplanName",
          align: "center",
        },
        {
          title: "根节点节点状态",
          key: "produceStatusName",
          align: "center",
        },
        {
          title: "根节点生产负责部门",
          key: "receiveDeptName",
          align: "center",
        },
        {
          title: "根节点负责人",
          key: "leader",
          align: "center",
        },
        {
          title: "版本号",
          key: "subplanVision",
          align: "center",
        },
        {
          title: "审核状态",
          key: "verifyStatusName",
          align: "center",
        },
        {
          title: "审核节点",
          key: "taskFlag",
          align: "center",
        },
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
        },
        {
          title: "创建人",
          key: "createName",
          align: "center",
        },
        {
          title: "修改时间",
          key: "updateTime",
          align: "center",
        },
        {
          title: "最后修改人",
          key: "updateName",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          fixed: "right",
          width: 180,
          event: [
            {
              label: "导出",
              type: "primary",
              icon: "el-icon-download",
              method: (row) => {
                this.handleRowExport(row);
              },
            },
            {
              label: "选择",
              type: "primary",
              icon: "el-icon-check",
              method: (row) => {
                this.handleRowChangeVersion(row);
              },
            },
            {
              label: "删除",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
              icon: "el-icon-delete",
              rules: (row) => {
                let show = false;
                if (row.subplanVision > 1 && row.verifyStatus == "PRJ-RWSHZT-DTJ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      rowData: null,
    };
  },
  created() {},
  mounted() {
    this.getTableData();
  },
  methods: {
    // 获取表格列表数据
    getTableData() {
      let { current, size } = this;
      this.loading = true;
      let url = null;
      if (this.resolveType == "childTask") {
        url = `/project/subplan/sub/page/${this.rowId}/${current}/${size}`;
      } else {
        url = `/project/subplan/page/${this.rowId}/${current}/${size}`;
      }
      postApi(url).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    handleRowChangeVersion(row) {
      this.$emit("versionChange", row.id);
    },
    handleRowExport(row) {
      let params = {
        subplanId: row.id,
      };
      postDownBlobApi(
        `/project/subplannode/subplan/export`,
        params,
        `${row.subplanName}_v${row.subplanVision}`
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
        }
      });
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 删除
    handleRowDel(row){
      if (row.subplanVision <= 1) {
        this.$message.warning("只能删除版本号不等于1的");
        return;
      }
      this.$confirm("确认删除该数据？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.deleteVersionList(row.id);
      });
    },
    // 确认删除请求
    deleteVersionList(id) {
      delApi(`/project/subplan/${id}`).then((res) => {
        let { data } = res;
        this.getTableData();
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.$emit("delChange")
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
