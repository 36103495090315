<template>
  <div>
    <dd-card spacer>
      <dd-form :form-config="formList" ref="baseFoem" :flex="2"></dd-form>
    </dd-card>
    <dd-card title="详细计量内容">
      <dd-form
        class="input"
        ref="formulaForm"
        :flex="2"
        :form-config="formula"
      ></dd-form>
      <dd-table
        max-height="235px"
        :columns="tableColumns"
        :data="tableDataSources"
        border
        :isSelection="false"
      >
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  components: {},
  props: {
    rowData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formList: [
        {
          label: "版本选择",
          value: "versionNum",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择版本",
          optionsListConfig: {
            name: "name",
            code: "value",
          },
          defaultValue: "",
          change: this.getDetailList,
        },
      ],
      formula: [
        {
          label: "计算公式",
          value: "formula",
          inputType: "text",
          placeholderText: "选择版本自动带出",
          defaultValue: "",
          disabled: true,
        },
      ],
      formulaResult: null,
      tableColumns: [
        {
          title: "计量内容",
          key: "workName",
          align: "center",
        },
        {
          title: "计量单位",
          key: "workUnitName",
          align: "center",
        },
        {
          title: "填写类型",
          key: "workMeteringName",
          align: "center",
        },
        {
          title: "代号",
          key: "workSymbol",
          align: "center",
        },
      ],
      tableDataSources: [],
    };
  },
  mounted() {
    this.getDetailData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getDetailData() {
      getApi(`/project/work/history`, { id: this.rowData.id }).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "versionNum",
            optionsList: data.data,
          };
          this.formList[0].defaultValue = data.data[0].value;
          this.getDetailList(data.data[0].value);
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    getDetailList(num) {
      let params = {
        nodeId: this.rowData.id,
        vsersion: num,
      };
      getApi(`/project/work/detail/list`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableDataSources = data.data.details;
          this.formula[0].defaultValue = data.data.formula;
        }
      });
    },
    getDataType() {
      // 字典项
      getApi(`/project/prj/dict/type/list`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let arr = [];
          data.data.forEach((val) => {
            arr.push({
              label: val.name,
              value: val.code,
            });
          });
          this.tableColumns.forEach((item) => {
            if (item.key == "dictCode") {
              item.inputTypeArray.forEach((val) => {
                if (val.inputType == "select") {
                  val.options = arr;
                }
              });
            }
          });
        }
      });
      // 计量单位类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GZBDW" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let arr = [];
            data.data.forEach((val) => {
              arr.push({
                label: val.name,
                value: val.code,
              });
            });
            this.tableColumns.forEach((item) => {
              if (item.key == "workUnit") {
                item.selectInput.options = arr;
              }
            });
          }
        }
      );
      // 填写类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GZBTXLX" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let arr = [];
            data.data.forEach((val) => {
              arr.push({
                label: val.name,
                value: val.code,
              });
            });
            this.tableColumns.forEach((item) => {
              if (item.key == "workMetering") {
                item.selectInput.options = arr;
              }
            });
          }
        }
      );
    },
    //添加行事件
    handleAddRow() {
      let item = {
        workName: "",
        nodeUnit: "",
        workMetering: "",
        workSymbol: "",
      };
      this.tableDataSources.push(item);
    },
    // 移除行数据
    handleRowDel(row, index) {
      this.tableDataSources.splice(index, 1);
    },
    handleFormulaVerify() {
      this.$refs.formulaForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          if (this.tableDataSources && this.tableDataSources.length > 0) {
            this.getFormulaResult();
          } else {
            this.$message.warning("请先添加填写详细计量内容");
          }
        } else {
          this.$message.warning("请输入计算公式");
        }
      });
    },
    getFormulaResult() {
      //   let required = true;
      //   this.tableDataSources.forEach((item) => {
      //     for (let val in item) {
      //       if (!item[val]) {
      //         required = false;
      //       }
      //     }
      //   });
      //   if (!required) {
      //     this.$message.warning("请填写详细计量内容");
      //     return;
      //   }
      this.tableDataSources.forEach((item) => {
        window[item.workSymbol] = Number(item.dictCode);
      });
      let formula = this.$refs.formulaForm.formData.formula;
      this.formulaResult = eval(formula);
      console.log(eval(formula));
    },

    //关闭费用申请单事件
    popupClose() {
      this.expenseApplyVisible = false;
    },
    //选择费用类型，确认事件
    handleApplySubmit() {},
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-input__icon {
  line-height: 30px;
}
.formula-box {
  display: flex;
  align-items: center;
  .input {
    width: 80%;
    margin-right: 20px;
  }
}
</style>
