<template>
  <div class="container">
      <div class="ibox-title">
        <h5 class="h5">明细比对</h5>
      </div>
      <div class="ibox-content">
        <el-table
            :data="tableData"
            border
            style="width: 100%; margin-top: 20px">
          <el-table-column
              prop="sshCostTypeParentName"
              label="业务类别"
              width="180">
          </el-table-column>
          <el-table-column
              prop="sshCostTypeName"
              label="二级费用类型">
          </el-table-column>
          <el-table-column
              prop="sshAmount"
              label="实时化侧金额(元)">
          </el-table-column>
          <el-table-column
              prop="kindeeAmount"
              label="金蝶侧金额(元)">
          </el-table-column>
        </el-table>
      </div>
  </div>
</template>

<script>
import {postApi, getApi} from "@/api/request";
import {formatNumber} from "@/utils/number";

export default {
  props: ["projectId"],
  computed: {
  },
  data() {
    return {
      activeName: "first",
      formData: {},
      projectId: "",
      tableData: [{
        sshCostTypeParentName: '',
        sshCostTypeName: '',
        sshAmount: '',
        kindeeAmount: ''
      }]
    };
  },
  created() {
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail(){
      this.formData['sshProjectId'] = this.projectId;

      postApi(
          `/finance/budget/compare/detail`,
          this.formData
      ).then((res) => {
        let {data} = res;
        if (data.code == 0) {
          let datas = data.data;

          for(var c in datas){
            if(datas.hasOwnProperty(c)){
              datas[c].forEach(item => {
                item.sshAmount = formatNumber(item.sshAmount);
                item.kindeeAmount = formatNumber(item.kindeeAmount);

                this.tableData.push(item);
              })
            }
          }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.expenseType {
  border: none;
  color: #66b1ff;
  cursor: pointer;
  background: none;
}
</style>