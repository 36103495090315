<template>
  <div class="main-container">
    <div class="select-info">
      <dd-form :form-config="formBasicList" ref="addAreaPopForm"></dd-form>
    </div>
    <div id="map"></div>
  </div>
</template>

<script>
import Map from "ol/Map"; //地图
import View from "ol/View"; //视图
import { Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import GeoJSON from "ol/format/GeoJSON";
import { Style, Fill, Stroke, Icon } from "ol/style";
import * as Layer from "/static/js/layer2D.js";
export default {
  name: "view-region",
  props: {
    rowData: {},
  },
  data() {
    return {
      map: "",
      vectorLayer: new VectorLayer({
        source: new VectorSource(),
      }),
      vectorSource: new VectorSource(),
      info: [],
      formBasicList: [
        {
          label: "区域名称",
          value: "areaname",
          inputType: "text",
          defaultValue: "",
          disabled: true,
        },
      ],
    };
  },
  mounted() {
    this.initMap(); //地图初始化（默认加载天地图底图）
    this.formBasicList[0].defaultValue = this.rowData.name;
    this.initRegion(); //获取区域信息
  },
  methods: {
    initMap() {
      this.map = new Map({
        target: "map",
        layers: Layer.tdtLayer(),
        view: new View({
          center: [108.948024, 34.263161],
          projection: "EPSG:4326",
          zoom: 12.5,
          minZoom: 5,
          maxZoom: 18,
          units: "metric",
        }),
      });
    },
    fitLayer(layer) {
      let extent = layer.getSource().getExtent();
      extent[0] = extent[0] - 0.01;
      extent[1] = extent[1] - 0.01;
      extent[2] = extent[2] + 0.01;
      extent[3] = extent[3] + 0.01;
      this.map.getView().fit(extent);
    },
    initRegion() {
      let geom = this.rowData.geom;
      let headstr =
        "{" +
        '"type"' +
        ":" +
        '"FeatureCollection"' +
        "," +
        '"totalFeatures"' +
        ":1," +
        '"features"' +
        ":[";
      let tailstr = "]}";
      let json = headstr + geom + tailstr;
      this.viewRegionInfo(json);
    },
    //回显区域范围
    viewRegionInfo(json) {
      this.vectorSource.addFeatures(new GeoJSON().readFeatures(json));
      this.vectorLayer = new VectorLayer({
        zIndex: 100,
        source: this.vectorSource,
        style: new Style({
          fill: new Fill({
            color: "rgba(0, 102, 102, 0.3)",
          }),
          stroke: new Stroke({
            color: "#fc5531",
            lineDash: [10, 10],
            width: 3,
          }),
        }),
      });
      this.map.addLayer(this.vectorLayer);
      if (json != "") {
        this.fitLayer(this.vectorLayer);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  border: 1px solid #ccc;
  /deep/ .base-form {
    width: 50%;
  }
  #map {
    margin-top: 10px;
    width: 100%;
    height: 80%;
  }
}
</style>