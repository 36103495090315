<template>
    <div class="main-container">
        <dd-form :form-config="formBasicList" ref="addPopForm">
        </dd-form>
    </div>
  </template>
  
  <script>
  export default {
    name: "add-pop",
    props: {
      dictType: [],
      rowData: [],
      poptitleName: ''
    },
    data() {
      return {
        formBasicList: [
          {
            label: "上级类别",
            value: "parentId",
            inputType: "cascader",
            optionsList: [],
            disabled: true,
            prop: {
              value: "id",
              label: "name",
              checkStrictly: true,
              emitPath: false
            },
            showAllSelcet: false,
            placeholderText: "请选择上级类别",
            rules: [
              {
                required: true,
                message: "请选择上级类别",
                trigger: "blur",
              },
            ]
          },
          // {
          //   label: "路由缓冲",
          //   value: "keepAlive",
          //   inputType: "radio",
          //   defaultValue: "",
          //   border: true,
          //   radioGroup: [],
          //   rules: [
          //     {
          //       required: true,
          //       message: "请选择路由缓冲",
          //       trigger: "blur",
          //     },
          //   ]
          // },
          {
            label: "类别名称",
            value: "name",
            inputType: "text",
            placeholderText: "请输入类别名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入类别名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "编码",
            value: "code",
            inputType: "text",
            placeholderText: "请输入编码",
            disabled: false,
            defaultValue: '',
          },
          {
            label: "描述",
            value: "description",
            inputType: "text",
            placeholderText: "请输入描述",
            disabled: false,
            defaultValue: '',
          },
          {
            label: "排序",
            value: "sortOrder",
            inputType: "number",
            min: 0,
            max: 999,
            placeholderText: "请输入排序",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    created() {},
    mounted() {
        if(this.dictType) {
          let newTree = [{
            name: '根菜单',
            id: '-1',
            children: this.dictType 
          }]
          this.formBasicList.forEach(item => {
            if(item.value == 'parentId') {
              item.optionsList = newTree
            }
          })
        }
        if(this.rowData != null) {
            this.formBasicList.forEach(item => {
              if(this.poptitleName == "新增") {
                if(item.value == "parentId") {
                  item.defaultValue = this.rowData.id
                }
              }else {
                item.defaultValue = this.rowData[item.value]
              }
            })
        }
    },
    methods: {
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.radioGroup = options.optionsList;
                    }
                }
            });
        },
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
  