<template>
  <div class="detail-container">
    <dd-card title="预算汇总">
      <dd-table
        ref="expenseDetail"
        :topBtn="false"
        :isSelection="false"
        :columns="tableColumns"
        :data="tableDataSources"
        border
        show-summary
      ></dd-table>
    </dd-card>
    <dd-card title="类型明细">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, idx) in tabArr"
          :key="idx"
          :label="item.name"
          :name="item.name"
        >
          <dd-table
            :columns="tableTypeColumns"
            :data="item.children"
            :topBtn="false"
            :is-selection="false"
            border
            default-expand-all
            :tree-props="{
              children: 'children',
              hasChildren: 'hasChildren',
            }"
          >
          </dd-table>
        </el-tab-pane>
      </el-tabs>
    </dd-card>
    <!-- 记账明细 -->
    <dd-popup
      width="80%"
      height="80%"
      inPopup
      :title-name="amountDetailTitle"
      :show="amountDetailVisible"
      @popup-close="amountDetailVisible = false"
    >
      <AmountDetail
        v-if="amountDetailVisible"
        slot="main"
        :row="row"
        :projectId="projectId"
        :expenseTypeCode="expenseTypeCode"
      ></AmountDetail>
    </dd-popup>
    <!-- 报账明细 -->
    <dd-popup
      width="80%"
      height="80%"
      inPopup
      :title-name="claimsAmountDetailTitle"
      :show="claimsAmountDetailVisible"
      @popup-close="claimsAmountDetailVisible = false"
    >
      <ClaimsAmountDetail
        v-if="claimsAmountDetailVisible"
        slot="main"
        :projectId="assignId"
      ></ClaimsAmountDetail>
    </dd-popup>
  </div>
</template>

<script>
import AmountDetail from "./AmountDetail";
import ClaimsAmountDetail from "./ClaimsAmountDetail";
import { postApi, getApi } from "@/api/request";
import { mapActions, mapState } from "vuex";
import qs from "qs";

export default {
  components: { AmountDetail, ClaimsAmountDetail },
  props: ["applyId", "projectId", "amountType", 'pageType', 'assignId'],
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      tableDataSources: [],
      tableColumns: [
        {
          title: "明细类别",
          key: "costTypeName",
          align: "center",
        },
        {
          title: "预算金额(元)",
          key: "budgetAmount",
          align: "center",
        },
        {
          title: "记账金额(元)",
          key: "bookkeepingAmount",
          align: "center",
        },
        {
          title: "报账金额(元)",
          key: "reviewAmount",
          align: "center",
        },
        {
          title: "剩余金额(元)",
          key: "remainingAmount",
          align: "center",
        },
      ],
      tableTypeColumns: [
        {
          title: "费用类型编号",
          key: "code",
          align: "center",
          width: 180,
        },
        {
          title: "费用类型名称",
          key: "name",
          align: "center",
          width: 180,
          event: [
            {
              type: "primary",
              href: (row) => {
                return row.name;
              },
              method: (row) => {
                this.openDayBookDetail(row);
              },
            },
          ],
        },
        {
          title: "预算金额(元)",
          key: "amount",
          align: "center",
        },
        {
          title: "记账金额(元)",
          key: "realUseAmount",
          align: "center",
        },
        {
          title: "报账金额(元)",
          key: "realClaimsAmount",
          align: "center",
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
        },
      ],
      row: {},
      tableData: [],
      proTableData: [],
      manTableData: [],
      saleTableData: [],
      devTableData: [],
      expandRowKeys: [],
      //判断当前用户角色，是否为当前项目经理角色
      isCurPrjCmmManager: false,
      amountDetailVisible: false,
      amountDetailTitle: "",
      expenseTypeCode: "",
      claimsAmountDetailVisible: false,
      claimsAmountDetailTitle: "",
      activeName: "生产类",
      tabArr: [],
    };
  },
  created() {},
  mounted() {
    let _this = this;
    // this.judgeIsCurPrjManager();
    this.getDetailList();
    setTimeout(() => {
      let table = document.querySelector(
        ".detail-container .el-table__footer-wrapper>table"
      );
      console.log('table', table.rows[0].cells)
      // 选择第几个点击事件 如果全部 直接写 table.rows[0].cells.onclick
      if (Number(table.rows[0].cells[3].textContent) > 0) {
        table.rows[0].cells[3].style.setProperty("cursor", "pointer");
        table.rows[0].cells[3].style.setProperty(
          "color",
          "#4e72ea",
          "important"
        );
        table.rows[0].cells[3].onclick = function (e) {
          _this.openReportBill();
        };
      }
      if (Number(table.rows[0].cells[2].textContent) > 0) {
        table.rows[0].cells[2].style.setProperty("cursor", "pointer");
        table.rows[0].cells[2].style.setProperty(
          "color",
          "#4e72ea",
          "important"
        );
        table.rows[0].cells[2].onclick = function (e) {
          _this.openDayBookDetail();
        };
      }
    }, 1000);
  },
  methods: {
    // ...mapActions(["getBtnAuthority"]), //获取权限标识
    getDetailList() {
      if (!this.applyId) {
        this.getApplyIdInUsingByProjectId();
      } else {
        this.getTotal(this.applyId);
      }
    },
    getApplyIdInUsingByProjectId() {
      let projectId = { projectId: this.projectId };
      postApi(`/finance/apply/front/budgetInUsing`, projectId).then((res) => {
        let { data } = res;
        this.getTotal(data.id);
      });
    },
    getTotal(id) {
      let params = { id: id };
      postApi(`/finance/applysheet/total/detail`, params).then((res) => {
        let { data } = res;
        if (data.code === 0 && data.data && data.data.oneTypeVOList) {
          let arr = [];
          data.data.oneTypeVOList.forEach((item) => {
            let obj = {
              name: item.name,
              children: [item],
            };
            arr.push(obj);
          });
          this.tabArr = arr;
          this.tableDataSources = data.data.budgetAccountResultList;
        }
      });
    },
    handleClick(tab, event) {},
    openDayBookDetail(row) {
      this.amountDetailTitle = "记账明细";
      this.amountDetailVisible = true;
      if(row) {
        this.row = row;
      }
      else {
        this.row = {}
      }

      this.row.assignId = this.assignId;
    },
    openReportBill(row) {
      this.claimsAmountDetailTitle = "报账明细";
      this.claimsAmountDetailVisible = true;
    },

    judgeIsCurPrjManager() {
      let params = {
        projectId: this.projectId,
      };
      postApi(`/finance/apply/front/isCurPrjCmmManager`, qs.stringify(params))
        .then((res) => {
          let { data } = res;
          if (data && true === data) {
            this.isCurPrjCmmManager = data;
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-tabs__header {
  margin: 0;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #576289;
  border-bottom: none;
}
/deep/.el-tabs__item.is-active {
  color: @systemColor !important;
}
/deep/.el-tabs__item:hover {
  color: #000 !important;
}
/deep/.el-tabs__item.is-active:focus {
  color: @systemColor !important;
}
/deep/ .el-tabs__nav {
  width: 100%;
}
/deep/ .el-tabs__item {
  width: 25%;
  text-align: center;
}
</style>
