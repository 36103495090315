<template>
  <div class="main-container">
    <dd-form
      :form-config="formManageList"
      :flex="2"
      labelWidth="220px"
      ref="addPopForm"
    >
    </dd-form>
  </div>
</template>

<script>
import { postApi, getApi, putApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowData: [],
  },
  data() {
    return {
      formManageList: [
        {
          label: "现销售负责部门",
          value: "nowDownDeptId",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择现销售负责部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          change: this.getNowClientJlList,
          defaultValue: "",
        },
        {
          label: "现客户经理",
          value: "nowCmmmanagerId",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择客户经理",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          defaultValue: "",
        },
        {
          label: "现生产负责部门",
          value: "nowReceiveDeptId",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择现生产负责部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          change: this.getNowPersonList,
          defaultValue: "",
        },
        {
          label: "现生产负责人",
          value: "nowLeaderId",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择现生产负责人",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          defaultValue: "",
        },
        {
          label: "任务书生产实施状态",
          value: "produceStatus",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择任务书生产实施状态",
          optionsListConfig: {
            code: "code",
            name: "name",
          },
          defaultValue: "",
        },
      ],
      detailData: null,
    };
  },
  created() {},
  mounted() {
    if (this.rowData != null) {
      this.getDataDetail(this.rowData.id);
    }
    this.getDataType();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options1 = {
            value: "nowReceiveDeptId",
            optionsList: data.data,
          };
          let options2 = {
            value: "nowDownDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formManageList, options1);
          this.setOPtionsList(this.formManageList, options2);
        }
      });
      // 生产实施状态
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJFZT" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "produceStatus",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formManageList, options);
          }
        }
      );
    },
    //现生产负责人列表
    getNowPersonList(deptId, type) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId: deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formManageList.forEach((item) => {
            if (item.value == "nowReceiveDeptId") {
              item.defaultValue = deptId;
            } else {
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
            if (item.value == "nowLeaderId") {
              item.optionsList = data.data;
              if (type != "init") {
                item.defaultValue = null;
              }
            }
          });
        }
      });
    },
    // 获取现客户经理列表数据
    getNowClientJlList(deptId, type) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId: deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formManageList.forEach((item) => {
            if (item.value == "nowDownDeptId") {
              item.defaultValue = deptId;
            } else {
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
            if (item.value == "nowCmmmanagerId") {
              item.optionsList = data.data;
              if (type != "init") {
                item.defaultValue = null;
              }
            }
          });
        }
      });
    },
    getDataDetail(id) {
      getApi(`/project/assign/${id}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.detailData = data.data;
          this.getNowClientJlList(data.data.nowDownDeptId, "init");
          this.getNowPersonList(data.data.nowReceiveDeptId, "init");
          this.formManageList.forEach((item) => {
            item.defaultValue = data.data[item.value];
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}
/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
</style>
