<template>
  <div class="main-container">
    <div class="data-form">
      <div class="form-item">
        <div class="card-name">考勤数据</div>
        <i
          class="el-icon-download"
          title="导出"
          @click="type == 'error' ? exportErrorData() : exportData()"
        >
          导出数据</i
        >
        <i class="el-icon-close" title="关闭" @click="dataPopupClose"></i>
      </div>
      <dd-table
        :columns="type == 'error' ? errorColumnsList : columnsList"
        :data="tableData"
        height="441"
        isSort
        border
        :isSelection="false"
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="left"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </div>
  </div>
</template>

<script>
import { getNowDate } from "@/utils/date.js";
import { getApi, loadApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index.js";
export default {
  name: "data-pop",
  props: { type: "", formData: {} },
  data() {
    return {
      columnsList: [
        {
          title: "姓名",
          key: "userFullName",
          align: "center",
          width: 100,
        },
        {
          title: "打卡时间",
          key: "signTime",
          align: "center",
          width: 200,
        },
        {
          title: "打卡地址",
          key: "signAddress",
          align: "center",
        },
      ],
      errorColumnsList: [
        {
          title: "姓名",
          key: "userFullName",
          align: "center",
          width: 100,
        },
        {
          title: "工号",
          key: "username",
          align: "center",
          width: 150,
        },
        {
          title: "部门",
          key: "deptName",
          align: "center",
          width: 150,
        },
        {
          title: "打卡时间",
          key: "signTime",
          align: "center",
        },
      ],
      tableData: [],
      total: 0,
      current: 1,
      size: 10,
    };
  },
  mounted() {
    if (this.type == "error") {
      this.getErrorData();
    } else {
      this.getTableData();
    }
  },
  watch: {
    type(newVal, oldVal) {
      if (this.type == "error") {
        this.getErrorData();
      } else {
        this.getTableData();
      }
    },
  },
  methods: {
    getTableData() {
      let { current, size } = this;
      let params = {
        current,
        size,
        startTimeStr: this.formData.date ? this.formData.date[0] : getNowDate(),
        endTimeStr: this.formData.date ? this.formData.date[1] : getNowDate(),
        userFullName: this.formData.name,
        deptId: this.formData.dept,
        checkType: this.type,
        areaId: this.formData.region,
        prjId: this.formData.type,
      };
      getApi(`/hr/attend/monthinfo/today`, params).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
    getErrorData() {
      let { current, size } = this;
      let params = {
        current,
        size,
        startTimeStr: this.formData.date ? this.formData.date[0] : getNowDate(),
        endTimeStr: this.formData.date ? this.formData.date[1] : getNowDate(),
      };
      getApi(`/hr/attend/monthinfo/locateexception`, params).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
    exportData() {
      let { current } = this;
      let params = {
        current,
        size: 9999,
        startTimeStr: this.formData.date ? this.formData.date[0] : getNowDate(),
        endTimeStr: this.formData.date ? this.formData.date[1] : getNowDate(),
        checkType: this.type,
        userFullName: this.formData.name ? this.formData.name : "",
        deptId: this.formData.dept ? this.formData.dept : "",
        areaId: this.formData.region ? this.formData.region : "",
        prjId: this.formData.type ? this.formData.type : "",
      };
      loadApi(
        `/hr/attend/monthinfo/today/export?current=` +
          params.current +
          "&size=" +
          params.size +
          "&startTimeStr=" +
          params.startTimeStr +
          "&endTimeStr=" +
          params.endTimeStr +
          "&checkType=" +
          params.checkType +
          "&userFullName=" +
          params.userFullName +
          "&deptId=" +
          params.deptId +
          "&areaId=" +
          params.areaId +
          "&prjId=" +
          params.prjId
      ).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    exportErrorData() {
      let { current } = this;
      let params = {
        current,
        size: 9999,
        startTimeStr: this.formData.date ? this.formData.date[0] : getNowDate(),
        endTimeStr: this.formData.date ? this.formData.date[1] : getNowDate(),
      };
      loadApi(
        `/hr/attend/monthinfo/exception/export?current=` +
          params.current +
          "&size=" +
          params.size +
          "&startTimeStr=" +
          params.startTimeStr +
          "&endTimeStr=" +
          params.endTimeStr
      ).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    dataPopupClose() {
      this.$emit("close");
    },
    // 分页页码回调
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      if (this.type == "error") {
        this.getErrorData();
      } else {
        this.getTableData();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
   height: 495px !important;
  width: 900px !important;
  position: absolute !important;
  top: 20%;
  right: 20px;
  .data-form {
    background-color: #fff;
    .form-item {
      background-color: @themeColor;
      color: #fff;
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      align-items: center;
      display: flex;
      .card-name {
        margin-left: 15px;
      }
      .el-icon-download {
        position: absolute;
        right: 2px;
        font-size: 15px;
        margin-right: 40px;
      }
      .el-icon-close {
        position: absolute;
        right: 2px;
        font-size: 20px;
        margin-right: 5px;
      }
    }
    .base-table {
      height: 495px !important;
      /deep/ .table-btn-group {
        display: none !important;
      }
      /deep/ .table-box {
        height: calc(100% - 41px) !important;
      }
      /deep/ .el-pagination{
        margin-top: unset !important;
      }
    }
  }
}
</style>