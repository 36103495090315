<template>
  <div class="main-container">
    <dd-form :form-config="formList" ref="addPopForm"> </dd-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import { getNowDate } from "@/utils/date";
export default {
  name: "add-pop",
  props: {
    rowData: {},
  },
  data() {
    return {
      formList: [
        {
          label: "实际工作量",
          value: "realWorkload",
          inputType: "text",
          placeholderText: "请输入实际工作量",
          rules: [
            { required: true, message: "请输入实际工作量", trigger: "blur" },
            {
              pattern: /^[1-9]\d{0,7}$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        // {
        //   label: "实际工天",
        //   value: "realWorkday",
        //   inputType: "text",
        //   placeholderText: "请输入实际工天",
        //   rules: [
        //     { required: true, message: "请输入实际工天", trigger: "blur" },
        //     {
        //       pattern: /^[1-9]\d{0,7}$/,
        //       required: true,
        //       message: "请输入正确的格式",
        //       trigger: "blur",
        //     },
        //   ],
        // },
        {
          label: "实际开始时间",
          value: "realStarttime",
          placeholderText: "实际开始时间",
          inputType: "date",
          dateType: "date",
          format: "yyyy-MM-dd",
          change: this.handleChange,
          defaultValue: getNowDate(),
          //   rules: [
          //     { required: true, message: "请选择用车结束日期", trigger: "blur" },
          //   ],
        },
        {
          label: "实际结束时间",
          value: "realEndtime",
          placeholderText: "实际结束时间",
          inputType: "date",
          dateType: "date",
          format: "yyyy-MM-dd",
          change: this.handleChange,
          defaultValue: getNowDate(),
          //   rules: [
          //     { required: true, message: "请选择用车结束日期", trigger: "blur" },
          //   ],
        },
      ],
    };
  },
  created() {},
  mounted() {
    console.log(this.rowData);
    if (this.rowData != null) {
      this.formList.forEach((item) => {
        if (this.rowData[item.value]) {
          item.defaultValue = this.rowData[item.value];
        }
      });
    }
  },
  methods: {
    handleChange(e, type) {
      let { realStarttime, realEndtime } = this.$refs.addPopForm.formData;
      if (type == "realStarttime" || type == "realEndtime") {
        if (realStarttime && realEndtime) {
          realStarttime = realStarttime.replace(/-/g, "/");
          realEndtime = realEndtime.replace(/-/g, "/");
          let start = new Date(realStarttime).getTime();
          let end = new Date(realEndtime).getTime();
          if (end < start) {
            this.$message.warning("结束时间需大于开始时间");
            this.$refs.addPopForm.formData.realStarttime = "";
            this.$refs.addPopForm.formData.realEndtime = "";
            return;
          }
        }
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
