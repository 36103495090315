import { render, staticRenderFns } from "./AddPop.vue?vue&type=template&id=1cd4c3c8&scoped=true"
import script from "./AddPop.vue?vue&type=script&lang=js"
export * from "./AddPop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cd4c3c8",
  null
  
)

export default component.exports