 /* 时间格式转化 */
 const formatDate = (params) => {
     let date = new Date(params);
     let YY = date.getFullYear() + '-';
     let MM =
         (date.getMonth() + 1 < 10 ?
             '0' + (date.getMonth() + 1) :
             date.getMonth() + 1) + '-';
     let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
     // let hh =
     //     (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
     // let mm =
     //     (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
     //     ':';
     // let ss =
     //     date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
     // return YY + MM + DD + ' ' + hh + mm + ss;
     return YY + MM + DD;
 };
 /* 时间格式转化 */
 const formatDateTime = (params) => {
     let date = new Date(params);
     let YY = date.getFullYear() + '-';
     let MM =
         (date.getMonth() + 1 < 10 ?
             '0' + (date.getMonth() + 1) :
             date.getMonth() + 1) + '-';
     let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
     let hh =
         (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
     let mm =
         (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
         ':';
     let ss =
         date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
     return YY + MM + DD + ' ' + hh + mm + ss;
 };
 //获取当前日期
 const getNowDate = () => {
     let data = new Date();
     let yyyy = data.getFullYear();
     let month = data.getMonth() + 1;
     let day = data.getDate();
     return yyyy + "-" + month + "-" + day;
 }
 //获取当前日期
 const getInitYear = () => {
     let data = new Date();
     let yyyy = data.getFullYear();
     return yyyy + "-01-01";
 }
 //获取当前月份
 const getNowMonth = () => {
     let data = new Date();
     let yyyy = data.getFullYear();
     let month = data.getMonth() + 1;
     month = month.toString().padStart(2,'0')
     return yyyy + "-" + month
 }
 // 获取当前年份
 const getNowYear = () => {
     let data = new Date();
     let yyyy = data.getFullYear();
     return yyyy
 }
 export {
     formatDate,
     getNowDate,
     formatDateTime,
     getNowMonth,
     getInitYear,
     getNowYear
 }