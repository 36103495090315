<template>
  <vue-office-excel
    v-if="fileType == 'xls' || fileType == 'xlsx'"
    :src="fileUrl"
    @rendered="renderedHandler"
    @error="errorHandler"
  />
  <vue-office-docx
    v-else-if="fileType == 'docx' || fileType == 'doc'"
    :src="fileUrl"
    @rendered="renderedHandler"
    @error="errorHandler"
  />
  <vue-office-pdf
    v-else-if="fileType == 'pdf'"
    :src="fileUrl"
    @rendered="renderedHandler"
    @error="errorHandler"
  />
</template>

<script>
import VueOfficeExcel from "@vue-office/excel";
import VueOfficeDocx from "@vue-office/docx";
import VueOfficePdf from "@vue-office/pdf";
import "@vue-office/excel/lib/index.css";
import "@vue-office/docx/lib/index.css";
export default {
  name: "dd-fileView",
  props: {
    fileType: {
      type: String,
      default: "",
    },
    fileUrl: {
      type: ArrayBuffer,
      default: "",
    },
  },
  components: {
    VueOfficeExcel,
    VueOfficeDocx,
    VueOfficePdf,
  },
  methods: {
    renderedHandler() {
      console.log("渲染完成");
    },
    errorHandler() {
      console.log("渲染失败");
    },
  },
};
</script>

<style></style>
