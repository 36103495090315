<template>
  <div class="main-container">
    <dd-card title="模板节点列表" height="100%">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        border
        :isSelection="false"
        :tree-props="{ children: 'children' }"
        default-expand-all
      >
        <template #table-btn>
          <dd-button
            type="warning"
            size="medium"
            icon="back"
            @click="handleBackPage"
            >返回</dd-button
          >
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd"
            >新增</dd-button
          >
        </template>
      </dd-table>
    </dd-card>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="30%"
      height="65%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :dictType="tableData"
        :rowData="rowData"
        :produceType="produceType"
        :poptitleName="poptitleName"
      ></add-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./AddNodePop.vue";
export default {
  props: {
    rowDataObj: null,
  },
  components: {
    addPop,
  },
  computed: {},
  data() {
    return {
      drawerVisible: false,
      tableColumns: [
        {
          title: "节点编码",
          key: "code",
        },
        {
          title: "节点名称",
          key: "name",
          align: "center",
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
        },
        {
          title: "工作量单位类型",
          key: "workloadUnitTypeName",
          align: "center",
        },
        {
          title: "排序",
          key: "weight",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 260,
          event: [
            {
              show: "true",
              label: "添加",
              icon: "el-icon-plus",
              method: (row) => {
                this.handleRowAdd(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (this.rowDataObj.produceType == "PRJ-SCKZMS-WBSMS") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              show: "true",
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (this.rowDataObj.produceType == "PRJ-SCKZMS-WBSMS") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      addPopupVisible: false,
      rowData: null,
      poptitleName: "新增",
      produceType: this.rowDataObj.produceType,
    };
  },
  created() {},
  mounted() {
    this.getTableData();
  },
  methods: {
    handleBackPage() {
      this.$emit("pageBack");
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let params = {
        baseId: this.rowDataObj.id,
      };
      getApi(`/project/prjwbstemplate/tree`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableData = data.data;
        }
      });
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
    },
    //   表格行内添加事件
    handleRowAdd(row) {
      this.rowData = row;
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.rowData = row;
      this.addPopupVisible = true;
      this.poptitleName = "编辑";
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/project/prjwbstemplate/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      params.baseId = this.rowDataObj.id;
      if (flag) {
        if (this.rowData != null && this.poptitleName == "编辑") {
          params.id = this.rowData.id;
          putApi(`/project/prjwbstemplate`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.handleClose();
              this.getTableData();
            }
          });
        } else {
          postApi(`/project/prjwbstemplate`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
