import {
    getApi,
    postApi,
    postfromApi
} from "../api/request"
import Vue from 'vue'
export default {
    asyncGetOrgList(context) {
        getApi(`/admin/org/getcurrentuserorglist`).then((res) => {
            context.commit('getOrgList',res.data.data)
        })
    }
    
}