<template>
  <div>
    <dd-card title="基本信息" spacer>
      <dd-form
        :form-config="formList"
        ref="baseFoem"
        :formRef="formBasicRef"
      ></dd-form>
    </dd-card>
    <dd-card title="费用明细">
      <more-table
        height="320"
        :columns="tableColumns"
        :data="tableDataSources"
        :default-expand-all="true"
        :span-method="arraySpanMethod"
        @select-all="selectAll"
        @select-change="handleSelectionChange"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        ref="moreTable"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            size="medium"
            icon="add"
            @click="handleExpenseApply"
            >选择</dd-button
          >
          <dd-button
            type="danger"
            size="medium"
            icon="delete"
            @click="handleDelete"
            >移除</dd-button
          >
        </template>
      </more-table>
    </dd-card>

    <dd-popup
      width="80%"
      inPopup
      showBtn
      :show="expenseApplyVisible"
      title-name="费用申请单"
      @popup-close="expenseApplyVisible = false"
    >
      <dd-button slot="button" size="medium" @click="popupClose"
        >取消</dd-button
      >
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleApplySubmit"
        >确认</dd-button
      >

      <expense-apply
        v-if="expenseApplyVisible"
        slot="main"
        ref="expenseApply"
        :filterSelection="filterSelection"
      ></expense-apply>
    </dd-popup>
  </div>
</template>

<script>
import { getNowDate } from "@/utils/date";
import expenseApply from "./ExpenseApplyPop.vue";
import { postApi, getApi } from "@/api/request";
import $ from "jquery";
export default {
  name: "add-pop",
  components: {
    expenseApply,
  },
  props: {
    rowEditId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      depList: [],
      projectList: [],
      formList: [
        {
          label: "报账单号",
          value: "code",
          inputType: "text",
          placeholderText: "报账单号",
          disabled: true,
        },
        {
          label: "报账日期",
          value: "applyDate",
          inputType: "text",
          disabled: true,
          placeholderText: getNowDate(),
        },
        {
          label: "报账人",
          value: "applier",
          inputType: "text",
          placeholderText: this.getApplier(),
          disabled: true,
        },
      ],
      tableColumns: [
        {
          title: "申请单号",
          key: "code",
          align: "center",
          width: 200,
        },
        {
          title: "记账日期",
          key: "applyTime",
          align: "center",
        },
        {
          title: "费用类型",
          key: "extenseTypeName",
          align: "center",
        },
        {
          title: "金额（元）",
          key: "amount",
          align: "center",
        },
        {
          title: "申请人",
          key: "applier",
          align: "center",
        },
        {
          title: "费用承担部门",
          key: "expenseBearDepName",
          align: "center",
        },
        {
          title: "操作",
          key: "file",
          align: "center",
          width: 80,
          event: [
            {
              label: "移除",
              method: (row) => {
                this.handleRemove(row);
              },
            },
          ],
        },
      ],
      tableDataSources: [],
      //费用申请单显隐
      expenseApplyVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      //是否打开删除确认
      autoConfirm: false,
      //默认选择状态
      filterSelection: [],
      formBasicRef: "formBasic",
      checked: false,
      options: {},
    };
  },
  mounted() {
    this.getDep();
    this.getProject();
    //编辑报账单回显
    let id = this.rowEditId;
    getApi(`/finance/claims/update/${id}`)
      .then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let obj = {
            applier: data.data.user,
            applyDate: data.data.createDate,
            code: data.data.code,
          };
          this.$refs.baseFoem.formData = obj;
          this.tableDataSources = data.data.detail;
          this.refreshTableDataSources();
        }
      })
      .catch((error) => {
        this.$message.error(error.response.data.msg);
      });
    //格式化报账单界面
    // this.refreshTableDataSources();
  },
  methods: {
    //报账人 - 获取当前登录人账号
    getApplier() {
      return sessionStorage.getItem("userFullName");
    },
    //选择事件---打开费用申请单列表
    handleExpenseApply() {
      this.filterSelection = [];
      this.expenseApplyVisible = true;
      //默认选择数据
      this.tableDataSources.forEach((item) => {
        item.children.forEach((itm) => {
          this.filterSelection.push(itm);
        });
      });
    },
    //关闭费用申请单事件
    popupClose() {
      this.expenseApplyVisible = false;
    },
    //单行删除
    handleRemove(row) {
      // this.$confirm("是否删除此行数据？", "提示", {
      //   confirmButtonClass: "btnsearch",
      //   confirmButtonText: "确认",
      //   cancelButtonClass: "btnreset",
      //   cancelButtonText: "取消",
      //   showClose: true,
      //   type: "warning",
      // }).then(() => {
      this.filterSelection = [];
      this.getDelete(row);
      // });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //全选事件
    selectAll() {
      this.checked = !this.checked;
      this.handleSelect(this.tableDataSources, this.checked);
    },
    //全选事件选中状态
    handleSelect(data, flag) {
      data.forEach((row) => {
        this.$refs.moreTable.$refs.moreTable.toggleRowSelection(row, flag);
        if (row.children) {
          this.handleSelect(row.children);
        }
      });
    },
    //批量删除事件
    handleDelete() {
      let deleteSelection = this.multipleSelection;
      if (deleteSelection.length < 1) {
        this.$message.warning("请至少选择一条数据!");
      } else {
        // this.autoConfirm = true;
        this.handleConfirmDelete();
      }
    },
    //是否确认删除
    handleConfirmDelete() {
      this.filterSelection = [];
      //批量删除费用明细
      this.multipleSelection.map((item) => {
        this.getDelete(item);
      });
      //清除选择状态
      this.multipleSelection = [];
      this.$refs.moreTable.$refs.moreTable.clearSelection();
      // this.autoConfirm = false;
    },
    //删除费用报账单费用明细数据
    getDelete(data) {
      this.tableDataSources.map((item, index) => {
        item.children.map((itm, idx) => {
          if (itm.id == data.id) {
            this.tableDataSources[index].children.splice(idx, 1);
            if (this.tableDataSources[index].children.length == 0) {
              this.tableDataSources.splice(index, 1);
            }
          } else {
            this.filterSelection.push(itm);
          }
        });
      });
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      // if(row.code == 1){
      //     return [0,6]
      // }
    },
    //选择费用类型，确认事件
    handleApplySubmit() {
      let filterSelectionIds = [];
      let multipleSelectionIds = [];
      let filterSelectionProject = [];
      let multipleSelectionProject = [];
      let projectId = [];
      //费用报账单--费用明细默认选择数据id集合
      this.$refs.expenseApply.filterSelection.map((item) => {
        filterSelectionIds.push(item.id);
        filterSelectionProject.push(item.projectId);
      });
      //费用申请单--当前选择数据id集合
      this.$refs.expenseApply.multipleSelection.map((item) => {
        multipleSelectionIds.push(item.id);
        multipleSelectionProject.push(item.projectId);
      });
      //求差集
      let arr = multipleSelectionIds.filter((item) => {
        return filterSelectionIds.indexOf(item) == -1;
      });
      projectId = [...filterSelectionProject, ...multipleSelectionProject];
      //数据未改变则不允许修改
      if (arr.length == 0) {
        this.$message.warning("请至少选择一条数据进行确认操作！");
        return;
      }
      let flag = this.getFlagProjectId(projectId);
      //判断当前所选项目是否相同（数组projectId中的项目id是否完全一样）
      if (!flag) {
        this.$message.warning("只能选择相同项目的申请单进行报销!");
        return;
      }
      this.getTableDataList();
    },
    getFlagProjectId(arrList) {
      if (arrList.length > 1) {
        return !arrList.some((value, index) => {
          return value !== arrList[0];
        });
      } else {
        return true;
      }
    },
    getTableDataList() {
      this.autoConfirm = false;
      let expenseApplyData = [];
      //判断是否选择数据---费用报账单费用明细数据为默认选择数据和费用申请单选择数据合集
      if (this.$refs.expenseApply) {
        expenseApplyData = this.$refs.expenseApply.multipleSelection;
        if (this.filterSelection.length != 0) {
          let arr = this.filterSelection.concat(expenseApplyData);
          for (var i = 0; i < arr.length; i++) {
            for (var j = 0; j < arr.length; j++) {
              if (arr[i].id == arr[j].id && i != j) {
                //将后面重复的数删掉
                arr.splice(j, 1);
              }
            }
          }
          expenseApplyData = arr;
        }
      } else {
        expenseApplyData = this.filterSelection;
      }

      let ids = [];
      expenseApplyData.forEach((item) => {
        ids.push(item.id);
      });
      if (ids.length < 1) {
        this.$message.warning("请至少选择一条数据!");
        this.tableDataSources = [];
        return;
      }
      let idsform = {
        ids: ids,
      };
      postApi(`/finance/claims/detail`, idsform)
        .then((res) => {
          if (res.data.code == 0 && res.data.data) {
            if (res.data.data.length > 0) {
              res.data.data.forEach((item) => {
                item.children.forEach((itm) => {
                  if (itm.amount) {
                    itm.amount = parseFloat(itm.amount).toFixed(2);
                  }
                });
              });
            }
            this.tableDataSources = res.data.data;
            this.refreshTableDataSources();
            this.expenseApplyVisible = false;
          }
        })
        .catch(() => {
          this.$message.error("请求出错");
        });
    },

    //获取部门信息
    getDep() {
      getApi(`/middle/dep/depList`).then((res) => {
        this.depList = res.data;
        this.options = {
          value: "expenseBearDepId",
          optionsList: this.depList,
        };
        this.setOPtionsList(this.formList, this.options);
      });
    },
    //获取项目信息
    getProject() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.projectList = data.data;
          this.options = {
            value: "projectId",
            optionsList: this.projectList,
          };
          this.setOPtionsList(this.formList, this.options);
        }
      });
    },
    //项目选择——添加项目编号
    changeProject(val) {
      //获取项目编号
      // getApi(``, val).then((res) => {
      //   this.$refs.expenseBasicForm.formData.projectCode = res.data.data;
      // });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    refreshTableDataSources() {
      let _this = this;
      this.$nextTick(() => {
        let width = $(".el-table__row--level-1").width();
        $(".el-table__row--level-0").width(width + "px");
        _this.tableDataSources.forEach((item, idx) => {
          $(".el-table__row--level-0").eq(idx).html(item.projectName);
        });
      });
    },
  },
};
</script>

<style>
</style>