<template>
    <div class="main-container">
        <div class="main-container">
            <div class="head_wrap">
                <dd-card title="条件搜索" spacer>
                    <dd-search-form :form-config="formList" @search-click="handleSearchEvent"
                        ref="ddSearchForm"></dd-search-form>
                </dd-card>
            </div>

            <dd-card title="" :height="tableHeight">
                <dd-table :columns="columnsList" :data="tableData" :row-class-name="tableRowClassName" :stripe="false" border :isSelection="false" show-page
                    :loading="loading">
                    <template #table-btn-right>
                        <el-button icon="el-icon-s-operation" circle type="info" size="small"
                            @click="drawerVisible = true" title="列筛选"></el-button>
                    </template>
                    <template #table-pagination>
                        <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                            @page-change="handlePageChange" align="right">
                        </dd-pagination>
                    </template>
                </dd-table>
            </dd-card>

            <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
                :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
                @handleColumnsChange="handleColumnsChange">
            </dd-filter-drawer>

            <dd-popup v-if="associationVisible" width="78%" height="80%" showBtn :saveBtn="false" :submitBtn="false"
                :show="associationVisible" title-name="关联" @popup-close="handleClose">
                <dd-button slot="button" size="medium" type="primary" @click="handleSubmit(1)">确认数据并提交</dd-button>
                <association ref="association" :pageProp="pageProp" slot="main"></association>
            </dd-popup>
        </div>
    </div>
</template>

<script>
import { postApi } from '@/api/request';
import association from './association.vue'
export default {
    name: 'contractPendingContract',
    $dynamicRoute: {
        name: "结算待关联",
        path: "/contract/contractPending/settlement",
    },
    components: { association },
    data() {
        return {
            associationVisible: false,
            loading: false,
            formList: [
                {
                    label: "金蝶合同编号",
                    value: "purchaseCode",
                    inputType: "text",
                    placeholderText:
                        "请输入金蝶合同编号进行搜索",
                },
                {
                    label: "金蝶合同名",
                    value: "purchaseName",
                    inputType: "text",
                    placeholderText:
                        "请输入金蝶合同名进行搜索",
                },
                {
                    label: "关联状态",
                    value: "relateState",
                    inputType: "select",
                    optionsList: [],
                    optionsListConfig: {
                        name: "name",
                        code: "code",
                    },
                    placeholderText:
                        "请选择关联状态",
                },
            ],
            pageProp: {},
            tableColumns: [
                {
                    title: "金蝶结算单编号",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                    width: 120,
                },
                {
                    title: "金蝶结算单名称",
                    key: "fname",
                    align: "center",
                    overflow: true,
                    width: 120,
                },
                {
                    title: "期间",
                    key: "fperiod",
                    align: "center",
                    overflow: true,
                }, {
                    title: "金蝶合同编号",
                    key: "purchaseCode",
                    align: "center",
                    overflow: true,
                    width: 110,
                },
                {
                    title: "金蝶合同名称",
                    key: "purchaseName",
                    align: "center",
                    overflow: true,
                    width: 110,
                }, {
                    title: "金蝶变更后合同总金额",
                    key: "purchaseChangeamount",
                    align: "center",
                    overflow: true,
                    width: 170,
                },
                {
                    title: "金蝶含税结算总金额",
                    key: "fseletleamount",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "金蝶合同乙方",
                    key: "fpartaname",
                    align: "center",
                    overflow: true,
                    width: 110,
                },
                {
                    title: "金蝶合同甲方",
                    key: "fpartbname",
                    align: "center",
                    overflow: true,
                    width: 110,
                },
                {
                    title: "金蝶合同关联类型",
                    key: "correlationType",
                    align: "center",
                    overflow: true,
                    width: 140,
                }, {
                    title: "已关联的结算金额",
                    key: "correlationAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "关联状态",
                    key: "relateState",
                    align: "center",
                    overflow: true,
                    width: 80,
                }, {
                    title: "关联操作人",
                    key: "applyname",
                    align: "center",
                    overflow: true,
                    width: 96,
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: 'right',
                    overflow: true,
                    event: [
                        {
                            state: "default",
                            label: "关联",
                            type: "primary",
                            width: 90,
                            method: (row) => {
                                this.associationContarct(row);
                            },
                        },
                    ],
                },
            ],
            drawerVisible: false,
            tableHeight: null,
            tableData: [],
            searchData: {
                relateState: null,
            },
            columnsList: [],
            // 当前页码
            current: 1,
            // 总条数
            total: 0,
            // 每页的数据条数
            size: 10,
        }
    },
    created() {
        this.getDataType();
        
    },
    mounted() {
        this.columnsList = [...this.tableColumns];
        this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
        });
    },
    methods: {
        getTableData() {
            postApi(`/contract/contractpurchaseinfosettle/page/${this.current}/${this.size}`, this.searchData).then(res => {
                if(res.data.code == 0) {
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total
                }
            })
        },
        tableRowClassName({row, rowIndex}) {
            if (row.correlationType == null) {
            return 'warning-row';
            } else {
            return '';
            }
        },
        associationContarct(row) {
            if(!row.correlationType) {
                this.$message.warning('请先判断定义所属金蝶合同的关联类型')
                return false
            }
            this.pageProp.id = row.id;
            this.pageProp.purchaseInfoId = row.purchaseInfoId;
            this.pageProp.fnumber = row.fnumber;
            this.pageProp.fname = row.fname;
            this.pageProp.fseletleamount = row.fseletleamount;
            this.pageProp.correlationAmount = row.correlationAmount;
            this.associationVisible = true;
        },
        handleSubmit() {
            if(this.$refs.association.tableData && this.$refs.association.tableData.length) {
                let flag = true;
                let arr = this.$refs.association.tableData;
                for(let i = 0 ; i < arr.length; i++) {
                    if((arr[i].allotmentAmount || 0) > ((arr[i].contractRelatedAmount|| 0) - (arr[i].relatedSettleAmount|| 0))) {
                        flag = false;
                        break;
                    }
                }
                let sums = 0;
                arr.map(item => { sums += Number(item.allotmentAmount) })
                if (sums > (Number(this.$refs.association.$refs.assignForm_formList.formData.fseletleamount))) {
                    this.$message.warning('关联结算金额之和，不能大于金蝶含税结算总金额')
                    return false
                }
                if(!flag) {
                    this.$message.warning('关联结算金额不能大于 合同关联金额-与本金蝶合同已关联的结算金额（即可关联结算金额限值）')
                    return false;
                }
            }
            let params = {
                settleRelations: this.$refs.association.tableData
            }
            postApi(`/contract/contractpurchaseinfosettle/settle/update`, params).then(res => {
                if(res.data.code == 0) {
                    this.$message.success('操作成功')
                }
                else {
                    this.$message.warning('操作失败，请稍后重试')
                }
                this.associationVisible = false;
                this.getTableData();
            })
        },
        handleClose() {
            this.associationVisible = false;
        },
        handleSearchEvent(e) {
            this.searchData = e;
            this.current = 1;
            this.getTableData();
        },
        handleColumnsChange(val) {
            let list = new Set();
            this.tableColumns.map((item) => {
                val.map((itm) => {
                    if (item.event || item.key == itm.key) {
                        list.add(item);
                    }
                });
            });
            this.columnsList = [...list];
        },
        getDataType() {
            postApi(`/contract/dicttype/list/all`, {code: 'PC-QTTODO-STATUS'}).then(res=> {
                if(res.data.code == 0) {
                    this.formList.forEach(item => {
                        if(item.value == 'relateState') {
                            item.optionsList = res.data.data
                        }
                    })
                    res.data.data.forEach(item => {
                        if(item.name == '待关联') {
                            this.formList[2].defaultValue = item.code;
                            this.searchData.relateState = item.code
                            this.$forceUpdate()
                        }
                    })
                    this.getTableData();
                }
            })
        },  
        handlePageChange(pageSize, pageNumber) {
            this.size = pageSize;
            this.current = pageNumber;
            this.getTableData()
        },
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-table {
    .warning-row {
        background: oldlace !important;
    }
}

</style>