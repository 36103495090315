<template>
    <div class="main-container">
      <dd-form labelWidth="100px" :form-config="formBasicList" :flex="2" ref="addPopForm">
      </dd-form>
    </div>
  </template>
    
  <script>
  export default {
    name: "add-pop",
    props: {
      dictType: [],
      dictKey: {
        type: String,
        default: null
      },
      rowData: []
    },
    data() {
      return {
        //项目列表
        project: [],
        formBasicList: [
          {
            label: "权限名称",
            value: "scopeName",
            inputType: "text",
            placeholderText: "请输入标识",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入标识",
                trigger: "blur",
              },
            ],
          },
          {
            label: "权限编号",
            value: "resourceCode",
            inputType: "text",
            placeholderText: "请输入数据值",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入标识",
                trigger: "blur",
              },
            ],
          },
          {
            label: "权限字段",
            value: "scopeColumn",
            disabled: false,
            defaultValue: '',
            inputType: "text",
            optionsList: [],
            filterable: false,
            placeholderText: "请选择权限字段",
            optionsListConfig: {
              name: "label",
              code: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择权限字段",
                trigger: "blur",
              },
            ],
          },
          {
            label: "规则类型",
            value: "scopeType",
            disabled: false,
            defaultValue: '',
            inputType: "select",
            optionsList: [],
            filterable: false,
            placeholderText: "请选择规则类型",
            optionsListConfig: {
              name: "label",
              code: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择规则类型",
                trigger: "blur",
              },
            ],
            change: (e) => {
              let obj = {
                label: "规则值",
                value: "scopeValue",
                inputType: "text",
                placeholderText: "请输入规则值",
                disabled: false,
                defaultValue: '',
                rules: [
                  {
                    required: true,
                    message: "请输入规则值",
                    trigger: "blur",
                  },
                ],
              }
              if(e == 5) {
                this.formBasicList.forEach((item) => {
                  item.defaultValue = this.$refs.addPopForm.formData[item.value]
                })
                this.formBasicList.push(obj)
              }else {
                this.formBasicList.forEach((item,idx) => {
                  item.defaultValue = this.$refs.addPopForm.formData[item.value]
                  if(item.value == 'scopeValue') {
                    this.formBasicList.splice(idx,1)
                  }
                })
              }
              
            }
          },
          {
            label: "可见字段",
            value: "scopeField",
            disabled: false,
            defaultValue: '',
            inputType: "text",
            optionsList: [],
            filterable: false,
            placeholderText: "请选择可见字段",
            optionsListConfig: {
              name: "label",
              code: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择可见字段",
                trigger: "blur",
              },
            ],
          },
          {
            label: "权限类名",
            value: "scopeClass",
            disabled: false,
            defaultValue: '',
            inputType: "text",
            optionsList: [],
            filterable: false,
            placeholderText: "请选择权限类名",
            optionsListConfig: {
              name: "label",
              code: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择权限类名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "备注",
            value: "remark",
            inputType: "text",
            placeholderText: "请输入备注",
            disabled: false,
            defaultValue: '',
          },
        ],
      };
    },
    created() { },
    mounted() {
      let options = {
        value: "scopeType",
        optionsList: this.dictType,
      };
      this.setOPtionsList(this.formBasicList, options);
      if (this.rowData != null) {
        if(this.rowData.scopeType == '5') {
          let obj = {
            label: "规则值",
            value: "scopeValue",
            inputType: "text",
            placeholderText: "请输入规则值",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入规则值",
                trigger: "blur",
              },
            ],
          }
          this.formBasicList.push(obj)
        }
        this.formBasicList.forEach(item => {
          if(item.value == "scopeType") {
            item.defaultValue = JSON.stringify(this.rowData[item.value])
          }else{
            item.defaultValue = this.rowData[item.value]
          }
        })
      }
    },
    methods: {
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
        formList.forEach((item) => {
          if (options) {
            if (item.value == options.value) {
              item.optionsList = options.optionsList;
            }
          }
        });
      },
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
    