<!--
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-08-10 17:10:22
 * @LastEditors: hechen
 * @LastEditTime: 2023-08-21 11:46:31
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-finance-ui\src\view\SystemManagement\params\components\AddPop.vue
 * @Descripttion: 
-->
<template>
    <div class="main-container">
        <dd-form :form-config="formBasicList" flex="2" ref="addPopForm">
        </dd-form>
    </div>
  </template>
  
  <script>
  export default {
    name: "add-pop",
    props: {
      dictType: [],
      paramType: [],
      statusType: [],
      rowData: []
    },
    data() {
      return {
        formBasicList: [
          {
            label: "名称",
            value: "publicName",
            inputType: "text",
            placeholderText: "请输入名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "键",
            value: "publicKey",
            inputType: "text",
            placeholderText: "请输入键",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入键",
                trigger: "blur",
              },
            ],
          },
          {
            label: "值",
            value: "publicValue",
            inputType: "text",
            placeholderText: "请输入值",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入值",
                trigger: "blur",
              },
            ],
          },
          {
            label: "编码",
            value: "validateCode",
            inputType: "text",
            placeholderText: "请输入编码",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "参数类型",
            value: "systemFlag",
            inputType: "select",
            optionsList: [],
            filterable: false,
            placeholderText: "请选择参数类型",
            optionsListConfig: {
              name: "label",
              code: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择参数类型",
                trigger: "blur",
              },
            ],
            title: true,
          },
          {
            label: "状态",
            value: "status",
            inputType: "select",
            optionsList: [],
            filterable: false,
            placeholderText: "请选择状态",
            optionsListConfig: {
              name: "label",
              code: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "blur",
              },
            ],
            title: true,
          },
          {
            label: "类型",
            value: "publicType",
            inputType: "select",
            optionsList: [],
            filterable: false,
            defaultValue: '',
            placeholderText: "请选择类型",
            optionsListConfig: {
              name: "label",
              code: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择类型",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    created() {},
    mounted() {
        if(this.rowData != null) {
            this.formBasicList.forEach(item => {
                item.defaultValue = this.rowData[item.value]
            })
        }
        let options1 = {
            value: "systemFlag",
            optionsList: this.dictType,
        };
        let options2 = {
            value: "publicType",
            optionsList: this.paramType,
        };
        let options3 = {
            value: "status",
            optionsList: this.statusType,
        };
        this.setOPtionsList(this.formBasicList, options1);
        this.setOPtionsList(this.formBasicList, options2);
        this.setOPtionsList(this.formBasicList, options3);
    },
    methods: {
      change() {
        this.$forceUpdate();
      },
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.optionsList = options.optionsList;
                    }
                }
            });
        },
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
  