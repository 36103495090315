<template>
  <div class="main-container">
    <dd-card title="基本信息">
      <dd-form :form-config="formBasicList" :flex="3"> </dd-form>
    </dd-card>
    <div class="bottom-box">
      <div class="left-box">
        <dd-card title="已选人员列表">
          <dd-table
            :columns="leftTableColumns"
            :data="leftTableData"
            height="470"
            border
            ref="dLeftTableRef"
            :isSelection="!!leftTableData.length"
            show-page
            @select-change="handleSelectionChange($event, 1)"
          >
            <template #table-btn>
              <dd-button
                type="danger"
                icon="delete"
                size="medium"
                @click="handleDelete"
                >删除</dd-button
              >
            </template>
          </dd-table>
        </dd-card>
      </div>
      <div class="right-box">
        <div class="head_wrap">
          <dd-card title="条件搜索" spacer>
            <dd-search-form
              :form-config="formList"
              @search-click="handleSearchEvent"
              ref="ddSearchForm"
            ></dd-search-form>
          </dd-card>
        </div>
        <dd-card title="人员列表" :height="tableHeight">
          <dd-table
            :columns="tableColumns"
            :data="tableData"
            height="340"
            border
            ref="dTableRef"
            show-page
            @select-change="handleSelectionChange($event, 2)"
          >
            <template #table-btn>
              <dd-button type="primary" size="medium" @click="handleSubmit"
                >提交</dd-button
              >
            </template>
            <template #table-pagination>
              <dd-pagination
                :total="total"
                :page-size="size"
                :page-number="current"
                @page-change="handlePageChange"
                align="right"
              >
              </dd-pagination>
            </template>
          </dd-table>
        </dd-card>
      </div>
    </div>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";
export default {
  props: {
    row: {},
  },
  components: {},
  computed: {},
  data() {
    return {
      tableHeight: null,
      loading: false,
      leftLoading: false,
      formData: {},
      formBasicList: [
        {
          label: "部门名称",
          value: "deptName",
          inputType: "text",
          disabled: true,
          defaultValue: this.row.deptName,
        },
        {
          label: "子部门名称",
          value: "name",
          inputType: "text",
          disabled: true,
          defaultValue: this.row.name,
        },
        {
          label: "子部门编码",
          value: "code",
          inputType: "text",
          disabled: true,
          defaultValue: this.row.code,
        },
      ],
      formList: [
        {
          label: "员工姓名",
          value: "userName",
          inputType: "text",
          placeholderText: "请输入员工姓名",
        },
        {
          label: "员工工号",
          value: "userCode",
          inputType: "text",
          placeholderText: "请输入员工工号",
        },
         {
          label: "子部门",
          value: "id",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索子部门名称",
          filterable: true,
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
      ],
      tableColumns: [
        {
          title: "工号",
          key: "userCode",
          align: "center",
        },
        {
          title: "姓名",
          key: "userName",
          align: "center",
        },
        {
          title: "子部门",
          key: "name",
          align: "center",
        },
      ],
      leftTableColumns: [
        {
          title: "工号",
          key: "username",
          align: "center",
        },
        {
          title: "姓名",
          key: "fullName",
          align: "center",
        },
      ],
      tableData: [],
      leftTableData: [],
      current: 1,
      leftCurrent: 1,
      size: 20,
      leftSize: 999,
      total: null,
      rowData: null,
      poptitleName: "新增",
      selection: [],
      dictType: [],
      multipleSelection: [],
      leftMultipleSelection: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getTableData();
    this.getLeftTableData();
    this.getSubDeptList()
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 表格多选事件
    handleSelectionChange(val, type) {
      if (type == 1) {
        this.leftMultipleSelection = val;
      } else {
        this.multipleSelection = val;
      }
    },
     //获取子部门列表
    getSubDeptList() {
      postApi(`admin/sysdeptbranch/list`, {
        deptId:this.row.deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          if (data.code == 0) {
            this.formList.forEach((item) => {
              if (item.value == "id") {
                item.optionsList = data.data;
              }
            });
          }
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        deptId: this.row.deptId,
        current,
        size,
      };
      this.loading = true;
      getApi(`/admin/sysdeptbranch/userPage`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    getLeftTableData() {
      let params = {
        deptBranchId: this.row.id,
      };
      this.leftLoading = true;
      getApi(`/admin/user/getuserlistbysysdeptbranch`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.leftLoading = false;
          this.leftTableData = data.data;
        }
      });
    },
    handleTableSelect(selection) {
      this.selection = selection;
    },
    //删除
    handleDelete() {
      let { leftMultipleSelection } = this;
      if (!leftMultipleSelection.length) {
        this.$message.warning("请至少选择一条数据");
        return;
      }
      let userIdList = [];
      leftMultipleSelection.map((item) => {
        userIdList.push(item.userId);
      });
      let params = {
        id: this.row.id,
        userIdList,
      };
      postApi(`/admin/sysdeptbranch/deleteuserdeptbranch`, params).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.$refs.dLeftTableRef.$refs.baseTable.clearSelection();
            this.$refs.dTableRef.$refs.baseTable.clearSelection();
            this.getLeftTableData();
            this.getTableData();
          }
        }
      );
    },
    //提交
    handleSubmit() {
      let { multipleSelection } = this;
      if (!multipleSelection.length) {
        this.$message.warning("请至少选择一条数据");
        return;
      }
      let userIdList = [];
      multipleSelection.map((item) => {
        userIdList.push(item.userId);
      });
      let params = {
        id: this.row.id,
        userIdList,
      };
      postApi(`/admin/sysdeptbranch/adduserdeptbranch`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          this.$refs.dLeftTableRef.$refs.baseTable.clearSelection();
          this.$refs.dTableRef.$refs.baseTable.clearSelection();
          this.getLeftTableData();
          this.getTableData();
        }
      });
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handlePageChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  .bottom-box {
    display: flex;
    justify-content: space-between;
  }
  /deep/ .el-table__header-wrapper .gutter {
    height: 40px !important;
  }
  .left-box {
    width: 23%;
    /deep/ .has-gutter {
      .el-table-column--selection {
        .cell {
          display: none;
        }
      }
    }
  }
  .right-box {
    width: 77%;
  }
  /deep/ .search-form-input {
    width: 33% !important;
  }
}
</style>
