<template>
    <div class="main-container" style="padding: 5px 10px 10px 10px">
        <iframe id="iframeContainer" class="auto_size" :src="modelUrl" frameborder="0"></iframe>
    </div>
  </template>
    
  <script>
  export default {
    name: "activitiDetail",
    $dynamicRoute: {
        name: "模型图",
        path: "/activiti/detail/:id",
    },
    components: {
    },
    computed: {
    },
    data() {
        return {
            modelUrl: null
        };
    },
    created() { 
        this.modelUrl = `${this.$activitiUrl}/activiti/modeler.html?modelId=${this.$route.params.id}`;
    },
    watch: {
        $route(to, from) {
            this.modelUrl = `${this.$activitiUrl}/activiti/modeler.html?modelId=${to.params.id}`;
            this.listenerPageLoad();
        }
    },
    mounted() {
        this.listenerPageLoad();
    },
    methods: {
        listenerPageLoad() {
            let _this = this;
            this.$nextTick(() => {
                _this.$showLoading()
                const iframe = document.getElementById('iframeContainer');
                if (iframe.attachEvent) {
                    iframe.attachEvent('onload', function () {
                        _this.$hideLoading();
                    })
                } else {
                    iframe.onload = function () {
                        _this.$hideLoading();
                    }
                }
            })
        }
    },
  };
  </script>
    
  <style lang='less' scoped>
  </style>