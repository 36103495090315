<template>
  <div>
    <dd-card title="基本信息" spacer>
      <dd-form
        :form-config="formList"
        ref="addPopForm"
        labelWidth="160px"
        :flex="3"
      ></dd-form>
    </dd-card>
    <dd-card title="需汇总的核算单">
      <dd-table
        max-height="360"
        ref="Table"
        :columns="tableColumns"
        :data="tableDataSources"
        :loading="loading"
        border
        :isEdit="true"
        :isSelection="true"
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            v-if="isEditf == false"
            type="primary"
            size="medium"
            icon="add"
            @click="handleExpenseApply"
            >添加</dd-button
          >
          <dd-button
            type="danger"
            size="medium"
            icon="delete"
            @click="handleDelete"
            >移除</dd-button
          >
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 添加 -->
    <dd-popup
      v-if="dialogVisible"
      width="60%"
      height="60%"
      :inPopup="true"
      :showBtn="true"
      :title-name="poptitleName"
      :show="dialogVisible"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <listShow
        slot="main"
        ref="addPop"
        :screening="screening"
        @select_list="select_list"
      ></listShow>
    </dd-popup>
  </div>
</template>

<script>
import { getNowMonth } from "@/utils/date";
import { postApi, getApi } from "@/api/request";
import listShow from "./listShow.vue";
export default {
  name: "add-pop",
  components: {
    listShow,
  },
  props: {
    rowData:{
      type: Object,
      default: null,
    },
    isEditf:false,
  },
  data() {
    return {
      depList: [],
      projectList: [],
      loading: false,
      formList: [
        {
          label: "分管部门",
          value: "fenDeptId",
          inputType: "select",
          optionsList: [],
          defaultValue:'',
          filterable: true,
          disabled:false,
          placeholderText: "分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择分管部门",
              trigger: "blur",
            },
          ],
          change:this.selectFenDeptId
        },
        {
          label: "所属年月",
          value: "reportMonth",
          placeholderText: "所属年月",
          inputType: "date",
          dateType: "month",
          disabled:false,
          format: "yyyy-MM",
          defaultValue: getNowMonth(),
          rules: [
            { required: true, message: "请选择所属年月", trigger: "blur" },
          ],
        },
      ],
      tableColumns: [
        {
          title: "单据名称",
          key: "name",
          align: "center",
        },
        {
          title: "部门",
          key: "deptName",
          align: "center",
        },
        // {
        //   title: "绩效核算组",
        //   key: "nodeName",
        //   align: "center",
        // },
        {
          title: "所属年月",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "开始时间点",
          key: "startTime",
          align: "center",
        },
        {
          title: "截止时间点",
          key: "deadLine",
          align: "center",
        },
        {
          title: "统计工作量类型",
          key: "standardWorkpackageType",
          align: "center",
        },
        {
          title: "状态",
          key: "statusName",
          align: "center",
        },
      ],
      tableDataSources: [],
      // 表格选择数据集合
      multipleSelection: [],
      //默认选择状态
      filterSelection: [],
      checked: false,
      current: 1,
      size: 20,
      total: null,
      dialogVisible:false,
      poptitleName:'',
      showBtn:true,
      screening:{},
      selectList:[],
    };
  },
  mounted() {
    console.log("this.isEditf",this.isEditf)
    this.getDataType();
    if (this.rowData) {
      if(this.rowData.status == 'ACCOUNTING-SUM-STATE-ZC'){
        this.tableColumns.push(
          {
            title: "操作",
            key: "file",
            align: "center",
            width: 80,
            event: [
              {
                label: "移除",
                type: "danger",
                method: (row) => {
                  this.handleRemove(row);
                },
              },
            ],
          }
        );
      }else{
        // this.formList.forEach((item) => {
        //   item.disabled = true;
        // });
      }
      if(this.isEditf){
        this.formList.forEach((item) => {
          item.disabled = true;
        });
      }
      this.getDetail(this.rowData.id);
    }
  },
  methods: {
    selectFenDeptId(){
      this.tableDataSources = [];
    },
    // 编辑回显详情查询
    getDetail(id) {
      getApi(`/project/accountingsum/${id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            item.defaultValue = data.data[item.value];
          });
          this.tableDataSources = data.data.list;
        }
      });
    },
    // 选择的数据
    select_list(data){
      this.selectList = data;
    },
    //选择事件---请求加载核算单列表
    getTableData() {
      let { current, size } = this;
      let params = {
        status: "PRJ_ASSIGN_PERFORMANCE_ACCOUNTING_YQR",
      };
      postApi(
        `/project/performanceaccounting/page/${current}/${size}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data;
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取下拉选择数据
    getDataType() {
      // 获取分管部门
      getApi(`/admin/sysfendept/getList`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "fenDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    //单行删除
    handleRemove(row) {
      this.filterSelection = [];
      this.getDelete(row);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit('select_list',val)
    },
    //全选事件
    selectAll() {
      this.checked = !this.checked;
      this.handleSelect(this.tableDataSources, this.checked);
    },
    //全选事件选中状态
    handleSelect(data, flag) {
      data.forEach((row) => {
        this.$refs.moreTable.$refs.moreTable.toggleRowSelection(row, flag);
        if (row.children) {
          this.handleSelect(row.children);
        }
      });
    },
    //批量删除事件
    handleDelete() {
      let deleteSelection = this.multipleSelection;
      if (deleteSelection.length < 1) {
        this.$message.warning("请至少选择一条数据!");
      } else {
        this.handleConfirmDelete();
      }
    },
    //是否确认删除
    handleConfirmDelete() {
      this.filterSelection = [];
      //批量删除费用明细
      this.multipleSelection.map((item) => {
        this.getDelete(item);
      });
      //清除选择状态
      this.multipleSelection = [];
      this.$refs.Table.$refs.baseTable.clearSelection();
    },
    //删除费用报账单费用明细数据
    getDelete(data) {
      this.tableDataSources.map((item, index) => {
        if (item.id == data.id) {
          this.tableDataSources.splice(index, 1);
        } else {
          this.filterSelection.push(item);
        }
      });
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 添加弹窗
    handleExpenseApply(){
      let par = this.$refs.addPopForm.formData
      if(par.fenDeptId == ''){
        return this.$message.warning('分管部门不能为空');
      }
      else if(par.reportMonth == null){
        return this.$message.warning('所属年月不能为空');
      }
      this.dialogVisible = true;
      this.poptitleName = "新增";
      this.screening = this.$refs.addPopForm.formData;
      // this.tableDataSources = [];
    },
    // 弹框关闭事件
    handleClose() {
      this.dialogVisible = false;
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      if(this.selectList && this.selectList.length <= 0){
        this.selectList = this.$refs.addPop.$refs.addPopForm.$attrs.data
      }
      if(this.selectList && this.selectList.length <= 0){
        return this.$message.warning('汇总的核算单不能为空');
      }
      this.tableDataSources = this.selectList;
      this.selectList = [];
      this.handleClose();
    },
  },
};
</script>

<style></style>
