<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowData: [],
  },
  data() {
    return {
      formBasicList: [
        // {
        //   label: "编号",
        //   value: "code",
        //   inputType: "text",
        //   optionsList: [],
        //   filterable: false,
        //   placeholderText: "请输入编号",
        // },
        {
          label: "模板控制模式",
          value: "produceType",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择模板控制模式",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择模板控制模式",
              trigger: "blur",
            },
          ],
          change: this.changeModelType,
        },
        {
          label: "模板名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入模板名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入模板名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "应用部门",
          value: "deptList",
          inputType: "select",
          optionsList: [],
          filterable: true,
          multiple: true,
          placeholderText: "请选择下发部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: true,
              message: "请输入应用部门",
              trigger: "blur",
            },
          ],
        },
        {
          label: "排序",
          value: "sortOrder",
          inputType: "number",
          min: 0,
          max: 999,
          placeholderText: "请输入排序",
          disabled: false,
          defaultValue: "",
        },
        {
          label: "备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入备注",
          disabled: false,
          defaultValue: "",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getDeptList();
    this.getDataType();
    if (this.rowData != null) {
      this.getDataDetail(this.rowData.id);
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getDataType() {
      // 生产控制模式
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-SCKZMS" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "produceType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptList",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    changeModelType(e) {
      if (e == "PRJ-SCKZMS-WBSMS") {
        let formList = this.formBasicList;
        for (let i = 0; i < formList.length; i++) {
          if (formList[i].value == "produceType") {
            formList[i].defaultValue = e;
          }
          if (formList[i].value == "prjAreaNodeIdList") {
            formList.splice(i, 1);
            i--;
          }
        }
      } else {
        let obj = {
          label: "WBS简易模式标准节点",
          value: "prjAreaNodeIdList",
          inputType: "select",
          optionsList: [],
          filterable: false,
          multiple: true,
          placeholderText: "请选择WBS简易模式标准节点",
          optionsListConfig: {
            name: "nodeName",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择WBS简易模式标准节点",
              trigger: "blur",
            },
          ],
        };
        this.formBasicList.forEach((item, index) => {
          if (item.value == "produceType") {
            item.defaultValue = e;
          }
        });
        this.formBasicList.splice(3, 0, obj);
        getApi(`/project/prjareanode/list`).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            let options = {
              value: "prjAreaNodeIdList",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        });
      }
    },
    // 获取详情
    getDataDetail(id) {
      getApi(`/project/prjwbsbasetemplate/${id}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.changeModelType(data.data.produceType);
          this.formBasicList.forEach((item) => {
            if (item.value == "prjAreaNodeIdList") {
              let arr = [];
              data.data.prjAreaNodeList.forEach((val) => {
                arr.push(val.id);
              });
              item.defaultValue = arr;
            } else {
              item.defaultValue = data.data[item.value];
            }
          });
        }
      });
    },
    // 查询部门
    getDeptList(){
      let params = {
        orgId: sessionStorage.getItem("orgId")
      };
      postApi(`/admin/dept/getdeptlistbyorgid`,params).then((res) => {
        let { data } = res;
        console.log(1111,data)
        if (data && data.code == 0) {
          this.options = {
            value: "dept",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, this.options);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
