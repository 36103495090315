<template>
  <div class="main-container">
    <div class="select-info">
      <dd-form
        :form-config="formBasicList"
        :flex="flex"
        ref="addAreaPopForm"
      ></dd-form>
      <div class="select-info-button" v-if="type == 'add' || type == 'update'">
        <dd-button type="primary" size="medium" icon="add" @click="handleAdd"
          >自定义范围</dd-button
        >
        <dd-button
          type="primary"
          size="medium"
          icon="delete"
          @click="handleClear"
          >清除范围</dd-button
        >
      </div>
    </div>
    <div id="map"></div>
  </div>
</template>
  
<script>
import Map from "ol/Map"; //地图
import View from "ol/View"; //视图
import { Draw } from "ol/interaction";
import { Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import { Polygon, Point } from "ol/geom";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import { Style, Fill, Circle, Stroke, Text } from "ol/style";
import Select from "ol/interaction/Select";
import * as Layer from "/static/js/layer2D.js";
import { getApi, postApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    type: "",
    id: "",
  },
  data() {
    return {
      flex: 3,
      addPopForm: "addAreaPopForm",
      formBasicList: [
        {
          label: "省",
          value: "province",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "name",
            name: "value",
          },
          defaultValue: "",
          disabled: this.type == "view" ? true : false,
          change: this.handleChange,
          placeholderText: "请选择考勤范围",
          rules: [
            {
              required: true,
              message: "请选择省",
              trigger: "blur",
            },
          ],
        },
        {
          label: "市",
          value: "city",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "name",
            name: "value",
          },
          disabled: this.type == "view" ? true : false,
          defaultValue: "",
          placeholderText: "请选择考勤范围",
          change: this.handleChange,
          rules: [
            {
              required: true,
              message: "请选择市",
              trigger: "blur",
            },
          ],
        },
        {
          label: "区",
          value: "county",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "name",
            name: "value",
          },
          disabled: this.type == "view" ? true : false,
          defaultValue: "",
          change: this.handleChange,
          placeholderText: "请选择考勤范围",
          rules: [
            {
              required: true,
              message: "请选择区",
              trigger: "blur",
            },
          ],
        },
        {
          label: "考勤类型",
          value: "locationType",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "code",
            name: "name",
          },
          disabled: this.type == "view" ? true : false,
          change: this.handleChange,
          placeholderText: "请选择考勤类型",
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请选择考勤类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "区域名称",
          value: "areaname",
          inputType: "text",
          placeholderText: "请输入区域名称",
          defaultValue: "",
          disabled: this.type == "view" ? true : false,
          rules: [
            {
              required: true,
              message: "请输入区域名称",
              trigger: "blur",
            },
          ],
        },
      ],
      current: 1,
      size: 15,
      map: "",
      selectSingleClick: "",
      draw: null,
      lngAndLatStr: "",
      originLngAndLatStr: "",
      //自定义范围
      vectorLayer: new VectorLayer({
        source: new VectorSource(),
      }),
      vectorSource: new VectorSource(),
      //行政区范围
      regionLayer: new VectorLayer({
        zIndex: 9999,
        source: new VectorSource(),
      }),
      regionSource: new VectorSource(),
    };
  },
  created() {},
  mounted() {
    this.getDistrictData("000000", "province");
    this.getTypeList();
    this.initMap(); //地图初始化（默认加载天地图底图）
    if (this.type == "update" || this.type == "view") {
      this.getRegionInfo();
    }
  },
  watch: {},
  methods: {
    initMap() {
      this.map = new Map({
        target: "map",
        layers: Layer.tdtLayer(),
        view: new View({
          center: [108.948024, 34.263161],
          projection: "EPSG:4326",
          zoom: 12.5,
          minZoom: 5,
          units: "metric",
        }),
      });
    },
    getDistrictData(code, type) {
      getApi(`/admin/region/map/${code}`).then((res) => {
        let { data } = res;
        this.formBasicList.forEach((item) => {
          if (item.value == type) {
            let lists = data.data;
            let list = [];
            for (let itm in lists) {
              list.push({
                name: itm,
                value: lists[itm],
              });
            }
            item.optionsList = list;
          }
        });
      });
    },
    getTypeList() {
      let params = {
        typeCode: "attend-location",
      };
      postApi(`/hr/hrdict/list?typeCode=${params.typeCode}`, params).then(
        (res) => {
          this.formBasicList.forEach((item) => {
            let { data } = res;
            if (item.value == "locationType") {
              item.optionsList = data.data;
            }
          });
        }
      );
    },
    getDistrict(code, name) {
      let key = "b268e62eb07566b14ffd28c142db4cc5"; //高德底图Key
      let keyword = name;
      let countyCode = code;
      let url =
        "http://restapi.amap.com/v3/config/district?key=" +
        key +
        "&keywords=" +
        keyword +
        "&filter=" +
        countyCode +
        "&subdistrict=1&extensions=all";
      getApi(url, {}, {}).then((res) => {
        this.clearLayer(this.regionLayer); //清除行政区
        this.clearLayer(this.vectorLayer); //清除范围
        let response = res.data.districts[0];
        let center = response.center;
        let code = response.adcode;
        center = center.split(",");
        let name = response.name;
        let level = response.level;
        let data = response.polyline;
        data = data.split("|");
        data.forEach((polyline) => {
          polyline = polyline.split(";");
          let polygon = [];
          polyline.forEach((item) => {
            let point = item.split(",");
            polygon.push(point);
          });

          let feature = new Feature({
            geometry: new Polygon([polygon]),
            name: name,
            code: code,
            level: level,
          });
          feature.setStyle(this.polygonStyle());
          this.regionLayer.getSource().addFeature(feature);
        });
        // this.loadPoint(center, name);
        this.map.addLayer(this.regionLayer);
        this.fitLayer(this.regionLayer);
      });
    },
    // 加载地名和点
    loadPoint(point, text) {
      let feature = new Feature({
        geometry: new Point(point),
      });
      feature.setStyle(
        new Style({
          text: new Text({
            text: text,
            stroke: new Stroke({
              color: "rgba(29,233,182,0)",
            }),
            fill: new Fill({
              color: "rgba(29,233,182,1)",
            }),
            textAlign: "center",
            textBaseline: "bottom",
          }),
        })
      );
      this.vectorLayer.getSource().addFeature(feature);
    },
    // polygon样式
    polygonStyle() {
      return new Style({
        stroke: new Stroke({
          color: `rgba(3,199,213,1)`,
          width: 1,
        }),
        fill: new Fill({
          color: `rgba(19,44,71,0.5)`,
        }),
      });
    },
    // 地图自适应图层所在元素的范围
    fitLayer(layer) {
      let extent = layer.getSource().getExtent();
      extent[0] = extent[0] - 0.01;
      extent[1] = extent[1] - 0.01;
      extent[2] = extent[2] + 0.01;
      extent[3] = extent[3] + 0.01;
      this.map.getView().fit(extent);
    },
    // 清除图层
    clearLayer(layer) {
      this.map.removeLayer(layer);
      layer.getSource().clear();
    },
    handleChange(e, itm) {
      let code = e;
      if (itm == "province") {
        this.getDistrictData(code, "city");
      } else if (itm == "city") {
        this.getDistrictData(code, "county");
      }
      this.formBasicList.forEach((item) => {
        if (item.value == itm) {
          item.defaultValue = code;
          if (item.value == "county") {
            let list = item.optionsList;
            list.map((im) => {
              if (im.name == code) {
                this.getDistrict(code, im.value);
              }
            });
          }
        }
      });
    },
    //编辑回显
    getRegionInfo() {
      getApi(`/hr/attend/baseinfo/${this.id}`).then((res) => {
        let { data } = res;
        let provinceCode = data.data.province;
        let cityCode = data.data.city;
        let jsonstr = data.data.lngAndLatStr;

        let headstr =
          "{" +
          '"type"' +
          ":" +
          '"FeatureCollection"' +
          "," +
          '"totalFeatures"' +
          ":1," +
          '"features"' +
          ":[";
        let tailstr = "]}";
        this.lngAndLatStr = headstr + jsonstr + tailstr;
        this.lngAndLatStr = jsonstr.replace("'", '"');
        this.getDistrictData(provinceCode, "city");
        this.getDistrictData(cityCode, "county");
        this.formBasicList.forEach((item) => {
          item.defaultValue = data.data[item.value];
        });
        this.viewRegionInfo(jsonstr);
      });
    },
    //回显打卡范围
    viewRegionInfo(json) {
      this.vectorSource.addFeatures(new GeoJSON().readFeatures(json));
      this.vectorLayer = new VectorLayer({
        zIndex: 100,
        source: this.vectorSource,
        style: new Style({
          fill: new Fill({
            color: "rgba(0, 102, 102, 0.3)",
          }),
          stroke: new Stroke({
            color: "#fc5531",
            lineDash: [10, 10],
            width: 3,
          }),
        }),
      });
      this.map.addLayer(this.vectorLayer);
      if (json != "") {
        this.fitLayer(this.vectorLayer);
      }
    },
    //自定义范围
    handleAdd() {
      this.clearLayer(this.regionLayer); //清除之前加载的行政区矢量
      this.clearLayer(this.vectorLayer); //清除范围矢量
      // 矢量图层
      this.vectorLayer = new VectorLayer({
        zIndex: 101,
        source: this.vectorSource,
        style: new Style({
          fill: new Fill({
            color: "rgba(0, 102, 102, 0.3)",
          }),
          stroke: new Stroke({
            color: "#006666",
            width: 3,
          }),
          image: new Circle({
            radius: 0,
            fill: new Fill({
              color: "#fc5531",
            }),
          }),
        }),
        name: "范围图层",
      });
      this.map.addLayer(this.vectorLayer);
      this.draw = new Draw({
        source: this.vectorSource,
        type: "Polygon",
      });

      this.map.addInteraction(this.draw);
      this.draw.on("drawend", (e) => {
        this.lngAndLatStr = "";
        let json = new GeoJSON().writeFeature(e.feature);
        //json转对象
        let obj = JSON.parse(json);
        //对象转json
        let jsonStr = JSON.stringify(obj.geometry);
        this.lngAndLatStr = jsonStr;
        this.map.removeInteraction(this.draw); // 移除绘制组件
        this.draw = null;
      });
    },
    //清除范围
    handleClear() {
      this.clearLayer(this.regionLayer); //清除之前加载的行政区矢量
      this.clearLayer(this.vectorLayer); //清除范围矢量
    },
  },
};
</script>
<style lang='less' scoped>
.main-container {
  border: 1px solid #ccc;
  /deep/ .base-form {
    margin-left: 10px;
    margin-right: 20px;
  }
  .select-info-button {
    position: absolute !important;
    top: 11%;
    right: 2.5%;
  }
  #map {
    margin-top: 10px;
    width: 96.5%;
    height: 78%;
    margin-left: 20px;
    border: 1px solid #ccc;
  }
}
</style>
  