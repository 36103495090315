import { render, staticRenderFns } from "./contractDetailZj.vue?vue&type=template&id=033ba002&scoped=true"
import script from "./contractDetailZj.vue?vue&type=script&lang=js"
export * from "./contractDetailZj.vue?vue&type=script&lang=js"
import style0 from "./contractDetailZj.vue?vue&type=style&index=0&id=033ba002&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "033ba002",
  null
  
)

export default component.exports