<template>
    <div class="main-container">
        <dd-form v-show="formVisible" :flex="2" :form-config="formBasicList" ref="addPopForm">
        </dd-form>
    </div>
  </template>
  
  <script>
  import { getApi, delApi, postApi, putApi, postfromApi } from "@/api/request";
  export default {
    name: "add-pop",
    props: {
      dictType: [],
      roleTree: [],
      orgTree: [],
      userType: [],
      rowData: []
    },
    data() {
      return {
        formBasicList: [
          {
            label: "工号/账号",
            value: "username",
            inputType: "text",
            placeholderText: "请输入工号/账号",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入工号/账号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "姓名",
            value: "fullName",
            inputType: "text",
            placeholderText: "请输入姓名",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入姓名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "昵称",
            value: "nick",
            inputType: "text",
            placeholderText: "请输入昵称",
            disabled: false,
            defaultValue: '',
          },
          {
            label: "邮箱",
            value: "email",
            inputType: "text",
            placeholderText: "请输入邮箱",
            disabled: false,
            defaultValue: '',
          },
          {
            label: "电话",
            value: "phone",
            inputType: "text",
            placeholderText: "请输入手机号",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入手机号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "用户类型",
            value: "typeId",
            inputType: "select",
            optionsList: [],
            filterable: false,
            placeholderText: "请选择用户类型",
            optionsListConfig: {
              name: "label",
              code: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择用户类型",
                trigger: "blur",
              },
            ],
          },
          {
            label: "所属机构",
            value: "orgId",
            inputType: "cascader",
            optionsList: [],
            prop: {
              value: "id",
              label: "name",
              checkStrictly: true,
              emitPath: false
            },
            placeholderText: "请选择所属机构",
            showAllSelcet: false,
            rules: [
              {
                required: true,
                message: "请选择所属机构",
                trigger: "blur",
              },
            ],
            change: (e,refName) => {
              this.$refs.addPopForm.$refs[refName][0].dropDownVisible = false;
              this.getDeptTree(e);
            }
          },
          {
            label: "所属部门",
            value: "deptId",
            inputType: "select",
            optionsList: [],
            filterable: true,
            placeholderText: "请选择所属部门",
            optionsListConfig: {
              name: "name",
              code: "deptId",
            },
            rules: [
              {
                required: true,
                message: "请选择所属部门",
                trigger: "blur",
              },
            ],
            change: (e) => {
              this.getPostTree(e);
            }
          },
          {
            label: "所属岗位",
            value: "postId",
            inputType: "select",
            optionsList: [],
            filterable: false,
            multiple: false,
            placeholderText: "请选择岗位",
            optionsListConfig: {
              name: "postName",
              code: "postId",
            },
            rules: [
              {
                required: true,
                message: "请选择所属岗位",
                trigger: "blur",
              },
            ],
          },
          {
            label: "次要岗位",
            value: "post",
            inputType: "select",
            optionsList: [],
            filterable: false,
            multiple: true,
            placeholderText: "请选择岗位",
            optionsListConfig: {
              name: "postName",
              code: "postId",
            },
          },
          {
            label: "角色",
            value: "role",
            inputType: "select",
            optionsList: [],
            filterable: false,
            multiple: true,
            placeholderText: "请选择角色",
            optionsListConfig: {
              name: "roleName",
              code: "roleId",
            },
            rules: [
              {
                required: true,
                message: "请选择角色",
                trigger: "blur",
              },
            ],
          },
          {
            label: "状态",
            value: "lockFlag",
            inputType: "select",
            optionsList: [],
            filterable: false,
            placeholderText: "请选择状态",
            optionsListConfig: {
              name: "label",
              code: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "blur",
              },
            ],
          }
        ],
        deptTree: [],
        postTree: [],
        formVisible: true
      };
    },
    created() {
    },
    mounted() {
      let options1 = {
            value: "lockFlag",
            optionsList: this.dictType,
        };;
        let options3 = {
            value: "role",
            optionsList: this.roleTree,
        };
        let options5 = {
            value: "typeId",
            optionsList: this.userType,
        };
        let options6 = {
            value: "orgId",
            optionsList: this.orgTree,
        };
        this.setOPtionsList(this.formBasicList, options1);
        this.setOPtionsList(this.formBasicList, options3);
        this.setOPtionsList(this.formBasicList, options5);
        this.setOPtionsList(this.formBasicList, options6);
        if(this.rowData != null) {
          this.formVisible = false;
            this.formBasicList.forEach(item => {
                item.defaultValue = this.rowData[item.value]
                if(item.value == 'role'){
                  let arr = [];
                  this.rowData.roleList.forEach(val => {
                    arr.push(val.roleId);
                  })
                  item.defaultValue = arr;
                }
                if(item.value == 'post'){
                  let arr = [];
                  this.rowData.postList.forEach(val => {
                    arr.push(val.postId);
                  })
                  item.defaultValue = arr;
                }
            })
            this.getDeptTree(this.rowData.orgId);
            this.getPostTree(this.rowData.deptId);
        }
    },
    methods: {
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.optionsList = options.optionsList;
                    }
                }
            });
        },

      getDeptTree(id) {
        let params = {
          orgId: id
        }
          postApi(`/admin/dept/getdeptlistbyorgid`,params).then((res) => {
              let { data } = res;
              if (data && data.code == 0 && data.data) {
                this.formBasicList.forEach((item) => {
                  if(item.value == "orgId") {
                    item.defaultValue = id
                  }else {
                    item.defaultValue = this.$refs.addPopForm.formData[item.value]
                  }
                  if(item.value == "deptId") {
                    item.optionsList = data.data
                  }
                })
              }
          });
      },
      getPostTree(id) {
        let params = {
          deptId: id
        }
          postApi(`/admin/post/getpostlistbydeptid`,params).then((res) => {
              let { data } = res;
              if (data && data.code == 0 && data.data) {
                this.formBasicList.forEach((item) => {
                  if(item.value == "deptId") {
                    item.defaultValue = id
                  }
                  if(item.value == "postId") {
                    item.optionsList = data.data
                  }
                  if(item.value == "post") {
                    item.optionsList = data.data
                  }
                })
                this.formVisible = true;
              }
          });
      }
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
  