<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
    <dd-card title="工作量公示信息" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        :isSelection="false"
        show-page
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <!-- 新增/修改/详情-->
    <dd-popup
      v-if="dialogVisible"
      width="70%"
      height="80%"
      :showBtn="showBtn"
      :saveBtn="false"
      submitBtn
      :title-name="popTitle"
      :show="dialogVisible"
      :inPopup="true"
      @popup-submit="handleSubmit"
      @popup-close="handleClose"
    >
      <AddUpdateDetailInfoPop
        slot="main"
        :type="type"
        :rowData="rowData"
        ref="addPop"
        :id="workloadSumId"
        :dataType="dataType"
        :userOpinions="userOpinion"
        :isVerify="isVerify"
      ></AddUpdateDetailInfoPop>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="handleClose"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import AddUpdateDetailInfoPop from "./components/AddUpdateDetailInfoPop";
import { postApi, getApi, loadApi, delApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index.js";
import { formatDateTime } from "@/utils/date";
export default {
  name: "workloadPublicity",
  $dynamicRoute: {
    name: "工作量公示信息",
    path: "/publicity/workload/index",
  },
  components: { AddUpdateDetailInfoPop },
  data() {
    return {
      isVerify: false,
      tableHeight: null,
      showBtn: true,
      type: "",
      dataType: "",
      userOpinion: "",
      workloadSumId: "",
      id: "",
      popTitle: "",
      drawerVisible: false,
      processInstanceId: null,
      loading: false,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      rowData: {},
      dialogVisible: false,
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "单据名称",
          value: "workloadSumName",
          inputType: "text",
          placeholderText: "请输入单据名称",
          title: true,
        },
        {
          label: "类型",
          value: "type",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择类型",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "状态",
          value: "status",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "单据名称",
          key: "workloadSumName",
          align: "center",
        },
        {
          title: "类型",
          key: "typeName",
          align: "center",
        },
        {
          title: "确认状态",
          key: "statusName",
          align: "center",
        },
        {
          title: "确认意见",
          key: "userOpinion",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "确认公示情况",
              state: "default",
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.status == "PRJ-ASSIGN-WORKLOAD-SUM-CONFIRM-DQR") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
              method: (row) => {
                this.handleAddEdit(1, row);
              },
            },
            {
              label: "查看详情",
              icon: "el-icon-view",
              state: "default",
              method: (row) => {
                this.handleAddEdit(2, row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {},
  mounted() {
    // let a = new Map();
    // a.set("test", 111);
    // a.set("test", 222);
    // console.log(a.get("test"));
    // 获取状态
    this.getStateList();
    this.getAccountingList(sessionStorage.getItem("depId"));
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  // computed: {
  //   ...mapState(["btnAuthorityList"]), //权限标识数组
  // },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 下拉数据请求
    getStateList() {
      //类型
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "PRJ_ASSIGN_WORKLOAD_SUM_CONFIRM",
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "type",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      //状态
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "PRJ_ASSIGN_WORKLOAD_SUM_CONFIRM_STATE",
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "status",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    //核算组列表
    getAccountingList(deptId) {
      getApi(`/project/accounting/list`, { deptId }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "accountingGroupId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 列表请求
    getApplyList() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
      };
      postApi(
        `/project/workloadsum/confirm/page/${current}/${size}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },

    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },

    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getApplyList();
    },
    // 弹框关闭事件
    handleClose() {
      this.current = 1;
      this.getApplyList();
      this.dialogVisible = false;
      this.drawerVisible = false;
    },
    handleAddEdit(type, row) {
      //  1：公示确认；2：查看详情
      if (type == 1) {
        this.isVerify = false;
        this.popTitle = "工作量公示确认";
        this.showBtn = true;
        this.userOpinion = "";
      } else if (type == 2) {
        this.popTitle = "详情";
        this.isVerify = true;
        this.rowData = row;
        this.showBtn = false;
        this.userOpinion = row.userOpinion;
      }
      this.dataType = row.type;
      this.workloadSumId = row.workloadSumId;
      this.id = row.id;
      this.dialogVisible = true;
      this.type = "detail";
    },
    handleDelete(row) {
      this.$confirm("是否确定删除？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          delApi(`/project/workloadsum/${row.id}`)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getApplyList();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} size 每页显示条数
     * @param {*} current 第几页
     */
    handlePageChange(size, current) {
      this.size = size;
      this.current = current;
      this.getApplyList();
    },
    // //校验输入框的值
    handleInputReg(val) {
      let value = val.applyWorkload;
      let regex = /^\d+(\.\d{1,2})?$/; // 匹配整数或最多两位小数的数字
      if (!regex.test(value)) {
        val.applyWorkload = "";
        this.$message.warning("请输入正确的格式!");
        return;
      }
    },
    // 确认事件
    handleSubmit() {
      let { userOpinion } = this.$refs.addPop;
      let { dataType, workloadSumId, id } = this;
      if (!userOpinion) {
        this.$message.warning("请输入确认意见");
        return;
      }
      let params = {
        id,
        workloadSumId,
        userOpinion,
        type: dataType,
      };
      postApi(`/project/workloadsum/confirm/verify`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("提交成功");
          this.handleClose();
          this.getApplyList();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
