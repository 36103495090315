<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="客户列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        isSort
        border
        :isSelection="false"
        :topBtn="false"
        show-page
        :loading="loading"
      >
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
export default {
  name: "client",
  $dynamicRoute: {
    name: "客户管理111",
    path: "/client/index",
    meta: {
      keepAlive: true,
    },
  },
  components: {},
  computed: {},
  data() {
    return {
      tableHeight: null,
      drawerVisible: false,
      loading: true,
      formData: {},
      formList: [
        {
          label: "客户编号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入客户编号",
        },
        {
          label: "客户名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入客户名称",
        },
        {
          label: "客户经理",
          value: "managername",
          inputType: "text",
          placeholderText: "请输入客户经理",
        },
        {
          label: "客户来源",
          value: "sourceId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户来源",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
        {
          label: "客户类别",
          value: "typeId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户类别",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
        {
          label: "客户状态",
          value: "stateId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户状态",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
        {
          label: "客户行业",
          value: "industryId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户行业",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
      ],
      tableColumns: [
        {
          title: "客户编号",
          key: "code",
          align: "center",
          width: 150,
        },
        {
          title: "客户名称",
          key: "name",
          align: "center",
          width: 230,
        },
        {
          title: "客户地址",
          key: "address",
          align: "center",
          width: 350,
        },
        {
          title: "客户类别",
          key: "typeName",
          align: "center",
        },
        {
          title: "客户状态",
          key: "stateName",
          align: "center",
        },
        {
          title: "客户行业",
          key: "industryName",
          align: "center",
          width: 120,
        },
        {
          title: "客户来源",
          key: "sourceName",
          align: "center",
        },
        {
          title: "客户经理",
          key: "managerName",
          align: "center",
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      prjState: [],
      addPopupVisible: false,
      rowData: null,
      poptitleName: "新增",
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      postApi(`/project/prj/dict/list/all`, { typeCode: "khlb" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "typeId",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formList, options);
          }
        }
      );
      postApi(`/project/prj/dict/list/all`, { typeCode: "khzt" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "stateId",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formList, options);
          }
        }
      );
      postApi(`/project/prj/dict/list/all`, { typeCode: "khhy" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "industryId",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formList, options);
          }
        }
      );
      postApi(`/project/prj/dict/list/all`, { typeCode: "khly" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "sourceId",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formList, options);
          }
        }
      );
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      postApi(`/project/cmminfo/listByRole`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "编辑";
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/admin/tenant/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    // 格式化类型
    getState(key, row) {
      let label = "";
      this[key].forEach((item) => {
        if (item.value == row) {
          label = item.label;
        }
      });
      return label;
    },
    getArrItem(key, row) {
      let arr = [];
      row.forEach((item) => {
        arr.push(item[key]);
      });
      return arr;
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (flag) {
        if (this.rowData != null) {
          params.id = this.rowData.id;
          putApi(`/admin/tenant`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.handleClose();
              this.getTableData();
            }
          });
        } else {
          postApi(`/admin/tenant`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
