<template>
    <div class="container">
        <dd-card title="">
            <dd-form
                :form-config="formList"
                :labelWidth="'170px'"
                :flex="3"
                ref="assignForm_1"
                formRef="assignForm_1"
            ></dd-form>
        </dd-card>
        <dd-card title="合同变更单据列表">
            <dd-table :columns="tableColumns" :data="tableData" border :isSelection="true" show-page :loading="loading">
            </dd-table>
        </dd-card>
    </div>
</template>

<script>
    export default {
        name: 'contractAmendment',
        props: ['detailData'],
        data() {
            return {
                formList: [
                    {
                        label: "合同总金额",
                        value: "foriginalamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同变更后总金额",
                        value: "ftotalamount",
                        inputType: "text",
                        disabled: true,
                    },
                ],
                tableData: [],
                tableColumns: [
                    {
                        title: "项目组织",
                        key: "fprojectorgName",
                        align: "center",
                        overflow: true,
                        width: 180,
                    },
                    {
                        title: "工程项目",
                        key: "fprojectName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "单据编号",
                        key: "fnumber",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "状态",
                        key: "fbillsateName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "合同编号",
                        key: "purchaseInfoNum",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "合同名称",
                        key: "purchaseInfoName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "变更名称",
                        key: "fname",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "变更种类",
                        key: "fchangetypeName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "变更等级",
                        key: "fchangelevelName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "变更总金额(元)",
                        key: "code777",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "业务日期",
                        key: "fbizdate",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "批复日期",
                        key: "fapprovaldate",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "批复文号",
                        key: "fapprovalsymbol",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "备注",
                        key: "fdescription",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "制单人",
                        key: "fcreatorName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "制单日期",
                        key: "fcreatetime",
                        align: "center",
                        overflow: true,
                    },
                ],
                loading: false,
            }
        },
        watch: {
            detailData() {
                this.initPageData()
            },
        },
        created() {

        },
        methods: {
            initPageData() {
                this.$refs.assignForm_1.formData = {
                    foriginalamount: this.detailData.foriginalamount,
                    ftotalamount: this.detailData.ftotalamount
                }
                this.tableData = this.detailData.contractPurchaseInfoChangeVOList || []
            },
        }
    }
</script>

<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}

/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>