<template>
  <div class="main-container">
    <dd-form
      :form-config="formBasicList"
      :flex="3"
      labelWidth="220px"
      ref="addPopForm"
    >
    </dd-form>
    <dd-form :form-config="formBasicList1" labelWidth="165px" ref="addPopForm1">
    </dd-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import { getNowDate, getNowYear } from "@/utils/date";
export default {
  name: "add-pop",
  props: {
    rowData: [],
    titleName: "",
    taskData: "",
  },
  data() {
    return {
      formBasicList: [
        {
          label: "单据编号",
          value: "assignCode",
          inputType: "text",
          placeholderText: "保存后自动生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "子任务编码",
          value: "assignNum",
          inputType: "text",
          placeholderText: "审批通过后自动生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "任务书年份",
          value: "assignYear",
          inputType: "text",
          defaultValue: getNowYear(),
          disabled: true,
        },
        {
          label: "子任务名称",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入子任务名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入子任务名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "所在区域",
          value: "regionAreaId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          disabled: true,
          placeholderText: "请选择所在区域",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择所在区域",
              trigger: "blur",
            },
          ],
        },
        {
          label: "任务生产实施状态",
          value: "produceStatus",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择任务生产实施状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择任务生产实施状态",
              trigger: "blur",
            },
          ],
        },
        {
          label: "任务工期",
          value: "taskDuration",
          placeholderText: "任务工期",
          inputType: "date",
          dateType: "daterange",
          defaultValue: getNowDate(),
          format: "yyyy-MM-dd",
          rules: [{ type: "array",required: true, message: "请选择任务工期", trigger: "blur" }],
        },
        {
          label: "任务下发部门",
          value: "downDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          disabled: true,
          placeholderText: "请选择任务下发部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: true,
              message: "请选择任务下发部门",
              trigger: "blur",
            },
          ],
          change: this.getClientJlList,
        },
        {
          label: "客户经理",
          value: "cmmmanagerId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          disabled: true,
          placeholderText: "请选择客户经理",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          rules: [
            {
              required: true,
              message: "请选择客户经理",
              trigger: "blur",
            },
          ],
        },
        {
          label: "客户名称",
          value: "cmmId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户名称",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择客户名称",
              trigger: "blur",
            },
          ],
          // remote: true,
          remoteMethod: (query) => {
            this.getCrmList(query);
          },
          change: this.getCrmPerson,
        },
        {
          label: "客户联系人",
          value: "crmContactId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户联系人",
          optionsListConfig: {
            code: "id",
            name: "name",
          },
          rules: [
            {
              required: true,
              message: "请选择客户联系人",
              trigger: "blur",
            },
          ],
        },
        {
          label: "联系方式",
          value: "contactPhone",
          inputType: "text",
        },
        {
          label: "任务生产部门",
          value: "receiveDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择任务生产部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: true,
              message: "请选择任务生产部门",
              trigger: "blur",
            },
          ],
          change: this.getPersonList,
        },
        {
          label: "生产负责人",
          value: "leaderId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择生产负责人",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
        },
        {
          label: "业务类型",
          value: "businessTypeId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择业务类型",
          optionsListConfig: {
            name: "businessName",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择业务类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "项目名称及编号",
          value: "prjId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          disabled: true,
          placeholderText: "请选择",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
          defaultValue: this.prjId,
          change: this.getContractList,
        },
        {
          label: "合同名称及编号",
          value: "contractId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
      ],
      formBasicList1: [
        {
          label: "任务概况",
          value: "assignOverview",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入任务概况",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          // rules: [
          //   {
          //     required: true,
          //     message: "请输入任务概况",
          //     trigger: "blur",
          //   },
          // ],
        },
        {
          label: "工作内容及工作量",
          value: "contentWorkload",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入工作内容及工作量",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          // rules: [
          //   {
          //     required: true,
          //     message: "请输入工作内容及工作量",
          //     trigger: "blur",
          //   },
          // ],
        },
        {
          label: "执行标准及质量要求",
          value: "qualityRequire",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入执行标准及质量要求",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          // rules: [
          //   {
          //     required: true,
          //     message: "请输入执行标准及质量要求",
          //     trigger: "blur",
          //   },
          // ],
        },
        {
          label: "应提交的资料",
          value: "resultList",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入应提交的资料",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          // rules: [
          //   {
          //     required: true,
          //     message: "请输入应提交的资料",
          //     trigger: "blur",
          //   },
          // ],
        },
        {
          label: "特殊情况及注意事项",
          value: "specialPrecautions",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入特殊情况及注意事项",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
        },
      ],
    };
  },
  created() {
    this.getDataType();
  },
  mounted() {
    if (this.taskData) {
      this.getContractList(this.taskData.prjId);
    }
    if (this.rowData != null) {
      this.getDataDetail(this.rowData.id);
    } else {
      this.formBasicList.forEach((item, index) => {
        if (item.value == "produceStatus") {
          this.formBasicList.splice(index, 1);
        }
        if (item.value == "contactPhone") {
          this.formBasicList.splice(index, 1);
        }
        if (item.value == "downDeptId") {
          item.defaultValue = this.taskData[item.value];
          this.getClientJlList(this.taskData[item.value], "init");
        }
        if (item.value == "cmmmanagerId") {
          item.defaultValue = this.taskData[item.value];
        }
        if (item.value == "cmmId") {
          item.defaultValue = this.taskData[item.value];
        }
        if (item.value == "regionAreaId") {
          item.defaultValue = this.taskData[item.value];
        }
        if (item.value == "receiveDeptId") {
          this.getPersonList(this.taskData[item.value], "init");
          item.defaultValue = this.taskData[item.value];
        }
      });
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 获取项目列表数据
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "prjId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "receiveDeptId",
            optionsList: data.data,
          };
          let options1 = {
            value: "downDeptId",
            optionsList: data.data,
          };

          this.setOPtionsList(this.formBasicList, options);
          this.setOPtionsList(this.formBasicList, options1);
        }
      });
      // 区域列表
      getApi(`/admin/syscompanyregion/list`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "regionAreaId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      // 生产控制模式
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-SCKZMS" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "produceType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      // 生产实施状态
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJFZT" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "produceStatus",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      // 项目生产需求级别
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJB" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "assignLevel",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      this.getCrmList("", "init");
      // 获取业务类型列表数据
      getApi(`/project/prjareasubassginbussitype/list`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "businessTypeId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    // 获取客户列表
    getCrmList(name, type) {
      let params = {
        name: name,
      };
      postApi(`/project/customerinfo/list`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            item.defaultValue = this.$refs.addPopForm.formData[item.value];
            if (item.value == "cmmId") {
              item.optionsList = data.data;
              if (type != "init") {
                item.defaultValue = null;
              }
            }
          });
        }
      });
    },
    // 获取客户联系人
    getCrmPerson(id, type) {
      let params = {
        crmId: id,
      };
      getApi(`/project/customercontactdetail/list`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.formBasicList.forEach((item) => {
            item.defaultValue = this.$refs.addPopForm.formData[item.value];
            if (item.value == "crmContactId") {
              item.optionsList = data.data;
              if (type != "init") {
                item.defaultValue = null;
              }
            }
          });
        }
      });
    },
    //生产负责人列表
    getPersonList(deptId, type) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId: deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "receiveDeptId") {
              item.defaultValue = deptId;
            } else {
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
            if (item.value == "leaderId") {
              item.optionsList = data.data;
              if (type != "init") {
                item.defaultValue = null;
              }
            }
          });
        }
      });
    },
    // 获取客户经理列表数据
    getClientJlList(deptId, type) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId: deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "downDeptId") {
              item.defaultValue = deptId;
            } else {
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
            if (item.value == "cmmmanagerId") {
              item.optionsList = data.data;
              if (type != "init") {
                item.defaultValue = null;
              }
            }
          });
        }
      });
    },
    // 获取合同列表数据
    getContractList(id, type) {
      let params = {
        prjId: id,
      };
      getApi(`/project/contractinfo/getcontractinfolistbyprjid`, params).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.formBasicList.forEach((item) => {
              if (item.value == "prjId") {
                item.defaultValue = id;
              } else {
                item.defaultValue = this.$refs.addPopForm.formData[item.value];
              }
              if (item.value == "contractId") {
                item.optionsList = data.data;
                if (type != "init") {
                  item.defaultValue = null;
                }
              }
            });
          }
        }
      );
    },
    getDataDetail(id) {
      getApi(`/project/assign/${id}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.getClientJlList(data.data.downDeptId, "init");
          this.getPersonList(data.data.receiveDeptId, "init");
          this.getContractList(data.data.prjId, "init");
          this.getCrmList(data.data.cmmName, "init");
          this.getCrmPerson(data.data.cmmId, "init");
          this.formBasicList.forEach((item) => {
            if (item.value == "taskDuration") {
              item.defaultValue = [data.data.startTime, data.data.endTime];
            } else {
              item.defaultValue = data.data[item.value];
            }
            if (this.titleName == "详情") {
              item.disabled = true;
              item.rules = null;
            }
          });
          this.formBasicList1.forEach((item) => {
            item.defaultValue = data.data[item.value];
            if (this.titleName == "详情") {
              item.disabled = true;
              item.rules = null;
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
</style>
