<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm" labelWidth="180px">
    </dd-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    dictType: [],
    rowData: [],
    poptitleName: "",
    produceType: "",
  },
  data() {
    return {
      formBasicList: [
        {
          label: "上级节点",
          value: "parentId",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
          },
          showAllSelcet: false,
          placeholderText: "请选择上级节点",
          rules: [
            {
              required: true,
              message: "请选择上级节点",
              trigger: "blur",
            },
          ],
        },
        {
          label: "节点类型",
          value: "nodeTypeCode",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择节点类型",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择节点类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "节点名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入节点名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入节点名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "节点编码",
          value: "code",
          inputType: "text",
          placeholderText: "请输入节点编码",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入节点编码",
              trigger: "blur",
            },
          ],
        },
        {
          label: "工作量单位类型",
          value: "workloadUnitTypeCode",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择工作量单位类型",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择工作量单位类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "排序",
          value: "sortOrder",
          inputType: "number",
          min: 0,
          max: 999,
          placeholderText: "请输入排序",
          disabled: false,
          defaultValue: "",
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (this.produceType == "PRJ-SCKZMS-ZRWMS") {
      this.formBasicList.forEach((item) => {
        if (item.value != "workloadUnitTypeCode") {
          item.disabled = true;
        }
      });
    }
    this.getDataType();
    if (this.dictType) {
      this.formBasicList.forEach((item) => {
        if (item.value == "parentId") {
          item.optionsList = this.dictType;
        }
      });
    }
    if (this.rowData != null) {
      this.formBasicList.forEach((item) => {
        if (this.poptitleName == "新增") {
          if (item.value == "parentId") {
            item.defaultValue = this.rowData.id;
          }
        } else {
          item.defaultValue = this.rowData[item.value];
        }
      });
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 节点类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-RWJHJDLX" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "nodeTypeCode",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      // 工作量单位类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GZBDW" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "workloadUnitTypeCode",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scoped></style>
