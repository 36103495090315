<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="汇总单统计" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        tableRef="dTableRef"
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            v-if="btnAuthorityList.includes('hr:export')"
            icon="export"
            type="primary"
            size="medium"
            autoConfirm
            @click="handleExport(0)"
            >导出
          </dd-button>
          <dd-button
            v-if="
              btnAuthorityList.includes('fin:export') ||
              btnAuthorityList.includes('hr:export')
            "
            icon="export"
            type="primary"
            size="medium"
            autoConfirm
            @click="handleExport(1)"
            >财务核算表导出
          </dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="pageSize"
            :page-number="pageNumber"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      v-if="dialogVisible"
      width="85%"
      height="90%"
      :title-name="addEditTitle"
      :show="dialogVisible"
      @popup-close="dialogVisible = false"
    >
      <SummarySheet
        slot="main"
        :type="type"
        :id="id"
        :code="code"
        :applyTime="applyTime"
        @diaChange="diaChange"
      ></SummarySheet>
    </dd-popup>
    <dd-popup
      v-if="dialogDetailVisible"
      width="85%"
      height="90%"
      :title-name="addEditTitle"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
    >
      <SummaryDetail
        slot="main"
        @diaChange="diaChange"
        :type="type"
        :id="id"
      ></SummaryDetail>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import SummarySheet from "../Department/components/SummarySheet";
import SummaryDetail from "./components/SummaryDetail";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
import { mapState } from "vuex";

export default {
  name: "withoutSummarySheet",
  $dynamicRoute: {
    name: "外部租车汇总单管理",
    path: "/withoutCar/summarySheet/list",
  },
  components: { SummaryDetail, SummarySheet },
  data() {
    return {
      tableHeight: null,
      addEditTitle: null,
      drawerVisible: false,
      dialogDetailVisible: false,
      id: null,
      applyTime: "",
      type: null,
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      formList: [
        {
          label: "汇总单编号",
          value: "collectCode",
          inputType: "text",
          placeholderText: "请输入汇总单编号",
          title: true,
        },
        {
          label: "部门名称",
          value: "collectDepId",
          inputType: "select",
          placeholderText: "请选择或搜索部门名称",
          optionsList: [],
          optionsListConfig: {
            code: "deptId",
            name: "name",
          },
        },
        {
          label: "月份",
          value: "reportMonth",
          inputType: "date",
          dateType: "monthrange",
          format: "yyyy-MM",
        },
        {
          label: "上报状态",
          value: "reportStatus",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索单据状态",
        },
        {},
        {},
        {},
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "汇总单编号",
          key: "collectCode",
          align: "center",
          event: [
            {
              type: "primary",
              href: (row) => {
                return row.collectCode;
              },
              method: (row) => {
                this.handleDetailInfo(row, 0);
              },
            },
          ],
        },
        {
          title: "单据名称",
          key: "name",
          align: "center",
          overflow: true,
        },
        {
          title: "组织",
          key: "collectOrgName",
          align: "center",
        },
        {
          title: "部门",
          key: "collectDepName",
          align: "center",
        },
        {
          title: "月份",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "上报状态",
          key: "reportStatusName",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "接收确认",
              type: "primary",
              method: (row) => {
                this.handleDetailInfo(row, 2);
              },
              rules: (row) => {
                let show = false;
                if (row.reportStatus == "wbzchzdsbzt_dxzqr") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "汇总详情",
              method: (row) => {
                this.handleDetailInfo(row, 1);
              },
              rules: (row) => {
                let show = false;
                if (
                  row.reportStatus == "wbzchzdsbzt_dxzqr" ||
                  row.reportStatus == "wbzchzdsbzt_xzyqr" ||
                  row.reportStatus == "wbzchzdsbzt_yth"
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    // 获取单据状态
    this.getStateList();
    this.initSearchForm(); //根据状态切换查询表单
    // this.initTableColumns(); //根据权限切换表头
    this.getExpenseDepartmentList();
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  computed: {
    ...mapState(["btnAuthorityList"]), //权限标识数组
  },
  methods: {
    //根据状态切换查询表单
    initSearchForm() {
      /* 财务角色没有状态筛选 */
      // if (this.btnAuthorityList.includes("fin:export")) {
      //   this.formList.forEach((item) => {
      //     if (item.value === "reportStatus" || !item.value) {
      //       //从list中移除这个对象
      //       this.formList.splice(this.formList.indexOf(item), 4);
      //     }
      //   });
      // }
      this.formList.forEach((item) => {
        if (item.value === "reportStatus" || !item.value) {
          //从list中移除这个对象
          this.formList.splice(this.formList.indexOf(item), 4);
        }
      });
    },
    //根据权限切换表头
    initTableColumns() {
      /* 财务角色不能审核 */
      if (this.btnAuthorityList.includes("fin:export")) {
        // 表格头部配置数据
        this.tableColumns.forEach((item) => {
          if (item.title == "操作") {
            item.event.splice(0, 1);
          }
        });
      }
    },
    //费用承担部门
    getExpenseDepartmentList() {
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.formList.forEach((item) => {
          if (item.value == "collectDepId") {
            item.optionsList = res.data.data;
          }
        });
      });
    },
    diaChange(e) {
      this.dialogDetailVisible = e;
      this.dialogVisible = e;
      this.id = null;
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.getApplyList();
    },
    // 详情
    handleDetailInfo(row, type) {
      this.id = row.id;
      if (type == "0") {
        // 查看详情
        this.addEditTitle = "申请单详情";
        this.type = "detail";
        this.code = row.collectCode;
        this.applyTime = row.reportMonth;
        this.dialogVisible = true;
      } else if (type == "1") {
        //汇总详情
        this.addEditTitle = `${row.collectDepName}${row.reportMonth}车辆补贴汇总单`;
        this.type = "hz";
        this.dialogDetailVisible = true;
      } else {
        this.type = "sh";
        this.addEditTitle = `${row.collectDepName}${row.reportMonth}车辆补贴汇总单`;
        this.dialogDetailVisible = true;
        //审核
      }
    },
    // 总金额（预算明细)
    handleDetail(val) {
      this.applyDetailTitle = "预算明细";
      this.applyId = val.id;
      this.projectId = val.projectId;
      this.dialogDetailVisible = true;
      this.amountType = "totalAmount";
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    // 汇总单列表
    getApplyList() {
      let _collectCode = this.formData.collectCode || "";
      let _collectOrgId = sessionStorage.getItem("orgId");
      let _collectDepId = this.formData.collectDepId || "";
      var _reportMonth = [];
      if (this.formData.reportMonth) {
        _reportMonth = this.formData.reportMonth;
      }
      let _reportStatus = this.formData.reportStatus || "";
      if (_collectOrgId.indexOf("/") != -1) {
        _collectOrgId = _collectOrgId.replaceAll("/", "____");
      }

      if (_collectOrgId.indexOf("+") != -1) {
        _collectOrgId = _collectOrgId.replaceAll("+", "___");
      }

      if (_collectDepId.indexOf("/") != -1) {
        _collectDepId = _collectDepId.replaceAll("/", "____");
      }

      if (_collectDepId.indexOf("+") != -1) {
        _collectDepId = _collectDepId.replaceAll("+", "___");
      }

      getApi(
        `/hr/rentalcar/collectsheet/audit/page?current=` +
          this.pageNumber +
          `&size=` +
          this.pageSize +
          `&collectCode=` +
          _collectCode +
          `&collectOrgId=` +
          _collectOrgId +
          `&collectDepId=` +
          _collectDepId +
          `&reportMonthStr=` +
          _reportMonth +
          `&reportStatus=` +
          _reportStatus
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let list = [];
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
          // if (this.btnAuthorityList.includes("fin:export")) {
          //   data.data.records.forEach((item) => {
          //     if (item.reportStatus == "wbzchzdsbzt_xzyqr") {
          //       list.push(item);
          //     }
          //   });
          //   this.tableDataSources = list;
          //   this.total = list.length;
          // } else {
          //   this.tableDataSources = data.data.records;
          //   this.total = data.data.total;
          // }
        }
      });
    },
    // 单据状态下拉数据请求
    getStateList() {
      postApi(`/hr/hrdict/list?typeCode=wbzchzdsbzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "reportStatus") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      console.log(obj);
      this.pageNumber = 1;
      this.getApplyList();
    },
    /* 导出 */
    handleExport(type) {
      /*
      type   0：行政  1：财务 
       */
      let ids = [];
      this.multipleSelection.length &&
        this.multipleSelection.map((item) => {
          ids.push(item.id);
        });
      if (type == 0) {
        if (this.multipleSelection.length != 1) {
          this.$message.warning("请选择一条数据！");
          return;
        }
        let params = {
          formData: {
            current: 1,
            size: 99999,
            collectId: this.multipleSelection[0].id,
          },
        };
        console.log("params", params);
        loadApi(
          `/hr/rentalcar/collectsheet/export/statistics/person`,
          params
        ).then((res) => {
          let { data } = res;
          downloadBinaryFile(
            data,
            decodeURIComponent(
              res.headers["content-disposition"].split(";")[1].split("=")[1]
            )
          );
        });
      } else {
        if (this.multipleSelection.length != 1) {
          this.$message.warning("请选择一条数据！");
          return;
        }
        let params = {
          formData: {
            current: this.pageNumber,
            size: 9999999,
            // ids: this.multipleSelection.length ? ids.join() : "",
            collectId: this.multipleSelection[0].id,
            // projectId: this.multipleSelection[0].projectId,
          },
        };
        console.log("params", params);
        loadApi(
          `/hr/rentalcar/collectsheet/export/statistics/prj`,
          params
        ).then((res) => {
          let { data } = res;
          downloadBinaryFile(
            data,
            decodeURIComponent(
              res.headers["content-disposition"].split(";")[1].split("=")[1]
            )
          );
        });
      }
    },
    // 格式化表格单据状态
    getState(cellValue) {
      if (cellValue == "dtj") {
        return "待提交";
      } else if (cellValue == "shz") {
        return "审核中";
      } else if (cellValue == "shtg") {
        return "审核通过";
      } else if (cellValue == "shbtg") {
        return "审核不通过";
      }
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.getApplyList();
    },
  },
};
</script>

<style lang="less" scoped></style>

<style>
.el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>
