<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="项目目录列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        ref="tableData"
        border
        isSelection
        show-page
        :loading="loading"
        @select-change="handleTableSelect"
      >
        <template #table-btn>
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd"
            >批量生成目录</dd-button
          >
          <dd-button
            v-if="btnAuthorityList.includes('prj:catalog:export')"
            type="warning"
            size="medium"
            icon="export"
            @click="handleExport"
            >导出</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="60%"
      height="80%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      title-name="生成目录"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop v-if="addPopupVisible" slot="main" ref="addPop"></add-pop>
    </dd-popup>
    <dd-popup
      width="80%"
      height="85%"
      :showBtn="false"
      :show="catalogPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
    >
      <catalog-pop
        v-if="catalogPopupVisible"
        slot="main"
        ref="addRightPop"
        :prjId="rowData.id"
        :cataState="rowData.cataState"
      ></catalog-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, postDownBlobApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
import catalogPop from "./components/AddCatalogPop.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "prjlog",
  $dynamicRoute: {
    name: "项目目录",
    path: "/project/file/prjlog",
  },
  components: {
    addPop,
    catalogPop,
  },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      tableHeight: null,
      drawerVisible: false,
      loading: true,
      formData: {},
      formList: [
        {
          label: "项目名称",
          value: "prjName",
          inputType: "text",
          placeholderText: "请输入项目名称",
        },
        {
          label: "项目编码",
          value: "prjCode",
          inputType: "text",
          placeholderText: "请输入项目编码",
        },
        {
          label: "项目状态",
          value: "prjStateCode",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择项目状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "归档状态",
          value: "cataState",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择归档状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {},
        {},
        {},
      ],
      tableColumns: [
        {
          title: "项目编码",
          key: "prjCode",
          align: "center",
          width: 150,
        },
        {
          title: "项目名称",
          key: "prjName",
          width: 360,
        },
        {
          title: "项目状态",
          key: "prjState",
          align: "center",
          formatter: (row) => {
            return row;
          },
        },
        {
          title: "归档责任人",
          key: "cataUser",
          align: "center",
        },
        {
          title: "归档状态",
          key: "cataState",
          align: "center",
          formatter: (row) => {
            return this.getState("cataState", row);
          },
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 300,
          event: [
            {
              label: "生成目录",
              icon: "el-icon-plus",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.cataState == null) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "提交归档",
              icon: "el-icon-plus",
              method: (row) => {
                this.handleRowSubmit(row, "gdz");
              },
              type: "warning",
              rules: (row) => {
                let show = false;
                if (
                  row.cataState == "wgd" &&
                  this.btnAuthorityList.indexOf("prj:catalog:submit") != -1
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "确认归档",
              icon: "el-icon-plus",
              method: (row) => {
                this.handleRowSubmit(row, "ygd");
              },
              type: "success",
              rules: (row) => {
                let show = false;
                if (
                  row.cataState == "gdz" &&
                  this.btnAuthorityList.indexOf("prj:catalog:confirm") != -1
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看目录",
              icon: "el-icon-view",
              method: (row) => {
                this.handleRowDetail(row);
              },
              type: "info",
              rules: (row) => {
                let show = false;
                if (row.cataState != null) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (row.cataState == "wgd") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      prjState: [],
      cataState: [],
      addPopupVisible: false,
      catalogPopupVisible: false,
      rowData: null,
      poptitleName: "新增",
      selection: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      postApi(`/project/prj/dict/list/all`, { typeCode: "xmzt" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "prjStateCode",
              optionsList: data.data,
            };
            this.prjState = data.data;
            this.setOPtionsList(this.formList, options);
          }
        }
      );
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "prj_catalog_state",
      }).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "cataState",
            optionsList: data.data,
          };
          this.cataState = data.data;
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      getApi(`/project/catalog/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
          this.selection = [];
          this.$refs.tableData.$refs.baseTable.clearSelection();
        }
      });
    },
    // 新增按钮事件
    handleAdd() {
      if (this.selection.length == 0) {
        this.$message.warning("请选择项目数据");
        return;
      }
      let isNext = true;
      this.selection.forEach((item) => {
        console.log(item);
        if (item.cataState != null) {
          isNext = false;
        }
      });
      if (!isNext) {
        this.$message.warning("存在已经被创建项目，请重新选择");
        return;
      }
      this.addPopupVisible = true;
    },
    // 导出事件
    handleExport() {
      let prjarr = [];
      this.selection.forEach((item) => {
        prjarr.push(item.id);
      });
      let params = {
        prjIds: prjarr,
      };
      postDownBlobApi("/project/catalog/export", params, "项目归档汇总表.xlsx");
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
      this.catalogPopupVisible = false;
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.selection.push(row);
    },
    // 提交归档type=gdz/确认归档type=ygd
    handleRowSubmit(row, type) {
      this.$confirm(`确认归档该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmSubmit(row.id, type);
      });
    },
    handleConfirmSubmit(rowId, type) {
      let params = {
        prjId: rowId,
        state: type,
      };
      postApi(`/project/catalog/batchConfirmCata`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.$message.success("操作成功");
          this.getTableData();
        }
      });
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      let params = {
        prjId: delId,
      };
      postApi(`/project/catalog/deleteCatalog`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
          this.getTableData();
        }
      });
    },
    // 查看详情
    handleRowDetail(row) {
      this.rowData = row;
      let params = {
        prjId: row.id,
      };
      postApi(`/project/catalog/findCatalog`, params).then((res) => {
        let { data } = res;
        if (data.code == 0 && data.data && data.data.length > 0) {
          this.poptitleName = row.prjName;
          this.catalogPopupVisible = true;
        } else {
          this.$message.warning("目录已被删除");
        }
      });
    },
    // 格式化类型
    getState(key, row) {
      let label = "";
      this[key].forEach((item) => {
        if (item.code == row) {
          label = item.name;
        }
      });
      return label;
    },
    getArrItem(key, row) {
      let arr = [];
      row.forEach((item) => {
        arr.push(item[key]);
      });
      return arr;
    },
    handleTableSelect(selection) {
      this.selection = selection;
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      params.areaName =
        this.$refs.addPop.$refs[formName].$refs.areaId[0].selectedLabel;
      params.username =
        this.$refs.addPop.$refs[formName].$refs.userId[0].selectedLabel;
      let selection = this.$refs.addPop.selection;
      if (selection.length == 0) {
        this.$message.warning("请选择目录数据");
        return;
      }
      if (flag) {
        let arr = [];
        selection.forEach((item) => {
          arr.push(item.dirId);
        });
        params.dirIdList = arr;
        let prjarr = [];
        this.selection.forEach((item) => {
          prjarr.push(item.id);
        });
        params.prjIdList = prjarr;
        console.log(JSON.stringify(params));
        postApi(`/project/catalog/create`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("保存成功");
            this.handleClose();
            this.getTableData();
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
