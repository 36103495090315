<template>
  <div class="main-container">
    <div class="search-container">
      <dd-search-form
        :form-config="formList"
        :ref="formRef"
        @search-click="handleSearchEvent"
      ></dd-search-form>
      <div style="margin-left: 8px">
        <el-button
          type="primary"
          icon="search"
          size="medium "
          @click="handleLocation"
          >定位到当前考勤区域</el-button
        >
      </div>
    </div>
    <div :id="cesiumID" class="cesiumMap"></div>
    <div class="data-container">
      <div class="data-card">
        <div class="card-name">当天考勤数据</div>
        <i class="el-icon-warning-outline" @click="handleErrorData">
          定位异常</i
        >
      </div>
      <div class="table-data">
        <el-table :data="tableDataSources" style="width: 100%">
          <el-table-column prop="kqyd" label="应到" width="78.4">
            <template slot-scope="scope">
              <div>{{ scope.row.kqyd }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="kqsd" label="实到" width="78.4">
            <template slot-scope="scope">
              <el-tag @click="handleData(1)">{{ scope.row.kqsd }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="kqwd" label="未到" width="78.4">
            <template slot-scope="scope">
              <div>{{ scope.row.kqwd }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="kqcd" label="迟到" width="78.4">
            <template slot-scope="scope">
              <el-tag @click="handleData(2)">{{ scope.row.kqcd }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="kqyc" label="异常">
            <template slot-scope="scope">
              <el-tag @click="handleData(3)">{{ scope.row.kqyc }}</el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <DataPop
      v-if="checkInDataPopupVisible"
      slot="main"
      :type="type"
      :formData="formData"
      @close="handleDataClose"
    ></DataPop>
    <!-- 地图属性弹框 -->
    <FeaturePop
      v-if="mapPopupVisible"
      slot="main"
      :popupObj="popupObj"
      @close="handleMapClose"
    ></FeaturePop>
  </div>
</template>

<script>
import { initMap } from "/static/js/init_cesium.js";
import { getApi, postApi } from "@/api/request";
import DataPop from "./components/DataPop.vue";
import FeaturePop from "./components/FeaturePop.vue";
import * as Cesium from "cesium/Cesium";
import { getNowDate } from "@/utils/date.js";
import { cesiumEvent } from "/static/js/cesiumEvent.js";
export default {
  name: "statistics",
  $dynamicRoute: {
    name: "考勤统计",
    path: "/checkingIn/statistics",
  },
  components: {
    DataPop,
    FeaturePop,
  },
  computed: {},
  data() {
    return {
      cesiumID: "SPCesiumMap", //地图id
      formRef: "baseForm",
      // 接收表单返回数据
      formData: {},
      formList: [
        {
          placeholderText: "请选择查询日期",
          value: "date",
          inputType: "date",
          dateType: "daterange",
          change: this.handleChange,
          defaultValue: [getNowDate(), getNowDate()],
          labelWidth: "5px",
          format: "yyyy-MM-dd",
        },
        {
          placeholderText: "考勤区域",
          value: "region",
          change: this.handleChange,
          inputType: "select",
          labelWidth: "10px",
          defaultValue: "",
          optionsList: [],
          optionsListConfig: {
            code: "id",
            name: "areaname",
          },
        },
        {
          placeholderText: "考勤状态",
          value: "statu",
          change: this.handleChange,
          inputType: "select",
          defaultValue: "",
          labelWidth: "10px",
          optionsList: [],
          optionsListConfig: {
            code: "code",
            name: "name",
          },
        },
        {
          placeholderText: "考勤类型",
          value: "type",
          change: this.handleChange,
          inputType: "select",
          labelWidth: "10px",
          defaultValue: "",
          optionsList: [],
          optionsListConfig: {
            code: "code",
            name: "name",
          },
        },
        {
          placeholderText: "组织机构",
          value: "organ",
          inputType: "cascader",
          labelWidth: "5px",
          optionsList: [],
          defaultValue: "",
          change: this.handleChange,
          prop: {
            emitPath: false,
            label: "name",
            value: "id",
          },
        },
        {
          placeholderText: "部门",
          value: "dept",
          change: this.handleChange,
          inputType: "select",
          labelWidth: "10px",
          defaultValue: "",
          optionsList: [],
          optionsListConfig: {
            code: "deptId",
            name: "name",
          },
        },
        {
          placeholderText: "员工姓名",
          value: "name",
          inputType: "text",
          labelWidth: "10px",
          defaultValue: "",
        },
      ],
      tableDataSources: [
        {
          kqcd: 0,
          kqsd: 0,
          kqwd: 0,
          kqyc: 0,
          kqyd: 0,
        },
      ],
      current: 1,
      size: 15,
      mapPopupVisible: false,
      checkInDataPopupVisible: false,
      poptitleName: "",
      date: "",
      searchList: [],
      popupObj: {},
      type: "",
      startTimeStr: "",
      endTimeStr: "",
      regionId: "",
      polyjson: "",
    };
  },
  mounted() {
    initMap(this.cesiumID, "img");
    this.getRegionList(); //考勤区域
    this.getStatusList(); //考勤状态
    this.getTypeList(); //考勤类型
    this.getOrganList(); //组织机构
    this.getDeptList(); //部门
    this.getTodayNum(); //当天
    this.getCheckInStatistic();//默认加载当天数据
    cesiumEvent("", this.clickCallback); //属性弹窗
  },
  methods: {
    //考勤区域列表数据
    getRegionList() {
      let { current, size } = this;
      let params = {
        current,
        size,
      };
      getApi(`/hr/attend/baseinfo/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "region") {
              item.optionsList = data.data.records;
            }
          });
        }
      });
    },
    //考勤状态列表数据
    getStatusList() {
      let params = {
        typeCode: "type-qdzt",
      };
      postApi(`/hr/hrdict/list?typeCode=${params.typeCode}`).then((res) => {
        this.formList.forEach((item) => {
          let { data } = res;
          if (item.value == "statu") {
            item.optionsList = data.data;
          }
        });
      });
    },
    //考勤类型列表状态
    getTypeList() {
      let params = {
        typeCode: "attend-location",
      };
      postApi(`/hr/hrdict/list?typeCode=${params.typeCode}`).then((res) => {
        this.formList.forEach((item) => {
          let { data } = res;
          if (item.value == "type") {
            item.optionsList = data.data;
          }
        });
      });
    },
    //获取组织列表
    getOrganList() {
      getApi(`/admin/org/tree`, {}).then((res) => {
        this.formList.forEach((item) => {
          let { data } = res;
          if (item.value == "organ") {
            item.optionsList = data.data;
          }
        });
      });
    },
    //考勤部门列表
    getDeptList(e) {
      let params = {
        orgId: e,
      };
      postApi(`/admin/dept/getdeptlistbyorgid`, params).then((res) => {
        this.formList.forEach((item) => {
          let { data } = res;
          if (item.value == "dept") {
            item.optionsList = [];
            item.optionsList = data.data;
          }
        });
      });
    },
    handleData(type) {
      this.handleDataClose();
      this.poptitleName = "考勤数据";
      // 1:实到 2:迟到 3:异常
      if (type == 1 && this.tableDataSources[0].kqsd != 0) {
        this.type = "type-qdzt_1";
        this.checkInDataPopupVisible = true;
      } else if (type == 2 && this.tableDataSources[0].kqcd != 0) {
        this.type = "type-qdzt_2";
        this.checkInDataPopupVisible = true;
      } else if (type == 3 && this.tableDataSources[0].kqyc != 0) {
        this.type = "type-qdzt_3";
        this.checkInDataPopupVisible = true;
      }
    },
    //定位到当前考勤区域
    handleLocation() {
      if (!this.regionId) {
        this.$message.warning("请先选择考勤区域");
      } else {
        getApi(`/hr/attend/baseinfo/${this.regionId}`).then((res) => {
          let { data } = res;
          this.initPolygon(data.data.lngAndLatStr);
        });
      }
    },
    initPolygon(json) {
      let dataSourceCollection = window.viewer.dataSources;
      console.log(dataSourceCollection._dataSources);
      dataSourceCollection._dataSources.forEach((item) => {
        if (item._name == "region") {
          window.viewer.dataSources.remove(item);
        }
      });
      let obj = JSON.parse(json);
      let GeoJsonDataSource = new Cesium.GeoJsonDataSource("region");
      let datasource = GeoJsonDataSource.load(obj, {
        clampToGround: true,
        fill: Cesium.Color.fromAlpha(Cesium.Color.LIGHTSEAGREEN, 0.5),
      });
      window.viewer.dataSources.add(datasource);
      window.viewer.zoomTo(datasource); //定位
    },
    //考勤异常数据
    handleErrorData() {
      this.poptitleName = "考勤异常数据";
      this.checkInDataPopupVisible = true;
      this.type = "error"; //异常
    },
    handleDataClose() {
      this.checkInDataPopupVisible = false;
    },
    handleChange(e, itm) {
      if (itm == "organ") {
        this.getDeptList(e);
      }
      if (itm == "date") {
        this.startTimeStr = e[0];
        this.endTimeStr = e[1];
      }
      if (itm == "region") {
        this.regionId = e;
      }
      this.formList.forEach((item) => {
        if (item.value == itm) {
          item.defaultValue = e;
        }
      });
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getCheckInStatistic();
    },
    getCheckInStatistic() {
      let { current } = this;
      let params = {
        current,
        size: 9999,
        dataType: 0,
        startTimeStr: this.formData.date ? this.formData.date[0] : getNowDate(),
        endTimeStr: this.formData.date ? this.formData.date[1] : getNowDate(),
        userFullName: this.formData.name,
        deptId: this.formData.dept,
        instatus: this.formData.statu,
        areaId: this.formData.region,
        prjId: this.formData.type,
      };
      getApi(`/hr/attend/monthinfo/statistics`, params).then((res) => {
        let { data } = res.data;
        this.searchList = data;
        if (this.searchList.length > 0) {
          this.getTodayNum();
          if (this.searchList.length > 0) {
            this.viewUserData();
          }
        }else{
          this.$message.warning("未查到当天考勤数据!")
        }
      });
    },
    //当天考勤数据
    getTodayNum() {
      let params = {
        startTimeStr: this.formData.date ? this.formData.date[0] : getNowDate(),
        endTimeStr: this.formData.date ? this.formData.date[1] : getNowDate(),
        userFullName: this.formData.name,
        deptId: this.formData.dept,
        instatus: this.formData.statu,
        areaId: this.formData.region,
        prjId: this.formData.type,
        dataType: 0,
      };
      getApi(`/hr/attend/monthinfo/today/statistics`, params).then((res) => {
        this.tableDataSources = [res.data.data];
      });
    },
    //cesium添加点数据 并定位
    viewUserData() {
      window.viewer.graphicsLayer.entities.removeAll(); //删除实体
      this.searchList.forEach((item) => {
        let obj = {};
        obj.id = item.id;
        if (item.inlocation) {
          let objlocation = JSON.parse(item.inlocation);
          let objPositions = objlocation.coordinates;
          let locationX = objPositions[0];
          let locationY = objPositions[1];
          obj.pointX = parseFloat(locationX);
          obj.pointY = parseFloat(locationY);
          let Img = null;
          if (item.instatus == "type-qdzt_1") {
            //外勤正常打卡
            Img = require("/static/image/normal.png");
          } else if (item.instatus == "type-qdzt_2") {
            //外勤异常打卡
            Img = require("/static/image/abnormal.png");
          } else {
            //内勤正常和外勤打卡未确定区域
            Img = require("/static/image/unarea.png");
          }
          let options = {
            name: "checkPoint",
            oid: obj.id,
            positions: Cesium.Cartesian3.fromDegrees(obj.pointX, obj.pointY),
            color: Cesium.Color.RED,
            outlineColor: Cesium.Color.WHITE,
            outlineWidth: 2,
            pixelSize: 10,
            billboardOption: {
              b_img: Img,
              b_width: 70,
              b_height: 90,
              b_scale: 0.5,
            },
          };
          this.createPointsGraphics(options);
        }
      });
      window.viewer.zoomTo(window.viewer.graphicsLayer.entities);
    },
    createPointsGraphics(options) {
      let entity = new Cesium.Entity();
      entity.name = options.name || "";
      entity.oid = options.oid || "point";
      entity.position = options.positions;
      if (options.positions) entity.point = this.getPointGraphics(options);
      if (options.billboardOption)
        entity.billboard = this.getBillboardGraphics(options.billboardOption);
      // if (options.labelOption)
      //   entity.label = this.getLabelGraphics(options.labelOption);
      window.viewer.graphicsLayer.entities.add(entity);
    },
    //点
    getPointGraphics(options) {
      if (options) {
        return new Cesium.PointGraphics({
          color: options.color || Cesium.Color.GREEN,
          pixelSize: options.pixelSize || 10,
          outlineColor: options.outlineColor || Cesium.Color.WHITE,
          outlineWidth: options.outlineWidth || 1,
        });
      }
    },
    //标签
    getLabelGraphics(options) {
      if (options && options.l_text) {
        return new Cesium.LabelGraphics({
          //文字标签
          text: options.l_text,
          font: options.l_font || "14px sans-serif",
          // fillColor: options.l_fillColor || Cesium.Color.GOLD,
          // style: options.l_style || Cesium.LabelStyle.FILL_AND_OUTLINE,
          // outlineWidth: options.l_outlineWidth || 2,
          // showBackground: options.l_showBackground || false,
          // backgroundColor:
          //   options.l_backgroundColor ||
          //   new Cesium.Color(0.165, 0.165, 0.165, 0.8),
          // verticalOrigin:
          //   options.l_verticalOrigin || Cesium.VerticalOrigin.BOTTOM,
          // pixelOffset: options.l_pixelOffset || new Cesium.Cartesian2(0, -30),
          // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
        });
      }
    },
    //广告牌
    getBillboardGraphics(options) {
      if (options && options.b_img) {
        return new Cesium.BillboardGraphics({
          image: options.b_img,
          width: options.b_width || 35,
          height: options.b_height || 35,
          scale: options.b_scale || 1,
          // eyeOffset :new Cesium.Cartesian2(0, -20),
          pixelOffset: options.b_pixelOffset || new Cesium.Cartesian2(0, -20),
          scaleByDistance: options.b_scaleByDistance || undefined,
          // heightReference:Cesium.HeightReference.RELATIVE_TO_GROUND
        });
      }
    },
    //属性弹窗
    clickCallback(e) {
      if (typeof e == "undefined") {
        this.mapPopupVisible = false;
      } else {
        this.poptitleName = "要素属性";
        this.mapPopupVisible = true;
        let list = this.searchList;
        list.forEach((item) => {
          if (item.id == e.oid) {
            this.popupObj = item;
          }
        });
      }
    },
    handleMapClose() {
      this.mapPopupVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  .search-container {
    position: absolute;
    top: 5px;
    left: 20px;
    right: 30%;
    z-index: 999;
    .el-button {
      background-color: @themeColor;
      border-color: @themeColor;
    }
  }
  .cesiumMap {
    height: 100% !important;
  }
  .data-container {
    position: absolute;
    z-index: 999;
    top: 15px;
    left: 75%;
    .table-data {
      border-radius: 100px;
      /deep/ .el-table__cell {
        text-align: center;
      }
    }
    .data-card {
      background-color: @themeColor;
      color: #fff;
      height: 40px;
      line-height: 40px;
      font-size: 15px;
      align-items: center;
      justify-content: space-between;
      display: flex;
      .card-name {
        margin-left: 15px;
      }
      .el-icon-warning-outline {
        font-size: 15px;
        margin-right: 10px;
      }
    }
  }
}
</style>