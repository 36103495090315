<template>
  <div class="main-container">
    <dd-card title="基本信息" spacer
      ><dd-form
        :form-config="formBasicList"
        :flex="2"
        labelWidth="140px"
        ref="addForm"
      >
      </dd-form>
    </dd-card>
    <dd-card title="上传附件">
      <el-upload
        class="upload-demo"
        drag
        :multiple="false"
        :limit="1"
        :action="actionURL"
        :headers="headers"
        :on-remove="handleRemove"
        :on-success="handleFileSuccess"
        :file-list="fileList"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">上传同步至文件服务器</div>
      </el-upload>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import { getNowDate } from "@/utils/date";
import qs from "qs";
export default {
  name: "add-pop",
  props: {
    treeList: {
      type: Array,
      default: [],
    },
    rowData: {
      type: Object,
      default: null,
    },
    rowId: {
      type: String,
      default: "",
    },
    poptitleName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      actionURL: "/admin/sys-file/upload",
      fileList: [],
      // 请求头信息
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("authToken") || "",
      },
      //项目列表
      project: [],
      formBasicList: [
        {
          label: "节点名称",
          value: "nodeCode",
          inputType: "cascader",
          optionsList: this.treeList,
          prop: {
            value: "nodeCode",
            label: "nodeName",
            checkStrictly: true,
            emitPath: false,
          },
          placeholderText: "请选择节点名称",
          showAllSelcet: false,
          rules: [
            {
              required: true,
              message: "请选择节点名称",
              trigger: "blur",
            },
          ],
          change: (e, refName) => {
            this.assignId =
              this.$refs.addForm.$refs[
                refName
              ][0].getCheckedNodes()[0].data.assignId;
            this.$refs.addForm.$refs[refName][0].dropDownVisible = false;
            // this.getDocType(e);
          },
        },
        {
          label: "文档名称",
          value: "documentName",
          inputType: "text",
          placeholderText: "请输入文档名称",
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入文档名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "文档分类",
          value: "documentType",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择文档分类",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "文档状态",
          value: "documentState",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择文档状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "版本号",
          value: "version",
          inputType: "text",
          placeholderText: "请输入版本号",
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入版本号",
              trigger: "blur",
            },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的版本格式",
              trigger: "blur",
            },
          ],
        },
      ],
      assignId: null,
    };
  },
  created() {},
  mounted() {
    this.getDocState();
    this.getDocType();
    if (this.poptitleName != "审批修改") {
      this.formBasicList.forEach((item, index) => {
        if (item.value == "documentState") {
          this.formBasicList.splice(index, 1);
        }
      });
    }
    // 编辑请求详情
    if (this.rowData) {
      this.assignId = this.rowData.assignId;
      this.getDataDetail();
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getDocState() {
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "PRJ-RWJHJDWDZT",
      }).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "documentState",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    getDocType() {
      postApi(
        `/project/prj/dict/list/all`,{ typeCode: "PRJ-RWJHJDWD" }).then(
          (res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "documentType",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    //编辑获取详情数据
    getDataDetail() {
      //编辑时，通过id获取数据
      getApi(`/project/subplannode/attachfile/${this.rowData.id}`).then(
        (res) => {
          let { data } = res;
          if (data.code == 0) {
            this.getDocType(data.data.nodeCode);
            let editDataSources = data.data;
            this.editDataSources = editDataSources;
            this.formBasicList.forEach((item) => {
              item.defaultValue = editDataSources[item.value];
            });
            this.fileList = [
              {
                name: data.data.sourceFileName,
                fileName: data.data.fileName,
              },
            ];
          }
        }
      );
    },
    //附件上传成功
    handleFileSuccess(res, file) {
      if (res.code == 0) {
        this.fileList.push(res.data);
      }
    },
    //附件上传移除
    handleRemove(file, fileList) {
      this.fileList.map((item, index) => {
        if (item.fileName == file.fileName) {
          this.fileList.splice(index, 1);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
