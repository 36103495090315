<template>
  <div class="container">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="发票详情" name="发票详情">
        <addEditDetails :pageProp="pageProp"></addEditDetails>
      </el-tab-pane>
      <el-tab-pane label="分配采购合同详情" name="分配采购合同详情">
        <allocation :pageProp="pageProp"></allocation>
      </el-tab-pane>
      <el-tab-pane label="冲红详情" name="冲红详情">
        <redBlood :pageProp="pageProp"></redBlood>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import addEditDetails from './addEditDetails.vue';
import redBlood from './redBlood.vue';
import allocation from './allocation.vue';
export default {
  name: "pageDetail",
  props: ["pageProp"],
  components: {
    addEditDetails, redBlood, allocation
  },
  data() {
    return {
      activeName: "发票详情",
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleClick() {},
  },
};
</script>

<style lang="less" scoped>
</style>