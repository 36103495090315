import request from "./axios";
//get请求
export const getApi = (url, params, header) => {
    let headers = {
        "Authorization": 'Bearer ' + sessionStorage.getItem("authToken") || ''
    }
    return request({
        url: url,
        headers: header || headers,
        method: "get",
        params: params,
    });
};
//post请求
export const postApi = (url, params, header) => {
    let headers = {
        "Authorization": 'Bearer ' + sessionStorage.getItem("authToken") || ''
    }
    return request({
        url: url,
        headers: header || headers,
        method: "post",
        data: params,
    });
};
export const downBlobApi = (url, params, fileName, header) => {
    let headers = {
        "Authorization": 'Bearer ' + sessionStorage.getItem("authToken") || ''
    }
    request({
        url: url,
        headers: header || headers,
        method: "get",
        data: params,
        responseType: 'blob'
    }).then(res => {
        // 处理返回的文件流
        const blob = res.data;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        window.setTimeout(function () {
            window.URL.revokeObjectURL(blob);
            document.body.removeChild(link);
        }, 0);
    });
};
export const postDownBlobApi = (url, params, fileName, header) => {
    let headers = {
        "Authorization": 'Bearer ' + sessionStorage.getItem("authToken") || ''
    }
    request({
        url: url,
        headers: header || headers,
        method: "post",
        data: params,
        responseType: 'blob'
    }).then(res => {
        // 处理返回的文件流
        const blob = res.data;
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        window.setTimeout(function () {
            window.URL.revokeObjectURL(blob);
            document.body.removeChild(link);
        }, 0);
    });
};
//post下载请求
export const loadApi = (url, params, header) => {
    let headers = {
        "Authorization": 'Bearer ' + sessionStorage.getItem("authToken") || ''
    }
    return request({
        url: url,
        headers: header || headers,
        method: "post",
        data: params,
        responseType: 'arraybuffer' //防止乱码
    });
};
export const loadGetApi = (url, params, header) => {
    let headers = {
        "Authorization": 'Bearer ' + sessionStorage.getItem("authToken") || ''
    }
    return request({
        url: url,
        headers: header || headers,
        method: "get",
        data: params,
        responseType: 'arraybuffer' //防止乱码
    });
};

//postfrom请求
export const postfromApi = (url, params, header) => {
    let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        "Authorization": 'Bearer ' + sessionStorage.getItem("authToken")
    }
    let tfr = [function (data) {
        let form = ''
        for (let p in data) {
            form += encodeURIComponent(p) + '=' + encodeURIComponent(data[p]) + '&'
        }

        form = form.substring(0, form.length - 1);
        return form
    }]
    return request({
        url: url,
        headers: header || headers,
        method: "post",
        data: params,
        transformRequest: tfr
    });
};

//delete请求
export const delApi = (url, params, header) => {
    let headers = {
        "Authorization": 'Bearer ' + sessionStorage.getItem("authToken") || ''
    }
    return request({
        url: url,
        headers: headers,
        method: "delete",
        data: params
    });
};

//put请求
export const putApi = (url, params, header) => {
    let headers = {
        "Authorization": 'Bearer ' + sessionStorage.getItem("authToken") || ''
    }
    return request({
        url: url,
        headers: headers,
        method: "put",
        data: params
    });
};
//xml请求
export const getXmlApi = (url, params) => {
    let headers = {
        "Content-Type": "application/xml"
    }
    return request({
        url: url,
        headers: headers,
        method: "get",
        data: params
    });
};