<template>
  <div>
    <dd-card title="基本信息" spacer>
      <dd-form
        :form-config="formList"
        ref="addPopForm"
        labelWidth="160px"
        :flex="2"
      ></dd-form>
    </dd-card>
    <dd-card title="计划内容">
      <dd-table
        max-height="360"
        ref="expenseApplyTable"
        :columns="tableColumns"
        :data="tableDataSources"
        :loading="loading"
        border
        :isEdit="true"
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            size="medium"
            icon="add"
            @click="handleExpenseApply"
            >选择计划节点</dd-button
          >
          <dd-button
            type="danger"
            size="medium"
            icon="delete"
            @click="handleDelete"
            >移除</dd-button
          >
        </template>
      </dd-table>
    </dd-card>

    <dd-popup
      width="80%"
      height="80%"
      inPopup
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="expenseApplyVisible"
      title-name="计划节点选择"
      @popup-close="expenseApplyVisible = false"
    >
      <dd-button slot="button" size="medium" @click="popupClose"
        >取消</dd-button
      >
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleApplySubmit"
        >确认</dd-button
      >

      <expense-apply
        v-if="expenseApplyVisible"
        slot="main"
        ref="expenseApply"
        :assignId="selectAssignId"
        :selectDataObj="selectDataObj"
        :filterSelection="filterSelection"
      ></expense-apply>
    </dd-popup>
  </div>
</template>

<script>
import { getNowDate, formatDateTime } from "@/utils/date";
import expenseApply from "./NodeListPop.vue";
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  components: {
    expenseApply,
  },
  props: {
    assignId: {
      type: String,
      default: "",
    },
    rowData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      depList: [],
      projectList: [],
      loading: false,
      formList: [
        // {
        //   label: "任务列表",
        //   value: "assignId",
        //   inputType: "select",
        //   optionsList: [],
        //   filterable: false,
        //   placeholderText: "请选择任务",
        //   optionsListConfig: {
        //     name: "assignName",
        //     code: "id",
        //   },
        //   rules: [
        //     {
        //       required: true,
        //       message: "请选择任务",
        //       trigger: "blur",
        //     },
        //   ],
        //   change: this.getChangeTask,
        //   defaultValue: "",
        // },
        {
          label: "计划类型",
          value: "subplanType",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "计划类型",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          change: this.changePlan,
          rules: [
            {
              required: true,
              message: "请选择计划类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "计划周期",
          value: "taskDuration",
          placeholderText: "计划周期",
          inputType: "date",
          dateType: "datetimerange",
          defaultValue: ['00:00:00', '23:59:59'],
          format: "yyyy-MM-dd HH:mm:ss",
          rules: [{ type: "array",required: true, message: "请选择计划周期", trigger: "blur" }],
        },
        {
          label: "计划执行部门",
          value: "applierDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "计划执行部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: true,
              message: "请选择计划执行部门",
              trigger: "blur",
            },
          ],
        },
        // {
        //   label: "计划次数与名称",
        //   value: "assignName",
        //   inputType: "text",
        //   placeholderText: "请输入计划次数与名称",
        //   disabled: false,
        //   defaultValue: "",
        //   rules: [
        //     {
        //       required: true,
        //       message: "请输入计划次数与名称",
        //       trigger: "blur",
        //     },
        //   ],
        // },
      ],
      tableColumns: [
        {
          title: "所属节点序号",
          key: "nodeCodeParent",
          align: "center",
        },
        {
          title: "所属节点名称",
          key: "nodeNameParent",
          align: "center",
        },
        {
          title: "节点编号",
          key: "nodeCode",
          align: "center",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
        },
        {
          title: "计量单位",
          key: "nodeUnitName",
          align: "center",
        },
        {
          title: "总工作量",
          key: "planWorkloadSum",
          align: "center",
        },
        {
          title: "总标准工天",
          key: "planStandardWorkdaySum",
          align: "center",
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
        },
        {
          title: "已完成量",
          key: "realWorkloadSum",
          align: "center",
        },
        {
          title: "已完成标准工天",
          key: "realStandardWorkdaySum",
          align: "center",
        },
        {
          title: "本期计划",
          align: "center",
          children: [
            {
              title: "计划标准工天",
              key: "planStandardWorkday",
              align: "center",
              // input: [
              //   {
              //     placeholderText: "请输入",
              //     method: (value, row, idx) => {
              //       // this.changeAmount(value, row, idx);
              //     },
              //     isEdit: (value) => {
              //       let state = false;
              //       return state;
              //     },
              //     rules: (value) => {
              //       return value.replace(/[^\d.]/g, "");
              //     },
              //   },
              // ],
            },
            {
              title: "计划工作量",
              key: "planWorkload",
              align: "center",
              input: [
                {
                  placeholderText: "请输入",
                  method: (value, row, idx) => {
                    this.changeAmount(value, row, idx);
                  },
                  isEdit: (value) => {
                    let state = false;
                    return state;
                  },
                  rules: (value) => {
                    return value.replace(/[^\d.]/g, "");
                  },
                },
              ],
            },
          ],
        },
        {
          title: "负责部门",
          key: "nodeDeptName",
          align: "center",
        },
        {
          title: "操作",
          key: "file",
          align: "center",
          width: 80,
          event: [
            {
              label: "移除",
              type: "danger",
              method: (row) => {
                this.handleRemove(row);
              },
            },
          ],
        },
      ],
      tableDataSources: [],
      //费用申请单显隐
      expenseApplyVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      //是否打开删除确认
      autoConfirm: false,
      //默认选择状态
      filterSelection: [],
      checked: false,
      // 所选申请单集合
      multipleSelectionProject: [],
      selectDataObj: {},
      selectAssignId: null,
    };
  },
  mounted() {
    this.selectAssignId = this.assignId;
    this.getDataType();
    if (this.rowData) {
      this.getDetail(this.rowData.id);
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    changeAmount(val, row, idx) {
      // 计划标准工天= 计划工作量/效率
      // planStandardWorkday = val/efficiency
      this.tableDataSources[idx].planStandardWorkday = val/row.efficiency
    },
    // 获取下拉选择数据
    getDataType() {
      postApi(`/project/assign/list/${this.assignId}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "assignId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      // 计划类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-JDJHLX" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "subplanType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formList, options);
          }
        }
      );
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "applierDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    getChangeTask(id) {
      this.selectAssignId = id;
    },
    changePlan(e) {
      let params = {
        assignId: this.assignId,
        subplanType: e,
      };
      getApi(`/project/subplan/getLastPeriodPlan`, params).then((res) => {
        let { data } = res;
        if (data.code == 0 && data.data) {
          this.$refs.addPopForm.formData.subplanStarttime =
            data.data.realEndtime;
        }
      });
    },
    // 编辑回显详情查询
    getDetail(id) {
      getApi(`/project/subplan/getprjassignperiodplandetail/${id}`).then(
        (res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formList.forEach((item) => {
              if(item.dateType == "daterange"){
                item.defaultValue = [data.data.subplanStarttime,data.data.subplanEndtime]
              }else{
                item.defaultValue = data.data[item.value];
              }
            });
            this.tableDataSources = data.data.prjAssignSubplanPeriodNodeVOList;
          }
        }
      );
    },
    handleChange(e, type) {
      let { subplanStarttime, subplanEndtime } = this.$refs.addPopForm.formData;
      if (subplanStarttime && subplanEndtime) {
        let start = new Date(subplanStarttime).getTime();
        let end = new Date(subplanEndtime).getTime();
        if (end < start) {
          this.$message.warning("结束时间需大于开始时间");
          this.$refs.addPopForm.formData.subplanEndtime = "";
          this.$refs.addPopForm.formData.subplanStarttime = "";
          return;
        }
      }
    },
    //选择事件---打开计划节点列表
    handleExpenseApply() {
      this.filterSelection = [];
      this.expenseApplyVisible = true;
      //默认选择数据
      this.filterSelection = this.tableDataSources;
    },
    //关闭计划节点列表事件
    popupClose() {
      this.expenseApplyVisible = false;
    },
    //单行删除
    handleRemove(row) {
      // this.$confirm("是否删除此行数据？", "提示", {
      //   confirmButtonClass: "btnsearch",
      //   confirmButtonText: "确认",
      //   cancelButtonClass: "btnreset",
      //   cancelButtonText: "取消",
      //   showClose: true,
      //   type: "warning",
      // }).then(() => {
      this.filterSelection = [];
      this.getDelete(row);
      // });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //全选事件
    selectAll() {
      this.checked = !this.checked;
      this.handleSelect(this.tableDataSources, this.checked);
    },
    //全选事件选中状态
    handleSelect(data, flag) {
      data.forEach((row) => {
        this.$refs.moreTable.$refs.moreTable.toggleRowSelection(row, flag);
        if (row.children) {
          this.handleSelect(row.children);
        }
      });
    },
    //批量删除事件
    handleDelete() {
      let deleteSelection = this.multipleSelection;
      if (deleteSelection.length < 1) {
        this.$message.warning("请至少选择一条数据!");
      } else {
        this.handleConfirmDelete();
      }
    },
    //是否确认删除
    handleConfirmDelete() {
      this.filterSelection = [];
      //批量删除费用明细
      this.multipleSelection.map((item) => {
        this.getDelete(item);
      });
      //清除选择状态
      this.multipleSelection = [];
      this.$refs.expenseApplyTable.$refs.baseTable.clearSelection();
    },
    //删除费用报账单费用明细数据
    getDelete(data) {
      this.tableDataSources.map((item, index) => {
        if (item.id == data.id) {
          this.tableDataSources.splice(index, 1);
        } else {
          this.filterSelection.push(item);
        }
      });
    },
    //选择费用类型，确认事件
    handleApplySubmit() {
      this.multipleSelectionProject = this.$refs.expenseApply.multipleSelection;
      if (
        this.multipleSelectionProject &&
        this.multipleSelectionProject.length == 0
      ) {
        this.$message.warning("请至少选择一条数据进行确认操作！");
        return;
      }
      this.expenseApplyVisible = false;
      this.tableDataSources = this.multipleSelectionProject;
    },
  },
};
</script>

<style></style>
