<template>
  <div class="main-container">
    <dd-card title="机构列表" height="100%">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        border
        :topBtn="false"
        :isSelection="false"
        :show-page="false"
        :tree-props="{ children: 'children' }"
        default-expand-all
      >
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { getApi } from "@/api/request";
export default {
  name: "org",
  $dynamicRoute: {
    name: "机构管理",
    path: "/admin/org/index",
  },
  components: {},
  computed: {},
  data() {
    return {
      tableColumns: [
        {
          title: "机构名称",
          key: "name",
        },
        {
          title: "机构编码",
          key: "code",
          align: "center",
        },
        {
          title: "电话",
          key: "phone",
          align: "center",
        },
        {
          title: "传真",
          key: "tax",
          align: "center",
        },
        {
          title: "地址",
          key: "address",
          align: "center",
        },
      ],
      tableData: [],
    };
  },
  created() {},
  mounted() {
    this.getTableData();
  },
  methods: {
    // 获取表格列表数据
    getTableData() {
      getApi(`/admin/org/tree`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableData = data.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
