<template>
  <div class="detail-container">
    <dd-card title="基本信息">
      <dd-form
        :form-config="formList"
        :labelWidth="'170px'"
        :flex="3"
        ref="decomposeForm"
        formRef="decomposeForm"
      ></dd-form>
    </dd-card>
    <dd-card title="类型明细">
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, idx) in tabArr"
          :key="idx"
          :label="item.costTypeName"
          :name="item.costTypeName"
        >
          <dd-table
            :columns="tableTypeColumns"
            :data="item.children"
            :topBtn="false"
            :is-selection="false"
            :loading="loading"
            border
            default-expand-all
            :tree-props="{
              children: 'children',
              hasChildren: 'hasChildren',
            }"
          >
          </dd-table>
        </el-tab-pane>
      </el-tabs>
    </dd-card>

    <dd-popup
      v-if="dialogAddEditProVisible"
      width="70%"
      height="80%"
      inPopup
      showBtn
      :saveBtn="saveBtn"
      :submitBtn="submitBtn"
      :show="dialogAddEditProVisible"
      :title-name="addEditTitlePro"
      @popup-close="handleClose"
    >
      <addEditPopPro
        ref="addEditPopPro"
        slot="main"
        :addEdit="workOutProps"
        :type="type"
      ></addEditPopPro>
    </dd-popup>

    <dd-popup
      v-if="dialogAddEditSalesVisible"
      width="70%"
      height="80%"
      showBtn
      inPopup
      :saveBtn="saveBtn"
      :submitBtn="submitBtn"
      :show="dialogAddEditSalesVisible"
      :title-name="addEditTitleSales"
      @popup-close="handleClose"
    >
      <addEditPopSale
        ref="AddEditPopSales"
        slot="main"
        :addEdit="workOutProps"
        :type="type"
      ></addEditPopSale>
    </dd-popup>
  </div>
</template>

<script>
// import AmountDetail from "./AmountDetail";
// import ClaimsAmountDetail from "./ClaimsAmountDetail";
import { postApi, getApi } from "@/api/request";
import addEditPopPro from "../../WorkOut/components/addEditPopPro.vue";
import addEditPopSale from "../../WorkOut/components/addEditPopSale.vue";
import { mapActions, mapState } from "vuex";
import qs from "qs";

export default {
  // components: { AmountDetail, ClaimsAmountDetail },
  props: ["type", "pageType", "propDetail"],
  components: {
    addEditPopPro,
    addEditPopSale,
  },
  data() {
    return {
      formList: [],
      loading: false,
      dialogAddEditProVisible: false,
      dialogAddEditSalesVisible: false,
      workOutProps: {},
      saveBtn: true,
      submitBtn: true,
      addEditTitlePro: "生产预算表详情",
      addEditTitleSales: "销售预算表详情",
      formListEdit: [
        {
          label: "任务书编码",
          value: "assignNum",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          disabled: true,
        },
        {
          label: "预算总金额",
          value: "budgetTotalAmount",
          inputType: "text",
          disabled: true,
        },
      ],
      formListDetail: [
        {
          label: "单据编码",
          value: "code",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        {
          label: "任务书编码",
          value: "assignNum",
          inputType: "text",
          disabled: true,
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          disabled: true,
        },
        {
          label: "生产版本",
          value: "scBudgetVersion",
          inputType: "link",
          click: (val, formData) => {
            this.openSCDialog(val, formData);
          },
        },
        {
          label: "销售版本",
          value: "xsBudgetVersion",
          inputType: "link",
          click: (val, formData) => {
            this.openXSDialog(val, formData);
          },
        },
        // {
        //   label: "版本",
        //   value: "version",
        //   inputType: "text",
        //   disabled: true,
        // },
        {
          label: "预算总金额",
          value: "budgetTotalAmount",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        // {
        //   label: "版本更新人",
        //   value: "applierName",
        //   inputType: "text",
        //   disabled: true,
        // },
        // {
        //   label: "版本完成日期",
        //   value: "applyDate",
        //   inputType: "text",
        //   disabled: true,
        // },
      ],
      formListDetailKingDee: [
        {
          label: "单据编码",
          value: "code",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        {
          label: "项目编码",
          value: "projectCode",
          inputType: "text",
          disabled: true,
        },
        {
          label: "项目名称",
          value: "projectName",
          inputType: "text",
          disabled: true,
        },
        {
          label: "制单人",
          value: "makerUser",
          inputType: "text",
          disabled: true,
        },
        {
          label: "制单日期",
          value: "makeDate",
          inputType: "text",
          disabled: true,
        },
        {
          label: "版本",
          value: "versionCode",
          inputType: "text",
          disabled: true,
        },
      ],
      tableTypeColumns: [
        {
          title: "费用类型编号",
          key: "costTypeCode",
          align: "center",
        },
        {
          title: "费用类型名称",
          key: "costTypeName",
          align: "center",
          // event: [
          //   {
          //     type: "primary",
          //     href: (row) => {
          //       return row.costTypeName;
          //     },
          //     method: (row) => {
          //       this.openDayBookDetail(row);
          //     },
          //   },
          // ],
        },
        {
          title: "预算金额(元)",
          key: "budgetAmount",
          align: "center",
          input: [
            {
              isEdit: (row) => {
                let state = false;
                if (this.pageType == "decompose" && this.type == "detail") {
                  state = true;
                } else if (
                  this.pageType == "decompose" &&
                  this.type == "edit" &&
                  row.parentId == "00"
                ) {
                  state = true;
                }
                return state;
              },
              method: (value, row, index) => {
                this.changeAmount(value, row, index);
              },
              rules: (value) => {
                let result = value.replace(/[^\d.]/g, "");
                if (result.charAt(0) === ".") {
                  result = result.slice(1);
                }
                const parts = result.split(".");
                if (parts.length > 2) {
                  result = parts.slice(0, 2).join(".");
                }
                if (parts.length > 1) {
                  result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                }
                return result;
              },
            },
          ],
        },
        // {
        //   title: "使用金额(元)",
        //   key: "realUseAmount",
        //   align: "center",
        // },
      ],
      tableKingDeeColumns: [
        {
          title: "费用类型编号",
          key: "costTypeCode",
          align: "center",
        },
        {
          title: "费用类型名称",
          key: "costTypeName",
          align: "center",
        },
        {
          title: "预算金额(元)",
          key: "amount",
          align: "center",
        },
      ],
      tableData: [],
      proTableData: [],
      manTableData: [],
      saleTableData: [],
      devTableData: [],
      expandRowKeys: [],
      //判断当前用户角色，是否为当前项目经理角色
      isCurPrjCmmManager: false,
      amountDetailVisible: false,
      amountDetailTitle: "",
      expenseTypeCode: "",
      claimsAmountDetailVisible: false,
      claimsAmountDetailTitle: "",
      activeName: "生产类",
      tabArr: [],
    };
  },
  created() {},
  mounted() {
    if (this.pageType == "kingdee") {
      this.formList = this.formListDetailKingDee;
      this.tableTypeColumns = this.tableKingDeeColumns;
      this.getkingdeeData();
    } else {
      if (this.type == "edit") {
        this.formList = this.formListEdit;
      } else {
        this.formList = this.formListDetail;
      }
      this.getPageData(this.propDetail.id);
    }

    // let _this = this;
    // setTimeout(() => {
    //   let table = document.querySelector(
    //     ".detail-container .el-table__footer-wrapper>table"
    //   );
    //   console.log('table', table);
    //   // 选择第几个点击事件 如果全部 直接写 table.rows[0].cells.onclick
    //   if (Number(table.rows[0].cells[3].textContent) > 0) {
    //     table.rows[0].cells[3].style.setProperty("cursor", "pointer");
    //     table.rows[0].cells[3].style.setProperty(
    //       "color",
    //       "#4e72ea",
    //       "important"
    //     );
    //     table.rows[0].cells[3].onclick = function (e) {
    //       _this.openReportBill();
    //     };
    //   }
    //   if (Number(table.rows[0].cells[2].textContent) > 0) {
    //     table.rows[0].cells[2].style.setProperty("cursor", "pointer");
    //     table.rows[0].cells[2].style.setProperty(
    //       "color",
    //       "#4e72ea",
    //       "important"
    //     );
    //     table.rows[0].cells[2].onclick = function (e) {
    //       _this.openDayBookDetail();
    //     };
    //   }
    // }, 1000);
  },
  methods: {
    getkingdeeData() {
      // 获取金蝶数据
      postApi("/finance/finbudgetkingdee/getentitybyid", {
        id: this.propDetail.id,
      }).then((res) => {
        if (res.data.code == 0) {
          let { data } = res;
          if (res.data.code == "0") {
            let { data } = res;
            let { finBudgetKingdeeDetailList, ...jbxxData } = data.data;
            if (this.type == "edit") {
            }
            this.$refs.decomposeForm.formData = jbxxData;
            let arr = [];
            finBudgetKingdeeDetailList.forEach((item) => {
              let obj = {
                costTypeName: item.costTypeName,
                children: [item],
              };
              arr.push(obj);
            });
            this.tabArr = arr;
          }
        }
      });
    },
    getPageData(id) {
      getApi(`/finance/finbudget/costdisassemble/getBudgetCostById`, {id}).then((res) => {
        if (res.data.code == "0") {
          let { data } = res;
          let { detailList, ...jbxxData } = data.data;
          if (this.type == "edit") {
          }
          this.$refs.decomposeForm.formData = jbxxData;
          let arr = [];
          detailList.forEach((item) => {
            let obj = {
              costTypeName: item.costTypeName,
              children: [item],
            };
            arr.push(obj);
          });
          this.tabArr = arr;
        }
      });
    },
    changeAmount(value, row, index) {
      // 计算预算总金额
      let count = 0;
      this.tabArr.forEach((item) => {
        if (item.costTypeName == this.activeName) {
          item.children[0].children.forEach((item) => {
            count += Number(item.budgetAmount || 0);
          });
          item.children[0].budgetAmount = count.toFixed(2);
        }
      });
      let budgetAmountTotal = 0;
      this.tabArr.forEach((item) => {
        budgetAmountTotal += Number(item.children[0].budgetAmount || 0);
      });
      this.$refs.decomposeForm.formData.budgetTotalAmount =
        budgetAmountTotal.toFixed(2);
    },
    openSCDialog(val, formData) {
      this.workOutProps.pageType = "workout";
      this.workOutProps.type = "detail";
      this.saveBtn = false;
      this.submitBtn = false;
      this.workOutProps.id = formData.scBudgetEstablishmentId;
      this.dialogAddEditProVisible = true;
    },
    handleClose() {
      this.dialogAddEditProVisible = false;
      this.dialogAddEditSalesVisible = false;
    },
    openXSDialog(val, formData) {
      this.workOutProps.pageType = "workout";
      this.workOutProps.type = "detail";
      this.saveBtn = false;
      this.submitBtn = false;
      this.workOutProps.id = formData.xsBudgetEstablishmentId;
      this.dialogAddEditSalesVisible = true;
    },
    handleClick(tab, event) {
      this.activeName = tab.label;
    },
    openDayBookDetail(row) {
      this.amountDetailTitle = "记账明细";
      this.amountDetailVisible = true;
      this.expenseTypeCode = row.code;
    },
    openReportBill(row) {
      this.claimsAmountDetailTitle = "报账明细";
      this.claimsAmountDetailVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-tabs__header {
  margin: 0;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #576289;
  border-bottom: none;
}
/deep/.el-tabs__item.is-active {
  color: @systemColor !important;
}
/deep/.el-tabs__item:hover {
  color: #000 !important;
}
/deep/.el-tabs__item.is-active:focus {
  color: @systemColor !important;
}
/deep/ .el-tabs__nav {
  width: 100%;
}
/deep/ .el-tabs__item {
  width: 25%;
  text-align: center;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}
/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
</style>
