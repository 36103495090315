<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>
  
  <script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    type: "",
    rowData:{}
  },
  data() {
    return {
      formBasicList: [
        {
          label: "负责部门",
          value: "deptId",
          inputType: "select",
          show: false,
          optionsList: [],
          filterable: false,
          placeholderText: "请选择负责部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          change: this.handleChange,
          rules: [
            {
              required: true,
              message: "请选择负责部门",
              trigger: "blur",
            },
          ],
        },
        {
          label: "销售负责人",
          value: "leaderIdsList",
          inputType: "select",
          show: false,
          optionsList: [],
          filterable: false,
          placeholderText: "请选择区域销售负责人",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          multiple: true,
          rules: [
            {
              required: true,
              message: "请选择区域销售负责人",
              trigger: "blur",
            },
          ],
        },
      ],
      deptTree: [],
    };
  },
  created() {},
  mounted() {
    this.formBasicList.forEach((item) => {
      if (item.value == "deptId") {
        item.show = this.type == 1;
      } else {
        item.show = this.type == 2;
      }
    });
    if (this.type == 1) {
      this.getDeptList();
    } else {
      this.getPersonList(this.rowData.deptId);
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //获取部门列表
    getDeptList() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    //负责人列表
    getPersonList(deptId) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "leaderIdsList",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
  },
};
</script>
  <style lang='less' scoped>
</style>
  