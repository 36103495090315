<template>
  <div class="main-container">
    <dd-form
      :form-config="formBasicList"
      :flex="2"
      labelWidth="200px"
      ref="addPopForm"
    >
    </dd-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    dictType: [],
    paramType: [],
    statusType: [],
    rowData: [],
  },
  data() {
    return {
      formBasicList: [
        {
          label: "工作包名称",
          value: "nodeName",
          inputType: "text",
          placeholderText: "请输入工作包名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入工作包名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "工作包类型",
          value: "nodeType",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择工作包类型",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择工作包类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "单位类型",
          value: "nodeUnit",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择单位类型",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择单位类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "排序",
          value: "sortOrder",
          inputType: "number",
          min: 0,
          max: 999,
          placeholderText: "请输入排序",
          disabled: false,
          defaultValue: "",
        },
        {
          label: "备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入备注",
          disabled: false,
          defaultValue: "",
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (this.rowData != null) {
      this.formBasicList.forEach((item) => {
        item.defaultValue = this.rowData[item.value];
      });
    }
    this.getDataType();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 获取业务类型列表数据
      getApi(`/project/prjareasubassginbussitype/list`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "bussTypeId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      // 获取区域节点类型列表数据
      getApi(`/project/prjareanode/list`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "areaCodeId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      // 任务工作包类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GZBLX" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "nodeType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      // 单位类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GZBDW" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "nodeUnit",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scoped></style>
