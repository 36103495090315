<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        :labelWidth="'190px'"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="预算比对列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        border
        ref="dTableRef"
        :loading="loading"
        :isSelection="false"
        :isSelectable="false"
      >
        <template #table-btn-right>
            <el-button
              icon="el-icon-s-operation"
              circle
              type="info"
              size="small"
              @click="drawerVisible = true"
              title="列筛选"
            ></el-button>
          </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <!-- 详情 -->
    <dd-popup
      v-if="distinctionDetailVisible"
      width="70%"
      height="80%"
      showBtn
      :saveBtn="false"
      :submitBtn="false"
      :show="distinctionDetailVisible"
      :title-name="distinctionTitle"
      @popup-close="distinctionDetailVisible = false"
    >
      <distinctionDetail
        ref="DistinctionDetail"
        slot="main"
        :propDetail="propDetail"
        :pageType="pageType"
      ></distinctionDetail>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>
    
    <script>
import { postApi, loadApi, getApi } from "@/api/request";
import distinctionDetail from "./components/distinctionDetail.vue";
export default {
  name: "distinction",
  $dynamicRoute: {
    name: " 预算比对（新）",
    path: "/finance/budget/distinction",
    meta: {
      keepAlive: true,
    },
  },
  components: { distinctionDetail },
  data() {
    return {
      searchObj: {},
      tableHeight: null,
      drawerVisible: false,
      loading: false,
      distinctionDetailVisible: false,
      distinctionTitle: "",
      type: "edit",
      propDetail: {},
      pageType: "kingdee",
      tableData: [],
      formList: [
        {
          label: "项目名称及编码",
          value: "prjName",
          inputType: "text",
          placeholderText: "请输入项目名称或编码",
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "项目编码",
          key: "prjCode",
          align: "center",
          overflow: true,
        },
        {
          title: "项目名称",
          key: "prjName",
          align: "center",
          width: 600,
        },
        {
          title: "管理系统预算总金额",
          key: "assignAmount",
          align: "center",
        },
        {
          title: "金蝶预算总金额",
          key: "totalAmount",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetail(row);
              },
            },
          ],
        },
      ],
      columnsList: [],
      current: 1, // 当前页码
      total: 0, // 总条数
      size: 10, // 每页的数据条数
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    getTableData(obj) {
      const params = {
        current: this.current,
        size: this.size,
        ...obj,
      }
      getApi(`/finance/finbudgetadjustable/compare/page`, params).then(res => {
        if(res.data.code == 0) {
          let { data } = res;
          console.log('data.data', data.data)
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      })
    },
    handleDetail(row) {
      this.distinctionDetailVisible = true;
      this.propDetail.prjId = row.prjId;
      this.distinctionTitle = "预算比对详情";
    },
    handleSearchEvent(val) {
      this.searchObj = val;
      this.getTableData(val);
    },
    handleCurrentChange(size, number) {
          this.size = size;
          this.current = number;
          this.getTableData(this.searchObj);
    },
  },
};
</script>
    
<style lang="less" scoped></style>
    