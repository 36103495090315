<template>
    <div class="main-container">
        <dd-form :form-config="formBasicList" ref="addPopForm" labelWidth="150px">
        </dd-form>
    </div>
  </template>

  <script>
  import { postApi, getApi, loadApi } from "@/api/request";
  export default {
    name: "add-pop",
    props: {
      dictType: [],
      rowData: []
    },
    data() {
      return {
        formBasicList: [
          {
            label: "分管部门名称",
            value: "name",
            inputType: "text",
            placeholderText: "请输入分管部门名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入分管部门名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "分管部门负责人",
            style: "width:50%",
            value: "userId",
            inputType: "select",
            optionsList: [],
            optionsListConfig: {
              code: "userId",
              name: "fullName",
            },
            change: this.handleSelectChange,
            clear: this.handleSelectClear,
            defaultValue: "",
            filterable: true,
            placeholderText: "请选择或搜索分管部门负责人",
            rules: [
              { required: true, message: "请选择或搜索分管部门负责人", trigger: "blur" },
            ],
          },
          {
            label: "排序",
            value: "sortOrder",
            inputType: "number",
            min: 0,
            max: 999,
            placeholderText: "请输入排序",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
          {
            label: "备注",
            value: "description",
            inputType: "text",
            type: "textarea",
            placeholderText: "请输入备注",
            disabled: false,
            defaultValue: "",
            autosize: { minRows: 3 },
            rules: [
              {
                required: false,
                message: "请输入备注",
                trigger: "blur",
              },
            ],
          },
        ],
        deptTree: [],
      };
    },
    created() {
      this.getCollectionList();
    },
    mounted() {
      if(this.rowData != null) {
        this.formBasicList.forEach(item => {
          if(item.value == 'userId'){
            item.defaultValue = this.rowData.leaderName
          }else{
            item.defaultValue = this.rowData[item.value]
          }
        })
      }
    },
    methods: {
      // 人员列表
      getCollectionList() {
        postApi(`/admin/user/getver/list`,{}).then(res => {
          let {data} = res;
          if (data.code == 0) {
            let options = {
              value: "userId",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        })
      },
      // 条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formBasicList, options) {
          formBasicList.forEach((item) => {
              if (options) {
                  if (item.value == options.value) {
                      item.optionsList = options.optionsList;
                  }
              }
          });
      },
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
