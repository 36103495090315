<template>
  <div class="main-container">
    <dd-card title="公示确认情况">
      <dd-search-form
        :form-config="searchFormList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        :isSelection="false"
        ref="dTableRef"
        show-page
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
  </div>
</template>
  
  <script>
import { postApi, getApi, loadApi, delApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    id: "",
  },
  data() {
    return {
      searchFormList: [
        {
          label: "类型",
          value: "type",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择类型",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "状态",
          value: "status",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "姓名",
          value: "fullname",
          inputType: "text",
          placeholderText: "请输入姓名",
          title: true,
        },
      ],
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      //筛选列表格头部配置
      columnsList: [
        {
          title: "类型",
          key: "typeName",
          align: "center",
          width: 200,
          overflow: true,
        },
        {
          title: "工号",
          key: "username",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "姓名",
          key: "fullname",
          width: 180,
          align: "center",
          overflow: true,
        },
        {
          title: "确认状态",
          key: "statusName",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "确认意见",
          key: "userOpinion",
          align: "center",
        },
        {
          title: "确认时间",
          key: "confirmTime",
          align: "center",
        },
      ],
      // 表格数据集合
      tableDataSources: [],
      formData: {},
    };
  },
  created() {},
  mounted() {
    this.getPublicityList();
    this.getStateList();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getStateList() {
      //类型
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "PRJ_ASSIGN_WORKLOAD_SUM_CONFIRM",
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "type",
            optionsList: data.data,
          };
          this.setOPtionsList(this.searchFormList, options);
        }
      });
      //状态
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "PRJ_ASSIGN_WORKLOAD_SUM_CONFIRM_STATE",
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "status",
            optionsList: data.data,
          };
          this.setOPtionsList(this.searchFormList, options);
        }
      });
    },
    getPublicityList() {
      let { current, size, formData } = this;
      let params = {
        ...formData,
      };
      params.workloadSumId = this.id;
      postApi(
        `/project/prjassign/workloadsumconfirm/page/${current}/${size}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getPublicityList();
    },
    handlePageChange(size, current) {
      this.size = size;
      this.current = current;
      this.getPublicityList();
    },
  },
};
</script>
  <style lang='less' scoped>
</style>
  