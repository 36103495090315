<template>
    <div class="container">
        <dd-card title="">
            <dd-form
                :form-config="formList"
                :labelWidth="'170px'"
                :flex="3"
                ref="assignForm_1"
                formRef="assignForm_1"
            ></dd-form>
        </dd-card>
        <dd-card title="合同结算单据列表">
            <dd-table :columns="tableColumns" :data="tableData" :isSelection="false" border :loading="loading">
            </dd-table>
        </dd-card>

        <dd-popup v-if="showDetail" width="78%" height="80%" showBtn inPopup :saveBtn="saveBtn" :submitBtn="false"
            :show="showDetail" title-name="详情"
            @popup-close="handleClose">
            <contractSettlementDetail ref="contractSettlementDetail" :rowData="rowData" slot="main"></contractSettlementDetail>
        </dd-popup>
    </div>
</template>

<script>
import contractSettlementDetail from './contractSettlementDetail.vue'
    export default {
        name: 'contractSettlement',
        props: ['detailData'],
        components: {contractSettlementDetail},
        data() {
            return {
                saveBtn: false,
                showDetail: false,
                formList: [
                    {
                        label: "合同总金额",
                        value: "foriginalamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同变更后总金额",
                        value: "ftotalamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同结算金额",
                        value: "settleFoftax",
                        inputType: "text",
                        disabled: true,
                    },
                ],
                loading: false,
                tableColumns: [
                    {
                        title: "单据编号",
                        key: "fnumber",
                        align: "center",
                        overflow: true,
                        width: 180,
                    },
                    {
                        title: "单据状态",
                        key: "fbillsateName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "期间",
                        key: "fperiodidName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "合同编码",
                        key: "purchaseInfoNum",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "合同名称",
                        key: "purchaseInfoName",
                        align: "center",
                        overflow: true,
                    },

                    {
                        title: "业务日期",
                        key: "fbizdate",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "本期金额(元)",
                        key: "foriginalamount",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "备注",
                        key: "fdescription",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "制单人",
                        key: "fcreatorName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "制单日期",
                        key: "fcreatetime",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "操作",
                        align: "center",
                        overflow: true,
                        event: [
                        {
                            state: "default",
                            label: "查看详情",
                            method: (row) => {
                                this.handleDetail(row);
                            },
                        },
                        ]
                    },
                ],
                rowData: {},
                tableData: []
            }
        },
        watch: {
            detailData() {
                this.initPageData()
            },
        },
        created() {

        },
        mounted() {

        },
        methods: {
            handleDetail(row) {
                this.showDetail = true;
                this.rowData = row;
            },
            handleClose() {
                this.showDetail = false;
            },
            initPageData() {
                this.$refs.assignForm_1.formData = {
                    foriginalamount: this.detailData.foriginalamount,
                    ftotalamount: this.detailData.ftotalamount,
                    settleFoftax: this.detailData.settleFoftax,
                }
                this.tableData = this.detailData.contractPurchaseInfoSettleVOList || []
            },
        }
    }
</script>

<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}

/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>