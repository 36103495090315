import { render, staticRenderFns } from "./AddEditPop.vue?vue&type=template&id=2a479fe2&scoped=true"
import script from "./AddEditPop.vue?vue&type=script&lang=js"
export * from "./AddEditPop.vue?vue&type=script&lang=js"
import style0 from "./AddEditPop.vue?vue&type=style&index=0&id=2a479fe2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a479fe2",
  null
  
)

export default component.exports