<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>

<script>
import { postApi, getApi, putApi } from "@/api/request";
import { getNowDate, getNowYear } from "@/utils/date";
export default {
  name: "add-pop",
  props: {
    rowData: [],
    titleName: "",
  },
  data() {
    return {
      formBasicList: [
        {
          label: "工作量汇总单",
          value: "workloadSumId",
          inputType: "select",
          defaultValue:'',
          optionsList: [],
          filterable: false,
          filterable: true,
          placeholderText: "请选择工作量汇总单",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择工作量汇总单",
              trigger: "blur",
            },
          ],
        },
      ],
      detailData: null,
    };
  },
  created() {},
  mounted() {
    // if (this.rowData != null) {
    //   this.getDataDetail(this.rowData.id);
    // }
    this.getDataType();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 获取工作量汇总单列表数据
      getApi(`/project/workloadsum/list`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "workloadSumId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
</style>
