<template>
  <div>
    <dd-card title="" titleStyle="display:none">
      <dd-table
        :columns="tableColumns"
        :data="tableDataSources"
        :isSelection="false"
        max-height="600"
        border
        show-page
      >
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "log-pop",
  props: {
    rowLogId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      // 表格选择数据集合
      multipleSelection: [],
      tableColumns: [
        {
          title: "流程",
          key: "operateTypeName",
          align: "center",
        },
        {
          title: "操作时间",
          key: "updateTime",
          align: "center",
        },
        {
          title: "操作人",
          key: "updateBy",
          align: "center",
        },
        {
          title: "原因",
          key: "description",
          align: "center",
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
        },
      ],
      tableDataSources: [],
    };
  },
  mounted() {
    getApi(`/finance/claims/record/${this.rowLogId}`)
      .then((res) => {
        let { data } = res;
        if (data.code == 0 && data.data) {
          this.tableDataSources = data.data;
        }
      })
      .catch((error) => {
        this.$message.error(error.response.data.msg);
      });
  },
  methods: {
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} pageSize 每页显示条数
     * @param {*} pageNumber 第几页
     */
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
    },
  },
};
</script>

<style lang="less" scoped>
</style>