<template>
  <div class="detail-container">
    <dd-card title="" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        border
        ref="dTableRef"
        @header-click="headerClick"
        :SetheaderCellClassName="SetheaderCellClassName"
        :loading="loading"
        :isSelection="false"
        :isSelectable="false"
      >
        <template #table-btn>
          <dd-button type="primary" size="medium" @click="DifferentCell()"
            >查看不一致项</dd-button
          >
          <dd-button type="primary" size="medium" icon="add" @click="allCell()"
            >全部查看</dd-button
          >
        </template>
      </dd-table>
    </dd-card>

    <dd-popup
          v-if="dialogEditDetailVisible"
          width="70%"
          height="80%"
          inPopup
          showBtn
          :saveBtn="false"
          :submitBtn="false"
          :show="dialogEditDetailVisible"
          :title-name="EditDetailTitle"
          @popup-close="dialogEditDetailVisible = false"
        >
          <DetailedBudgetDetails
            ref="DecomposeEdit"
            slot="main"
            :pageType="pageType"
            :propDetail="propDetailprop"
          ></DetailedBudgetDetails>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, postApi } from '@/api/request';
import DetailedBudgetDetails from "../../Details/components/DetailedBudgetDetails.vue"; // 详情

export default {
  props: ['propDetail'],
  components: {
    DetailedBudgetDetails
  },
  data() {
    return {
      loading: false,
      dialogEditDetailVisible: false,
      pageType: 'adjustment',
      EditDetailTitle: '',
      tableData: [],
      propDetailprop: {
        type: 'detail'
      },
      tableHeight: '900',
      tableDataCopy: [],
      columnsList: [
        {
          key: "costTypeCode",
          align: "center",
          title: "费用类型编号",
        },
        {
          key: "costTypeName",
          align: "center",
          title: "费用类型名称",
        },
        {
          title: "",
          align: "center",
          children: [
            {
                  title: '金蝶项目预算',
                  key: 'kingdeeAmount',
                  align: 'center',
                  styleEvent: {
                    method: (row) => {
                      return this.handleStyleEvent(row, ['kingdeeAmount', 'totalAmount']);
                    },
                  },
            },
            {
                  title: '项目合计',
                  key: 'totalAmount',
                  align: 'center',
                  styleEvent: {
                    method: (row) => {
                      return this.handleStyleEvent(row, ['kingdeeAmount', 'totalAmount']);
                    },
                  },
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getTableData();
  },
  mounted() {},
  methods: {
    getTableData() {
      postApi('/finance/finbudgetadjustable/compare/getbudgetkingdeecomparebyid', {prjId: this.propDetail.prjId}).then(res => {
        if(res.data.code == 0) {
          let { data } = res;
          this.columnsList[2].title = data.data[0].prjName;
          data.data.forEach((element, index) => {
            if(element.finBudgetAdjusdetailVOList?.length) {
              element.finBudgetAdjusdetailVOList.forEach((item, i) => {
                if(index == 0) {
                  this.columnsList[2].children.push({
                    key: 'value' + i,
                    title: item.assignName,
                    align: 'center',
                    width: 300,
                  })
                }
                
                element[`value${i}`] = item.budgetAmount
              })
              this.tableData = data.data;
              this.tableDataCopy = JSON.parse(JSON.stringify(data.data))
            }
          })
          
        }
      })
    },
    headerClick(row) {
      let id = null;
      for(let i = 0; i < this.tableDataCopy.length; i++) {
        let element = this.tableDataCopy[i].finBudgetAdjusdetailVOList;
        element.forEach(item => {
          if(item.assignName == row.label) {
            id = item.budgetCostDisassembleId
          }
        })
      };
      if(id) {
        this.dialogEditDetailVisible = true;
        this.EditDetailTitle = '详情';
        this.propDetailprop.id = id;
      }

      
    },
    handleStyleEvent(row, keys) {
      if(row[keys[0]] === row[keys[1]]) {
        return `color: #0000b9`
      }
      else {
        return `color: red`
      }
    },
    SetheaderCellClassName({row, column, rowIndex, columnIndex}) {
      if(columnIndex >= 2 && rowIndex == 1) {
        return 'blueColor'
      }
    },
    DifferentCell() {
      this.tableData.forEach(item => {
        if(item.kingdeeAmount === item.totalAmount) {
          item.kingdeeAmount = '';
          item.totalAmount = '';
          item.costTypeCode = '';
          item.costTypeName = ''
        }
      })
    },
    allCell() {
      this.tableData = JSON.parse(JSON.stringify(this.tableDataCopy));
    },
  },
};
</script>

<style lang="less" scoped>
  /deep/ .blueColor {
  color: blue !important;
  cursor: pointer !important;
}
/deep/.el-tabs__header {
  margin: 0;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #576289;
  border-bottom: none;
}
/deep/.el-tabs__item.is-active {
  color: @systemColor !important;
}
/deep/.el-tabs__item:hover {
  color: #000 !important;
}
/deep/.el-tabs__item.is-active:focus {
  color: @systemColor !important;
}
/deep/ .el-tabs__nav {
  width: 100%;
}
/deep/ .el-tabs__item {
  width: 25%;
  text-align: center;
}
</style>