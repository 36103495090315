<template>
    <div class="main-container">
      <dd-form labelWidth="100px" :form-config="formBasicList" flex="2" ref="addPopForm">
      </dd-form>
    </div>
  </template>
    
  <script>
  export default {
    name: "add-pop",
    props: {
      dictType: [],
      dictKey: {
        type: String,
        default: null
      },
      rowData: []
    },
    data() {
      return {
        //项目列表
        project: [],
        formBasicList: [
          {
            label: "标识",
            value: "dictKey",
            inputType: "text",
            placeholderText: "请输入标识",
            disabled: true,
            defaultValue: this.dictKey,
            rules: [
              {
                required: true,
                message: "请输入标识",
                trigger: "blur",
              },
            ],
          },
          {
            label: "数据值",
            value: "value",
            inputType: "text",
            placeholderText: "请输入数据值",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入标识",
                trigger: "blur",
              },
            ],
          },
          {
            label: "标签名",
            value: "label",
            inputType: "text",
            placeholderText: "请输入标签名",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入标签名",
                trigger: "blur",
              },
            ],
          },
          {
            label: "展示类型",
            value: "type",
            disabled: false,
            defaultValue: '',
            inputType: "select",
            optionsList: [],
            filterable: false,
            placeholderText: "请选择展示类型",
            optionsListConfig: {
              name: "label",
              code: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择展示类型",
                trigger: "blur",
              },
            ],
          },
          {
            label: "描述",
            value: "description",
            inputType: "text",
            placeholderText: "请输入描述",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入描述",
                trigger: "blur",
              },
            ],
          },
          {
            label: "排序",
            value: "sortOrder",
            inputType: "text",
            placeholderText: "请输入排序",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
          {
            label: "备注信息",
            value: "remark",
            inputType: "text",
            placeholderText: "请输入备注信息",
            disabled: false,
            defaultValue: '',
          },
        ],
      };
    },
    created() { },
    mounted() {
      if (this.rowData != null) {
        this.formBasicList.forEach(item => {
          item.defaultValue = this.rowData[item.value]
          if (item.value == 'dictKey') {
            item.disabled = true;
          }
        })
      }
      let options1 = {
        value: "type",
        optionsList: this.dictType,
      };
      this.setOPtionsList(this.formBasicList, options1);
    },
    methods: {
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
        formList.forEach((item) => {
          if (options) {
            if (item.value == options.value) {
              item.optionsList = options.optionsList;
            }
          }
        });
      },
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
    