<template>
  <div class="task-resolve-detail">
    <dd-card title="审批流程" spacer>
      <el-steps :active="activeIndex" align-center>
        <el-step
          v-for="(val, idx) in activityList"
          :key="idx"
          :title="val.activityName"
        >
          <div slot="description" class="description">
            <div
              v-for="(item, index) in val.description"
              :key="index"
              class="list"
            >
              <div class="flex_c">
                {{ item.time }}
                <div class="name">{{ item.userName }} 已审核</div>
              </div>
              <div>意见 {{ item.fullmessage }}</div>
            </div>
          </div>
        </el-step>
      </el-steps>
      <dd-form
        :form-config="formTaskBasicList"
        ref="taskBasicForm"
        formRef="taskBasicForm"
      >
      </dd-form>
    </dd-card>

    <div class="tab-container">
      <div class="left-tree container-left-tree">
        <el-tree
          ref="tree"
          :data="TreeList"
          accordion
          :props="defaultProps"
          default-expand-all
          highlight-current
          :expand-on-click-node="false"
          node-key="id"
          :current-node-key="treeNodeId"
        >
        </el-tree>
      </div>
      <div class="right-table">
        <dd-card class="table-tab">
          <el-tabs
            v-model="workActiveName"
            type="card"
            @tab-click="handleTabClick"
          >
            <el-tab-pane
              v-for="val in workTabList"
              :key="val.value"
              :label="val.label"
              :name="val.value"
            ></el-tab-pane>
          </el-tabs>
        </dd-card>
        <!-- 节点管理信息 -->
        <dd-card v-if="workActiveName == 'jdgl'" height="100%">
          <dd-table
            :columns="nodeTableColumns"
            :data="nodeTableData"
            :topBtn="false"
            border
            :isSelection="false"
            :loading="loading"
            :tree-props="{ children: 'children' }"
            default-expand-all
          >
          </dd-table>
        </dd-card>
        <!-- 文档控制信息 -->
        <dd-card v-if="workActiveName == 'wdkz'" height="100%">
          <dd-table
            :columns="docTableColumns"
            :data="docTableData"
            :topBtn="false"
            border
            :isSelection="false"
            :loading="loading"
            :tree-props="{ children: 'children' }"
            default-expand-all
          >
          </dd-table>
        </dd-card>
        <!-- 估算与计划信息 -->
        <dd-card v-if="workActiveName == 'gsyjh'" height="100%">
          <dd-table
            :columns="planInfoTableColumns"
            :data="planInfoTableData"
            :topBtn="false"
            border
            :isSelection="false"
            :loading="loading"
            :tree-props="{ children: 'children' }"
            default-expand-all
          >
          </dd-table>
        </dd-card>
      </div>
    </div>
  </div>
</template>

<script>
import { getApi, postApi, putApi } from "@/api/request";
export default {
  props: {
    rowId: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      activeIndex: 0,
      activityList: [],
      formTaskBasicList: [
        {
          label: "审批意见",
          value: "comment",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          placeholderText: "请输入审批意见",
          rules: [
            {
              required: true,
              message: "请输入审批意见",
              trigger: "blur",
            },
          ],
        },
      ],
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      treeNodeId: "",
      treeNodeParentId: "",
      loading: true,
      workActiveName: "jdgl",
      workTabList: [
        {
          label: "节点管理信息",
          value: "jdgl",
        },
        {
          label: "文档控制信息",
          value: "wdkz",
        },
        {
          label: "估算与计划信息",
          value: "gsyjh",
        },
      ],
      nodeTableColumns: [
        {
          title: "节点编号",
          key: "nodeCode",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
          overflow: true,
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
        },
        {
          title: "节点状态",
          key: "nodeStateName",
          align: "center",
        },
        {
          title: "生产分管部门",
          key: "nodeDeptBranchName",
          align: "center",
        },
        {
          title: "部门",
          key: "nodeDeptName",
          align: "center",
        },
        {
          title: "负责人",
          key: "nodeUser",
          align: "center",
        },
        {
          title: "供应商",
          key: "purName",
          align: "center",
        },
      ],
      nodeTableData: [],
      docTableColumns: [
        {
          title: "节点编号",
          key: "nodeCode",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
          overflow: true,
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
        },
        {
          title: "必须提交的文档",
          key: "documentArr",
          align: "center",
          formatterArr: (row) => {
            return this.getArrItem(row);
          },
        },
        {
          title: "其他成果备注",
          key: "achieveRemark",
          align: "center",
        },
      ],
      docTableData: [],
      documentArr: [],
      planInfoTableColumns: [
        {
          title: "节点编号",
          key: "nodeCode",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
          overflow: true,
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
        },
        {
          title: "预估总工作量",
          key: "planWorkload",
          align: "center",
          width:'120',
        },
        {
          title: "工作量单位",
          key: "nodeUnit",
          align: "center",
          formatter: (row) => {
            return this.getUnitItem(row);
          },
          width:"100",
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
          width:"80",
        },
        {
          title: "预估标准工天",
          key: "planStandardWorkday",
          align: "center",
          overflow: true,
          width:"110",
        },
        // {
        //   title: "已分配父节点预估标准工天",
        //   key: "assignedStandardWorkday",
        //   align: "center",
        //   overflow: true,
        //   width:"200",
        // },
        // {
        //   title: "剩余父节点预估标准工天",
        //   key: "remainStandardWorkday",
        //   align: "center",
        //   overflow: true,
        //   width:"180",
        // },
        {
          title: "标准工天单价",
          key: "standardWorkdayPrice",
          align: "center",
          width:"110",
        },
        {
          title: "计划开始时间",
          key: "planStarttime",
          align: "center",
          width:"110",
        },
        {
          title: "计划结束时间",
          key: "planEndtime",
          align: "center",
          width:"110",
        },
        {
          title: "预估绩效",
          key: "planPriceTotal",
          align: "center",
        },
        {
          title: "预估计工作量单价(元)",
          key: "planPriceUnit",
          align: "center",
          width:"170",
        },

        {
          title: "计划权重",
          key: "planSchedulePercent",
          align: "center",
        },
      ],
      planInfoTableData: [],
      unitArr: [],
    };
  },
  created() {
    this.getDataType();
  },
  mounted() {
    this.getTaskResolveData();
  },
  methods: {
    // 获取子任务模式分解数据
    getTaskResolveData() {
      getApi(`/activiti/process/viewPage/${this.rowId}`).then((res) => {
        if (res.data.code == 0) {
          this.activityList = res.data.data;
        }
      });
      getApi(`/project/subplannode/${this.rowId}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          if (data.data.taskNodes) {
            this.activityList.forEach((item, index) => {
              item.description = [];
              if (item.isCurrentActiviti == 1) {
                this.activeIndex = index + 1;
              }
              data.data.taskNodes.forEach((val) => {
                if (item.activityName == val.taskName) {
                  item.description.push(val);
                }
              });
            });
          }
          this.TreeList = data.data.tree;
          this.treeNodeId = data.data.tree[0].id;
          this.treeNodeParentId = data.data.tree[0].parentId;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
          });
          this.nodeTableData = data.data.tree;
          this.docTableData = data.data.tree;
          this.planInfoTableData = data.data.tree;
          this.loading = false;
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 文档类型列表
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-RWJHJDWD" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let arr = [];
            data.data.forEach((item) => {
              let obj = {
                label: item.name,
                value: item.code,
              };
              arr.push(obj);
            });
            this.documentArr = arr;
          }
        }
      );
      // 工作量单位
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GZBDW" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let arr = [];
            data.data.forEach((item) => {
              let obj = {
                label: item.name,
                value: item.code,
              };
              arr.push(obj);
            });
            this.unitArr = arr;
          }
        }
      );
    },
    // Tab切换事件
    handleTabClick(tab, event) {
      this.$nextTick(() => {
        this.tableHeight = this.autoTableHeight(0);
      });
      this.workActiveName = tab.name;
    },
    getArrItem(row) {
      let arr = [];
      this.documentArr.forEach((item) => {
        row &&
          row.forEach((val) => {
            if (val == item.value) {
              arr.push(item.label);
            }
          });
      });

      return arr;
    },
    getUnitItem(row) {
      let label = null;
      this.unitArr.forEach((item) => {
        if (row == item.value) {
          label = item.label;
        }
      });
      return label;
    },
  },
};
</script>

<style lang="less" scoped>
.task-resolve-detail {
  height: 100%;

  .tab-container {
    height: 100%;
    display: flex;
    .left-tree {
      width: 20%;
      height: 100%;
      padding: 10px 15px;
      box-sizing: border-box;
      border-right: 5px solid #e7eaec;
      overflow-y: auto;
      .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: @fs14;

        .node-hanlde {
          color: @defaultFontColor;
          i {
            margin: 0 5px;
          }
        }
      }
    }
    .right-table {
      width: 80%;
      height: 150%;
      .table-tab {
        /deep/ .el-tabs__item {
          width: 33%;
        }
        /deep/ .card-contant {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-bottom: 0;
          .el-tabs {
            width: 150%;
          }
        }
        /deep/ .el-tabs__header {
          margin: 0;
        }
      }
    }
    .right-table {
      /deep/ .el-input__suffix {
        display: none !important;
      }
      /deep/ .el-input__prefix {
        display: none !important;
      }
      /deep/.el-table__expand-icon {
        display: none !important;
      }
      /deep/ .el-table__indent {
        display: none !important;
      }
      /deep/.el-table__placeholder {
        display: none !important;
      }
    }
    /deep/ .el-date-editor--date {
      width: 100% !important;
    }
    /deep/ .el-input__inner {
      padding: 0 5px !important;
    }
  }
  /deep/ .el-table__body-wrapper{
  height: auto !important;
}
}
</style>
