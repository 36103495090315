<template>
  <div>
    <dd-card title="节点列表">
      <dd-table
        :topBtn="false"
        :columns="tableColumns"
        :data="tableDataSources"
        max-height="400"
        border
        @select-change="handleSelectionChange"
        ref="test"
        :tableRef="tableRef"
      >
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import { getNowMonth } from "@/utils/date";
export default {
  name: "expense-apply",
  props: {
    selectDataObj: {
      type: Object,
      default: null,
    },
    filterSelection: {
      type: Array,
      default: [],
    },
    assignId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableRef: "expleApplyPop",
      // 表格选择数据集合
      multipleSelection: [],
      formList: [],
      tableColumns: [
        {
          title: "所属节点序号",
          key: "nodeCodeParent",
          align: "center",
        },
        {
          title: "所属节点名称",
          key: "nodeNameParent",
          align: "center",
        },
        {
          title: "节点编号",
          key: "nodeCode",
          align: "center",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
        },
        {
          title: "节点状态",
          key: "nodeStateName",
          align: "center",
        },
        {
          title: "工作量单位",
          key: "nodeUnitName",
          align: "center",
        },
        {
          title: "总工作量",
          key: "planWorkloadSum",
          align: "center",
        },
        {
          title: "总标准工天",
          key: "planStandardWorkdaySum",
          align: "center",
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
        },
        {
          title: "已完成量",
          key: "realWorkloadSum",
          align: "center",
        },
        {
          title: "已完成标准工天",
          key: "realStandardWorkdaySum",
          align: "center",
        },
        {
          title: "负责部门",
          key: "nodeDeptName",
          align: "center",
        },
      ],
      tableDataSources: [],
      //多选集合
      multipleSelectionId: [],
      //默认选择回显
      toggleSelectionData: [],
      loading: true,
    };
  },
  mounted() {
    this.getTableDataList();
  },
  methods: {
    //初始化数据
    getTableDataList() {
      getApi(
        `/project/subplan/getprjassignperiodplanaddlist/${this.assignId}`
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableDataSources = data.data;
        }
      });
    },
    //默认选择回显
    toggleSelection(rows) {
      let { expleApplyPop } = this.$refs.test.$refs;
      if (rows) {
        rows.forEach((row) => {
          this.$refs.test.$refs.baseTable.toggleRowSelection(row);
        });
      }
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.multipleSelectionId = val.id;
    },
    //获取默认选择状态回显数据
    getToggleSelection(data) {
      if (data) {
        this.toggleSelectionData = this.tableDataSources.filter((item1) =>
          data.find((item2) => item2.id === item1.id)
        );
        this.toggleSelection(this.toggleSelectionData);
      }
    },
  },
};
</script>
