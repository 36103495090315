/*
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-08-21 17:07:00
 * @LastEditors: hechen
 * @LastEditTime: 2023-10-08 10:42:56
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\utils\index.js
 * @Descripttion: 
 */
/**
 * 判断路由是否相等
 */
 export const diff = (obj1, obj2) => {
    delete obj1.close;
    var o1 = obj1 instanceof Object;
    var o2 = obj2 instanceof Object;
    if (!o1 || !o2) {
        /*  判断不是对象  */
        return obj1 === obj2;
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
        // Object.keys() 返回一个由对象的自身可枚举属性(key值)组成的数组,例如：数组返回下表：let arr = ["a", "b", "c"];console.log(Object.keys(arr))->0,1,2;
    }

    for (var attr in obj1) {
        var t1 = obj1[attr] instanceof Object;
        var t2 = obj2[attr] instanceof Object;
        if (t1 && t2) {
            return diff(obj1[attr], obj2[attr]);
        } else if (obj1[attr] !== obj2[attr]) {
            return false;
        }
    }
    return true;
};

/* 对象数组去重 */
export const resetArray = (arr) => {
    //reduce第一个参数是遍历需要执行的函数，第二个参数是item的初始值
    var obj = {};
    arr = arr.reduce(function (item, next) {
        obj[next.id] ? '' : obj[next.id] = true && item.push(next);
        return item;
    }, []);
    console.log(arr);
    return arr
};
export const downloadBinaryFile = (data, filename) => {
    const blob = new Blob([data], {
        type: 'application/octet-stream'
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
};
 /* 获取嵌套对象中的属性值 */
 export const getObjValue = (row, key) => {
    if(key.indexOf('.') != -1){
        let keyTemp = key.substring(0, key.indexOf('.'));
        key = key.substring(key.indexOf('.') + 1, key.length);

        if(row[keyTemp]){
            return getObjValue(row[keyTemp], key);
        }

        return row[key];
    }else {
        return row[key];
    }
};