<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" :flex="3" ref="addPopForm"> </dd-form>
    <el-transfer
      v-model="value"
      filterable
      :titles="['人员列表', '已选人员']"
      :button-texts="['删除', '添加']"
      :format="{
        noChecked: '${total}',
        hasChecked: '${checked}/${total}',
      }"
      @change="handleChange"
      :data="dataList"
    >
      <span slot-scope="{ option }">{{ option.label }}</span>
    </el-transfer>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowData: null,
  },
  data() {
    return {
      formBasicList: [
        {
          label: "部门",
          value: "nodeDeptId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          change: this.getPersonList,
        },
      ],
      dataList: [],
      value: [],
      selectPersonDataList: [],
    };
  },
  created() {},
  mounted() {
    this.getDataType();
    this.value = this.rowData.subplanStakeholderArr || [];
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleChange(value, direction, movedKeys) {
      this.selectPersonDataList = value;
      console.log(value, direction, movedKeys);
    },
    // 获取数据字典类型
    getDataType() {
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "nodeDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      this.getPersonList();
    },
    getPersonList(id) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId: id,
      }).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          const arr = [];
          data.data.forEach((item) => {
            arr.push({
              key: item.userId,
              label: item.fullName,
            });
          });
          this.dataList = arr;
        }
      });
    },
    getChildDeptPersonList(deptBranchId) {
      getApi(`/admin/user/getuserlistbysysdeptbranch`, {
        deptBranchId: deptBranchId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "nodeDeptBranchId") {
              item.defaultValue = deptBranchId;
            } else {
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
          });
          const arr = [];
          data.data.forEach((item) => {
            arr.push({
              key: item.userId,
              label: item.fullName,
            });
          });
          this.dataList = arr;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-transfer {
  height: calc(100% - 60px);
  display: flex;
  align-items: center;
}
/deep/.el-transfer-panel {
  height: 100%;
  width: 40%;
}
/deep/.el-transfer__buttons {
  width: 20%;
  box-sizing: border-box;
  text-align: center;
}
/deep/.el-transfer-panel__body {
  height: calc(100% - 40px);
  .el-transfer-panel__filter {
    height: 32px;
  }
  .el-transfer-panel__list {
    height: calc(100% - 32px);
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
}
/deep/.el-transfer__button {
  margin-bottom: 0;
  padding: 6px 8px;
  span {
    margin: 0;
  }
}

/deep/.el-transfer-panel__item.el-checkbox {
  width: 90px;
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: @themeColor;
}
</style>
