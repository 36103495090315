<template>
  <div class="main-container">
    <dd-form
      :form-config="formManageList"
      :flex="2"
      labelWidth="230px"
      ref="addPopForm"
    >
    </dd-form>
    <dd-form style="display:none;"
      :form-config="formBasicListKh"
      :flex="3"
      labelWidth="230px"
      ref="addPopForm1"
    >
    </dd-form>
    <div style="display:none;">
      <dd-form
      :form-config="formManageListBx"
      :flex="3"
      labelWidth="230px"
      ref="formManageListBx"
    >
    </dd-form>
    </div>
  </div>
</template>

<script>
import { postApi, getApi, putApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowData: [],
  },
  data() {
    return {
      formManageList: [
        {
          label: "生产任务编码",
          value: "assignNum",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: "",
          placeholderText: "输入生产任务编码后四位后自动生成",
          // rules: [
          //   {
          //     required: true,
          //     message: "请输入生产任务编码",
          //     trigger: "blur",
          //   },
          // ],
        },
        // {
        //   label: "生产任务编码后四位编号",
        //   value: "serialNumber",
        //   inputType: "text",
        //   // disabled: true,
        //   defaultValue: "",
        //   placeholderText: "请输入生产任务编码后四位编号",
        //   rules: [
        //     {
        //       required: true,
        //       message: "请输入生产任务编码后四位编号",
        //       trigger: "blur",
        //     },
        //     {
        //       required: true,
        //       pattern: /^\d{4}$/,
        //       message: "只能输入4位整数",
        //       trigger: "blur",
        //     }
        //   ],
        // },
        {
          label: "项目生产需求级别",
          value: "assignLevel",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择项目生产需求级别",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择项目生产需求级别",
              trigger: "blur",
            },
          ],
        },
        {
          label: "业务类型",
          value: "businessTypeId",
          inputType: "cascader",
          optionsList: [],
          defaultValue: "",
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
          },
          showAllSelcet: false,
          placeholderText: "请选择业务类型",
          rules: [
            {
              required: true,
              message: "请选择业务类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "生产控制模式",
          value: "produceType",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择生产控制模式",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择生产控制模式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "现下发分管部门",
          value: "nowDownFenDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择现下发分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择现下发分管部门",
              trigger: "blur",
            },
          ],
          change: this.getDownFenDeptId,
        },
        {
          label: "现下发分管领导",
          value: "nowDownFenDeptLeaderId",
          inputType: "select",
          optionsList: [],
          disabled:true,
          placeholderText: "请选择现下发分管领导",
          optionsListConfig: {
            code: "leaderId",
            name: "leaderName",
          },
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请选择现下发分管领导",
              trigger: "blur",
            },
          ],
        },
        {
          label: "现下发负责部门",
          value: "nowDownDeptId",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          filterable: true,
          placeholderText: "请选择现下发负责部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: true,
              message: "请选择现下发负责部门",
              trigger: "blur",
            },
          ],
          change: this.getDownDeptId,
        },
        {
          label: "现客户经理",
          value: "nowCmmmanagerId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          disabled: false,
          defaultValue: "",
          placeholderText: "请选择现客户经理",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          rules: [
            {
              required: true,
              message: "请选择现客户经理",
              trigger: "blur",
            },
          ],
          change:this.modifyNowCmmmanagerId
        },
        {
          label: "现生产分管部门",
          value: "nowReceiveFenDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择现生产分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择现生产分管部门",
              trigger: "blur",
            },
          ],
          change: this.getReceiveFenDeptId,
        },
        {
          label: "现生产分管领导",
          value: "nowReceiveFenDeptLeaderId",
          inputType: "select",
          defaultValue: "",
          optionsList: [],
          disabled:true,
          placeholderText: "请选择现生产分管领导",
          optionsListConfig: {
            code: "leaderId",
            name: "leaderName",
          },
          rules: [
            {
              required: true,
              message: "请选择现生产分管领导",
              trigger: "blur",
            },
          ],
        },
        {
          label: "现生产负责部门",
          value: "nowReceiveDeptId",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择现生产负责部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          change: this.getNowPersonList,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请选择现生产负责部门",
              trigger: "blur",
            },
          ],
        },
        {
          label: "现项目经理",
          value: "nowLeaderId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择现项目经理",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          rules: [
            {
              required: true,
              message: "请选择现项目经理",
              trigger: "blur",
            },
          ],
        },
      ],
      formManageListBx:[
        {
          label: "现下发分管领导",
          value: "nowDownFenDeptLeaderId",
          inputType: "select",
          optionsList: [],
          disabled:true,
          placeholderText: "请选择现下发分管领导",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          defaultValue: "",
        },
        {
          label: "现生产分管领导",
          value: "nowReceiveFenDeptLeaderId",
          inputType: "select",
          defaultValue: "",
          optionsList: [],
          disabled:true,
          placeholderText: "请选择现生产分管领导",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
        },
      ],
      formBasicListKh:[
        {
          label: "客户名称",
          value: "cmmId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户名称",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择客户名称",
              trigger: "blur",
            },
          ],
          remote: true,
          remoteMethod: (query) => {
            this.getCrmList(query);
          },
          change: this.getCrmPerson,
        },
        {
          label: "客户联系人",
          value: "crmContactId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户联系人",
          optionsListConfig: {
            code: "id",
            name: "name",
          },
          rules: [
            {
              required: true,
              message: "请选择客户联系人",
              trigger: "blur",
            },
          ],
          change: this.selectContactPhone
        },
        {
          label: "个人联系方式",
          disabled: true,
          value: "contactPhone",
          defaultValue: '',
          inputType: "text",
          rules: [
            {
              required: false,
              message: "请输入个人联系方式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "工作联系方式",
          value: "crmContactDept",
          inputType: "text",
          disabled: true,
          defaultValue: "",

        },
      ],
      contactPhoneList:{},
      detailData: null,
      fenDeptList:[], // 分管列表，查询分类领导
    };
  },
  created() {
    this.getFenDeptList();
  },
  mounted() {
    if (this.rowData != null) {
      this.getDataDetail(this.rowData.id);
      if(this.rowData.verifyStatus == "PRJ-RWSHZT-SHTG" && this.rowData.prjAssignSubplanList.length > 0){
          this.formManageList.forEach(item =>{
            if (item.value == "businessTypeId" || item.value == "produceType") {
              item.disabled = true;
              item.rules[0].required = false;
            }
          })  
      }
    }
    this.getDataType();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 生产控制模式
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-SCKZMS" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "produceType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formManageList, options);
          }
        }
      );
      // 项目生产需求级别
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJB" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "assignLevel",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formManageList, options);
          }
        }
      );
      // -------------------------------------------------
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options1 = {
            value: "nowReceiveDeptId",
            optionsList: data.data,
          };
          let options2 = {
            value: "nowDownDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formManageList, options1);
          this.setOPtionsList(this.formManageList, options2);
        }
      });
      // 生产实施状态
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJFZT" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "produceStatus",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formManageList, options);
          }
        }
      );
      this.getCrmList()
    },
    getCrmList(name, type) {
      let params = {
        name: name,
      };
      console.log('name', name)
      postApi(`/project/customerinfo/list`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicListKh.forEach((item) => {
            if (item.value == "cmmId") {
              item.optionsList = data.data;
              console.log('data222', data.data)
              // if (type != "init") {
              //   item.defaultValue = null;
              // }
            }
            else {
              // item.defaultValue = this.$refs.addPopFormKh.formData[item.value];
            }
          });
        }
      });
    },
    
    // 获取客户联系人
    // getCrmPerson(id) {
    //   let params = {
    //     crmId: id,
    //   };
    //   getApi(`/project/assign/basic/crm/contact`, params).then((res) => {
    //     let { data } = res;
    //     if (data && data.code == 0) {
    //       this.contactPhoneList = data.data;
    //       let options = {
    //         value: "crmContactId",
    //         optionsList: data.data,
    //       };
    //       this.formBasicListKh[1].optionsList = data.data
    //       // this.setOPtionsList(this.formBasicListKh, options);
    //     }
    //   });
    // },
    getCrmPerson(id, type) {
      this.formBasicListKh.forEach((item) => {
        if(item.value == 'crmContactId'){
          item.defaultValue = '';
        }else if(item.value == 'contactPhone'){
          item.defaultValue = '';
        }else {
          item.defaultValue = this.$refs.addPopForm1.formData[item.value];
        }
      })
      if(id != ''){
        let params = {
          crmId: id,
        };
       postApi(`/project/customercontactdetail/list`, params).then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.contactPhoneList = data.data;
            this.formBasicListKh.forEach((item) => {
              if (item.value == "crmContactId") {
                item.optionsList = data.data;
                // if (type != "init") {
                //   item.defaultValue = null;
                // }
              }
              else{
                item.defaultValue = this.$refs.addPopForm1.formData[item.value];
              }
            });
          }
        });
      }
    },
    // 选择联系人
    selectContactPhone(id){
      this.formBasicListKh.forEach((item) => {
        if(!id) {
          if (item.value == 'contactPhone' || item.value == 'crmContactDept') {
          item.defaultValue = '';
          } else {
            item.defaultValue = this.$refs.addPopForm1.formData[item.value];
          }
        }
      })
      this.contactPhoneList.forEach(item => {
        if (item.id == id) {
          console.log('item', item)
          this.$refs.addPopForm1.formData.contactPhone = item.personalContact
          this.$refs.addPopForm1.formData.crmContactDept = item.workContact
        }
      })
      // this.contactPhoneList.forEach(obj => {
      //   if(obj.id == id){
      //     this.formBasicListKh.forEach((item) => {
      //       if (item.value == "crmContactId") {
      //         item.defaultValue = obj.id;
      //       }
      //       if (item.value == "contactPhone") {
      //         item.defaultValue = obj.phone;
      //       }
      //     })
      //   }
      // })
    },
    // 业务类型
    getBusinessType(){
      getApi(`/project/prjareasubassginbussitype/tree`, {}).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "businessTypeId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formManageList, options);
        }
      });
    },
    getFenDeptList(){
      getApi(`/admin/sysfendept/getList`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "nowDownFenDeptId",
            optionsList: data.data
          };
          this.fenDeptList = data.data;
          this.setOPtionsList(this.formManageList, options);
          let options1 = {
            value: "nowReceiveFenDeptId",
            optionsList: data.data
          };
          this.setOPtionsList(this.formManageList, options1);

          let options2 = {
            value: "nowReceiveFenDeptLeaderId",
            optionsList: data.data
          };
          this.setOPtionsList(this.formManageList, options2);

          let options3 = {
            value: "nowDownFenDeptLeaderId",
            optionsList: data.data
          };
          this.setOPtionsList(this.formManageList, options3);
        }
      });
    },
    // 根据 现下发负责部门 获取 现客户经理 联动
    getDownDeptId(deptId){
      this.formManageList.forEach(item =>{
        if (item.value == "nowCmmmanagerId") {
          item.defaultValue = '';
        }
        else{
          item.defaultValue = this.$refs.addPopForm.formData[item.value];
        } 
      })
      if(deptId != ""){
        postApi(`/admin/user/getuserlist/bydeptid`, { deptId: deptId }).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formManageList.forEach((item) => {
              if (item.value == "nowDownDeptId") {
                item.defaultValue = deptId;
              }
              if (item.value == "nowCmmmanagerId") {
                item.optionsList = data.data;
                // if(deptId != this.detailData.nowDownDeptId){
                //   item.defaultValue = '';
                // }
              }
            })
          }
        });
      }
    },
    // 现客户经理
    modifyNowCmmmanagerId(id){
      this.formManageList.forEach((item) => {
        if (item.value == "nowCmmmanagerId") {
          item.defaultValue = id;
        }
      })
    },
    // 根据 现下发分管部门 获取 现下发分管领导 现下发负责部门 联动
    getDownFenDeptId(deptId){
      this.formManageList.forEach(item =>{
        if (item.value == "nowDownFenDeptLeaderId") {
          item.defaultValue = '';
        }
        else if(item.value == "nowDownDeptId"){
          item.defaultValue = '';
        }
        else if(item.value == "nowCmmmanagerId"){
          item.defaultValue = '';
        }
        else{
          item.defaultValue = this.$refs.addPopForm.formData[item.value];
        } 
      })
      if(deptId != ""){
        // 选择 现下发分管领导
        this.fenDeptList.forEach(val => {
          if(val.id == deptId){
            this.formManageList.forEach((item) => {
                if (item.value == "nowDownFenDeptLeaderId"){
                  item.defaultValue = val.leaderId;
                }
                if(item.value == 'nowDownFenDeptId'){
                  item.defaultValue = val.id;
                }
            })
            this.formManageListBx.forEach((item) => {
                if (item.value == "nowDownFenDeptLeaderId"){
                  item.defaultValue = val.leaderId;
                }
            })
          }
        })
        // 现下发负责部门
        getApi(`/admin/dept/getbydepid/${deptId}`, {}).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formManageList.forEach((item) => {
              if (item.value == "downFenDeptId") {
                item.defaultValue = deptId;
              }else{
                item.defaultValue = this.$refs.addPopForm.formData[item.value];
              } 
              if(item.value == 'nowDownDeptId'){
                item.optionsList = data.data;
              }
              if(deptId != this.detailData.nowDownDeptId){
                // if (item.value == "nowDownFenDeptLeader"){
                //   item.defaultValue = '';
                // }
                if(item.value == "nowDownDeptId"){
                  item.defaultValue = '';
                }
                if(item.value == "nowCmmmanagerId"){
                  item.defaultValue = '';
                }
              }
            })
          }
        })
      }
    },
    // 根据 现生产分管部门 获取 现生产分管领导 联动
    getReceiveFenDeptId(deptId){
      this.formManageList.forEach(item =>{
        if (item.value == "nowReceiveFenDeptLeaderId") {
          item.defaultValue = '';
        }
        else if(item.value == "nowReceiveDeptId"){
          item.defaultValue = '';
        }
        else if(item.value == "nowLeaderId"){
          item.defaultValue = '';
        }
        else{
          item.defaultValue = this.$refs.addPopForm.formData[item.value];
        } 
      })
      if(deptId != ""){
        // 选择 现下发分管领导
        this.fenDeptList.forEach(val => {
          if(val.id == deptId){
            this.formManageList.forEach((item) => {
                if (item.value == "nowReceiveFenDeptLeaderId"){
                  item.defaultValue = val.leaderId;
                }
            })
            this.formManageListBx.forEach((item) => {
              if (item.value == "nowReceiveFenDeptLeaderId"){
                item.defaultValue = val.leaderId;
              }
            })
          }
        })
        // 现生产负责部门
        getApi(`/admin/dept/getbydepid/${deptId}`, {}).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formManageList.forEach((item) => {
              if (item.value == "nowReceiveFenDeptId") {
                item.defaultValue = deptId;
              }else{
                item.defaultValue = this.$refs.addPopForm.formData[item.value];
              }

              if(item.value == 'nowReceiveDeptId'){
                item.optionsList = data.data;
              }

              if(deptId != this.detailData.nowDownDeptId){
                // if (item.value == "nowReceiveFenDeptLeader"){
                //   item.defaultValue = '';
                // }
                if(item.value == "nowReceiveDeptId"){
                  item.defaultValue = '';
                }
                if(item.value == "nowLeaderId"){
                  item.defaultValue = '';
                }
              }
            })
          }
        })
      }
    },
    //现生产负责人列表
    getNowPersonList(deptId, type) {
      this.formManageList.forEach(item =>{
        if (item.value == "nowLeaderId") {
          item.defaultValue = '';
        }
        else{
          item.defaultValue = this.$refs.addPopForm.formData[item.value];
        } 
      })
      if(deptId != ""){
        postApi(`/admin/user/getuserlist/bydeptid`, {
          deptId: deptId,
        }).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formManageList.forEach((item) => {
              if (item.value == "nowReceiveDeptId") {
                item.defaultValue = deptId;
              } 
              // else {
              //   item.defaultValue = this.$refs.addPopForm.formData[item.value];
              // }
              if (item.value == "nowLeaderId") {
                item.optionsList = data.data;
                if (type != "init") {
                  item.defaultValue = null;
                }
              }
            });
          }
        });
      }
    },
    // 获取现客户经理列表数据
    getNowClientJlList(deptId, type) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId: deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formManageList.forEach((item) => {
            if (item.value == "nowDownDeptId") {
              item.defaultValue = deptId;
            } else {
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
            if (item.value == "nowCmmmanagerId") {
              item.optionsList = data.data;
              // if (type != "init") {
              //   item.defaultValue = null;
              // }
            }
          });
        }
      });
    },
    getDataDetail(id) {
      getApi(`/project/assign/${id}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.detailData = data.data;
          console.log('this.detailData', this.detailData)
          this.getNowClientJlList(data.data.nowDownDeptId, "init");
          this.getNowPersonList(data.data.nowReceiveDeptId, "init");
          this.getCrmPerson(data.data.cmmId);
          
          this.getBusinessType();
          if(data.data.nowDownFenDeptId) {
            let deptId = data.data.nowDownFenDeptId;
            getApi(`/admin/dept/getbydepid/${deptId}`, {}).then((res) => {
                let { data } = res;
                if (data.code == 0) {
                  this.formManageList.forEach((item) => {
                    if (item.value == "downFenDeptId") {
                      item.defaultValue = deptId;
                    }else{
                      item.defaultValue = this.$refs.addPopForm.formData[item.value];
                    } 
                    if(item.value == 'nowDownDeptId'){
                      item.optionsList = data.data;
                    }
                  })
                }
              })
          }
          if(data.data.nowReceiveFenDeptId) {
            let deptId = data.data.nowReceiveFenDeptId
            getApi(`/admin/dept/getbydepid/${deptId}`, {}).then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.formManageList.forEach((item) => {
                  if (item.value == "nowReceiveFenDeptId") {
                    item.defaultValue = deptId;
                  }else{
                    item.defaultValue = this.$refs.addPopForm.formData[item.value];
                  }

                  if(item.value == 'nowReceiveDeptId'){
                    item.optionsList = data.data;
                  }
                })
              }
            })
          }
          this.getDownDeptId(data.data.downDeptId);
          this.formManageList.forEach((item) => {
            item.defaultValue = data.data[item.value];
          });
          // this.$refs.addPopForm.formData.serialNumber = data.data.serialNumber
          this.formBasicListKh.forEach((item) => {
            item.defaultValue = data.data[item.value];
          });
          this.getCrmList(data.data.cmmName)
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}
/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
</style>
