<template>
  <div class="main-container">
      <!-- <dd-card title="" titleStyle="display:none"> -->
        <dd-table
          ref="detailByProject"
          height="490"
          :isSelection="false"
          :isSort="true"
          :columns="tableColumns"
          :data="tableDataSources"
          border
        ></dd-table>
      <!-- </dd-card> -->
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "detail-project",
  props: {
    expenseApply: {},
    depList: [],
    expenseType: [],
    prop: {},
  },
  data() {
    return {
      //附件列表
      fileList: [],
      //附件图片地址url
      AttachFilesImage: [],
      //项目列表
      project: [],
      //费用承担部门列表
      bearcorpation: [],
      //默认数据对象
      formListDataSources: {},
      //选择或者级联配置数据对象
      options: {},
      //详情，后台请求获取数据对象
      detailDataSourcrs: {},
      //审批流程表头
      tableColumns: [
       {
          title: "单号",
          key: "code",
          align: "center",
          overflow: true,
        },
        {
          title: "申请日期",
          key: "applyTime",
          align: "center",
          overflow: true,
        },
        {
          title: "业务类别",
          key: "firstExpense",
          align: "center",
          overflow: true,
        },
        {
          title: "二级费用类型",
          key: "twoLevelType",
          align: "center",
          width: 110,
          overflow: true,
        },
        {
          title: "费用类型",
          key: "extenseTypeName",
          align: "center",
          overflow: true,
        },
        {
          title: "金额（元）",
          key: "amount",
          align: "center",
          width: 100,
        },
        {
          title: "核定金额（元）",
          key: "reviewAmount",
          align: "center",
          width: 120,
        },
        {
          title: "是否关闭",
          key: "closeStatus",
          align: "center",
          // formatter: (row) => {
          //   return this.getCloseState(row);
          // },
        },
        {
          title: "单据状态",
          key: "stateName",
          align: "center",
        },
        {
          title: "报销状态",
          key: "claimsStatusName",
          align: "center",
        },
        {
          title: "节点",
          key: "taskFlag",
          align: "center",
          overflow: true,
        },
        {
          title: "申请人",
          key: "applier",
          align: "center",
        },
        {
          title: "事由",
          key: "subjectMatter",
          align: "center",
          overflow: true,
          width: 120,
        },
        {
          title: "费用申请部门",
          key: "expenseBearDepName",
          align: "center",
          overflow: true,
          width: 110,
        },
        {
          title: "费用承担部门",
          key: "expenseBearDepName",
          align: "center",
          overflow: true,
          width: 110,
        },
      ],
      //审批流程数据
      tableDataSources: [],
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    //转换预览图片数组格式
    handlePreviewSrcList(arr) {
      let list = [];
      arr.map((item) => {
        list.push(item.url);
      });
      return list;
    },
    //获取数据
    getDataList() {
      //编辑时，通过id获取数据
      getApi(
        `/finance/expenseApply/front/${sessionStorage.getItem("account")}/${
          this.expenseApply.id
        }`
      ).then((res) => {
        if (res.data.code == 0 && res.data.data) {
        //   if (res.data.data) {
        //     res.data.data.amount = parseFloat(res.data.data.amount).toFixed(2);
        //   }
        //   this.tableDataSources = res.data.data.taskList;
        //   this.detailDataSourcrs = res.data.data;
        //   let formBasicData = {
        //     applier: this.detailDataSourcrs.applier,
        //     applyTime: this.detailDataSourcrs.applyTime,
        //     dep: this.detailDataSourcrs.applyDep,
        //     projectName: this.detailDataSourcrs.projectName,
        //     expenseBearDepName: this.detailDataSourcrs.expenseBearDepName,
        //     expenseBearOrg: this.detailDataSourcrs.expenseBearOrg,
        //   };
        //   let formDetailData = {
        //     expenseTypeName: this.detailDataSourcrs.extenseTypeName,
        //     amount: this.detailDataSourcrs.amount,
        //     subjectMatter: this.detailDataSourcrs.subjectMatter,
        //   };
        //   this.$refs.expenseDetailBasicForm.formData = formBasicData;
        //   this.$refs.expenseDetailDetailForm.formData = formDetailData;
        //   let AttachFiles = res.data.data.finExpenseApplyAttachFiles;
        //   let url = "";
        //   AttachFiles.forEach((item) => {
        //     url = "/admin/sys-file/ssh/" + item.fileName;
        //     this.AttachFilesImage.push(url);
        //   });
        } else {
          this.$message.error("数据获取错误,请重新登录");
        }
      });
    },
  },
};
</script>
<style lang='less' scoped>
</style>
