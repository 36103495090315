<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="工作包选择列表" :height="tableHeight">
      <dd-table
        ref="workTable"
        :columns="tableColumns"
        :data="tableData"
        :topBtn="false"
        border
        isSelection
        :loading="loading"
        @select-change="handleSelectionChange"
      >
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
export default {
  props: {},
  components: {},
  computed: {},
  data() {
    return {
      tableHeight: null,
      loading: false,
      formData: {},
      formList: [
        {
          label: "区域节点",
          value: "areaCodeId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择区域节点",
          optionsListConfig: {
            name: "nodeName",
            code: "id",
          },
        },
        {
          label: "业务类型",
          value: "bussTypeId",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
          },
          filterable: "filterable",
          placeholderText: "请选择或搜索业务类型",
        },
        {
          label: "工作包名称",
          value: "nodeWorkName",
          inputType: "text",
          placeholderText: "请输入工作包名称",
        },
      ],
      tableColumns: [
        {
          title: "业务类型",
          key: "bussTypeName",
          align: "center",
        },
        {
          title: "区域节点类型",
          key: "areaCodeName",
          align: "center",
        },
        {
          title: "工作包名称",
          key: "nodeWorkName",
          align: "center",
        },
      ],
      tableData: [],
      rowData: null,
      selection: [],
      multipleSelection: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(2);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    loopData(data) {
      if(data && data.length) {
        data.forEach(item => {
          if(item?.children && item?.children?.length > 0) {
            item.disabled = true;
            this.loopData(item.children)
          }
        })
      }
      return data;
    },
    // 获取数据字典类型
    getDataType() {
      // 获取区域节点列表数据
      getApi(`/project/prjareanode/list`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "areaCodeId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      // 获取业务类型列表数据
      getApi(`/project/prjareasubassginbussitype/tree`).then((res) => {
        let { data } = res;
        let arr = this.loopData(data.data)
        if (data.code == 0) {
          let options = {
            value: "bussTypeId",
            optionsList: arr,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
      };
      this.loading = true;
      getApi(`/project/prjareanodeworkassociation/list`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data;
        }
      });
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.getTableData();
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  /deep/ .search-form-input {
    width: 33% !important;
  }
}
</style>
