<template>
  <div class="tab-container">
    <div class="left-tree container-left-tree">
      <el-tree
        ref="tree"
        :data="TreeList"
        accordion
        :props="defaultProps"
        default-expand-all
        highlight-current
        :expand-on-click-node="false"
        node-key="id"
        :current-node-key="treeNodeId"
        @node-click="handleNodeClick"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div
            :class="
              data.nodeType == 'PRJ-RWJHJDLX-ZRW'
                ? 'zrw'
                : data.nodeType == 'PRJ-RWJHJDLX-GZB'
                ? 'gzb'
                : null
            "
          >
            {{ node.label }}
          </div>
        </div>
      </el-tree>
    </div>
    <div class="right-table">
      <dd-card class="table-tab">
        <el-tabs
          v-model="workActiveName"
          type="card"
          @tab-click="handleTabClick"
        >
          <el-tab-pane
            v-for="val in workTabList"
            :key="val.value"
            :label="val.label"
            :name="val.value"
          ></el-tab-pane>
        </el-tabs>
      </dd-card>
      <dd-card v-if="workActiveName == 'wbs'" :height="tableHeight">
        <dd-table
          :columns="nodeTableColumns"
          :data="nodeTableData"
          border
          :isSelection="false"
          :loading="loading"
          :tree-props="{ children: 'children' }"
          default-expand-all
          :row-class-name="tableRowClassName"
          :cell-class-name="tableCellClassName"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="export"
              @click="handleAllExport"
              >导出</dd-button
            >
          </template>
        </dd-table>
      </dd-card>
      <dd-card v-if="workActiveName == 'cbfj'" :height="tableHeight">
        <dd-table
          :columns="docTableColumns"
          :data="docTableData"
          :topBtn="false"
          border
          :isSelection="false"
          show-page
          :loading="loading"
        >
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
  </div>
</template>

<script>
import { getApi, postApi, postDownBlobApi } from "@/api/request";
export default {
  props: {
    resolveType: {
      type: String,
      default: "",
    },
    rowId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableHeight: null,
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      treeNodeId: "",
      loading: true,
      workActiveName: "wbs",
      workTabList: [
        {
          label: "WBS维度人工成本管理",
          value: "wbs",
        },
        // {
        //   label: "成本分解维度成本管理",
        //   value: "cbfj",
        // },
      ],
      nodeTableColumns: [
        {
          title: "节点编号",
          key: "nodeCode",
          width:'100',
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
          overflow: true,
          width:'100',
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
          overflow: true,
          width:'100',
        },
        {
          title: "进度状态",
          key: "nodeStateName",
          align: "center",
          overflow: true,
          width:'100',
        },
        {
          title: "预估绩效成本",
          align: "center",
          children: [
            {
              title: "计划标准工天",
              key: "planStandardWorkday",
              align: "center",
              overflow: true,
              width:'100',
            },
            {
              title: "标准工天单价",
              key: "standardWorkdayPrice",
              align: "center",
              overflow: true,
              width:'100',
            },
            {
              title: "预估绩效",
              key: "planPriceTotal",
              align: "center",
              overflow: true,
              width:'100',
            },
          ],
        },
        {
          title: "实际人工成本",
          align: "center",
          children: [
            {
              title: "实际标准工天",
              key: "realStandardWorkday",
              align: "center",
              overflow: true,
              width:'100',
            },
            {
              title: "实际绩效",
              key: "realPriceTotal",
              align: "center",
              overflow: true,
              width:'100',
            },
            {
              title: "实际计工作量单价(元)",
              key: "realPriceUnit",
              align: "center",
              overflow: true,
              width:"180",
            },
          ],
        },
        {
          title: "剩余可用金额",
          key: "remainingAvailableAmount",
          align: "center",
          overflow: true,
        },
      ],
      nodeTableData: [],
      docTableColumns: [
        {
          title: "成本分解结构编码",
          key: "prjCode",
          align: "center",
        },
        {
          title: "成本分解结构名称",
          key: "prjName",
          align: "center",
        },
        {
          title: "预算成本",
          key: "planStartTime",
          align: "center",
        },
        {
          title: "实际成本",
          key: "tjwd",
          align: "center",
        },
        {
          title: "数据汇总来源说明",
          key: "remark",
          align: "center",
        },
      ],
      docTableData: [],
      current: 1,
      size: 20,
      total: null,
      treeAssignId: null,
    };
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.treeAssignId = this.rowId;
    this.getTableData();
  },
  methods: {
    countRemainingAvailableAmount(data) { // 计算剩余可用金额
      if (!data || data.length == 0) {
        return false;
      }
      data.forEach(item => {
        item.remainingAvailableAmount = (Number(item.planPriceTotal || 0) - Number(item.realPriceTotal || 0));
        if (item.children && item.children?.length > 0) {
          this.countRemainingAvailableAmount(item.children)
        }
      })
      return data
    },
    // 获取表格列表数据
    getTableData() {
      let params = {
        id: this.treeNodeId,
      };
      this.loading = true;
      getApi(
        `/project/subplan/getsubplanenabletree/${this.treeAssignId}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0 && data.data) {
          if (!this.treeNodeId) {
            this.TreeList = data.data;
            this.treeNodeId = data.data[0].id;
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
            });
          }
          this.nodeTableData = this.countRemainingAvailableAmount(data.data);
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    handleNodeClick(data) {
      this.treeNodeId = data.id;
      this.treeAssignId = data.assignId;
      this.getTableData();
    },
    handelNodeAdd(data) {
      console.log(data);
    },
    handelNodeEdit(data) {
      console.log(data);
    },
    handelNodeDelete(data) {
      console.log(data);
    },
    handleTabClick(tab, event) {
      this.$nextTick(() => {
        this.tableHeight = this.autoTableHeight(0);
      });
      this.workActiveName = tab.name;
    },
    handleAdd() {
      console.log("新增");
    },
    handleAllExport() {
      let params = {
        id: this.treeAssignId,
      };
      postDownBlobApi(
        `/project/subplan/cost/export`,
        params,
        "人工成本管理"
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
        }
      });
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.nodeType === "PRJ-RWJHJDLX-ZRW") {
        return "zrw";
      } else if (row.nodeType === "PRJ-RWJHJDLX-GZB") {
        return "gzb";
      } else if (row.nodeState === "PRJ-RWJHJDZT-JY") {
        return "jy";
      }
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      if (
        column.property == "nodeStateName" &&
        row.nodeState == "PRJ-RWJHJDZT-SCZ"
      ) {
        return "scz";
      } else if (
        column.property == "nodeStateName" &&
        row.nodeState == "PRJ-RWJHJDZT-WC"
      ) {
        return "wc";
      } else if (
        column.property == "nodeStateName" &&
        row.nodeState == "PRJ-RWJHJDZT-JY"
      ) {
        return "jy";
      } else if (
        column.property == "nodeStateName" &&
        row.nodeState == "PRJ-RWJHJDZT-ZC"
      ) {
        return "zc";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tab-container {
  height: 100%;
  display: flex;
  .left-tree {
    width: 20%;
    height: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    border-right: 5px solid #e7eaec;
    overflow-y: auto;
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: @fs14;

      .zrw {
        color: #0020c5;
      }

      .gzb {
        color: #159d9d;
      }

      .node-hanlde {
        color: @defaultFontColor;
        i {
          margin: 0 5px;
        }
      }
    }
  }
  .right-table {
    width: 80%;
    height: 100%;
    .table-tab {
      /deep/ .card-contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        .el-tabs {
          width: 100%;
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
    }
    /deep/.el-table__expand-icon {
      display: none !important;
    }
    /deep/ .el-table__indent {
      display: none !important;
    }
    /deep/.el-table__placeholder {
      display: none !important;
    }
    /deep/.el-table .zrw {
      td.el-table__cell {
        background-color: #cadaff;
        color: #0020c5;
      }
    }

    /deep/.el-table .gzb {
      td.el-table__cell {
        background-color: #159d9d;
        color: #fff;
      }
    }

    /deep/.el-table .jy {
      td.el-table__cell {
        background-color: #a9a9a9;
        color: #fff;
      }
    }
    /deep/ .el-table {
      td.scz {
        background-color: #0f55ab !important;
        color: #fff !important;
      }
      td.wc {
        background-color: #07b107 !important;
        color: #fff !important;
      }
      td.zc {
        background-color: #df8e3b !important;
        color: #fff !important;
      }
    }
  }
}
</style>
