<template>
  <div class="main-container">
    <dd-card title="区域列表" height="100%">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        :isEdit="true"
        border
        ref="dTableRef"
        show-page
        :tree-props="{ children: 'children' }"
        default-expand-all
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd"
            >新增</dd-button
          >
          <dd-button type="primary" size="medium" @click="handleBatchData(1)"
            >批量指定负责部门</dd-button
          >
          <dd-button type="primary" size="medium" @click="handleBatchData(2)"
            >批量指定负责人</dd-button
          >
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="60%"
      height="70%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :type="type"
        :dictType="tableData"
        :rowData="rowData"
      ></add-pop>
    </dd-popup>
    <!-- 批量页面弹窗 -->
    <dd-popup
      width="30%"
      height="40%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="batchPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleBatchSubmit('addPopForm', 'baseForm')"
    >
      <batch-pop
        v-if="batchPopupVisible"
        slot="main"
        ref="addPop"
        :type="type"
        :rowData="rowData"
      ></batch-pop>
    </dd-popup>
    <!-- 矢量范围弹窗 -->
    <dd-popup
      width="40%"
      height="60%"
      :show="regionPopupVisible"
      title-name="矢量范围"
      @popup-close="handleClose"
    >
      <view-region
        v-if="regionPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></view-region>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
import batchPop from "./components/batchPop.vue";
import viewRegion from "./components/viewRegion.vue";
export default {
  name: "region",
  $dynamicRoute: {
    name: "区域管理",
    path: "/admin/region/index",
  },
  components: {
    addPop,
    batchPop,
    viewRegion,
  },
  computed: {},
  data() {
    return {
      drawerVisible: false,
      type: "",
      tableColumns: [
        {
          title: "区域代码",
          key: "code",
          width: 260,
          overflow: true,
        },
        {
          title: "区域名称",
          key: "name",
          align: "center",
          width: 260,
        },
        {
          title: "区域类型",
          key: "typeName",
          align: "center",
          overflow: true,
        },
        {
          title: "区域范围",
          key: "rangeName",
          align: "center",
          width: 260,
          overflow: true,
          event: [
            {
              // icon: "el-icon-info",
              href: (row) => {
                return row.rangeName;
              },
              method: (row) => {
                this.handleGetRegion(row);
              },
              type: "primary",
            },
          ],
        },
        {
          title: "是否可选",
          key: "whetherCodeName",
          align: "center",
          formatter: (row) => {
            return row;
          },
        },
        {
          title: "负责部门",
          key: "deptName",
          align: "center",
          width: 260,
          overflow: true,
        },
        {
          title: "状态",
          switch: true,
          key: "stateCode",
          align: "center",
          change: this.handleChange,
        },
        {
          title: "区域销售负责人",
          key: "leaderNames",
          align: "center",
          width: 300,
          overflow: true,
          formatterArr: (row) => {
            return row ? row.split(",") : [];
          },
        },
        {
          title: "区域销售子部门",
          key: "deptBranchName",
          align: "center",
          width: 260,
          overflow: true,
        },
        {
          title: "关联行政区(到县级)",
          key: "relationNames",
          align: "center",
          width: 260,
          overflow: true,
          formatterArr: (row) => {
            return row ? row.split(",") : [];
          },
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          fixed: "right",
          width: "300px",
          event: [
            {
              show: "true",
              label: "添加",
              icon: "el-icon-plus",
              method: (row) => {
                this.handleRowAdd(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      addPopupVisible: false,
      rowData: null,
      poptitleName: "新增",
      multipleSelection: [],
      batchPopupVisible: false,
      regionPopupVisible: false,
      rangeNameShow: false,
    };
  },
  created() {},
  mounted() {
    this.getTableData();
  },
  methods: {
    // 获取表格列表数据
    getTableData() {
      getApi(`/admin/syscompanyregion/tree`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.handleTreeRecursion(data.data);
          this.tableData = data.data || [];
          this.tableData.forEach((item) => {
            item.rangeName = item.geom ? "矢量范围" : "-";
            if (item.children && item.children.length > 0) {
              item.children.forEach((child) => {
                child.rangeName = child.geom ? "矢量范围" : "-";
                if (child.children && child.children.length > 0) {
                  child.children.forEach((grandson) => {
                    grandson.rangeName = grandson.geom ? "矢量范围" : "-";
                  });
                }
              });
            }
          });
        }
      });
    },
    //处理树结构数据
    handleTreeRecursion(data) {
      data.forEach((item) => {
        item.stateCode = Number(item.stateCode) == 0;
        if (item.children && item.children.length) {
          this.handleTreeRecursion(item.children);
        }
      });
    },
    //row数据变动事件
    handleChange(key, row) {
      console.log(key, row);
      if (key == "stateCode") {
        postApi(`/admin/syscompanyregion/changestatus/${row.id}`).then(
          (res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.getTableData();
            }
          }
        );
      }
    },
    // 新增按钮事件
    handleAdd() {
      this.type = "";
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //批量处理事件
    handleBatchData(type) {
      let { multipleSelection } = this;
      if (!multipleSelection.length) {
        this.$message.warning("请至少选择一条数据!");
        return;
      }
      //批量指定负责部门
      if (type == 1) {
        this.poptitleName = "批量指定负责部门";
        this.batchPopupVisible = true;
      }
      //批量部门指定负责人
      else {
        let deptId = multipleSelection[0].deptId;
        let count = 0;
        multipleSelection.map((item) => {
          if (item.deptId == deptId) {
            count++;
          }
        });
        if (multipleSelection.length != count) {
          this.$message.warning("批量部门指定负责人需负责部门一致");
          return;
        }
        this.poptitleName = "批量部门指定负责人";
        this.batchPopupVisible = true;
      }
      this.type = type;
      this.rowData = multipleSelection[0];
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
      this.batchPopupVisible = false;
      this.regionPopupVisible = false;
    },
    handleRowAdd(row) {
      this.type = "add";
      this.rowData = row;
      this.rowData.parentId = row.id;
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      getApi(`/admin/syscompanyregion/${row.id}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.rowData = data.data;
          this.addPopupVisible = true;
          this.poptitleName = "编辑";
          this.type = "update";
        }
      });
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/admin/syscompanyregion/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    // 批量确认事件
    handleBatchSubmit(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      let idsList = [];
      this.multipleSelection.map((item) => {
        idsList.push(item.id);
      });
      params.idsList = idsList;
      if (flag) {
        if (params.deptId) {
          delete params.leaderIdsList;
          postApi(`/admin/syscompanyregion/batchsettingdept`, params).then(
            (res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message.success("修改成功");
                this.$refs.dTableRef.$refs.baseTable.clearSelection();
                this.handleClose();
                this.getTableData();
              }
            }
          );
        } else {
          delete params.deptId;
          postApi(`/admin/syscompanyregion/batchsettinguser`, params).then(
            (res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message.success("修改成功");
                this.$refs.dTableRef.$refs.baseTable.clearSelection();
                this.handleClose();
                this.getTableData();
              }
            }
          );
        }
      }
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;

      delete params.undefined;
      let leadersList = [];
      let relationList = [];
      if (params.leadersList && params.leadersList.length) {
        params.leadersList.map((item) => {
          leadersList.push({
            leaderUserId: item,
          });
        });
      }
      if (params.relationList && params.relationList.length) {
        params.relationList.map((item) => {
          relationList.push({
            regionCode: item,
          });
        });
      }
      params.leadersList = leadersList;
      params.relationList = relationList;
      if (flag) {
        if (this.rowData != null && this.poptitleName == "编辑") {
          params.id = this.rowData.id;
          putApi(`/admin/syscompanyregion`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.handleClose();
              this.getTableData();
            }
          });
        } else {
          postApi(`/admin/syscompanyregion`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
            }
          });
        }
      }
    },
    handleGetRegion(row) {
      if (row.geom) {
        this.rowData = row;
        this.regionPopupVisible = true;
      } else {
        this.$message.warning("该区域范围不存在");
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
