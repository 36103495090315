<template>
    <div class="container">
        <dd-card title="">
            <dd-form
                :form-config="formList"
                :labelWidth="'160px'"
                :flex="3"
                ref="assignForm_111"
                formRef="assignForm_111"
            ></dd-form>
        </dd-card>
        <dd-card title="">
            <dd-table :columns="tableColumns" :data="tableData" :isSelection="false" border :loading="loading">
            </dd-table>
        </dd-card>
    </div>
</template>

<script>
    export default {
        name: 'contractPayment',
        props: ['detailData'],
        data() {
            return {
                loading: false,
                formList: [
                    {
                        label: "合同总金额",
                        value: "foriginalamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同变更后总金额",
                        value: "ftotalamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同结算金额",
                        value: "settleFoftax",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同已付款金额",
                        value: "paymentForiginalamount",
                        inputType: "text",
                        disabled: true,
                    },
                ],
                tableColumns: [
                    {
                        title: "单据编号",
                        key: "fnumber",
                        align: "center",
                        overflow: true,
                        width: 180,
                    },
                    {
                        title: "单据名称",
                        key: "fname",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "单据状态",
                        key: "fbillsateName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "所属组织",
                        key: "fprojectorgName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "付款期间",
                        key: "fperiodidName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "合同名称",
                        key: "purchaseInfoName",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "供应商",
                        key: "fpartb",
                        align: "center",
                        overflow: true,
                    },{
                        title: "收款单位",
                        key: "freceiptorgid",
                        align: "center",
                        overflow: true,
                    },{
                        title: "金额",
                        key: "foriginalamount",
                        align: "center",
                        overflow: true,
                    },
                    {
                        title: "制单人",
                        key: "fcreatorName",
                        align: "center",
                        overflow: true,
                    },{
                        title: "制单日期",
                        key: "fbizdate",
                        align: "center",
                        overflow: true,
                    },{
                        title: "最后修改人",
                        key: "flastupdateuserName",
                        align: "center",
                        overflow: true,
                        width: 140,
                    },
                    {
                        title: "最后修改日期",
                        key: "flastupdatetime",
                        align: "center",
                        overflow: true,
                        width: 140,
                    },{
                        title: "审核人",
                        key: "fauditorName",
                        align: "center",
                        overflow: true,
                    },{
                        title: "审核日期",
                        key: "fauditdate",
                        align: "center",
                        overflow: true,
                    },
                ],
                tableData: []
            }
        },
        watch: {
            detailData() {
                this.initPageData()
            },
        },
        created() {

        },
        mounted() {

        },
        methods: {
            initPageData() {
                this.$refs.assignForm_111.formData = {
                    foriginalamount: this.detailData.foriginalamount,
                    ftotalamount: this.detailData.ftotalamount,
                    settleFoftax: this.detailData.settleFoftax,
                }
                this.tableData = this.detailData.contractPurchaseInfoPaymentVOList || []
            }
        }
    }
</script>

<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}

/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>