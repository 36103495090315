<template>
    <div class="container">
        <dd-card title="">
            <div class="text_box">
                <div class="top_box_item" v-for="(item, index) in topText" :key="index">
                    <div class="label">{{ item.label? item.label + '' : item.label }}</div>
                    <div class="value">{{ item.value }}</div>
                </div>
            </div>
        </dd-card>
        <dd-card title="">
            <dd-table :columns="tableColumns" :data="tableData" border :isSelection="false" :summary-method="getSummaries"  show-page show-summary :loading="loading">
            </dd-table>
        </dd-card>
    </div>
</template>

<script>
import { postApi } from '@/api/request';
    export default {
        name: 'paymentPop',
        props: ['pageProp'],
        data() {
        return {
            topText: [
                {
                    label: "本期实付总金额：",
                    value: "",
                },
                {
                    label: "已关联分配金额：",
                    value: "",
                },
                {
                    label: "",
                    value: "",
                },
            ],
            tableData: [],
            tableColumns: [
                {
                    title: "自建合同编码",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "自建合同名称",
                    key: "fname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "自建合同乙方",
                    key: "partyBCompany",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "项目名称",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "结算关联金额",
                    key: "contractRelatedAmount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "与本金蝶合同已关联的付款金额",
                    key: "relatedSettleAmount",
                    align: "center",
                    overflow: true,
                    width: 220,
                },
                {
                    title: "数据状态",
                    key: "status",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "关联结算金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                    input: [
                        {
                            method: (value, row, index) => {
                                this.changeAmount(value, row, index);
                            },
                            isEdit: () => {
                                let state = false;
                                return state;
                            },
                            rules: (value) => {
                                let result = value.replace(/[^\d.]/g, '');
                                if (result.charAt(0) === '.') {
                                    result = result.slice(1);
                                }
                                const parts = result.split('.');
                                if (parts.length > 2) {
                                    result = parts.slice(0, 2).join('.');
                                }
                                if (parts.length > 1) {
                                    result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                                }
                                return result;
                            },
                        }
                    ]
                },
            ],
            loading: false,
        }
    },
    created() {
        this.getTableData()
        this.topText[0].value = this.pageProp.foriginalamount
    },
    methods: {
        changeAmount(value, row, index) {
            if (value) {
                value = Number(value)
            }
            else {
                value = 0
            }
        },
        getTableData() {
            postApi(`/contract/contractpurchaseinfopayment/payment/detail?id=${this.pageProp.id}&purchaseInfoId=${this.pageProp.purchaseInfoId}`).then(res => {
                if(res.data.code == 0) {
                    this.tableData = res.data.data;
                }
            })
        }, 
        getSummaries(param) { // 自定义项表格合计方法
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                } else if (column.property == "allotmentAmount"
                ) {
                    //如果是经费（正常的加减法）
                    const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
                    if (!values.every((value) => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            var sum = 0;
                            if (!isNaN(value)) {
                                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                                return sum;
                            } else {
                                return prev;
                            }
                        }, 0);
                        sums[index] += " ";
                    }
                }
            });
            return sums
        },
    }
    }
</script>

<style lang="less" scoped>
.text_box {
        width: 80%;
        height: 35px;
        line-height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        font-weight: bold;
        font-size: 15px;
        color: #606266;

        .top_box_item {
            display: flex;
            align-items: center;
        }
    }
</style>