import {Message} from 'element-ui';
let messageInstance = null;
const restMessage = (options) => {
    if(messageInstance) {
        messageInstance.close();
    }
    messageInstance = Message(options);
};
['error', 'success', 'info', 'warning'].forEach( type => {
    restMessage[type] = options => {
        if(typeof options === 'string') {
            options = {
                message: options
            }
        }
        options.type = type;
        return restMessage(options)
    }
})
export const message = restMessage