<template>
  <div class="main-container">
      <dd-card title="菜单列表">
          <dd-table :columns="tableColumns" :data="tableData" height="705" border :isSelection="false"
               show-page :tree-props="{children: 'children'}">
              <template #table-btn>
                  <dd-button type="primary" size="medium" icon="add" @click="handleAdd">新增</dd-button>
              </template>
              <template #table-btn-right>
                  <el-button icon="el-icon-s-operation" circle type="info" size="small" @click="drawerVisible = true"
                      title="列筛选"></el-button>
              </template>
              <template #table-pagination>
                  <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                      @page-change="handleCurrentChange" align="right">
                  </dd-pagination>
              </template>
          </dd-table>
      </dd-card>
      <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
          :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
          @handleColumnsChange="handleColumnsChange">
      </dd-filter-drawer>
      <!-- 新增页面弹窗 -->
      <dd-popup
          width="30%"
          height="65%"
          showBtn
          :saveBtn="true"
          :cancelBtn="true"
          :show="addPopupVisible"
          :title-name="poptitleName"
          @popup-close="handleClose"
          @popup-save="handleSave('addPopForm','baseForm')"
      >
          <add-pop v-if="addPopupVisible" slot="main" ref="addPop" :dictType="tableData" :rowData="rowData" :poptitleName="poptitleName" @addIconVisible="addIconVisible=true"></add-pop>
      </dd-popup>
  </div>
</template>
  
<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
export default {
  name: "appmenus",
  $dynamicRoute: {
      name: "APP菜单管理",
      path: "/admin/appmenu/index",
  },
  components: {
      addPop
  },
  computed: {
  },
  data() {
      return {
          drawerVisible: false,
          tableColumns: [
          {
                  title: "菜单名称",
                  key: "name",
              },
              {
                  title: "排序",
                  key: "sort",
                  align: "center",
              },
              {
                  title: "组件路径",
                  key: "path",
                  align: "center",
              },
              {
                  title: "权限标识",
                  key: "permission",
                  align: "center",
              },
              {
                  title: "操作",
                  key: "handle",
                  align: "center",
                  event: [
                      {
                          show: "true",
                          label: "添加",
                          icon: "el-icon-plus",
                          method: (row) => {
                              this.handleRowAdd(row);
                          },
                          type: "primary"
                      },
                      {
                          show: "true",
                          label: "编辑",
                          icon: "el-icon-edit",
                          method: (row) => {
                              this.handleRowEditor(row);
                          },
                          type: "primary"
                      },
                      {
                          show: "true",
                          label: "删除",
                          icon: "el-icon-delete",
                          method: (row) => {
                              this.handleRowDel(row);
                          },
                          type: "danger",
                      }
                  ],
              },
          ],
          tableData: [],
          current: 1,
          size: 20,
          total: null,
          addPopupVisible: false,
          rowData: null,
          poptitleName: '新增',
      };
  },
  created() { },
  mounted() {
      this.getTableData();
  },
  methods: {
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
          formList.forEach((item) => {
              if (options) {
                  if (item.value == options.value) {
                      item.optionsList = options.optionsList;
                  }
              }
          });
      },
      // 获取表格列表数据
      getTableData() {
          let { current, size } = this;
          let params = {
              current,
              size,
          };
          getApi(`/admin/app_menu/tree`).then((res) => {
              let { data } = res;
              if (data && data.code == 0) {
                  this.tableData = data.data;
              }
          });
      },
      // 新增按钮事件
      handleAdd() {
          this.addPopupVisible = true;
          this.poptitleName = '新增';
      },
      // 新增弹框关闭事件
      handleClose() {
          this.rowData = null;
          this.addPopupVisible = false;
      },
    //   图标弹框关闭事件
      handleIconClose() {
        this.addIconVisible = false;
      },
      getIconVal(e) {
        console.log(this.$refs.addPop)
        this.$refs.addPop.$refs.addPopForm.formData.icon = e;
        this.handleIconClose();
      },
    //   表格行内添加事件
      handleRowAdd(row) {
          this.rowData = row;
          this.addPopupVisible = true;
          this.poptitleName = '新增';
      },
      // 表格行内编辑事件
      handleRowEditor(row) {
          this.rowData = row;
          this.addPopupVisible = true;
          this.poptitleName = '编辑';
      },
      //列表单行删除
      handleRowDel(row) {
          this.$confirm(`确认删除该数据吗？`, "提示", {
              confirmButtonClass: "btnsearch",
              confirmButtonText: "确认",
              cancelButtonClass: "btnreset",
              cancelButtonText: "取消",
              showClose: true,
              type: "warning",
          }).then(() => {
              this.handleConfirmDelete(row.id);
          });
      },
      // 确认删除请求
      handleConfirmDelete(delId) {
          delApi(`/admin/app_menu/${delId}`).then((res) => {
              let { data } = res;
              if (data.code == 0) {
                  this.$message.success("删除成功");
              }
              this.getTableData();
          });
      },
      // 格式化类型
      getState(key, row) {
          let label = "";
          this[key].forEach(item => {
              if (item.value == row) {
                  label = item.label
              }
          })
          return label;
      },
      /* 搜索栏 */
      handleSearchEvent(obj) {
          this.formData = obj;
          this.current = 1;
          this.getTableData();
      },
      /* 分页页码回调 */
      handleCurrentChange(size, number) {
          this.current = number;
          this.size = size;
          this.getTableData();
      },
      // 新增确认事件
      handleSave(formName,formNameBase) {
          let flag = false;
          this.$refs.addPop.$refs[formName].$refs[formNameBase].validate((valid) => {
              if (valid) {
                  flag = true;
              }
          });
          let params = this.$refs.addPop.$refs[formName].formData;
          if(flag) {
              if(this.rowData != null && this.poptitleName == '编辑') {
                  params.id = this.rowData.id;
                  putApi(`/admin/app_menu`,params).then(res => {
                      let { data } = res;
                      if(data.code == 0) {
                          this.$message.success("修改成功");
                          this.handleClose();
                          this.getTableData();
                      }
                  })
              }else {
                  postApi(`/admin/app_menu`,params).then(res => {
                      let { data } = res;
                      if(data.code == 0) {
                          this.$message.success("保存成功");
                          this.handleClose();
                          this.getTableData();
                      }
                  })
              }
          }
      }
  },
};
</script>
  
<style lang='less' scoped>
</style>