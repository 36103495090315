<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>
  
  <script>
export default {
  name: "add-pop",
  props: {
    dictType: [],
    rowData: [],
    poptitleName: "",
  },
  data() {
    return {
      formBasicList: [
        {
          label: "上级菜单",
          value: "parentId",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
          },
          showAllSelcet: false,
          placeholderText: "请选择上级菜单",
          rules: [
            {
              required: true,
              message: "请选择角色",
              trigger: "blur",
            },
          ],
        },
        {
          label: "菜单名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入菜单名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入菜单名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "组件路径",
          value: "path",
          inputType: "text",
          placeholderText: "请输入组件路径",
          disabled: false,
          defaultValue: "",
        },
        {
          label: "排序",
          value: "sort",
          inputType: "number",
          min: 0,
          max: 999,
          placeholderText: "请输入排序",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入排序",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (this.dictType) {
      let newTree = [
        {
          name: "根菜单",
          id: "-1",
          children: this.dictType,
        },
      ];
      this.formBasicList.forEach((item) => {
        if (item.value == "parentId") {
          item.optionsList = newTree;
        }
      });
    }
    if (this.rowData != null) {
      this.formBasicList.forEach((item) => {
        if (this.poptitleName == "新增") {
          if (item.value == "parentId") {
            item.defaultValue = this.rowData.id;
          }
        } else {
          item.defaultValue = this.rowData[item.value];
        }
      });
    }
  },
  methods: {},
};
</script>
  <style lang='less' scoped>
</style>
  