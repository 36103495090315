<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>
  
  <script>
export default {
  name: "add-pop",
  props: {
    dictType: [],
    menuType: [],
    bufferType: [],
    rowData: [],
    poptitleName: "",
  },
  data() {
    return {
      formBasicList: [
        {
          label: "菜单类型",
          key: "all",
          value: "type",
          inputType: "radio",
          defaultValue: "",
          border: true,
          radioGroup: [],
          change: (e, v) => {
            this.handleMenuTypeForm(e);
          },
          rules: [
            {
              required: true,
              message: "请选择菜单类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "上级菜单",
          key: "all",
          value: "parentId",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
          },
          showAllSelcet: false,
          placeholderText: "请选择上级菜单",
          rules: [
            {
              required: true,
              message: "请选择上级菜单",
              trigger: "blur",
            },
          ],
        },
        {
          label: "名称",
          key: "all",
          value: "name",
          inputType: "text",
          placeholderText: "请输入名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "权限标识",
          key: "all",
          value: "permission",
          inputType: "text",
          placeholderText: "请输入权限标识",
          disabled: false,
          defaultValue: "",
        },
        {
          label: "路由地址",
          key: "menu",
          value: "path",
          inputType: "text",
          placeholderText: "请输入路由地址",
          disabled: false,
          defaultValue: "",
        },
        {
          label: "路由缓冲",
          key: "menu",
          value: "keepAlive",
          inputType: "radio",
          defaultValue: "",
          border: true,
          radioGroup: [],
          rules: [
            {
              required: true,
              message: "请选择路由缓冲",
              trigger: "blur",
            },
          ],
        },
        {
          label: "图标",
          key: "menu",
          value: "icon",
          inputType: "buttonInput",
          placeholderText: "请选择图标",
          defaultValue: "",
          handleEvent: () => {
            this.$emit("addIconVisible", true);
          },
        },
        {
          label: "排序",
          key: "menu",
          value: "sortOrder",
          inputType: "number",
          min: 0,
          max: 999,
          placeholderText: "请输入排序",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入排序",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (this.dictType) {
      let newTree = [
        {
          name: "根菜单",
          id: "-1",
          children: this.dictType,
        },
      ];
      this.formBasicList.forEach((item) => {
        if (item.value == "parentId") {
          item.optionsList = newTree;
        }
      });
    }
    if (this.rowData != null) {
      this.formBasicList.forEach((item) => {
        if (this.poptitleName == "新增") {
          if (item.value == "parentId") {
            item.defaultValue = this.rowData.id;
          }
        } else {
          item.defaultValue = this.rowData[item.value];
        }
      });
    }
    let options1 = {
      value: "type",
      optionsList: this.menuType,
    };
    let options2 = {
      value: "keepAlive",
      optionsList: this.bufferType,
    };
    this.setOPtionsList(this.formBasicList, options1);
    this.setOPtionsList(this.formBasicList, options2);
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.radioGroup = options.optionsList;
            if (item.defaultValue == "") {
              item.defaultValue = options.optionsList[0].value;
            }
          }
        }
      });
    },
    // 菜单类型切换
    handleMenuTypeForm(e) {
      if (e == 1) {
        let formList = this.formBasicList;
        for (let i = 0; i < formList.length; i++) {
          if (formList[i].value == "type") {
            formList[i].defaultValue = e;
          }
          if (formList[i].key == "menu") {
            formList.splice(i, 1);
            i--;
          }
        }
      } else {
        let menuForm = [
          {
            label: "路由地址",
            key: "menu",
            value: "path",
            inputType: "text",
            placeholderText: "请输入路由地址",
            disabled: false,
            defaultValue: "",
          },
          {
            label: "路由缓冲",
            key: "menu",
            value: "keepAlive",
            inputType: "radio",
            defaultValue: "",
            border: true,
            radioGroup: [],
            rules: [
              {
                required: true,
                message: "请选择路由缓冲",
                trigger: "blur",
              },
            ],
          },
          {
            label: "图标",
            key: "menu",
            value: "icon",
            inputType: "buttonInput",
            placeholderText: "请选择图标",
            defaultValue: "",
            handleEvent: () => {
              this.$emit("addIconVisible", true);
            },
          },
          {
            label: "排序",
            key: "menu",
            value: "sortOrder",
            inputType: "number",
            min: 0,
            max: 999,
            placeholderText: "请输入排序",
            disabled: false,
            defaultValue: "",
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
        ];
        let options2 = {
          value: "keepAlive",
          optionsList: this.bufferType,
        };
        this.setOPtionsList(menuForm, options2);
        this.formBasicList.forEach((item, index) => {
          if (item.value == "type") {
            item.defaultValue = e;
          }
        });
        this.formBasicList = this.formBasicList.concat(menuForm);
      }
    },
  },
};
</script>
  <style lang='less' scoped>
</style>
  