<template>
  <div class="main-container">
    <dd-card title="令牌列表" height="100%">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        isSort
        border
        :topBtn="false"
        :isSelection="false"
        show-page
        :loading="loading"
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import { getApi, delApi } from "@/api/request";
export default {
  name: "token",
  $dynamicRoute: {
    name: "令牌管理",
    path: "/admin/token/index",
  },
  computed: {},
  data() {
    return {
      drawerVisible: false,
      tableColumns: [
        {
          title: "用户名",
          key: "username",
          align: "center",
          width: 150,
        },
        {
          title: "客户端",
          key: "clientId",
          align: "center",
          width: 150,
        },
        {
          title: "令牌",
          key: "accessToken",
          align: "center",
        },
        {
          title: "创建时间",
          key: "expiresAt",
          align: "center",
        },
        {
          title: "过期时间",
          key: "issuedAt",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      loading: true,
    };
  },
  created() {},
  mounted() {
    this.getTableData();
  },
  methods: {
    // 获取表格列表数据
    getTableData() {
      let { current, size } = this;
      let params = {
        current,
        size,
      };
      this.loading = true;
      getApi(`/admin/token/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/admin/token/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped></style>
