<template>
    <div class="container">
            <dd-button slot="button" style="margin: 10px 20px" size="medium" type="primary" v-if="pageProp.pageType == 'add' || pageProp.pageType == 'edit'" @click="addContarct()">添加合同</dd-button>
        <dd-card title="">
            <dd-form :form-config="formList" :labelWidth="'190px'" :flex="3" ref="form_addEditPopup"
                formRef="form_addEditPopup"></dd-form>
        </dd-card>

        <dd-table ref="baseTable_1" :columns="tableColumns" isSort :summary-method="getSummaries" :data="tableData" show-summary
                 border :isSelection="false" :loading="loading">
        </dd-table>

        <dd-popup v-if="addKingDeeVisible" width="78%" height="80%" inPopup showBtn :saveBtn="saveBtn" :submitBtn="false"
            :show="addKingDeeVisible" title-name="添加金蝶合同"
            @popup-close="handleClose">
            <dd-button  slot="button" size="medium" type="primary"
            @click="handleSubmit(1)">提交</dd-button>
            <addKingDee ref="addKingDee"    :pageProp="pageProp"  slot="main"></addKingDee>
        </dd-popup>

        <dd-popup v-if="popup_formVisible" width="40%" height="60%" inPopup showBtn :saveBtn="true" :submitBtn="false"
            :show="popup_formVisible" title-name="查看详情" @popup-save="handleSave()" @popup-close="handleClose">
            <div slot="main">
                <dd-card title="" >
                        <dd-form :form-config="formList_form" :flex="1" :labelWidth="'260px'" 
                            ref="EditForm" formRef="EditForm"></dd-form>
                    </dd-card>
            </div>
        </dd-popup>
    </div>
</template>

<script>
import { getApi, postApi } from '@/api/request';
import addKingDee from '../../invoiceRegistration/components/addKingDee.vue'
export default {
    name: 'addEditPopup',
    props: ['pageProp'],
    components: { addKingDee },
    data() {
        return {
            rowData: {},
            popup_formVisible: false,
            loading: false,
            addKingDeeVisible: false,
            rowId: null,
            saveBtn: false,
            tableColumns: [
                {
                    title: "采购年度",
                    key: "cfyear",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "采购合同编号",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "采购合同名称",
                    key: "fname",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "乙方单位",
                    key: "fpartbname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "采购合同类型",
                    key: "fcontracttypename",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "采购总金额",
                    key: "foriginalamount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "采购合同变更后总金额",
                    key: "ftotalamount",
                    align: "center",
                    overflow: true,
                    width: 170
                },
                {
                    title: "采购累计结算金额",
                    key: "fsettlebalance",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "采购累计实付金额",
                    key: "frealamount",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "本期采购计划支付",
                    key: "paymentAmount",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "本期采购计划付款节点说明",
                    key: "nodeDescription",
                    align: "center",
                    overflow: true,
                    width: 190
                },
                {
                    title: "采购付款条件及说明",
                    key: "conditionInstruction",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "本期付款后采购累计已付",
                    key: "currentPaymentAfterPaid",
                    align: "center",
                    overflow: true,
                    width: 180
                },
                {
                    title: "本期付款后采购剩余未付",
                    key: "currentPaymentAfterUnpaid",
                    align: "center",
                    overflow: true,
                    width: 180
                },
                {
                    title: "关联项目编号",
                    key: "prjCode",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "关联项目名称",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "关联销售合同编号",
                    key: "saleContractCode",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "关联销售合同名称",
                    key: "saleContractName",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "销售合同额",
                    key: "saleContractAmount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "销售合同变更后合同额",
                    key: "saleContractAfterModAmount",
                    align: "center",
                    overflow: true,
                    width: 180
                },
                {
                    title: "销售已回款",
                    key: "saleContractRefund",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "操作",
                    key: "code",
                    align: "center",
                    fixed: 'right',
                    overflow: true,
                    width: 140,
                    event: [
                        {
                            state: "default",
                            label: "编辑",
                            method: (row) => {
                                this.handleEdit(row);
                            },
                        },
                        {
                            state: "default",
                            label: "移除",
                            method: (row) => {
                                this.handleDelete(row);
                            },
                        },
                    ],
                },
            ],
            tableData: [],
            formList: [
                {
                    label: "单据编码",
                    defaultValue: "",
                    value: "code",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入单据编码",
                },
                {
                    label: "单据名称",
                    defaultValue: "",
                    value: "name",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "请输入单据名称",
                },

                {
                    label: "单据状态",
                    value: "status",
                    disabled: true,
                    inputType: "select",
                    optionsList: [],
                    filterable: true,
                    defaultValue: "",
                    placeholderText: "请选择单据状态",
                    optionsListConfig: {
                        name: "name",
                        code: "code", 
                    },
                },
                {
                    label: "年度",
                    value: "year",
                    dateType: 'year',
                    format: 'yyyy',
                    displayFormat: 'yyyy',
                    defaultValue: "",
                    inputType: "otherDate",
                    disabled: this.pageProp.pageType != 'add',
                    placeholderText:
                        "请选择年度",
                        rules: [
                        {
                        required: true,
                        message: "请选择年度",
                        trigger: "blur",
                        },
                    ],
                },
                {
                    label: "月份",
                    value: "month",
                    defaultValue: "",
                    format: 'MM',
                    displayFormat: 'MM',
                    dateType: 'month',
                    disabled: this.pageProp.pageType != 'add',
                    inputType: "otherDate",
                    placeholderText:
                        "请选择月份",
                        rules: [
                        {
                        required: true,
                        message: "请选择月份",
                        trigger: "blur",
                        },
                    ],
                },
                {
                    label: "本期计划付款金额",
                    value: "amount",
                    inputType: "text",
                    defaultValue: "",
                    disabled: true,
                    placeholderText:
                        "请输入本期计划付款金额",
                },
            ],
            formList_form: [
                {
                    label: "本期采购计划支付",
                    value: "paymentAmount",
                    inputType: "text",
                    disabled: false,
                    defaultValue: "",
                    change: this.changePaymentAmount,
                    rules: [
                        {
                        required: true,
                        message: "请输入本期采购计划支付",
                        trigger: "blur",
                        },
                        {
                            required: true,
                            pattern: /^\d*\.?\d{0,2}$/,
                            message: "请输入数字，小数最多两位",
                            trigger: "blur",
                        }
                    ],
                },
                {
                    label: "本期采购计划付款节点说明",
                    value: "nodeDescription",
                    inputType: "text",
                    disabled: false,
                    defaultValue: "",
                    rules: [
                        {
                        required: true,
                        message: "请输入本期采购计划付款节点说明",
                        trigger: "blur",
                        },
                    ],
                },
                {
                    label: "采购付款条件及说明",
                    value: "conditionInstruction",
                    inputType: "text",
                    disabled: false,
                    defaultValue: "",
                    rules: [
                        {
                        required: true,
                        message: "请输入本期采购计划付款节点说明",
                        trigger: "blur",
                        },
                    ],
                },
                {
                    label: "本期付款后采购累计已付",
                    value: "currentPaymentAfterPaid",
                    inputType: "text",
                    disabled: true,
                    defaultValue: "",
                },
            ],
        }
    },
    created() {
        if(this.pageProp.pageType != 'add') {
            this.getPageData();
        }
        
        this.getDataType();
    },
    mounted() {

    },
    methods: {
        getPageData() {
            getApi(`/contract/payment/schedule/query?id=${this.pageProp.id}`).then(res => {
                if(res.data.code == 0) {
                    let { data } = res;
                    data.data.year = data.data.year.toString();
                    this.$refs.form_addEditPopup.formData = {...data.data};
                    this.tableData = data.data.scheduleVOS || [];
                }
            })
        },
        addContarct() {
            this.addKingDeeVisible = true;
            this.pageProp.tableData = this.tableData
            this.pageProp.key = 'purchaseContractId'
        },
        handleDelete(row) {
            if(this.pageProp.pageType == 'check' || this.pageProp.pageType == 'detail') {
                return false;
            }
            this.tableData = this.tableData.filter(item => item.id !== row.id);
        },
        changePaymentAmount() {
            this.formList_form[0].defaultValue = this.$refs.EditForm.formData.paymentAmount
            this.formList_form[1].defaultValue = this.$refs.EditForm.formData.nodeDescription;
            this.formList_form[2].defaultValue = this.$refs.EditForm.formData.conditionInstruction;
            this.formList_form[3].defaultValue = (Number(this.$refs.EditForm.formData.paymentAmount || 0) + Number(this.rowData.frealamount || 0)).toFixed(2);
        },
        handleEdit(e) {
            if(this.pageProp.pageType == 'add' || this.pageProp.pageType == 'edit') {
            }
            else {
                return false
            }
            this.popup_formVisible = true;
            
            this.rowData = e;
            this.rowId = e.id;
            this.$nextTick(() => {
                this.formList_form[0].defaultValue = this.rowData.paymentAmount;
                this.formList_form[1].defaultValue = this.rowData.nodeDescription;
                this.formList_form[2].defaultValue = this.rowData.conditionInstruction;
                this.formList_form[3].defaultValue = (Number(this.rowData.paymentAmount || 0) + Number(this.rowData.frealamount || 0)).toFixed(2);
                this.$forceUpdate()
            })
        },
        handleSave() {
            let flag = false;
                this.$refs.EditForm.$refs.EditForm.validate(
                    (valid) => {
                    if (valid) {
                        flag = true;
                    }
                    }
                );
                if(!flag) {
                    return false
                }
                this.tableData.forEach(item => {
                    if(item.id == this.rowId) {
                        this.$set(item, 'paymentAmount', this.$refs.EditForm.formData.paymentAmount)
                        this.$set(item, 'nodeDescription', this.$refs.EditForm.formData.nodeDescription)
                        this.$set(item, 'conditionInstruction', this.$refs.EditForm.formData.conditionInstruction)
                        this.$set(item, 'currentPaymentAfterPaid', this.$refs.EditForm.formData.currentPaymentAfterPaid)
                    }
                })
                let amount = 0;
                this.tableData.forEach(item => {
                    amount += Number(item.paymentAmount || 0)
                })
                this.$refs.form_addEditPopup.formData.amount = amount
                this.popup_formVisible = false;
        },
        handleClose() {
            this.addKingDeeVisible = false;
            this.popup_formVisible = false;
        },
        handleSubmit() {
            let purchaseContractIdArr = this.$refs.addKingDee.selection_kingDee.map(item => { return item.purchaseContractId})
            postApi(`/contract/payment/schedule/selected/purchase`, {purchaseIds: purchaseContractIdArr}).then(res => {
                if(res.data.code == 0) {
                    this.tableData = (this.tableData || []).concat(res.data.data);
                }
            })
            this.addKingDeeVisible = false;
        },
        getDataType() {
            postApi(`/contract/dicttype/list/all`, {code: "PC-CGJH-STATUS"}).then(res => {
                if(res.data.code == 0) {
                    this.formList.forEach(item => {
                        if(item.value == 'status') {
                            item.optionsList = res.data.data;
                        }
                    })
                }
            })
        },
        getSummaries(param) { // 自定义项表格合计方法
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                sums[index] = "合计";
                return;
                } else if (column.property == "paymentAmount"
                ) {
                //如果是经费（正常的加减法）
                const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    var sum = 0;
                    if (!isNaN(value)) {
                        sum = Number(Number(prev) + Number(curr)).toFixed(2);
                        return sum;
                    } else {
                        return prev;
                    }
                    }, 0);
                    sums[index] += " ";
                }
                }
            });
            return sums
            },
    }
}
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
</style>