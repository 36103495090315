<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>
  
  <script>
import { postApi, getApi, loadApi, delApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowDataList: [],
    rowId: null,
  },
  data() {
    return {
      formBasicList: [],
    };
  },
  created() {},
  mounted() {
    this.rowDataList.map((item) => {
      if (item.workMetering == "PRJ-GZBTXLX-NUMBER") {
        this.formBasicList.push({
          label: item.key,
          value: item.value,
          inputType: "text",
          placeholderText: "请输入工作量",
          defaultValue: item.defaultValue,
          rules: [
            { required: true, message: "请输入工作量", trigger: "blur" },
            {
              pattern: /^\d+(\.\d{1,2})?$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "change",
            },
          ],
        });
      } else if (item.workMetering == "PRJ-GZBTXLX-OPTIONS") {
        this.formBasicList.push({
          label: item.key,
          value: item.value,
          inputType: "select",
          optionsList: item.options,
          defaultValue: item.defaultValue,
          placeholderText: "请选择",
          rules: [{ required: true, message: "请选择", trigger: "blur" }],
        });
      }
    });
    console.log(this.rowDataList, this.rowId);
  },
  methods: {},
};
</script>
  <style lang='less' scoped>
</style>
  