<template>
    <div class="container">
      <dd-card title="基本信息">
        <dd-form :form-config="formList" :flex="2" :ref="formRef"></dd-form>
      </dd-card>
    </div>
  </template>
  
  <script>
  import { postApi, getApi, loadApi } from "@/api/request";
  import { mapState, mapActions } from "vuex";
  
  export default {
    props: {
      type: "",
      id: "",
    },
    computed: {
      ...mapState(["btnAuthorityList"]),
    },
    data() {
      return {
        detailiInfo: {},
        hrVehicleApplySheetFileList: [],
        formRef: "baseForm",
        headers: {}, // 请求头信息
        limit: 10, //文件上次个数限制
        ruleForm: {},
        activeName: "first",
        fileList: [],
        applyId: "",
        value: "",
        proTableData: [],
        manTableData: [],
        saleTableData: [],
        devTableData: [],
        tableData: [],
        formList: [
          {
            label: "人员",
            value: "username",
            inputType: "text",
            type: "text",
            disabled: true,
            defaultValue: "",
          },
          {
            label: "工号",
            value: "account",
            inputType: "text",
            disabled: true,
            type: "text",
            defaultValue: "",
          },
          {
            label: "所属年份",
            value: "year",
            inputType: "text",
            disabled: true,
            defaultValue:"",
          },
          {
            label: "所属月份",
            value: "month",
            inputType: "text",
            defaultValue: "",
            disabled: true,
          },
          {
            label: "工资余额",
            value: "salary",
            inputType: "text",
            defaultValue: "",
            disabled: true,
          },
          {
            label: "备注",
            value: "remark",
            inputType: "text",
            type: "textarea",
            defaultValue: "",
            disabled: true,
          },
        ],
      };
    },
    created() {
      this.getDetailInfo();
    },
    mounted() {},
    methods: {
      //查询详情
      getDetailInfo() {
        getApi(`/finance/balance/${this.id}`, {}).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formList.forEach((item) => {
              item.defaultValue = data.data[item.value];
            });
          }
        });
      },
    },
  };
  </script>
  
  <style lang='less' scoped>
  /deep/.el-input.is-disabled .el-input__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
  }
  /deep/.el-textarea.is-disabled .el-textarea__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
    resize: none;
  }
  /deep/ .el-input__suffix {
    display: none;
  }
  </style>