<template>
    <div class="main-container">
        <div class="head_wrap">
            <dd-card title="条件搜索" spacer>
                <dd-search-form :form-config="formList" @search-click="handleSearchEvent"
                    ref="ddSearchForm"></dd-search-form>
            </dd-card>
        </div>
        <dd-card title="目录列表">
            <dd-table :columns="tableColumns" :data="tableData" max-height="350" border isSelection
                 show-page :loading="loading" @select-change="handleTableSelect">
                <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handleCurrentChange" align="right">
                    </dd-pagination>
                </template>
            </dd-table>
        </dd-card>
    </div>
  </template>
    
  <script>
  import { getApi, delApi, postApi, putApi } from "@/api/request";
  export default {
    components: {
    },
    computed: {
    },
    data() {
        return {
            loading: true,
            formData: {},
            formList: [
                {
                    label: "目录名称",
                    value: "name",
                    inputType: "text",
                    placeholderText: "请输入目录名称",
                },
            ],
            tableColumns: [
                {
                    title: "目录名称",
                    key: "name",
                    align: "center",
                },
                {
                    title: "排序",
                    key: "sortNum",
                    align: "center",
                }
            ],
            tableData: [],
            current: 1,
            size: 20,
            total: null,
            rowData: null,
            poptitleName: '新增',
            selection: []
        };
    },
    created() { },
    mounted() {
        this.getTableData();
    },
    methods: {
        // 获取表格列表数据
        getTableData() {
            let { formData, current, size } = this;
            let params = {
                ...formData,
                current,
                size
            };
            this.loading = true;
            getApi(`/project/prj/dir/page`, params).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    this.loading = false;
                    this.tableData = data.data.records;
                    this.total = data.data.total;
                }
            });
        },
        handleTableSelect(selection) {
            this.selection = selection;
        },
        /* 搜索栏 */
        handleSearchEvent(obj) {
            this.formData = obj;
            this.current = 1;
            this.getTableData();
        },
        /* 分页页码回调 */
        handleCurrentChange(size, number) {
            this.current = number;
            this.size = size;
            this.getTableData();
        },
    },
  };
  </script>
    
  <style lang='less' scoped>
    .main-container {
        display: block !important;
    }
  </style>