<template>
    <div class="main-container">
        <dd-form :form-config="formBasicList" ref="addPopForm">
        </dd-form>
    </div>
  </template>
  
  <script>
  export default {
    name: "add-pop",
    props: {
      dictType: [],
      rowData: []
    },
    data() {
      return {
        formBasicList: [
          {
            label: "部门名称",
            value: "name",
            inputType: "text",
            placeholderText: "请输入部门名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入部门名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "部门编码",
            value: "name",
            inputType: "text",
            placeholderText: "请输入部门编码",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入部门编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "上级部门",
            value: "parentId",
            inputType: "cascader",
            optionsList: [],
            prop: {
              value: "id",
              label: "name",
              checkStrictly: true,
              emitPath: false
            },
            showAllSelcet: false,
            placeholderText: "请选择上级部门",
            rules: [
              {
                required: true,
                message: "请选择上级部门",
                trigger: "blur",
              },
            ]
          },
          {
            label: "排序",
            value: "sortOrder",
            inputType: "number",
            min: 0,
            max: 999,
            placeholderText: "请输入排序",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
        ],
        deptTree: [],
      };
    },
    created() {},
    mounted() {
      if(this.dictType) {
          let newTree = [{
            name: '根菜单',
            id: '0',
            children: this.dictType 
          }]
          this.formBasicList.forEach(item => {
            if(item.value == 'parentId') {
              item.optionsList = newTree
            }
          })
        }
        if(this.rowData != null) {
            this.formBasicList.forEach(item => {
                item.defaultValue = this.rowData[item.value]
            })
        }
    },
    methods: {
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.optionsList = options.optionsList;
                    }
                }
            });
        },
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
  