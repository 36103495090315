<template>
  <div class="task-container" v-if="defaultPage">
    <div class="top-tab">
      <dd-button type="text" size="medium" icon="back" @click="handleBackPage"
        >返回上一级</dd-button
      >
      <el-tabs v-model="activeName" type="card" @tab-click="handleTabClick">
        <el-tab-pane
          v-for="val in tabList"
          :key="val.value"
          :label="val.label"
          :name="val.value"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div class="wrapper">
      <!-- <child-task
        v-if="activeName == 'zrw'"
        :rowId="rowId"
        :taskData="taskData"
        :resolveType="resolveType"
        @handleTaskResolve="handleTaskResolve"
      ></child-task> -->
      <work-resolve
        v-if="activeName == 'gzfj'"
        :rowId="rowId"
        :sourceType="sourceType"
        :resolveType="resolveType"
      ></work-resolve>
      <person-manage
        v-if="activeName == 'gxrgl'"
        :rowId="rowId"
        :resolveType="resolveType"
      ></person-manage>
      <schedule-manage
        v-if="activeName == 'jdgl'"
        :rowId="rowId"
        :resolveType="resolveType"
      ></schedule-manage>
      <work-amount
        v-if="activeName == 'gzlgl'"
        :rowId="rowId"
        :resolveType="resolveType"
      ></work-amount>
      <cost-manage
        v-if="activeName == 'cbgl'"
        :rowId="rowId"
        :resolveType="resolveType"
      ></cost-manage>
      <doc-manage
        v-if="activeName == 'wdgl'"
        :rowId="rowId"
        :resolveType="resolveType"
      ></doc-manage>
      <wx-manage
        v-if="activeName == 'wxgl'"
        :rowId="rowId"
        :resolveType="resolveType"
      ></wx-manage>
      <complete-prj-manage
        v-if="activeName == 'wcgzlgl'"
        :resolveType="resolveType"
      ></complete-prj-manage>
    </div>
  </div>
  <!-- <init-child-task-resolve
    v-else
    @pageBack="defaultPage = true"
    :assignData="assignData"
    @nextTaskResolve="nextTaskResolve"
  ></init-child-task-resolve> -->
</template>

<script>
// import initChildTaskResolve from "./childTask/components/InitResolveTask.vue";
// // 子任务
// import childTask from "./childTask";
// 工作分解
import workResolve from "./workResolve";
// 干系人管理
import personManage from "./personManage";
// 进度管理
import scheduleManage from "./scheduleManage";
// 工作量管理
import workAmount from "./workAmount";
// 成本管理
import costManage from "./costManage";
// 文档管理
import docManage from "./docManage";
// 外协管理
import wxManage from "./wxManage";
// 完成工程量管理
import completePrjManage from "./completePrjManage";
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    sourceType: {
      type: String,
      default: "",
    },
    rowId: {
      type: String,
      default: "",
    },
    // taskData: {
    //   type: Object,
    //   default: {},
    // },
  },
  components: {
    // childTask,
    workResolve,
    personManage,
    scheduleManage,
    workAmount,
    costManage,
    docManage,
    wxManage,
    completePrjManage,
    // initChildTaskResolve,
  },
  data() {
    return {
      defaultPage: true,
      resolveType: "",
      activeName: "gzfj",
      tabList: [
        {
          label: "工作分解",
          value: "gzfj",
        },
        {
          label: "干系人管理",
          value: "gxrgl",
        },
        {
          label: "进度管理",
          value: "jdgl",
        },
        {
          label: "工作量管理",
          value: "gzlgl",
        },
        {
          label: "成本管理",
          value: "cbgl",
        },
        {
          label: "文档管理",
          value: "wdgl",
        },
        {
          label: "外协管理",
          value: "wxgl",
        },
        // {
        //   label: "完成工作量管理",
        //   value: "wcgzlgl",
        // },
      ],
      assignData: null,
    };
  },
  created() {
    this.resolveType = this.type;
    // if (this.type == "childTask") {
    //   // this.tabList.unshift({
    //   //   label: "子任务",
    //   //   value: "zrw",
    //   // });
    //   this.activeName = "gzfj";
    // }
  },
  mounted() {
    console.log(this.type);
  },
  methods: {
    handleTabClick(tab, event) {
      if (tab.name == "zrw") {
        this.resolveType = "childTask";
      }
      this.activeName = tab.name;
    },
    handleBackPage() {
      this.$emit("pageBack");
      this.$bus.$emit("collapse", false);
    },
    handleTaskResolve(data) {
      this.defaultPage = false;
      this.assignData = data;
    },
    nextTaskResolve() {
      this.resolveType = "childTask";
      this.defaultPage = true;
      this.activeName = "gzfj";
    },
  },
};
</script>

<style lang="less" scoped>
.task-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: @systemColor;
  position: relative;
  .top-tab {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: #f2f7ff;
    border-bottom: 1px solid #fff;
    /deep/ i {
      font-weight: 800;
    }
    /deep/ .el-tabs {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    /deep/ .el-tabs__header {
      margin: 0;
      border: none;
    }
    /deep/ .el-tabs__nav {
      border: none;
    }
    /deep/ .el-tabs__item {
      height: auto;
      line-height: normal;
      padding: 0 35px;
      border-left-width: 2px;
      border-left-color: #888888;
      font-size: @fs16;
      font-weight: bold;
      color: #0b1d57;
    }
    /deep/ .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
      border-bottom: none !important;
    }
  }
  .wrapper {
    width: 100%;
    height: calc(100% - 40px);
  }

  /deep/ .el-button--text {
    color: @themeColor;
    font-size: @fs16;
  }
}
</style>
