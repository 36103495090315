<template>
  <div class="main-container">
    <div class="left-box">
      <div class="head_wrap">
        <dd-card title="条件搜索" spacer>
          <dd-search-form
            :form-config="formList"
            @search-click="handleSearchEvent"
            ref="ddSearchForm"
          ></dd-search-form>
        </dd-card>
      </div>
      <dd-card title="项目类型列表" :height="tableHeight">
        <dd-table
          :columns="tableColumns"
          :data="tableData"
          ref="leftTable"
          border
          isSelection
          show-page
          :loading="loading"
          @row-click="handleTableRow"
          @select="handleSelect"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAdd"
              >新增</dd-button
            >
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
    <div class="right-box">
      <div class="head_wrap">
        <dd-card title="条件搜索" spacer>
          <dd-search-form
            :form-config="rightFormList"
            @search-click="handleRightSearchEvent"
            ref="ddRightSearchForm"
          ></dd-search-form>
        </dd-card>
      </div>
      <dd-card title="目录列表" :height="tableHeight">
        <dd-table
          :columns="rightTableColumns"
          :data="rightTableData"
          max-height="530"
          ref="rightTable"
          border
          isSelection
          show-page
          :loading="rightLoading"
          @select-change="handleRightTableSelect"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleRightAdd"
              >新增</dd-button
            >
            <dd-button
              type="danger"
              size="medium"
              icon="delete"
              @click="handleArrDelete"
              >删除</dd-button
            >
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="rightTotal > 0"
              :total="rightTotal"
              :page-size="rightSize"
              :page-number="rightCurrent"
              @page-change="handleRightCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
    <dd-popup
      width="30%"
      height="30%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></add-pop>
    </dd-popup>
    <dd-popup
      width="50%"
      height="80%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="catalogPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleRightSave('addPopForm', 'baseForm')"
    >
      <catalog-pop
        v-if="catalogPopupVisible"
        slot="main"
        ref="addRightPop"
        :rowData="rowData"
      ></catalog-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
import catalogPop from "./components/AddCatalogPop.vue";
export default {
  name: "tenement",
  $dynamicRoute: {
    name: "目录类型",
    path: "/project/file/type",
  },
  components: {
    addPop,
    catalogPop,
  },
  computed: {},
  data() {
    return {
      tableHeight: null,
      drawerVisible: false,
      loading: false,
      rightLoading: false,
      formData: {},
      rightFormData: {},
      typeId: null,
      formList: [
        {
          label: "项目类型",
          value: "typeName",
          inputType: "text",
          placeholderText: "请输入项目类型",
        },
      ],
      rightFormList: [
        {
          label: "目录名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入目录名称",
        },
      ],
      tableColumns: [
        {
          title: "类型列表",
          key: "typeName",
          align: "center",
        },
        {
          title: "排序",
          key: "sortNum",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
            {
              show: "true",
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row, "left");
              },
              type: "danger",
            },
          ],
        },
      ],
      rightTableColumns: [
        {
          title: "目录名称",
          key: "name",
          align: "center",
        },
      ],
      tableData: [],
      rightTableData: [],
      current: 1,
      rightCurrent: 1,
      size: 20,
      rightSize: 20,
      total: null,
      rightTotal: 0,
      dictType: [],
      addPopupVisible: false,
      catalogPopupVisible: false,
      rowData: null,
      poptitleName: "新增",
      rightSelection: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      getApi(`/admin/dict/key/type-zhzt`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "status",
            optionsList: data.data,
          };
          this.dictType = data.data;
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      getApi(`/project/catalogtype/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 表格行点击选择事件
    handleTableRow(row) {
      let data = [];
      if (row == this.rowData) {
        row = null;
      } else {
        this.rowData = row;
        data.push(row);
      }
      this.handleSelect(data, row);
    },
    // 复选框单选事件
    handleSelect(selection, row) {
      this.selection = selection;
      this.rowData = row;
      this.$refs.leftTable.$refs.baseTable.clearSelection();
      if (selection.length == 0) {
        this.$refs.leftTable.$refs.baseTable.setCurrentRow(null);
        this.clearRightTable();
        return;
      }
      this.$refs.leftTable.$refs.baseTable.toggleRowSelection(row, true);
      this.$refs.leftTable.$refs.baseTable.setCurrentRow(row);
      this.typeId = row.id;
      this.getRightTableData();
      this.handleRightTableSelect([]);
    },
    // 复选框选择事件
    handleRightTableSelect(selection) {
      this.rightSelection = selection;
      if (selection.length == 0) {
        this.$refs.rightTable.$refs.baseTable.clearSelection();
        return;
      }
    },
    // 右侧表格数据
    getRightTableData(id) {
      let { rightFormData, rightCurrent, rightSize, typeId } = this;
      let params = {
        ...rightFormData,
        current: rightCurrent,
        size: rightSize,
        typeId,
      };
      this.rightLoading = true;
      getApi(`/project/catalogtypedir/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.rightLoading = false;
          this.rightTableData = data.data.records;
          this.rightTotal = data.data.total;
        }
      });
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
      this.rowData = null;
    },
    handleRightAdd() {
      if (this.typeId == null) {
        this.$message.warning("请先选择项目类型");
        return;
      }
      this.catalogPopupVisible = true;
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
      this.catalogPopupVisible = false;
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "编辑";
    },
    //列表单行删除
    handleRowDel(row, type) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        if (type == "left") {
          this.handleConfirmDelete(row.id);
        } else {
          this.handleArrDelete(row.id);
        }
      });
    },
    handleArrDelete() {
      if (this.typeId == null) {
        this.$message.warning("请先选择项目类型");
        return;
      }
      if (this.rightSelection.length == 0) {
        this.$message.warning("请至少选择一条数据删除");
        return;
      }
      this.$confirm(`确认删除所选数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleRightConfirmDelete();
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/project/catalogtype/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
          this.getTableData();
          this.clearRightTable();
        }
      });
    },
    handleRightConfirmDelete() {
      let arr = [];
      this.rightSelection.forEach((item) => {
        arr.push(item.dirId);
      });
      let params = {
        dirIds: arr,
        typeId: this.typeId,
      };
      delApi(`/project/catalogtypedir`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getRightTableData();
      });
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
      this.handleSelect([]);
      this.typeId = null;
      this.getRightTableData();
    },
    handleRightSearchEvent(obj) {
      if (this.typeId == null) {
        this.$message.warning("请先选择项目类型");
        return;
      }
      this.rightFormData = obj;
      this.rightCurrent = 1;
      this.getRightTableData();
      this.handleRightTableSelect([]);
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    handleRightCurrentChange(size, number) {
      this.rightCurrent = number;
      this.rightSize = size;
      this.getRightTableData();
    },
    // 清除右侧表格数据
    clearRightTable() {
      this.$refs.ddRightSearchForm.formData = {};
      this.typeId = null;
      this.rightTableData = [];
      this.rightTotal = 0;
      this.rightSize = 20;
      this.rightCurrent = 1;
      this.rightSelection = [];
      this.rowData = null;
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (flag) {
        if (this.rowData != null) {
          params.id = this.rowData.id;
          putApi(`/project/catalogtype`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.handleClose();
              this.getTableData();
            }
          });
        } else {
          postApi(`/project/catalogtype`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
            }
          });
        }
      }
    },
    handleRightSave() {
      let selectArr = this.$refs.addRightPop.selection;
      if (selectArr.length > 0) {
        let arr = [];
        selectArr.forEach((item) => {
          arr.push(item.id);
        });
        let params = {
          dirIds: arr,
          typeId: this.typeId,
        };
        postApi(`/project/catalogtypedir/add`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("保存成功");
            this.handleClose();
            this.getRightTableData();
          }
        });
      } else {
        this.$message.warning("请先选择目录");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  display: flex;
  justify-content: space-between;
  .left-box {
    width: 43%;
    /deep/ .has-gutter {
      .el-table-column--selection {
        .cell {
          display: none;
        }
      }
    }
  }
  .right-box {
    width: 56%;
  }
  /deep/ .search-form-input {
    width: 50% !important;
  }
}
</style>
