<template>
    <div class="main-container">
        <dd-form :form-config="formBasicList" ref="addPopForm">
        </dd-form>
    </div>
  </template>
  
  <script>
  export default {
    name: "add-pop",
    props: {
      dictType: [],
      rowData: []
    },
    data() {
      return {
        formBasicList: [
          {
            label: "岗位名称",
            value: "postName",
            inputType: "text",
            placeholderText: "请输入岗位名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入岗位名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "岗位标识",
            value: "postCode",
            inputType: "text",
            placeholderText: "请输入岗位标识",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入岗位标识",
                trigger: "blur",
              },
            ],
          },
          {
            label: "岗位描述",
            value: "remark",
            inputType: "text",
            type: "textarea",
            placeholderText: "请输入岗位描述",
            disabled: false,
            defaultValue: '',
          },
          {
            label: "排序",
            value: "postSort",
            inputType: "number",
            min: 0,
            max: 999,
            placeholderText: "请输入排序",
            disabled: false,
            defaultValue: '',
          },
        ],
      };
    },
    created() {},
    mounted() {
        if(this.rowData != null) {
            this.formBasicList.forEach(item => {
                item.defaultValue = this.rowData[item.value]
            })
        }
    },
    methods: {
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
  