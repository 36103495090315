/*
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-08-21 17:07:00
 * @LastEditors: hechen
 * @LastEditTime: 2023-10-10 11:20:07
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\router\index.js
 * @Descripttion: 
 */
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)
// 保存原来的push函数
const VueRouterPush = Router.prototype.push;
// 重写push函数
Router.prototype.push = function push(to) {
  // 调用原来的push函数，并捕获异常
  return VueRouterPush.call(this, to).catch(err => err);
};
const routeContext = require.context('../views',true,/index.(vue|js)$/)
const routes = [];
routeContext.keys().forEach(file_name => {
  const component = routeContext(file_name);
  if(component.default.hasOwnProperty('$dynamicRoute')) {
    routes.push(routeItemGenerator(file_name, component.default))
  }
})
/**
 * 
 * @param {*} key 
 * @param {*} config 
 */
 function routeItemGenerator(key, component) {
  const config = component.$dynamicRoute
  const route = {}
  //路由的name
  if (config.name) {
    route.name = config.name
  } else if (component.name) {
    route.name = component.name
  } else {
    route.name = path2name(key)
  }
  // 路由的path
  route.path = config.path || route.name
  // 元数据
  route.meta = config.meta || { requiresAuth: true }
  // 路由组件与路由映射
  route.component = component
  // 返回
  return route
}
/**
 * 将路径转换成name/path
 * @param {string} path 
 */
function path2name(path) {
  if (typeof path !== 'string') return ''
  let name = ''
  path = path.split('/')
  path.shift()
  name = path.join('_').replaceAll('.vue', '').toUpperCase()
  return name
}
export default new Router({
  routes: [{
    path: '/',
    redirect: '/login',
    meta: {
      isTab: false,
    }
  },{
    path: '/login',
    name: '登录',
    component: () => import('@/views/login.vue'),
    meta: {
      requireAuth: true,
      isTab: false,
    }
  },
  {
    path: '/home',
    name: '首页',
    component: () => import('@/views/home.vue'),
    children: routes
  }]
})