<!--
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-03-22 09:44:39
 * @LastEditors: hechen
 * @LastEditTime: 2023-10-16 16:50:57
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\components\BaseCard\index.vue
 * @Descripttion: 页面card布局组件
-->
<template>
  <div class="base-card" :style="`--height: ${height}`">
    <div class="card-title" v-if="title" style="display: flex;justify-content: space-between;" :style="titleStyle">
      <div>{{ title }}</div>
      <div v-if="showOpen" >
        <dd-button type="primary" size="mini" @click="openAndClose">{{ isOpen ? '展开' : '收起' }}
        </dd-button>
      </div>
    </div>
    <div class="card-contant" v-show="!isOpen" :style="title ? { height: 'calc(100% - 45px)' } : { height: '100%' }">
      <slot></slot>
    </div>
    <!-- 间隔符 card-spacer-->
    <div v-show="spacer" class="card-spacer"></div>
  </div>
</template>

<script>
export default {
  name: "dd-card",
  props: {
    title: {
      type: String,
      default: "",
    },
    // 是否显示间隔符
    spacer: {
      type: Boolean,
      default: false,
    },
    titleStyle: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "auto",
    },
    showOpen: {
      type: Boolean,
      default: false,
    },
    ExpandAndCollapse: {
      type: Function,
      default: () => {}
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    openAndClose() {
      this.isOpen = !this.isOpen;
      return this.ExpandAndCollapse(this.isOpen)
    }
  }
};
</script>

<style lang="less" scoped>
.base-card {
  height: var(--height);

  .card-title {
    padding: 0 15px;
    box-sizing: border-box;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid @dividerColor;
    font-weight: bold;
  }

  .card-contant {
    overflow: hidden;
    padding: 10px 15px;
    box-sizing: border-box;
  }

  .card-spacer {
    height: 4px;
    background-color: @dividerColor;
  }
}
</style>
