<template>
    <div class="main-container">
        <dd-form :form-config="formBasicList" ref="addPopForm">
        </dd-form>
    </div>
  </template>
  
  <script>
  export default {
    name: "add-pop",
    props: {
      dictType: [],
      menuType: [],
      bufferType: [],
      rowData: [],
      poptitleName: ''
    },
    data() {
      return {
        formBasicList: [
          {
            label: "上级菜单",
            value: "parentId",
            inputType: "text",
            disabled: true,
            defaultValue: "一级父类"
          },
          {
            label: "类型状态",
            value: "state",
            inputType: "radio",
            defaultValue: "",
            border: true,
            radioGroup: [],
            rules: [
              {
                required: true,
                message: "请选择类型状态",
                trigger: "blur",
              },
            ]
          },
          {
            label: "费用名称",
            value: "name",
            inputType: "text",
            placeholderText: "请输入费用名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入费用名称",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    created() {},
    mounted() {
        if(this.rowData != null) {
            this.formBasicList.forEach(item => {
              if(this.poptitleName == "新增") {
                if(item.value == "parentId") {
                  item.defaultValue = this.rowData.name
                }
              }else {
                item.defaultValue = this.rowData[item.value]
              }
            })
        }
        let options2 = {
            value: "state",
            optionsList: this.bufferType,
        };
        this.setOPtionsList(this.formBasicList, options2);
    },
    methods: {
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.radioGroup = options.optionsList;
                        if(item.defaultValue == '') {
                          item.defaultValue = options.optionsList[0].value;
                        }
                    }
                }
            });
        },
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
  