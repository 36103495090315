<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm">
    </dd-form>
  </div>
</template>
  
<script>
export default {
  name: "add-pop",
  props: {
    dictType: [],
    rowData: []
  },
  data() {
    return {
      formBasicList: [
        {
          label: "标识",
          value: "dictKey",
          inputType: "text",
          placeholderText: "请输入标识",
          disabled: false,
          defaultValue: '',
          rules: [
            {
              required: true,
              message: "请输入标识",
              trigger: "blur",
            },
          ],
        },
        {
          label: "描述",
          value: "description",
          inputType: "text",
          placeholderText: "请输入描述",
          disabled: false,
          defaultValue: '',
          rules: [
            {
              required: true,
              message: "请输入描述",
              trigger: "blur",
            },
          ],
        },
        {
          label: "字典类型",
          value: "systemFlag",
          inputType: "radio",
          defaultValue: "",
          border: true,
          radioGroup: [],
          rules: [
            {
              required: true,
              message: "请选择字典类型",
              trigger: "blur",
            },
          ]
        },
        {
          label: "备注信息",
          value: "remark",
          inputType: "text",
          placeholderText: "请输入备注信息",
          disabled: false,
          defaultValue: '',
        },
      ],
    };
  },
  created() { },
  mounted() {
    if (this.rowData != null) {
      let obj = {
        label: "创建时间",
        value: "createTime",
        inputType: "text",
        placeholderText: "请输入备注信息",
        disabled: true,
        defaultValue: this.rowData.createTime,
      }
      this.formBasicList.push(obj);
      this.formBasicList.forEach(item => {
        item.defaultValue = this.rowData[item.value]
        if (item.value == 'dictKey') {
          item.disabled = true;
        }
      })
    }
    let options1 = {
      value: "systemFlag",
      optionsList: this.dictType,
    };
    this.setOPtionsList(this.formBasicList, options1);
  },
  methods: {
    change() {
      this.$forceUpdate();
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.radioGroup = options.optionsList;
          }
        }
      });
    },
  },
};
</script>
<style lang='less' scoped>
</style>
  