<template>
  <div class="container">
    <dd-card title="基本信息">
      <dd-form :form-config="formList" ref="formRef" :flex="3"></dd-form>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import qs from "qs";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    type: "",
    id: "",
  },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      detailiInfo: {},
      hrVehicleApplySheetFileList: [],
      tableData: [],
      formList: [
      {
          label: "用车补助类型",
          value: "carSubsidyTypeName",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "code",
            name: "name",
          },
          defaultValue: '私车公用',
          placeholderText: "请选择用车补助类型",
          rules: [
            { required: true, message: "请选择用车补助类型", trigger: "blur" },
          ],
        },
        {
          label: "申请编号",
          style: "width:50%",
          value: "applyCode",
          inputType: "text",
          placeholderText: "保存后生成",
          disabled: true,
          defaultValue: "",
        },
        // {
        //   label: "申请时间",
        //   style: "width:50%",
        //   value: "applyDate",
        //   disabled: true,
        //   placeholderText: "用车起始日期",
        //   inputType: "date",
        //   dateType: "date",
        //   defaultValue: this.formatDate(new Date()),
        // },
        {
          label: "申请人",
          value: "applierName",
          style: "width:50%",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: this.getText("applier"),
        },
        {
          label: "费用承担组织",
          style: "width:50%",
          value: "expenseBearOrgName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: this.getText("orgName"),
        },
        {
          label: "费用承担部门",
          style: "width:50%",
          value: "expenseBearDepId",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "deptId",
            name: "name",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索费用承担部门",
          rules: [
            { required: true, message: "请选择费用承担部门", trigger: "blur" },
          ],
        },
        {
          label: "项目名称",
          style: "width:50%",
          value: "projectName",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "projectId",
            name: "projectName",
          },
          defaultValue: "",
          filterable: true,
          change: this.handleSelectChange,
          clear: this.handleSelectClear,
          placeholderText: "请选择或搜索项目名称",
          rules: [
            { required: true, message: "请选择项目名称", trigger: "blur" },
          ],
        },
        {
          label: "项目编号",
          style: "width:50%",
          value: "projectId",
          filterable: true,
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "projectId",
            name: "projectCode",
          },
          change: this.handleSelectChange,
          clear: this.handleSelectClear,
          defaultValue: "",
          placeholderText: "请选择或搜索项目编号",
          rules: [
            { required: true, message: "请选择项目编号", trigger: "blur" },
          ],
        },
        {
          label: "收款人员工号",
          style: "width:50%",
          value: "payeeCode",
          filterable: true,
          disabled: true,
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "username",
            name: "username",
          },
          change: this.handleSelectChange,
          clear: this.handleSelectClear,
          defaultValue: "",
          placeholderText: "请选择或搜索收款人员工号",
          rules: [
            { required: true, message: "请选择收款人员工号", trigger: "blur" },
          ],
        },
        {
          label: "收款人",
          style: "width:50%",
          value: "payeeId",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          change: this.handleSelectChange,
          clear: this.handleSelectClear,
          defaultValue: "",
          placeholderText: "请选择或搜索收款人",
          rules: [{ required: true, message: "请选择收款人", trigger: "blur" }],
        },
        {
          label: "用车起始日期",
          style: "width:50%",
          value: "vehicleStartDate",
          placeholderText: "用车起始日期",
          inputType: "date",
          dateType: "date",
          change: this.handleChange,
          defaultValue: this.formatDate(new Date()),
          format: "yyyy-MM-dd",
          rules: [
            { required: true, message: "请选择用车起始日期", trigger: "blur" },
          ],
        },
        {
          label: "用车结束日期",
          style: "width:50%",
          value: "vehicleEndDate",
          placeholderText: "用车结束日期",
          inputType: "date",
          dateType: "date",
          format: "yyyy-MM-dd",
          change: this.handleChange,
          defaultValue: this.formatDate(new Date()),
          rules: [
            { required: true, message: "请选择用车结束日期", trigger: "blur" },
          ],
        },
        {
          label: "出发地",
          value: "departurePlace",
          style: "width:50%",
          inputType: "text",
          placeholderText: "请输入出发地",
          defaultValue: "",
          rules: [{ required: true, message: "请输入出发地", trigger: "blur" }],
        },
        {
          label: "目的地",
          style: "width:50%",
          value: "destinationPlace",
          inputType: "text",
          placeholderText: "请输入目的地",
          defaultValue: "",
          rules: [{ required: true, message: "请输入目的地", trigger: "blur" }],
        },
        {
          label: "起始公里数",
          value: "startKilometer",
          style: "width:50%",
          ref: "startKilometer",
          change: this.handleChange,
          inputType: "text",
          placeholderText: "请输入正整数,最大不超过8位数",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入起始公里数", trigger: "blur" },
            {
              // pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/,
              pattern: /^[1-9]\d{0,7}$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "结束公里数",
          style: "width:50%",
          ref: "endKilometer",
          change: this.handleChange,
          value: "endKilometer",
          inputType: "text",
          placeholderText: "请输入正整数,最大不超过8位数",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入结束公里数", trigger: "blur" },
            {
              // pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^0$)|(^\d\.\d{1,2}$)/,
              pattern: /^[1-9]\d{0,7}$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "用车公里数",
          disabled: true,
          style: "width:50.1%",
          value: "totalKm",
          inputType: "text",
          placeholderText: "自动计算",
          defaultValue: "",
        },
        {
          label: "起始公里图片",
          value: "startImg",
          style: "width:50%",
          action: "/admin/sys-file/upload",
          headers: {},
          accept: "image/*",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleRemove,
          ref: "startImg",
          placeholderText: "点击可替换之前上传图片",
          successEvent: (res) => {
            this.handleFileSuccess(res, "startImg");
          },
          defaultValue: "",
          rules: [
            { required: true, message: "请选择起始公里图片", trigger: "blur" },
          ],
        },
        {
          label: "结束公里图片",
          value: "endImg",
          style: "width:50%",
          action: "/admin/sys-file/upload",
          headers: {},
          accept: "image/*",
          placeholderText: "点击可替换之前上传图片",
          ref: "endImg",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleRemove,
          successEvent: (res) => {
            this.handleFileSuccess(res, "endImg");
          },
          defaultValue: "",
          rules: [
            { required: true, message: "请选择结束公里图片", trigger: "blur" },
          ],
        },
        {
          label: "用车事宜",
          value: "vehicleMatter",
          inputType: "text",
          style: "width:50.1%",
          type: "textarea",
          placeholderText: "请输入用车事宜",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入用车事宜", trigger: "blur" },
            {
              min: 1,
              max: 100,
              message: "长度在 1 到 100个字符",
              trigger: "blur",
            },
          ],
        },
        {
          label: "备注",
          value: "remark",
          inputType: "text",
          style: "width:49.9%",
          type: "textarea",
          placeholderText: "请输入备注",
          defaultValue: "",
          rules: [
            {
              max: 100,
              message: "长度在 1 到 100个字符",
              trigger: "blur",
            },
          ],
        },
      ],
      projectIdBak: "",
      flag: false,
    };
  },
  mounted() {
    this.getExpenseDepartmentList();
    this.getProjectList(); //项目列表
    this.getCollectionList(); //收款人列表
    if (this.type == "update" || this.type == "detail") {
      this.getDetailInfo();
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //项目列表
    getProjectList() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "projectName" || item.value == "projectId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    //收款人列表
    getCollectionList() {
      postApi(`/admin/user/getver/list`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "payeeCode" || item.value == "payeeId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    //费用承担部门
    getExpenseDepartmentList() {
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        // this.depList = res.data.data;
        this.options = {
          value: "expenseBearDepId",
          optionsList: res.data.data,
        };
        this.setOPtionsList(this.formList, this.options);
      });
    },
    //编辑页面信息回显
    getDetailInfo() {
      getApi(`/hr/applysheet/${this.id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.detailiInfo = data.data;
          console.log(data.data);
          this.hrVehicleApplySheetFileList =
            data.data.hrVehicleApplySheetFileList;
          let startImg = "";
          let endImg = "";
          data.data.hrVehicleApplySheetFileList &&
            data.data.hrVehicleApplySheetFileList.length &&
            data.data.hrVehicleApplySheetFileList.map((val) => {
              if (val.sign == "begin") {
                startImg = val.url;
              } else if (val.sign == "end") {
                endImg = val.url;
              }
            });
          this.formList.forEach((item) => {
            if (this.type == "detail") {
              item.disabled = true;
            }
            item.defaultValue = data.data[item.value];
            item.rules = this.type == "update" ? item.rules : [];
            if (item.value == "startImg") {
              item.defaultValue = startImg;
              item.placeholderText = "";
            } else if (item.value == "endImg") {
              item.defaultValue = endImg;
              item.placeholderText = "";
            } else if (item.value == "totalKm") {
              if (data.data.endKilometer && data.data.startKilometer) {
                item.defaultValue =
                  data.data.endKilometer - data.data.startKilometer;
              } else {
                item.defaultValue = 0;
              }
            }
          });
          console.log(this.formList);
        }
      });
    },

    getRowColumn({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex;
      column.index = columnIndex;
    },
    getText(value) {
      if (value == "applier") {
        console.log(sessionStorage.getItem("username"));
        return sessionStorage.getItem("username");
      } else if (value == "applyDep") {
        return sessionStorage.getItem("depname");
      } else if (value == "maker") {
        return sessionStorage.getItem("username");
      } else if (value == "orgName") {
        return sessionStorage.getItem("orgName");
      }
    },
    // 格式化日期
    formatDate(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    cancel() {
      this.$emit("diaChange", false);
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
</style>