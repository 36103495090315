<template>
  <div>
    <dd-card spacer>
      <dd-form :form-config="formList" ref="baseFoem" :flex="2"></dd-form>
    </dd-card>
    <dd-card title="详细计量内容" spacer>
      <dd-table
        max-height="235px"
        :columns="tableColumns"
        :data="tableDataSources"
        border
        :isSelection="false"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            size="medium"
            icon="add"
            @click="handleAddRow"
            >添加行</dd-button
          >
        </template>
      </dd-table>
    </dd-card>
    <dd-card title="详细定额计算">
      <div class="formula-box">
        <dd-form
          class="input"
          ref="formulaForm"
          :form-config="formula"
        ></dd-form>
        <dd-button
          class="btn"
          type="warning"
          size="medium"
          @click="handleFormulaVerify"
          >公式验证</dd-button
        >
      </div>
      <div class="resultText" v-if="formulaResult != null">
        返回结果：{{ formulaResult }}
      </div>
      <!-- <div v-else class="resultText">
        验证错误,请检查公式和计量内容是否输入正确
      </div> -->
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  components: {},
  props: {
    rowData: {
      type: Object,
      default: null,
    },
    popType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      formList: [
        {
          label: "工作包名称",
          value: "nodeName",
          inputType: "text",
          placeholderText: "工作包名称",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "工作量单位",
          value: "nodeUnitName",
          inputType: "text",
          placeholderText: "工作量单位",
          disabled: true,
          defaultValue: "",
        },
      ],
      formula: [
        {
          label: "计算公式",
          value: "formula",
          inputType: "text",
          placeholderText: "请输入计算公式",
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入计算公式",
              trigger: "blur",
            },
          ],
        },
      ],
      formulaResult: null,
      tableColumns: [
        {
          title: "计量内容",
          key: "workName",
          align: "center",
          input: [
            {
              placeholderText: "请输入",
            },
          ],
        },
        {
          title: "计量单位",
          key: "workUnit",
          align: "center",
          selectInput: {
            placeholderText: "请选择",
            options: [],
          },
        },
        {
          title: "填写类型",
          key: "workMetering",
          align: "center",
          selectInput: {
            placeholderText: "请选择",
            options: [],
          },
          change: (val, row) => {
            console.log(row);
            row.workSymbol = null;
            row.dictCode = null;
            row.value = null;
          },
        },
        {
          title: "代号",
          key: "workSymbol",
          align: "center",
          input: [
            {
              placeholderText: "请输入",
              isEdit: (value) => {
                let state = false;
                if (value.workMetering == "PRJ-GZBTXLX-TEXT") {
                  state = true;
                }
                return state;
              },
            },
          ],
        },
        {
          title: "字典",
          key: "dictCode",
          align: "center",
          selectInput: {
            placeholderText: "请选择",
            options: [],
            isEdit: (value) => {
              let state = true;
              if (value.workMetering == "PRJ-GZBTXLX-OPTIONS") {
                state = false;
              }
              return state;
            },
          },
          change: (val, row) => {
            row.value = null;
          },
        },
        {
          title: "值",
          key: "value",
          align: "center",
          inputTypeArray: [
            {
              inputType: "text",
              placeholderText: "请输入",
              rules: (value) => {
                return value.replace(/[^\d.]/g, "");
              },
              showEvent: (row) => {
                let show = false;
                if (row.workMetering == "PRJ-GZBTXLX-NUMBER") {
                  show = true;
                }
                return show;
              },
            },
            {
              inputType: "text",
              placeholderText: "请输入",
              showEvent: (row) => {
                let show = false;
                if (row.workMetering == "PRJ-GZBTXLX-TEXT") {
                  show = true;
                }
                return show;
              },
            },
          ],
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 120,
          event: [
            {
              label: "移除",
              icon: "el-icon-delete",
              method: (row, scope) => {
                this.handleRowDel(row, scope.$index);
              },
              type: "danger",
            },
          ],
        },
      ],
      tableDataSources: [],
    };
  },
  mounted() {
    if (this.rowData != null) {
      this.formList.forEach((item) => {
        item.defaultValue = this.rowData[item.value];
      });
    }
    this.getDataType();
    if (this.popType == "work") {
      this.getWokrDetailData();
    } else {
      this.getDetailData();
    }
  },
  methods: {
    getDetailData() {
      let params = {
        nodeId: this.rowData.id,
      };
      getApi(`/project/work/detail/list`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableDataSources = data.data.details;
          this.formula.forEach((item) => {
            item.defaultValue = data.data[item.value];
          });
        }
      });
    },
    getWokrDetailData() {
      getApi(
        `/project/prjareastandardnodework/getstandardnodeworkdetaillist/${this.rowData.id}`
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          if (data.data.prjAreaStandardNodeWorkDetailList.length > 0) {
            this.tableDataSources = data.data.prjAreaStandardNodeWorkDetailList;
          }
          data.data.prjAreaStandardNodeWorkFormula &&
            this.formula.forEach((item) => {
              item.defaultValue =
                data.data.prjAreaStandardNodeWorkFormula[item.value];
            });
        }
      });
    },
    getDataType() {
      // 字典项
      getApi(`/project/prj/dict/getlistbycode`, { code: "PRJ-GZBTXLX" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let arr = [];
            data.data.forEach((val) => {
              arr.push({
                label: val.name,
                value: val.code,
              });
            });
            this.tableColumns.forEach((item) => {
              if (item.key == "dictCode") {
                item.selectInput.options = arr;
                arr.forEach((item1) => {
                  this.getDictValue(item1.value);
                });
              }
            });
          }
        }
      );
      // 计量单位类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GZBDW" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let arr = [];
            data.data.forEach((val) => {
              arr.push({
                label: val.name,
                value: val.code,
              });
            });
            this.tableColumns.forEach((item) => {
              if (item.key == "workUnit") {
                item.selectInput.options = arr;
              }
            });
          }
        }
      );
      // 填写类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GZBTXLX" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let arr = [];
            data.data.forEach((val) => {
              arr.push({
                label: val.name,
                value: val.code,
              });
            });
            this.tableColumns.forEach((item) => {
              if (item.key == "workMetering") {
                item.selectInput.options = arr;
              }
            });
          }
        }
      );
    },
    getDictValue(val) {
      postApi(`/project/prj/dict/list/all`, { typeCode: val }).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let arr = [];
          data.data.forEach((val) => {
            arr.push({
              label: val.name,
              value: val.code,
            });
          });
          let obj = {
            inputType: "select",
            placeholderText: "请选择",
            options: arr,
            method: (val) => {
              this.txlxChange(val);
            },
            showEvent: (row) => {
              let show = false;
              if (row.dictCode == val) {
                show = true;
              }
              return show;
            },
          };
          this.tableColumns.forEach((item) => {
            if (item.key == "value") {
              item.inputTypeArray.push(obj);
            }
          });
        }
      });
    },
    //添加行事件
    handleAddRow() {
      let item = {
        workName: "",
        nodeUnit: "",
        workMetering: "",
        workSymbol: "",
        dictCode: "",
        value: "",
      };
      this.tableDataSources.push(item);
    },
    // 移除行数据
    handleRowDel(row, index) {
      this.tableDataSources.splice(index, 1);
    },
    handleFormulaVerify() {
      this.$refs.formulaForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          if (this.tableDataSources && this.tableDataSources.length > 0) {
            this.getFormulaResult();
          } else {
            this.$message.warning("请先添加填写详细计量内容");
          }
        } else {
          this.$message.warning("请输入计算公式");
        }
      });
    },
    getFormulaResult() {
      this.tableDataSources.forEach((item) => {
        window[item.workSymbol] = Number(item.value);
      });
      let formula = this.$refs.formulaForm.formData.formula;
      this.formulaResult = eval(formula);
      console.log(eval(formula));
    },

    //关闭费用申请单事件
    popupClose() {
      this.expenseApplyVisible = false;
    },
    //选择费用类型，确认事件
    handleApplySubmit() {},
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-input__icon {
  line-height: 30px;
}
.formula-box {
  display: flex;
  align-items: center;
  .input {
    width: 80%;
    margin-right: 20px;
  }
}
</style>
