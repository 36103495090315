<template>
  <div>
    <!-- <dd-card title="基本信息" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card> -->
    <dd-card title="需汇总的核算单">
      <dd-table
        max-height="360"
        ref="addPopForm"
        :columns="tableColumns"
        :data="tableDataSources"
        :loading="loading"
        border
        :isEdit="true"
        @select-change="handleSelectionChange"
      >
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { getNowDate } from "@/utils/date";
import { postApi, getApi } from "@/api/request";
export default {
  name: "list-show",
  components: {},
  props: {
    screening: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // depList: [],
      // projectList: [],
      loading: false,
      formList: [
        {
          label: "部门",
          value: "deptName",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择部门",
              trigger: "blur",
            },
          ],
        },
        {
          label: "所属年月",
          value: "reportMonth",
          placeholderText: "所属年月",
          inputType: "date",
          dateType: "month",
          format: "yyyy-MM",
          defaultValue: getNowDate(),
          rules: [
            { required: true, message: "请选择所属年月", trigger: "blur" },
          ],
        },
      ],
      tableColumns: [
        {
          title: "单据名称",
          key: "name",
          align: "center",
        },
        {
          title: "部门",
          key: "deptName",
          align: "center",
        },
        // {
        //   title: "绩效核算组",
        //   key: "nodeName",
        //   align: "center",
        // },
        {
          title: "所属年月",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "开始时间点",
          key: "startTime",
          align: "center",
        },
        {
          title: "截止时间点",
          key: "deadLine",
          align: "center",
        },
        {
          title: "统计工作量类型",
          key: "standardWorkpackageType",
          align: "center",
        },
        {
          title: "状态",
          key: "statusName",
          align: "center",
        },
      ],
      tableDataSources: [],
      // 表格选择数据集合
      multipleSelection: [],
      //默认选择状态
      filterSelection: [],
      checked: false,
      current: 1,
      size: 20,
      total: null,
      screeningObj:{}
    };
  },
  mounted() {
    this.screeningObj = this.screening;
    this.formList.forEach(item => {
      if(item.value == "fenDeptId"){
        item.defaultValue = this.screeningObj.fenDeptId;
      }
      if(item.value == "reportMonth"){
        item.defaultValue = this.screeningObj.reportMonth;
      }
    })
    this.getDataType();
    this.getTableData();
  },
  methods: {
    // 列表
    getTableData() {
      let { current, size } = this;
      let params = {
        ...this.screeningObj,
      };
      postApi(`/project/accountingsum/accounting/page/${current}/${size}`,params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data.records;
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 查询
    handleSearchEvent(obj) {
      this.screeningObj = obj;
      this.current = 1;
      this.getTableData();
    },
    // 获取下拉选择数据
    getDataType() {
      // 获取分管部门
      getApi(`/admin/sysfendept/getList`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "fenDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    handleSelectionChange(val) {
      this.$emit('select_list',val)
    },
  },
};
</script>

<style></style>
