<template>
  <div class="main-container">
    <dd-form
      :form-config="formList"
      :ref="formRef"      
      :flex="1"
      labelWidth="160px"
    > </dd-form>
  </div>
</template>
  
<script>
export default {
  name: "add-pop-detail-edit",
  props: {
    rowData: [],
    editData:[],
  },
  data() {
    return {
      formRef: "addPopForm",
      formList: [],
    };
  },
  created() {},
  mounted() {
    if(this.rowData.standardWorkpackage == 0){
      this.formList = [
        {
          label: "执行标准工天单价",
          value: "carryStandardWorkdayPrice",
          inputType: "text",
          placeholderText: "请输入执行标准工天单价",
          defaultValue: this.editData.carryStandardWorkdayPrice,
          rules: [
            {
              required: true,
              message: "请输入执行标准工天单价",
              trigger: "blur",
            },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            }
          ],
        },
        {
          label: "系数调整",
          value: "coefficientAdjustment",
          inputType: "text",
          placeholderText: "请输入系数调整",
          defaultValue: this.editData.coefficientAdjustment,
          rules: [
            {
              required: true,
              message: "请输入系数调整",
              trigger: "blur",
            },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            }
          ],
        },
        {
          label: "固定调整",
          value: "fixedAdjustment",
          inputType: "text",
          placeholderText: "请输入固定调整",
          defaultValue: `${this.editData.fixedAdjustment}`,
          rules: [
            {
              required: true,
              message: "请输入固定调整",
              trigger: "blur",
            },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            }
          ],
        },
        {
          label: "绩效计算备注说明",
          value: "performanceRemark",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入绩效计算备注说明",
          disabled: false,
          defaultValue: this.editData.performanceRemark,
          autosize: { minRows: 2 },
          rules: [
            {
              required: true,
              message: "请输入绩效计算备注说明",
              trigger: "blur",
            },
          ],
        },
      ];
    }else if(this.rowData.standardWorkpackage == 1){
      this.formList = [
        {
          label: "系数调整",
          value: "coefficientAdjustment",
          inputType: "text",
          placeholderText: "请输入系数调整",
          defaultValue: this.editData.coefficientAdjustment,
          rules: [
            {
              required: true,
              message: "请输入系数调整",
              trigger: "blur",
            },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            }
          ],
        },
        {
          label: "固定调整",
          value: "fixedAdjustment",
          inputType: "text",
          placeholderText: "请输入固定调整",
          defaultValue: `${this.editData.fixedAdjustment}`,
          rules: [
            {
              required: true,
              message: "请输入固定调整",
              trigger: "blur",
            },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            }
          ],
        },
        {
          label: "绩效计算备注说明",
          value: "performanceRemark",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入绩效计算备注说明",
          disabled: false,
          defaultValue: this.editData.performanceRemark,
          autosize: { minRows: 2 },
          rules: [
            {
              required: true,
              message: "请输入绩效计算备注说明",
              trigger: "blur",
            },
          ],
        },
      ];
    }
  },
  methods: {},
};
</script>
  <style lang='less' scoped>
</style>
  