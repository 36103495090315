<template>
    <div class="container">
        <dd-card title="">
            <dd-form
                :form-config="formList"
                :labelWidth="'190px'"
                :flex="3"
                ref="assignForm_11"
                formRef="assignForm_11"
            ></dd-form>
        </dd-card>
    </div>
</template>

<script>
    export default {
        props: ['rowData'],
        name: 'contractSettlementDetail',
        data() {
            return {
                formList: [
                    {
                        label: "结算名称",
                        value: "fname",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "结算编号",
                        value: "fnumber",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "项目组织",
                        value: "projectorgName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "工程项目",
                        value: "fprojectName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "期间",
                        value: "fperiodidName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同",
                        value: "purchaseInfoName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同甲方",
                        value: "fparta",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同乙方",
                        value: "fpartb",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "收款人",
                        value: "fsettleorgid",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "清单结算金额",
                        value: "fscheduleamount",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "状态",
                        value: "fbillsateName",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "其他款项进成本金额",
                        value: "fotherimputatcostamount",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "合同类型",
                        value: "cfcontracttype",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "结算总金额",
                        value: "foriginalamount",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "结算进成本总金额",
                        value: "ftotalimputatcostamount ",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "开始日期",
                        value: "fbegindate",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "结束日期",
                        value: "fenddate",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "税率(%)",
                        value: "frate",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "税额(元)",
                        value: "ftax",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "含税结算总额(元)",
                        value: "foftax",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "备注",
                        value: "fdescription",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "适应税种",
                        value: "ftaxrateinfoName",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "不含税结算总额(元)",
                        value: "fnotaxdataamount",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "已收发票金额",
                        value: "freceiptedamount",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "本次发票金额",
                        value: "fthisinvamount",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "累计发票金额",
                        value: "ftotalinvamount ",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "业务部门",
                        value: "fcostorgidName",
                        inputType: "text",
                        disabled: true,
                    },{
                        label: "单位工程",
                        value: "unitprojectname",
                        inputType: "text",
                        disabled: true,
                    },
                ]
            }
        },
        created() {

        },
        mounted() {
            this.$nextTick(() => {
                this.$refs.assignForm_11.formData =  { ...this.rowData }
            })
        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}

/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>