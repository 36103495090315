<template>
    <div class="main-container">
        <dd-form :form-config="formList" :labelWidth="'170px'" :flex="3" ref="workPackageForm"
            formRef="workPackageForm"></dd-form>

        <dd-card :height="tableHeight">
            <dd-table :columns="tableColumns" :data="tableData" border ref="workTable"
                @select-change="handleSelectionChange($event, 2)">
                <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handleCurrentChange" align="right">
                    </dd-pagination>
                </template>
            </dd-table>
        </dd-card>
    </div>
</template>

<script>
import { postApi, getApi, delApi } from "@/api/request";
export default {
    props: ['treeRowData', 'rowId'],
    name: 'workPackagePage',
    data() {
        // 业务类型 分管部门 部门 责任人   核算组 工作包
        return {
            tableHeight: null,
            tableColumns: [
                {
                    title: "业务类型",
                    key: "bussTypeName",
                    align: "center",
                },
                {
                  title: "工作包",
                  key: "nodeWorkName",
                  align: "center",
                },
                {
                    title: "分管部门",
                    key: "fenDeptName",
                    align: "center",
                },
                {
                    title: "部门",
                    key: "deptName",
                    align: "center",
                },
                {
                    title: "责任人",
                    key: "leader",
                    align: "center",
                },
                {
                    title: "核算组",
                    key: "accounting",
                    align: "center",
                },

            ],
            tableData: [],
            formList: [
                {
                    label: "主节点",
                    value: "nodeId",
                    inputType: "select",
                    optionsList: [],
                    filterable: true,
                    placeholderText: "请选择主节点",
                    optionsListConfig: {
                        name: "nodeName",
                        code: "id",
                    },
                    change: this.nodelChange,
                    rules: [
                        {
                            required: true,
                            message: "请选择主节点",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "工作包",
                    value: "nodeName",
                    inputType: "text",
                    change: this.nodeWorkIdChange,
                },
            ],
            selectionData: [],
            nodeList: [],
            nodeName: null,
            id: null,
            current: 1,
            size: 10,
            total: null,
        }
    },
    created() {
        this.getDataType();

    },
    mounted() {
        this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
        });
    },
    methods: {
        handleSelectionChange(e) {
            this.selectionData = e
        },
        getDataType() {
          getApi(`/project/subplannode/tree/${this.treeRowData.subplanId}`).then(res => {
                if (res.data.code == 0) {
                    this.formList.forEach(item => {
                        if (item.value == 'nodeId') {
                            item.optionsList = res.data.data[0].children;
                            this.nodeList = res.data.data[0].children;
                        }
                    })
                }
            })
            // 工作包列表
            getApi(`/project/prjareastandardnodework/list`).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    let options = {
                        value: "nodeWorkId",
                        optionsList: data.data,
                    };
                    this.setOPtionsList(this.formList, options);
                }
            });
        },
        nodeWorkIdChange(e, value) {
            if(!this.id) {
                this.$message.warning('请先选择主节点')
                this.$refs.workPackageForm.formData[value] = '';
                return false;
            }
            this.nodeName = this.$refs.workPackageForm.formData[value] || ''
            this.getTableData(this.id, this.nodeName);
        },
        nodelChange(e) {
            if(e) {
                this.id = e;
                this.getTableData(this.id, this.nodeName);
            }
            else {
                this.id = '';
                this.tableData = [];
                this.total = 0;
            }
            
        },
        getTableData(id, nodeName) {
            let params = {
                current: this.current,
                size: this.size,
                id,
            };
            if (nodeName) {
                params.nodeWorkName = nodeName
            }
            getApi(`/project/subplannode/standpackage/page`, params).then(res => {
                if (res.data.code == 0) {
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                }
            })
        },
        /* 分页页码回调 */
        handleCurrentChange(size, number) {
            this.current = number;
            this.size = size;
            this.getTableData(this.id, this.nodeworkId);
        },
        //条件查询表单--下拉选择数据填充（optionsList）
        setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.optionsList = options.optionsList;
                    }
                }
            });
        },

    }
}
</script>

<style lang="less" scoped></style>