<template>
  <div class="tab-container">
    <div class="left-tree container-left-tree">
      <el-tree
        ref="tree"
        :data="TreeList"
        accordion
        :props="defaultProps"
        default-expand-all
        highlight-current
        node-key="id"
        :current-node-key="treeNodeId"
        @node-click="handleNodeClick"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div>{{ node.label }}</div>
          <div class="node-hanlde">
            <i class="iconfont icon-add" @click.stop="handelNodeAdd(data)"></i>
            <i
              class="iconfont icon-edit"
              @click.stop="handelNodeEdit(data)"
            ></i>
            <i
              class="iconfont icon-delete"
              @click.stop="handelNodeDelete(data)"
            ></i>
          </div>
        </div>
      </el-tree>
    </div>
    <div class="right-table">
      <dd-card height="100%">
        <dd-table
          :columns="tableColumns"
          :data="tableData"
          border
          :isSelection="false"
          show-page
          :loading="loading"
        >
          <template #table-btn>
            <dd-button
              type="warning"
              size="medium"
              icon="refresh"
              @click="handleAdd"
              >同步合同清单</dd-button
            >
            <dd-button
              type="primary"
              size="medium"
              icon="view"
              @click="handleAdd"
              >查看历史版本</dd-button
            >
            <dd-button
              type="primary"
              size="medium"
              icon="export"
              @click="handleAdd"
              >导出工程清单</dd-button
            >
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";
export default {
  data() {
    return {
      tableHeight: null,
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeNodeId: "",
      loading: true,
      tableColumns: [
        {
          title: "清单编号",
          key: "prjCode",
          align: "center",
        },
        {
          title: "工程清单名称",
          key: "prjName",
          align: "center",
          overflow: true,
        },
        {
          title: "合同数量",
          key: "planStartTime",
          align: "center",
        },
        {
          title: "计量单位",
          key: "prjName1",
          align: "center",
        },
        {
          title: "完工数量",
          key: "chechState",
          align: "center",
        },
        {
          title: "备注",
          key: "rwldw",
          align: "center",
        },
        {
          title: "确认时间",
          key: "plan",
          align: "center",
        },
        {
          title: "状态",
          key: "plan",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 400,
          event: [
            {
              show: "true",
              label: "确认",
              icon: "el-icon-check",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
    };
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getLeftTree();
    this.getTableData();
  },
  methods: {
    // 获取左侧树形数据
    getLeftTree() {
      getApi(`/activiti/wflcategory/tree`, {}).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.TreeList = data.data;
          this.treeNodeId = data.data[0].id;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
          });
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      postApi(`/project/prj/list/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    handleNodeClick(data) {
      this.treeNodeId = data.id;
    },
    handelNodeAdd(data) {
      console.log(data);
    },
    handelNodeEdit(data) {
      console.log(data);
    },
    handelNodeDelete(data) {
      console.log(data);
    },
    handleAdd() {
      console.log("新增");
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.tab-container {
  height: 100%;
  display: flex;
  .left-tree {
    width: 20%;
    height: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    border-right: 5px solid #e7eaec;
    overflow-y: auto;
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: @fs14;

      .node-hanlde {
        color: @defaultFontColor;
        i {
          margin: 0 5px;
        }
      }
    }
  }
  .right-table {
    width: 80%;
    height: 100%;
    .table-tab {
      /deep/ .card-contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        .el-tabs {
          width: 100%;
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
    }
  }
}
</style>
