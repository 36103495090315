<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowData: [],
  },
  data() {
    return {
      formBasicList: [
        {
          label: "供应商",
          value: "purId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择供应商",
          optionsListConfig: {
            name: "purName",
            code: "purId",
          },
          rules: [
            {
              required: true,
              message: "请选择供应商",
              trigger: "blur",
            },
          ],
        },
        {
          label: "联系人名称",
          value: "purUserName",
          inputType: "text",
          placeholderText: "请输入联系人名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入联系人名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "电话",
          value: "phone",
          inputType: "text",
          placeholderText: "电话",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入电话",
              trigger: "blur",
            },
          ],
        },
        {
          label: "排序",
          value: "sortOrder",
          inputType: "number",
          min: 0,
          max: 999,
          placeholderText: "请输入排序",
          disabled: false,
          defaultValue: "",
        },
        {
          label: "邮编",
          value: "email",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入邮编",
          disabled: false,
          defaultValue: "",
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (this.rowData != null) {
      this.formBasicList.forEach((item) => {
        item.defaultValue = this.rowData[item.value];
      });
    }
    this.getDataType();
  },
  methods: {
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 获取业务类型列表数据
      getApi(`/project/purinfo/page?current=1&size=9999`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "purId",
            optionsList: data.data.records,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    }
  },
};
</script>
<style lang="less" scoped></style>
