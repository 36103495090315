<template>
  <div class="aside_contain">
    <!-- 系统图标 -->
    <div class="sys_img_wrap" v-if="!collapse">
      <img class="sys_img" src="../../assets/image/logo.png" alt />
    </div>
    <div class="sys_img_wrap" v-else>
      <img class="sys_img" src="../../assets/image/single_logo.png" alt style="width: 60px;margin-bottom:15px;" />
    </div>
    <!-- 菜单栏 -->
    <el-menu
      :default-active="defaultPath"
      router
      class="menu_vertical new-el-menu--sidebar"
      text-color="#ffffff"
      active-text-color="#ffffff"
      unique-opened
      :collapse="collapse"
    >
      <div v-for="(item,index) in menuList" :key="index">
        <!-- 判断是否存在二级菜单并显示 -->
        <el-submenu v-if="item.children && item.children.length" :index="item.name">
          <template slot="title">
            <i :class="['iconfont', `${item.icon}`]" :style="collapse ? '' : 'margin-left:20px'"></i>
            <span slot="title" v-show="!collapse">{{item.name}}</span>
          </template>
          <el-menu-item-group v-for="(item2,idx) in item.children" :key="idx" class="sub_menu_item">
            <!-- 判断是否存在三级菜单并显示 -->
            <el-submenu v-if="item2.children && item2.children.length" :index="item2.name">
              <template slot="title">
                <i :class="['iconfont', `${item2.icon}`]" :style="collapse ? 'margin-right:10px' : 'margin-left:20px'"></i>
                <span slot="title">{{item2.name}}</span>
              </template>
              <el-menu-item-group v-for="(item3,idx) in item2.children" :key="idx" class="sub_menu_item">
                <el-menu-item :index="item3.path">
                  <i
                    :class="['iconfont', `${item3.icon}`]"
                    :style="collapse ? 'margin-right:10px' : 'margin: 0 5px 0 20px'"
                  ></i>
                  <span slot="title">{{item3.name}}</span>
                </el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <!-- 不存在三级显示二级菜单 -->
            <el-menu-item v-else :index="item2.path">
              <i
                :class="['iconfont', `${item2.icon}`]"
                :style="collapse ? 'margin-right:10px' : 'margin: 0 5px 0 20px'"
              ></i>
              <span slot="title">{{item2.name}}</span>
            </el-menu-item>
          </el-menu-item-group>
        </el-submenu>
        <!-- 不存在二级默认显示一级菜单 -->
        <el-menu-item v-else :index="item.path">
          <i :class="['iconfont', `${item.icon}`]" :style="collapse ? '' : 'margin-left:20px'"></i>
          <span slot="title">{{item.name}}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'dd-aside',
  props: ["menuList"],
  components: {},
  computed: {},
  data() {
    return {
      defaultPath: "", //菜单选中的路径,
      collapse: false // 菜单是否折叠
    };
  },
  created() {},
  mounted() {
    // 初次渲染的选中路径或者刷新页面的缓存路径
    let { path, name } = this.$route;
    this.defaultPath = path ? path : this.menuList[0].path;
    sessionStorage.setItem("moduleName", name);
    this.$bus.$on("collapse", data => {
      this.collapse = data;
    });
  },
  watch: {
    $route(to, from) {
      this.defaultPath = to.path;
    }
  },
  methods: {}
};
</script>

<style lang='less' scoped>
.aside_contain {
  width: 100%;
  height: 100%;
  .sys_img_wrap {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px dashed rgba(223, 223, 223, 0.5);
    .sys_img {
      height: auto;
    }
  }
  /deep/ .el-menu{
    background-color: transparent;
  }
  .menu_vertical {
    border-right: 0 !important;
    /deep/ .el-submenu__title {
      font-weight: 800 !important;
    }
    /deep/ .el-menu-item {
      font-weight: 800;
    }
    /deep/ .el-menu-item.is-active {
      background-color: @bgHoverColor !important;
    }
    /deep/ .el-menu-item:focus, .el-menu-item:hover{
      background: @bgHoverColor !important;
    }
    /deep/ .el-submenu__title:hover{
      background-color: @bgHoverColor !important;
    }

    /deep/ .el-submenu__icon-arrow {
      color: @systemColor;
      font-weight: bold;
    }

    /deep/ .el-menu-item-group__title {
      padding: 0;
    }

    .iconfont {
      margin-right: 5px;
      color: @systemColor;
      font-size: @fs18;
      font-weight: normal;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }
}
</style>