<template>
    <div class="container">
      <el-tabs v-model="activeName" type="card" class="tabs_card111" @tab-click="handleClick">
        <el-tab-pane label="合同详情" name="合同详情">
          <contractDetail :detailData="detailData"></contractDetail>
        </el-tab-pane>
        <el-tab-pane label="合同变更信息" name="合同变更信息">
          <contractAmendment :detailData="detailData"></contractAmendment>
        </el-tab-pane>
        <el-tab-pane label="合同结算信息" name="合同结算信息">
          <contractSettlement :detailData="detailData"></contractSettlement>
        </el-tab-pane>
        <el-tab-pane label="合同付款信息" name="合同付款信息">
          <contractPayment :detailData="detailData"></contractPayment>
        </el-tab-pane>
        <el-tab-pane label="开票信息" name="开票信息">
          <contractInvoicing :detailData="detailData"></contractInvoicing>
        </el-tab-pane>
        <el-tab-pane label="关联自建合同情况" name="关联自建合同情况">
        <contractAssociation :detailData="detailData"></contractAssociation>
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  
  <script>
  import contractDetail from './contractDetail.vue'; // 合同信息
  import contractAmendment from './contractAmendment.vue'; // 合同变更信息
  import contractSettlement from './contractSettlement.vue'; // 合同结算信息
  import contractPayment from './contractPayment.vue' // 合同付款信息
  import contractInvoicing from './contractInvoicing.vue' // 开票信息
  import contractAssociation from './contractAssociation.vue' // 关联自建合同
import { getApi, postApi } from '@/api/request';
  export default {
    name: "pageDetailsd",
    props: ["pageProp"],
    components: {
      contractDetail,
      contractAmendment, 
      contractSettlement, 
      contractPayment, 
      contractInvoicing, 
      contractAssociation
    },
    data() {
      return {
        activeName: "合同详情",
        detailData: {},
      };
    },
    created() {
      console.log('this.pageProp.id', this.pageProp.id)
      this.getDetailData();
    },
    mounted() {},
    methods: {
      getDetailData() {
        postApi(`/contract/contractpurchaseinfo/getentitybyid?id=${this.pageProp.id}`,).then(res => {
          if(res.data.code == 0) {
            this.detailData = res.data.data;
            console.log('this.detailData', this.detailData)
          }
        })
      },
      handleClick() {},
    },
  };
  </script>
  
  <style lang="less" scoped>
 /deep/ .container  .tabs_card111  .el-tabs__item {
      width: 17% !important;
    }
  </style>