<template>
  <div class="container">
    <dd-card title="公示类型">
      <el-checkbox-group v-model="checkList">
        <el-checkbox
          v-for="(item, index) in commitList"
          :key="index"
          :label="item.code"
          >{{ item.name }}</el-checkbox
        >
      </el-checkbox-group>
    </dd-card>
    <dd-card title="公示与确认截止时间">
      <div class="date-box">
        <el-date-picker
          v-model="deadLine"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="公示与确认截止时间"
          :picker-options="{
            disabledDate(v){
              return v.getTime() < new Date().getTime() - 86400000;//  - 86400000是否包括当天
            },
            selectableRange: startTimeRange
          }"
        >
        </el-date-picker>
      </div>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import { mapState, mapActions } from "vuex";
export default {
  props: {
    id: "",
  },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      radio: "",
      checkList: [],
      publicityList: [],
      commitList: [],
      deadLine: "",
    };
  },
  mounted() {
    this.getStateList();
  },
  watch: {
    deadLine: {
      handler(val) {
        if (val) {
          let dateTime = '';
          const yy = new Date().getFullYear()
          const mm = new Date().getMonth() + 1
          const dd = new Date().getDate()
          const hh = new Date().getHours()
          const mz = mm < 10 ? '0' + mm : mm
          const mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes()
            : new Date().getMinutes()
          const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds()
            : new Date().getSeconds()
          dateTime = yy + '-' + mz + '-' + dd + ' ' + hh + ':' + mf + ':' + ss
          const dt = dateTime.split(' ')
          let st = ''
          if (val.split(' ')[0] === dt[0]) {
            st = dt[1]
          } else {
            st = '00:00:00'
          }
          this.startTimeRange = st + ' - 23:59:59'
          // 例如：如果今天此刻时间为10:41:40 则选择时间范围为： 10:41:40 - 23:59:59
          // 否则为：00:00:00- 23:59:59
        }
      }
    }
  },
  methods: {
    getStateList() {
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "PRJ_ASSIGN_WORKLOAD_SUM_CONFIRM",
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let publicityList = [];
          let commitList = [];
          data.data.map((item) => {
            if (
              item.code == "PRJ_ASSIGN_WORKLOAD_SUM_CONFIRM_BGS" ||
              item.code == "PRJ_ASSIGN_WORKLOAD_SUM_CONFIRM_HSZ"
            ) {
              publicityList.push(item);
            } else {
              commitList.push(item);
            }
          });
          this.publicityList = publicityList;
          this.commitList = commitList;
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
.inline {
  display: unset !important;
}
.date-box {
  display: flex;
  align-items: center;
  .date-label {
  }
}
/deep/ .el-radio {
  display: block !important;
  margin-top: 14px;
}
/deep/ .el-checkbox {
  display: flex !important;
  align-items: center;
  margin-top: 14px;
}
</style>