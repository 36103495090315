<template>
  <div class="detail-container">
    <dd-card title="审批流程" spacer>
      <el-steps
        :active="activeIndex"
        align-center
        :finish-status="
          detailData && detailData.taskFlag == '结束' ? 'success' : 'finish'
        "
      >
        <el-step
          v-for="(val, idx) in activityList"
          :key="idx"
          :title="val.activityName"
        >
          <div slot="description" class="description">
            <div
              v-for="(item, index) in val.description"
              :key="index"
              class="list"
            >
              <div class="flex_c">
                {{ item.time }}
                <div class="name">{{ item.userName }} 已审核</div>
              </div>
              <div>意见 {{ item.fullmessage }}</div>
            </div>
          </div>
        </el-step>
      </el-steps>
      <dd-form
        :form-config="formTaskBasicList"
        ref="taskBasicForm"
        formRef="taskBasicForm"
      >
      </dd-form>
    </dd-card>

    <el-tabs v-model="defaultTabs" type="card" sticky="true">
      <!-- style="height: 500px;overflow: hidden;overflow-y:auto" -->
      <el-tab-pane label="基本信息" name="jbxx">
        <dd-form
          :form-config="formBasicList"
          :flex="3"
          labelWidth="220px"
          ref="addPopForm"
        >
        </dd-form>
        <dd-form :form-config="formBasicList1" labelWidth="165px" ref="addPopForm1">
        </dd-form>        
      </el-tab-pane>
      <el-tab-pane label="客户相关信息" name="khxgxx" style="height: 500px;overflow: hidden;overflow-y:auto">
        <dd-form
          :form-config="formBasicListKh"
          :flex="3"
          labelWidth="220px"
          ref="addPopFormKh"
        >
        </dd-form>
      </el-tab-pane>
      <el-tab-pane label="项目关联信息" name="xmglxx" style="height: 500px;overflow: hidden;overflow-y:auto">
        <dd-form
          :form-config="formBasicListXm"
          :flex="3"
          labelWidth="240px"
          ref="addPopFormXm"
        >
        </dd-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import { getNowDate, getNowYear } from "@/utils/date";
export default {
  name: "detail-pop",
  props: {
    rowData: [],
  },
  data() {
    return {
      defaultTabs: "jbxx",
      formTaskBasicList: [
        {
          label: "审批意见",
          value: "comment",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          placeholderText: "请输入审批意见",
          rules: [
            {
              required: true,
              message: "请输入审批意见",
              trigger: "blur",
            },
          ],
        },
      ],
      formBasicList: [
        {
          label: "单据编号",
          value: "assignCode",
          inputType: "text",
          placeholderText: "保存后自动生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "任务书编号",
          value: "assignNum",
          inputType: "text",
          placeholderText: "审批通过后自动生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "任务书年份",
          value: "assignYear",
          inputType: "text",
          defaultValue: getNowYear(),
          disabled: true,
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: false,
              message: "请输入任务书名称",
              trigger: "blur",
            },
          ],
        },
        
        {
          label: "预计合同金额(元)",
          value: "estimatedAmount",
          inputType: "text",
          placeholderText: "请输入预计合同金额",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: false,
              message: "请输入预计合同金额",
              trigger: "blur",
            },
          ],
        },
        {
          label: "所在区域",
          value: "regionAreaId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择所在区域",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: false,
              message: "请选择所在区域",
              trigger: "blur",
            },
          ],
        },
        {
          label: "项目生产需求级别",
          value: "assignLevel",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择项目生产需求级别",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: false,
              message: "请选择项目生产需求级别",
              trigger: "blur",
            },
          ],
        },
        {
          label: "任务工期",
          value: "taskDuration",
          placeholderText: "任务工期",
          inputType: "date",
          dateType: "daterange",
          defaultValue: getNowDate(),
          format: "yyyy-MM-dd",
          rules: [
            {
              type: "array",
              required: false,
              message: "请选择任务工期",
              trigger: "blur",
            },
          ],
        },
        // --------------添加开始------------
        {
          label: "任务下发分管部门",
          value: "downFenDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择任务下发分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: false,
              message: "请选择任务下发分管部门",
              trigger: "blur",
            },
          ],
          change: this.getDownFenDeptId,
        },
        {
          label: "任务下发部门",
          value: "downDeptId",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          filterable: true,
          placeholderText: "请选择任务下发部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: false,
              message: "请选择任务下发部门",
              trigger: "blur",
            },
          ],
          // change: this.getDownDeptId,
        },
        {
          label: "客户经理",
          value: "cmmmanagerId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          disabled: false,
          defaultValue: "",
          placeholderText: "请选择客户经理",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          rules: [
            {
              required: false,
              message: "请选择客户经理",
              trigger: "blur",
            },
          ],
        },
        {
          label: "任务生产分管部门",
          value: "receiveFenDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择任务生产分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: false,
              message: "请选择任务生产分管部门",
              trigger: "blur",
            },
          ],
          change: this.getReceiveFenDeptId,
        },
        {
          label: "任务生产部门",
          value: "receiveDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          disabled:false,
          defaultValue:'',
          placeholderText: "请选择任务生产部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: false,
              message: "请选择任务生产部门",
              trigger: "blur",
            },
          ],
          change:this.modeReceiveDeptIdS
        },
        {
          label: "项目经理",
          value: "leaderId",
          inputType: "select",
          optionsList: [],
          defaultValue:'',
          disabled:false,
          filterable: true,
          placeholderText: "请选择项目经理",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          rules: [
            {
              required: false,
              message: "请选择项目经理",
              trigger: "blur",
            },
          ],
        },
        {
          label: "业务类型",
          value: "businessTypeId",
          inputType: "cascader",
          optionsList: [],
          defaultValue: '',
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
          },
          showAllSelcet: false,
          placeholderText: "请选择业务类型",
          rules: [
            {
              required: false,
              message: "请选择业务类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "生产控制模式",
          value: "produceType",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择生产控制模式",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: false,
              message: "请选择生产控制模式",
              trigger: "blur",
            },
          ],
        },
      ],
      formBasicList1: [
        {
          label: "任务概况",
          value: "assignOverview",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入任务概况",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          rules: [
            {
              required: false,
              message: "请输入任务概况",
              trigger: "blur",
            },
          ],
        },
        {
          label: "工作内容及工作量",
          value: "contentWorkload",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入工作内容及工作量",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          rules: [
            {
              required: false,
              message: "请输入工作内容及工作量",
              trigger: "blur",
            },
          ],
        },
        {
          label: "执行标准及质量要求",
          value: "qualityRequire",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入执行标准及质量要求",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          rules: [
            {
              required: false,
              message: "请输入执行标准及质量要求",
              trigger: "blur",
            },
          ],
        },
        {
          label: "应提交的资料",
          value: "resultList",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入应提交的资料",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
          rules: [
            {
              required: false,
              message: "请输入应提交的资料",
              trigger: "blur",
            },
          ],
        },
        {
          label: "特殊情况及注意事项",
          value: "specialPrecautions",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入特殊情况及注意事项",
          disabled: false,
          defaultValue: "",
          autosize: { minRows: 4 },
        },
      ],
      // 客户相关信息
      formBasicListKh:[
        {
          label: "客户名称",
          value: "cmmId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户名称",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: false,
              message: "请选择客户名称",
              trigger: "blur",
            },
          ],
          // remote: true,
          remoteMethod: (query) => {
            this.getCrmList(query);
          },
          change: this.getCrmPerson,
        },
        {
          label: "客户联系人",
          value: "crmContactId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择客户联系人",
          optionsListConfig: {
            code: "id",
            name: "name",
          },
          rules: [
            {
              required: false,
              message: "请选择客户联系人",
              trigger: "blur",
            },
          ],
          change:this.selectContactPhone
        },
        {
          label: "个人联系方式",
          disabled: true,
          value: "contactPhone",
          defaultValue:'',
          inputType: "text",
        },
        {
          label: "工作联系方式",
          value: "crmContactDept",
          inputType: "text",
          placeholderText: "请输入工作联系方式",
          disabled: true,
          defaultValue: "",
        },
      ],
      contactPhoneList:[],
      // 项目关联信息
      formBasicListXm:[
        {
          label: "任务书类型",
          value: "assignType",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择任务书类型",
          optionsListConfig: {
            code: "code",
            name: "name",
          },
        },
        {
          label: "项目名称及编号",
          value: "prjId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          // disabled: true,
          placeholderText: "请选择",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
          // defaultValue: this.prjId,
          defaultValue: '',
          change: this.getContractList,
        },
        {
          label: "合同名称及编号",
          value: "contractId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
        {
          label: "老任务书名称",
          value: "relateAssignId",
          inputType: "select",
          show: false,
          optionsList: [],
          filterable: true,
          remote: true,
          remoteMethod: (query) => {
            this.getAssignRelateList(query);
          },
          placeholderText: "请选择",
          change: this.assignRelateList,
          optionsListConfig: {
            name: "assignName",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择合同名称及编号",
              trigger: "blur",
            },
          ],
        },
        {
          label: "老任务书编码",
          value: "relateAssignCode",
          inputType: "text",
          disabled: true,
          show: false,
        },
        {
          label: "老任务书已重新生产次数",
          value: "serialNumberO",
          disabled: true,
          show: false,
          inputType: "text",
        },
      ],
      activeIndex: 0,
      activityList: [],
      detailData: null,
      // 客户信息
      basicCrmContactList: [],
      currentActiviti:'',
    };
  },
  created() {},
  mounted() {
    if (this.rowData != null) {
      this.formBasicList.forEach((item) => {
        if (
          item.value == "leaderId" &&
          this.rowData.taskDefinKey == "scbmfzr"
        ) {
          item.rules = [
            {
              required: true,
              message: "请选择生产负责人",
              trigger: "blur",
            },
          ];
          item.disabled = false;
        }
        item.defaultValue = this.rowData[item.value];
      });
      this.getDataDetail(this.rowData.proInstanceId);
    }
    this.getDataType();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 获取项目列表数据
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "prjId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "receiveDeptId",
            optionsList: data.data,
          };
          let options1 = {
            value: "downDeptId",
            optionsList: data.data,
          };

          this.setOPtionsList(this.formBasicList, options);
          this.setOPtionsList(this.formBasicList, options1);
        }
      });
      // 区域列表
      getApi(`/admin/syscompanyregion/list`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "regionAreaId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      // 生产控制模式
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-SCKZMS" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "produceType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      // 项目生产需求级别
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJB" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "assignLevel",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      this.getCrmList();
    },
    // 获取客户列表
    getCrmList(name, type) {
      let params = {
        name: name,
      };
      postApi(`/project/customerinfo/list`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicListKh.forEach((item) => {
            if (item.value == "cmmId") {
              item.optionsList = data.data;
              // if (type != "init") {
              //   item.defaultValue = null;
              // }
            }
            else {
              // item.defaultValue = this.$refs.addPopFormKh.formData[item.value];
            }
          });
        }
      });
    },
    //生产负责人列表
    getPersonList(deptId) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId: deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "receiveDeptId") {
              item.defaultValue = deptId;
            } else {
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
            if (item.value == "leaderId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 获取客户经理列表数据
    getClientJlList(deptId) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId: deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "downDeptId") {
              item.defaultValue = deptId;
            } else {
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
            if (item.value == "cmmmanagerId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 查询项目经理  任务生产部门添加值  
    modeReceiveDeptId(id){
      if(id != ''){
        postApi(`/admin/user/getuserlist/bydeptid`, { deptId: id }).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formBasicList.forEach((item) => {
              if(item.value == 'receiveDeptId'){
                item.defaultValue = id;
              }
              // else if(item.value == "leaderId"){
              //   item.defaultValue = ''
              // }
              else{
                item.defaultValue = this.$refs.addPopForm.formData[item.value]
              }
              if (item.value == "leaderId") {
                item.optionsList = data.data;
              }
            })
          }
        });
      }
    },
    modeReceiveDeptIdS(id){
      if(id != ''){
        postApi(`/admin/user/getuserlist/bydeptid`, { deptId: id }).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formBasicList.forEach((item) => {
              if(item.value == 'receiveDeptId'){
                item.defaultValue = id;
              }
              else if(item.value == "leaderId"){
                item.defaultValue = ''
              }
              else{
                item.defaultValue = this.$refs.addPopForm.formData[item.value]
              }
              if (item.value == "leaderId") {
                item.optionsList = data.data;
              }
            })
          }
        });
      }
    },
    getAssignRelateList(name) {
      // serialNumberO relateAssignCode
      postApi(`/project/assign/pc/relate`, {assignName: name}).then(res => {
        if(res.data.code == 0) {
          this.formBasicListXm.forEach(item => {
            if(item.value == 'relateAssignId') {
              item.optionsList = res.data.data;
              this.relateList = res.data.data;
            }
          })
        }
      })
     },
    getDataDetail(id) {
      getApi(`/activiti/process/viewPage/${this.rowData.proInstanceId}`).then(
        (res) => {
          if (res.data.code == 0) {
            this.activityList = res.data.data;
          }
        }
      ).finally(() => {
        getApi(`/project/assign/proInstanceId/${id}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.detailData = data.data;
          console.log('this.detailData', this.detailData)
          this.getClientJlList(data.data.downDeptId);
          this.getPersonList(data.data.receiveDeptId);
          this.getCrmList(data.data.cmmName);
          
          let currentActiviti = '';
          this.activityList.forEach(it => {
            if(it.isCurrentActiviti == 1){
              currentActiviti = it.activityId
            }
          })
          this.currentActiviti = currentActiviti;
          this.formBasicList.forEach((item) => {
            if (item.value == "taskDuration") {
              item.defaultValue = [data.data.startTime, data.data.endTime];
            } else {
              item.defaultValue = data.data[item.value];
            }
            // 接收部门分管负责人 接收部门负责人
            if(currentActiviti == 'jsbmfgfzr' || currentActiviti == 'jsbmfzr' || currentActiviti == "bmfzr"){
              if(currentActiviti == 'jsbmfgfzr' && (item.value == "leaderId" || item.value == "receiveDeptId" || item.value == "businessTypeId" || item.value == "produceType" )){
                  item.disabled = false;
                if(item.value == "receiveDeptId"){
                  item.rules[0].required = true;
                }
              }else if(currentActiviti == 'jsbmfzr' && (item.value == "leaderId" ||  item.value == "produceType" || item.value == 'businessTypeId')){
                if(item.value == "leaderId" || item.value == "businessTypeId" || item.value == "produceType" ) {
                  item.rules[0].required = true;
                }
                item.disabled = false
                
              }else if(currentActiviti == "bmfzr" && (item.value == "produceType" || item.value == 'businessTypeId')) {
                item.disabled = false
              }
              else{
                item.disabled = true;
              }
              // if(currentActiviti == 'jsbmfgfzr' && (item.value == "businessTypeId" || item.value == "produceType")) {
              //   item.rules[0].required = true;
              // }
            }
            else{
              item.disabled = true;
            }

            console.log('currentActiviti', currentActiviti)
          });


          this.formBasicList1.forEach((item) => {
            item.defaultValue = data.data[item.value];
            item.disabled = true;
          });

          
          this.formBasicListXm.forEach((item) => {
            item.defaultValue = data.data[item.value];
            item.disabled = true;
            this.$refs.addPopFormXm.formData[item.value] = data.data[item.value];
          });
          if(data.data.assignType == 'PRJ-ASSIGN-TYPE-CXSC') {
            this.formBasicListXm.forEach((item) => {
              if(item.value == 'serialNumberO' || item.value == 'relateAssignCode' || item.value == 'relateAssignId') {
                item.show = true;
                this.getAssignRelateList(data.data.relateAssign)
              }
            })
          }

          // this.getDownDeptId(data.data.receiveDeptId)
          // this.getDataType();
          this.getCrmPerson(data.data.cmmId, "init");
          this.getContractList(data.data.prjId, "init");
          this.formBasicListKh.forEach((item) => {
            item.defaultValue = data.data[item.value];
            this.$refs.addPopFormKh.formData[item.value] = data.data[item.value];
            item.disabled = true;
          });
          
          this.getDownFenDeptId(data.data.downFenDeptId);
          this.getReceiveFenDeptId(data.data.receiveFenDeptId)
          this.modeReceiveDeptId(data.data.receiveDeptId)
          if (this.detailData.taskNodes) {
            this.activityList.forEach((item, index) => {
              item.description = [];
              if (item.isCurrentActiviti == 1) {
                this.activeIndex = index + 1;
              }
              this.detailData.taskNodes.forEach((val) => {
                if (item.activityName == val.taskName) {
                  item.description.push(val);
                }
              });
            });
          }
          this.basicCrmContactList = data.data.basicCrmContactList;
          if (this.detailData.taskFlag == "结束") {
            this.activeIndex = this.activityList.length;
          }
        }
      });
      })

    },
    // 根据 任务下发分管部门 获取 任务下发分管部门 联动
    getDownFenDeptId(fenDeptId){
      if(fenDeptId != ''){
        getApi(`/admin/dept/getbydepid/${fenDeptId}`, {}).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formBasicList.forEach((item) => {
                if(item.value == 'downDeptId'){
                  item.optionsList = data.data;
                }
            })
          }
        })
      }
    },
    // 根据 任务生产部门 获取 客户经理 联动
    getDownDeptId(deptId){
      postApi(`/admin/user/getuserlist/bydeptid`, { deptId: deptId }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "downDeptId") {
              item.defaultValue = deptId;
            }

            if (item.value == "cmmmanagerId") {
              item.optionsList = data.data;
            }

            if (item.value == "leaderId") {
              item.optionsList = data.data;
            }
          })
        }
      });
    },
    // 根据 任务生产分管部门 获取 任务生产部门 联动
    getReceiveFenDeptId(fenDeptId){
      if(fenDeptId != ''){
        getApi(`/admin/dept/getbydepid/${fenDeptId}`, {}).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formBasicList.forEach((item) => {
              if(item.value == 'receiveDeptId'){
                item.optionsList = data.data;
              }
            })
          }
        })
      }
    },
    // 获取数据字典类型 - 项目名称及编号
    getDataType() {
      // 获取项目列表数据
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "prjId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicListXm, options);
        }
      });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "receiveDeptId",
            optionsList: data.data,
          };
          let options1 = {
            value: "downDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
          this.setOPtionsList(this.formBasicList, options1);
        }
      });
      // 区域列表
      getApi(`/admin/syscompanyregion/list`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "regionAreaId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      // 生产控制模式
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-SCKZMS" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "produceType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      // 项目生产需求级别
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJB" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "assignLevel",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      // 项目关联信息 - 任务书类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ_ASSIGN_TYPE" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "assignType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicListXm, options);
          }
        }
      );
      this.getCrmList("", "init");
      this.getInit();
    },
    // 默认加载
    getInit(){
      this.getFenDeptList();
      this.getBusinessType();
    },
    // 获取分管部门（任务下发分管部门、任务生产分管部门）
    getFenDeptList(){
      getApi(`/admin/sysfendept/getList`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "downFenDeptId",
            optionsList: data.data
          };
          this.setOPtionsList(this.formBasicList, options);
          let options1 = {
            value: "receiveFenDeptId",
            optionsList: data.data
          };
          this.setOPtionsList(this.formBasicList, options1);
        }
      });
    },
    // 业务类型
    getBusinessType(){
      getApi(`/project/prjareasubassginbussitype/tree`, {}).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "businessTypeId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    // 获取客户联系人
    getCrmPerson(id, type) {
      this.formBasicListKh.forEach((item) => {
        if (item.value == 'crmContactId') {
          item.defaultValue = '';
        } else if (item.value == 'contactPhone') {
          item.defaultValue = '';
        } else {
          item.defaultValue = this.$refs.addPopFormKh.formData[item.value];
        }
      })
      if (id != '') {
        let params = {
          crmId: id,
        };
        this.crmId = id,
          postApi(`/project/customercontactdetail/list`, params).then((res) => {
            let { data } = res;
            if (data && data.code == 0) {
              this.contactPhoneList = data.data;
              this.formBasicListKh.forEach((item) => {
                if (item.value == "crmContactId") {
                  item.optionsList = data.data;
                  // if (type != "init") {
                  //   item.defaultValue = null;
                  // }
                }
                else {
                  item.defaultValue = this.$refs.addPopFormKh.formData[item.value];
                }
              });
            }
          });
      }
    },
    // 获取合同列表数据
    getContractList(id, type) {
      let params = {
        prjId: id,
      };
      getApi(`/project/contractinfo/getcontractinfolistbyprjid`, params).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.formBasicListXm.forEach((item) => {
              if (item.value == "prjId") {
                item.defaultValue = id;
              } else {
                item.defaultValue = this.$refs.addPopFormXm.formData[item.value];
              }
              if (item.value == "contractId") {
                item.optionsList = data.data;
                if (type != "init") {
                  item.defaultValue = null;
                }
              }
            });
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-range-editor.is-disabled input {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
/deep/ .el-step__description {
  display: flex;
  justify-content: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.description {
  .list {
    .name {
      margin-left: 10px;
    }
  }
}
/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}
/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
</style>
