<template>
    <div class="container">
        <dd-card title="结算详情">
            <dd-table :columns="tableColumns" :data="tableData" border :isSelection="false" show-page :loading="loading">
            </dd-table>
        </dd-card>
    </div>
</template>

<script>
    export default {
        name: 'detail',
        data() {
            return {
                tableColumns: [
                    {
                        title: "金蝶结算单编号",
                        key: "code",
                        align: "center",
                        overflow: true,
                        width: 180,
                    },
                    {
                        title: "金蝶结算单名称",
                        key: "code1",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "期间",
                        key: "code777777777",
                        align: "center",
                        overflow: true,
                        
                    },

                    {
                        title: "金蝶合同编号",
                        key: "code777777777",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "金蝶合同名称",
                        key: "code777777777",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "金蝶变更后合同总金额",
                        key: "code777777777",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "金蝶本次含税结算总金额",
                        key: "code777777777",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "合同关联金额",
                        key: "code777777777",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "已关联其他自建合同的结算金额",
                        key: "code777777777",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "数据状态",
                        key: "code777777777",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "归属部门",
                        key: "code777777777",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "修改后关联结算金额",
                        key: "code777777777",
                        align: "center",
                        overflow: true,
                        
                    },
                ],
                tableData: [{}, {}, {}],
                loading: false,
            }
        },
        created() {

        },
        mounted() {

        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>

</style>