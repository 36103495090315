/*
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-08-21 17:07:00
 * @LastEditors: hechen
 * @LastEditTime: 2023-09-06 16:05:34
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\store\index.js
 * @Descripttion: 
 */
import Vue from 'vue'
import Vuex from 'vuex'
import tags from './modules/tags'
import getters from './getters'
import actions from '@/store/actions'
import mutations from '@/store/mutations'
import state from '@/store/state'
Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    tags
  },
  state,
  getters,
  mutations,
  actions
})

export default store