<template>
    <div class="container">
        <dd-card title="">
            <dd-form
                :form-config="formList"
                :labelWidth="'170px'"
                :flex="3"
                ref="assignForm_1111"
                formRef="assignForm_1111"
            ></dd-form>
        </dd-card>
        <dd-card title="">
            <dd-table :columns="tableColumns" :data="tableData" border :isSelection="true" show-page :loading="loading">
            </dd-table>
        </dd-card>
    </div>
</template>

<script>
    export default {
        name: 'contractInvoicing',
        props: ['detailData'],
        data() {
            return {
                loading: false,
                formList: [
                    {
                        label: "合同总金额",
                        value: "foriginalamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同变更后总金额",
                        value: "ftotalamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同结算金额",
                        value: "settleFoftax",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同已付款金额",
                        value: "paymentForiginalamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同已开票金额",
                        value: "invoiceAllotmentAmount",
                        inputType: "text",
                        disabled: true,
                    },
                ],
                tableColumns: [
                    {
                        title: "合同发票编码",
                        key: "invoiceNum",
                        align: "center",
                        overflow: true,
                        width: 110,
                    },
                    {
                        title: "已分配到合同的发票金额",
                        key: "allotmentAmount",
                        align: "center",
                        overflow: true,
                        width: 180,
                    },
                    {
                        title: "发票登记单据编码",
                        key: "code",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "发票代码",
                        key: "invoiceCode",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "发票号码",
                        key: "invoiceNum",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "开票日期",
                        key: "invoiceDate",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "金额",
                        key: "totalAmount",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "税额",
                        key: "totalTax",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "价税合计金额",
                        key: "amountInFiguers",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "发票类型",
                        key: "serviceType",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "有效发票金额",
                        key: "totalAmount",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "开票供应商名称",
                        key: "sellerName",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "制单人",
                        key: "noteDrawer",
                        align: "center",
                        overflow: true,
                        
                    },
                    {
                        title: "制单日期",
                        key: "invoiceDate",
                        align: "center",
                        overflow: true,
                        
                    },
                ],
                tableData: []
            }
        },
        watch: {
            detailData() {
                this.initPageData()
            },
        },
        methods:{
            initPageData() {
                this.$refs.assignForm_1111.formData = {
                    foriginalamount: this.detailData.foriginalamount,
                    ftotalamount: this.detailData.ftotalamount,
                    paymentForiginalamount: this.detailData.paymentForiginalamount,
                    invoiceAllotmentAmount: this.detailData.invoiceAllotmentAmount,
                }
                this.tableData = this.detailData.contractInvoiceRegistrationVOList;
            },
        }
    }
</script>

<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}

/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>