<!--
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-03-23 20:04:21
 * @LastEditors: hechen
 * @LastEditTime: 2023-09-06 10:58:40
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\components\BasePopup\index.vue
 * @Descripttion: 
-->
<template>
  <div class="base-popup">
    <el-dialog
      :title="titleName"
      ref="basePopup"
      :show-close="showClose"
      :visible="popupShow"
      :append-to-body="inPopup"
      :showBtn="showBtn"
      :width="width"
      :style="`--height: ${height}`"
      custom-class="popup-box"
      :modal-append-to-body="inBody"
      :close-on-click-modal="false"
      @close="popupClose"
    >
      <div
        class="slot-main"
        :style="!showBtn ? `height: 100%` : `height:calc(100% - 55px)`"
      >
        <slot name="main"></slot>
      </div>
      <div class="popup-btn" v-if="showBtn">
        <dd-button v-if="cancelBtn" size="medium" @click="popupClose"
          >取消</dd-button
        >
        <dd-button
          v-if="saveBtn"
          type="primary"
          size="medium"
          autoLoading
          @click="handleSave"
          >保存</dd-button
        >
        <dd-button
          v-if="submitBtn"
          type="primary"
          size="medium"
          autoLoading
          @click="handleSubmit"
          >提交</dd-button
        >
        <dd-button
          v-if="restBtn"
          type="warning"
          size="medium"
          autoLoading
          @click="handleRest"
          >重置</dd-button
        >
        <slot name="button"></slot>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "dd-popup",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    showClose: {
      type: Boolean,
      default: true,
    },
    titleName: {
      type: String,
      default: "弹框标题",
    },
    showBtn: {
      type: Boolean,
      default: false,
    },
    inPopup: {
      type: Boolean,
      default: false,
    },
    inBody: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "50%",
    },
    height: {
      type: String,
      default: "50%",
    },
    saveBtn: {
      type: Boolean,
      default: true,
    },
    submitBtn: {
      type: Boolean,
      default: false,
    },
    cancelBtn: {
      type: Boolean,
      default: true,
    },
    restBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popupShow: this.show,
    };
  },
  watch: {
    show(n, o) {
      this.popupShow = n;
    },
  },
  mounted() {},
  methods: {
    // 弹框关闭事件
    popupClose() {
      this.$emit("popup-close", false);
    },
    // 保存按钮事件
    handleSave() {
      this.$emit("popup-save");
    },
    // 提交按钮事件
    handleSubmit() {
      this.$emit("popup-submit");
    },
    // 重置按钮事件
    handleRest() {
      this.$emit("popup-rest");
    },
  },
};
</script>

<style lang="less" scoped>
.slot-main {
  height: calc(100% - 55px);
  overflow-x: hidden;
  overflow-y: auto;
}
.popup-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 15px;
}
/deep/.el-dialog {
  position: absolute;
  margin: 0 !important;
  border-radius: 10px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  background: transparent;
}
/deep/.popup-box {
  height: var(--height) !important;
}
/deep/.el-dialog__header {
  background-color: @themeColor;
  color: @systemColor;
  font-size: @fs14;
  padding: 10px;
  height: 45px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .el-dialog__title {
    color: @systemColor;
  }
  .el-dialog__headerbtn {
    position: inherit;
    .el-dialog__close {
      color: @systemColor;
      font-size: @fs20;
    }
  }
}
/deep/.el-dialog__body {
  height: calc(100% - 45px);
  box-sizing: border-box;
  padding: 15px 20px;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.base-popup {
  position: absolute;

  /deep/ .el-tabs__nav {
    width: 100%;
  }
  /deep/ .el-tabs__item {
    width: 25%;
    text-align: center;
  }
}
</style>
