import { render, staticRenderFns } from "./AddPop.vue?vue&type=template&id=a5b7a9d4&scoped=true"
import script from "./AddPop.vue?vue&type=script&lang=js"
export * from "./AddPop.vue?vue&type=script&lang=js"
import style0 from "./AddPop.vue?vue&type=style&index=0&id=a5b7a9d4&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5b7a9d4",
  null
  
)

export default component.exports