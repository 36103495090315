<template>
    <div class="main-container">
      <dd-card title="条件查询" spacer>
        <dd-search-form
          :form-config="formList"
          :labelWidth="'190px'"
          @search-click="handleSearchEvent"
        ></dd-search-form>
      </dd-card>
      <dd-card title="可调整预算列表" :height="tableHeight">
        <dd-table
          :columns="columnsList"
          :data="tableData"
          border
          ref="dTableRefs"
          :loading="loading"
          show-page
          :isSelectable="true"
          @select-change="handleSelectionChange"
          :handleCheckDisable="handleCheckDisable"
        >
          <template #table-btn-right>
            <el-button
              icon="el-icon-s-operation"
              circle
              type="info"
              size="small"
              @click="drawerVisible = true"
              title="列筛选"
            ></el-button>
          </template>
          <template #table-btn>
            <dd-button type="primary" size="medium" icon="update" @click="edit()"
              >调整编辑</dd-button
            >
            <dd-button
              type="primary"
              size="medium"
              @click="confirm()"
              >确认</dd-button
            >
            <dd-button
              type="primary"
              size="medium"
              @click="counterConfirmation()"
              >反确认</dd-button
            >
            <dd-button
              type="primary"
              size="medium"
              @click="kingDee()"
              >按金蝶预算视图查看</dd-button
            >
            <dd-button
              type="primary"
              size="medium"
              icon="export"
              @click="exportDetail()"
              >导出</dd-button
            >
            <dd-button type="primary" size="medium" icon="export" @click="handleExportBatch()">批量导出</dd-button>
          </template>
          <template #table-pagination>
            <dd-pagination
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
  
      <!-- 编辑 && 详情 -->
      <dd-popup
          v-if="dialogEditDetailVisible"
          width="79%"
          height="80%"
          showBtn
          :saveBtn="false"
          :submitBtn="showSubmitBtn"
          :show="dialogEditDetailVisible"
          :title-name="EditDetailTitle"
          @popup-submit="handleSubmit()"
          @popup-close="dialogEditDetailVisible = false"
        >
          <DetailedBudgetDetails
            ref="DecomposeEdit"
            slot="main"
            :pageType="pageType"
            :propDetail="propDetail"
            :type="type"
          ></DetailedBudgetDetails>
        </dd-popup>

        <!-- 金蝶视图查看 -->
      <dd-popup
          v-if="dialogkingDeeVisible"
          width="70%"
          height="80%"
          showBtn
          :saveBtn="false"
          :submitBtn="false"
          :show="dialogkingDeeVisible"
          :title-name="EditkingDeeTitle"
          @popup-close="popupClose"
        >
          <KingDee
            ref="DecomposeEdit"
            slot="main"
            :propDetail="propDetail"
            :type="type"
          ></KingDee>
        </dd-popup>

        <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
    </div>
  </template>
    
    <script>
  import { postApi, loadApi, getApi } from "@/api/request";
  import DetailedBudgetDetails from "../Details/components/DetailedBudgetDetails.vue"; // 详情
  import { downloadBinaryFile } from "@/utils/index";
  import KingDee from "./components/KingDeeList.vue";
  export default {
    name: "adjustment",
    $dynamicRoute: {
      name: " 可调整预算表",
      path: "/finance/budget/adjustment",
      meta: {
        keepAlive: true,
      },
    },
    components: { DetailedBudgetDetails, KingDee },
    data() {
      return {
        searchObj:{},
        drawerVisible: false,
        tableHeight: null,
        loading: false,
        EditDetailTitle: '',
        EditkingDeeTitle: '金蝶视图',
        dialogkingDeeVisible: false,
        dialogEditDetailVisible: false,
        showSubmitBtn: true,
        propDetail: {
          type: 'detail'
        },
        selectData: [],
        type: "edit",
        pageType: 'adjustment',
        tableData: [],
        formList: [
          {
            label: "单据编码",
            value: "code",
            inputType: "text",
            placeholderText: "请输入单据编码",
          },
          {
            label: "任务书名称或编码",
            value: "assignName",
            inputType: "text",
            placeholderText: "任务书名称或编码",
          },
          {
            label: "状态",
            value: "status",
            inputType: "select",
            optionsList: [],
            filterable: "filterable",
            placeholderText: "请选择启用状态",
            optionsListConfig: {
              name: "name",
              code: "code",
            },
          },
          {
            label: "项目名称或编码",
            value: "prjName",
            inputType: "text",
            placeholderText: "项目名称或编码",
          },
          {},{},{}
        ],
        // 表格头部配置数据
        tableColumns: [
          {
            title: "单据编码",
            key: "code",
            align: "center",
            width: 200
          },
          {
            title: "任务书编码",
            key: "assignNum",
            align: "center",
            overflow: true,
          },
          {
            title: "任务书名称",
            key: "assignName",
            align: "center",
            event: [
            {
              type: "primary",
              method: (row) => {
                this.handleRowHref(row);
              },
              href: (row) => {
                return (
                  row.assignName
                );
              },
            },
            ]
          },
          {
            title: "项目编码",
            key: "prjCode",
            align: "center",
            overflow: true,
          },
          {
            title: "项目名称",
            key: "prjName",
            align: "center",
            width: 400
          },
          {
            title: "版本",
            key: "version",
            align: "center",
          },
          {
            title: "状态",
            key: "statusName",
            align: "center",
          },
          {
            title: "操作",
            align: "center",
            width: 120,
            event: [
              {
                label: "查看详情",
                state: "default",
                method: (row) => {
                  this.handleDetail(row);
                },
              },
            ],
          },
        ],
        columnsList: [],
        current: 1, // 当前页码
        total: 0, // 总条数
        size: 10, // 每页的数据条数
      };
    },
    created() {
      this.getStatus();
    },
    mounted() {
      this.columnsList = [...this.tableColumns];
      this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
      this.getTableData();
    },
    methods: {
      handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
      handleRowHref(row) {
            this.dialogEditDetailVisible = true;
            this.propDetail.type= 'detail';
            this.propDetail.id = row.id;
            this.propDetail.isTaskBook = true;
            this.showSubmitBtn = false;
            this.EditDetailTitle = '可调整预算详情'
      },
      getTableData(obj) {
        let params = {
          current: this.current,
          size: this.size,
          ...obj,
        }
        getApi(`/finance/finbudgetadjustable/page`, params).then(res => {
          if(res.data.code == 0) {
            this.tableData = res.data.data.records;
            this.total = res.data.data.total;
          }
        })
      },
        edit() {
          if(this.selectData.length !== 1) {
            this.$message.warning('请勾选一条数据');
            return false
          }
          if(this.selectData[0].status == 'CW-KTZYS-ZT-YWC') {
            this.$message.warning('状态为已完成不可调整编辑');
            return false
          }
            this.dialogEditDetailVisible = true;
            this.propDetail.isTaskBook = false;
            this.EditDetailTitle = '编辑可调整预算'
            this.propDetail.type= 'edit';
            this.propDetail.id = this.selectData[0].id
            this.showSubmitBtn = true;
        },
        confirm() {
          if(this.selectData.length !== 1) {
            this.$message.warning('请勾选一条数据');
            return false;
          }

          if(this.selectData[0].status !== 'CW-KTZYS-ZT-TZZ') {
            this.$message.warning('只可操作状态为调整中的数据');
            return false
          }
          postApi(`/finance/finbudgetadjustable/verify/${this.selectData[0].id}`).then(res => {
            if(res.data.code == 0) {
              this.$message.success('操作成功')
            }
            else {
              this.$message.success(res.data.msg)
            }
          }).finally(() => {
            this.getTableData();
            this.$refs.dTableRefs.$refs.baseTable.clearSelection();
            this.selectData = [];
          })

        },
        counterConfirmation() {
          if(this.selectData.length !== 1) {
            this.$message.warning('请勾选一条数据');
            return false;
          }
          if(this.selectData[0].status !== 'CW-KTZYS-ZT-YWC') {
            this.$message.warning('只可操作状态为已完成的数据');
            return false
          }
          // /finance/finbudgetadjustable/verify/{id}
          postApi(`/finance/finbudgetadjustable/rollback/${this.selectData[0].id}`).then(res => {
            if(res.data.code == 0) {
              this.$message.success('操作成功')
            }
            else {
              this.$message.success(res.data.msg)
            }
          }).finally(() => {
            this.getTableData();
            this.$refs.dTableRefs.$refs.baseTable.clearSelection();
            this.selectData = [];
          })
        },
        kingDee() {
          if(this.selectData.length !== 1) {
            this.$message.warning('请勾选一条数据');
            return false;
          }
          this.propDetail.id = this.selectData[0].id
          this.dialogkingDeeVisible = true;
        },
      exportDetail() {
        // this.$message.warning('功能开发中!')
        if (this.selectData.length != 1) {
          this.$message.warning('请选择一条数据');
          return false;
        }
        loadApi(`/finance/finbudgetadjustable/export`, { id: this.selectData[0].id }).then(res => {
          let { data } = res;
          this.$refs.dTableRefs.$refs.baseTable.clearSelection();
          this.selectData = [];
          downloadBinaryFile(
            data,
            decodeURIComponent(
              res.headers["content-disposition"].split(";")[1].split("=")[1]
            )
          );
          this.$message.success('导出成功')
        })
      },
      handleExportBatch() {
        if (this.selectData.length < 1) {
          this.$message.warning('请至少选择一条数据');
          return false;
        }
        loadApi(`/finance/finbudgetadjustable/batch/export`, { ids: this.selectData.map((item) => { return item.id }) }).then(res => {
          let { data } = res;
          this.$refs.dTableRefs.$refs.baseTable.clearSelection();
          this.selectData = [];
          downloadBinaryFile(
            data,
            decodeURIComponent(
              res.headers["content-disposition"].split(";")[1].split("=")[1]
            )
          );
          this.$message.success('导出成功')
        })
      },
        handleDetail(row) {
            this.dialogEditDetailVisible = true;
            this.propDetail.isTaskBook = false;
            this.propDetail.type= 'detail';
            this.propDetail.id = row.id;
            this.showSubmitBtn = false;
            this.EditDetailTitle = '可调整预算详情'
        },
        handleSearchEvent(e) {
          this.searchObj = e;
          this.getTableData(e)

        },
        handleSelectionChange(e) {
            this.selectData = e;
        },
        handleCheckDisable() {
          return true
        },
        popupClose() {
          this.dialogkingDeeVisible = false;
          this.$refs.dTableRefs.$refs.baseTable.clearSelection();
          this.selectData = [];
        },
        handleSubmit() {
          let jbxxData = this.$refs.DecomposeEdit.$refs.detailedForm.formData;
          if(jbxxData.budgetTotalAmount == 0) {
            this.$message.warning('调整后预算总额不能为0');
            return false;
          }
          const params = {
            id: jbxxData.id,
            budgetTotalAmount: jbxxData.budgetTotalAmount,
            finBudgetAdjusxsGdVOList: this.$refs.DecomposeEdit.tableDataSales,
            finBudgetAdjusscZdyVOList: this.$refs.DecomposeEdit.tableDataSource,
            finBudgetAdjusscGdVOList: this.$refs.DecomposeEdit.tableDataFixed,
            applyDate: this.$refs.DecomposeEdit.$refs.personForm.formData.applyDate
          }
          postApi('/finance/finbudgetadjustable/update', params).then(res => {
            if(res.data.code == 0) {
              this.$message.success('提交成功');
              this.getTableData();
            }
            else {
              this.$message.warning(res.data.msg);
            }
          }).finally(() => {
            this.dialogEditDetailVisible = false;
            this.$refs.dTableRefs.$refs.baseTable.clearSelection();
            this.selectData = [];
          })
        },
        handleCurrentChange(size, number) {
          this.size = size;
          this.current = number;
          this.getTableData(this.searchObj);
        },
        getStatus() {
          postApi('/finance/dict/list/all', {typeCode: "CW-KTZYS-ZT"}).then(res => {
            let { data } = res;
            if (data.code == 0) {
              let options = {
                value: "status",
                optionsList: data.data
              };
              this.setOPtionsList(this.formList, options);
            }
          })
        },
        setOPtionsList(formList, options) {
          formList.forEach((item) => {
            if (options) {
              if (item.value == options.value) {
                item.optionsList = options.optionsList;
              }
            }
          });
        },
    },
  };
  </script>
    
<style lang="less" scoped></style>
    