<template>
    <div class="main-container">
        <div class="head_wrap">
            <dd-card title="条件搜索" spacer>
                <dd-search-form :form-config="formList" :labelWidth="'190px'" @search-click="handleSearchEvent"
                    ref="ddSearchForm"></dd-search-form>
            </dd-card>
        </div>

        <dd-card title="合同结算列表" :height="tableHeight">
            <dd-table :columns="columnsList" :data="tableData" border :isSelection="true" show-page :loading="loading">
                <template #table-btn>
                    <dd-button type="primary" icon="export" size="medium" @click="handleExport">导出
                    </dd-button>
                </template>
                <template #table-btn-right>
                    <el-button icon="el-icon-s-operation" circle type="info" size="small" @click="drawerVisible = true"
                        title="列筛选"></el-button>
                </template>
                <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handlePageChange" align="right">
                    </dd-pagination>
                </template>
            </dd-table>
        </dd-card>

        <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
            :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
            @handleColumnsChange="handleColumnsChange">
        </dd-filter-drawer>


        <!-- <dd-popup v-if="pageDetailVisible" width="78%" height="80%" showBtn :saveBtn="saveBtn" :submitBtn="false"
            :show="pageDetailVisible" title-name="详情"
            @popup-close="handleClose">
            <detail ref="detail" :pageProp="pageProp" slot="main"></detail>
        </dd-popup> -->
    </div>
</template>

<script>
import { postApi } from '@/api/request';
import detail from './components/detail.vue'
export default {
    name: 'overviewContractSettlement',
    $dynamicRoute: {
        name: "合同结算总览",
        path: "/contract/overviewContractSettlement/index",
    },
    components: { detail },
    data() {
        return {
            saveBtn: false,
            pageDetailVisible: false,
            pageProp: {},
            loading: false,
            formList: [
                {
                    label: "所属部门",
                    value: "dept",
                    inputType: "text",
                    placeholderText:
                        "请输入所属部门",
                },
                {
                    label: "关联结算单名称",
                    value: "settlefname",
                    inputType: "text",
                    placeholderText:
                        "请输入关联结算单名称",
                },
                {
                    label: "关联结算单编号",
                    value: "settlefnumber",
                    inputType: "text",
                    placeholderText:
                        "请输入关联结算单编号",
                },
                {
                    label: "自建合同名称",
                    value: "fname",
                    inputType: "text",
                    placeholderText:
                        "请输入自建合同名称",
                },{},{},{}
            ],
            tableColumns: [
                {
                    title: "所属部门",
                    key: "dept",
                    align: "center",
                    overflow: true,
                    width: 110,
                },
                {
                    title: "结算期间",
                    key: "fperiod",
                    align: "center",
                    overflow: true,
                    width: 110,
                },
                {
                    title: "本期结算金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                    width: 110,
                },
                {
                    title: "关联结算单编号",
                    key: "settlefnumber",
                    align: "center",
                    overflow: true,
                    width: 130,
                }, {
                    title: "关联结算单名称",
                    key: "settlefname",
                    align: "center",
                    overflow: true,
                    width: 130,
                },
                {
                    title: "自建合同编号",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                    width: 130,
                }, {
                    title: "自建合同名称",
                    key: "fname",
                    align: "center",
                    overflow: true,
                    width: 110,
                },
                {
                    title: "自建合同变更后合同总金额",
                    key: "procurementFinallyAmount",
                    align: "center",
                    overflow: true,
                    width: 190,
                }, {
                    title: "自建合同累计结算金额",
                    key: "contractRelatedAmount",
                    align: "center",
                    overflow: true,
                    width: 170,
                },
                {
                    title: "关联项目编号",
                    key: "prjCode",
                    align: "center",
                    overflow: true,
                    width: 130,
                },
                {
                    title: "关联项目名称",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                    width: 130,
                }, {
                    title: "关联销售合同编号",
                    key: "saleContractCode",
                    align: "center",
                    overflow: true,
                    width: 140,
                    event: [
                        {
                        type: "primary",
                        method: (row) => {
                            this.handleRowHref(row);
                        },
                        href: (row) => {
                            return (
                            row.saleContractCode
                            );
                        },
                        },
                    ],
                },
                {
                    title: "关联销售合同名称",
                    key: "saleContractName",
                    align: "center",
                    overflow: true,
                    width: 140,
                    event: [
                        {
                        type: "primary",
                        method: (row) => {
                            this.handleRowHref(row);
                        },
                        href: (row) => {
                            return (
                            row.saleContractCode
                            );
                        },
                        },
                    ],
                }, {
                    title: "经办日期",
                    key: "fbizdate",
                    align: "center",
                    overflow: true,
                    width: 100,
                },
                {
                    title: "制单人",
                    key: "applierName",
                    align: "center",
                    overflow: true,
                    width: 100,
                },
                {
                    title: "制单日期",
                    key: "applyDate",
                    align: "center",
                    overflow: true,
                    width: 100,
                },
            ],
            drawerVisible: false,
            tableHeight: null,
            tableData: [],
            searchObj: {},
            columnsList: [],
            // 当前页码
            current: 1,
            // 总条数
            total: 0,
            // 每页的数据条数
            size: 10,
        }
    },
    created() {
        this.getTableList();
    },
    mounted() {
        this.columnsList = [...this.tableColumns]
    },
    methods: {
        getTableList() {
            postApi(`/contract/selfestablished/procurement/self/settle/${this.current}/${this.size}`, this.searchObj).then(res => {
                if(res.data.code == 0) {
                    this.tableData = res.data.data.records
                    this.total = res.data.data.total;
                }
            })
        },
        handleSearchEvent(e) {
            this.current = 1;
            this.searchObj = e;
            this.getTableList();
        },
        handleExport() {

        },
        handlePageChange(pageSize, pageNumber) {
            this.size = pageSize;
            this.current = pageNumber;
            this.getTableList();
        },
        handleDetail(e) {
            this.pageDetailVisible = true;
        },
        handleRowHref(row) {
            console.log('row', row)
        },
        handleClose() {
            this.pageDetailVisible = false;
        },
        handleColumnsChange(val) {
            let list = new Set();
            this.tableColumns.map((item) => {
                val.map((itm) => {
                    if (item.event || item.key == itm.key) {
                        list.add(item);
                    }
                });
            });
            this.columnsList = [...list];
        },
    }
}
</script>

<style lang="less" scoped></style>