<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>

<script>
export default {
  name: "add-pop",
  props: {
    rowData: [],
  },
  data() {
    return {
      formBasicList: [
        {
          label: "节点分类编码",
          value: "nodeCode",
          inputType: "text",
          placeholderText: "请输入节点分类编码",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入节点分类编码",
              trigger: "blur",
            },
          ],
        },
        {
          label: "节点分类名称",
          value: "nodeName",
          inputType: "text",
          placeholderText: "请输入节点分类名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入节点分类名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "排序",
          value: "sortOrder",
          inputType: "number",
          min: 0,
          max: 999,
          placeholderText: "请输入排序",
          disabled: false,
          defaultValue: "",
        },
        {
          label: "备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入备注",
          disabled: false,
          defaultValue: "",
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (this.rowData != null) {
      this.formBasicList.forEach((item) => {
        item.defaultValue = this.rowData[item.value];
      });
    }
  },
  methods: {},
};
</script>
<style lang="less" scoped></style>
