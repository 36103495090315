<template>
  <div class="cardBox">
    <div class="card-item">
      <div class="card-name">属性</div>
      <i class="el-icon-close" title="关闭" @click="popupClose"></i>
    </div>
    <dd-table
      class="card-table"
      :columns="columnsList"
      :data="tableData"
      height="320"
      ref="dTableRef"
      :isSelection="false"
    >
    </dd-table>
  </div>
</template>

<script>
export default {
  name: "feature-pop",
  props: { popupObj: {}, mapPopupVisible: false },
  data() {
    return {
      columnsList: [
        {
          title: "属性名",
          key: "name",
          align: "center",
          width: 100,
        },
        {
          title: "属性值",
          key: "value",
          align: "center",
          width: 200,
        },
      ],
      tableData: [],
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    popupObj() {
      this.initData();
    },
    deep: true,
  },
  methods: {
    //姓名 打卡时间 打卡地址 项目类型 所属部门 打卡类型 签到状态
    initData() {
      let values = { ...this.popupObj };
      let list = [];
      for (let item in values) {
        if (item == "userFullName") {
          list.push({ name: "姓名", value: values[item] });
        } else if (item == "signTime") {
          list.push({ name: "打卡时间", value: values[item] });
        } else if (item == "signAddress") {
          if (values[item].length > 10)
            values[item] = values[item].substring(0, 10) + "...";
          list.push({ name: "打卡地址", value: values[item] });
        } else if (item == "prjName") {
          list.push({ name: "项目类型", value: values[item] });
        } else if (item == "deptName") {
          list.push({ name: "所属部门", value: values[item] });
        } else if (item == "name") {
          list.push({ name: "打卡状态", value: values[item] });
        } else if (item == "instatusName") {
          list.push({ name: "签到状态", value: values[item] });
        }
      }
      this.tableData = list;
    },
    popupClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.cardBox {
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  .card-item {
    background-color: @themeColor;
    color: #fff;
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    align-items: center;
    justify-content: space-between;
    display: flex;
    .card-name {
      margin-left: 15px;
    }
  }
  .card-table {
    /deep/ .table-btn-group {
      display: none !important;
    }
  }
  .el-icon-close {
    font-size: 20px;
    margin-right: 10px;
  }
}
/deep/ .el-dialog__body {
  height: 100% !important;
  .el-table__cell {
    padding: 0px !important;
  }
}
</style>