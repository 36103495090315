<template>
    <div class="main-container">
        <dd-form :form-config="formList" :labelWidth="'170px'" :flex="3" ref="standardMasterNodeForm"
            formRef="standardMasterNodeForm"></dd-form>


        <dd-card :height="tableHeight">
            <dd-table :columns="tableColumns" :data="tableData" border ref="workTable"
                @select-change="handleSelectionChange($event, 2)">
            </dd-table>
        </dd-card>


        <dd-popup width="50%" height="50%" showBtn inPopup :saveBtn="true" :cancelBtn="true" :show="editPopupVisible"
            title-name="编辑" @popup-close="editPopupVisible = false"
            @popup-save="handleEditPopSave('addPopForm', 'baseForm')">
            <model-node-edit-pop v-if="editPopupVisible" :rowData="rowData" slot="main" ref="editPop">
            </model-node-edit-pop>
        </dd-popup>
    </div>
</template>

<script>
import { postApi, getApi, delApi } from "@/api/request";
import ModelNodeEditPop from "./ModelNodeEditPop.vue";
export default {
    name: 'standardMasterNodePage',
    props: ['treeRowData'],
    components: { ModelNodeEditPop },
    data() {
        return {
            tableHeight: null,
            formList: [
                {
                    label: "模板名称",
                    value: "templateId",
                    inputType: "select",
                    optionsList: [],
                    filterable: false,
                    placeholderText: "请选择模板名称",
                    optionsListConfig: {
                        name: "name",
                        code: "id",
                    },
                    change: this.modelChange,
                    rules: [
                        {
                            required: true,
                            message: "请选择模板名称",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "业务类型",
                    value: "businessTypeId",
                    inputType: "cascader",
                    optionsList: [],
                    defaultValue: "",
                    prop: {
                        value: "id",
                        label: "name",
                        checkStrictly: true,
                        emitPath: false,
                    },
                    showAllSelcet: false,
                    placeholderText: "请选择业务类型",
                    rules: [
                        {
                            required: true,
                            message: "请选择业务类型",
                            trigger: "blur",
                        },
                    ],
                },

            ],
            rowData: {},
            editPopupVisible: false,
            modelId: null,
            tableData: [],
            selectionData: [],
            tableColumns: [
                {
                    title: "节点",
                    key: "name",
                    align: "center",
                },
                {
                    title: "生产负责部门",
                    key: "receiveDeptName",
                    align: "center",
                },
                {
                    title: "部门",
                    key: "childDepName",
                    align: "center",
                },
                {
                    title: "负责人",
                    key: "fullName",
                    align: "center",
                },
                {
                    title: "核算组",
                    key: "accounting",
                    align: "center",
                },
                {
                    title: "操作",
                    key: "handle",
                    align: "center",
                    event: [
                        {
                            label: "编辑",
                            icon: "el-icon-edit",
                            method: (row) => {
                                this.handleRowEditor(row);
                            },
                            type: "primary",
                        },
                    ],
                },
            ],
        }
    },
    created() {
        this.getBusinessType();
    },
    mounted() {
        this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
        });
    },
    methods: {
        handleRowEditor(row) {
            this.rowData = row;
            this.editPopupVisible = true;
        },
        handleSelectionChange(e) {
            this.selectionData = e;
        },
        handleEditPopSave(formName, formNameBase) {
            let flag = false;
            this.$refs.editPop.$refs[formName].$refs[formNameBase].validate(
                (valid) => {
                    if (valid) {
                        flag = true;
                    }
                }
            );
            let params = this.$refs.editPop.formDataObj;
            if (flag) {
                this.tableData.forEach((item) => {
                    if (item.id == this.rowData.id) {
                        item.receiveSubDeptId = params.receiveSubDeptId;
                        item.childDepName = params.childDepName;
                        item.leaderId = params.leaderId;
                        item.fullName = params.fullName;
                        item.areaAccountingId = params.areaAccountingId;
                        item.accounting = params.accounting;
                        item.receiveDeptId = params.receiveDeptId;
                        item.receiveDeptName = params.receiveDeptName;
                    }
                });
                this.editPopupVisible = false;
            }
        },
        getTableData() {
            getApi(
                `/project/subplannode/unselected/standard/node/${this.modelId}/${this.treeRowData.subplanId}`
            ).then(res => {
                if (res.data.code == 0) {
                    let { data } = res;
                    data.data.forEach((item) => {
                        item.receiveDeptId = this.treeRowData.nodeDeptId;
                        item.receiveDeptName = this.treeRowData.nodeDeptName;

                        item.receiveSubDeptId = null;
                        item.childDepName = null;

                        item.leaderId = null;
                        item.fullName = null;

                        item.areaAccountingId = null;
                        item.accounting = null;
                    });
                    this.tableData = data.data;
                }
            })
        },
        modelChange(e) {
            if (e) {
                this.modelId = e;
                this.getTableData();
            } else {
                this.tableData = [];
            }
        },
        loopData(data) {
            if (data && data.length) {
                data.forEach((item) => {
                    if (item?.children && item?.children?.length > 0) {
                        item.disabled = true;
                        this.loopData(item.children);
                    }
                });
            }
            return data;
        },
        setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.optionsList = options.optionsList;
                    }
                }
            });
        },
        // 业务类型
        getBusinessType() {
            getApi(`/project/prjareasubassginbussitype/tree`, {}).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    let arr = this.loopData(data.data);
                    let options = {
                        value: "businessTypeId",
                        optionsList: arr,
                    };
                    this.setOPtionsList(this.formList, options);
                }
            });
            getApi(`/project/prjwbsbasetemplate/list/zrw`).then((res) => {
                let { data } = res;
                if (data.code == 0) {
                    let options = {
                        value: "templateId",
                        optionsList: data.data,
                    };
                    this.setOPtionsList(this.formList, options);
                }
            });

        },
    },
}
</script>

<style lang="less" scoped></style>