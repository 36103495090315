<!--
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-08-11 10:59:00
 * @LastEditors: hechen
 * @LastEditTime: 2023-08-22 15:33:58
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\views\SystemManagement\File\components\AddPop.vue
 * @Descripttion: 
-->
<template>
    <div class="main-container">
      <el-upload
        class="upload-demo"
        drag
        :action="actionURL"
        :headers="headers"
        :on-remove="handleRemove"
        :on-success="handleFileSuccess"
        :file-list="fileList"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">上传同步至文件服务器</div>
      </el-upload>
    </div>
  </template>
  
  <script>
  export default {
    name: "add-pop",
    props: {
    },
    data() {
      return {
        actionURL: '/admin/sys-file/upload',
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("authToken") || ""
        },
        fileList: [],
        formBasicList: [
          {
            label: "附件上传",
            value: "publicName",
            inputType: "upload",
            action: '/admin/sys-file/upload',
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          }
        ],
      };
    },
    created() {},
    mounted() {
    },
    methods: {
      //附件上传成功
      handleFileSuccess(res, file) {
        if (res.code == 0) {
          this.fileList.push(res.data);
          this.$emit('file-seccess')
        }
      },
      //附件上传移除
      handleRemove(file, fileList) {
        this.fileList.map((item, index) => {
          if (item.fileName == file.fileName) {
            this.fileList.splice(index, 1);
          }
        });
      },
    },
  };
  </script>
  <style lang='less' scoped>
  /deep/.el-upload{
    width: 100%;
  }
  /deep/.el-upload-dragger{
    width: 100%;
    background-color: #f5f5f5;
  }
</style>
  