<template>
  <div class="init-task-container">
    <div class="top-tab" v-if="oneStepPage">
      <dd-button type="text" size="medium" icon="back" @click="handleBackPage"
        >返回上一级</dd-button
      >
    </div>
    <div
      class="wrapper"
      :style="!oneStepPage ? `height:100%` : `height:calc(100% - 40px)`"
    >
      <div v-if="oneStepPage" class="one-step">
        <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
        <dd-button type="primary" size="medium" @click="handleNextPage"
          >下一步</dd-button
        >
        <dd-button type="warning" size="medium" @click="handleBackPage"
          >取消</dd-button
        >
      </div>
      <task-process
        v-else
        type="wbs"
        sourceType="init"
        :rowId="rowId"
        @pageBack="handleBackStep"
      ></task-process>
    </div>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";
// 工作分解
import taskProcess from "../../ProjectProcess/index.vue";
export default {
  props: {
    rowId: {
      type: String,
      default: "",
    },
  },
  components: {
    taskProcess,
  },
  data() {
    return {
      oneStepPage: true,
      formBasicList: [
        {
          label: "创建人",
          value: "createBy",
          inputType: "text",
          placeholderText: "请输入创建人",
          disabled: true,
          defaultValue: sessionStorage.getItem("fullName"),
        },
        {
          label: "创建部门",
          value: "createDeptName",
          inputType: "text",
          placeholderText: "请输入创建部门",
          disabled: true,
          defaultValue: sessionStorage.getItem("depName"),
        },
        {
          label: "模板名称",
          value: "templateId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择模板名称",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择模板名称",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    // 获取模板列表
    this.getModelList();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleBackPage() {
      this.$emit("pageBack");
      // this.$bus.$emit("collapse", false);
    },
    handleBackStep() {
      // this.oneStepPage = true;
      this.$emit("pageBack");
      // this.$bus.$emit("collapse", false);
    },
    handleNextPage() {
      let flag = false;
      this.$refs.addPopForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (flag) {
        let formData = this.$refs.addPopForm.formData;
        let params = {
          templateId: formData.templateId,
          assignId: this.rowId,
        };
        postApi(`/project/subplan`, params).then((res) => {
          if (res.data && res.data.code == 0) {
            this.oneStepPage = false;
            // this.$bus.$emit("collapse", true);
          }
        });
      }
    },
    // 获取模板列表
    getModelList() {
      getApi(`/project/prjwbsbasetemplate/list/wbs`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "templateId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.init-task-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: @systemColor;
  position: relative;
  .top-tab {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: #f2f7ff;
    border-bottom: 1px solid #fff;
  }
  .wrapper {
    width: 100%;
    height: calc(100% - 40px);

    .one-step {
      width: 50%;
      text-align: right;
      margin: 0 auto;
    }
  }

  /deep/ .el-button--text {
    color: @themeColor;
    font-size: @fs16;
  }

  /deep/.el-input.is-disabled .el-input__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
  }
}
</style>
