<template>
  <div class="main-container">
    <div class="left-box">
      <dd-card title="责任部门">
        <div class="container-left-tree">
          <el-tree
            ref="tree"
            :data="TreeList"
            accordion
            :props="defaultProps"
            default-expand-all
            highlight-current
            node-key="id"
            :current-node-key="treeNodeId"
            @node-click="handleNodeClick"
          >
          </el-tree>
        </div>
      </dd-card>
    </div>
    <div class="right-box">
      <dd-card title="部门列表" height="100%">
          <dd-table
            ref="listTable"
            tableRef="table"
            :columns="columnsList"
            :data="tableData"
            @select-change="handleTableSelect"
            border
          >
            <template #table-btn>
              <dd-button type="primary" size="medium" @click="handleSubmit"
                >提交</dd-button
              >
            </template>
          </dd-table>
      </dd-card>
    </div>
  </div>
</template>
<script>
import { getApi, postApi } from "@/api/request";
export default {
  props: {
      dictType: [],
      rowData: []
    },
  data() {
    return {
      loading: true,
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeNodeId: "",
      leftTableColumns: [
        {
          title: "部门",
          key: "name",
          align: "center",
        },
      ],
      columnsList: [
        {
          title: "部门名称",
          key: "name",
          align: "center",
        },
        {
          title: "分管部门",
          key: "subject",
          align: "center",
        }
      ],
      tableData: [],

      total: 0,
      current: 1,
      size: 10,
      selectList:[]
    };
  },
  mounted() {
    this.getLeftTree();
    this.getTableData();
  },
  methods: {
    // 获取左侧数据
    getLeftTree() {
      getApi(`/admin/dept/getbydepid/${this.rowData.id}`, {}).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.TreeList = data.data;
        }
      });
    },
    handleNodeClick(data) {
      this.treeNodeId = data.id;
      this.getTableData();
    },
    // 获取部门列表数据
    getTableData() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableData = data.data;
        }
      });
    },
    // 选择
    handleTableSelect(val) {
      this.selectList = val;
    },
    // 保存
    handleSubmit(){
      let deptIds = [];
      for(let i=0;i<this.selectList.length;i++){
        deptIds.push(this.selectList[i].deptId)
      }
      let params = {
        deptIdList:deptIds,fenDeptId:this.rowData.id
      };
      postApi(`/admin/sysfendept/setdeptbyfendept`, params).then((res) => {
        let { data } = res;
        this.getLeftTree();
        this.getTableData();
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
      });

    }
  },
};
</script>

<style lang="less" scoped>
.main-container {
  position: relative;
  display: flex;

  .left-box {
    width: 30%;
    border-right: 5px solid @dividerColor;
  }

  .right-box {
    width: 70%;
  }
}
</style>
