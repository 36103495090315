<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowData: [],
    poptitleName: "",
  },
  data() {
    return {
      formBasicList: [
        {
          label: "干系人类型",
          value: "holderType",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择干系人类型",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择干系人类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "干系人名称",
          value: "holderName",
          inputType: "text",
          placeholderText: "请输入干系人名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入干系人名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "联系方式",
          value: "holderTel",
          inputType: "text",
          placeholderText: "请输入联系方式",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入联系方式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "干系人职务",
          value: "holderPost",
          inputType: "text",
          placeholderText: "请输入干系人职务",
          disabled: false,
          defaultValue: "",
        },
        {
          label: "干系人单位",
          value: "holderCompany",
          inputType: "text",
          placeholderText: "请输入干系人单位",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入干系人单位",
              trigger: "blur",
            },
          ],
        },
        {
          label: "说明",
          value: "holderDes",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入说明",
          disabled: false,
          defaultValue: "",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getDataType();
    if (this.rowData != null) {
      this.formBasicList.forEach((item) => {
        item.defaultValue = this.rowData[item.value];
      });
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 干系人类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GXRLX" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "holderType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scoped></style>
