<template>
  <div class="container">
    <dd-card title="基本信息">
      <dd-form
        :form-config="formList"
        :ref="formRef"
        :formRef="formRef"
        :flex="3"
        labelWidth="220px"
      ></dd-form>
    </dd-card>
    <el-form :model="ruleForm" ref="ruleForm">
      <div v-if="this.type == 'detail' || this.type == 'approve'">
        <dd-card title="审批流程">
          <dd-table
            ref="approveProcess"
            :isSelection="false"
            :columns="tableColumns"
            :data="tableDataSources"
            border
          ></dd-table>
        </dd-card>
      </div>
    </el-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import { getNowDate } from "@/utils/date";
import { mapState } from "vuex";

export default {
  props: {
    type: "",
    id: "",
  },
  components: {
    "el-image-viewer": () =>
      import("element-ui/packages/image/src/image-viewer"),
  },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      detailiInfo: {},
      hrRentalcarApplySheetFileList: [],
      formRef: "baseForm",
      headers: {}, // 请求头信息
      limit: 10, //文件上次个数限制
      ruleForm: {},
      fileList: [],
      AttachFilesImage: [],
      AttachFilesOther: [],
      applyId: "",
      value: "",
      tableData: [],
      formList: [
        {
          label: "申请编号",
          value: "applyCode",
          inputType: "text",
          placeholderText: "保存后生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请人",
          value: "applierName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: sessionStorage.getItem("fullName"),
        },
        {
          label: "部门",
          value: "applierDepName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: sessionStorage.getItem("depName"),
        },
        {
          label: "费用承担组织",
          value: "expenseBearOrgName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: sessionStorage.getItem("orgName"),
        },
        {
          label: "费用承担部门",
          value: "expenseBearDepId",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "deptId",
            name: "name",
          },
          defaultValue: sessionStorage.getItem("depId"),
          placeholderText: "请选择或搜索费用承担部门",
          rules: [
            { required: true, message: "请选择费用承担部门", trigger: "blur" },
          ],
        },
        {
          label: "申请时间",
          value: "date",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: getNowDate(),
        },
        {
          label: "项目名称",
          value: "projectId",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "projectId",
            name: "projectName",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索项目名称",
          rules: [
            { required: true, message: "请选择项目名称", trigger: "blur" },
          ],
        },
        {
          label: "项目编号",
          value: "projectId",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "projectId",
            name: "projectCode",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索项目编号",
          rules: [
            { required: true, message: "请选择项目编号", trigger: "blur" },
          ],
        },
        {},
        {
          label: "用车起始日期",
          value: "rentalcarStartDate",
          placeholderText: "用车起始日期",
          inputType: "date",
          dateType: "date",
          change: this.handleChange,
          defaultValue: getNowDate(),
          format: "yyyy-MM-dd",
          rules: [
            { required: true, message: "请选择用车起始日期", trigger: "blur" },
          ],
        },
        {
          label: "用车结束日期",
          value: "rentalcarEndDate",
          placeholderText: "用车结束日期",
          inputType: "date",
          dateType: "date",
          format: "yyyy-MM-dd",
          change: this.handleChange,
          defaultValue: getNowDate(),
          rules: [
            { required: true, message: "请选择用车结束日期", trigger: "blur" },
          ],
        },
        {
          label: "验证人",
          value: "verifierId",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索验证人",
          rules: [{ required: true, message: "请选择验证人", trigger: "blur" }],
        },
        {
          label: "出发地",
          value: "departurePlace",
          inputType: "text",
          placeholderText: "请输入出发地",
          defaultValue: "",
          rules: [{ required: true, message: "请输入出发地", trigger: "blur" }],
        },
        {
          label: "目的地",
          value: "destinationPlace",
          inputType: "text",
          placeholderText: "请输入目的地",
          defaultValue: "",
          rules: [{ required: true, message: "请输入目的地", trigger: "blur" }],
        },
        {
          label: "验证人员工号",
          value: "verifierId",
          inputType: "select",
          filterable: true,
          disabled: true,
          optionsList: [],
          optionsListConfig: {
            code: "userId",
            name: "username",
          },
          defaultValue: "",
          placeholderText: "验证人员工号",
          rules: [
            { required: true, message: "请选择验证人员工号", trigger: "blur" },
          ],
        },
        {
          label: "起始公里数",
          value: "startKilometer",
          ref: "startKilometer",
          change: this.handleChange,
          inputType: "text",
          placeholderText: "请输入正整数,最大不超过8位数",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入起始公里数", trigger: "blur" },
            {
              pattern: /^[1-9]\d{0,7}$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "结束公里数",
          ref: "endKilometer",
          change: this.handleChange,
          value: "endKilometer",
          inputType: "text",
          placeholderText: "请输入正整数,最大不超过8位数",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入结束公里数", trigger: "blur" },
            {
              pattern: /^[1-9]\d{0,7}$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "用车公里数",
          disabled: true,
          value: "totalKm",
          inputType: "text",
          placeholderText: "自动计算",
          defaultValue: "",
        },
        {
          label: "起始公里图片",
          value: "begin",
          ref: "begin",
          icon: "icon-licheng",
          action: "/admin/sys-file/upload",
          headers: {
            Authorization:
              "Bearer " + sessionStorage.getItem("authToken") || "",
          },
          accept: "image/*",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleRemove,
          placeholderText: "点击可替换之前上传图片",
          successEvent: (res) => {
            this.handleFileSuccess(res, "begin");
          },
          defaultValue: "",
          rules: [
            { required: true, message: "请选择起始公里图片", trigger: "blur" },
          ],
        },
        {
          label: "结束公里图片",
          value: "end",
          ref: "end",
          icon: "icon-licheng",
          action: "/admin/sys-file/upload",
          headers: {
            Authorization:
              "Bearer " + sessionStorage.getItem("authToken") || "",
          },
          accept: "image/*",
          placeholderText: "点击可替换之前上传图片",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleRemove,
          successEvent: (res) => {
            this.handleFileSuccess(res, "end");
          },
          defaultValue: "",
          rules: [
            { required: true, message: "请选择结束公里图片", trigger: "blur" },
          ],
        },
        {
          label: "付款证明图片",
          value: "pay",
          ref: "pay",
          icon: "icon-fapiao",
          action: "/admin/sys-file/upload",
          headers: {
            Authorization:
              "Bearer " + sessionStorage.getItem("authToken") || "",
          },
          accept: "image/*",
          placeholderText: "点击可替换之前上传图片",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleRemove,
          successEvent: (res) => {
            this.handleFileSuccess(res, "pay");
          },
          defaultValue: "",
          rules: [
            { required: true, message: "请选择付款证明图片", trigger: "blur" },
          ],
        },
        {
          label: "基础租车费用",
          value: "basicFee",
          ref: "basicFee",
          change: this.handleChange,
          inputType: "text",
          placeholderText: "请输入两位小数",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入基础租车费用", trigger: "blur" },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "超出公里数追加费用",
          ref: "additionalFee",
          change: this.handleChange,
          value: "additionalFee",
          inputType: "text",
          placeholderText: "请输入两位小数",
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入超出公里数追加费用",
              trigger: "blur",
            },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "租车计价说明",
          value: "pricingInstruction",
          inputType: "text",
          placeholderText: "",
          defaultValue: "",
        },
        {
          label: "租车金额",
          disabled: true,
          value: "rentalcarAmount",
          inputType: "text",
          placeholderText: "自动计算",
          defaultValue: "",
        },
        {
          label: "用车事宜",
          style: "width:100%",
          value: "rentalcarMatter",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入用车事宜",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入用车事宜", trigger: "blur" },
            {
              min: 1,
              max: 100,
              message: "长度在 1 到 100个字符",
              trigger: "blur",
            },
          ],
        },
        {
          label: "备注",
          value: "remark",
          style: "width:100%",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入备注",
          defaultValue: "",
          rules: [
            {
              max: 100,
              message: "长度在 1 到 100个字符",
              trigger: "blur",
            },
          ],
        },
        {
          label: "核定金额",
          change: this.handleChange,
          disabled: this.type == "add" || this.type == "update",
          value: "approvedAmount",
          inputType: "text",
          placeholderText: "部门核定金额后显示",
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入核定金额",
              trigger: "blur",
            },
            {
              pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "车辆行驶证",
          value: "driving",
          ref: "driving",
          icon: "icon-wodejiashizheng",
          action: "/admin/sys-file/upload",
          headers: {
            Authorization:
              "Bearer " + sessionStorage.getItem("authToken") || "",
          },
          accept: "image/*",
          placeholderText: "点击可替换之前上传图片",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleRemove,
          successEvent: (res) => {
            this.handleFileSuccess(res, "driving");
          },
          defaultValue: "",
          rules: [
            { required: true, message: "请选择行驶证图片", trigger: "blur" },
          ],
        },
        {
          label: "驾驶证/身份证",
          value: "identity",
          ref: "identity",
          icon: "icon-shenfenzheng",
          action: "/admin/sys-file/upload",
          headers: {
            Authorization:
              "Bearer " + sessionStorage.getItem("authToken") || "",
          },
          accept: "image/*",
          placeholderText: "点击可替换之前上传图片",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleRemove,
          successEvent: (res) => {
            this.handleFileSuccess(res, "identity");
          },
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请选择驾驶证/身份证图片",
              trigger: "blur",
            },
          ],
        },
      ],
      //审批流程表头
      tableColumns: [
        {
          title: "节点名称",
          key: "taskName",
          align: "center",
        },
        {
          title: "操作时间",
          key: "time",
          align: "center",
        },
        {
          title: "节点操作人",
          key: "userName",
          align: "center",
        },
        {
          title: "审批情况",
          key: "message",
          align: "center",
        },
        {
          title: "审批意见",
          key: "fullmessage",
          align: "center",
        },
      ],
      //审批流程数据
      tableDataSources: [],
    };
  },
  mounted() {
    this.getExpenseDepartmentList(); //费用承担部门列表
    this.getProjectList(); //项目列表
    this.getVerifierList(sessionStorage.getItem("depId")); //验证人员列表
    this.getDataList(); //初始化信息
    if (this.type == "add") {
      this.formList.forEach((item, index) => {
        if (item.value == "approvedAmount") {
          this.formList.splice(index, 1);
        }
      });
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //项目列表
    getProjectList() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    //验证人列表
    getVerifierList(deptId) {
      postApi(`/admin/user/getver/list`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "verifierId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    //费用承担部门
    getExpenseDepartmentList() {
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.options = {
          value: "expenseBearDepId",
          optionsList: res.data.data,
        };
        this.setOPtionsList(this.formList, this.options);
      });
    },
    //获取初始数据
    getDataList() {
      if (
        this.type == "detail" ||
        this.type == "update" ||
        this.type == "approve"
      ) {
        this.type == "detail" &&
          this.formList.forEach((item) => {
            item.disabled = true;
          });
        this.type == "approve" &&
          this.formList.forEach((item) => {
            if (item.value != "approvedAmount") {
              item.disabled = true;
            }
          });
        this.getDetailInfo();
      }
    },
    handleChange(e, type) {
      let {
        startKilometer,
        endKilometer,
        vehicleStartDate,
        vehicleEndDate,
        basicFee,
        additionalFee,
        rentalcarAmount,
        approvedAmount,
      } = this.$refs.baseForm.formData;
      if (type == "startKilometer" || type == "endKilometer") {
        startKilometer = Number(startKilometer);
        endKilometer = Number(endKilometer);
        if (startKilometer && endKilometer) {
          if (endKilometer > startKilometer) {
            this.$refs.baseForm.formData.totalKm =
              endKilometer - startKilometer;
          } else {
            this.$refs.baseForm.formData.totalKm = "";
            this.$message.warning("结束公里数需大于起始公里数");
          }
        }
      } else if (type == "vehicleStartDate" || type == "vehicleEndDate") {
        if (vehicleStartDate && vehicleEndDate) {
          vehicleStartDate = vehicleStartDate.replace(/-/g, "/");
          vehicleEndDate = vehicleEndDate.replace(/-/g, "/");
          let start = new Date(vehicleStartDate).getTime();
          let end = new Date(vehicleEndDate).getTime();
          if (end < start) {
            this.$message.warning("用车结束时间数需大于用车起始时间");
            this.$refs.baseForm.formData.vehicleStartDate = "";
            this.$refs.baseForm.formData.vehicleEndDate = "";
            return;
          }
        }
      } else if (type == "basicFee" || type == "additionalFee") {
        basicFee = Number(basicFee);
        additionalFee = Number(additionalFee);
        let amount = basicFee + additionalFee;
        amount = amount.toFixed(2);
        this.$refs.baseForm.formData.rentalcarAmount = amount;
      } else if ((type = "rentalcarAmount" || type == "approvedAmount")) {
        rentalcarAmount = Number(rentalcarAmount);
        approvedAmount = Number(approvedAmount);
        if (rentalcarAmount < approvedAmount) {
          this.$message.warning("核定金额需小于或等于租车金额");
          return;
        }
      }
    },
    //编辑页面信息回显
    getDetailInfo() {
      let params = {
        id: this.id,
        type: "pc",
      };
      postApi(`/hr/rentalcar/applysheet/detail`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.getVerifierList(data.data.applierDepId);
          this.hrRentalcarApplySheetFileList =
            data.data.hrRentalcarApplySheetFileList;
          this.formList.forEach((item) => {
            if (item.value) {
              if (item.value == "date") {
                item.defaultValue = data.data.applyTime;
              } else if (item.value == "approvedAmount") {
                if (data.data.approvedAmount == 0) {
                  item.defaultValue = data.data.rentalcarAmount;
                } else {
                  item.defaultValue = data.data[item.value];
                }
              } else if (item.value == "totalKm") {
                item.defaultValue =
                  data.data.endKilometer - data.data.startKilometer;
              } else if (item.value == "additionalFee") {
                if (data.data[item.value] == 0) {
                  item.defaultValue = "0.00";
                } else {
                  item.defaultValue = data.data[item.value];
                }
              } else {
                item.defaultValue = data.data[item.value];
              }
              data.data.hrRentalcarApplySheetFileList.forEach((val) => {
                if (item.value == val.sign) {
                  item.defaultValue = val.url;
                }
              });
            }
          });
          this.tableDataSources = data.data.approveList;
          if (this.type != "approve" && data.data.formStatus != "wbzcyhz") {
            this.formList.forEach((item, index) => {
              if (item.value == "approvedAmount") {
                this.formList.splice(index, 1);
              }
            });
          }
        }
      });
    },
    //成功上传文件
    handleFileSuccess(res, type) {
      let { url } = res.data;
      let obj = {};
      obj = res.data;
      obj.sign = type;
      this.$refs.baseForm.formData[type] = url;
      this.hrRentalcarApplySheetFileList.push(obj);
    },
    //删除文件
    handleRemove(type) {
      this.hrRentalcarApplySheetFileList.forEach((item, index) => {
        if (item.sign == type) {
          this.hrRentalcarApplySheetFileList.splice(index, 1);
        }
      });
      this.$refs.baseForm.formData[type] = "";
      this.$refs.baseForm.$refs[type][0].clearFiles();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .card-contant {
  overflow: hidden !important;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor !important;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
</style>
