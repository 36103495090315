<template>
  <div class="tab-container">
    <div class="left-tree container-left-tree">
      <el-tree
        ref="tree"
        :data="TreeList"
        accordion
        :props="defaultProps"
        default-expand-all
        highlight-current
        :expand-on-click-node="false"
        node-key="id"
        :current-node-key="treeNodeId"
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
    <div class="right-table">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
          labelWidth="160px"
        ></dd-search-form>
      </dd-card>
      <dd-card title="子任务列表" :height="tableHeight">
        <dd-table
          :columns="tableColumns"
          :data="tableData"
          border
          :isSelection="false"
          show-page
          :loading="loading"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAdd"
              >新增子任务</dd-button
            >
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="80%"
      height="80%"
      :showBtn="poptitleName == '详情' ? false : true"
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        :titleName="poptitleName"
        :rowData="rowData"
        :taskData="taskData"
        slot="main"
        ref="addPop"
      ></add-pop>
    </dd-popup>
    <!-- 编辑管理信息页面弹窗 -->
    <dd-popup
      width="30%"
      height="30%"
      showBtn
      :show="manageInfoPopupVisible"
      title-name="编辑"
      @popup-close="handleClose"
      @popup-save="handleManageSave('addPopForm', 'baseForm')"
    >
      <manage-info
        v-if="manageInfoPopupVisible"
        slot="main"
        ref="manageInfoPop"
        :rowData="rowData"
      ></manage-info>
    </dd-popup>
    <!-- 管理信息修改记录页面弹窗 -->
    <dd-popup
      width="50%"
      height="40%"
      :show="detailPopupVisible"
      title-name="修改记录"
      @popup-close="handleClose"
    >
      <EditRecordPop
        v-if="detailPopupVisible"
        slot="main"
        :assignId="rowData.id"
      ></EditRecordPop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, postApi, putApi, delApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
import manageInfo from "./components/EditManageInfo.vue";
import EditRecordPop from "./components/EditRecordPop.vue";
export default {
  props: {
    rowId: {
      type: String,
      default: "",
    },
    taskData: {
      type: Object,
      default: {},
    },
  },
  components: {
    addPop,
    manageInfo,
    EditRecordPop,
  },
  data() {
    return {
      tableHeight: null,
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "assignName",
      },
      treeNodeId: "",
      loading: true,
      formData: {},
      formList: [
        {
          label: "子任务编号",
          value: "assignCode",
          inputType: "text",
          placeholderText: "请输入子任务编号",
        },
        {
          label: "子任务名称",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入子任务名称",
        },
        {
          label: "合同名称",
          value: "contractId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择合同",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          // remote: true,
          remoteMethod: (query) => {
            this.getContract(query);
          },
        },
        {
          label: "任务书年份",
          value: "assignYear",
          inputType: "date",
          dateType: "year",
          format: "yyyy",
          placeholderText: "请选择任务书年份",
        },
        {
          label: "子任务生产部门",
          value: "receiveDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择子任务生产部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
      ],
      tableColumns: [
        {
          title: "子任务书单据编号",
          key: "assignCode",
          align: "center",
        },
        {
          title: "子任务书编码",
          key: "assignNum",
          align: "center",
          width: 160,
        },
        {
          title: "子任务书名称",
          key: "assignName",
          align: "center",
        },
        {
          title: "子任务书年份",
          key: "assignYear",
          align: "center",
        },
        {
          title: "生产实施状态",
          key: "produceStatusName",
          align: "center",
        },
        {
          title: "所在区域",
          key: "regionAreaName",
          align: "center",
        },
        {
          title: "客户名称",
          key: "cmmName",
          align: "center",
        },
        {
          title: "项目编码",
          key: "prjCode",
          align: "center",
        },
        {
          title: "项目名称",
          key: "prjName",
          align: "center",
        },
        {
          title: "合同编码",
          key: "contractCode",
          align: "center",
        },
        {
          title: "合同名称",
          key: "contractName",
          align: "center",
        },
        {
          title: "现任务下发部门",
          key: "nowDownDeptName",
          align: "center",
        },
        {
          title: "现生产负责部门",
          key: "nowReceiveDeptName",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          fixed: "right",
          width: 300,
          event: [
            {
              label: "快速配置WBS基本信息",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowInitTaskResolve(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.prjAssignSubplanList.length == 0) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.prjAssignSubplanList.length == 0) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (row.prjAssignSubplanList.length == 0) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看",
              icon: "el-icon-view",
              method: (row) => {
                this.handleRowDetail(row);
              },
            },
            {
              label: "修改管理信息",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowManageInfo(row);
              },
              type: "primary",
            },
            {
              label: "修改记录",
              icon: "el-icon-view",
              type: "primary",
              method: (row) => {
                this.handleRowEditRecord(row);
              },
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      addPopupVisible: false,
      poptitleName: "新增",
      rowData: null,
      detailPopupVisible: false,
      manageInfoPopupVisible: false,
    };
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getContract("", "init");
    this.getDataType();
    this.getLeftTree();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getContract(name, type) {
      // 获取合同列表数据
      postApi(`/project/contractinfo/list`, { name: name }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            item.defaultValue = this.$refs.ddSearchForm.formData[item.value];
            if (item.value == "contractId") {
              item.optionsList = data.data;
              if (type != "init") {
                item.defaultValue = null;
              }
            }
          });
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "receiveDeptId",
            optionsList: data.data,
          };
          let options1 = {
            value: "downDeptId",
            optionsList: data.data,
          };

          this.setOPtionsList(this.formList, options);
          this.setOPtionsList(this.formList, options1);
        }
      });
    },
    // 获取左侧树形数据
    getLeftTree() {
      getApi(`/project/assign/tree/subAssign/${this.rowId}`, {}).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.TreeList = [data.data];
          this.treeNodeId = data.data.id;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
          });
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size, treeNodeId } = this;
      this.loading = true;
      let params = {
        ...formData,
        id: treeNodeId,
      };
      postApi(
        `/project/assign/list/subAssign/${this.rowId}/${current}/${size}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    handleNodeClick(data) {
      this.treeNodeId = data.id;
      this.getTableData();
    },
    handelNodeAdd(data) {
      console.log(data);
    },
    handelNodeEdit(data) {
      console.log(data);
    },
    handelNodeDelete(data) {
      console.log(data);
    },
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
      this.detailPopupVisible = false;
      this.manageInfoPopupVisible = false;
    },
    // 表格行内查看事件
    handleRowDetail(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "详情";
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "编辑";
    },
    handleRowManageInfo(row) {
      this.manageInfoPopupVisible = true;
      this.rowData = row;
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/project/subplan/del/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    // 快速配置WBS基本信息
    handleRowInitTaskResolve(row) {
      this.$emit("handleTaskResolve", row);
    },
    handleRowEditRecord(row) {
      this.rowData = row;
      this.detailPopupVisible = true;
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase, type) {
      let flag = false;
      let flag1 = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      this.$refs.addPop.$refs.addPopForm1.$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag1 = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      let params1 = this.$refs.addPop.$refs.addPopForm1.formData;
      params = {
        ...params1,
        ...params,
      };
      if (flag && flag1) {
        if (this.rowData != null) {
          params.id = this.rowData.id;
          putApi(`/project/assign/update/subAssign`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
              this.getLeftTree();
            }
          });
        } else {
          postApi(`/project/assign/add/subAssign/${this.rowId}`, params).then(
            (res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message.success("保存成功");
                this.handleClose();
                this.getTableData();
                this.getLeftTree();
              }
            }
          );
        }
      }
    },
    handleManageSave(formName, formNameBase) {
      let flag = false;
      this.$refs.manageInfoPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.manageInfoPop.$refs[formName].formData;
      params.id = this.$refs.manageInfoPop.detailData.id;
      if (flag) {
        putApi(`/project/assign`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("修改成功");
            this.handleClose();
            this.getTableData();
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tab-container {
  height: 100%;
  display: flex;
  .left-tree {
    width: 20%;
    height: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    border-right: 5px solid #e7eaec;
    overflow-y: auto;
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: @fs14;

      .node-hanlde {
        color: @defaultFontColor;
        i {
          margin: 0 5px;
        }
      }
    }
  }
  .right-table {
    width: 80%;
    height: 100%;
    .table-tab {
      /deep/ .card-contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        .el-tabs {
          width: 100%;
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
    }
  }
}
</style>
