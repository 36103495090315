<template>
  <div class="detail-container">
    <dd-card title="审批流程" spacer>
      <el-steps :active="activeIndex" align-center finish-status="success">
        <el-step
          v-for="(val, idx) in activityList"
          :key="idx"
          :title="val.activityName"
        >
          <div slot="description" class="description">
            <div
              v-for="(item, index) in val.description"
              :key="index"
              class="list"
            >
              <div>{{ item.time }}</div>
              <div class="name">{{ item.userName }} 已审核</div>
              <div>意见 {{ item.fullmessage }}</div>
            </div>
          </div>
        </el-step>
      </el-steps>
      <dd-form :form-config="formTaskBasicList" ref="taskBasicForm"> </dd-form>
    </dd-card>
    <dd-card title="基本信息">
      <dd-form
        :form-config="formList"
        :flex="2"
        :formRef="formBasicRef"
        ref="expenseBasicForm"
      >
      </dd-form>
    </dd-card>
    <!-- <dd-card title="费用明细">
      <dd-form
        :form-config="formDetailList"
        :flex="3"
        :formRef="formDetailRef"
        ref="expenseDetailForm"
      ></dd-form>
      <el-link
        type="warning"
        :underline="false"
        icon="el-icon-info"
        style="margin-left: 50px"
        >该笔费用的具体用途(时间，地点、数量、规格、型号、具体名称、用途)</el-link
      >
    </dd-card> -->
    <!-- <dd-card title="附件查看">
      <div v-if="AttachFilesImage.length" class="ibox-content">
        <div>
          <el-image
            v-for="(item, index) in AttachFilesImage"
            :key="index"
            style="
              width: 100px;
              height: 90px;
              margin-right: 10px;
              nargin-bottom: 5px;
            "
            :src="item"
            :preview-src-list="AttachFilesImage"
          ></el-image>
        </div>
      </div>
      <div v-else class="ibox-content" style="height: 20px; overflow: auto">
        <div>暂无附件</div>
      </div>
    </dd-card> -->
    <dd-card title="审批流程">
      <dd-table
        ref="expenseDetail"
        :topBtn="false"
        :isSelection="false"
        :isSort="true"
        :columns="tableColumns"
        :data="tableDataSources"
        border
      ></dd-table>
    </dd-card>
  </div>
</template>
  
<script>
import { formatDate } from "@/utils/date";
import { getApi } from "@/api/request";
import { get } from "ol/proj";

export default {
  name: "detail-pop",
  props: {
    rowData: "",
    expenseType: [],
    billState: [],
  },
  data() {
    return {
      activeIndex: 0,
      formBasicRef: "expenseDetailBasicForm",
      formDetailRef: "expenseDetailDetailForm",
      //附件图片地址url
      AttachFilesImage: [],
      formTaskBasicList: [
        {
          label: "审批意见",
          value: "comment",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          placeholderText: "请输入审批意见",
          rules: [
            {
              required: true,
              message: "请输入审批意见",
              trigger: "blur",
            },
          ],
        },
      ],
      formList: [
        {
          label: "申请编号",
          value: "applyCode",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请人",
          value: "applierId",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: this.getText("applier"),
        },
        {
          label: "部门",
          value: "applyDep",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: this.getText("applyDep"),
        },
        {
          label: "费用承担组织",
          value: "expenseBearOrgName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: this.getText("orgName"),
        },
        {
          label: "费用承担部门",
          value: "expenseBearDepName",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "deptId",
            name: "name",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索费用承担部门",
        },
        {
          label: "申请时间",
          value: "date",
          inputType: "text",
          placeholderText: "",
          disabled: true,
          defaultValue: this.formatDate(new Date()),
        },
        {
          label: "项目名称",
          value: "projectName",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "projectId",
            name: "projectName",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索项目名称",
        },
        {
          label: "项目编号",
          value: "projectCode",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "projectId",
            name: "projectCode",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索项目编号",
        },
        {
          label: "验证人员工号",
          value: "verifierCode",
          inputType: "select",
          filterable: true,
          disabled: true,
          optionsList: [],
          optionsListConfig: {
            code: "username",
            name: "username",
          },
          defaultValue: "",
          placeholderText: "验证人员工号",
        },
        {
          label: "用车起始日期",
          value: "rentalcarStartDate",
          placeholderText: "用车起始日期",
          inputType: "date",
          dateType: "date",
          defaultValue: this.formatDate(new Date()),
          format: "yyyy-MM-dd",
        },
        {
          label: "用车结束日期",
          value: "rentalcarEndDate",
          placeholderText: "用车结束日期",
          inputType: "date",
          dateType: "date",
          format: "yyyy-MM-dd",
          defaultValue: this.formatDate(new Date()),
        },
        {
          label: "验证人",
          value: "verifierId",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索验证人",
        },
        {
          label: "出发地",
          value: "departurePlace",
          inputType: "text",
          placeholderText: "请输入出发地",
          defaultValue: "",
        },
        {
          label: "目的地",
          value: "destinationPlace",
          inputType: "text",
          placeholderText: "请输入目的地",
          defaultValue: "",
        },
        {},
        {
          label: "起始公里数",
          value: "startKilometer",
          ref: "startKilometer",
          inputType: "text",
          placeholderText: "请输入正整数,最大不超过8位数",
          defaultValue: "",
        },
        {
          label: "结束公里数",
          ref: "endKilometer",
          value: "endKilometer",
          inputType: "text",
          placeholderText: "请输入正整数,最大不超过8位数",
          defaultValue: "",
        },
        {
          label: "用车公里数",
          disabled: true,
          value: "totalKm",
          inputType: "text",
          placeholderText: "自动计算",
          defaultValue: "",
        },
        {
          label: "起始公里图片",
          value: "startImg",
          isAttchment: false,
          action: "/admin/sys-file/upload",
          headers: {},
          accept: "image/*",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          ref: "startImg",
          placeholderText: "点击可替换之前上传图片",
          defaultValue: "",
        },
        {
          label: "结束公里图片",
          value: "endImg",
          isAttchment: false,
          action: "/admin/sys-file/upload",
          headers: {},
          accept: "image/*",
          placeholderText: "点击可替换之前上传图片",
          ref: "endImg",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          defaultValue: "",
        },
        {
          label: "付款证明图片",
          value: "payImg",
          isAttchment: false,
          action: "/admin/sys-file/upload",
          headers: {},
          accept: "image/*",
          placeholderText: "点击可替换之前上传图片",
          ref: "payImg",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          defaultValue: "",
        },
        {
          label: "基础租车费用",
          value: "basicFee",
          ref: "basicFee",
          inputType: "text",
          placeholderText: "请输入两位小数",
          defaultValue: "",
        },
        {
          label: "超出公里数追加费用",
          ref: "additionalFee",
          value: "additionalFee",
          inputType: "text",
          placeholderText: "请输入两位小数",
          defaultValue: "",
        },
        {
          label: "租车计价说明",
          value: "pricingInstruction",
          inputType: "text",
          placeholderText: "",
          defaultValue: "",
        },
        {
          label: "租车金额",
          disabled: true,
          value: "rentalcarAmount",
          inputType: "text",
          placeholderText: "自动计算",
          defaultValue: "",
        },
        {},
        {
          label: "车辆行驶证",
          value: "driverLicenseImg",
          isAttchment: false,
          action: "/admin/sys-file/upload",
          headers: {},
          accept: "image/*",
          placeholderText: "点击可替换之前上传图片",
          ref: "driverLicenseImg",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleRemove,
          successEvent: (res) => {
            this.handleFileSuccess(res, "driverLicenseImg");
          },
          defaultValue: "",
        },
        {
          label: "驾驶证/身份证",
          value: "driversLicenseImg",
          isAttchment: false,
          action: "/admin/sys-file/upload",
          headers: {},
          accept: "image/*",
          placeholderText: "点击可替换之前上传图片",
          ref: "driversLicenseImg",
          limit: 1,
          inputType: "upload",
          pictureType: "picture-card",
          removeEvent: this.handleRemove,
          successEvent: (res) => {
            this.handleFileSuccess(res, "driversLicenseImg");
          },
          defaultValue: "",
        },
        {
          label: "用车事宜",
          style: "width:100%",
          value: "rentalcarMatter",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入用车事宜",
          defaultValue: "",
        },
        {
          label: "备注",
          value: "remark",
          style: "width:100%",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入备注",
          defaultValue: "",
        },
      ],
      //审批流程表头
      tableColumns: [
        {
          title: "节点名称",
          key: "taskName",
          align: "center",
        },
        {
          title: "操作时间",
          key: "time",
          align: "center",
        },
        {
          title: "节点操作人",
          key: "userName",
          align: "center",
        },
        {
          title: "审批意见",
          key: "fullmessage",
          align: "center",
        },
      ],
      //审批流程数据
      tableDataSources: [],
      detailDataSourcrs: null,
      activityList: [],
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //获取数据
    getDataList() {
      getApi(`/activiti/process/viewPage/${this.rowData.proInstanceId}`).then(
        (res) => {
          if (res.data.code == 0) {
            this.activityList = res.data.data;
            this.activityList.forEach((item, index) => {
              if (item.isCurrentActiviti == 1) {
                this.activeIndex = index + 1;
              }
            });
          }
        }
      );
      getApi(
        `/hr/rentalcar/applysheet/getByProInsId/${this.rowData.proInstanceId}`
      ).then((res) => {
        let { data } = res;
        if (data.code == 0 && data.data) {
          if (data.data) {
            this.detailDataSourcrs = data.data;
            this.tableDataSources = data.data.approveList;
            this.formList.forEach((item) => {
              item.defaultValue = data.data[item.value];
              item.disabled = true;
              item.rules = [];
              if (item.value == "date") {
                item.defaultValue = data.data.applyTime;
              } else if (item.value == "totalKm") {
                item.defaultValue = data.data.useKilometer;
              } else if (item.value == "applierId") {
                item.defaultValue = data.data.applierName;
              } else if (item.value == "verifierId") {
                item.defaultValue = data.data.verifierName;
              } else if (item.value == "applyDep") {
                item.defaultValue = sessionStorage.getItem("depName");
              } else if (item.value == "approvedAmount") {
                if (this.type == "approve") {
                  item.defaultValue = data.data.rentalcarAmount;
                }
              }
            });
            data.data.hrRentalcarApplySheetFileList.forEach((item) => {
              if (item.sign == "begin") {
                this.formList[18].defaultValue = item.url;
              }
              if (item.sign == "end") {
                this.formList[19].defaultValue = item.url;
              }
              if (item.sign == "pay") {
                this.formList[20].defaultValue = item.url;
              }
              if (item.sign == "driving") {
                this.formList[26].defaultValue = item.url;
              }
              if (item.sign == "identity") {
                this.formList[27].defaultValue = item.url;
              }
              if (item.sign == "other") {
                //修改回显
                if (this.type == "update") {
                  this.fileList.push({
                    name: item.name,
                    url: item.url,
                  });
                }
                let fileSuffix = item.name.substring(
                  item.name.lastIndexOf(".")
                );
                let obj = {};
                obj.url = "/admin/sys-file/ssh/" + item.fileName;
                obj.fileName = item.name.substring(
                  0,
                  item.name.lastIndexOf(".")
                );
                obj.fileType = fileSuffix.substring(1, fileSuffix.length);
                this.AttachFilesImage.push(obj.url);
                this.AttachFilesOther.push(obj);
              }
            });
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    getText(value) {
      if (value == "applier") {
        return sessionStorage.getItem("fullName");
      } else if (value == "applyDep") {
        return sessionStorage.getItem("depName");
      } else if (value == "orgName") {
        return sessionStorage.getItem("orgName");
      }
    },
    // 格式化日期
    formatDate(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
  },
};
</script>
  <style lang='less' scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
/deep/ .el-step__description {
  display: flex;
  justify-content: center;
  padding-left: 10% !important;
  padding-right: 10% !important;
}
.description {
  .list {
    display: flex;
    align-items: center;
    .name {
      margin: 0 10px;
    }
  }
}
</style>
  