import { render, staticRenderFns } from "./AuditPop.vue?vue&type=template&id=12ffe57f&scoped=true"
import script from "./AuditPop.vue?vue&type=script&lang=js"
export * from "./AuditPop.vue?vue&type=script&lang=js"
import style0 from "./AuditPop.vue?vue&type=style&index=0&id=12ffe57f&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ffe57f",
  null
  
)

export default component.exports