/*
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-03-24 15:29:14
 * @LastEditors: hechen
 * @LastEditTime: 2023-03-24 15:48:02
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-finance-ui-base\src\components\autoRegister.js
 * @Descripttion: 全局组件自动注册
 * 全局组件各个组件按文件夹区分，文件夹名称与组件名无关联，但建议与组件名保持一致
 * 文件夹内至少保留一个文件名为 index 的组件入口，例如 index.vue
 * 组件必须设置 name 并保证其唯一，自动注册会将组件的 name 设为组件名，统一采用 dd-组件名
 */
 import Vue from 'vue'
 const componentsContext = require.context('./',true,/index.(vue|js)$/)
 componentsContext.keys().forEach(file_name => {
     // 获取文件中的 default 模块 (若是.vue文件，则componentConfig就是一个vue组件)
     const componentConfig = componentsContext(file_name).default
     if((/.vue$/).test(file_name)){
        //vue文件
         Vue.component(componentConfig.name,componentConfig)//组件全局注册： Vue.component(注册的名称,vue组件)
     }else{ 
        //js文件
         Vue.use(componentConfig)
     }
     
 });
 