import { render, staticRenderFns } from "./batchPop.vue?vue&type=template&id=62cef035&scoped=true"
import script from "./batchPop.vue?vue&type=script&lang=js"
export * from "./batchPop.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62cef035",
  null
  
)

export default component.exports