<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    dictType: [],
    paramType: [],
    statusType: [],
    rowData: [],
  },
  data() {
    return {
      formBasicList: [
        {
          label: "业务类型",
          value: "bussTypeId",
          inputType: "cascader",
          filterable:true,
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
          },
          showAllSelcet: false,
          placeholderText: "请选择业务类型",
          rules: [
            {
              required: true,
              message: "请选择业务类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "区域节点类型",
          value: "areaCodeId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择区域节点类型",
          optionsListConfig: {
            name: "nodeName",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择区域节点类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "工作包",
          value: "nodeWorkId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择工作包",
          optionsListConfig: {
            name: "nodeName",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择工作包",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {
    if (this.rowData != null) {
      this.formBasicList.forEach((item) => {
        item.defaultValue = this.rowData[item.value];
      });
    }
    this.getDataType();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    loopData(data) {
      if(data && data.length) {
        data.forEach(item => {
          if(item?.children && item?.children?.length > 0) {
            item.disabled = true;
            this.loopData(item.children)
          }
        })
      }
      return data;
    },
    // 获取数据字典类型
    getDataType() {
      // 获取业务类型列表数据
      getApi(`/project/prjareasubassginbussitype/tree`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let arr = this.loopData(data.data)
          let options = {
            value: "bussTypeId",
            optionsList: arr,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });

      // 获取区域节点类型列表数据
      getApi(`/project/prjareanode/list`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "areaCodeId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      // 工作包列表
      getApi(`/project/prjareastandardnodework/list`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "nodeWorkId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
