<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-form :form-config="formBasicList" :flex="3" ref="addPopForm">
        </dd-form>
      </dd-card>
    </div>
    <dd-card title="目录列表">
      <dd-table :columns="tableColumns" :data="tableData" max-height="350" border isSelection show-page :loading="loading"
        @select-change="handleTableSelect">
        <template #table-pagination>
          <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
            @page-change="handleCurrentChange" align="right">
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
  </div>
</template>
  
<script>
import { getApi} from "@/api/request";
export default {
  components: {
  },
  computed: {
  },
  data() {
    return {
      loading: true,
      formData: {},
      formBasicList: [
        {
          label: "区域名",
          value: "areaId",
          disabled: false,
          defaultValue: '',
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择区域名",
          optionsListConfig: {
            name: "areaName",
            code: "id",
          },
          ref: 'areaId',
          rules: [
            {
              required: true,
              message: "请选择区域名",
              trigger: "blur",
            },
          ],
          change: (e) => {
            this.getUserList(e)
          },
        },
        {
          label: "责任人",
          value: "userId",
          disabled: false,
          defaultValue: '',
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择责任人",
          optionsListConfig: {
            name: "username",
            code: "userId",
          },
          ref: 'userId',
          rules: [
            {
              required: true,
              message: "请选择责任人",
              trigger: "blur",
            },
          ],
        },
        {
          label: "项目类型",
          value: "typeId",
          disabled: false,
          defaultValue: '',
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择项目类型",
          optionsListConfig: {
            name: "typeName",
            code: "id",
          },
          ref: 'typeId',
          rules: [
            {
              required: true,
              message: "请选择项目类型",
              trigger: "blur",
            },
          ],
          change: (e) => {
            console.log(e)
            this.getTableData(e)
            this.typeId = e;
          },
        }
      ],
      tableColumns: [
        {
          title: "目录名称",
          key: "name",
          align: "center",
        }
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      selection: [],
      typeId: null
    };
  },
  created() { },
  mounted() {
    this.getDataType();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      getApi(`/project/catalogarea/page?current=1&size=999`).then((res) => {
        let { data } = res;
        if (data && data.code == 0 && data.data) {
          let options = {
            value: "areaId",
            optionsList: data.data.records,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });

      getApi(`/project/catalogtype/page?current=1&size=999`).then((res) => {
        let { data } = res;
        if (data && data.code == 0 && data.data) {
          let options = {
            value: "typeId",
            optionsList: data.data.records,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      
    },
    getUserList(id) {
      getApi(`/project/catalogarea/area/user/page?current=1&size=999&areaId=${id}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0 && data.data) {
          this.formBasicList.forEach((item) => {
            if(item.value == "areaId") {
              item.defaultValue = id
            }
            if(item.value == "userId") {
              item.optionsList = data.data.records
            }
            if(item.value == "typeId") {
              item.defaultValue = this.typeId
            }
          })
        }
      });
    },
    // 获取表格列表数据
    getTableData(id) {
      let { current, size } = this;
      let params = {
        current,
        size,
        typeId: id
      };
      this.loading = true;
      getApi(`/project/catalogtypedir/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    handleTableSelect(selection) {
      this.selection = selection;
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
  },
};
</script>
  
<style lang='less' scoped>
.main-container {
  display: block !important;
}
</style>