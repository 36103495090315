<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" :flex="2" ref="addPopForm"> </dd-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowData: [],
  },
  data() {
    return {
      formBasicList: [
        {
          label: "分管部门",
          value: "fenDeptId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择分管部门",
              trigger: "blur",
            },
          ],
          change: this.getFenDeptSelect,
        },
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择部门",
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: true,
              message: "请选择部门",
              trigger: "blur",
            },
          ],
          change: this.getPersonList,
        },
        // {
        //   label: "子部门",
        //   value: "branchDeptIds",
        //   inputType: "select",
        //   optionsList: [],
        //   filterable: false,
        //   multiple: true,
        //   placeholderText: "请选择子部门",
        //   optionsListConfig: {
        //     name: "name",
        //     code: "id",
        //   },
        //   defaultValue: [],
        // },
        // {
        //   label: "核算组编号",
        //   value: "accountCode",
        //   inputType: "text",
        //   placeholderText: "请输入核算组编号",
        //   disabled: false,
        //   defaultValue: "",
        //   rules: [
        //     {
        //       required: true,
        //       message: "请输入核算组编号",
        //       trigger: "blur",
        //     },
        //   ],
        // },
        {
          label: "核算组名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入核算组名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入核算组名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "负责人",
          value: "leaderUserId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          defaultValue: "",
          placeholderText: "请选择负责人",
          optionsListConfig: {
            name: "fullName",
            code: "userId",
          },
          rules: [
            {
              required: true,
              message: "请选择负责人",
              trigger: "blur",
            },
          ],
        },
        {
          label: "排序",
          key: "menu",
          value: "sortOrder",
          inputType: "number",
          min: 0,
          max: 999,
          placeholderText: "请输入排序",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入排序",
              trigger: "blur",
            },
          ],
        },
        {
          label: "核算组说明",
          value: "remark",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入核算组说明",
          disabled: false,
          defaultValue: "",
        },
      ],
      detail:{}
    };
  },
  created() {},
  mounted() {
    this.getFenDeptList();
    if (this.rowData != null) {
      this.getDataDetail(this.rowData.id);
    }
  },
  methods: {
    // 获取分管部门
    getFenDeptList(){
      getApi(`/admin/sysfendept/getList`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "fenDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    // 根据分管部门查询部门
    getFenDeptSelect(fenDeptId){      getApi(`/admin/dept/getbydepid/${fenDeptId}`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "fenDeptId") {
              item.defaultValue = fenDeptId;
            } else {
              if(fenDeptId != this.detail.fenDeptId){
                if (item.value == "deptId") {
                  item.defaultValue = '';
                }
                if (item.value == "leaderUserId") {
                  item.defaultValue = '';
                }
              }
              else{
                item.defaultValue = this.$refs.addPopForm.formData[item.value];
              }
            }
            if (item.value == "deptId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 修改部门 联动 负责人列表
    getPersonList(deptId) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId: deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "deptId") {
              item.defaultValue = deptId;
            } else {
              if(deptId != this.detail.deptId){
                if (item.value == "leaderUserId") {
                  item.defaultValue = '';
                }
              }else{
                item.defaultValue = this.$refs.addPopForm.formData[item.value];
              }
            }
            if (item.value == "leaderUserId") {
              item.optionsList = data.data;
            }
          });
        }
      });
      postApi(`/admin/sysdeptbranch/list`, {
        deptId: deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "deptId") {
              item.defaultValue = deptId;
            } else {
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
            if (item.value == "branchDeptIds") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取详情
    getDataDetail(id) {
      getApi(`/project/accounting/${id}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.detail = data.data;
          this.getFenDeptList();
          this.getFenDeptSelect(data.data.fenDeptId)
          this.getPersonList(data.data.deptId);
          this.formBasicList.forEach((item) => {
            item.defaultValue = data.data[item.value];
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
