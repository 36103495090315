<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm" :flex="2"> </dd-form>
  </div>
</template>
  
  <script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    dictType: [],
    rowData: [],
    type: "",
  },
  data() {
    return {
      formBasicList: [
        {
          label: "上级区域",
          value: "parentId",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
          },
          showAllSelcet: false,
          defaultValue: "",
          placeholderText: "请选择上级区域",
          rules: [
            {
              required: true,
              message: "请选择上级区域",
              trigger: "blur",
            },
          ],
        },
        {
          label: "区域类型",
          value: "typeCode",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择区域类型",
          change: this.handleChange,
          optionsListConfig: {
            name: "label",
            code: "value",
          },
          rules: [
            {
              required: true,
              message: "请选择区域类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "区域代码",
          value: "code",
          inputType: "text",
          placeholderText: "请输入区域代码",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入区域代码",
              trigger: "blur",
            },
          ],
        },
        {
          label: "区域名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入区域名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入区域名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "区域范围",
          value: "rangeCode",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择区域范围",
          optionsListConfig: {
            name: "label",
            code: "value",
          },
          rules: [
            {
              required: true,
              message: "请选择区域范围",
              trigger: "blur",
            },
          ],
        },
        {
          label: "是否可选",
          value: "whetherCode",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择是否可选",
          optionsListConfig: {
            name: "label",
            code: "value",
          },
          change: this.handleChange,
          rules: [
            {
              required: true,
              message: "请选择是否可选",
              trigger: "blur",
            },
          ],
        },
        {
          label: "状态",
          value: "stateCode",
          inputType: "select",
          show: false,
          optionsList: [],
          filterable: false,
          placeholderText: "请选择状态",
          optionsListConfig: {
            name: "label",
            code: "value",
          },
          rules: [
            {
              required: true,
              message: "请选择状态",
              trigger: "blur",
            },
          ],
        },
        {
          label: "负责部门",
          value: "deptId",
          inputType: "select",
          show: false,
          optionsList: [],
          filterable: false,
          placeholderText: "请选择负责部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          change: this.handleChange,
          rules: [
            {
              required: true,
              message: "请选择负责部门",
              trigger: "blur",
            },
          ],
        },
        {
          label: "销售负责人",
          value: "leadersList",
          inputType: "select",
          show: false,
          optionsList: [],
          filterable: true,
          placeholderText: "请选择区域销售负责人",
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          multiple: true,
          rules: [
            {
              required: true,
              message: "请选择区域销售负责人",
              trigger: "blur",
            },
          ],
        },
        {
          label: "子部门",
          value: "deptBranchId",
          inputType: "select",
          show: false,
          optionsList: [],
          filterable: false,
          placeholderText: "请选择子部门",
          optionsListConfig: {
            code: "id",
            name: "name",
          },
        },
        {
          label: "关联行政区",
          value: "relationList",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
            multiple: true,
          },
          showAllSelcet: false,
          defaultValue: "",
          placeholderText: "请选择关联行政区",
          rules: [
            {
              required: true,
              message: "请选择关联行政区",
              trigger: "blur",
            },
          ],
        },
        {},
        {
          label: "备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          autosize: { minRows: 4 },
          placeholderText: "请输入内容",
          title: true,
          defaultValue: "",
        },
      ],
      deptTree: [],
    };
  },
  created() {},
  mounted() {
    if (this.dictType) {
      let newTree = [
        {
          name: "根菜单",
          id: "-1",
          children: this.dictType,
        },
      ];
      this.formBasicList.forEach((item) => {
        if (item.value == "parentId") {
          item.optionsList = newTree;
        }
      });
    }

    if (this.type == "update") {
      let leadersList = [];
      let relationList = [];
      this.rowData.leadersList.map((item) => {
        leadersList.push(item.leaderUserId);
      });
      this.rowData.relationList.map((item) => {
        relationList.push(item.regionCode);
      });
      this.rowData.leadersList = leadersList;
      this.rowData.relationList = relationList;
      if (this.rowData.whetherCode == 1) {
        this.getPersonList(this.rowData.deptId);
        this.getSubDeptList(this.rowData.deptId);
      }
      this.formBasicList.forEach((item) => {
        if (this.rowData.whetherCode == 1) {
          if (
            item.value == "stateCode" ||
            item.value == "deptId" ||
            item.value == "deptBranchId" ||
            item.value == "leadersList"
          ) {
            item.show = true;
          }
        }
        item.defaultValue = this.rowData[item.value];
      });
    } else if (this.type == "add") {
      this.formBasicList.forEach((item) => {
        if (item.value == "parentId") {
          item.disabled = true;
          item.defaultValue = this.rowData.parentId;
        }
      });
    }
    this.getListData();
    this.getDeptList();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //获取下拉数据
    getListData() {
      //区域类型
      getApi(`/admin/dict/key/TYPE-REGION-QULX`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "typeCode",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      //区域范围
      getApi(`/admin/dict/key/TYPE-REGION-QUFW`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "rangeCode",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      //是否可选
      getApi(`/admin/dict/key/type-whether`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "whetherCode",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      //状态
      getApi(`/admin/dict/key/type-data-status`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "stateCode",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      //行政区划
      getApi(`/admin/region/tree`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          // this.addDisabledToLevels(data.data);
          let options = {
            value: "relationList",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    //添加禁用
    // addDisabledToLevels(data) {
    //   data.forEach((item) => {
    //     if (item.children && item.children.length > 0) {
    //       this.addDisabledToLevels(item.children); // 递归处理子级数据
    //       item.disabled = true; // 添加 disabled 属性
    //     } else {
    //       item.disabled = false; // 最后一级数据不添加 disabled 属性
    //     }
    //   });
    // },
    //获取部门列表
    getDeptList() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    //负责人列表
    getPersonList(deptId) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "leadersList",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    //子部门列表
    getSubDeptList(deptId) {
      postApi(`/admin/sysdeptbranch/list`, {
        deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptBranchId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    handleChange(e, type) {
      let { formData } = this.$refs.addPopForm;
      console.log(e, type);
      this.formBasicList.forEach((item) => {
        item.defaultValue = formData[item.value];
        if (type == "whetherCode") {
          if (
            item.value == "stateCode" ||
            item.value == "deptId" ||
            item.value == "deptBranchId" ||
            item.value == "leadersList"
          ) {
            item.show = e == "1" ? true : false;
            if (e == 0) {
              item.defaultValue = null;
            }
          }
        } else if (type == "typeCode") {
          if (item.value == "relationList") {
            item.rules =
              e == "TYPE-REGION-FDLQY"
                ? []
                : [
                    {
                      required: true,
                      message: "请选择关联行政区",
                      trigger: "blur",
                    },
                  ];
          }
        }
      });
      if (type == "deptId") {
        if (e == "") {
          let { formData } = this.$refs.addPopForm;
          this.formBasicList.forEach((item) => {
            if (item.value == "leadersList" || item.value == "deptBranchId") {
              item.defaultValue = [];
              item.optionsList = [];
            } else {
              item.defaultValue = formData[item.value];
            }
          });
          return;
        }
        this.getPersonList(e);
        this.getSubDeptList(e);
      }
    },
  },
};
</script>
  <style lang='less' scoped>
</style>
  