<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>
  
  <script>
import { postApi, getApi, loadApi, delApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    type: null,
    id: null,
  },
  data() {
    return {
      formBasicList: [
        {
          label: "子部门编码",
          value: "code",
          inputType: "text",
          placeholderText: "新增后自动生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "部门名称",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索部门名称",
          rules: [
            { required: true, message: "请选择部门名称", trigger: "blur" },
          ],
          change: this.handleChange,
        },
        {
          label: "子部门名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入子部门名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入子部门名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "子部门类型",
          value: "deptBranchTypeCodesList",
          inputType: "select",
          optionsList: [],
          multiple: true,
          optionsListConfig: {
            code: "value",
            name: "label",
          },
          defaultValue: [],
          placeholderText: "请选择子部门类型",
          rules: [
            { required: true, message: "请选择子部门类型", trigger: "blur" },
          ],
        },
        {
          label: "子部门负责人",
          value: "leaderId",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          filterable: true,
          defaultValue: "",
          placeholderText: "请选择或搜索子部门负责人",
          rules: [
            {
              required: true,
              message: "请选择子部门负责人",
              trigger: "blur",
            },
          ],
        },
        {
          label: "排序",
          value: "sortOrder",
          inputType: "number",
          min: 0,
          max: 999,
          placeholderText: "请输入排序",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入排序",
              trigger: "blur",
            },
          ],
        },
        {
          label: "备注",
          value: "description",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入备注",
          disabled: false,
          defaultValue: "",
        },
      ],
      deptTree: [],
    };
  },
  created() {},
  mounted() {
    this.getDeptList();
    this.getDict();
    console.log(this.type, this.id);
    if (this.type == "update") {
      this.getDetailInfo();
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //详情
    getDetailInfo() {
      getApi(`/admin/sysdeptbranch/${this.id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let list = [];
          data.data.deptBranchTypeList.map((item) => {
            list.push(item.code);
          });

          this.formBasicList.forEach((item) => {
            if (item.value == "deptBranchTypeCodesList") {
              item.defaultValue = list;
            } else {
              item.defaultValue = data.data[item.value];
            }
          });
          this.getPersonList(data.data.deptId);
        }
      });
    },
    //子部门类型
    getDict() {
      getApi(`/admin/dict/key/TYPE-DEPT-BRANCH`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptBranchTypeCodesList",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    //获取部门列表
    getDeptList() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    //负责人列表
    getPersonList(deptId) {
      postApi(`/admin/user/getuserlist/bydeptid`, {
        deptId: deptId,
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "leaderId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    handleChange(e, type) {
      console.log(e, type);
      let { model } = this.$refs.addPopForm.$refs.baseForm;
      this.formBasicList.forEach((item) => {
        if (item.value == "leaderId") {
          item.defaultValue = "";
        } else {
          item.defaultValue = model[item.value];
        }
      });
      if (type == "deptId") {
        this.getPersonList(e);
      }
    },
  },
};
</script>
  <style lang='less' scoped>
</style>
  