<template>
    <div class="main-container">
        <dd-form :form-config="formBasicList" ref="addPopForm">
        </dd-form>
    </div>
  </template>
  
  <script>
  export default {
    name: "add-pop",
    props: {
      dictType: [],
      rowData: []
    },
    data() {
      return {
        formBasicList: [
          {
            label: "租户编号",
            value: "code",
            inputType: "text",
            placeholderText: "请输入租户编号",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入租户编号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "租户代码",
            value: "shortCode",
            inputType: "text",
            placeholderText: "请输入租户代码",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入租户代码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "租户名称",
            value: "tenantName",
            inputType: "text",
            placeholderText: "请输入租户名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入租户名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "状态",
            value: "status",
            inputType: "select",
            optionsList: [],
            filterable: false,
            placeholderText: "请选择状态",
            optionsListConfig: {
              name: "label",
              code: "value",
            },
            rules: [
              {
                required: true,
                message: "请选择状态",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "联系人",
          //   value: "phone",
          //   inputType: "text",
          //   placeholderText: "请输入联系人",
          //   disabled: false,
          //   defaultValue: ''
          // },
          // {
          //   label: "联系电话",
          //   value: "phone",
          //   inputType: "text",
          //   placeholderText: "请输入联系电话",
          //   disabled: false,
          //   defaultValue: ''
          // },
          // {
          //   label: "联系地址",
          //   value: "phone",
          //   inputType: "text",
          //   placeholderText: "请输入联系地址",
          //   disabled: false,
          //   defaultValue: ''
          // },
          {
            label: "备注",
            value: "remark",
            inputType: "text",
            placeholderText: "请输入备注",
            disabled: false,
            defaultValue: ''
          },
        ],
      };
    },
    created() {
    },
    mounted() {
      let options1 = {
            value: "status",
            optionsList: this.dictType,
        };
        this.setOPtionsList(this.formBasicList, options1);
        if(this.rowData != null) {
            this.formBasicList.forEach(item => {
                item.defaultValue = this.rowData[item.value]
            })
        }
    },
    methods: {
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.optionsList = options.optionsList;
                    }
                }
            });
        },
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
  