<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form :form-config="formList" :labelWidth="'190px'" @search-click="handleSearchEvent"></dd-search-form>
    </dd-card>
    <dd-card title="金蝶预算列表" :height="tableHeight">
      <dd-table :columns="columnsList" :data="tableData" border ref="dTableRef" :loading="loading"
         show-page  :isSelection="false" :isSelectable="false"
       >
       <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination :total="total" :page-size="size" :page-number="current" @page-change="handleCurrentChange"
            align="right">
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <!-- 详情 -->
    <dd-popup v-if="dialogEditDetailVisible" width="70%" height="80%" showBtn :saveBtn="false"
      :submitBtn="showSubmitBtn" :show="dialogEditDetailVisible" :title-name="EditDetailTitle"
      @popup-close="dialogEditDetailVisible = false">
      <DecomposeEditDetail ref="DecomposeEdit" slot="main" 
        :propDetail="propDetail" :pageType="pageType" :type="type"></DecomposeEditDetail>
    </dd-popup>


    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import { postApi, loadApi, getApi } from "@/api/request";
import DecomposeEditDetail from "../Decompose/components/DecomposeEditDetail.vue";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
export default {
  name: "kingdee",
  $dynamicRoute: {
    name: " 金蝶预算表",
    path: "/finance/budget/kingdee",
    meta: {
      keepAlive: true,
    },
  },
  components: { DecomposeEditDetail },
  data() {
    return {
      searchObj: {},
      drawerVisible: false,
      tableHeight: null,
      loading: false,
      EditDetailTitle: '',
      dialogkingDeeVisible: false,
      dialogEditDetailVisible: false,
      showSubmitBtn: false,
      type: "edit",
      pageType: 'kingdee',
      propDetail: {},
      tableData: [],
      formList: [
        {
          label: "单据编码",
          value: "code",
          inputType: "text",
          placeholderText: "请输入单据编码",
        },
        {
          label: "项目名称或编码",
          value: "projectName",
          inputType: "text",
          placeholderText: "请输入项目名称或编码",
        },
        {
          label: "状态",
          value: "isEnable",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择启用状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "单据编码",
          key: "code",
          align: "center",
          width: 200
        },
        {
          title: "项目编码",
          key: "projectCode",
          align: "center",
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          width: 500
        },
        {
          title: "预算总金额",
          key: "totalAmount",
          align: "center",
        },
        {
          title: "版本",
          key: "versionCode",
          align: "center",
        },
        {
          title: "状态",
          key: "isEnableName",
          align: "center",
        },
        {
          title: "编制人",
          key: "applyUser",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 120,
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetail(row);
              },
            },
          ],
        },
      ],
      columnsList: [],
      current: 1, // 当前页码
      total: 0, // 总条数
      size: 10, // 每页的数据条数
    };
  },
  created() {
    this.getIsEnable();
    this.getTableData();
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    getTableData(obj) {
      const params = {
        current: this.current,
        size: this.size,
        ...obj
      };
      getApi('/finance/finbudgetkingdee/page', params).then(res => {
        if(res.data.code == 0) {
          let { data } = res;
          this.total = data.data.total;
          this.tableData = data.data.records;
        }
      })
    },
    edit() {
      this.dialogEditDetailVisible = true;
      this.EditDetailTitle = '编辑'
    },
    handleDetail(row) {
      this.dialogEditDetailVisible = true;
      this.propDetail.id = row.id;
      this.EditDetailTitle = '详情'
    },
    handleSearchEvent(val) {
      this.searchObj = val;
      this.getTableData(val);
    },
    handleSubmit() {

    },
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData(this.searchObj);
    },
    //   
    getIsEnable() {
      postApi(`/finance/dict/list/all`, {typeCode:  "xmysqyzt"}).then(res => {
        if(res.data.code == 0) {
          this.formList.forEach(item => {
            if(item.value == 'isEnable') {
              item.optionsList = res.data.data;
            }
          })
        }
      })
    },
  },
};
</script>

<style lang="less" scoped></style>