<template>
  <div class="container">
    <dd-card title="审批流程">
      <el-steps
        :active="activeIndex"
        align-center
        :finish-status="
        activeIndex == activityList.length ? 'success' : 'finish'
        "
      >
        <el-step
          v-for="(val, idx) in activityList"
          :key="idx"
          :title="val.activityName"
        >
          <div slot="description" class="description">
            <div
              v-for="(item, index) in val.description"
              :key="index"
              class="list"
            >
              <div class="">
                {{ item.time }}
                <div class="name">{{ item.userName }} {{ item.message }}</div>
              </div>
              <div>意见 {{ item.fullmessage }}</div>
            </div>
          </div>
        </el-step>
      </el-steps>
      <dd-form
        v-if="!showOpinion"
        :form-config="formTaskBasicList"
        ref="workOutForm"
        formRef="workOutForm"
      >
      </dd-form>
    </dd-card>
    <dd-card title="基本信息">
      <dd-form
        :form-config="formList"
        :labelWidth="'170px'"
        :flex="3"
        ref="assignForm"
        formRef="assignForm"
      ></dd-form>
    </dd-card>
    <dd-card v-if="budgetType == 'CW-YSBZ-YSLX-SC'" title="生产预算自定义项">
      <div class="explain">说明：预算输入框数据为红色字体时，所属类型不符合预算基准单标准费用</div>
      <dd-table
            :columns="tableColumns"
             class="table_cell"
            :SetCellClassName="SetCellClassName"
            ref="multipleTable"
            show-summary
            :summary-method="getSummaries"
            :data="tableDataSource"
            :topBtn="false"
            :is-selection="false"
            :loading="loading"
            border
          >
          </dd-table>
    </dd-card>

    <dd-card v-if="budgetType == 'CW-YSBZ-YSLX-SC'" title="生产预算固定项">
      <dd-table
        :columns="tableColumnsFixed"
        :data="tableDataFixed"
        show-summary
        :summary-method="getSummariesFixed"
        :topBtn="false"
        :is-selection="false"
        :loading="loading"
        border
      >
      </dd-table>
    </dd-card>

    <dd-card v-if="budgetType == 'CW-YSBZ-YSLX-XS'" title="销售预算固定项">
          <dd-table
            :columns="tableColumns_xs"
            :data="tableData_xs"
            :topBtn="false"
            :is-selection="false"
            :loading="loading"
            border
          >
          </dd-table>
    </dd-card>

    <dd-card title="">
      <dd-form
        :form-config="PersonList"
        :labelWidth="'170px'"
        :flex="3"
        ref="PersonForm"
        formRef="PersonForm"
      ></dd-form>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import { downloadBinaryFile } from "@/utils/index";
import { mapState, mapActions } from "vuex";

export default {
  name: "WorkOutDetail",
  props: ['rowId', 'showOpinion'],
  data() {
    return {
      activityList: [],
      description: [],
      activeIndex: 0,
      tableColumns_xs: [ // 销售预算固定项表头
        {
          title: "工作内容",
          key: "jobContent",
          align: "center",
        },
        {
          title: "材料办公费",
          key: "materialsOfficeFee",
          align: "center",
          input: [
            {
              isEdit: () => {
              return true;
            },
              rules: (value) => {
                let result = value.replace(/[^\d.]/g, '');
                if (result.charAt(0) === '.') {
                  result = result.slice(1);
                }
                const parts = result.split('.');
                if (parts.length > 2) {
                  result = parts.slice(0, 2).join('.');
                }
                if (parts.length > 1) {
                  result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                }
                return result;
              },
            }
          ]
        },
        {
          title: "外协劳务费",
          key: "outsourcedLaborFee",
          align: "center",
          input: [
            {
              isEdit: () => {
              return true;
            },
              rules: (value) => {
                let result = value.replace(/[^\d.]/g, '');
                if (result.charAt(0) === '.') {
                  result = result.slice(1);
                }
                const parts = result.split('.');
                if (parts.length > 2) {
                  result = parts.slice(0, 2).join('.');
                }
                if (parts.length > 1) {
                  result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                }
                return result;
              },
            }
          ]
        },
        {
          title: "小计",
          key: "subtotal",
          align: "center",
        },
      ],
      tableData_xs: [],
      loading: false,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("authToken") || "",
      }, // 请求头信息
      activeName: "显示全部",
      sumExpenseSubtotalZdy: 0,
      sumExpenseSubtotalGd: 0,
      tableDataFixed: [{jobContent: '检查项'}, {jobContent: '管理项'}],
      formTaskBasicList: [
        {
          label: "审批意见",
          value: "comment",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          placeholderText: "请输入审批意见",
          rules: [
            {
              required: true,
              message: "请输入审批意见",
              trigger: "blur",
            },
          ],
        },
      ],
      tableDataSource: [{
        index: 1,
      }],
      budgetType: '',
      selectTableSource: [],
      PersonList: [
        // 底部编制人 表单
        {
          label: "编制人",
          value: "applierName",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        {
          label: "编制时间",
          value: "applyDate",
          inputType: "date",
          format: "yyyy-MM-dd",
          dateType: "date",
          defaultValue: '',
          disabled: true,
        },
      ],
      tableColumnsFixed: [
        // 固定项表头
        {
          title: "工作内容",
          key: "jobContent",
          align: "center",
        },
        {
          title: "工种",
          key: "workType",
          align: "center",
          width: 160,
          selectInput: {
            placeholderText: "请选择工种",
            options: [],
            isEdit: () => {
              return true;
            },
          },
        },
        {
          title: "项目所需标准工天",
          key: "standardWorkday",
          align: "center",
          width: 170,
          input: [
            {
              isEdit: () => {
                return true;
              },
            }
          ]
        },
        {
          title: "定额百分比",
          key: "quotaPercentage",
          align: "center",
          width: 170,
          isCustom: {
            isTrue: true,
            options1: ()=> {
              return this.optionsJC;
            },
            options2: () => {
              return this.optionsGL;
            },
          },
          selectInput: {
              isEdit: () => {
                let state = false;
                  state = true;
                return true;
              },
            }
        },
        {
          title: "工天",
          key: "workday",
          align: "center",
        },
        {
          title: "费用小计",
          key: "expenseSubtotal",
          align: "center",
        },
        {
          title: "绩效工资",
          align: "center",
          width: 180,
          children: [
            {
              title: "单价",
              key: "meritPayUnivalence",
              align: "center",
              input: [
                {
                  isEdit: () => {
                    let state = false;
                      state = true;
                    return state;
                  },
                },
              ],
            },
            {
              title: "费用",
              key: "meritPayExpense",
              align: "center",
            },
          ],
        },
        {
          title: "基本工资+社保",
          align: "center",
          width: 180,
          children: [
            {
              title: "单价",
              key: "salaryInsuranceUnivalence",
              align: "center",
              input: [
                {
                  isEdit: () => {
                    let state = false;
                      state = true;
                    return state;
                  },
                },
              ],
            },
            {
              title: "费用",
              key: "salaryInsuranceExpense",
              align: "center",
            },
          ],
        },
      ],
      formList: [
        // 基本信息表单配置项
        {
          label: "生产任务书名称",
          value: "assignName",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择生产任务书名称",
          optionsListConfig: {
            name: "assignName",
            code: "assignId",
          },
          disabled: true,
        },
        {
          label: "生产任务书编码",
          value: "assignNum",
          inputType: "text",
          placeholderText: "",
          disabled: true,
        },
        {
          label: "生产预算总额",
          value: "budgetTotalAmount",
          inputType: "text",
          disabled: true,
        },
        {
          label: "销售合同名称",
          value: "contractName",
          inputType: "text",
          disabled: true,
        },
        {
          label: "销售合同编码",
          value: "contractCode",
          inputType: "text",
          disabled: true,
        },
        {
          label: "暂定合同额",
          value: "assignAmount",
          inputType: "text",
          disabled: true,
        },
        {
          label: "版本",
          value: "version",
          inputType: "text",
          disabled: true,
        },
        {
          label: "状态",
          value: "statusName",
          inputType: "text",
          disabled: true,
        },
      ],
      tableColumns: [], // 自定义项表头
      ColumnsConfig: [
        // 自定义项表头无需切换部分配置
        {
          title: "序号",
          key: "index",
          align: "center",
        },
        {
          title: "工作内容",
          key: "jobContent",
          align: "center",
          width: 180,
          input: [
            {
              placeholderText: "请输入工作内容",
              isEdit: () => {
                let state = false;
                  state = true;
                return state;
              },
            },
          ],
        },
        {
          title: "计量单位",
          key: "measureUnit",
          align: "center",
          width: 140,
          selectInput: {
            placeholderText: "请选择计量单位",
            options: [],
            isEdit: () => {
                let state = false;
                  state = true;
                return state;
              },
          },
        },
        {
          title: "总工作量",
          key: "totalEffort",
          width: 120,
          align: "center",
          input: [
            {
              isEdit: () => {
                let state = false;
                  state = true;
                return state;
              },
              rules: (value) => {
                let result = value.replace(/[^\d.]/g, '');
                if (result.charAt(0) === '.') {
                  result = result.slice(1);
                }
                const parts = result.split('.');
                if (parts.length > 2) {
                  result = parts.slice(0, 2).join('.');
                }
                if (parts.length > 1) {
                  result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                }
                return result;
              },
            },
          ],
        },
        {
          title: "工种",
          key: "workType",
          align: "center",
          width: 140,
          selectInput: {
            placeholderText: "请选择工种",
            options: [],
            isEdit: () => {
                let state = false;
                  state = true;
                return state;
              },
          },

        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
          input: [
            {
              isEdit: () => {
                let state = false;
                  state = true;
                return state;
              },
              rules: (value) => {
                let result = value.replace(/[^\d.]/g, '');
                if (result.charAt(0) === '.') {
                  result = result.slice(1);
                }
                const parts = result.split('.');
                if (parts.length > 2) {
                  result = parts.slice(0, 2).join('.');
                }
                if (parts.length > 1) {
                  result = `${parts[0]}.${parts[1].slice(0, 4)}`;
                }
                return result;
              },
            },
          ],
        },
        {
          title: "工天",
          key: "workday",
          align: "center",
        },
        {
          title: "费用小计",
          key: "expenseSubtotal",
          align: "center",
        },
      ],
      ColumnsAllocation: [
        {
          type: "材料办公",
          content: [
            {
              title: "材料办公",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "materialsOfficeUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },
                {
                  title: "费用",
                  key: "materialsOfficeExpense",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },
                {
                  title: "备注",
                  inputTooltip: true,
                  key: "materialsOfficeRemark",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                }
              ],
            },
          ],
        },
        {
          type: "厨具伙食费用",
          content: [
            {
              // 厨具伙食费用表头数据
              title: "厨具伙食费用",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "cookingUtensilsUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },
                {
                  title: "费用",
                  key: "cookingUtensilsExpense",
                  align: "center",
                },
                {
                  title: "备注",
                  inputTooltip: true,
                  key: "cookingUtensilsRemark",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },

              ],
            },
          ],
        },
        {
          type: "车辆租赁费用",
          content: [
            {
              // 车辆租赁费表头数据
              title: "车辆租赁费用",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "vehicleRentalUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },

                  ],
                },
                {
                  title: "费用",
                  key: "vehicleRentalExpense",
                  align: "center",
                },
                {
                  title: "备注",
                  inputTooltip: true,
                  key: "vehicleRentalRemark",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },

              ],
            },
          ],
        },
        {
          type: "其他",
          content: [
            {
              // 其他表头数据
              title: "水电房租",
              align: "center",
              children: [
                {
                  title: "费用",
                  key: "utilityRentExpense",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },
                {
                  title: "备注",
                  inputTooltip: true,
                  key: "utilityRentRemark",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },

              ],
            },
            {
              title: "交通差旅费",
              align: "center",
              children: [
                {
                  title: "费用",
                  key: "travelExpensesExpense",
                  width: "140px",

                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },
              ],
            },
            {
              title: "验收费",
              align: "center",
              children: [
                {
                  title: "费用",
                  key: "inspectionExpense",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "人工费用",
          content: [
            // 人工费用表头数据
            {
              title: "外协劳务费",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "outsourcedLaborUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },
                {
                  title: "费用",
                  key: "outsourcedLaborExpense",
                  align: "center",
                },

              ],
            },
            {
              title: "绩效工资",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "meritPayUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },
                {
                  title: "费用",
                  key: "meritPayExpense",
                  align: "center",
                },

              ],
            },
            {
              title: "基本工资+社保",
              align: "center",
              children: [
                {
                  title: "单价",
                  key: "salaryInsuranceUnivalence",
                  align: "center",
                  input: [
                    {
                      isEdit: () => {
                        let state = false;
                          state = true;
                        return state;
                      },
                    },
                  ],
                },
                {
                  title: "费用",
                  key: "salaryInsuranceExpense",
                  align: "center",
                },

              ],
            },
          ],
        },
      ],
      tabArr: [
        // tabs 配置项 及 表格内容
        {
          name: "材料办公",
        },
        {
          name: "厨具伙食费用",
        },
        {
          name: "车辆租赁费用",
        },
        {
          name: "其他",
        },
        {
          name: "人工费用",
        },
        {
          name: "显示全部",
        },
      ],
    };
  },
  created() {
    this.getUnitList();
    this.getQuotaPercentageOptionGL();
    this.getQuotaPercentageOptionQC();
  },
  mounted() {
    console.log(this.budgetType)
    console.log('this.showOpinion', this.showOpinion)
    this.getPageData();
    this.filterColums();
    this.getStepList();
  },
  methods: {
    getQuotaPercentageOptionGL() {
      let params = {
        typeCode: ""
      }
        params.typeCode = 'CW-GLDEBFB';
        
        postApi(`/finance/dict/list/all`, params).then(res => {
          if(res.data.code == 0) {
            this.optionsGL = res.data.data.map(item => {
              return {
                label: item.name,
                value: Number(item.code),
              }
            })
          }
        })
    },
    getQuotaPercentageOptionQC() {
      let params = {
        typeCode: ""
      }
      params.typeCode = 'CW-DEBFB';
        postApi(`/finance/dict/list/all`, params).then(res => {
          if(res.data.code == 0) {
            this.optionsJC = res.data.data.map(item => {
              return {
                label: item.name,
                value: Number(item.code),
              }
            })
          }
        })
    },
    // /finance/budget/establishment/proInstanceId/{proInstanceId}
    getPageData() {
      getApi(`/finance/budget/establishment/proInstanceId/${this.rowId}`).then(res => {
        if(res.data.code == 0) {
          let {data} = res;
            this.$refs.PersonForm.formData = {
              applierName: data.data.applierName,
              applyDate: data.data.applyDate,
              applierId: data.data.applierId,
            }
            let { finBudgetEstablishmentScGds, finBudgetEstablishmentScZdys, finBudgetEstablishmentXsGds, ...jbxxData} = data.data
          console.log('jbxxData', jbxxData)
          this.budgetType = jbxxData.budgetType;
          if(this.budgetType == 'CW-YSBZ-YSLX-XS') {
            this.tableData_xs = finBudgetEstablishmentXsGds;
            this.formList.forEach(item =>{
              if(item.value == 'budgetTotalAmount') {
                item.label = '销售预算总额'
              }
            })
          }
          else {
            this.tableDataSource = finBudgetEstablishmentScZdys
            this.tableDataSource.forEach((item, i) => {
              item.index = i + 1
            })
            this.tableDataFixed = finBudgetEstablishmentScGds
            this.formList.forEach(item =>{
              if(item.value == 'budgetTotalAmount') {
                item.label = '生产预算总额'
              }
            })
          }
          setTimeout(() => {
            this.$refs.assignForm.formData =  jbxxData
          }, 0)
            if (data.data.taskNodes) {
            this.activityList.forEach((item) => {
              item.description = [];
              data.data.taskNodes.forEach((val) => {
                if(item.activityName == val.taskName) {
                  item.description.push(val)
                }
              })
            })
          }
            
        }
      })
    },
    getStepList() {
      getApi(`/activiti/process/viewPage/${this.rowId}`).then(res => {
        let { data } = res;
        console.log('data', data)
        if(res.data.code == 0) {
          this.activityList = data.data;
          this.activityList.forEach((item,index) => {
            if(item.isCurrentActiviti == 1 || item.isHistoricActiviti == 1) {
              this.activeIndex = index+1
            }
          })
        }
      })
    },
    // tabs 切换 表头
    filterColums() {
      let arr = [];
      this.ColumnsAllocation.forEach((item) => {
        if (this.activeName == "显示全部") {
          item.content.map((it) => {
            arr.push(it);
          });
        } else if (item.type == this.activeName) {
          item.content.map((it) => {
            arr.push(it);
          });
        } else {
        }
      });
      this.tableColumns = this.ColumnsConfig.concat(arr);
      console.log('this.tableColumns', this.tableColumns)
    },
    handleSelectionChange(val) {
      this.selectTableSource = val;
    },
    getAssignList() { // 获取任务书列表
      getApi(`/project/assign/budget/assign/list/sc`).then(res => {
        if(res && res.data.code == 0) {
          let { data } =  res;
          let options = {
            value: "assignName",
            optionsList: data.data,
          };
          console.log('data', res)
          this.setOPtionsList(this.formList, options)
        }
      })
    },
    getSummaries(param) { // 自定义项表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "expenseSubtotal" || column.property == "workday" || column.property == "materialsOfficeExpense"
        || column.property == "cookingUtensilsExpense"|| column.property == "vehicleRentalExpense"|| column.property == "outsourcedLaborExpense"
        || column.property == "meritPayExpense"|| column.property == "salaryInsuranceExpense"|| column.property == "utilityRentExpense"
        || column.property == "inspectionExpense"|| column.property == "travelExpensesExpense"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      this.sumExpenseSubtotalZdy = sums[8] || 0;
      return sums
    },
    getSummariesFixed(param) { // 固定项 表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "expenseSubtotal" || column.property == "workday" || column.property == "meritPayExpense"
        || column.property == "salaryInsuranceExpense") {
          //如果是经费（正常的加减法）
          const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      this.sumExpenseSubtotalGd = sums[5] || 0;
      return sums
    },
    getUnitList() { // 获取计量单位下拉数据
      const params = {
        typeCode: "PRJ-GZBDW"
      }
      postApi('/finance/dict/list/all', params).then(res => {
        if(res.data.code == 0) {
          let { data } = res
          this.ColumnsConfig.forEach(item => {
            if(item.key == 'measureUnit') {
              item.selectInput.options = data.data.map((it) => {
                return {
                  label: it.name,
                  value: it.code
                }
              })
            }
          })
        }
      }).finally(() => {
        
        this.getJobType();
      })
    },
    getJobType() { // 获取工种List
      const params = {
        typeCode: "BUDGET-WORK-TYPE"
      }
      postApi('/finance/dict/list/all', params).then(res => {
        if(res.data.code == 0) {
          let { data } = res
          this.ColumnsConfig.forEach(item => {
            if(item.key == 'workType') {
              item.selectInput.options = data.data.map((it) => {
                return {
                  label: it.name,
                  value: it.code
                }
              })
            }
          })
          this.tableColumnsFixed.forEach(item => {
            if(item.key == 'workType') {
              item.selectInput.options = data.data.map((it) => {
                return {
                  label: it.name,
                  value: it.code
                }
              })
            }
          })
          
        }
      })
    },
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    SetCellClassName({row, column, rowIndex, columnIndex}) {
      if(column.property == 'jobContent' || column.property == 'measureUnit' || column.property == 'totalEffort' || column.property == 'workType'
       || column.property == 'efficiency'|| column.property == 'workday'|| column.property == 'expenseSubtotal' || column.property == 'index') {
        return 'backgroundColor'
       }
    },
  },
};
</script>

<style lang="less" scoped>
.buttonBox {
  margin: 4px 0;

}
.explain{
  margin-left: 30px;
  color: #00000075;
  height: 36px;
  line-height: 36px;
}
/deep/.el-tabs__header {
  margin: 0;
}
/deep/ .el-tabs__item {
  font-weight: 600;
  border-bottom: none;
  width: 16.67% !important;
}
/deep/.el-tabs--card > .el-tabs__header {
  border-bottom: none;
}
/deep/.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #576289;
  border-bottom: none;
}
/deep/.el-tabs__item.is-active {
  color: @systemColor !important;
}
/deep/.el-tabs__item:hover {
  color: #000 !important;
}
/deep/.el-tabs__item.is-active:focus {
  color: @systemColor !important;
}

/deep/ .el-input__icon {
  line-height: 30px;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}
/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
/deep/ .table_cell {
  .backgroundColor {
    background-color: #e7f0fa !important;
  }
}
</style>
