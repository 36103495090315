<template>
  <div class="task-container">
    <dd-card title="基本信息" spacer>
      <dd-form :form-config="formBasicList" :flex="3" ref="addPopForm">
      </dd-form>
    </dd-card>
    <dd-card title="详细填报信息(根据所选任务和外协带出)" :height="tableHeight">
      <dd-table
        ref="workTable"
        :columns="tableColumns"
        :data="tableData"
        :topBtn="false"
        border
        :show-page="false"
        :isSelection="false"
        :loading="loading"
        :isEdit="true"
      >
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";
import { getNowDate } from "@/utils/date";
export default {
  props: {
    resolveType: {
      type: String,
      default: "",
    },
    rowId: {
      type: String,
      default: "",
    },
    rowData: {
      type: Object,
      default: null,
    },
    assignId:{
      type: String,
      default: "",
    }
  },
  components: {},
  data() {
    return {
      tableHeight: null,
      editPopupVisible: false,
      formBasicList: [
        // {
        //   label: "任务列表",
        //   value: "assignId",
        //   inputType: "select",
        //   optionsList: [],
        //   filterable: false,
        //   placeholderText: "请选择任务",
        //   optionsListConfig: {
        //     name: "assignName",
        //     code: "id",
        //   },
        //   rules: [
        //     {
        //       required: true,
        //       message: "请选择任务",
        //       trigger: "blur",
        //     },
        //   ],
        //   change: this.getChangeTask,
        //   defaultValue: "",
        // },
        {
          label: "外协名称",
          value: "purId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择外协名称",
          optionsListConfig: {
            name: "purName",
            code: "purId",
          },
          rules: [
            {
              required: true,
              message: "请选择外协名称",
              trigger: "blur",
            },
          ],
          change: this.getChangePur,
          defaultValue: "",
        },
        {
          label: "完成日期",
          value: "workTime",
          placeholderText: "选择完成日期",
          inputType: "date",
          dateType: "date",
          format: "yyyy-MM-dd",
          defaultValue: getNowDate(),
        },
      ],
      loading: false,
      tableColumns: [
        {
          title: "节点编号",
          key: "nodeCode",
          align: "center",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
        },
        {
          title: "预估总工作量",
          key: "planWorkload",
          align: "center",
        },
        {
          title: "工作量单位",
          key: "nodeUnitName",
          align: "center",
        },
        {
          title: "预估标准工天",
          key: "planStandardWorkday",
          align: "center",
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
        },
        {
          title: "已完成量",
          key: "realWorkload",
          align: "center",
        },
        {
          title: "已完成标准工天",
          key: "realStandardWorkday",
          align: "center",
        },
        {
          title: "本次完成量",
          key: "workload",
          align: "center",
          input: [
            {
              placeholderText: "请输入",
              method: (value, row, idx) => {
                this.changeAmount(value, row, idx);
              },
              isEdit: (value) => {
                let state = false;
                return state;
              },
              rules: (value) => {
                return value.replace(/[^\d.]/g, "");
              },
            },
          ],
        },
        {
          title: "本次完成标准工天",
          key: "workStandardWorkday",
          align: "center",
        },
        {
          title: "说明",
          key: "description",
          align: "center",
          input: [
            {
              placeholderText: "请输入",
              method: (value, row, idx) => {
                // this.changeAmount(value, row, idx);
              },
              isEdit: (value) => {
                let state = false;
                return state;
              },
            },
          ],
        },
      ],
      tableData: [],
    };
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(2);
    });
    // 获取模板列表
    this.getModelList();
    if (this.rowData) {
      this.getDetail(this.rowData.id);
    }
    if(this.assignId && this.assignId != ''){
      this.getChangeTask(this.assignId, "init")
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 编辑回显详情查询
    getDetail(id) {
      getApi(`/project/prjassignpurworkload/${id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.getChangeTask(data.data.assignId, "init");
          this.formBasicList.forEach((item) => {
            item.defaultValue = data.data[item.value];
          });
          this.tableData = data.data.workloadDetailList;
        }
      });
    },
    changeAmount(val, row, idx) {
      // 本次完成量/效率
      this.tableData[idx].workStandardWorkday = val/row.efficiency
      // this.tableData[idx].realWorkload = val;
    },
    // 获取任务列表
    getModelList() {
      postApi(`/project/assign/list/${this.rowId}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "assignId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    // 查询外协名称
    getChangeTask(id, type) {
      getApi(`/project/purinfo/listbyassignId/${id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formBasicList.forEach((item) => {
            if (item.value == "assignId") {
              item.defaultValue = id;
            } else {
              item.defaultValue = this.$refs.addPopForm.formData[item.value];
            }
            if (item.value == "purId") {
              item.optionsList = data.data;
              if (type != "init") {
                item.defaultValue = null;
              }
            }
          });
        }
      });
    },
    getChangePur(id) {
      this.purId = id;
      this.getNodeList();
    },
    getNodeList() {
      let params = {
        assignId: this.assignId,
        purId: this.purId,
      };
      getApi(`/project/prjassignpurworkload/nodeReadList`, params).then(
        (res) => {
          let { data } = res;
          if (data.code == 0) {
            this.tableData = data.data;
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped>
.task-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: @systemColor;
  position: relative;
}
</style>
