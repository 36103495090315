<template>
  <div v-if="defaultPage" class="main-container">
    <dd-card title="条件搜索" spacer :showOpen="true" :ExpandAndCollapse="ExpandAndCollapse">
      <dd-search-form :form-config="formList" @search-click="handleSearchEvent" ref="ddSearchForm"
        labelWidth="180px"></dd-search-form>
    </dd-card>
    <dd-card title="任务书列表" :height="tableHeight">
      <dd-table :columns="tableColumns" :data="tableData" border show-page ref="TableRef" :loading="loading"
        @select-change="handleSelectionChange">
        <template #table-btn>
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd">新增</dd-button>
          <dd-button type="primary" size="medium" icon="update" @click="handleedit">任务书编码变更</dd-button>
          <dd-button type="primary" size="medium" icon="update" v-if="btnAuthorityList.includes('btn:edit:assign')" @click="handleChange">任务书基本信息变更</dd-button>
          <dd-button icon="export" type="success" size="medium" autoConfirm @click="handleFileExport">导出</dd-button>
        </template>
        <template #table-btn-right>
          <el-button icon="el-icon-s-operation" circle type="info" @click="drawerVisible = true" size="small"
            title="列筛选"></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
            @page-change="handleCurrentChange" align="right">
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
      :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange">
    </dd-filter-drawer>
    <!-- 新增页面弹窗 -->
    <dd-popup width="80%" height="80%" :showBtn="poptitleName == '详情' ? false : true" :submitBtn="true"
      :show="addPopupVisible" :title-name="poptitleName" @popup-close="handleClose"
      @popup-submit="handleSave('addPopForm', 'baseForm', 1)" @popup-save="handleSave('addPopForm', 'baseForm', 0)">
      <add-pop v-if="addPopupVisible" slot="main" ref="addPop" :titleName="poptitleName" :rowData="rowData"></add-pop>
    </dd-popup>
    <!-- 编辑管理信息页面弹窗 -->
    <dd-popup width="70%" height="70%" showBtn :show="manageInfoPopupVisible" title-name="编辑" @popup-close="handleClose"
      @popup-save="handleManageSave('addPopForm', 'baseForm')">
      <manage-info v-if="manageInfoPopupVisible" slot="main" ref="manageInfoPop" :rowData="rowData"></manage-info>
    </dd-popup>
    <dd-popup width="40%" height="30%" :showBtn="true" :submitBtn="false" :show="showChangeCode" title-name="任务书编码变更"
      @popup-close="changeCodeClose" @popup-save="handleChangeCodeSave()">
      <div slot="main">
        <dd-form :form-config="formChangeCodeList" :flex="1" labelWidth="230px" ref="ChangeCodeForm" formRef="ChangeCodeForm">
        </dd-form>
      </div>
    </dd-popup>
    <dd-popup width="80%" height="80%" :showBtn="true" :submitBtn="false" :show="BasicInformationVisible" title-name="任务书基本信息变更"
      @popup-close="changeBasicInformationClose" @popup-save="handleChangeBasicInformationSave('addPopForm', 'baseForm')">
      <BasicInformationChange v-if="BasicInformationVisible" slot="main" ref="BasicInformationChange" :rowData="propData"></BasicInformationChange>
    </dd-popup>
  </div>
  <div v-else-if="taskProcessPage" class="main-container">
    <!-- WBS控制模式  管理任务过程 -->
    <task-process :type="taskType" sourceType="manage" :rowId="rowData.id" :taskData="rowData"
      @pageBack="pageBack"></task-process>
  </div>
  <div v-else-if="taskResolvePage" class="main-container">
    <!-- wbs 初始化分解任务 -->
    <init-task-resolve v-if="rowData.produceType == 'PRJ-SCKZMS-WBSMS'" :rowId="rowData.id"
      @pageBack="pageBack"></init-task-resolve>
    <!-- 简易子任务模式 初始化分解任务 -->
    <init-child-task-resolve v-if="rowData.produceType == 'PRJ-SCKZMS-ZRWMS'" :assignData="rowData"
      @pageBack="pageBack"></init-child-task-resolve>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi, loadApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
import BasicInformationChange from "./components/BasicInformationChange.vue";
import taskProcess from "../ProjectProcess/index.vue";
import initTaskResolve from "./components/InitResolveTask.vue";
import manageInfo from "./components/EditManageInfo.vue";
import initChildTaskResolve from "../ProjectProcess/childTask/components/InitResolveTask.vue";
import { downloadBinaryFile } from "@/utils/index";
import { mapState } from "vuex";
export default {
  name: "projectTask",
  $dynamicRoute: {
    name: "生产任务书",
    path: "/project/task/list",
    keepAlive: true,
  },
  components: {
    addPop,
    taskProcess,
    initTaskResolve,
    manageInfo,
    initChildTaskResolve,
    BasicInformationChange
  },
  data() {
    return {
      propData: {},
      BasicInformationVisible: false,
      showChangeCode: false,
      tableHeight: null,
      defaultPage: true,
      taskProcessPage: false,
      taskResolvePage: false,
      taskType: "wbs",
      drawerVisible: false,
      loading: true,
      formData: {},
      formList: [
        {
          label: "单据编号",
          value: "assignCode",
          inputType: "text",
          placeholderText: "请输入任务书编号",
        },
        {
          label: "任务书编码",
          value: "assignNum",
          inputType: "text",
          placeholderText: "请输入任务书编码",
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
        },
        {
          label: "任务书年份",
          value: "assignYear",
          inputType: "date",
          dateType: "year",
          format: "yyyy",
          placeholderText: "请选择任务书年份",
        },
        {
          label: "项目名称及编号",
          value: "prjId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "下发部门",
          value: "downDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择下发部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
        {
          label: "任务生产部门",
          value: "receiveDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择任务生产部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
        {
          label: "任务书审核状态",
          value: "verifyStatus",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择任务书审核状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "审核节点",
          value: "taskFlag",
          inputType: "text",
          placeholderText: "请输入审核节点",
        },
        {
          label: "现客户经理",
          value: "nowCmmmanagerName",
          inputType: "text",
          placeholderText: "请输入现客户经理",
        },
        {
          label: "现项目经理",
          value: "nowLeaderName",
          inputType: "text",
          placeholderText: "请输入现项目经理",
        },
        {
          label: "客户名称",
          value: "cmmName",
          inputType: "text",
          placeholderText: "请输入客户名称",
        },{},{},{}
      ],
      tableColumns: [
        {
          title: "单据编号",
          key: "assignCode",
          align: "center",
          width: 180,
        },
        {
          title: "任务书编码",
          key: "assignNum",
          align: "center",
          width: 180,
        },
        {
          title: "任务书名称",
          key: "assignName",
          align: "center",
          width: 180,
        },
        {
          title: "任务书年份",
          key: "assignYear",
          align: "center",
        },
        {
          title: "生产控制模式",
          key: "produceTypeName",
          align: "center",
          width: 150,
        },
        {
          title: "生产实施状态",
          key: "produceStatusName",
          align: "center",
        },
        {
          title: "所在区域",
          key: "regionAreaName",
          align: "center",
          width: 120,
        },
        {
          title: "任务书审核状态",
          key: "verifyStatusName",
          align: "center",
        },
        {
          title: "审核节点",
          key: "taskFlag",
          align: "center",
        },
        {
          title: "客户名称",
          key: "cmmName",
          align: "center",
        },
        {
          title: "项目编码",
          key: "prjCode",
          align: "center",
        },
        {
          title: "项目名称",
          key: "prjName",
          align: "center",
        },
        {
          title: "合同编码",
          key: "contractCode",
          align: "center",
        },
        {
          title: "合同名称",
          key: "contractName",
          align: "center",
        },
        {
          title: "现任务下发部门",
          key: "nowDownDeptName",
          align: "center",
        },
        {
          title: "现生产负责部门",
          key: "nowReceiveDeptName",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 300,
          fixed: "right",
          event: [
            {
              label: "查看",
              icon: "el-icon-view",
              method: (row) => {
                this.handleRowDetail(row);
              },
              rules: (row) => {
                let show = false;
                if (row.verifyStatus != "PRJ-RWSHZT-DTJ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "初始化分解任务",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowInitTaskResolve(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (
                  row.produceType == "PRJ-SCKZMS-WBSMS" &&
                  row.verifyStatus == "PRJ-RWSHZT-SHTG" &&
                  row.prjAssignSubplanList.length == 0
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "管理任务过程",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowTaskProcess(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (
                  row.produceType == "PRJ-SCKZMS-WBSMS" &&
                  row.verifyStatus == "PRJ-RWSHZT-SHTG" &&
                  row.prjAssignSubplanList.length > 0
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              // label: "子任务管理",
              // icon: "el-icon-view",
              // method: (row) => {
              //   this.handleRowChildTask(row);
              // },
              // type: "primary",
              // rules: (row) => {
              //   let show = false;
              //   if (
              //     row.produceType == "PRJ-SCKZMS-ZRWMS" &&
              //     row.verifyStatus == "PRJ-RWSHZT-SHTG"
              //   ) {
              //     show = true;
              //   } else {
              //     show = false;
              //   }
              //   return show;
              // },
              label: "初始化分解任务",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowInitTaskResolveZRW(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (
                  row.produceType == "PRJ-SCKZMS-ZRWMS" &&
                  row.verifyStatus == "PRJ-RWSHZT-SHTG" &&
                  row.prjAssignSubplanList.length == 0
                ) {
                  // if (row.prjAssignSubplanList.length == 0) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "管理任务过程",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowChildTask(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (
                  row.produceType == "PRJ-SCKZMS-ZRWMS" &&
                  row.verifyStatus == "PRJ-RWSHZT-SHTG" &&
                  row.prjAssignSubplanList.length > 0
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (
                  row.verifyStatus == "PRJ-RWSHZT-DTJ" ||
                  row.verifyStatus == "PRJ-RWSHZT-YTH"
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (row.verifyStatus == "PRJ-RWSHZT-DTJ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "编辑管理信息",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowManageInfo(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.verifyStatus == "PRJ-RWSHZT-SHTG") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      formChangeCodeList: [
        {
          label: "年份",
          value: "assignYear",
          inputType: "otherDate",
          dateType: "year",
          defaultValue: '',
          format: 'yyyy',
          placeholderText: "请选择年份",
          rules: [
            {
              required: true,
              message: "请选择年份",
              trigger: "blur",
            },
          ],
        },
        {
          label: "生产任务编码后四位编号",
          value: "serialNumber",
          inputType: "text",
          defaultValue: "",
          placeholderText: "请输入生产任务编码后四位编号",
          rules: [
            {
              required: true,
              message: "请输入生产任务编码后四位编号",
              trigger: "blur",
            },
            {
              required: true,
              pattern: /^\d{4}$/,
              message: "只能输入4位整数",
              trigger: "blur",
            }
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      prjState: [],
      addPopupVisible: false,
      rowData: null,
      poptitleName: "新增",
      manageInfoPopupVisible: false,
      multipleSelection: [],
    };
  },
  computed: {
    ...mapState(["btnAuthorityList"]), //权限标识数组
  },
  created() { },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    changeBasicInformationClose() {
      this.$refs.TableRef.$refs.baseTable.clearSelection();
      this.propData = {};
      this.BasicInformationVisible = false;
    },
    handleChange() {
      if(this.multipleSelection.length != 1) {
        this.$message.warning('请勾选一条数据')
        return
      }
      if(this.multipleSelection[0].verifyStatus !== 'PRJ-RWSHZT-SHTG') {
        this.$message.warning('仅可修改任务书审核状态为审批通过的数据')
        return
      }
      this.propData = this.multipleSelection[0];
      this.BasicInformationVisible = true;
    },
    handleChangeBasicInformationSave(formName, formNameBase) {
      let flag = false;
      let flag1 = false;
      let flagKh = false;
      this.$refs.BasicInformationChange.$refs[formName].$refs[formNameBase].validate(
          (valid) => {
            if (valid) {
              flag = true;
            }
          }
        );
        this.$refs.BasicInformationChange.$refs.addPopForm1.$refs[formNameBase].validate(
          (valid) => {
            if (valid) {
              flag1 = true;
            }
          }
        );
        this.$refs.BasicInformationChange.$refs.addPopFormKh.$refs[formNameBase].validate(
          (valid) => {
            if (valid) {
              flagKh = true;
            } 
          }
        );

        let params = { ...this.$refs.BasicInformationChange.$refs[formName].formData, ...this.$refs.BasicInformationChange.$refs.addPopForm1.formData, ...this.$refs.BasicInformationChange.$refs.addPopFormKh.formData };
        params.id = this.$refs.BasicInformationChange.detailData.id;
        if (flag && flag1 && flagKh) {
        putApi(`/project/assign`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("修改成功");
            this.changeBasicInformationClose();
            this.getTableData();
          }
        });
      }
    },
    ExpandAndCollapse(val) {
            if(val) {
                this.tableHeight = 'calc(100% - 45px)';
            }
            else {
                this.tableHeight = `calc(100% - ${Math.ceil(this.formList.length / 4) * 51 + 45}px)`;
            }
    },
    handleChangeCodeSave() {
      let flag = false;
      this.$refs.ChangeCodeForm.$refs.ChangeCodeForm.validate(
          (valid) => {
            if (valid) {
              flag = true;
            }
          }
        );
        if(flag) {
          const params = {
            assignYear: this.$refs.ChangeCodeForm.formData.assignYear * 1,
            serialNumber: this.$refs.ChangeCodeForm.formData.serialNumber * 1,
            id: this.multipleSelection[0].id,
          }
          postApi(`/project/assign/update/assignnum`, params).then(res => {
            if(res.data.code == 0) {
              this.$message.success('操作成功')
            }
            else {
              this.$message.warning('操作失败，请稍后重试')
            }
              this.getTableData();
              this.showChangeCode = false
          })
        }
    },
    changeCodeClose() {
      this.$refs.TableRef.$refs.baseTable.clearSelection();
      this.showChangeCode = false
    },
    handleedit() {
      if(this.multipleSelection.length != 1) {
        this.$message.warning('请勾选一条数据')
        return
      }
      if(this.multipleSelection[0].assignType != 'PRJ-ASSIGN-TYPE-GKGL' && this.multipleSelection[0].verifyStatus == 'PRJ-RWSHZT-SHTG') {
        this.$message.warning('仅可修改任务书类型为挂靠已有项目的任务书且审批状态为审批通过的数据')
        return
      }
      this.showChangeCode = true;
      setTimeout(()=>{
        this.$refs.ChangeCodeForm.formData.assignYear = this.multipleSelection[0].assignYear.toString() || '';
        this.$refs.ChangeCodeForm.formData.serialNumber = this.multipleSelection[0].serialNumber || '';
        this.$forceUpdate()
      }, 0)
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 获取项目列表数据
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "prjId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "receiveDeptId",
            optionsList: data.data,
          };
          let options1 = {
            value: "downDeptId",
            optionsList: data.data,
          };

          this.setOPtionsList(this.formList, options);
          this.setOPtionsList(this.formList, options1);
        }
      });
      // 获取审核状态
      postApi(`/project/prj/dict/list/all`, {typeCode: "PRJ-RWSHZT"}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "verifyStatus",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
      };
      this.loading = true;
      postApi(`/project/assign/all/${current}/${size}`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
      this.manageInfoPopupVisible = false;
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "编辑";
    },
    // 表格行内查看事件
    handleRowDetail(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "详情";
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/project/assign/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    // wbs 初始化分解任务
    handleRowInitTaskResolve(row) {
      this.defaultPage = false;
      this.taskProcessPage = false;
      this.taskResolvePage = true;

      this.rowData = row;
    },
    // 子任务 初始化分解任务
    handleRowInitTaskResolveZRW(row) {
      this.defaultPage = false;
      this.taskProcessPage = false;
      this.taskResolvePage = true;

      this.rowData = row;
    },
    // WBS 行内管理任务过程点击事件
    handleRowTaskProcess(row) {
      this.defaultPage = false;
      this.taskResolvePage = false;
      this.taskProcessPage = true;
      this.rowData = row;
      this.taskType = "wbs";
      this.$bus.$emit("collapse", true);
    },
    // 子任务 行内管理任务过程点击事件
    handleRowChildTask(row) {
      this.defaultPage = false;
      this.taskResolvePage = false;
      this.taskProcessPage = true;
      this.taskType = "childTask";
      this.rowData = row;
      this.$bus.$emit("collapse", true);
    },
    // 编辑管理信息
    handleRowManageInfo(row) {
      this.rowData = row;
      this.manageInfoPopupVisible = true;
    },
    // 任务过程页面返回事件回调
    pageBack() {
      this.defaultPage = true;
      this.taskResolvePage = false;
      this.taskProcessPage = false;
      this.rowData = null;
      this.getTableData();
    },
    // 格式化类型
    getState(key, row) {
      let label = "";
      this[key].forEach((item) => {
        if (item.value == row) {
          label = item.label;
        }
      });
      return label;
    },
    getArrItem(key, row) {
      let arr = [];
      row.forEach((item) => {
        arr.push(item[key]);
      });
      return arr;
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase, type) {
      let flag = false;
      let flag1 = false;
      let flagKh = false;
      let flagXm = false;
      if (type == 1) {
        this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
          (valid) => {
            if (valid) {
              flag = true;
              this.$refs.addPop.modifyJbxx(0)
            } else {
              this.$refs.addPop.modifyJbxx(1)
            }
          }
        );
        this.$refs.addPop.$refs.addPopForm1.$refs[formNameBase].validate(
          (valid) => {
            if (valid) {
              flag1 = true;
            }
          }
        );
        this.$refs.addPop.$refs.addPopFormKh.$refs[formNameBase].validate(
          (valid) => {
            if (valid) {
              flagKh = true;
              this.$refs.addPop.modifyKhxgxx(0)
            } else {
              this.$refs.addPop.modifyKhxgxx(1)
            }
          }
        );
        this.$refs.addPop.$refs.addPopFormXm.$refs[formNameBase].validate(
          (valid) => {
            if (valid) {
              flagXm = true;
              this.$refs.addPop.modifyXmglxx(0)
            } else {
              this.$refs.addPop.modifyXmglxx(1)
            }
          }
        );
      } else {
        flag = true;
        flag1 = true;
        flagKh = true;
        flagXm = true;
        let estimatedAmount = this.$refs.addPop.$refs[formName].formData.estimatedAmount
        if (estimatedAmount != '' && !/^-?\d+(\.\d+)?$/.test(estimatedAmount)) {
          flag = false;
        }
      }

      let params1 = this.$refs.addPop.$refs[formName].formData,
        params2 = this.$refs.addPop.$refs.addPopForm1.formData,
        paramsKh = this.$refs.addPop.$refs.addPopFormKh.formData,
        paramsXm = this.$refs.addPop.$refs.addPopFormXm.formData,
        params = {
          ...params1,
          ...params2,
          ...paramsKh,
          ...paramsXm,
          applierId: sessionStorage.getItem("userId"),
          applier: sessionStorage.getItem("fullName"),
        };
      if (flag && flag1 && flagKh && flagXm) {
        if (this.rowData) {
          params.id = this.rowData.id;
        }
        postApi(`/project/assign/save/${type}`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("保存成功");
            this.handleClose();
            this.getTableData();
          }
        });
      }
    },
    handleManageSave(formName, formNameBase) {
      let flag = false;
      this.$refs.manageInfoPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = { ...this.$refs.manageInfoPop.$refs[formName].formData, ...this.$refs.manageInfoPop.$refs.addPopForm1.formData },
        BcId = this.$refs.manageInfoPop.$refs['formManageListBx'].formData;
      params.id = this.$refs.manageInfoPop.detailData.id;
      if (BcId.nowDownFenDeptLeader != '') {
        params.nowDownFenDeptLeader = BcId.nowDownFenDeptLeader
      }
      if (BcId.nowReceiveFenDeptLeader != '') {
        params.nowReceiveFenDeptLeader = BcId.nowReceiveFenDeptLeader
      }
      // params
      if (params.serialNumber) {
        params.serialNumber = Number(params.serialNumber)
      }
      if (flag) {
        putApi(`/project/assign`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("修改成功");
            this.handleClose();
            this.getTableData();
          }
        });
      }
    },
    // 导出
    handleFileExport() {
      let { multipleSelection } = this;
      if (multipleSelection.length == 0) {
        this.$message.warning("请选择导出数据");
        return;
      }
      let idsArr = []
      multipleSelection.forEach(item => {
        idsArr.push(item.id)
      })
      let params = {
        ids: idsArr,
      };
      loadApi(`/project/assign/export/word`, params).then((res) => {
        let { data } = res;
        this.$refs.TableRef.$refs.baseTable.clearSelection();
        this.multipleSelection = [];
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
