<template>
    <div class="container">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="合同关联" name="合同关联">
            <contractAssociation ref="contractAssociation" :pageProp="pageProp"></contractAssociation>
        </el-tab-pane>
        <el-tab-pane label="结算关联" name="结算关联">
            <settlementAssociation ref="settlementAssociation" :pageProp="pageProp"></settlementAssociation>
        </el-tab-pane>
        <el-tab-pane label="付款关联" name="付款关联">
            <paymentAssociation ref="paymentAssociation" :pageProp="pageProp"></paymentAssociation>
        </el-tab-pane>
        <el-tab-pane label="发票关联" name="发票关联">
            <invoiceAssociation ref="invoiceAssociation" :pageProp="pageProp"></invoiceAssociation>
        </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import contractAssociation from './contractAssociation.vue'
import settlementAssociation from './settlementAssociation.vue'
import paymentAssociation from './paymentAssociation.vue'
import invoiceAssociation from './invoiceAssociation.vue'
    export default {
        name: 'maintenanceVisible',
        props: ['pageProp'],
        components: { contractAssociation, settlementAssociation, paymentAssociation, invoiceAssociation },
        data() {
            return {
                activeName: '合同关联',
            }
        },
        created() {

        },
        mounted() {

        },
        methods: {
            handleClick() {
                if(this.activeName == '合同关联') {
                    this.$refs.contractAssociation.getPageData()
                }
                else if(this.activeName == '结算关联') {
                    this.$refs.settlementAssociation.getPageData()
                }
                else if(this.activeName == '付款关联') {
                    this.$refs.paymentAssociation.getPageData()
                }
                else if(this.activeName == '发票关联') {
                    this.$refs.invoiceAssociation.getPageData()
                }
            },
        }
    }
</script>

<style lang="less" scoped>

</style>