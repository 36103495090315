<template>
  <div class="task-container">
    <div class="top-tab" v-if="oneStepPage">
      <dd-button type="text" size="medium" icon="back" @click="handleBackPage"
        >返回上一级</dd-button
      >
    </div>
    <div class="wrapper" :style="!oneStepPage ? `height:100%` : `height:calc(100% - 40px)`">
      <div class="one-step" v-if="oneStepPage">
        <dd-card title="模板选择" spacer>
          <dd-form :form-config="formBasicList" :flex="2" ref="addPopForm">
          </dd-form>
        </dd-card>
        <dd-card title="选择模板下的节点并指定负责人" :height="tableHeight">
          <dd-table
            ref="workTable"
            :columns="tableColumns"
            :data="tableData"
            :topBtn="false"
            border
            isSelection
            :loading="loading"
            @select-change="handleSelectionChange"
          >
          </dd-table>
        </dd-card>
        <div class="btn">
          <dd-button type="primary" size="medium" @click="handleNextPage"
            >下一步</dd-button
          >
          <dd-button type="warning" size="medium" @click="handleBackPage"
            >取消</dd-button
          >
        </div>
      </div>
      <task-process
        v-else
        type="childTask"
        sourceType="init"
        :rowId="assignData.id"
        @pageBack="handleBackStep"
      ></task-process>
    </div>
    <dd-popup
      width="35%"
      height="50%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="editPopupVisible"
      title-name="编辑"
      @popup-close="handClose"
      @popup-save="handleEditPopSave('addPopForm', 'baseForm')"
    >
      <model-node-edit-pop
        v-if="editPopupVisible"
        :rowData="rowData"
        slot="main"
        ref="editPop"
      >
      </model-node-edit-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";
import ModelNodeEditPop from "./ModelNodeEditPop.vue";
// 工作分解
import taskProcess from "../../../ProjectProcess/index.vue";
export default {
  props: {
    assignData: {
      type: Object,
      default: "",
    },
  },
  components: {
    ModelNodeEditPop,
    taskProcess
  },
  data() {
    return {
      oneStepPage: true,
      tableHeight: null,
      rowData: null,
      editPopupVisible: false,
      formBasicList: [
        {
          label: "创建人",
          value: "createBy",
          inputType: "text",
          placeholderText: "请输入创建人",
          disabled: true,
          defaultValue: sessionStorage.getItem("fullName"),
        },
        {
          label: "创建部门",
          value: "createDeptName",
          inputType: "text",
          placeholderText: "请输入创建部门",
          disabled: true,
          defaultValue: sessionStorage.getItem("depName"),
        },
        {
          label: "模板名称",
          value: "templateId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择模板名称",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择模板名称",
              trigger: "blur",
            },
          ],
          change: this.getNodeList,
        },
        {
          label: "业务类型",
          value: "businessTypeId",
          inputType: "cascader",
          filterable: true,
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
          },
          showAllSelcet: false,
          placeholderText: "请选择业务类型",
          rules: [
            {
              required: true,
              message: "请选择业务类型",
              trigger: "blur",
            },
          ],
        },
      ],
      loading: false,
      tableColumns: [
        {
          title: "节点",
          key: "name",
          align: "center",
        },
        {
          title: "生产分管部门",
          key: "receiveDeptName",
          align: "center",
        },
        {
          title: "部门",
          key: "childDepName",
          align: "center",
        },
        {
          title: "负责人",
          key: "fullName",
          align: "center",
        },
        {
          title: "核算组",
          key: "accounting",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
            {
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
            },
          ],
        },
      ],
      addPopFormData: null,
      tableData: [],
      multipleSelection: [],
      // 模板id
      modelId: null,
    };
  },
  created() {
    this.getBusinessType()
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    // 获取模板列表
    this.getModelList();
    // console.log('assignData', this.assignData)
    // this.$refs.addPopForm.formData.businessTypeId = ;
    this.formBasicList.forEach(item => {
      if(item.value == 'businessTypeId') {
        item.defaultValue = this.assignData.businessTypeId
      }
    })
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleBackPage() {
      this.$emit("pageBack");
    },
    handleBackStep() {
      this.$emit("pageBack");
    },
    handClose(){
      this.editPopupVisible = false
      // this.getModelList();
    },
    handleNextPage() {
      let flag = false;
      this.$refs.addPopForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (flag) {
        if (this.multipleSelection.length == 0) {
          this.$message.warning("请选择节点");
          return;
        }
        let isNext = false;
        this.multipleSelection.forEach((item) => {
          if (item.accounting == null) {
            isNext = true;
          }
        });
        if (isNext) {
          this.$message.warning("所选节点核算组不能为空，请编辑操作");
          return;
        }
        let params = this.multipleSelection;
        postApi(
          `/project/subplannode/subplan/addnode/${this.assignData.id}/${this.$refs.addPopForm.formData.businessTypeId}`,
          params
        ).then((res) => {
          if (res.data && res.data.code == 0) {
            this.oneStepPage = false;
            // this.$emit("nextTaskResolve");
          }
        });
      }
    },
    loopData(data) {
      if (data && data.length) {
        data.forEach((item) => {
          if (item?.children && item?.children?.length > 0) {
            item.disabled = true;
            this.loopData(item.children);
          }
        });
      }
      return data;
    },
    getBusinessType() {
      getApi(`/project/prjareasubassginbussitype/tree`, {}).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let arr = this.loopData(data.data);
          let options = {
            value: "businessTypeId",
            optionsList: arr,
          };
          console.log('arr', arr)
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    // 获取模板列表
    getModelList() {
      getApi(`/project/prjwbsbasetemplate/list/zrw`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "templateId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    getNodeList(modelId) {
      if(modelId == ''){
        this.tableData = [];
      }
      else if(modelId != ''){
        this.modelId = modelId;
        let params = {
          baseId: modelId,
        };
        getApi(`/project/prjwbstemplate/tree`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            data.data.forEach((item) => {
              item.receiveDeptId = this.assignData.nowReceiveFenDeptId;
              item.receiveDeptName = this.assignData.nowReceiveFenDeptName;
              
              item.receiveSubDeptId = this.assignData.nowReceiveDeptId;
              item.childDepName = this.assignData.nowReceiveDeptName;

              item.leaderId = this.assignData.nowLeaderId;
              item.fullName = this.assignData.nowLeaderName;

              postApi(`/project/accounting/branch/getBydepId`, {
                deptId: this.assignData.nowReceiveDeptId,
              }).then((res) => {
                let { data } = res;
                if (data.code == 0) {
                  item.areaAccountingId = data.data.accountingId;
                  item.accounting = data.data.accounting;
                }
              });
              item.areaAccountingId = null;
              item.accounting = null;
            });
            this.tableData = data.data;
          }
        });
      }
    },
    handleRowEditor(row) {
      this.rowData = row;
      this.editPopupVisible = true;
    },
    handleEditPopSave(formName, formNameBase) {
      let flag = false;
      this.$refs.editPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.editPop.$refs.addPopForm.formData

      this.$refs.editPop.receiveDeptIdList.forEach(item => {
        if(params.receiveDeptId == item.id){
          params.receiveDeptName = item.name
        }
      })
      this.$refs.editPop.receiveSubDeptIdList.forEach(item => {
        if(params.receiveSubDeptId == item.deptId){
          params.childDepName = item.name
        }
      })
      this.$refs.editPop.leaderIdList.forEach(item => {
        if(params.leaderId == item.userId){
          params.fullName = item.fullName
        }
      })
      params.accounting = this.$refs.editPop.areaAccountingIdList.accounting
      if (flag) {
        this.tableData.forEach((item) => {
          if (item.id == this.rowData.id) {
            item.receiveSubDeptId = params.receiveSubDeptId;
            item.childDepName = params.childDepName;

            item.leaderId = params.leaderId;
            item.fullName = params.fullName;

            item.areaAccountingId = params.areaAccountingId;
            item.accounting = params.accounting;

            item.receiveDeptId = params.receiveDeptId;
            item.receiveDeptName = params.receiveDeptName;
          }
        });
        this.editPopupVisible = false;
      }
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="less" scoped>
.task-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: @systemColor;
  position: relative;
  .top-tab {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 15px;
    box-sizing: border-box;
    background-color: #f2f7ff;
    border-bottom: 1px solid #fff;
  }
  .wrapper {
    width: 100%;
    height: calc(100% - 40px);

    .one-step {
      width: 50%;
      height: 70%;
      margin: 0 auto;
      .btn {
        margin-top: 10px;
        text-align: right;
      }
    }
  }

  /deep/ .el-button--text {
    color: @themeColor;
    font-size: @fs16;
  }
  /deep/.el-input.is-disabled .el-input__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
  }
}
</style>
