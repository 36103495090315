<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="模型列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        isSort
        border
        :isSelection="false"
        show-page
        :loading="loading"
      >
        <template #table-btn>
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd"
            >新增</dd-button
          >
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="25%"
      height="45%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :dictType="dictType"
        :rowData="rowData"
      ></add-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
export default {
  name: "model",
  $dynamicRoute: {
    name: "模型管理",
    path: "/activiti/index",
  },
  components: {
    addPop,
  },
  computed: {},
  data() {
    return {
      tableHeight: null,
      drawerVisible: false,
      loading: true,
      formData: {},
      formList: [
        {
          label: "流程分类",
          value: "category",
          inputType: "selectTree",
          placeholderText: "请输入流程分类",
          optionsList: [],
          filterable: true,
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
      ],
      tableColumns: [
        {
          title: "模型ID",
          key: "id",
          align: "center",
        },
        {
          title: "模型标识",
          key: "key",
          align: "center",
        },
        {
          title: "流程分类",
          key: "category",
          align: "center",
          formatter: (row) => {
            let obj = this.findNodeById(this.dictType, row);
            return obj ? obj.name : row;
          },
        },
        {
          title: "模型名称",
          key: "name",
          align: "center",
        },
        {
          title: "版本号",
          key: "version",
          align: "center",
        },
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
        },
        {
          title: "最后更新时间",
          key: "lastUpdateTime",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 260,
          event: [
            {
              show: "true",
              label: "部署",
              icon: "el-icon-plus",
              method: (row) => {
                this.handleRowAdd(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "模型图",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      addPopupVisible: false,
      modelUrl: null,
      rowData: null,
      poptitleName: "新增",
      dictType: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getDataType() {
      getApi(`/activiti/wflcategory/tree`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "category",
            optionsList: data.data,
          };
          this.dictType = data.data;
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      getApi(`/activiti/model`, params)
        .then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.loading = false;
            this.tableData = data.data.records;
            this.total = data.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.$router.push({
        name: "模型图",
        params: {
          id: row.id,
        },
        query: {
          name: row.name + "的模型图",
        },
      });
    },
    handleRowAdd(row) {
      this.$confirm(`确认部署该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmUpload(row.id);
      });
    },
    handleConfirmUpload(id) {
      let params = {
        id: id,
      };
      postApi(`/activiti/model/deploy/${id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("部署成功");
          this.getTableData();
        }
      });
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/activiti/model/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    // 格式化类型
    getState(key, row) {
      let label = "";
      this[key].forEach((item) => {
        if (item.value == row) {
          label = item.label;
        }
      });
      return label;
    },
    getArrItem(key, row) {
      let arr = [];
      row.forEach((item) => {
        arr.push(item[key]);
      });
      return arr;
    },
    findNodeById(tree, id) {
      for (const node of tree) {
        if (node.id === id) {
          return node;
        }
        if (node.children && node.children.length > 0) {
          const foundNode = this.findNodeById(node.children, id);
          if (foundNode) {
            return foundNode;
          }
        }
      }
      return null;
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (flag) {
        postApi(`/activiti/model/insert`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("保存成功");
            this.handleClose();
            this.getTableData();
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
