<template>
  <div class="main-container">
    <div class="left-box">
      <dd-card title="类别树">
        <div class="container-left-tree">
          <el-tree
            ref="tree"
            :data="treeList"
            accordion
            :props="defaultProps"
            default-expand-all
            highlight-current
            node-key="id"
            :current-node-key="treeNodeId"
            @node-click="handleNodeClick"
          >
          </el-tree>
        </div>
      </dd-card>
    </div>
    <div class="right-box">
      <dd-card title="任务列表" height="100%">
        <dd-table
          :columns="columnsList"
          :data="tableData"
          border
          ref="dTableRef"
          tableRef="dTableRef"
          :isSort="true"
          :isSelection="false"
          :topBtn="false"
          :loading="loading"
        >
          <template #table-pagination>
            <dd-pagination
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="infoPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dayBookDetail
        v-if="infoPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></dayBookDetail>
    </dd-popup>
    <!-- 预算审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="budgetPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <budgetDetail
        v-if="budgetPopupVisible"
        slot="main"
        ref="budgetPop"
        :rowData="rowData"
      ></budgetDetail>
    </dd-popup>
    <!-- 外部用車审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="outerCarPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <outerCarDetail
        v-if="outerCarPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></outerCarDetail>
    </dd-popup>
    <!-- 任务书审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="taskBookPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <taskBookDetail
        v-if="taskBookPopupVisible"
        slot="main"
        ref="taskBookPop"
        :rowData="rowData"
      ></taskBookDetail>
    </dd-popup>
    <!-- WBS分解审批弹窗 -->
    <dd-popup
      width="72%"
      height="80%"
      :showBtn="false"
      :show="taskResolveVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <taskResolveDetail
        v-if="taskResolveVisible"
        slot="main"
        ref="taskResolvePop"
        :rowId="rowData.proInstanceId"
      ></taskResolveDetail>
    </dd-popup>
    <!-- 文档审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="docFilesVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <fileDetail
        v-if="docFilesVisible"
        slot="main"
        ref="taskResolvePop"
        :rowId="rowData.proInstanceId"
      ></fileDetail>
    </dd-popup>

    <!-- 预算编制弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="workOutVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
    <WorkOutDetail
        v-if="workOutVisible"
        slot="main"
        ref="workOutPop"
        :showOpinion="true"
        :rowId="rowData.proInstanceId"
      ></WorkOutDetail>
    </dd-popup>
  </div>
</template>
<script>
import { getApi, postApi } from "@/api/request";
import dayBookDetail from "./components/DaybookDetail.vue";
import budgetDetail from "./components/BudgetDetail.vue";
import outerCarDetail from "./components/OuterCarDetail.vue";
// 任务书审批
import taskBookDetail from "./components/TaskBookDetail.vue";
// wbs分解审批
import taskResolveDetail from "./components/TaskResolveDetail.vue";
// 文档审批
import fileDetail from "./components/FileDetail.vue";
// 预算编制审批
import WorkOutDetail from "../TodoTask/components/WorkOutDetail.vue";
import { getNowDate } from "@/utils/date.js";
export default {
  name: "taskSearch",
  $dynamicRoute: {
    name: "任务查询",
    path: "/personalOffice/taskSearch",
  },
  components: {
    dayBookDetail,
    budgetDetail,
    outerCarDetail,
    taskBookDetail,
    taskResolveDetail,
    fileDetail,
    WorkOutDetail
  },
  data() {
    return {
      infoPopupVisible: false,
      budgetPopupVisible: false,
      outerCarPopupVisible: false,
      taskBookPopupVisible: false,
      taskResolveVisible: false,
      docFilesVisible: false,
      workOutVisible: false,
      loading: true,
      deptId: "",
      treeList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      formData: {},
      tableData: [],
      formList: [
        {
          label: "申请人",
          value: "prjName",
          inputType: "text",
          placeholderText: "请输入申请人",
        },
      ],
      columnsList: [
        {
          title: "申请人",
          key: "username",
          align: "center",
        },
        {
          title: "主题",
          key: "subject",
          align: "center",
        },
        {
          title: "创建时间",
          key: "startTime",
          align: "center",
        },
        {
          title: "完成时间",
          key: "endTime",
          align: "center",
        },
        {
          title: "当前节点",
          key: "taskName",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: "120px",
          event: [
            {
              show: "true",
              label: "查看明细",
              icon: "el-icon-info",
              method: (row) => {
                this.handleInfo(row);
              },
              type: "success",
            },
          ],
        },
      ],
      total: 0,
      current: 1,
      size: 10,
      total: null,
      pointInfo: "",
      treeNodeId: "",
      rowData: null,
    };
  },
  mounted() {
    this.getLeftTree(); //组织机构树
  },
  methods: {
    // 获取左侧树形数据
    getLeftTree() {
      getApi(`/activiti/wflcategory/tree`, {}).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.treeList = data.data;
          this.treeNodeId = data.data[0].id;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
          });
          this.getTableData();
        }
      });
    },
    handleNodeClick(data) {
      this.treeNodeId = data.id;
      this.getTableData();
    },
    // 获取表格列表数据
    getTableData() {
      let { current, size } = this;
      let params = {
        current,
        size,
        category: this.treeNodeId,
      };
      this.loading = true;
      getApi(`/activiti/workflow/search/list`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.username = item.initator == null ? '' : item.initator.fullName;
            item.subject = item.subject.split("-")[0];
          });
          this.tableData = data.data.records;
          this.total = data.data.total;
          this.loading = false;
        }
      });
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.formData.deptId = this.deptId;
      if (this.deptId == "") {
        this.$message.warning("请先选择流程类别");
      } else {
        this.getTableData();
      }
    },
    handleClose() {
      this.pointInfo = "";
      this.infoPopupVisible = false;
      this.budgetPopupVisible = false;
      this.outerCarPopupVisible = false;
      this.taskBookPopupVisible = false;
      this.taskResolveVisible = false;
      this.docFilesVisible = false;
      this.workOutVisible = false;
    },
    handleInfo(row) {
      this.rowData = row;
      if (
        row.proDefKey == "PrjBudgetApply" ||
        row.proDefKey == "PrjBudgetRevise"
      ) {
        this.budgetPopupVisible = true;
      } else if (row.proDefKey == "PrjJournalApply") {
        this.infoPopupVisible = true;
      } else if (row.proDefKey == "RentalCar") {
        this.outerCarPopupVisible = true;
      } else if (row.proDefKey == "PrjAssign") {
        this.taskBookPopupVisible = true;
      } else if (row.proDefKey == "PrjAssignSubplan") {
        this.taskResolveVisible = true;
      } else if (row.proDefKey == "PrjAssignAttachfile") {
        this.docFilesVisible = true;
      } else if (row.proDefKey == 'FinBudgetEstablishment') {
        this.workOutVisible = true;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  position: relative;
  display: flex;

  .left-box {
    width: 20%;
    border-right: 5px solid @dividerColor;
  }

  .right-box {
    width: 80%;
  }
}
</style>
