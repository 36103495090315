<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="流程列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        isSort
        border
        :topBtn="false"
        :isSelection="false"
        show-page
        :loading="loading"
      >
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
    <!-- 流程图 -->
    <dd-popup
      width="65%"
      height="55%"
      :showBtn="false"
      :show="addPopupVisible"
      title-name="流程图"
      @popup-close="handleClose"
    >
      <div class="auto_size process_img" slot="main">
        <img :src="imageUrl" />
      </div>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
export default {
  name: "process",
  $dynamicRoute: {
    name: "流程管理",
    path: "/activiti/process",
  },
  components: {},
  computed: {},
  data() {
    return {
      tableHeight: null,
      drawerVisible: false,
      loading: true,
      formData: {},
      formList: [
        {
          label: "流程分类",
          value: "category",
          inputType: "selectTree",
          placeholderText: "请输入流程分类",
          optionsList: [],
          filterable: true,
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
      ],
      tableColumns: [
        {
          title: "流程ID",
          key: "processonDefinitionId",
          align: "center",
        },
        {
          title: "模型标识",
          key: "key",
          align: "center",
        },
        {
          title: "流程分类",
          key: "category",
          align: "center",
        },
        {
          title: "模型名称",
          key: "name",
          align: "center",
        },
        {
          title: "版本号",
          key: "revision",
          align: "center",
        },
        {
          title: "部署时间",
          key: "deploymentTime",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 330,
          event: [
            {
              show: "true",
              label: "流程图",
              icon: "el-icon-view",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "激活",
              icon: "el-icon-plus",
              method: (row) => {
                this.handleRowStatus(row, "activate");
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.suspend == true) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              show: "true",
              label: "失效",
              icon: "el-icon-plus",
              method: (row) => {
                this.handleRowStatus(row, "lose");
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.suspend == false) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      addPopupVisible: false,
      imageUrl: null,
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getDataType() {
      getApi(`/activiti/wflcategory/tree`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "category",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      getApi(`/activiti/process`, params)
        .then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.loading = false;
            this.tableData = data.data.records;
            this.total = data.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 激活/失效按钮事件
    handleRowStatus(row, type) {
      let label = type == "lose" ? "失效" : "激活";
      this.$confirm(`确认${label}该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmUpload(row.processonDefinitionId, type);
      });
    },
    handleConfirmUpload(id, type) {
      let status = type == "lose" ? "suspend" : "active";
      putApi(`/activiti/process/status/${id}/${status}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("操作成功");
          this.getTableData();
        }
      });
    },
    // 新增弹框关闭事件
    handleClose() {
      this.addPopupVisible = false;
    },
    // 表流程图查看事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.imageUrl = `/activiti/process/resource/${row.deploymentId}/${row.processonDefinitionId}/image`;
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.deploymentId);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/activiti/process/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.process_img {
  text-align: center;
  img {
    border: 1px solid #d9d9d9;
  }
}
</style>
