<template>
    <div class="main-container">
      <dd-form :form-config="formBasicList" labelWidth="220px" ref="editPopForm">
      </dd-form>
    </div>
  </template>
  
  <script>
  import { postApi, getApi, putApi } from "@/api/request";
import { disable } from "ol/rotationconstraint";
  export default {
    name: "edit-pop",
    props: {
      rowData: [],
      titleName: "",
    },
    components: {},
    data() {
      return {
        formBasicList: [
        {
          label: "单据编号",
          value: "assignCode",
          inputType: "text",
          placeholderText: "保存后自动生成",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "任务书类型",
          value: "assignType",
          inputType: "select",
          optionsList: [],
          defaultValue: '',
          disabled: true,
          filterable: true,
          placeholderText: "请选择任务书类型",
          optionsListConfig: {
            code: "code",
            name: "name",
          },
          rules: [
            {
              required: true,
              message: "请选择任务书类型",
              trigger: "blur",
            },
          ],
          change: this.assignTypeSelect
        },
        {
          label: "项目名称及编号",
          value: "prjId",
          disabled: true,
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择项目名称及编号",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          change: this.getContractList,
          rules: [
            {
              required: true,
              message: "请选择项目名称及编号",
              trigger: "blur",
            },
          ],
        },
          {
            label: "合同名称及编号",
            value: "contractId",
            inputType: "select",
            optionsList: [],
            filterable: true,
            placeholderText: "请选择合同名称及编号",
            optionsListConfig: {
              name: "name",
              code: "id",
            },
            rules: [
              {
                required: false,
                message: "请选择合同名称及编号",
                trigger: "blur",
              },
            ],
          },
        ],
        detailData: null,
        // 客户信息
        basicCrmContactList: [],
        detailPopupVisible: false,
      };
    },
    created() {},
    mounted() {
      this.getDataType();
      if (this.rowData != null) {
        if (this.rowData.prjId) {
          this.getContractList(this.rowData.prjId);
          // this.assignTypeSelect(this.rowData.assignType)
        }
        const editPopFormPrj = this.rowData.prjCode + ' ' + this.rowData.prjName;
        this.formBasicList.forEach((item) => {
          if(item.value == 'prjId') {
            item.defaultValue = editPopFormPrj;
            this.$forceUpdate()
          }
          else {
            item.defaultValue = this.rowData[item.value];
          }
        });
      }
    },
    methods: {
      assignTypeSelect(code) {
        getApi(`/project/assign/relation/assign?type=${code}`).then(res => {
          if(res.data.code == 0) {
            let { data } = res;
            this.formBasicList.forEach(item => {
              if(item.value == 'prjId') {
                item.optionsList = data.data;
              }
              if(item.value == 'assignType') {
                item.defaultValue = code
              }
              // if(item.)
              // else {
              //   item.defaultValue = this.$refs.editPopForm.formData[item.value]
              // }
            })
            // this.setOPtionsList(this.formBasicList, options);
          }
        this.$forceUpdate()
  
        })
      },
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
        formList.forEach((item) => {
          if (options) {
            if (item.value == options.value) {
              item.optionsList = options.optionsList;
            }
          }
        });
      },
      // 获取数据字典类型
      getDataType() {
        // 获取项目列表数据
        // getApi(`/project/prj/list/all`).then((res) => {
        //   let { data } = res;
        //   if (data.code == 0) {
        //     let options = {
        //       value: "prjId",
        //       optionsList: data.data,
        //     };
        //     this.setOPtionsList(this.formBasicList, options);
        //   }
        // });
        // 项目关联信息 - 任务书类型
        postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ_ASSIGN_TYPE" }).then(
          (res) => {
            let { data } = res;
            if (data && data.code == 0) {
              let options = {
                value: "assignType",
                optionsList: data.data,
              };
              // this.assignTypeList = data.data
              this.setOPtionsList(this.formBasicList, options);
            }
          }
        );
      },
      // 获取合同列表数据
      getContractList(id, type) {
        let params = {
          prjId: id,
        };
        getApi(`/project/contractinfo/getcontractinfolistbyprjid`, params).then(
          (res) => {
            let { data } = res;
            if (data && data.code == 0) {
              this.formBasicList.forEach((item) => {
                if (item.value == "prjId") {
                  // item.defaultValue = id;
                } else {
                  item.defaultValue = this.$refs.editPopForm.formData[item.value];
                }
                if (item.value == "contractId") {
                  item.optionsList = data.data;
                    item.defaultValue = this.rowData.contractId;
                }
              });
            }
          }
        );
      },
    },
  };
  </script>
  <style lang="less" scoped>
  /deep/.el-input.is-disabled .el-input__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
  }
  /deep/.el-textarea.is-disabled .el-textarea__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
    resize: none;
  }
  // /deep/ .el-input__suffix {
  //   display: none;
  // }
  /deep/ .el-range-editor.is-disabled {
    background-color: #fbfbfb;
    color: @defaultFontColor;
  }
  /deep/ .el-range-editor.is-disabled input {
    color: @defaultFontColor;
  }
  /deep/ .el-descriptions-item__label {
    color: #606266;
    font-weight: bold;
  }
  /deep/ .el-descriptions-item__content {
    color: @defaultFontColor;
  }
  </style>
  