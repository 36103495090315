<template>
    <div class="main-container">
        <dd-form :form-config="formBasicList" flex="2" ref="addPopForm">
        </dd-form>
    </div>
  </template>
  
  <script>
  export default {
    name: "add-pop",
    props: {
      rowData: []
    },
    data() {
      return {
        formBasicList: [
          {
            label: "编号",
            value: "clientId",
            inputType: "text",
            placeholderText: "请输入名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "密钥",
            value: "clientSecret",
            inputType: "text",
            placeholderText: "请输入键",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入键",
                trigger: "blur",
              },
            ],
          },
          {
            label: "域",
            value: "scope",
            inputType: "text",
            placeholderText: "请输入值",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入值",
                trigger: "blur",
              },
            ],
          },
          {
            label: "授权模式",
            value: "authorizedGrantTypes",
            inputType: "text",
            placeholderText: "请输入编码",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "回调地址",
            value: "webServerRedirectUri",
            inputType: "text",
            placeholderText: "请输入编码",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "权限",
            value: "authorities",
            inputType: "text",
            placeholderText: "请输入编码",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "令牌时效",
            value: "accessTokenValidity",
            inputType: "text",
            placeholderText: "请输入编码",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "刷新时效",
            value: "refreshTokenValidity",
            inputType: "text",
            placeholderText: "请输入编码",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "扩展信息",
            value: "additionalInformation",
            inputType: "text",
            placeholderText: "请输入编码",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "资源ID",
            value: "resourceIds",
            inputType: "text",
            placeholderText: "请输入编码",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "自动放行",
            value: "autoapprove",
            inputType: "radio",
            defaultValue: "",
            border: true,
            radioGroup: [{
              label: '是',
              value: "true"
            },{
              label: '否',
              value: "false"
            }],
            rules: [
              {
                required: true,
                message: "请选择参数类型",
                trigger: "blur",
              },
            ]
          },
        ],
      };
    },
    created() {},
    mounted() {
        if(this.rowData != null) {
            this.formBasicList.forEach(item => {
              if(item.value == "clientId") {
                item.disabled = true;
              }
              item.defaultValue = this.rowData[item.value]
            })
        }
    },
    methods: {
      change() {
        this.$forceUpdate();
      },
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.optionsList = options.optionsList;
                    }
                }
            });
        },
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
  