<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowData: {},
  },
  data() {
    return {
      formBasicList: [
        {
          label: "节点状态",
          value: "nodeState",
          inputType: "select",
          optionsList: [],
          disabled:true,
          filterable: false,
          placeholderText: "请选择节点状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          rules: [
            {
              required: true,
              message: "请选择节点状态",
              trigger: "blur",
            },
          ],
        },
        {
          label: "部门",
          value: "nodeDeptBranchId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          defaultValue:'',
          placeholderText: "请选择部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          change:this.getFenDetpList,
          // change: this.getChildDeptPersonList,
          rules: [
            {
              required: true,
              message: "请选择部门",
              trigger: "blur",
            },
          ],
        },
        {
          label: "生产分管部门",
          value: "nodeDeptId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          disabled: true,
          placeholderText: "请选择生产分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          // change: this.getChildDeptList,
          rules: [
            {
              required: true,
              message: "请选择生产分管部门",
              trigger: "blur",
            },
          ],
        },
        {
          label: "责任人",
          value: "nodeUserId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择责任人",
          optionsListConfig: {
            name: "fullName",
            code: "userId",
          },
          rules: [
            {
              required: true,
              message: "请选择责任人",
              trigger: "blur",
            },
          ],
        },
        {
          label: "供应商",
          value: "nodePurId",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择供应商",
          optionsListConfig: {
            name: "purName",
            code: "purId",
          },
          rules: [
            {
              required: true,
              message: "请选择供应商",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getDataType();
  },
  mounted() {
    if (this.rowData != null) {
      // 主节点外协可编辑供应商
      if (this.rowData.nodeType != "PRJ-RWJHJDLX-ZJDWX") {
        this.formBasicList.forEach((item, index) => {
          if (item.value == "nodePurId") {
            this.formBasicList.splice(index, 1);
          }
        });
      }
      // 主节点外部门可编辑生产分管部门
      // if (this.rowData.nodeType == "PRJ-RWJHJDLX-ZJDWBM") {
      //   this.formBasicList.forEach((item, index) => {
      //     if (item.value == "nodeDeptId") {
      //       item.disabled = false;
      //     }
      //   });
      // }
      // 标准节点只可编辑负责人
      if (this.rowData.isStandardWorkpackage == 1) {
        this.formBasicList.forEach((item, index) => {
          if (item.value == "nodeDeptBranchId") {
            item.disabled = true;
          }
        });
      }
      if (this.rowData.nodeDeptBranchId) {
        // this.getChildDeptPersonList(this.rowData.nodeDeptBranchId, "init");
        // this.getFenDetpList(this.rowData.nodeDeptBranchId);
        this.getFenDetpList(this.rowData.nodeDeptBranchId)
      }
      this.formBasicList.forEach((item) => {
        item.defaultValue = this.rowData[item.value];
      });
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      getApi(`/admin/sysfendept/getList`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "nodeDeptId",
            optionsList: data.data
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "nodeDeptBranchId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
      // 查询负责人
      // postApi(`/admin/user/getuserlist/bydeptid`, {}).then((res) => {
      //   let { data } = res;
      //   if (data.code == 0) {
      //     let options = {
      //       value: "nodeUserId",
      //       optionsList: data.data,
      //     };
      //     this.setOPtionsList(this.formBasicList, options);
      //   }
      // });
      // 节点状态
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-RWJHJDZT" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "nodeState",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        }
      );
      // 供应商列表
      getApi(`/project/purinfo/list`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "nodePurId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      });
    },
    // 根据部门查询分管部门
    getFenDetpList(deptId){
      this.formBasicList.forEach((item) => {
        if(item.value == 'nodeDeptBranchId' || item.value == 'nodeDeptId' || item.value == 'nodeUserId'){
          item.defaultValue = '';
        }
        else{
          item.defaultValue = this.rowData[item.value]
        }
      })
      if(deptId != ''){
        postApi(`/admin/dept/getfenbydeptid`, {deptId:deptId}).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formBasicList.forEach((item) => {
              if(item.value == 'nodeDeptBranchId'){
                item.defaultValue = deptId;
              }
              else if (item.value == "nodeDeptId") {
                item.defaultValue = data.data.id;
              }
              else{
                item.defaultValue = this.$refs.addPopForm.formData[item.value];
              }
            })
          }
        });

        // 根据部门查询责任人
        postApi(`/admin/user/getuserlist/bydeptid`, { deptId: deptId }).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formBasicList.forEach((item) => {
              if (item.value == "nodeUserId") {
                item.optionsList = data.data;
              }
              else{
                item.defaultValue = this.$refs.addPopForm.formData[item.value];
              }
            })
          }
        });
      }
    },
    // 根据部门获取子部门列表
    // getChildDeptList(deptId, type) {
    //   postApi(`/admin/sysdeptbranch/list`, {
    //     deptId: deptId,
    //   }).then((res) => {
    //     let { data } = res;
    //     if (data.code == 0) {
    //       this.formBasicList.forEach((item) => {
    //         if (item.value == "nodeDeptId") {
    //           item.defaultValue = deptId;
    //         } else {
    //           item.defaultValue = this.$refs.addPopForm.formData[item.value];
    //         }
    //         if (item.value == "nodeDeptBranchId") {
    //           item.optionsList = data.data;
    //           if (type != "init") {
    //             item.defaultValue = null;
    //           }
    //         }
    //         if (item.value == "nodeUserId") {
    //           if (type != "init") {
    //             item.defaultValue = null;
    //           }
    //         }
    //       });
    //     }
    //   });
    // },
    // 根据部门获取人员列表
    getChildDeptPersonList(deptBranchId, type) {
    //   getApi(`/admin/user/getuserlistbysysdeptbranch`, {
    //     deptBranchId: deptBranchId,
    //   }).then((res) => {
    //     let { data } = res;
    //     if (data.code == 0) {
    //       this.formBasicList.forEach((item) => {
    //         if (item.value == "nodeDeptBranchId") {
    //           item.defaultValue = deptBranchId;
    //         } else {
    //           item.defaultValue = this.$refs.addPopForm.formData[item.value];
    //         }
    //         if (item.value == "nodeUserId") {
    //           item.optionsList = data.data;
    //           if (type != "init") {
    //             item.defaultValue = null;
    //           }
    //         }
    //       });
    //     }
    //   });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
</style>
