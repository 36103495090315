<template>
  <div class="container">
    <div class="ibox-title">
      <h5 class="h5">预算汇总</h5>
      <el-table
        :data="expenseTable"
        ref="totalAmountTable"
        v-if="amountType == 'totalAmount'"
      >
        <el-table-column prop="amountLabel" label="明细类别" align="center">
        </el-table-column>
        <el-table-column
          prop="sum"
          label="合计"
          align="center"
        ></el-table-column>
        <el-table-column prop="pro" label="生产类" align="center">
        </el-table-column>
        <el-table-column prop="sale" label="销售类" align="center">
        </el-table-column>
        <el-table-column prop="man" label="管理类" align="center">
        </el-table-column>
        <el-table-column prop="dev" label="研发类" align="center">
        </el-table-column>
      </el-table>
      <el-table :data="realTotalAmountTable" ref="realTotalAmountTable" v-else>
        <el-table-column prop="amountLabel" label="明细类别" align="center">
        </el-table-column>
        <el-table-column
          prop="sum"
          label="合计"
          align="center"
        ></el-table-column>
        <el-table-column prop="pro" label="生产类" align="center">
        </el-table-column>
        <el-table-column prop="sale" label="销售类" align="center">
        </el-table-column>
        <el-table-column prop="man" label="管理类" align="center">
        </el-table-column>
        <el-table-column prop="dev" label="研发类" align="center">
        </el-table-column>
      </el-table>
    </div>
    <div class="ibox-title" v-if="amountType == 'totalAmount'">
      <h5 class="h5">类型明细</h5>
    </div>
    <div class="ibox-content" v-if="amountType == 'totalAmount'">
      <el-tabs v-model="activeName">
        <el-tab-pane label="生产类" name="first">
          <el-table
            :data="proTableData"
            height="300"
            style="width: 100%; margin-bottom: 20px"
            row-key="costTypeId"
            border
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            :expand-row-keys="expandRowKeys"
          >
            <el-table-column prop="code" label="费用类型编号" width="180">
            </el-table-column>
            <el-table-column prop="name" label="费用类型名称" width="180">
            </el-table-column>
            <el-table-column prop="amount" label="预算金额" width="180">
            </el-table-column>
            <el-table-column
              prop="realUseAmount"
              label="使用金额(元)"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane
          label="销售类"
          name="third"
          v-if="
            btnAuthorityList.indexOf('prj:budget:sale') != -1 &&
            this.isCurPrjCmmManager
          "
        >
          <el-table
            :data="saleTableData"
            height="300"
            style="width: 100%; margin-bottom: 20px"
            row-key="costTypeId"
            border
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            row-key:id
            :expand-row-keys="expandRowKeys"
          >
            <el-table-column prop="code" label="费用类型编号" width="180">
            </el-table-column>
            <el-table-column prop="name" label="费用类型名称" width="180">
            </el-table-column>
            <el-table-column prop="amount" label="预算金额" width="180">
            </el-table-column>
            <el-table-column
              prop="realUseAmount"
              label="使用金额(元)"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="管理类" name="second">
          <el-table
            :data="manTableData"
            height="300"
            style="width: 100%; margin-bottom: 20px"
            row-key="costTypeId"
            border
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            :expand-row-keys="expandRowKeys"
          >
            <el-table-column prop="code" label="费用类型编号" width="180">
            </el-table-column>
            <el-table-column prop="name" label="费用类型名称" width="180">
            </el-table-column>
            <el-table-column prop="amount" label="预算金额" width="180">
            </el-table-column>
            <el-table-column
              prop="realUseAmount"
              label="使用金额(元)"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="研发类" name="fourth">
          <el-table
            :data="devTableData"
            height="300"
            style="width: 100%; margin-bottom: 20px"
            row-key="costTypeId"
            border
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            :expand-row-keys="expandRowKeys"
          >
            <el-table-column prop="code" label="费用类型编号" width="180">
            </el-table-column>
            <el-table-column prop="name" label="费用类型名称" width="180">
            </el-table-column>
            <el-table-column prop="amount" label="预算金额" width="180">
            </el-table-column>
            <el-table-column
              prop="realUseAmount"
              label="使用金额(元)"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="remark" label="备注"> </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import { mapActions, mapState } from "vuex";
import qs from "qs";

export default {
  props: ["applyId", "projectId", "amountType"],
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      activeName: "first",
      tableData: [],
      proTableData: [],
      manTableData: [],
      saleTableData: [],
      devTableData: [],
      expandRowKeys: [],
      expenseTable: [
        {
          amountLabel: "预算金额",
          sum: 0,
          pro: 0,
          sale: 0,
          man: 0,
          dev: 0,
          amountType: "totalAmount",
        },
        {
          amountLabel: "剩余金额",
          sum: 0,
          pro: 0,
          sale: 0,
          man: 0,
          dev: 0,
          amountType: "residueAmount",
        },
      ],
      realTotalAmountTable: [
        // {
        //   amountLabel: "预算金额",
        //   sum: 0,
        //   pro: 0,
        //   sale: 0,
        //   man: 0,
        //   dev: 0,
        //   amountType: "totalAmount",
        // },
        // {
        //   amountLabel: "剩余金额",
        //   sum: 0,
        //   pro: 0,
        //   sale: 0,
        //   man: 0,
        //   dev: 0,
        //   amountType: "residueAmount",
        // },
        {
          amountLabel: "记账金额",
          sum: 0,
          pro: 0,
          sale: 0,
          man: 0,
          dev: 0,
          amountType: "realTotalAmount",
        },
        {
          amountLabel: "报账金额",
          sum: 0,
          pro: 0,
          sale: 0,
          man: 0,
          dev: 0,
          amountType: "claimAmount",
        },
      ],
      //判断当前用户角色，是否为当前项目经理角色
      isCurPrjCmmManager: false,
    };
  },
  created() {},
  mounted() {
    this.judgeIsCurPrjManager();
    this.getDetailList();
  },
  methods: {
    ...mapActions(["getBtnAuthority"]), //获取权限标识
    getDetailList() {
      if (!this.applyId) {
        this.getApplyIdInUsingByProjectId();
      } else {
        this.getTotal();
      }

      if (this.amountType == "realTotalAmount") {
        this.getResidue();
      }
    },
    getApplyIdInUsingByProjectId() {
      getApi(`/finance/apply/front/budgetInUsing/${this.projectId}`).then(
        (res) => {
          let { data } = res;
          this.applyId = data.id;
          this.getTotal();
        }
      );
    },
    getTotal() {
      let params = {id: this.applyId};
      postApi(`/finance/apply/front/detail`,params).then((res) => {
      // getApi(`/finance/apply/front/${this.applyId}`).then((res) => {
        let { data } = res;
        if (data.code === 0 && data.data && data.data.oneTypeVOList) {
          data.data.oneTypeVOList.forEach((item) => {
            if (item.name == "生产类") {
              let proAmount = [];
              let residueAmount = "0.00";
              if (this.filterDatas(item).amount) {
                residueAmount = this.filterDatas(item).amount;
              }
              let amount = "0.00";
              this.filterDatas(item).children.forEach((amounts) => {
                proAmount.push(amounts.amount);
              });
              if (proAmount.length > 0) {
                amount = proAmount.reduce((a, b) => {
                  return a + b;
                });
              }
              this.filterDatas(item).amount =
                amount + "\u00A0" + "\u00A0" + "(余额:" + residueAmount + ")";
              this.proTableData.push(this.filterDatas(item));
              if (item.amount) {
                this.getTotalAmount(
                  this.expenseTable,
                  "pro",
                  "totalAmount",
                  amount
                );
                this.getTotalAmount(
                  this.expenseTable,
                  "pro",
                  "residueAmount",
                  residueAmount
                );
              }
            } else if (item.name == "销售类") {
              let saleAmount = [];
              let residueAmount = "0.00";
              if (this.filterDatas(item).amount) {
                residueAmount = this.filterDatas(item).amount;
              }
              let amount = "0.00";
              this.filterDatas(item).children.forEach((amounts) => {
                saleAmount.push(amounts.amount);
              });
              if (saleAmount.length > 0) {
                amount = saleAmount.reduce((a, b) => {
                  return a + b;
                });
              }
              this.filterDatas(item).amount =
                amount + "\u00A0" + "\u00A0" + "(余额:" + residueAmount + ")";
              this.saleTableData.push(this.filterDatas(item));
              if (item.amount) {
                this.getTotalAmount(
                  this.expenseTable,
                  "sale",
                  "totalAmount",
                  amount
                );
                this.getTotalAmount(
                  this.expenseTable,
                  "sale",
                  "residueAmount",
                  residueAmount
                );
              }
            } else if (item.name == "管理类") {
              let manAmount = [];
              let residueAmount = "0.00";
              if (this.filterDatas(item).amount) {
                residueAmount = this.filterDatas(item).amount;
              }
              let amount = "0.00";
              this.filterDatas(item).children.forEach((amounts) => {
                manAmount.push(amounts.amount);
              });
              if (manAmount.length > 0) {
                amount = manAmount.reduce((a, b) => {
                  return a + b;
                });
              }
              this.filterDatas(item).amount =
                amount + "\u00A0" + "\u00A0" + "(余额:" + residueAmount + ")";
              this.manTableData.push(this.filterDatas(item));
              if (item.amount) {
                this.getTotalAmount(
                  this.expenseTable,
                  "man",
                  "totalAmount",
                  amount
                );
                this.getTotalAmount(
                  this.expenseTable,
                  "man",
                  "residueAmount",
                  residueAmount
                );
              }
            } else {
              let devAmount = [];
              let residueAmount = "0.00";
              if (this.filterDatas(item).amount) {
                residueAmount = this.filterDatas(item).amount;
              }
              let amount = "0.00";
              this.filterDatas(item).children.forEach((amounts) => {
                devAmount.push(amounts.amount);
              });
              if (devAmount.length > 0) {
                amount = devAmount.reduce((a, b) => {
                  return a + b;
                });
              }
              this.filterDatas(item).amount =
                amount + "\u00A0" + "\u00A0" + "(余额:" + residueAmount + ")";
              this.devTableData.push(this.filterDatas(item));
              if (item.amount) {
                this.getTotalAmount(
                  this.expenseTable,
                  "dev",
                  "totalAmount",
                  amount
                );
                this.getTotalAmount(
                  this.expenseTable,
                  "dev",
                  "residueAmount",
                  residueAmount
                );
              }
            }
          });

          this.getSumTotal();
        }
      });
    },
    getResidue() {
      let formData = {
        prjId: this.projectId,
      };
      postApi(`finance/expenseApply/getact/amount`, formData).then((res) => {
        res.data.data.forEach((item) => {
          this.getTotalAmount(
            this.realTotalAmountTable,
            Object.keys(item)[0],
            "realTotalAmount",
            item[Object.keys(item)]
          );
        });
      });
      postApi(`finance/expenseApply/get/claims/amount`, formData).then(
        (res) => {
          res.data.data.forEach((item) => {
            this.getTotalAmount(
              this.realTotalAmountTable,
              Object.keys(item)[0],
              "claimAmount",
              item[Object.keys(item)]
            );
          });
        }
      );
    },
    getSumTotal() {
      this.getTotalAmount(this.expenseTable, "sum", null, null);
      this.getTotalAmount(this.realTotalAmountTable, "sum", null, null);
    },
    getTotalAmount(formList, key, amountType, amounts) {
      formList.forEach((item) => {
        if (key == "sum") {
          item.sum = this.getSum(item);
        } else {
          if (item.amountType == amountType) {
            item[key] = parseFloat(amounts).toFixed(2);
          }
        }
      });
    },

    judgeIsCurPrjManager() {
      let params = {
        projectId: this.projectId,
      };
      postApi(`/finance/apply/front/isCurPrjCmmManager`, qs.stringify(params))
        .then((res) => {
          let { data } = res;
          if (data && true === data) {
            this.isCurPrjCmmManager = data;
          }
        })
        .catch((error) => {
          this.$message.error(error);
        });
    },
    filterDatas(data) {
      if (data && data.children) {
        data.children.map((item) => {
          item.amount = item.amount || 0.0;
        });

        this.expandRowKeys.push(data.costTypeId + "");
      }

      return data;
    },
    getSum(data) {
      let num = 0;
      if (data) {
        num =
          Number(data.pro) +
          Number(data.sale) +
          Number(data.man) +
          Number(data.dev);
      }
      return parseFloat(num).toFixed(2);
    },
  },
};
</script>
