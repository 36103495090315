import $ from 'jquery';
export function cesiumEvent(type, callback) {
  let handler = new Cesium.ScreenSpaceEventHandler(window.viewer.scene.canvas);
  //取消左键双击事件
  handler.setInputAction(function (movement) {
    viewer.trackedEntity = undefined;
  }, Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);
  // 左键点击事件
  let leftclick = Cesium.ScreenSpaceEventType.LEFT_CLICK;
  window.viewer.screenSpaceEventHandler.removeInputAction(leftclick);
  handler.setInputAction((e) => {
    // let pickObj = window.viewer.scene.globe.pick(window.viewer.camera.getPickRay(e.position), window.viewer.scene);
    let pickObj = window.viewer.scene.pick(e.position);
    callback(pickObj);
    if (Cesium.defined(pickObj)) {
      let id = pickObj.id;
      //点击3DTile
      if (pickObj.primitive instanceof Cesium.Cesium3DTileset) {
        //3dTiles的子模型属性
        let cartesian = window.viewer.scene.pickPosition(e.position);
        if (!Cesium.defined(cartesian)) {
          return;
        }
        let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
        let lonString = Cesium.Math.toDegrees(cartographic.longitude);
        let latString = Cesium.Math.toDegrees(cartographic.latitude);
        let elev = window.viewer.scene.globe.getHeight(cartographic);
        let name = "tilemodel"
        let tilepostion = {
          name,
          lonString,
          latString
        }
        callback(tilepostion);
        // 设置Popup属性
        popupPosition(lonString, latString, elev);
      }
      //点击Entity
      if (id && id.name != "measure_length" && id.name != "measure_area" && id.name != "measure_height" && id instanceof Cesium.Entity) {
        let cartesian = window.viewer.scene.pickPosition(e.position);
        if (!Cesium.defined(cartesian)) {
          return;
        }
        callback(id);
        let cartographic = Cesium.Cartographic.fromCartesian(cartesian);
        let lonString = Cesium.Math.toDegrees(cartographic.longitude);
        let latString = Cesium.Math.toDegrees(cartographic.latitude);
        let elev = window.viewer.scene.globe.getHeight(cartographic);
        // 设置Popup属性
        popupPosition(lonString, latString, elev);
      }

      //点击primitive
      if (pickObj.primitive instanceof Cesium.Primitive) {
        //primitive相关操作
      }

      //点击gltf/glb模型
      if (pickObj.primitive instanceof Cesium.Model) {
        if (type == "model" && callback != null) {
          pickObj.type = "model";
          callback(pickObj); // gltf ,glb 点击
          //模型gltf/glb相关操作
        }
      }
      if (pickObj.getProperty && pickObj.getProperty("name")) { //点击3DTile
        //pickObj.getProperty("name"): 子模型名称
        //模型相关操作
      }

      if (e && e.stopPropagation) {
        e.stopPropagation()
      } else {
        e.cancelBubble = true
      }
      return
    }
  }, leftclick)

}

function popupPosition(lonString, latString, elev) {
  let scratch = new Cesium.Cartesian2();
  //试试更新位置
  window.viewer.scene.postRender.addEventListener(function () {
    var p = Cesium.Cartesian3.fromDegrees(lonString, latString, elev);
    //转为屏幕坐标
    var canvasPosition = window.viewer.scene.cartesianToCanvasCoordinates(p, scratch)
    if (Cesium.defined(canvasPosition)) {
      $(".cardBox").css("top", parseFloat(canvasPosition.y) - parseFloat($(".cardBox").outerHeight() - 350) + 'px');
      $(".cardBox").css("left", (parseFloat(canvasPosition.x) + 30) + 'px');
      $(".cardBox").css("display", "block");
      
      // //解决滚动条不隐藏问题
      // var j = window.viewer.camera.position,
      //   n = window.viewer.scene.globe.ellipsoid.cartesianToCartographic(j).height;
      // n += window.viewer.scene.globe.ellipsoid.maximumRadius;
      // if (!(Cesium.Cartesian3.distance(j, p) > n) && window.viewer.camera.positionCartographic.height < 500000) {
      //   $(".cardBox").css("display", "block");
      // } else {
      //   $(".cardBox").css("display", "none");
      // }
    }
  });
}