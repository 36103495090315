<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
    <dd-card title="子部门列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        :isSelection="false"
        ref="dTableRef"
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            icon="add"
            size="medium"
            @click="handleAddEdit(0)"
            >新增</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      width="40%"
      height="65%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="dialogVisible"
      :title-name="popTitle"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop
        v-if="dialogVisible"
        slot="main"
        ref="addPop"
        :type="type"
        :id="id"
      ></add-pop>
    </dd-popup>
    <dd-popup
      width="80%"
      height="95%"
      showBtn
      :saveBtn="false"
      :cancelBtn="true"
      :show="catalogPopupVisible"
      :title-name="popTitle"
      @popup-close="handleClose"
    >
      <catalog-pop
        v-if="catalogPopupVisible"
        slot="main"
        :row="rowData"
        ref="addRightPop"
      ></catalog-pop>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="handleClose"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import { postApi, getApi, loadApi, delApi, putApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
import catalogPop from "./components/AddCatalogPop.vue";
export default {
  name: "subDept",
  $dynamicRoute: {
    name: "子部门管理",
    path: "/admin/sub/department/index",
  },
  components: { addPop, catalogPop },
  data() {
    return {
      tableHeight: null,
      showBtn: true,
      type: "",
      id: "",
      popTitle: "",
      drawerVisible: false,
      catalogPopupVisible: false,
      dialogDetailVisible: false,
      processInstanceId: null,
      loading: false,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      rowData: {},
      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "子部门名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入子部门名称",
          title: true,
        },
        {
          label: "子部门编码",
          value: "code",
          inputType: "text",
          placeholderText: "请输入子部门编码",
          title: true,
        },
        {
          label: "部门名称",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索部门名称",
          filterable: true,
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "子部门名称",
          key: "name",
          align: "center",
          width: 200,
          overflow: true,
        },
        {
          title: "子部门编码",
          key: "code",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "部门名称",
          key: "deptName",
          width: 180,
          align: "center",
        },
        {
          title: "子部门负责人",
          key: "userName",
          align: "center",
          width: 180,
        },
        {
          title: "子部门类型",
          key: "deptBranchTypeName",
          align: "center",
          width: 220,
          overflow: true,
          formatterArr: (row) => {
            return row;
          },
        },
        {
          title: "关联区域",
          key: "companyRegionName",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "关联核算组",
          key: "accountingName",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "排序",
          key: "sortOrder",
          align: "center",
          width: "80px",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          width: "300px",
          event: [
            {
              label: "人员管理",
              icon: "el-icon-tickets",
              type: "primary",
              state: "default",
              method: (row) => {
                this.handleAddEdit(3, row);
              },
            },
            {
              label: "编辑",
              state: "default",
              icon: "el-icon-edit",
              type: "warning",
              method: (row) => {
                this.handleAddEdit(1, row);
              },
            },
            {
              label: "删除",
              state: "default",
              type: "danger",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleDelete(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {},
  mounted() {
    // 获取状态
    this.getDeptList();
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  // computed: {
  //   ...mapState(["btnAuthorityList"]), //权限标识数组
  // },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //获取部门列表
    getDeptList() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          if (data.code == 0) {
            this.formList.forEach((item) => {
              if (item.value == "deptId") {
                item.optionsList = data.data;
              }
            });
          }
        }
      });
    },
    // 列表请求
    getApplyList() {
      this.handleClose();
      let { formData, current, size } = this;
      let params = {
        current,
        size,
        ...formData,
      };
      getApi(`/admin/sysdeptbranch/page`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            let arr = item.deptBranchTypeName
              ? item.deptBranchTypeName.split(",")
              : [];
            item.deptBranchTypeName = arr;
          });
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },

    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },

    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getApplyList();
    },
    // 新增弹框关闭事件
    handleClose() {
      this.dialogVisible = false;
      this.drawerVisible = false;
      this.catalogPopupVisible = false;
      this.dialogDetailVisible = false;
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.multipleSelection = [];
    },
    handleAddEdit(type, row) {
      // type 0: 新建；1：编辑；
      if (type == 0) {
        this.id = null;
        this.type = "add";
        this.rowData = {};
        this.popTitle = "新增";
        this.dialogVisible = true;
        this.showBtn = true;
      } else if (type == 1) {
        this.id = row.id;
        this.type = "update";
        this.rowData = row;
        this.popTitle = "编辑";
        this.dialogVisible = true;
        this.showBtn = true;
      } else if (type == 3) {
        this.id = row.id;
        this.rowData = row;
        this.popTitle = "人员管理";
        this.catalogPopupVisible = true;
        this.showBtn = true;
      }
    },
    handleDelete(row) {
      this.$confirm("是否确定删除？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          delApi(`/admin/sysdeptbranch/${row.id}`)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getApplyList();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} size 每页显示条数
     * @param {*} current 第几页
     */
    handlePageChange(size, current) {
      this.size = size;
      this.current = current;
      this.getApplyList();
    },
    // //校验输入框的值
    handleInputReg(val) {
      let value = val.applyWorkload;
      let regex = /^\d+(\.\d{1,2})?$/; // 匹配整数或最多两位小数的数字
      if (!regex.test(value)) {
        val.applyWorkload = "";
        this.$message.warning("请输入正确的格式!");
        return;
      }
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (flag) {
        if (this.id != null && this.popTitle == "编辑") {
          params.id = this.id;
          putApi(`/admin/sysdeptbranch`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.handleClose();
              this.getApplyList();
            }
          });
        } else {
          postApi(`/admin/sysdeptbranch`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getApplyList();
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
