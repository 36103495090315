<template>
  <div class="base-filter-drawer">
    <el-drawer
      :title="titleName"
      :visible.sync="show"
      direction="rtl"
      @closed="popupClose"
      :size="width"
    >
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        max-height="750px"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column type="index" label="序号" align="center">
        </el-table-column>
        <el-table-column label="列字段" align="center" prop="title">
        </el-table-column>
        <el-table-column label="列编码" align="center" prop="key">
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "dd-filter-drawer",
  props: {
    tableColumns: {
      type: Array,
      default: [],
    },
    columns: {
      type: Array,
      default: [],
    },
    show: {
      type: Boolean,
      default: false,
    },
    titleName: {
      type: String,
      default: "弹框标题",
    },
    width: {
      type: String,
      default: "30%",
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      this.tableColumns.map((item) => {
        if (!item.event) {
          this.tableData.push(item);
        }
      });
      this.$nextTick(() => {
        this.columns.map((row) => {
          if (!row.event) {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          }
        });
      });
    },
    handleSelectionChange(val) {
      this.$emit("handleColumnsChange", val);
    },
    // 弹框关闭事件
    popupClose() {
      this.$emit("popup-close", false);
    },
  },
};
</script>

<style lang="less" scoped>
.base-filter-drawer {
  width: 100%;
}
/deep/.el-table__header-wrapper {
  .el-checkbox {
    display: none !important;
  }
}
</style>