<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="文件列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        isSort
        border
        :isSelection="false"
        show-page
      >
        <template #table-btn>
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd"
            >新增</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="30%"
      height="40%"
      :showBtn="false"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        @file-seccess="handleClose('refresh')"
      ></add-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi, downBlobApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
export default {
  name: "file",
  $dynamicRoute: {
    name: "文件管理",
    path: "/admin/file/index",
  },
  components: {
    addPop,
  },
  computed: {},
  data() {
    return {
      tableHeight: null,
      drawerVisible: false,
      formData: {},
      formList: [
        {
          label: "文件名",
          value: "fileName",
          inputType: "text",
          placeholderText: "请输入文件名",
        },
      ],
      tableColumns: [
        {
          title: "空间",
          key: "bucketName",
          align: "center",
        },
        {
          title: "文件名",
          key: "fileName",
          align: "center",
          overflow: true,
        },
        {
          title: "原文件名",
          key: "original",
          align: "center",
          overflow: true,
        },
        {
          title: "文件类型",
          key: "type",
          align: "center",
        },
        {
          title: "文件大小(kb)",
          key: "fileSize",
          align: "center",
        },
        {
          title: "上传人",
          key: "createBy",
          align: "center",
        },
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 300,
          event: [
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
            },
            {
              show: "true",
              label: "下载",
              icon: "el-icon-download",
              method: (row) => {
                this.handleExport(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "在线预览",
              icon: "el-icon-view",
              method: (row) => {
                this.handlePreview(row);
              },
              type: "primary",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      addPopupVisible: false,
      poptitleName: "新增",
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getTableData();
  },
  methods: {
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        descs: "create_time",
        current,
        size,
      };
      getApi(`/admin/sys-file/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "附件上传";
    },
    // 新增弹框关闭事件
    handleClose(type) {
      this.rowData = null;
      this.addPopupVisible = false;
      if (type == "refresh") {
        this.getTableData();
      }
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除id为${row.id}的文件数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/admin/sys-file/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    // 导出下载事件
    handleExport(row) {
      downBlobApi(`/admin/sys-file/ssh/${row.fileName}`, {}, row.fileName);
    },
    // 附件预览
    handlePreview(row) {
      getApi(`/admin/sys-file/online/ssh/${row.fileName}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let url = data.data;
          window.open(url);
        }
      });
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (flag) {
        if (this.rowData != null) {
          params.publicId = this.rowData.publicId;
          putApi(`/admin/param`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.handleClose();
              this.getTableData();
            }
          });
        } else {
          postApi(`/admin/param`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
