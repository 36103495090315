<template>
  <div class="main-container">
    <div class="left-box">
      <dd-card title="类别树">
        <div class="container-left-tree">
          <el-tree
            ref="tree"
            :data="TreeList"
            accordion
            :props="defaultProps"
            default-expand-all
            highlight-current
            node-key="id"
            :current-node-key="treeNodeId"
            @node-click="handleNodeClick"
          >
          </el-tree>
        </div>
      </dd-card>
    </div>
    <div class="right-box">
      <dd-card title="任务列表" height="100%">
        <dd-table
          :columns="columnsList"
          :data="tableData"
          border
          ref="dTableRef"
          tableRef="dTableRef"
          :isSort="true"
          :isSelection="false"
          :topBtn="false"
          :loading="loading"
        >
          <template #table-pagination>
            <dd-pagination
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
    <!-- 日记账审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="infoPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleSubmit('true', 'taskBasicForm', 'expenseDetailForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleSubmit('false', 'taskBasicForm', 'expenseDetailForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleSubmit('end', 'taskBasicForm', 'expenseDetailForm')"
        >终止
      </dd-button> -->
      <dayBookDetail
        v-if="infoPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></dayBookDetail>
    </dd-popup>
    <!-- 预算审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="budgetPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleBudgetSubmit('true', 'taskBasicForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleBudgetSubmit('false', 'taskBasicForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleBudgetSubmit('end', 'taskBasicForm')"
        >终止
      </dd-button> -->
      <budgetDetail
        v-if="budgetPopupVisible"
        slot="main"
        ref="budgetPop"
        :rowData="rowData"
      ></budgetDetail>
    </dd-popup>
    <!-- 外部用車审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="outerCarPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleSubmit('true', 'taskBasicForm', 'baseForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleSubmit('false', 'taskBasicForm', 'baseForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleSubmit('end', 'taskBasicForm', 'baseForm')"
        >终止
      </dd-button> -->
      <outerCarDetail
        v-if="outerCarPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></outerCarDetail>
    </dd-popup>
    <!-- 任务书审批弹窗 -->
    <dd-popup
      width="77%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="taskBookPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleTaskBookSubmit('true', 'taskBasicForm', 'baseForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleTaskBookSubmit('false', 'taskBasicForm', 'baseForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleTaskBookSubmit('end', 'taskBasicForm', 'baseForm')"
        >终止
      </dd-button> -->
      <taskBookDetail
        v-if="taskBookPopupVisible"
        slot="main"
        ref="taskBookPop"
        :rowData="rowData"
      ></taskBookDetail>
    </dd-popup>
    <!-- WBS分解审批弹窗 -->
    <dd-popup
      width="90%"
      height="90%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="taskResolveVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleTaskResolveSubmit('true', 'taskBasicForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleTaskResolveSubmit('false', 'taskBasicForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleTaskResolveSubmit('end', 'taskBasicForm')"
        >终止
      </dd-button> -->
      <taskResolveDetail
        v-if="taskResolveVisible"
        slot="main"
        ref="taskResolvePop"
        :rowId="rowData.proInstanceId"
      ></taskResolveDetail>
    </dd-popup>
    <!-- 文档审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="docFilesVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleDocFilesSubmit('false', 'taskBasicForm')"
        >同意
      </dd-button>
      <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleDocFilesSubmit('true', 'taskBasicForm')"
        >回退
      </dd-button>
      <fileDetail
        v-if="docFilesVisible"
        slot="main"
        ref="docFilesPop"
        :rowId="rowData.proInstanceId"
      ></fileDetail>
    </dd-popup>
    <!-- 预算编制审批 -->
    <dd-popup
      width="70%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="workOutVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleWorkOutSubmit('true', 'workOutForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleWorkOutSubmit('false', 'workOutForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleWorkOutSubmit('end', 'workOutForm')"
        >终止
      </dd-button> -->
      <WorkOutDetail
        v-if="workOutVisible"
        slot="main"
        ref="workOutPop"
        :rowId="rowData.proInstanceId"
      ></WorkOutDetail>
    </dd-popup>
  </div>
</template>
<script>
import { getApi, postApi } from "@/api/request";
// 日记账审批
import dayBookDetail from "./components/DaybookDetail.vue";
// 预算审批
import budgetDetail from "./components/BudgetDetail.vue";
// 外部租车审批
import outerCarDetail from "./components/OuterCarDetail.vue";
// 任务书审批
import taskBookDetail from "./components/TaskBookDetail.vue";
// wbs分解审批
import taskResolveDetail from "./components/TaskResolveDetail.vue";
// 文档审批
import fileDetail from "./components/FileDetail.vue";
// 预算编制
import WorkOutDetail from "./components/WorkOutDetail.vue";
import { getNowDate } from "@/utils/date.js";
export default {
  name: "todoTask",
  $dynamicRoute: {
    name: "待办任务",
    path: "/personalOffice/todoTask",
  },
  components: {
    dayBookDetail,
    budgetDetail,
    outerCarDetail,
    taskBookDetail,
    taskResolveDetail,
    fileDetail,
    WorkOutDetail,
  },
  data() {
    return {
      currentNode:'',
      workOutVisible: false,
      infoPopupVisible: false,
      budgetPopupVisible: false,
      outerCarPopupVisible: false,
      taskBookPopupVisible: false,
      taskResolveVisible: false,
      docFilesVisible: false,
      loading: true,
      deptId: "",
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      formData: {},
      tableData: [],
      formList: [
        {
          label: "申请人",
          value: "prjName",
          inputType: "text",
          placeholderText: "请输入申请人",
        },
      ],
      columnsList: [
        {
          title: "申请人",
          key: "username",
          align: "center",
        },
        {
          title: "主题",
          key: "subject",
          align: "center",
        },
        {
          title: "发起时间",
          key: "startTime",
          align: "center",
        },
        {
          title: "当前节点",
          key: "taskName",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: "120px",
          event: [
            {
              show: "true",
              label: "任务办理",
              icon: "el-icon-info",
              method: (row) => {
                this.handleInfo(row);
              },
              type: "success",
            },
          ],
        },
      ],
      total: 0,
      current: 1,
      size: 10,
      total: null,
      pointInfo: "",
      treeNodeId: "",
      rowData: null,
      defaultTreeId: null,
    };
  },
  mounted() {
    this.getLeftTree(); //组织机构树
  },
  methods: {
    // 获取左侧树形数据
    getLeftTree() {
      getApi(`/activiti/wflcategory/tree`, {}).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.TreeList = data.data;
          this.treeNodeId = data.data[0].id;
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
          });
          this.getTableData();
        }
      });
    },
    handleNodeClick(data) {
      this.treeNodeId = data.id;
      this.getTableData();
    },
    // 获取表格列表数据
    getTableData() {
      let { current, size } = this;
      let params = {
        current,
        size,
        category: this.treeNodeId,
      };
      this.loading = true;
      getApi(`/activiti/workflow/todo/list`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.username = item.initator.fullName;
          });
          this.tableData = data.data.records;
          this.total = data.data.total;
          this.loading = false;
        }
      });
    },
    // 日记账审批提交
    handleSubmit(type, formName, formNames) {
      let { rowData } = this;
      if (
        rowData.proDefKey == "PrjBudgetApply" ||
        rowData.proDefKey == "PrjJournalApply"
      ) {
        let flag = false;
        this.$refs.addPop.$refs[formName].$refs[formName].validate((valid) => {
          if (valid) {
            flag = true;
          }
        });
        if (flag) {
          let obj = this.$refs.addPop.$refs[formName].formData;
          let obj1 = this.$refs.addPop.$refs[formNames].formData;
          let params = {
            ...obj,
            reject: type,
            reviewAmount: obj1.reviewAmount,
            applyId: this.$refs.addPop.detailDataSourcrs.id,
            taskId: this.rowData.taskId,
            proInstanceId: this.rowData.proInstanceId,
          };
          postApi(`/finance/applysheet/review`, params).then((res) => {
            if (res.data.code == 0) {
              this.$message.success(res.data.data);
              this.infoPopupVisible = false;
              this.getTableData();
            }
          });
        }
      } else if (rowData.proDefKey == "RentalCar") {
        let flag = false;
        this.$refs.addPop.$refs[formName].$refs[formNames].validate((valid) => {
          if (valid) {
            flag = true;
          }
        });
        if (flag) {
          let { model } = this.$refs.addPop.$refs[formName].$refs[formNames];
          let params = {
            comment: model.comment,
            reject: type,
            applyId: this.$refs.addPop.detailDataSourcrs.id,
            taskId: this.rowData.taskId,
          };
          postApi(`/hr/rentalcar/applysheet/review`, params).then((res) => {
            if (res.data.code == 0) {
              this.$message.success(res.data.data);
              this.outerCarPopupVisible = false;
              this.getTableData();
            }
          });
        }
      }
    },
    // 预算审批提交
    handleBudgetSubmit(type, formName, formNames) {
      let flag = false;
      this.$refs.budgetPop.$refs[formName].$refs[formName].validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (flag) {
        let obj = this.$refs.budgetPop.$refs[formName].formData;
        let oneTypeVOList = [];
        let { tabArr } = this.$refs.budgetPop;
        let amount = 0;
        tabArr.forEach((item) => {
          item.children.forEach((val) => {
            amount += val.amount;
          });
          oneTypeVOList.push(item.children[0]);
        });
        let params = {
          ...obj,
          reject: type,
          procDefKey: this.rowData.proDefKey,
          oneTypeVOList: oneTypeVOList,
          id: this.$refs.budgetPop.detailDataSourcrs.id,
          expenseApplyIds: this.$refs.budgetPop.detailDataSourcrs.id,
          taskIds: this.rowData.taskId,
          proInstanceId: this.rowData.proInstanceId,
          amount: amount,
        };
        postApi(`/finance/apply/front/review`, params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.data);
            this.budgetPopupVisible = false;
            this.getTableData();
          }
        });
      } else {
        this.$message.warning("请输入审批意见！");
      }
    },
    // 节点
    getStep(){
      getApi(`/activiti/process/viewPage/${this.rowData.proInstanceId}`).then(
        (res) => {
          if (res.data.code == 0) {
            let currentNode = '';
            res.data.data.forEach(item => {
              if(item.isCurrentActiviti == 1){
                this.currentNode = item.activityId;
              }
            })
          }
        }
      );
    },
    // 任务书审批提交
    handleTaskBookSubmit(type, formName, formNames) {
      // this.getStep();
      let flag = false;
      let infoFlag = false;
      let content = this.$refs.taskBookPop.$refs[formName].formData.comment;
      if(content == ''){
        this.$message.warning("请输入审批意见！");
      }else{
        flag = true;
      }
      if (this.rowData.taskDefinKey == "scbmfzr") {
        this.$refs.taskBookPop.$refs.addPopForm.$refs.baseForm.validate(
          (valid) => {
            if (valid) {
              infoFlag = true;
            } else {
              this.$message.warning("请选择生产负责人！");
            }
          }
        );
      } else {
        infoFlag = true;
      }
      if (flag && infoFlag) {
        let obj = this.$refs.taskBookPop.$refs[formName].formData;
        // let baseInfo = this.$refs.taskBookPop.$refs.addPopForm.formData;
        let params = {
          ...obj,
          reject: type,
          applyId: this.$refs.taskBookPop.detailData.id,
          taskId: this.rowData.taskId,
          proInstanceId: this.rowData.proInstanceId,
          receiveDeptId:this.$refs.taskBookPop.$refs['addPopForm'].formData.receiveDeptId,
          leaderId:this.$refs.taskBookPop.$refs['addPopForm'].formData.leaderId,
          businessTypeId: this.$refs.taskBookPop.$refs['addPopForm'].formData.businessTypeId,
          produceType: this.$refs.taskBookPop.$refs['addPopForm'].formData.produceType,
        };
        // 接收部门分管负责人
        if(this.rowData.taskDefinKey == 'jsbmfgfzr'){
          // params.receiveDeptId = this.$refs.taskBookPop.$refs['addPopForm'].formData.receiveDeptId;
          // params.leaderId = this.$refs.taskBookPop.$refs['addPopForm'].formData.leaderId;
          if(params.receiveDeptId == ''){
            return this.$message.warning("请选择任务生产部门！");
          }
        }
        // 接收部门负责人
        console.log('this.rowData.taskDefinKey', this.rowData.taskDefinKey)
        if(this.rowData.taskDefinKey == 'jsbmfzr'){
          // params.leaderId = this.$refs.taskBookPop.$refs['addPopForm'].formData.leaderId;
          if(params.leaderId == ''){
            return this.$message.warning("请选择项目经理！");
          }
          if(params.businessTypeId == ''){
            return this.$message.warning("请选择业务类型！");
          }
          if(params.produceType == ''){
            return this.$message.warning("请选择生产控制模式！");
          }
        }
        postApi(`/project/assign/review`, params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.data);
            this.taskBookPopupVisible = false;
            this.getTableData();
          }
        });
      }
    },
    // 预算编制审批
    handleWorkOutSubmit(type, formName) {
      let flag = false;
      this.$refs.workOutPop.$refs[formName].$refs[formName].validate(
        (valid) => {
          if (valid) {
            flag = true;
          } else {
            this.$message.warning("请输入审批意见！");
          }
        }
      );

      if (flag) {
        let obj = this.$refs.workOutPop.$refs[formName].formData; 
        let params = {
          ...obj,
          reject: type,
          applyId: this.$refs.workOutPop.$refs.assignForm.formData.id,
          taskId: this.rowData.taskId,
          proInstanceId: this.rowData.proInstanceId,
        };
        postApi(`/finance/budget/establishment/review`, params).then(res => {
          if(res.data.code == 0) {
            this.$message.success(res.data.data);
            this.workOutVisible = false;
            this.getTableData();
          }
        })
      }
    },
    // WBS任务分解审批提交
    handleTaskResolveSubmit(type, formName) {
      let flag = false;
      this.$refs.taskResolvePop.$refs[formName].$refs[formName].validate(
        (valid) => {
          if (valid) {
            flag = true;
          } else {
            this.$message.warning("请输入审批意见！");
          }
        }
      );

      if (flag) {
        let obj = this.$refs.taskResolvePop.$refs[formName].formData;
        let params = {
          ...obj,
          reject: type,
          applyId: this.$refs.taskResolvePop.nodeTableData[0].subplanId,
          taskId: this.rowData.taskId,
          proInstanceId: this.rowData.proInstanceId,
        };
        postApi(`/project/subplannode/review`, params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.data);
            this.taskResolveVisible = false;
            this.getTableData();
          }
        });
      }
    },
    // 文档审批提交
    handleDocFilesSubmit(type, formName) {
      let flag = false;
      this.$refs.docFilesPop.$refs[formName].$refs[formName].validate(
        (valid) => {
          if (valid) {
            flag = true;
          } else {
            this.$message.warning("请输入审批意见！");
          }
        }
      );

      if (flag) {
        let obj = this.$refs.docFilesPop.$refs[formName].formData;
        let params = {
          ...obj,
          reject: type,
          applyId: this.$refs.docFilesPop.detailData.id,
          taskId: this.rowData.taskId,
          proInstanceId: this.rowData.proInstanceId,
        };
        postApi(`/project/subplannode/attachfile/review`, params).then(
          (res) => {
            if (res.data.code == 0) {
              this.$message.success(res.data.data);
              this.docFilesVisible = false;
              this.getTableData();
            }
          }
        );
      }
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.formData.deptId = this.deptId;
      if (this.deptId == "") {
        this.$message.warning("请先选择流程类别");
      } else {
        this.getTableData();
      }
    },
    handleClose() {
      this.pointInfo = "";
      this.infoPopupVisible = false;
      this.budgetPopupVisible = false;
      this.outerCarPopupVisible = false;
      this.taskBookPopupVisible = false;
      this.taskResolveVisible = false;
      this.docFilesVisible = false;
      this.workOutVisible = false;
    },
    handleInfo(row) {
      this.rowData = row;
      if (
        row.proDefKey == "PrjBudgetApply" ||
        row.proDefKey == "PrjBudgetRevise"
      ) {
        this.budgetPopupVisible = true;
      } else if (row.proDefKey == "PrjJournalApply") {
        this.infoPopupVisible = true;
      } else if (row.proDefKey == "RentalCar") {
        this.outerCarPopupVisible = true;
      } else if (row.proDefKey == "PrjAssign") {
        this.taskBookPopupVisible = true;
      } else if (row.proDefKey == "PrjAssignSubplan") {
        this.taskResolveVisible = true;
      } else if (row.proDefKey == "PrjAssignAttachfile") {
        this.docFilesVisible = true;
      }else if(row.proDefKey == 'FinBudgetEstablishment') {
        this.workOutVisible = true
      }
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  position: relative;
  display: flex;

  .left-box {
    width: 20%;
    border-right: 5px solid @dividerColor;
  }

  .right-box {
    width: 80%;
  }
}
</style>
