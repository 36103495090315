<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="汇总单列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        tableRef="dTableRef"
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            icon="export"
            type="primary"
            size="medium"
            autoConfirm
            @click="handleExport"
            >导出
          </dd-button>
          <dd-button
            type="primary"
            size="medium"
            autoConfirm
            icon="add"
            @click="handleAddEdit(0)"
            >生成汇总单
          </dd-button>
          <dd-button
            type="primary"
            size="medium"
            icon="update"
            @click="handleAddEdit(1)"
            >修改
          </dd-button>

          <dd-button
            type="danger"
            icon="delete"
            size="medium"
            autoConfirm
            @click="handleDelete"
            >删除
          </dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
          <!--          <el-button-->
          <!--            icon="el-icon-download"-->
          <!--            circle-->
          <!--            type="info"-->
          <!--            @click="handleFileExport"-->
          <!--            size="small"-->
          <!--            title="下载"-->
          <!--          ></el-button>-->
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      v-if="dialogVisible"
      width="85%"
      height="80%"
      :title-name="addEditTitle"
      :show="dialogVisible"
      @popup-close="dialogVisible = false"
    >
      <SummarySheet
        slot="main"
        :type="type"
        :id="id"
        :collectDepName="collectDepName"
        :collectDepIdProp="collectDepId"
        @diaChange="diaChange"
      ></SummarySheet> 
    </dd-popup>

    <dd-popup
      v-if="dialogDetailVisible"
      width="70%"
      height="80%"
      :title-name="addEditTitle"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
    >
      <SummaryDetail
        slot="main"
        @diaChange="diaChange"
        :type="type"
        :id="id"
      ></SummaryDetail>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import SummarySheet from "./components/SummarySheet";
import SummaryDetail from "../Administration/components/SummaryDetail";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
import qs from "qs";

export default {
  name: "mothlySummarySheet",
  $dynamicRoute: {
    name: "私车公用部门月度汇总单",
    path: "/personalCar/mothlySummarySheet/list",
  },
  components: { SummaryDetail, SummarySheet },
  data() {
    return {
      tableHeight: null,
      addEditTitle: null,
      drawerVisible: false,
      dialogDetailVisible: false,
      collectDepId: null,
      collectDepName: null,
      processInstanceId: null,
      loading: false,
      id: null,
      type: null,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,

      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "汇总单编号",
          value: "collectCode",
          inputType: "text",
          placeholderText: "请输入汇总单编号",
          title: true,
        },
        {
          label: "月份",
          value: "applyDate",
          inputType: "date",
          dateType: "monthrange",
          format: "yyyy-MM",
        },
        {
          label: "上报状态",
          value: "reportStatus",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索上报状态",
          // optionsListConfig: {
          //     name: "label",
          //     code: "code",
          // },
        },
        {
          label: "部门",
          value: "collectDepId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索部门列表",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "汇总单编号",
          key: "collectCode",
          align: "center",
          href: [
            {
              method: (row) => {
                this.handleDetailInfo(row, 0);
              },
            },
          ],
        },
        {
          title: "单据名称",
          key: "collectName",
          align: "center",
          overflow: true,
        },
        {
          title: "组织",
          key: "collectOrgName",
          align: "center",
        },
        {
          title: "部门",
          key: "collectDepName",
          align: "center",
        },
        {
          title: "月份",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "上报状态",
          key: "reportStatus",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "汇总详情",
              method: (row) => {
                this.handleDetailInfo(row, 1);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getConditionData(); //获取部门数据
    this.getStateList();
    // this.getExpenseDepartmentList();
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    //获取部门数据
    getConditionData() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.formList.forEach((item) => {
          if (item.value == "collectDepId") {
            item.optionsList = res.data.data;
          }
        });
      });
    },
    getExpenseDepartmentList() {
      getApi(`/middle/dep/depList`, {
        orgId: sessionStorage.getItem("curOrgId"),
      }).then((res) => {
        let { data } = res;
        console.log(data);
        this.formList.forEach((item) => {
          if (item.value == "collectDepId") {
            item.optionsList = data;
          }
        });
        console.log(this.formList);
      });
    },
    diaChange(e) {
      this.dialogDetailVisible = e;
      this.dialogVisible = e;
      console.log(e);
      this.id = null;
      // this.state = null;
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.getApplyList();
    },
    // 详情
    handleDetailInfo(row, type) {
      if (type == "0") {
        // 查看详情
        this.id = row.id;
        this.addEditTitle = "申请单详情";
        this.type = "detail";
        this.dialogVisible = true;
      } else {
        /*
        dxzqr xzyqr 
         */
        /*
        待行政确认,行政已确认 
         */
        if (
          row.reportStatus != "待行政确认" &&
          row.reportStatus != "行政已确认"
        ) {
          this.$message.warning("只能选择待行政确认或行政已确认的数据!");
          return;
        }
        this.id = row.id;
        this.addEditTitle = `${row.collectDepName}${row.reportMonth}车辆补贴汇总单`;
        this.type = "hz";
        this.dialogDetailVisible = true;
        //汇总详情
      }
    },
    // 总金额（预算明细)
    handleDetail(val) {
      this.applyDetailTitle = "预算明细";
      this.applyId = val.id;
      this.projectId = val.projectId;
      this.dialogDetailVisible = true;
      this.amountType = "totalAmount";
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (!item.event) {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      console.log(downKeyList);
      console.log(this.formData);
      console.log(ids);
      let formData = {
        ...this.formData,
        current: 1,
        size: 99999,
      };
      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/apply/front/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    // 预算申请列表请求
    getApplyList() {
      this.loading = true;
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
        // collectOrgId: sessionStorage.getItem("curOrgId"),
        // collectDepId: sessionStorage.getItem("depId"),
      };
      postApi(`/hr/collectsheet/dept/page`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          // data.data.records.forEach((item) => {
          //   item.totalAmount = formatNumber(item.totalAmount);
          // });
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
          // this.loading = false;
        }
      });
    },
    // 单据状态下拉数据请求
    getStateList() {
      postApi(`/hr/hrdict/list?typeCode=djsbzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "reportStatus") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      this.pageNumber = 1;
      this.getApplyList();
    },
    /* 导出 */
    handleExport() {
      let ids = [];
      if (
        this.multipleSelection.length &&
        this.multipleSelection[0].reportStatus === "已退回"
      ) {
        this.$message.warning("已退回的汇总单无汇总信息！");
        return;
      }

      this.multipleSelection.length &&
        this.multipleSelection.map((item) => {
          ids.push(item.id);
        });

      if (this.multipleSelection.length != 1) {
        this.$message.warning("请选择一条数据！");
        return;
      }

      let params = {
        formData: {
          current: 1,
          size: 99999,
          collectId: this.multipleSelection[0].id,
        },
      };

      console.log("params", params);
      loadApi(`/hr/collectsheet/export/statistics/person`, params)
        .then((res) => {
          let { data } = res;
          downloadBinaryFile(
            data,
            decodeURIComponent(
              res.headers["content-disposition"].split(";")[1].split("=")[1]
            )
          );
        })
        .catch((error) => {
          this.$message.error("请求出错");
        });
    },
    handleAddEdit(type) {
      // type 0: 生产汇总；1：编辑
      if (type == 0) {
        if (this.multipleSelection.length > 0) {
          this.$message.warning("请取消选择数据！");
          return;
        }

        this.id = null;
        this.addEditTitle = "生成汇总单";
        this.type = "add";
        this.dialogVisible = true;
      } else {
        if (this.multipleSelection.length !== 1) {
          this.$message.warning("请选择一条数据！");
          return;
        }
        for (var i = 0; i < this.multipleSelection.length; i++) {
          if (
            this.multipleSelection[i].reportStatus != "未上报" &&
            this.multipleSelection[i].reportStatus != "已退回"
          ) {
            this.$message.warning("只能选择未上报或已退回状态数据！");
            return;
          }
        }
        this.id = this.multipleSelection[0].id;
        this.collectDepName = this.multipleSelection[0].collectDepName;
        this.collectDepId = this.multipleSelection[0].collectDepId;
        this.type = "update";
        this.addEditTitle = "修改汇总单";
        this.dialogVisible = true;
      }
    },
    handleDelete() {
      var idArr = [];
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请选择要删除的数据！");
        return;
      }
      for (var i = 0; i < this.multipleSelection.length; i++) {
        console.log(this.multipleSelection[i].reportStatus);
        if (
          this.multipleSelection[i].reportStatus != "未上报" &&
          this.multipleSelection[i].reportStatus != "已退回"
        ) {
          this.$message.warning("只能选择未上报或已退回状态数据！");
          return;
        } else {
          idArr.push(this.multipleSelection[i].id);
        }
      }
      var id = { ids: idArr };
      this.$confirm("是否确定删除汇总单？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          postApi(`/hr/collectsheet/delete`, id)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getApplyList();
                this.$refs.dTableRef.$refs.baseTable.clearSelection();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 格式化表格单据状态
    getState(cellValue) {
      if (cellValue == "dtj") {
        return "待提交";
      } else if (cellValue == "shz") {
        return "审核中";
      } else if (cellValue == "shtg") {
        return "审核通过";
      } else if (cellValue == "shbtg") {
        return "审核不通过";
      }
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "totalAmount") {
          const values = data.map((item) => Number(item[column.property]));
          console.log(values);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              console.log(value);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      let list = [...sums];
      list.map((item, index) => {
        if (!isNaN(+item)) {
          sums[index] = formatNumber(sums[index]);
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} size 每页显示条数
     * @param {*} current 第几页
     */
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getApplyList();
    },
    // // 弹框保存回调事件
    // handlePopupSave() {
    //   this.$refs.addEditPop.submitOrSave();
    // },
    // 弹框提交回调事件
    handlePopupSubmit() {},
    // 弹框重置回调事件
    handlePopupRest() {},
    //获取项目信息
    getProject() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.projectCodeOptions = {
            value: "projectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, this.options);
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>

<style>
.el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>
