<template>
      <div class="tab_nav_menu">
        <el-tabs 
          v-model="active"
          type="card"
          @contextmenu="handleContextmenu"
          :closable="tagLen!==1"
          @tab-click="openTag"
          @edit="menuTag"
        >
            <el-tab-pane 
              :key="item.value"
              v-for="item in tagList"
              :label="item.lable"
              :name="item.value"
            >
              <span slot="label" @contextmenu.stop.prevent="handleTagMouseRight(item,$event)">{{ item.label }}</span>
            </el-tab-pane>
        </el-tabs>
        <div class="tag_menu" v-show="tagMenuVisible">
          <div class="menu_list" v-for="val in tagMenu" :key="val.value" @click="handleTagMenu(val.value)">{{val.label}}</div>
        </div>
        <el-dropdown v-if="tagLen > 1" @command="handleTagMenu" class="more_handle" :show-timeout="150">
            <div>
              关闭操作<i class="el-icon-arrow-down el-icon--right"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="item in tagMenu"
                :key="item.value"
                :command="item.value"
              >{{item.label}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
      </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  export default {
    name: 'dd-tab',
    components: {},
    computed: {
      ...mapGetters(["tagWel", "tagList", "tag", "setting"]),
      tagLen () {
        return this.tagList.length || 0;
      }
    },
    data() {
      return {
        refresh: false,
        active: '',
        tagMenuVisible: false,
        tagMenu: [{
          label: '关闭其他',
          value: 'closeOthers'
        },{
          label: '关闭所有',
          value: 'closeAll'
        }]
      };
    },
    created() {},
    mounted() {
      this.active = this.tag.value;
    },
    watch: {
      tag: {
        handler (val) {
          this.active = val.value;
        },
      }
    },
    methods: {
      handleContextmenu (event) {
        let target = event.target;
        let flag = false;
        if (target.className.indexOf("el-tabs__item") > -1) flag = true;
        else if (target.parentNode.className.indexOf("el-tabs__item") > -1) {
          target = target.parentNode;
          flag = true;
        }
        if (flag) {
          event.preventDefault();
          event.stopPropagation();
          this.contentmenuX = event.clientX;
          this.contentmenuY = event.clientY;
          this.tagName = target.getAttribute("aria-controls").slice(5);
          this.contextmenuFlag = true;
        }
      },
      menuTag (value, action) {
        if (action === "remove") {
          let openTag; // 最终要打开的页面
          let { tag, key } = this.findTag(value);
          if (tag.value === this.tag.value) {
            openTag = this.tagList[key === 0 ? key : key - 1]; //如果关闭本标签让前推一个
          } else {
            openTag = this.tag;
            this.openTag(tag);
          }
          this.$store.commit("DEL_TAG", tag);
          this.tagMenuVisible = false;
          this.openTag(openTag);
        }
      },
      openTag (item) {
        if (item.props) item = item.props
        let tag;
        if (item.name) {
          tag = this.findTag(item.name).tag;
        } else {
          tag = item;
        }
        this.$router.push({
          path: tag.value,
          query: tag.query
        });
      },
      findTag (value) {
        let tag, key;
        this.tagList.map((item, index) => {
          if (item.value === value) {
            tag = item;
            key = index;
          }
        });
        return { tag: tag, key: key };
      },
      // 鼠标右键点击页签事件
      handleTagMouseRight(item,event) {
        if(this.tagLen == 1) {
          return
        }
        this.tagMenuVisible = true;
        let menu = document.querySelector('.tag_menu');
        menu.style.left = event.clientX+'px';
         // 给整个document新增监听鼠标事件，点击任何位置执行tagMenuListener方法
        document.addEventListener('click', this.tagMenuListener);
      },
      tagMenuListener() {
        // 取消鼠标监听事件
        this.tagMenuVisible = false;
        document.removeEventListener('click',this.tagMenuListener);
      },
      // 页签菜单点击事件
      handleTagMenu(val) {
        if(val == 'closeOthers') {
          this.tagMenuVisible = false;
          let openTag = this.tag;
          let tagList = this.tagList.filter(item => {
            return item.value !== this.tag.value && item.value !== this.tagWel.value
          });
          this.activeTag(tagList)
          this.openTag(openTag);
        }else {
          this.tagMenuVisible = false;
          this.activeTag(this.tagList)
          this.$router.push({
            path: this.tagWel.value,
            query: this.tagWel.query
          });
        }
      },
      activeTag (tagList) {
        tagList.forEach(item => {
          this.openTag(item);
          this.$store.commit("DEL_TAG", item);
        });
      },
    }
  };
  </script>
  
  <style lang='less' scoped>
    .tab_nav_menu {
        margin-top: 10px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
      .tag_menu {
          position: absolute;
          width: 100px;
          background-color: @systemColor;
          z-index: 999;
          top: 83px;
          padding: 10px 0;
          border: 1px solid #ebeef5;
          border-radius: 4px;
          box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  
          .menu_list {
            text-align: center;
            height: 35px;
            line-height: 35px;
            cursor: pointer;
          }
          :hover.menu_list {
            color: @themeColor;
            background-color: #f4f9ff;
          }
        }
        .more_handle {
            height: 35px;
            line-height: 35px;
            padding: 0 13px;
            border-width: 2px;
            border-radius: 25%;
            border-color: #d9d9d9;
            border-left: 2px solid #d9d9d9;
            border-right: 2px solid #d9d9d9;
            box-shadow: 0px 15px 10px 5px #d9d9d9;
        }
        /deep/.el-tabs {
          width: 92%;
          height: 35px;
          .el-tabs__header {
            margin: 0;
            border: none;
            .el-tabs__nav {
              border: none;
            }
          }
          .el-tabs__item {
            height: 35px;
            line-height: 35px;
            box-shadow: none !important;
            border-bottom: none;
            border-width: 2px;
            border-radius: 25%;
            border-color: #d9d9d9;
          }
          .el-tabs__item.is-active {
            border-right: 2px solid #d9d9d9;
            box-shadow: 0px 15px 10px 5px #d9d9d9 !important;
          }
  
          .el-tabs__nav-next, .el-tabs__nav-prev {
            line-height: 35px;
            font-size: @fs14;
          }
        }
        /deep/.el-tabs__item:first-child {
                padding: 0 20px !important;
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
                border-top-right-radius: 25% !important;
                border-bottom-right-radius: 25% !important;
                .el-icon-close {
                    display: none;
                }
        }
    }
  </style>