<template>
  <dd-card>
    <dd-table
      :columns="tableColumns"
      :data="tableData"
      border
      :topBtn="false"
      :isSelection="false"
    >
    </dd-table>
  </dd-card>
</template>

<script>
import { getApi } from "@/api/request";
export default {
  props: {
    assignId: "",
  },
  data() {
    return {
      tableColumns: [
        {
          title: "修改人",
          key: "updateByName",
          align: "center",
        },
        {
          title: "修改时间",
          key: "updateTime",
          align: "center",
        },
        {
          title: "修改任务书生产实施状态",
          key: "produceStatusName",
          align: "center",
        },
      ],
      tableData: [],
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    // 获取表格列表数据
    getTableData() {
      getApi(`/project/assignrecord/${this.assignId}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableData = data.data;
        }
      });
    },
  },
};
</script>

<style></style>
