<template>
  <div v-if="defaultPage" class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="菜单列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        :isSort="false"
        :topBtn="false"
        border
        :isSelection="false"
        :show-page="false"
        :tree-props="{ children: 'children' }"
      >
      </dd-table>
    </dd-card>
  </div>
  <div v-else class="main-container">
    <dict-child
      :rowId="rowData.id"
      :dictKey="rowData.dictKey"
      @pageBack="pageBack"
    ></dict-child>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
// 字典项页面
import dictChild from "./components/DictChild.vue";
export default {
  name: "dataPermission",
  $dynamicRoute: {
    name: "数据权限",
    path: "/admin/sysscopedata/index",
  },
  components: {
    dictChild,
  },
  computed: {},
  data() {
    return {
      tableHeight: null,
      defaultPage: true,
      drawerVisible: false,
      formData: {},
      formList: [
        {
          label: "标识",
          value: "dictKey",
          inputType: "text",
          placeholderText: "请输入标识",
        },
        {
          label: "字典类型",
          value: "systemFlag",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择字典类型",
          optionsListConfig: {
            name: "label",
            code: "value",
          },
        },
      ],
      tableColumns: [
        {
          title: "菜单名称",
          key: "name",
        },
        {
          title: "图标",
          key: "icon",
          align: "center",
          formatterIcon: true,
        },
        {
          title: "排序",
          key: "sortOrder",
          align: "center",
        },
        {
          title: "组件路径",
          key: "path",
          align: "center",
        },
        {
          title: "类型",
          key: "type",
          align: "center",
          formatter: (row) => {
            return this.getState("menuType", row);
          },
        },
        {
          title: "缓冲",
          key: "keepAlive",
          align: "center",
          type: "info",
          formatter: (row) => {
            return this.getState("bufferType", row);
          },
        },
        {
          title: "权限标识",
          key: "permission",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
            {
              show: "true",
              label: "权限设置",
              icon: "el-icon-plus",
              method: (row) => {
                this.defaultPage = true;
                this.handleRowAdd(row);
              },
              type: "primary",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      menuType: [],
      bufferType: [],
      rowData: null,
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      getApi(`/admin/dict/key/menu_type_status`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.menuType = data.data;
        }
      });
      getApi(`/admin/dict/key/keepalive_sta tus`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.bufferType = data.data;
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      getApi(`/admin/menu/tree`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableData = data.data;
        }
      });
    },
    handleRowAdd(row) {
      this.defaultPage = false;
      this.rowData = row;
    },
    // 字典项页面返回事件回调
    pageBack() {
      this.defaultPage = true;
      this.rowData = null;
    },
    // 格式化类型
    getState(key, row) {
      let label = "";
      this[key].forEach((item) => {
        if (item.value == row) {
          label = item.label;
        }
      });
      return label;
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped></style>
