<template>
  <div class="main-container">
    <div id="map"></div>
  </div>
</template>

<script>
import Map from "ol/Map"; //地图
import View from "ol/View"; //视图
import { Vector as VectorLayer } from "ol/layer";
import VectorSource from "ol/source/Vector";
import { Point } from "ol/geom";
import Feature from "ol/Feature";
import { Style, Circle, Fill, Stroke, Icon } from "ol/style";
import * as Layer from "/static/js/layer2D.js";
export default {
  name: "info-pop",
  props: {
    pointInfo: "",
  },
  data() {
    return {
      map: "",
      vectorLayer: new VectorLayer({
        source: new VectorSource(),
      }),
      info: [],
    };
  },
  mounted() {
    this.initMap(); //地图初始化（默认加载天地图底图）
    this.loadPoint();
  },
  methods: {
    initMap() {
      this.map = new Map({
        target: "map",
        layers: Layer.tdtLayer(),
        view: new View({
          center: [108.948024, 34.263161],
          projection: "EPSG:4326",
          zoom: 12.5,
          minZoom: 5,
          maxZoom: 18,
          units: "metric",
        }),
      });
    },
    //打卡位置
    loadPoint() {
      if (this.pointInfo == null) {
        return;
      }
      this.info = this.pointInfo.split("(");
      this.info = this.info[1].split(")");
      this.info = this.info[0].split(" ");
      let point = new Point(this.info);

      // 创建矢量对象
      let feature = new Feature({
        geometry: point,
      });
      const fill = new Fill({
        color: "red",
      });
      const stroke = new Stroke({
        color: "red",
        width: 25,
      });
      let imageUrl = require("/static/image/location.png");
      const styles = [
        new Style({
          // image: new Circle({
          //   fill: fill,
          //   stroke: stroke,
          //   radius: 2,
          // }),
          image: new Icon({
            src: imageUrl,
            width: 48,
            height: 48,
            anchor: [0.5, 0.5],
          }),
          fill: fill,
          stroke: stroke,
        }),
      ];
      // 创建矢量源
      let source = new VectorSource({});
      // 把要素集合添加到源
      source.addFeature(feature);
      // 创建矢量层
      this.vectorLayer = new VectorLayer({
        zIndex: 999,
        source: source,
        style: styles,
        id: "point",
      });
      this.map.addLayer(this.vectorLayer);
      this.fitLayer(this.vectorLayer);
    },
    fitLayer(layer) {
      let extent = layer.getSource().getExtent();
      extent[0] = extent[0] - 0.01;
      extent[1] = extent[1] - 0.01;
      extent[2] = extent[2] + 0.01;
      extent[3] = extent[3] + 0.01;
      this.map.getView().fit(extent);
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  border: 1px solid #ccc;
  #map {
    width: 100%;
    height: 100%;
  }
}
</style>