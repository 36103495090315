<template>
    <div class="main-container">
        <div class="left-box">
            <div class="head_wrap">
                <dd-card title="条件搜索" spacer>
                    <dd-search-form :form-config="formList" @search-click="handleSearchEvent"
                        ref="ddSearchForm"></dd-search-form>
                </dd-card>
            </div>
            <dd-card title="目录列表">
                <dd-table :columns="tableColumns" :data="tableData" max-height="420" ref="leftTable" border isSelection
                    :loading="loading" @row-click="handleTableRow" @select="handleSelect">
                    <template #table-btn v-if="cataState == 'wgd'">
                        <dd-button type="primary" size="medium" icon="add" @click="handleAdd">新增</dd-button>
                    </template>
                    <template #table-pagination>
                        <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                            @page-change="handleCurrentChange" align="right">
                        </dd-pagination>
                    </template>
                </dd-table>
            </dd-card>
        </div>
        <div class="right-box">
            <div class="head_wrap">
                <dd-card title="条件搜索" spacer>
                    <dd-search-form :form-config="rightFormList" @search-click="handleRightSearchEvent"
                        ref="ddRightSearchForm"></dd-search-form>
                </dd-card>
            </div>
            <dd-card title="文件列表">
                <dd-table :columns="rightTableColumns" :data="rightTableData" max-height="457" ref="rightTable" border :isSelection="false"
                    show-page :loading="rightLoading">
                    <template #table-pagination>
                        <dd-pagination v-show="rightTotal > 0" :total="rightTotal" :page-size="rightSize" :page-number="rightCurrent"
                            @page-change="handleRightCurrentChange" align="right">
                        </dd-pagination>
                    </template>
                </dd-table>
            </dd-card>
        </div>
        <dd-popup
            inPopup
            width="25%"
            height="30%"
            showBtn
            :saveBtn="true"
            :cancelBtn="true"
            :show="addPopupVisible"
            title-name="新增"
            @popup-close="handleClose"
            @popup-save="handleSave('addPopForm','baseForm')"
        >
            <add-pop v-if="addPopupVisible" slot="main" ref="addPop"></add-pop>
        </dd-popup>
    </div>
  </template>
    
  <script>
  import { getApi, delApi, postApi, putApi } from "@/api/request";
  // 新增页面弹窗
  import addPop from "./AddPrjCatalogPop.vue";
  export default {
    props: {
        prjId: '',
        cataState: ''
    },
    components: {
        addPop,
    },
    computed: {
    },
    data() {
        return {
            drawerVisible: false,
            loading: false,
            rightLoading: false,
            formData: {},
            rightFormData: {},
            typeId: null,
            formList: [
                {
                    label: "目录名称",
                    value: "typeName",
                    inputType: "text",
                    placeholderText: "请输入项目类型",
                },
            ],
            rightFormList: [
                {
                    label: "文件名称",
                    value: "fileName",
                    inputType: "text",
                    placeholderText: "请输入目录名称",
                },
            ],
            tableColumns: [
                {
                    title: "目录名称",
                    key: "path",
                    align: "center",
                },
                {
                    title: "文件数量",
                    key: "num",
                    align: "center",
                    width: 80
                }
            ],
            rightTableColumns: [
                {
                    title: "文件路径",
                    key: "path",
                    align: "center",
                },
                {
                    title: "文件名称",
                    key: "fileName",
                    align: "center",
                }
            ],
            tableData: [],
            rightTableData: [],
            current: 1,
            rightCurrent: 1,
            size: 20,
            rightSize: 20,
            total: null,
            rightTotal: 20,
            dictType: [],
            addPopupVisible: false,
            catalogPopupVisible: false,
            rowData: null,
            rightSelection: [],
        };
    },
    created() { },
    mounted() {
        this.getTableData();
        this.getRightTableData();
    },
    methods: {
        // 获取表格列表数据
        getTableData() {
            let { formData, current, size } = this;
            let params = {
                ...formData,
                current,
                size,
                prjId: this.prjId
            };
            this.loading = true;
            postApi(`/project/catalog/listType`, params).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    this.loading = false;
                    this.tableData = data.data.records;
                    this.total = data.data.total;
                }
            });
        },
        // 表格行点击选择事件
        handleTableRow(row) {
            let data = [];
            if(row == this.rowData) {
                row = null;
            }else {
                this.rowData = row;
                data.push(row);
            }
            this.handleSelect(data, row);
        },
        // 复选框单选事件
        handleSelect(selection, row) {
            this.selection = selection;
            this.rowData = row;
            this.$refs.leftTable.$refs.baseTable.clearSelection();
            if (selection.length == 0){
                this.$refs.leftTable.$refs.baseTable.setCurrentRow(null);
                this.typeId = null;
                this.$refs.ddRightSearchForm.formData = {};
                this.rightTotal = 0;
                this.rightSize = 20;
                this.rightCurrent = 1;
                this.rightTableData = [];
                this.rowData = null;
                return;
            };
            this.$refs.leftTable.$refs.baseTable.toggleRowSelection(row, true)
            this.$refs.leftTable.$refs.baseTable.setCurrentRow(row);
            this.typeId = row.id;
            this.getRightTableData();
        },
        // 右侧表格数据
        getRightTableData(id) {
            let { rightFormData, rightCurrent, rightSize, typeId } = this;
            let params = {
                ...rightFormData,
                current: rightCurrent,
                size: rightSize,
                prjId: this.prjId,
                catalogId: typeId
            };
            this.rightLoading = true;
            postApi(`/project/catalog/listCatalogs`, params).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    this.rightLoading = false;
                    this.rightTableData = data.data.records;
                    this.rightTotal = data.data.total;
                }
            });
        },
        // 新增按钮事件
        handleAdd() {
            this.addPopupVisible = true;
        },
        // 新增弹框关闭事件
        handleClose() {
            this.rowData = null;
            this.addPopupVisible = false;
            this.catalogPopupVisible = false;
        },
        /* 搜索栏 */
        handleSearchEvent(obj) {
            this.formData = obj;
            this.current = 1;
            this.getTableData();
            this.handleSelect([]);
            this.typeId = null;
            this.getRightTableData();
        },
        handleRightSearchEvent(obj) {
            if(this.typeId == null) {
                this.$message.warning("请先选择目录");
                return;
            }
            this.rightFormData = obj;
            this.rightCurrent = 1;
            this.getRightTableData();
        },
        /* 分页页码回调 */
        handleCurrentChange(size, number) {
            this.current = number;
            this.size = size;
            this.getTableData();
        },
        handleRightCurrentChange(size, number) {
            this.rightCurrent = number;
            this.rightSize = size;
            this.getRightTableData();
        },
        // 新增确认事件
        handleSave(formName,formNameBase) {
            let flag = false;
            this.$refs.addPop.$refs[formName].$refs[formNameBase].validate((valid) => {
                if (valid) {
                    flag = true;
                }
            });
            let params = this.$refs.addPop.$refs[formName].formData;
            params.prjId = this.prjId;
            if(flag) {
                postApi(`/project/catalog/insertCatalog`,params).then(res => {
                    let { data } = res;
                    if(data.code == 0) {
                        this.$message.success("保存成功");
                        this.handleClose();
                        this.getTableData();
                    }
                })
            }
        }
    },
  };
  </script>
    
  <style lang='less' scoped>
    .main-container{
        display: flex;
        justify-content: space-between;
        .left-box{
            width: 46%;
            /deep/ .has-gutter{
                .el-table-column--selection {
                    .cell{
                        display: none;
                    }
                }
            }
        }
        .right-box{
            width: 52%;
        }
        /deep/ .search-form-input{
            width: 50% !important;
        }
    }
  </style>