<template>
  <div class="container">
    <dd-card title="审批流程" spacer>
      <el-steps :active="activeIndex" align-center :finish-status="activeIndex == activityList.length ? 'success' : 'finish'">
        <el-step
          v-for="(val,idx) in activityList" :key="idx"
          :title="val.activityName"
        >
        <div slot="description" class="description">
          <div v-for="(item,index) in val.description" :key="index" class="list">
            <div class="flex_c">
              {{item.time}}
              <div class="name">{{item.userName}} {{item.message}}</div>
            </div>
            <div>意见 {{item.fullmessage}}</div>
          </div>
        </div>
        </el-step>
      </el-steps>
    </dd-card>
      <dd-card title="基本信息">
        <dd-form :form-config="formList" :flex="3" ref="budgetBasicForm" formRef="budgetBasicForm"></dd-form>
      </dd-card>
      <dd-card title="预算汇总">
        <dd-table
          ref="expenseDetail"
          :topBtn="false"
          :isSelection="false"
          :isSort="false"
          :columns="tableBudgetTotal"
          :data="tableDataBudgetSources"
          border
        ></dd-table>
      </dd-card>
      <dd-card title="预算编制">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane v-for="(item,idx) in tabArr" :key="idx" :label="item.name" :name="item.name">
              <dd-table
                :columns="tableTypeColumns"
                :data="item.children"
                :topBtn="false"
                :is-selection="false"
                :loading="loading"
                border
                default-expand-all
                :tree-props="{
                  children: 'children',
                  hasChildren: 'hasChildren',
                }"
              >
              </dd-table>
            </el-tab-pane>
          </el-tabs>
      </dd-card>
      <dd-card title="附件查看">
        <div v-if="fileList.length" class="ibox-content">
          <div v-for="(item, index) in fileList" :key="index" >
            <el-link :underline="false" :href="item.url">{{item.name}}</el-link>
          </div>
        </div>
        <div v-else class="ibox-content" style="height: 20px; overflow: auto">
          <div>暂无附件</div>
        </div>
      </dd-card>
  </div>
</template>

<script>
import { getApi, postApi } from "@/api/request";
import { mapState, mapActions } from "vuex";
import qs from "qs";

export default {
  name: "add",
  props: {
    rowData: null,
    type: "",
    revisePrj: {},
  },
  computed: {
    // ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      activeIndex: 0,
      activityList: [],
      loading: false,
      activeName: "生产类",
      fileList: [],
      formTaskBasicList: [
        {
          label: "审批意见",
          value: "comment",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          placeholderText: "请输入审批意见",
          rules: [
            {
              required: true,
              message: "请输入审批意见",
              trigger: "blur",
            },
          ],
        },
      ],
      formList: [
      {
          label: "编号",
          value: "code",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请人",
          value: "applyUser",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "部门",
          value: "applyDep",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "制单人",
          value: "maker",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "制单日期",
          value: "makeDate",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "项目名称",
          value: "projectName",
          inputType: "text",
          type: "textarea",
          disabled: true,
          autosize: true,
          defaultValue: "",
        },
        {
          label: "项目编号",
          value: "projectCode",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
      ],
      tableBudgetTotal: [
        {
          title: "明细类别",
          key: "amountLabel",
          align: "center",
        },
        {
          title: "生产类",
          key: "pro",
          align: "center",
        },
        {
          title: "管理类",
          key: "man",
          align: "center",
        },
        {
          title: "研发类",
          key: "dev",
          align: "center",
        },
        {
          title: "合计",
          key: "sum",
          align: "center",
        },
      ],
      tableTypeColumns: [
        {
          title: "费用类型编号",
          key: "code",
          align: "center",
          width: 180
        },
        {
          title: "费用类型名称",
          key: "name",
          align: "center",
          width: 180
        },
        {
          title: "预算金额",
          key: "amount",
          align: "center",
          width: 180,
        },
        {
          title: "备注",
          key: "remark",
          align: "center",
        },
      ],
      // 预算汇总数据
      tableDataBudgetSources: [],
      tabArr: [],
      detailDataSourcrs: ""
    };
  },
  mounted() {
    this.getSubList();
    this.getPrjBudgetApplyInfo();
  },
  methods: {
    handleClick(tab, event) {
    },
    //编辑页面信息回显
    getPrjBudgetApplyInfo() {
      getApi(`/activiti/process/viewPage/${this.rowData.proInstanceId}`).then((res) => {
        if(res.data.code == 0) {
          this.activityList = res.data.data;
          this.activityList.forEach((item,index) => {
            if(item.isCurrentActiviti == 1) {
              this.activeIndex = index+1
            }
          })
        }
      })
      let params = {
        processInstanceId: this.rowData.proInstanceId
      }
      postApi(`/finance/apply/getPrjBudgetApplyByPInsId`,params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.detailDataSourcrs = data.data;
          this.formList.forEach(item => {
            item.defaultValue = data.data[item.value]
          })
          this.fileList = data.data.prjBudgetApplyAttachFileList;
          this.judgeIsCurPrjManager(data.data.projectId);
          let arr = [];
          data.data.oneTypeVOList.forEach((item) => {
            let obj = {
                name: item.name,
                children: [item]
            }
            arr.push(obj);
          })
          this.tabArr = arr;
          if (this.detailDataSourcrs.taskNodes) {
            this.activityList.forEach((item) => {
              item.description = [];
              this.detailDataSourcrs.taskNodes.forEach((val) => {
                if(item.activityName == val.taskName) {
                  item.description.push(val)
                }
              })
            })
          }
          if(this.detailDataSourcrs.nodeName == "结束") {
            this.activeIndex = this.activityList.length
            console.log( this.activeIndex)
          }
        }
      });
    },
    // 判断是否可编制销售类
    judgeIsCurPrjManager(projectId) {
      let params = {
        projectId: projectId,
      };
      postApi(`/finance/apply/front/isCurPrjCmmManager`, qs.stringify(params))
        .then((res) => {
          let { data } = res;
          if(data) {
            let index = this.tabArr.findIndex(item => item.name === '销售类');
            let totalIndex = this.tableBudgetTotal.findIndex(item => item.title === '销售类')
            let saleObj ={
              title: "销售类",
              key: "sale",
              align: "center",
            }
            if(totalIndex == -1) {
              this.tableBudgetTotal.splice(2,0,saleObj)
            }
            
            if(index == -1) {
              this.tabArr.splice(1,0,this.saleTypeList)
            }
          }else {
            this.tabArr.forEach((item,idx) => {
              if(item.name == "销售类") {
                this.tabArr.splice(idx,1)
              }
            })
            this.tableBudgetTotal.forEach((item,idx) => {
              if(item.title == "销售类") {
                this.tableBudgetTotal.splice(idx,1)
              }
            })
          }
          this.activeName = "生产类";
          let totalObj = {
            amountLabel: "预算金额",
            pro: 0,
            sale: 0,
            man: 0,
            dev: 0,
            sum: 0
          }
          this.tabArr.forEach((item,idx) => {
            if(item.name == "生产类") {
              totalObj.pro = item.children[0].amount
            }
            if(item.name == "销售类") {
              totalObj.sale = item.children[0].amount
            }
            if(item.name == "管理类") {
              totalObj.man = item.children[0].amount
            }
            if(item.name == "研发类") {
              totalObj.dev = item.children[0].amount
            }
          })
          totalObj.sum = totalObj.pro + totalObj.sale + totalObj.man + totalObj.dev;
          this.tableDataBudgetSources.push(totalObj)
        })
    },
    //获取费用类型
    getSubList() {
      getApi(`/finance/expense/subList`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let arr = []
          for(let key in data.data) {
            let obj = {
                name: key,
                children: data.data[key]
            }
            arr.push(obj);
          }
          arr.forEach((item,idx) => {
            if(item.name == "销售类") {
              this.saleTypeList = item;
              arr.splice(idx,1)
            }
          })
          this.tabArr = arr;
        }
      });
    },
    // 更新费用
    changeAmount(val,row) {
      let typeCode = row.code.split('.')[0];
      this.tabArr.forEach((item) => {
        if(item.children[0].code == typeCode) {
          let chiledrenamount = item.children[0].children;
          let amount = 0;
          for (let i = 0; i < chiledrenamount.length; i++) {
            amount += Number(chiledrenamount[i].amount);
          }
          item.children[0].amount = amount;
        }
      })
      
    },
  },
};
</script>

<style lang='less' scoped>
  /deep/.el-tabs__header {
    margin: 0;
  }
  /deep/ .el-tabs__item {
    font-weight: 600;
    border-bottom: none;
  }
  /deep/.el-tabs--card>.el-tabs__header {
    border-bottom: none;
  }
  /deep/.el-tabs--card>.el-tabs__header .el-tabs__item.is-active {
    background-color: #576289;
    border-bottom: none;
  }
  /deep/.el-tabs__item.is-active {
    color: @systemColor !important;
  }
  /deep/.el-tabs__item:hover {
    color: #000 !important;
  }
  /deep/.el-tabs__item.is-active:focus {
    color: @systemColor !important;
  }
  /deep/.el-input.is-disabled .el-input__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
  }
  /deep/.el-textarea.is-disabled .el-textarea__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
    resize: none;
  }
  /deep/ .el-input__suffix {
    display: none;
  }
  /deep/ .el-step__description{
    display: flex;
    justify-content: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .description {
    .list {
      .name {
        margin-left: 10px;
      }
    }
  }
</style>