<!--
 * @version: V1.0.0
 * @Author: hebojiang
 * @Date: 2023-04-20 11:13:12
 * @LastEditors: hechen
 * @LastEditTime: 2023-10-17 17:35:46
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\views\Finance\Manager\ExpenseApply\index.vue
 * @Descripttion: 
-->
<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="日记账列表" :height="tableHeight">
      <dd-table
        ref="expenseApplyTable"
        tableRef="expenseApplyTable"
        :columns="columnsList"
        :data="tableDataSources"
        border
        show-page
        show-summary
        :summary-method="getSummaries"
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            v-if="$store.state.btnAuthorityList.includes('expenseApply:close')"
            type="primary"
            icon="close"
            size="medium"
            @click="handleClose"
            >关闭
          </dd-button>
          <dd-button
            v-if="
              $store.state.btnAuthorityList.includes(
                'expenseApply:counterreview'
              )
            "
            type="primary"
            icon="update"
            size="medium"
            @click="handleReverse"
            >反审核
          </dd-button>
          <dd-button
            v-if="$store.state.btnAuthorityList.includes('expenseApply:delete')"
            type="danger"
            size="medium"
            icon="delete"
            :autoConfirm="autoConfirm"
            @click="handleDelete"
            @confirm="handleConfirmDelete"
            >删除
          </dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
          <el-button
            icon="el-icon-download"
            circle
            type="info"
            @click="handleFileExport"
            size="small"
            title="下载"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 详情页面弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="diaexpenseDetail"
      title-name="申请单详情"
      @popup-close="handleCancel"
    >
      <detail-pop
        v-if="diaexpenseDetail"
        slot="main"
        ref="detailPop"
        :expenseApply="expenseApply"
        :depList="depList"
        :expenseType="expenseTypeSmall"
        :billState="billState"
      ></detail-pop>
    </dd-popup>
    <!-- 通过项目查询日记账明细页面弹窗 -->
    <dd-popup
      width="65%"
      height="80%"
      :showBtn="false"
      :show="detailByProjectPop"
      :title-name="detailByProjectTitle"
      @popup-close="handleCancel"
    >
      <apply-detail
        v-if="detailByProjectPop"
        slot="main"
        ref="detailPop"
        :projectId="detailProjectId"
        :amountType="amountType"
      ></apply-detail>
    </dd-popup>
    <dd-popup
      width="65%"
      showBtn
      :defaultBtn="false"
      :show="claimsSheetDetailPop"
      :title-name="claimsSheetDetailTitle"
      @popup-close="handleCancel"
    >
      <audit-pop
        v-if="claimsSheetDetailPop"
        slot="main"
        ref="detailPop"
        :projectId="projectId"
        :popupType="popupType"
        :rowAuditId="rowAuditId"
        :rowCheck="rowCheck"
      ></audit-pop>
    </dd-popup>
    <el-popover
      v-if="popovervisible"
      placement="top-start"
      width="200"
      v-model="popovervisible"
    >
      <div>
        <h5 style="text-align: center">
          {{ this.popoverConfirm.type }}
        </h5>
        <h5 style="text-align: center">{{ this.popoverConfirm.tip }}</h5>
      </div>
      <div style="margin: 15px 0px; display: flex; justify-content: center">
        <el-button class="btn-search" @click="handleContinue"
          >{{ this.popoverConfirm.define }}
        </el-button>
        <el-button class="btn-reset" @click="handlePopoverCancel"
          >取消
        </el-button>
      </div>
    </el-popover>
    <el-popover
      v-if="savePopoverVisible"
      placement="top-start"
      width="200"
      v-model="savePopoverVisible"
    >
      <div>
        <h5 style="text-align: center">
          {{ this.savePopoverConfirm.type }}
        </h5>
        <h5 style="text-align: center">{{ this.savePopoverConfirm.tip }}</h5>
      </div>
      <div style="margin: 15px 0px; display: flex; justify-content: center">
        <el-button class="btn-search" @click="handleSaveContinue"
          >{{ this.savePopoverConfirm.define }}
        </el-button>
        <el-button class="btn-reset" @click="handleSavePopoverCancel"
          >取消
        </el-button>
      </div>
    </el-popover>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import addPop from "./components/detailByProject.vue";
import { postApi, getApi, delApi, loadApi } from "@/api/request";
import detailPop from "./components/expenseDetail.vue";
import detailProject from "./components/detailByProject.vue";
import AuditPop from "../ReportBill/components/AuditPop";
import applyDetail from "../ProjectBudget/BudgetApply/components/applyDetail";
import qs from "qs";
import { downloadBinaryFile } from "@/utils/index";
import { getNowDate, getInitYear } from "@/utils/date.js";

export default {
  name: "dayBookList",
  $dynamicRoute: {
    name: "日记账列表",
    path: "/finance/dayBook/list",
    meta: {
      keepAlive: true,
    },
  },
  components: {
    addPop,
    detailPop,
    detailProject,
    applyDetail,
    AuditPop,
  },
  computed: {},
  data() {
    return {
      tableHeight: null,
      //删除确认显隐
      autoConfirm: false,
      //弹窗加载条件
      diaexpenseApply: false,
      //子传父值（判断新增或者编辑或者详情，type;id）
      expenseApply: {},
      //表单数据
      formData: {
        applyStartDate: getInitYear(),
        applyEndDate: getNowDate(),
      },
      //费用类型（一、二、三级）
      expenseType: [],
      expenseTypeSmall: [],
      //费用类型cascader级联选择器配置项
      prop: {
        value: "id",
        label: "name",
      },
      tableDataSources: [],
      //表选择数据
      multipleSelection: [],
      //部门数据
      depList: [],
      //通过项目查询所有日记账明细弹窗
      detailByProjectPop: false,
      detailByProjectTitle: "",
      detailProjectId: "",
      amountType: "",
      // 条件查询表单配置配置
      formList: [
        {
          label: "单号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入单号",
        },
        {
          label: "项目名称",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "项目编号",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目编号",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "费用类型",
          value: "expenseTypeCode",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "code",
            label: "name",
            checkStrictly: true,
          },
          placeholderText: "请选择费用类型",
        },
        {
          label: "申请人",
          value: "applier",
          inputType: "text",
          placeholderText: "请输入记账人姓名",
        },
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          placeholderText: "请选择或搜索记账人部门",
        },
        {
          label: "承担部门",
          value: "expenseBearDepId",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          placeholderText: "请选择或搜索费用承担部门",
        },
        {
          label: "单据状态",
          value: "state",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索单据状态",
        },
        {
          label: "开始时间",
          value: "applyStartDate",
          inputType: "date",
          placeholderText: "请选择开始时间",
          defaultValue: getInitYear(),
        },
        {
          label: "结束时间",
          value: "applyEndDate",
          inputType: "date",
          placeholderText: "请选择结束时间",
          defaultValue: getNowDate(),
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "单号",
          key: "code",
          align: "center",
        },
        {
          title: "任务书名称",
          key: "assignName",
          align: "center",
        },
        {
          title: "任务书编号",
          key: "assignNum",
          align: "center",
        },
        {
          title: "项目名称",
          width: 230,
          key: "projectName",
          // event: this.$store.state.btnAuthorityList.includes(
          //   "expenseApply:detailByProject"
          // )
          //   ? [
          //       {
          //         type: "primary",
          //         href: (row) => {
          //           return row.projectName;
          //         },
          //         method: (row) => {
          //           this.handleRowHref(row);
          //         },
          //       },
          //     ]
          //   : false,
        },
        {
          title: "项目编号",
          key: "prjCode",
          align: "center",
        },
        {
          title: "业务类别",
          key: "firstExpense",
          align: "center",
        },
        {
          title: "二级费用类型",
          key: "twoLevelType",
          align: "center",
        },
        {
          title: "费用类型",
          key: "extenseTypeName",
          align: "center",
        },
        {
          title: "金额(元)",
          key: "amount",
          align: "center",
        },
        {
          title: "核定金额（元）",
          key: "reviewAmount",
          align: "center",
        },
        {
          title: "单据状态",
          key: "stateName",
          align: "center",
        },
        {
          title: "节点",
          key: "nodeName",
          align: "center",
        },
        {
          title: "申请日期",
          key: "applyTime",
          align: "center",
        },
        {
          title: "申请人",
          key: "applier",
          align: "center",
        },
        {
          title: "部门",
          key: "deptName",
          align: "center",
        },
        {
          title: "费用承担部门",
          key: "expenseBearDeptName",
          align: "center",
        },
        {
          title: "事由",
          key: "subjectMatter",
          align: "center",
        },
        {
          title: "详情",
          key: "details",
          align: "center",
          event: [
            {
              state: "default",
              label: "查看明细",
              method: (row) => {
                this.getDetail(row);
              },
            },
          ],
        },
      ],
      // 当前页码
      current: 1,
      // 总条数
      total: 0,
      // 每页的数据条数
      size: 10,
      //详情查看显隐
      diaexpenseDetail: false,
      //是否继续记账弹窗显隐
      popovervisible: false,
      //是否继续记账弹窗内容
      popoverConfirm: {
        type: "",
        tip: "是否继续记账？",
        define: "继续",
      },
      //是否保存记账信息弹窗显隐
      savePopoverVisible: false,
      //是否保存记账信息弹窗内容
      savePopoverConfirm: {
        type: "",
        tip: "是否保存记账信息？",
        define: "确认",
      },
      //批量删除数据id集合
      deleteid: [],
      //选择或者级联配置数据对象
      options: {},
      // 记账人部门下拉框初始化
      applierDepIdOptions: {},
      drawerVisible: false,
      //筛选列表格头部配置
      columnsList: [],
      claimsSheetDetailPop: false,
      claimsSheetDetailTitle: "",
      popupType: "",
      rowAuditId: "",
      rowCheck: "",
      projectId: "",
      billState: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.columnsList = [...this.tableColumns];
    setTimeout(() => {
      this.getConditionData();
    }, 500);
    this.getDataList();
  },
  methods: {
    //新增、编辑弹窗界面关闭
    handleCancel() {
      this.diaexpenseApply = false;
      this.diaexpenseDetail = false;
      this.detailByProjectPop = false;
      this.multipleSelection = [];
      this.claimsSheetDetailPop = false;
      this.$refs.expenseApplyTable.$refs.baseTable.clearSelection();
    },

    //日记账列表查询事件
    handleSearchEvent(obj) {
      this.current = 1;
      this.size = 10;
      if (obj.expenseTypeCode && obj.expenseTypeCode.length > 0) {
        obj.expenseTypeCode = obj.expenseTypeCode.slice(-1)[0];
      }
      this.formData = obj;
      let startTime = new Date(obj.applyStartDate).getTime();
      let endTime = new Date(obj.applyEndDate).getTime();
      let load = true;
      if (startTime && endTime) {
        if (startTime > endTime) {
          load = false;
          this.$message.warning("开始时间应小于结束时间");
        }
        if (endTime < startTime) {
          load = false;
          this.$message.warning("结束时间应大于开始时间");
        }
      }
      if (load) {
        this.getDataList();
      }
    },
    //数值合并
    getSummaries(param) {
      this.$nextTick(() => {
        this.$refs.expenseApplyTable.$refs.baseTable.doLayout();
      });
      const { columns, data } = param;
      const sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (index === 9 || index === 10) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          } else {
            sums[index] = " ";
          }
        } else {
          sums[index] = " ";
          return;
        }
      });
      sums[9] = parseFloat(sums[9]).toFixed(2);
      sums[10] = parseFloat(sums[10]).toFixed(2);
      return sums;
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleAdd() {
      this.diaexpenseApply = true;
      this.expenseApply = {
        type: "0",
      };
    },
    handleEdit() {
      if (this.multipleSelection.length != 1) {
        this.$message.warning("请选择一条数据！");
        return;
      }
      if (
        this.multipleSelection[0].state != "dtj-1" &&
        this.multipleSelection[0].state != "yth-1"
      ) {
        this.$message.warning("只能选择待提交或已退回状态数据编辑！");
        return;
      }
      this.diaexpenseApply = true;
      this.expenseApply = {
        type: "1",
        id: this.multipleSelection[0].id,
      };
    },
    handleDelete() {
      var idArr = [];
      this.autoConfirm = false;
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请至少选择一条数据！");
        return;
      }
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (
          this.multipleSelection[i].state != "dtj-1" &&
          this.multipleSelection[i].state != "yfq" &&
          this.multipleSelection[i].state != "shbtg-1"
        ) {
          this.$message.warning("只能选择暂存,已废弃,审核不通过状态数据删除！");
          return;
        } else {
          idArr.push(this.multipleSelection[i].id);
        }
      }
      this.deleteid = { ids: idArr.join() };
      if (this.deleteid.ids && this.deleteid.ids.length > 0) {
        this.autoConfirm = true;
      }
      this.multipleSelection = [];
    },
    handleConfirmDelete(noPromptFlag) {
      postApi(`/finance/expenseApply/delete`, this.deleteid)
        .then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$refs.expenseApplyTable.$refs.baseTable.clearSelection();

            if (!noPromptFlag) {
              this.$message.success("删除成功!");
            }
          }
          this.getDataList();
          this.autoConfirm = false;
        })
        .catch((error) => {
          this.$message.error(error.response.msg);
        });
    },
    handleClose() {
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请至少选择一条数据！");
        return;
      }
      let idArr = [];
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (
          this.multipleSelection[0].state !== "shtg-1" &&
          this.multipleSelection[0].state !== "shz-1" &&
          this.multipleSelection[0].state !== "yth-1"
        ) {
          this.$message.warning(
            "只能选择审核中,审核通过,审核退回状态数据关闭！"
          );
          return;
        } else {
          idArr.push(this.multipleSelection[i].id);
        }
      }
      this.$confirm("是否执行关闭操作？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          postApi(`/finance/applysheet/close`, { ids: idArr })
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.getDataList();
                this.$message.success("关闭成功!");
                this.multipleSelection = [];
              }
            })
            .catch((error) => {
              this.$message.error(error.response.data.msg);
            });
        })
        .catch(() => {
          this.$message.info("关闭已取消");
        });
    },
    handleReverse() {
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请至少选择一条数据！");
        return;
      }
      let idArr = [];
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (this.multipleSelection[0].state !== "shtg-1") {
          this.$message.warning("只能选择审核通过状态数据反审核！");
          return;
        } else {
          idArr.push(this.multipleSelection[i].id);
        }
      }
      this.$confirm("是否执行反审核操作？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          postApi(`/finance/applysheet/reverse`, { ids: idArr })
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.getDataList();
                this.$message.success("反审核成功!");
                this.multipleSelection = [];
              } else {
                this.$message.error(data.msg);
              }
            })
            .catch((error) => {
              this.$message.error(error.response.data.msg);
            });
        })
        .catch(() => {
          this.$message.info("反审核已取消");
        });
    },
    // 日记账列表分页切换事件
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getDataList();
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (item.key != "detail") {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      let formData = {
        ...this.formData,
        current: 1,
        size: this.size,
      };

      formData["curOrgId"] = sessionStorage.getItem("curOrgId");

      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/expenseApply/front/audit/export`, params).then(
        (res) => {
          let { data } = res;
          downloadBinaryFile(
            data,
            decodeURIComponent(
              res.headers["content-disposition"].split(";")[1].split("=")[1]
            )
          );
        }
      );
    },
    //通过项目查询日记账明细
    handleRowHref(row) {
      this.detailProjectId = row.projectId;
      this.detailByProjectTitle = "预算明细";
      this.detailByProjectPop = true;
      this.amountType = "totalAmount";
    },

    //获取接口数据
    getDataList() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      postApi(`/finance/applysheet/audit/list`, params).then((res) => {
        let { data } = res.data;
        this.total = data.total;
        if (data.records) {
          data.records.forEach((item) => {
            item.amount = parseFloat(item.amount).toFixed(2);
            item.reviewAmount = parseFloat(item.reviewAmount).toFixed(2);
            item.finExpenseApplyAttachFiles &&
              item.finExpenseApplyAttachFiles.length &&
              item.finExpenseApplyAttachFiles.forEach((itm) => {
                itm.url = `/admin/sys-file/ssh/${itm.fileName}`;
              });
            item.AttachFiles = item.finExpenseApplyAttachFiles;
          });
          this.tableDataSources = data.records;
        } else {
          this.tableDataSources = [];
        }
        this.clearTableSelection();
      });
    },
    //条件数据查询
    getConditionData() {
      //获取项目信息
      getApi(`/project/prj/get/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.projectCodeOptions = {
            value: "projectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, this.options);
        }
      });

      //获取费用类型（一、二、三级树）
      getApi(`/finance/expense/getThFinTypeAll`)
        .then((res) => {
          this.expenseType = res.data.data;
          this.options = {
            value: "expenseTypeCode",
            optionsList: this.expenseType,
          };
          this.setOPtionsList(this.formList, this.options);
        })
        .catch(() => {
          this.$message.error("请求出错");
        });
      getApi(`/finance/expense/getThFinType`)
        .then((res) => {
          this.expenseTypeSmall = res.data.data;
        })
        .catch(() => {
          this.$message.error("请求出错");
        });
      //获取单据状态
      postApi(`/finance/dict/list`, qs.stringify({ typeCode: "xmfysqdzt" }))
        .then((res) => {
          if (res.data.code == 0) {
            this.billState = res.data.data;
            this.options = {
              value: "state",
              optionsList: res.data.data,
            };
            this.setOPtionsList(this.formList, this.options);
          }
        })
        .catch(() => {
          this.$message.error("请求出错");
        });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.depList = res.data.data;
        let options = {
          value: "expenseBearDepId",
          optionsList: this.depList,
        };
        let deptOptions = {
          value: "deptId",
          optionsList: this.depList,
        };
        this.setOPtionsList(this.formList, options);
        this.setOPtionsList(this.formList, deptOptions);
      });
    },
    //查看详情事件
    getDetail(row) {
      this.diaexpenseDetail = true;
      this.expenseApply = {
        id: row.id,
      };
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleCodeHref(row) {
      this.claimsSheetDetailPop = true;
      this.claimsSheetDetailTitle = "报账单详情";
      this.popupType = "Check";
      this.rowAuditId = row.claimsSheetId;
      this.rowCheck = row;
    },
    // 清除表格多选状态
    clearTableSelection() {
      this.$refs.expenseApplyTable.$refs.baseTable.clearSelection();
    },
  },
};
</script>

<style lang="less" scoped></style>
