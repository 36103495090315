<template>
    <div class="main-container">
      <div class="head_wrap">
        <dd-card title="条件搜索" spacer>
          <dd-search-form
            :form-config="formList"
            @search-click="handleSearchEvent"
            ref="ddSearchForm"
          ></dd-search-form>
        </dd-card>
      </div>
      <dd-card title="参数列表" :height="tableHeight">
        <dd-table
          :columns="tableColumns"
          :data="tableData"
          isSort
          border
          :isSelection="false"
          show-page
        >
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
  </template>
  
  <script>
  import { getApi, downBlobApi } from "@/api/request";
import { getNowDate } from '@/utils/date';

  export default {
    name: "file",
    $dynamicRoute: {
      name: "我的下载",
      path: "/personnel/download",
    },
    computed: {},
    data() {
      return {
        tableHeight: null,
        drawerVisible: false,
        formData: {},
        formList: [
          {
            label: "文档名",
            value: "documentName",
            inputType: "text",
            placeholderText: "请输入文档名",
          },
        ],
        tableColumns: [
          {
            title: "任务书",
            key: "assignName",
            align: "center",
          },
          {
            title: "节点名",
            key: "nodeName",
            align: "center",
            overflow: true,
          },
          {
            title: "文档名",
            key: "documentName",
            align: "center",
            overflow: true,
          },
          {
            title: "审核状态",
            key: "approveStateName",
            align: "center",
          },
          {
            title: "审核人",
            key: "approverName",
            align: "center",
          },
          {
            title: "审核时间",
            key: "approveTime",
            align: "center",
          },
          {
            title: "截止时间",
            key: "downloadDeadlineTime",
            align: "center",
          },
          {
            title: "节点",
            key: "taskFlag",
            align: "center",
          },
          {
            title: "操作",
            key: "handle",
            align: "center",
            width: 300,
            event: [
              {
                label: "下载",
                icon: "el-icon-download",
                method: (row) => {
                  this.handleExport(row);
                },
                type: "primary",
                rules: (row) =>{
                  let show = false;
                  if (row.approveState == 'PRJ-RWSHZT-SHTG'){
                    show = true;
                  }
                  return show;
                }
              },
            ],
          },
        ],
        tableData: [],
        current: 1,
        size: 20,
        total: null,
        addPopupVisible: false,
        poptitleName: "新增",
      };
    },
    created() {},
    mounted() {
      // 自动计算table高度
      this.$nextTick(() => {
        this.tableHeight = this.autoTableHeight(0);
      });
      this.getTableData();
    },
    methods: {
      // 获取表格列表数据
      getTableData() {
        let { formData, current, size } = this;
        let params = {
          ...formData,
        };
        getApi(`/project/prjassign/filedownload/page/${current}/${size}`, params).then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.tableData = data.data.records;
            this.total = data.data.total;
          }
        });
      },
      // 导出下载事件
      handleExport(row) {
        if(new Date(row.downloadDeadlineTime).getTime() < Date.now() ){
          this.$message.error("超过下载截止时间，请重新申请下载！");
          return;
        }
        downBlobApi(`/admin/sys-file/ssh/${row.fileName}`, {}, row.fileName);
      },
      // 附件预览
    //   handlePreview(row) {
    //     getApi(`/admin/sys-file/online/ssh/${row.fileName}`).then((res) => {
    //       let { data } = res;
    //       if (data && data.code == 0) {
    //         let url = data.data;
    //         window.open(url);
    //       }
    //     });
    //   },
      /* 搜索栏 */
      handleSearchEvent(obj) {
        this.formData = obj;
        this.current = 1;
        this.getTableData();
      },
      /* 分页页码回调 */
      handleCurrentChange(size, number) {
        this.current = number;
        this.size = size;
        this.getTableData();
      },
    },
  };
  </script>
  
  <style lang="less" scoped></style>
  