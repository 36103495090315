<template>
  <dd-card>
    <dd-table
      :columns="tableColumns"
      :data="tableData"
      border
      :topBtn="false"
      :isSelection="false"
    >
      <template #table-pagination>
        <dd-pagination
          v-show="total > 0"
          :total="total"
          :page-size="size"
          :page-number="current"
          @page-change="handleCurrentChange"
          align="right"
        >
        </dd-pagination>
      </template>
    </dd-table>
  </dd-card>
</template>

<script>
import { getApi } from "@/api/request";
export default {
  props: {
    assignId: "",
  },
  data() {
    return {
      tableHeight: null,
      tableColumns: [
        {
          title: "修改人",
          key: "updateByName",
          align: "center",
          width:'100',
        },
        {
          title: "修改时间",
          key: "updateTime",
          align: "center",
          width:'100',
        },
        {
          title: "单据编号",
          key: "assignCode",
          align: "center",
          width:'100',
        },
        {
          title: "项目生产需求级别",
          key: "assignLevelName",
          align: "center",
          width:'150',
        },
        {
          title: "业务类型",
          key: "businessType",
          align: "center",
          width:'100',
        },
        {
          title: "生产控制模式",
          key: "produceTypeName",
          align: "center",
          width:'120',
        },
        {
          title: "所在科室/部门",
          key: "crmContactDept",
          align: "center",
          width:'120',
        },
        {
          title: "现下发负责部门",
          key: "nowDownDeptName",
          align: "center",
          width:'150',
        },
        {
          title: "现客户经理",
          key: "nowCmmmanagerName",
          align: "center",
          width:'110',
        },
        {
          title: "现下发分管部门",
          key: "nowDownFenDept",
          align: "center",
          width:'120',
        },
        {
          title: "现下发分管领导",
          key: "nowDownFenDeptLeader",
          align: "center",
          width:'140',
        },
        {
          title: "现生产负责部门",
          key: "nowReceiveDeptName",
          align: "center",
          width:'140',
        },
        {
          title: "现项目经理",
          key: "nowLeaderName",
          align: "center",
          width:'120',
        },
        {
          title: "现生产分管部门",
          key: "nowReceiveFenDept",
          align: "center",
          width:'140',
        },
        {
          title: "现生产分管领导",
          key: "nowReceiveFenDeptLeader",
          align: "center",
          width:'140',
        }
      ],
      tableData: [],
      current: 1,
      size: 20,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getTableData();
  },
  methods: {
    // 获取表格列表数据
    getTableData() {
      let { current, size } = this;
      getApi(`/project/assignrecord/${this.assignId}/${current}/${size}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
  },
};
</script>

<style></style>
