<template>
    <div class="main-container">
      <dd-form :form-config="formBasicList" ref="addPopForm">
      </dd-form>
    </div>
  </template>
    
  <script>
  export default {
    name: "add-pop",
    props: {
      rowData: []
    },
    data() {
      return {
        formBasicList: [
          {
            label: "目录名称",
            value: "name",
            inputType: "text",
            placeholderText: "请输入目录名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入目录名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "排序",
            value: "sortNum",
            inputType: "number",
            min: 0,
            max: 999,
            placeholderText: "请输入排序",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    created() { },
    mounted() {
      if (this.rowData != null) {
        this.formBasicList.forEach(item => {
          item.defaultValue = this.rowData[item.value]
        })
      }
    },
    methods: {
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
    