<template>
    <div class="container">
        <dd-card title="条件搜索" spacer>
            <dd-search-form :form-config="formList" @search-click="handleSearchEvent"
                ref="ddSearchForm"></dd-search-form>
        </dd-card>
        <dd-card title="自建合同列表" :height="tableHeight">
            <dd-table ref="baseTable_1" :columns="tableColumns" @select-change="handleSelectionChange"
                :handleCheckDisable="handleCheckDisable" :data="tableData" border :isSelection="true" :isSelectable="true"
                >
                <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handlePageChange" align="right">
                    </dd-pagination>
                </template>
            </dd-table>
        </dd-card>
    </div>
</template>

<script>
import { postApi } from '@/api/request';
export default {
    props: ['pageProp'],
    name: 'addSelfBuiltContract',
    data() {
        return {
            loading: false,
            formList: [
                {
                    label: "合同编号",
                    value: "code",
                    inputType: "text",
                    placeholderText:
                        "请输入合同编号进行搜索",
                },
                {
                    label: "合同名称",
                    value: "name",
                    inputType: "text",
                    placeholderText:
                        "请输入合同名称进行搜索",
                },
                {
                    label: "乙方单位",
                    value: "partyBCompany",
                    inputType: "text",
                    placeholderText:
                        "请输入乙方单位进行搜索",
                },
                {
                    label: "项目编号",
                    value: "prjCode",
                    inputType: "text",
                    placeholderText:
                        "请输入项目编号进行搜索",
                },
                {
                    label: "项目名称",
                    value: "prjName",
                    inputType: "text",
                    placeholderText:
                        "请输入项目名称进行搜索",
                },
                {
                    label: "状态",
                    value: "status",
                    inputType: "select",
                    optionsList: [],
                    defaultValue: "",
                    optionsListConfig: {
                        name: "name",
                        code: "code",
                    },
                    placeholderText:
                        "请选择状态进行搜索",
                },
                {},
            ],
            tableColumns: [
                {
                    title: "采购年度",
                    key: "procurementYear",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "自建采购合同编号",
                    key: "code",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "自建采购合同名称",
                    key: "name",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "乙方单位",
                    key: "partyBCompany",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "采购总金额",
                    key: "procurementInitialAmount",
                    align: "center",
                    overflow: true,
                    width: 110,
                },
                {
                    title: "采购合同变更后总金额",
                    key: "procurementFinallyAmount",
                    align: "center",
                    overflow: true,
                    width: 170,
                },
                {
                    title: "采购累计结算金额",
                    key: "procurementSettleAmount",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "未结算金额",
                    key: "unSettleAmount",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "金蝶累计开票金额",
                    key: "procurementinvoiceAmount",
                    align: "center",
                    overflow: true,
                    width: 160,
                }, {
                    title: "金蝶累计实付金额",
                    key: "procurementPaymentAmount",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "未付金额",
                    key: "unPaymentAmount",
                    align: "center",
                    overflow: true,
                    width: 130,
                }, {
                    title: "金蝶合同总金额",
                    key: "purchaseAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "采购未付占采购应付比例",
                    key: "unPayRatio",
                    align: "center",
                    overflow: true,
                    width: 180,
                }, {
                    title: "采购合同额占销售合同比例",
                    key: "puchaseRatio",
                    align: "center",
                    overflow: true,
                    width: 190,
                }, {
                    title: "关联项目编号",
                    key: "prjCode",
                    align: "center",
                    overflow: true,
                    width: 110,
                }, {
                    title: "关联项目名称",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                    width: 130,
                },
                {
                    title: "关联销售合同编号",
                    key: "saleContractCode",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "关联销售合同名称",
                    key: "saleContractName",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "应付外协约定比例",
                    key: "payableOutsourceScale",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "扣除费用",
                    key: "deductExpenses",
                    align: "center",
                    overflow: true,
                }, {
                    title: "扣除费用详情备注",
                    key: "detailRemark",
                    align: "center",
                    overflow: true,
                    width: 140,
                }, {
                    title: "计算外协合同总金额",
                    key: "outsourceAmount",
                    align: "center",
                    overflow: true,
                    width: 150,
                }, {
                    title: "销售合同额",
                    key: "saleContractAmount",
                    align: "center",
                    overflow: true,
                    width: 110,
                }, {
                    title: "销售合同价格形式",
                    key: "saleContractForm",
                    align: "center",
                    overflow: true,
                    width: 140,
                }, {
                    title: "销售合同结算金额",
                    key: "saleContractSettleAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                }, {
                    title: "销售回款",
                    key: "saleContractRefund",
                    align: "center",
                    overflow: true,
                }, {
                    title: "销售回款比例",
                    key: "saleContractRefundRatio",
                    align: "center",
                    overflow: true,
                    width: 110,
                }, {
                    title: "制单人",
                    key: "applierName",
                    align: "center",
                    overflow: true,
                }, {
                    title: "状态",
                    key: "statusName",
                    align: "center",
                    overflow: true,
                },
            ],

            tableHeight: null,
            tableData: [],
            selectData: [],
            // 当前页码
            current: 1,
            // 总条数
            total: 0,
            // 每页的数据条数
            size: 10,
        }
    },
    created() {
        this.getDataType()
        this.getTableData()
    },
    mounted() {
        this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
        });
    },
    methods: {
        handleSelectionChange(e) {
            this.selectData = e;
        },
        getTableData() { 
            postApi(`/contract/selfestablished/procurement/page/${this.current}/${this.size}`, {...this.searchData}).then(res => {
                if (res.data.code == 0) {
                    if (this.pageProp.tableData && this.pageProp.tableData.length > 0) {
                        this.pageProp.tableData.forEach(it => {
                            res.data.data.records.forEach(item => {
                                    if (it.selfEstablishedProcurementId == item.id) {
                                        item.disabledCheck = 1;
                                    }
                            })
                        })
                    }
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                }
            })
        },
        handleSearchEvent(e) {
            this.searchData = e;
            this.current = 1;
            this.getTableData();
        },
        handlePageChange(pageSize, pageNumber) {
            this.size = pageSize;
            this.current = pageNumber;
            this.getTableData()
        },
        getDataType() {
            postApi(`/contract/dicttype/list/all`, { code: "PC-ZJCG-STATUS" }).then(res => {
                if (res.data.code == 0) {
                    this.formList.forEach(item => {
                        if (item.value == 'status') {
                            item.optionsList = res.data.data;
                        }
                    })
                }
            })
        },
        handleCheckDisable(row) {
            if (row.disabledCheck == 1) {
                return false;
            }
            else {
                return true;
            }
        },
    }
}
</script>

<style lang="less" scoped></style>