<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
    <dd-card title="申请单列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            icon="add"
            size="medium"
            @click="handleAddEdit(0)"
            >新增</dd-button
          >
          <dd-button
            type="primary"
            icon="update"
            size="medium"
            @click="handleAddEdit(1)"
            >修改</dd-button
          >
          <dd-button
            icon="delete"
            type="danger"
            size="medium"
            autoConfirm
            @click="handleDelete"
            >删除</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
          <!--          <el-button-->
          <!--            icon="el-icon-download"-->
          <!--            circle-->
          <!--            type="info"-->
          <!--            @click="handleFileExport"-->
          <!--            size="small"-->
          <!--            title="下载"-->
          <!--          ></el-button>-->
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 详情 -->
    <dd-popup
      v-if="dialogDetailVisible"
      width="70%"
      height="80%"
      :showBtn="false"
      :title-name="popTitle"
      :show="dialogDetailVisible"
      @popup-close="handleClose"
    >
      <DetailPop slot="main" :type="type" ref="addPop" :id="id"></DetailPop>
    </dd-popup>
    <!-- 新增/修改 -->
    <dd-popup
      v-if="dialogVisible"
      width="70%"
      height="80%"
      :showBtn="showBtn"
      :saveBtn="true"
      :submitBtn="true"
      :cancelBtn="true"
      :title-name="popTitle"
      :show="dialogVisible"
      @popup-close="handleClose"
      @popup-submit="handleSave(1)"
      @popup-save="handleSave(0)"
    >
      <AddUpdateDetailInfoPop
        slot="main"
        :type="type"
        ref="addPop"
        :id="id"
      ></AddUpdateDetailInfoPop>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="handleClose"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import DetailPop from "./components/Detail";
import AddUpdateDetailInfoPop from "./components/AddUpdateDetailInfoPop";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
import { mapState, mapActions } from "vuex";
import qs from "qs";
export default {
  name: "privateCar",
  $dynamicRoute: {
    name: "私车公用申请单",
    path: "/personnel/administration/private/car",
  },
  components: { AddUpdateDetailInfoPop, DetailPop },
  data() {
    return {
      tableHeight: null,
      showBtn: true,
      type: "",
      id: "",
      popTitle: "",
      drawerVisible: false,
      dialogDetailVisible: false,
      processInstanceId: null,
      loading: false,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      rowData: null,
      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "申请编号",
          value: "applyCode",
          inputType: "text",
          placeholderText: "请输入申请编号",
          title: true,
        },
        {
          label: "项目名称",
          value: "proName",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "项目编号",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目编号",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
          format: "yyyy-MM-dd",
        },
        {
          label: "单据状态",
          value: "formStatus",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索单据状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "上报状态",
          value: "reportStatus",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索上报状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "上报日期",
          value: "reportMonth",
          inputType: "date",
          dateType: "monthrange",
          format: "yyyy-MM",
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "用车补助类型",
          key: "carSubsidyTypeName",
          align: "center",
          width: 130,
          overflow: true,
        },
        {
          title: "申请编号",
          key: "applyCode",
          align: "center",
          width: 200,
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          overflow: true,
        },
        {
          title: "项目编号",
          key: "projectCode",
          width: 150,
          align: "center",
        },
        {
          title: "申请日期",
          key: "applyTime",
          align: "center",
          width: 120,
        },
        {
          title: "出发地",
          key: "departurePlace",
          align: "center",
          overflow: true,
        },
        {
          title: "目的地",
          key: "destinationPlace",
          align: "center",
          overflow: true,
        },
        {
          title: "用车公里数",
          key: "useKilometer",
          align: "center",
        },
        {
          title: "核定公里数",
          key: "approvedKilometer",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "用车事宜",
          key: "vehicleMatter",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "单据状态",
          key: "formStatus",
          align: "center",
        },
        {
          title: "上报状态",
          key: "reportStatus",
          align: "center",
        },
        {
          title: "上报日期",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleAddEdit(3, row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    //获取项目信息
    this.getProject();
    // 获取单据状态
    this.getStateList();
    this.getReportStateList();
    // this.getConditionData();
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  // computed: {
  //   ...mapState(["btnAuthorityList"]), //权限标识数组
  // },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //获取项目信息
    getProject() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        console.log(data);
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "proName" || item.value == "projectId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 单据状态下拉数据请求
    getStateList() {
      postApi(`/hr/hrdict/list?typeCode=sqddjzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "formStatus") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 单据上报下拉数据请求
    getReportStateList() {
      postApi(`/hr/hrdict/list?typeCode=djsbzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "reportStatus") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 预算申请列表请求
    getApplyList() {
      this.handleClose();
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      postApi(`/hr/applysheet/page`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },

    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (!item.event) {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      let formData = {
        ...this.formData,
        current: 1,
        size: 99999,
      };
      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/apply/front/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },

    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getApplyList();
    },
    // 新增弹框关闭事件
    handleClose() {
      this.dialogVisible = false;
      this.drawerVisible = false;
      this.dialogDetailVisible = false;
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.multipleSelection = [];
    },
    handleAddEdit(type, row) {
      // type 0: 新建；1：编辑；3：详情
      if (type == 0) {
        this.id = null;
        this.type = "add";
        this.popTitle = "新增";
        this.dialogVisible = true;
        this.showBtn = true;
      } else if (type == 1) {
        if (this.multipleSelection.length !== 1) {
          this.$message.warning("请选择一条数据！");
          return;
        } else if (
          this.multipleSelection[0].formStatus != "暂存" &&
          this.multipleSelection[0].formStatus != "退回"
        ) {
          this.$message.warning("只能修改删除暂存状态数据！");
          return;
        }
        console.log(this.multipleSelection[0]);
        this.id = this.multipleSelection[0].id;
        this.type = "update";
        this.popTitle = "修改";
        this.dialogVisible = true;
        this.showBtn = true;
      } else {
        this.id = row.id;
        this.type = "detail";
        this.popTitle = "详情";
        this.dialogDetailVisible = true;
        this.showBtn = false;
      }
    },
    handleDelete() {
      let idArr = [];
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请选择要删除的数据！");
        return;
      }
      for (var i = 0; i < this.multipleSelection.length; i++) {
        let item = this.multipleSelection[i];
        if (item.formStatus != "暂存" && item.formStatus != "退回") {
          this.$message.warning("只能选择暂存或退回状态数据删除！");
          return;
        } else {
          idArr.push(item.id);
        }
      }
      var id = { ids: idArr.join() };
      this.$confirm("是否确定删除用车申请？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          postApi(`/hr/applysheet/delete`, id)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getApplyList();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "useKilometer" ||
          column.property == "approvedKilometer"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              console.log(value);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} size 每页显示条数
     * @param {*} current 第几页
     */
    handlePageChange(size, current) {
      this.size = size;
      this.current = current;
      this.getApplyList();
    },
    // 新增确认事件
    handleSave(type) {
      let flag = false;
      this.$refs.addPop.$refs.baseForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return;
      }
      let hrVehicleApplySheetFileList =
        this.$refs.addPop.hrVehicleApplySheetFileList;
      let { formData } = this.$refs.addPop.$refs.baseForm;
      console.log('formData', formData)
      // formData.carSubsidyType == '私车公用'
      formData.applierId = sessionStorage.getItem("userId");
      formData.applierName = sessionStorage.getItem("fullName");
      formData.expenseBearOrgId = sessionStorage.getItem("orgId");
      let params = {
        ...formData,
        hrVehicleApplySheetFileList,
      };
      if (this.id) {
        params.id = this.id;
      }
      postApi(`/hr/applysheet/save/${type}`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success(`${type == 1 ? "提交成功" : "保存成功"}`);
          this.getApplyList();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
