<template>
  <div class="main_wrap" ref="main" id="main_wrap">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "dd-main",
  components: {},
  computed: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {},
  destroyed() {
    console.log(1);
    this.$destroy(true);
  },
};
</script>

<style lang="less" scoped>
.main_wrap {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>
