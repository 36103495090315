<template>
  <div class="tab-container">
    <div class="left-tree container-left-tree">
      <el-tree
        ref="tree"
        :data="TreeList"
        accordion
        :props="defaultProps"
        default-expand-all
        highlight-current
        :expand-on-click-node="false"
        node-key="nodeCode"
        :current-node-key="treeNodeId"
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
    <div class="right-table">
      <dd-card title="条件搜索" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
      <dd-card :height="tableHeight">        
        <dd-table
          :columns="tableColumns"
          :data="tableData"
          border
          :isSelection="false"
          show-page
          :loading="loading"
        >
        <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="export"
              @click="handleAllExport"
              >导出</dd-button
            >
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
  </div>
</template>

<script>
import { getApi, postDownBlobApi } from "@/api/request";
export default {
  props: {
    resolveType: {
      type: String,
      default: "",
    },
    rowId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableHeight: null,
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      treeNodeId: "",
      loading: true,
      formList: [
        {
          label: "填报时间",
          value: "applyTimeSearch",
          inputType: "date",
          dateType: "daterange",
          format: "yyyy-MM-dd",
        },
      ],
      tableColumns: [
        {
          title: "工号",
          key: "userCode",
          align: "center",
        },
        {
          title: "姓名",
          key: "userName",
          align: "center",
          overflow: true,
        },
        {
          title: "填报时间",
          key: "applyTime",
          align: "center",
        },
        {
          title: "工作包名称",
          key: "assignNodeName",
          align: "center",
        },
        {
          title: "单位",
          key: "nodeUnitName",
          align: "center",
        },
        {
          title: "工作量",
          key: "applyWorkload",
          align: "center",
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
        },
        {
          title: "标准工天",
          key: "planStandardWorkday",
          align: "center",
        },
        {
          title: "所属主节点",
          key: "assignNodeNameParent",
          align: "center",
        },
        {
          title: "状态",
          key: "workloadStateName",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 200,
          event: [
            // {
            //   show: "true",
            //   label: "查看详情",
            //   icon: "el-icon-view",
            //   method: (row) => {
            //     this.handleRowEditor(row);
            //   },
            // },
            {
              show: "true",
              label: "退回",
              icon: "el-icon-back",
              method: (row) => {
                this.handleRowBack(row);
              },
              type: "warning",
            },
            {
              show: "true",
              label: "作废",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowVoid(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      treeAssignId: null,
    };
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.treeAssignId = this.rowId;
    this.getLeftTree();
    this.getTableData();
  },
  methods: {
    // 获取左侧树形数据
    getLeftTree() {
      getApi(`/project/subplan/getsubplanenabletree/${this.rowId}`).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0 && data.data) {
            this.defaultProps.label = "nodeName";
            this.TreeList = data.data;
            this.treeNodeId = data.data[0].nodeCode;
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
            });
          }
        }
      );
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        assignId: this.treeAssignId,
        nodeCode: this.treeNodeId,
        current,
        size,
      };
      this.loading = true;
      getApi(`/project/real/workload/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    handleNodeClick(data) {
      this.treeNodeId = data.nodeCode;
      this.treeAssignId = data.assignId;
      this.getTableData();
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    handleAllExport() {
      let { formData } = this;
      let params = {
        ...formData,
        id: this.treeAssignId,
      };
      postDownBlobApi(
        `/project/real/workload/workload/export`,
        params,
        "工作量管理"
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
        }
      });
    },
    // 退回
    handleRowBack(row) {
      this.$confirm(`确认要退回此数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        getApi(`/project/real/workload/backbyid/${row.id}`).then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.getTableData();
          }
        });
      });
    },
    // 作废
    handleRowVoid(row) {
      this.$confirm(`确认要作废此数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        getApi(`/project/real/workload/cancelbyid/${row.id}`).then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.getTableData();
          }
        });
      });
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.tab-container {
  height: 100%;
  display: flex;
  .left-tree {
    width: 20%;
    height: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    border-right: 5px solid #e7eaec;
    overflow-y: auto;
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: @fs14;

      .node-hanlde {
        color: @defaultFontColor;
        i {
          margin: 0 5px;
        }
      }
    }
  }
  .right-table {
    width: 80%;
    height: 100%;
    .table-tab {
      /deep/ .card-contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        .el-tabs {
          width: 100%;
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
    }
  }
}
</style>
