import {Loading} from 'element-ui';
let loading
export function showLoading(text,bg){
  loading =Loading.service({
    lock: true,
    text: text ? text : '加载中...',
    background: bg ? bg : 'rgba(0, 0, 0, 0.7)'
  })
}
export function hideLoading(){
  loading.close()
}