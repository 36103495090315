<template>
    <div class="container">
        <dd-card title="">
            <dd-form :form-config="formList" :labelWidth="'170px'" :flex="3" ref="assignForm_contractInformation"
                formRef="assignForm_contractInformation"></dd-form>
        </dd-card>
        <div class="table_box">
            <dd-table :columns="tableColumns" :data="tableData" border :isSelection="false" :loading="loading">
            </dd-table>
        </div>

        <div class="table_box">
            <dd-table :columns="tableColumns_1" :data="tableData_1" border :isSelection="false" :loading="loading">
            </dd-table>
        </div>

        <dd-card title="">
            <dd-form :form-config="formList_person" :labelWidth="'170px'" :flex="3" ref="assignForm_pers"
                formRef="assignForm_pers"></dd-form>
        </dd-card>
    </div>
</template>

<script>
import { getApi } from '@/api/request';

export default {
    name: 'contractInformation',
    props: ['pageProp'],
    data() {
        return {
            loading: false,
            formList: [
                {
                    label: "自建采购合同编号",
                    value: "code",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "请输入自建采购合同编号",
                },
                {
                    label: "自建采购合同名称",
                    value: "name",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "请输入自建采购合同名称",
                },
                {
                    label: "采购年度",
                    value: "procurementYear",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "请输入采购年度",
                },
                {
                    label: "乙方单位",
                    value: "partyBCompany",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "请输入乙方单位",
                },
                {
                    label: "采购合同类型",
                    value: "procurementTypeName",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "请输入采购合同类型",
                },
                {
                    label: "采购合同总金额",
                    value: "procurementInitialAmount",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "请输入采购合同总金额",
                },
                {
                    label: "关联项目名称",
                    value: "prjName",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "请输入关联项目名称",
                },
                {
                    label: "关联项目编号",
                    value: "prjCode",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "请输入关联项目编号",
                },
            ],
            formList_person: [
                {
                    label: "制单人",
                    value: "applierName",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "请输入制单人",
                },
                {
                    label: "最近改动者",
                    value: "modifierName",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入最近改动者",
                },
            ],
            tableColumns: [
                {
                    title: "采购总金额",
                    key: "procurementInitialAmount",
                    align: "center",
                    overflow: true,
                    width: 100
                },
                {
                    title: "采购合同变更后总金额",
                    key: "procurementFinallyAmount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "已关联金蝶合同总金额",
                    key: "purchaseAmount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "采购结算金额",
                    key: "procurementSettleAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "采购已付金额",
                    key: "procurementPaymentAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "采购剩余未付",
                    key: "unPaymentAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "采购未付占采购应付比例",
                    key: "unPayRatio",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "采购合同额占销售合同比例",
                    key: "puchaseRatio",
                    align: "center",
                    overflow: true,
                },
            ],
            tableColumns_1: [
                {
                    title: "关联销售合同编号",
                    key: "saleContractCode",
                    align: "center",
                    overflow: true,
                    width: 140
                },
                {
                    title: "关联销售合同名称",
                    key: "saleContractName",
                    align: "center",
                    overflow: true,
                    width: 140
                },
                {
                    title: "应付外协约定比例",
                    key: "payableOutsourceScale",
                    align: "center",
                    overflow: true,
                    width: 140
                },
                {
                    title: "扣除费用",
                    key: "deductExpenses",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "扣除费用详情备注",
                    key: "detailRemark",
                    align: "center",
                    overflow: true,
                    width: 140
                },
                {
                    title: "计算外协合同总金额",
                    key: "outsourceAmount",
                    align: "center",
                    overflow: true, 
                    width: 150
                },
                {
                    title: "销售合同额",
                    key: "saleContractAmount",
                    align: "center",
                    overflow: true,
                    width: 100
                },
                {
                    title: "销售合同价格形式",
                    key: "saleContractForm",
                    align: "center",
                    overflow: true,
                    width: 150
                },
                {
                    title: "销售合同结算金额",
                    key: "saleContractSettleAmount",
                    align: "center",
                    overflow: true,
                    width: 140
                },
                {
                    title: "销售回款",
                    key: "saleContractRefund",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "销售回款比例",
                    key: "saleContractRefundRatio",
                    align: "center",
                    overflow: true,
                    width: 140
                },
            ],
            tableData: [],
            tableData_1: [],
        }
    },
    created() {
        this.getPageData()
    },
    mounted() {

    },
    methods: {
        getPageData() {
            getApi(`/contract/selfestablished/procurement/self/purchase`, {id: this.pageProp.id, type: 'detail'}).then(res => {
                if(res.data.code == 0) {
                    let { data } = res;
                    this.$refs.assignForm_contractInformation.formData = data.data;
                    this.$refs.assignForm_pers.formData = {
                        applierName: data.data.applierName,
                        modifierName: data.data.modifierName
                    }
                    this.tableData = [
                        {
                            procurementInitialAmount: data.data.procurementInitialAmount,
                            procurementFinallyAmount: data.data.procurementFinallyAmount,
                            purchaseAmount: data.data.purchaseAmount,
                            procurementSettleAmount: data.data.procurementSettleAmount,
                            procurementPaymentAmount: data.data.procurementPaymentAmount,
                            unPaymentAmount: data.data.unPaymentAmount,
                            unPayRatio: data.data.unPayRatio,
                            puchaseRatio: data.data.puchaseRatio,
                        }
                    ];
                    this.tableData_1 = [
                        {
                            saleContractCode: data.data.saleContractCode,
                            saleContractName: data.data.saleContractName,
                            payableOutsourceScale: data.data.payableOutsourceScale,
                            deductExpenses: data.data.deductExpenses,
                            detailRemark: data.data.detailRemark,
                            outsourceAmount: data.data.outsourceAmount,
                            saleContractAmount: data.data.saleContractAmount,
                            saleContractForm: data.data.saleContractForm,
                            saleContractSettleAmount: data.data.saleContractSettleAmount,
                            saleContractRefund: data.data.saleContractRefund,
                            saleContractRefundRatio: data.data.saleContractRefundRatio,
                        }
                    ]
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>