<template>
  <div class="header">
    <div class="title_name">
      <i
        :class="collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
        @click="handleMenuCollapse"
        style="cursor: pointer"
      ></i>
      运营管理系统
    </div>
    <div class="user_info_wrap">
      <div class="user_info">
        <div class="user_name">欢迎 {{ userName }}</div>
        <el-select
          v-model="orgValue"
          placeholder="请选择"
          @change="handleSelectChange"
        >
          <el-option
            v-for="item in orgList"
            :key="item.orgId"
            :label="item.name"
            :value="item.orgId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="login_out" @click="handleUpdataPwd">
        <i class="iconfont icon-password"></i> 修改密码
      </div>
      <div class="login_out" @click="handleLoginOut">
        <i class="iconfont icon-tuichudenglu"></i> 退出
      </div>
    </div>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="30%"
      height="35%"
      showBtn
      :saveBtn="false"
      :submitBtn="true"
      :show="addPopupVisible"
      title-name="修改密码"
      @popup-close="addPopupVisible = false"
      @popup-submit="handleSubmit"
    >
      <dd-form
        v-if="addPopupVisible"
        slot="main"
        :form-config="formBasicList"
        ref="addPopForm"
      >
      </dd-form>
    </dd-popup>
  </div>
</template>

<script>
import { clearAllStore, getStore } from "../../utils/store";
import { delApi, postApi, putApi } from "@/api/request";
import { mapActions, mapState } from "vuex";

export default {
  name: "dd-header",
  components: {},
  computed: {
    ...mapActions(["asyncGetOrgList"]),
    ...mapState(["orgList"]),
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (value === "123456") {
        callback(new Error("密码不能为123456"));
      } else {
        if (this.$refs.addPopForm.formData.newpassword2 !== "") {
          this.$refs.addPopForm.$refs.baseForm.validateField("newpassword2");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.$refs.addPopForm.formData.newpassword1) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      collapse: false, // 菜单是否折叠
      userName: getStore({ name: "fullName" }),
      orgValue: getStore({ name: "orgId" }),
      addPopupVisible: false,
      formBasicList: [
        {
          label: "原密码",
          value: "password",
          inputType: "text",
          type: "password",
          placeholderText: "请输入原密码",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入原密码",
              trigger: "blur",
            },
            { min: 6, max: 12, message: "密码长度为6-12位", trigger: "blur" },
          ],
        },
        {
          label: "新密码",
          value: "newpassword1",
          inputType: "text",
          type: "password",
          placeholderText: "请输入新密码",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              validator: validatePass,
              trigger: "blur",
            },
            {
              required: true,
              pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~.()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~.()-+=]+$)(?![0-9\W_!@#$%^&*`~.()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~.()-+=]{8,16}$/,
              message: "数字，大写字母，小写字母，特殊字符四选三且长度在8到16位之间",
              trigger: "blur",
            },
          ],
        },
        {
          label: "确认密码",
          value: "newpassword2",
          inputType: "text",
          type: "password",
          placeholderText: "请输入确认密码",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              validator: validatePass2,
              trigger: "blur",
            },
            {
              required: true,
              pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~.()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~.()-+=]+$)(?![0-9\W_!@#$%^&*`~.()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~.()-+=]{8,16}$/,
              message: "数字，大写字母，小写字母，特殊字符四选三且长度在8到16位之间",
              trigger: "blur",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("asyncGetOrgList");
  },
  mounted() {
  },
  watch: {
    orgList: {
      handler(val) {
        this.orgList.map(item => {
                if(item.whetherCurrent == 1) {
                    this.orgValue = item.orgId
                    sessionStorage.setItem("orgId", item.orgId);
                }
            })
      }
    }
  },
  methods: {
    //菜单收缩
    handleMenuCollapse() {
      this.collapse = !this.collapse;
      this.$bus.$emit("collapse", this.collapse);
    },
    // 组织机构切换事件
    handleSelectChange(e) {
      this.orgValue = e;
      let params = {
        orgId: e,
      };
      postApi(`/admin/user/change/userorg`, params).then((res) => {
        if (res.data && res.data.code == 0) {
          sessionStorage.setItem("orgId", e);
          sessionStorage.setItem(
            "fullName",
            res.data.data.userVO.fullName
          );
          sessionStorage.setItem("depId", res.data.data.userVO.deptId);
          sessionStorage.setItem(
            "depName",
            res.data.data.userVO.deptName
          );
          sessionStorage.setItem(
            "orgName",
            res.data.data.userVO.orgName
          );
          sessionStorage.setItem("orgId", res.data.data.userVO.orgId);
          location.reload();
        }
      });
    },
    // 展示修改密码弹窗
    handleUpdataPwd() {
      this.addPopupVisible = true;
    },
    // 修改密码提交
    handleSubmit() {
      this.$refs.addPopForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          let params = this.$refs.addPopForm.formData;
          putApi(`/admin/user/edit/pwd`, params).then((res) => {
            if (res.data && res.data.code == 0) {
              this.$message.success(
                "密码修改成功，请重新登录，3秒后自动跳入登录页面！"
              );
              this.addPopupVisible = false;
              setTimeout(() => {
                this.handleLoginOut();
              }, 3000);
            }
          });
        }
      });
    },
    //退出登录
    handleLoginOut() {
      delApi(`/auth/token/logout`, {}).then((res) => {
        this.$store.commit("DEL_ALL_TAG");
        clearAllStore();
        this.$router.push({
          path: "/login",
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_name {
    font-size: 20px;
    font-weight: bold;
  }
  .user_info_wrap {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .user_info {
      display: flex;
      align-items: center;
      .user_name {
        color: @defaultFontColor;
        font-weight: 400;
        font-size: @fs16;
        line-height: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
    .login_out {
      color: @defaultFontColor;
      font-size: @fs16;
      line-height: 30px;
      height: 30px;
      cursor: pointer;

      .iconfont {
        font-weight: 800;
      }
    }
  }
  .user_info {
    /deep/ .el-input__inner {
      height: 30px;
      line-height: 30px;
      border: none;
      font-weight: 400;
      font-size: @fs16;
      color: @defaultFontColor;
      border-left: 1px solid @dividerColor;
      border-right: 1px solid @dividerColor;
      border-radius: 0;
    }
    /deep/ .el-input__icon {
      line-height: 30px;
      color: @defaultFontColor;
    }
  }
}
</style>
