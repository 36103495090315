<template>
    <div class="main-container">
        <div class="icon-box">
            <div class="icon-item" v-for="(item,idx) in iconDataList" :key="idx" @click="handleItem(item.iconName)">
                <i :class="['iconfont', item.iconName]"></i>
                <div class="itemName">{{ item.name }}</div>
            </div>
        </div>

    </div>
</template>
  
<script>
import iconList from "@/assets/font/iconfont.json";
export default {
    name: "font-icon",
    props: {
    },
    data() {
        return {
            iconDataList: []
        };
    },
    created() { },
    mounted() {
       iconList.glyphs.map(item => {
            item.iconName = `icon-${item.font_class}`
       });
       this.iconDataList = iconList.glyphs;
    },
    methods: {
        handleItem(e) {
            this.$emit('getIconVal',e)
        }
    },
};
</script>
<style lang='less' scoped>
.icon-box {
    display: flex;
    flex-wrap: wrap;
    .icon-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 10%;
        margin-bottom: 15px;
        cursor: pointer;
        transition: all 0.1s;
        
        i {
            font-size: @fs24;
            margin-bottom: 3px;
        }
    }
    .icon-item:hover {
        transform: scale(1.1);
    }
}
</style>
  