<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="用户列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        isSort
        border
        :isSelection="false"
        show-page
        :loading="loading"
      >
        <template #table-btn>
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd"
            >新增</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="45%"
      height="55%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :dictType="dictType"
        :deptTree="deptTree"
        :orgTree="orgTree"
        :roleTree="roleTree"
        :postTree="postTree"
        :userType="userType"
        :rowData="rowData"
      ></add-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi, postfromApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
export default {
  name: "user",
  $dynamicRoute: {
    name: "用户管理",
    path: "/admin/user/index",
  },
  components: {
    addPop,
  },
  computed: {},
  data() {
    return {
      tableHeight: null,
      drawerVisible: false,
      loading: true,
      formData: {},
      formList: [
        {
          label: "工号/账号",
          value: "username",
          inputType: "text",
          placeholderText: "请输入工号/账号",
        },
        {
          label: "姓名",
          value: "fullName",
          inputType: "text",
          placeholderText: "请输入姓名",
        },
      ],
      tableColumns: [
        {
          title: "工号/账号",
          key: "username",
          align: "center",
          width: 100,
        },
        {
          title: "姓名",
          key: "fullName",
          align: "center",
          width: 100,
        },
        {
          title: "电话",
          key: "phone",
          align: "center",
          width: 110,
        },
        {
          title: "所属机构",
          key: "orgName",
          align: "center",
          overflow: true,
        },
        {
          title: "所属部门",
          key: "deptName",
          align: "center",
          width: 120,
        },
        {
          title: "所属岗位",
          key: "postList",
          align: "center",
          formatterArr: (row) => {
            return this.getArrItem("postName", row);
          },
        },
        {
          title: "拥有角色",
          key: "roleList",
          align: "center",
          formatterArr: (row) => {
            return this.getArrItem("roleName", row);
          },
        },
        {
          title: "用户类型",
          key: "typeId",
          align: "center",
          width: 100,
          formatter: (row) => {
            return this.getState("userType", row);
          },
        },
        {
          title: "状态",
          key: "lockFlag",
          align: "center",
          width: 80,
          formatter: (row) => {
            return this.getState("dictType", row);
          },
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 260,
          event: [
            {
              show: "true",
              label: "密码重置",
              icon: "el-icon-refresh",
              method: (row) => {
                this.handlePasswordRest(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      dictType: [],
      deptTree: [],
      roleTree: [],
      postTree: [],
      orgTree: [],
      userType: [],
      addPopupVisible: false,
      rowData: null,
      poptitleName: "新增",
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      getApi(`/admin/dict/key/user_lock_flag`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "lockFlag",
            optionsList: data.data,
          };
          this.dictType = data.data;
          this.setOPtionsList(this.formList, options);
        }
      });
      //   用户类型
      getApi(`/admin/dict/key/type-user`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.userType = data.data;
        }
      });
      //   机构
      getApi(`/admin/org/tree`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.orgTree = data.data;
        }
      });

      // 部门
      getApi(`/admin/dept/tree`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.deptTree = data.data;
        }
      });
      // 角色
      getApi(`/admin/role/list`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.roleTree = data.data;
        }
      });
      // 岗位
      getApi(`/admin/post/list`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.postTree = data.data;
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      getApi(`/admin/user/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
    },
    //   密码重置
    handlePasswordRest(row) {
      this.$confirm(`确认将该账号密码重置为123456？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        let params = {
          id: row.userId,
        };
        postfromApi(`/admin/user/resetpwd`, params).then((res) => {
          let { data } = res;
          if (data && data.code == 0 && data.data) {
            this.$message.success("密码重置成功");
          }
        });
      });
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "编辑";
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.userId);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/admin/user/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    // 格式化类型
    getState(key, row) {
      let label = "";
      this[key].forEach((item) => {
        if (item.value == row) {
          label = item.label;
        }
      });
      return label;
    },
    getArrItem(key, row) {
      let arr = [];
      row.forEach((item) => {
        arr.push(item[key]);
      });
      return arr;
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (flag) {
        if (this.rowData != null) {
          params.userId = this.rowData.userId;
          putApi(`/admin/user`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.handleClose();
              this.getTableData();
            }
          });
        } else {
          postApi(`/admin/user`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
