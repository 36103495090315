<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm" labelWidth="130px"> </dd-form>
  </div>
</template>

<script>
import { getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowData: [],
  },
  data() {
    return {
      formBasicList: [
        {
          label: "上级业务类型",
          value: "parentId",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
            checkStrictly: true,
            emitPath: false,
          },
          showAllSelcet: false,
          placeholderText: "请选择上级业务类型",
          rules: [
            {
              required: true,
              message: "请选择上级业务类型",
              trigger: "blur",
            },
          ],
        },
        // {
        //   label: "业务分类编码",
        //   value: "businessCode",
        //   inputType: "text",
        //   placeholderText: "请输入业务分类编码",
        //   disabled: false,
        //   defaultValue: "",
        //   rules: [
        //     {
        //       required: true,
        //       message: "请输入业务分类编码",
        //       trigger: "blur",
        //     },
        //   ],
        // },
        {
          label: "业务分类名称",
          value: "name",
          inputType: "text",
          placeholderText: "请输入业务分类名称",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入业务分类名称",
              trigger: "blur",
            },
          ],
        },
        {
          label: "排序",
          value: "weight",
          inputType: "number",
          min: 0,
          max: 999,
          placeholderText: "请输入排序",
          disabled: false,
          defaultValue: "",
        },
        {
          label: "备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入备注",
          disabled: false,
          defaultValue: "",
        },
      ],
    };
  },
  created() {},
  mounted() {
    this.getParentList();
    if (this.rowData != null) {
      this.formBasicList.forEach((item) => {
        item.defaultValue = this.rowData[item.value];
      });
    }
  },
  methods: {
    getParentList(){
      getApi(`/project/prjareasubassginbussitype/tree`,{}).then(res=>{
        let { data } = res;
        if (data.code == 0) {
          let newTree = [
            {
              name: "根菜单",
              id: "-1",
              children: this.dictType,
            },
          ];
          this.formBasicList.forEach((item) => {
              item.optionsList = [...newTree,...data.data];
          });
        }
      })
    }
  },
};
</script>
<style lang="less" scoped></style>
