<template>
  <div class="main-container">
    <dd-card title="用车汇总列表">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        max-height="550"
        :topBtn="false"
        border
        ref="dTableRef"
        tableRef="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        :isSelection="false"
        @select-change="handleSelectionChange"
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      v-if="dialogDetailVisible"
      width="70%"
      height="80%"
      title-name="详情"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
      :inPopup="true"
    >
      <AddUpdateDetailInfoPop
        slot="main"
        :type="applyType"
        :id="applyDetailId"
      ></AddUpdateDetailInfoPop>
    </dd-popup>
    <!-- 单图片预览 -->
    <el-image-viewer
      v-if="isspaImgePreview"
      :urlList="[spaImgePreviewUrl]"
      :on-close="closeViewer"
    ></el-image-viewer>
  </div>
</template>
  
<script>
import { formatNumber } from "@/utils/number";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import AddUpdateDetailInfoPop from "../../../Department/Apply/components/AddUpdateDetailInfoPop";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
import qs from "qs";
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    payeeId: {
      type: String,
      default: "",
    },
  },
  components: { ElImageViewer, AddUpdateDetailInfoPop },
  data() {
    return {
      loading: false,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据

      // 表格头部配置数据
      tableColumns: [
        {
          title: "用车补助类型",
          key: "carSubsidyTypeName",
          align: "center",
        },
        {
          title: "填报人",
          key: "applierName",
          align: "center",
        },
        {
          title: "收款人",
          key: "payeeName",
          align: "center",
        },
        {
          title: "用车事宜",
          key: "vehicleMatter",
          align: "center",
          width: 100,
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          width: 110,
          overflow: true,
        },
        {
          title: "项目编号",
          key: "projectCode",
          align: "center",
          width: 110,
          overflow: true,
        },
        {
          title: "起始日期",
          key: "vehicleStartDate",
          align: "center",
          width: 100,
        },
        {
          title: "终止日期",
          key: "vehicleEndDate",
          align: "center",
          width: 100,
        },
        {
          title: "起始公里",
          key: "startKilometer",
          align: "center",
          href: [
            {
              method: (row) => {
                this.handleImgPreview(row, "startKilometer");
              },
            },
          ],
        },
        {
          title: "结束公里",
          key: "endKilometer",
          align: "center",
          href: [
            {
              method: (row) => {
                this.handleImgPreview(row, "endKilometer");
              },
            },
          ],
        },
        {
          title: "申请时间",
          key: "applyTime",
          align: "center",
          width: 100,
        },
        {
          title: "核定公里",
          key: "approvedKilometer",
          align: "center",
          width: 100,
        },
        {
          title: "标准(元/km)",
          key: "standardParameter",
          align: "center",
          width: 130,
        },
        {
          title: "金额(元)",
          key: "approvedAmount",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetailInfo(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
      dialogDetailVisible: false,
      isspaImgePreview: false,
      spaImgePreviewUrl: "",
      applyDetailId: "",
      applyType: "",
    };
  },
  created() {},
  mounted() {
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    //单图片预览
    handleImgPreview(row, type) {
      console.log(row, type);
      if (type == "startKilometer") {
        this.spaImgePreviewUrl = row.hrVehicleApplySheetFileList[0].url;
      } else {
        this.spaImgePreviewUrl = row.hrVehicleApplySheetFileList[1].url;
      }
      this.isspaImgePreview = true;
    },
    closeViewer() {
      this.isspaImgePreview = false;
    },
    // 预算详情
    handleDetailInfo(row) {
      this.applyDetailId = row.id;
      this.applyType = "detail";
      this.dialogDetailVisible = true;
    },
    // 申请列表请求
    getApplyList() {
      this.loading = true;
      let { current, size } = this;
      let params = {
        current,
        size,
        id: this.id,
        payeeId: this.payeeId,
      };
      postApi(`/hr/collectsheet/list/apply`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.standardParameter = formatNumber(item.standardParameter);
            item.approvedAmount = formatNumber(item.approvedAmount);
          });
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.dTableRef.clearSelection();
      this.formData = obj;
      this.current = 1;
      this.getApplyList();
    },
    handleAddEdit(type) {
      // type 0: 新建；1：编辑
      if (type == 0) {
        this.addEdit.id = null;
        this.addEditTitle = "添加项目预算单";
        this.addEdit.type = "add";
        this.dialogAddEditVisible = true;
      } else {
        if (this.multipleSelection.length !== 1) {
          this.$message.warning("请选择一条数据！");
          return;
        }
        // dtj: 项目预算状态 待提交 状态编码
        if (
          this.multipleSelection[0].state !== "dtj" &&
          this.multipleSelection[0].state !== "yth-2"
        ) {
          this.$message.warning("只能选择待提交或已退回状态数据编辑！");
          return;
        }
        this.addEdit.id = this.multipleSelection[0].id;
        this.addEdit.type = "edit";
        this.addEditTitle = "编辑项目预算单";
        this.dialogAddEditVisible = true;
      }
    },
    handleDelete() {
      var idArr = [];
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请选择要删除的数据！");
        return;
      }
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (
          this.multipleSelection[i].state != "dtj" &&
          this.multipleSelection[i].state != "yth-2"
        ) {
          this.$message.warning("只能选择待提交或已退回状态数据删除！");
          return;
        } else {
          idArr.push(this.multipleSelection[i].id);
        }
      }
      var id = { ids: idArr.join() };
      this.$confirm("是否确定删除预算申请？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          postApi(`/finance/apply/deleteApply`, id)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getApplyList();
                this.$refs.dTableRef.$refs.dTableRef.clearSelection();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 格式化表格单据状态
    getState(cellValue) {
      if (cellValue == "dtj") {
        return "待提交";
      } else if (cellValue == "shz") {
        return "审核中";
      } else if (cellValue == "shtg") {
        return "审核通过";
      } else if (cellValue == "shbtg") {
        return "审核不通过";
      }
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "approvedAmount"
          // column.property == "approvedKilometer" ||
          // column.property == "startKilometer" ||
          // column.property == "endKilometer"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          console.log(values);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              console.log(value);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      let list = [...sums];
      list.map((item, index) => {
        if (index == sums.length - 1) {
          sums[index] = formatNumber(sums[index]);
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} size 每页显示条数
     * @param {*} current 第几页
     */
    handlePageChange(size, current) {
      this.size = size;
      this.current = current;
      this.getApplyList();
    },
    // 弹框保存回调事件
    handlePopupSave() {
      this.$refs.addEditPop.submitOrSave();
    },
    // 弹框提交回调事件
    handlePopupSubmit() {},
    // 弹框重置回调事件
    handlePopupRest() {},
    //获取项目信息
    getProject() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.projectCodeOptions = {
            value: "projectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, this.options);
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
  },
};
</script>
  
<style lang='less' scoped>
</style>

<style>
.el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>