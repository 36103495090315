<template>
  <div class="main-container">
    <div class="head_wrap">
      <dd-card title="条件搜索" spacer>
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
    </div>
    <dd-card title="用户列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        isSort
        border
        :isSelection="false"
        show-page
        :loading="loading"
      >
        <template #table-btn>
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd"
            >新增</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="45%"
      height="55%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :dictType="dictType"
        :userType="userType"
        :rowData="rowData"
      ></add-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi, postfromApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
export default {
  name: "user",
  $dynamicRoute: {
    name: "外部用户",
    path: "/admin/external/index",
  },
  components: {
    addPop,
  },
  computed: {},
  data() {
    return {
      tableHeight: null,
      drawerVisible: false,
      loading: true,
      formData: {},
      formList: [
        {
          label: "关键字",
          value: "keyword",
          inputType: "text",
          placeholderText: "请输入用户姓名或电话号",
        },
        {
          label: "用户类型",
          value: "type",
          inputType: "select",
          placeholderText: "请选择用户类型",
          optionsList: [],
          optionsListConfig: {
            name: "label",
            code: "value",
          },
        },
        {
          label: "审核状态",
          value: "auditState",
          inputType: "select",
          placeholderText: "请选择审核状态",
          optionsList: [],
          optionsListConfig: {
            name: "label",
            code: "value",
          },
        },
      ],
      tableColumns: [
        {
          title: "姓名",
          key: "fullName",
          align: "center",
          width: "125",
        },
        {
          title: "电话",
          key: "phone",
          align: "center",
          width: "150",
        },
        {
          title: "邮箱",
          key: "email",
          align: "center",
        },
        {
          title: "所属单位",
          key: "orgName",
          align: "center",
          overflow: true,
          width: "220",
        },

        {
          title: "介绍人",
          key: "introducer",
          align: "center",
          width: "125",
        },
        {
          title: "用户类型",
          key: "type",
          align: "center",
          width: "120",
          formatter: (row) => {
            return this.getState("userType", row);
          },
        },
        {
          title: "审核状态",
          key: "auditState",
          align: "center",
          width: "120",
          formatter: (row) => {
            return this.getState("auditState", row);
          },
        },
        {
          title: "审核人",
          key: "auditUserName",
          align: "center",
          width: "125",
        },
        {
          title: "审核时间",
          key: "auditTime",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
            {
              show: "true",
              label: "审核通过",
              icon: "el-icon-refresh",
              method: (row) => {
                this.auditExternal(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.auditState == 0) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              show: "true",
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      dictType: [],
      userType: [],
      auditState: [],
      addPopupVisible: false,
      rowData: null,
      poptitleName: "新增",
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      //   用户类型
      getApi(`/admin/dict/key/user_lock_flag`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "lockFlag",
            optionsList: data.data,
          };
          this.dictType = data.data;
          this.setOPtionsList(this.formList, options);
        }
      });
      //   用户类型
      getApi(`/admin/dict/key/external_user_type`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.userType = data.data;
          let options = {
            value: "type",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      //  审核类型
      getApi(`/admin/dict/key/audit_state`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.auditState = data.data;
          let options = {
            value: "auditState",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      getApi(`/admin/external/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
    },
    //   密码重置
    handlePasswordRest(row) {
      this.$confirm(`确认将该账号密码重置为123456？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        let params = {
          id: row.userId,
        };
        postfromApi(`/admin/external/resetpwd`, params).then((res) => {
          let { data } = res;
          if (data && data.code == 0 && data.data) {
            this.$message.success("密码重置成功");
          }
        });
      });
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      getApi(`/admin/external/` + row.userId).then((res) => {
        this.addPopupVisible = true;
        this.rowData = res.data.data;
        this.poptitleName = "编辑";
      });
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.userId);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/admin/external/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    // 格式化类型
    getState(key, row) {
      let label = "";
      this[key].forEach((item) => {
        if (item.value == row) {
          label = item.label;
        }
      });
      return label;
    },
    getArrItem(key, row) {
      let arr = [];
      row.forEach((item) => {
        arr.push(item[key]);
      });
      return arr;
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (flag) {
        if (this.rowData != null) {
          params.userId = this.rowData.userId;
          putApi(`/admin/external`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.handleClose();
              this.getTableData();
            }
          });
        } else {
          //手动新增默认为已审核
          params.auditState = "1";
          postApi(`/admin/external/save`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
            }
          });
        }
      }
    },
    auditExternal(row) {
      getApi(`/admin/external/verify`, { userId: row.userId, state: "1" }).then(
        (res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("审核成功");
            this.getTableData();
          }
        }
      );
    },
  },
};
</script>

<style lang="less" scoped></style>
