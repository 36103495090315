<template>
  <div class="detail-container">
    <dd-card title="审批流程" spacer>
      <el-steps :active="activeIndex" align-center>
        <el-step
          v-for="(val, idx) in activityList"
          :key="idx"
          :title="val.activityName"
        >
          <div slot="description" class="description">
            <div
              v-for="(item, index) in val.description"
              :key="index"
              class="list"
            >
              <div class="flex_c">
                {{ item.time }}
                <div class="name">{{ item.userName }} 已审核</div>
              </div>
              <div>意见 {{ item.fullmessage }}</div>
            </div>
          </div>
        </el-step>
      </el-steps>
      <dd-form
        :form-config="formTaskBasicList"
        ref="taskBasicForm"
        formRef="taskBasicForm"
      >
      </dd-form>
    </dd-card>
    <dd-card title="基本信息">
      <dd-form
        :form-config="formBasicList"
        :flex="3"
        :formRef="formBasicRef"
        ref="expenseBasicForm"
      >
      </dd-form>
    </dd-card>
    <dd-card title="费用明细">
      <dd-form
        :form-config="formDetailList"
        :flex="3"
        :formRef="formDetailRef"
        ref="expenseDetailForm"
      ></dd-form>
      <el-link
        type="warning"
        :underline="false"
        icon="el-icon-info"
        style="margin-left: 50px"
        >该笔费用的具体用途(时间，地点、数量、规格、型号、具体名称、用途)</el-link
      >
    </dd-card>
    <dd-card title="附件查看">
      <div v-if="AttachFilesImage.length" class="ibox-content">
        <div>
          <el-image
            v-for="(item, index) in AttachFilesImage"
            :key="index"
            style="
              width: 100px;
              height: 90px;
              margin-right: 10px;
              nargin-bottom: 5px;
            "
            :src="item"
            :preview-src-list="AttachFilesImage"
          ></el-image>
        </div>
      </div>
      <div v-else class="ibox-content" style="height: 20px; overflow: auto">
        <div>暂无附件</div>
      </div>
    </dd-card>
  </div>
</template>

<script>
import { formatDate } from "@/utils/date";
import { getApi } from "@/api/request";
import { get } from "ol/proj";

export default {
  name: "detail-pop",
  props: {
    rowData: "",
    expenseType: [],
    billState: [],
  },
  data() {
    return {
      activeIndex: 0,
      formBasicRef: "expenseDetailBasicForm",
      formDetailRef: "expenseDetailDetailForm",
      //附件图片地址url
      AttachFilesImage: [],
      formTaskBasicList: [
        {
          label: "审批意见",
          value: "comment",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          placeholderText: "请输入审批意见",
          rules: [
            {
              required: true,
              message: "请输入审批意见",
              trigger: "blur",
            },
          ],
        },
      ],
      formBasicList: [
        {
          label: "单号",
          value: "code",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "任务书编号",
          value: "assignNum",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "项目编号",
          value: "prjCode",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "项目名称",
          value: "projectName",
          inputType: "text",
          type: "textarea",
          autosize: true,
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请人",
          value: "applier",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "部门",
          value: "deptName",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请日期",
          value: "applyTime",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "费用承担部门",
          value: "expenseBearDeptName",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "费用承担公司",
          value: "expenseBearOrg",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        // {
        //   label: "验证人",
        //   value: "verifierName",
        //   inputType: "text",
        //   type: "text",
        //   disabled: true,
        //   defaultValue: "",
        // },
      ],
      formDetailList: [
        {
          label: "费用类型",
          value: "expenseTypeId",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "id",
            label: "name",
          },
          disabled: true,
        },
        {
          label: "申请金额(元)",
          value: "amount",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "核定金额(元)",
          value: "reviewAmount",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "事由",
          value: "subjectMatter",
          inputType: "text",
          type: "textarea",
          autosize: true,
          disabled: true,
          defaultValue: "",
        },
      ],
      //审批流程表头
      tableColumns: [
        {
          title: "节点名称",
          key: "taskName",
          align: "center",
        },
        {
          title: "操作时间",
          key: "time",
          align: "center",
        },
        {
          title: "节点操作人",
          key: "userName",
          align: "center",
        },
        {
          title: "审批意见",
          key: "fullmessage",
          align: "center",
        },
      ],
      //审批流程数据
      tableDataSources: [],
      detailDataSourcrs: "",
      activityList: [],
    };
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //获取数据
    getDataList() {
      //获取费用类型（一、二、三级树）
      getApi(`/finance/expense/getThFinTypeAll`).then((res) => {
        let options = {
          value: "expenseTypeId",
          optionsList: res.data.data,
        };
        this.setOPtionsList(this.formDetailList, options);
      });
      getApi(`/activiti/process/viewPage/${this.rowData.proInstanceId}`).then(
        (res) => {
          if (res.data.code == 0) {
            this.activityList = res.data.data;
            this.activityList.forEach((item, index) => {
              if (item.isCurrentActiviti == 1) {
                this.activeIndex = index + 1;
              }
            });
          }
        }
      );
      getApi(`/finance/applysheet/detail/${this.rowData.proInstanceId}`).then(
        (res) => {
          if (res.data.code == 0 && res.data.data) {
            if (res.data.data) {
              res.data.data.amount = parseFloat(res.data.data.amount).toFixed(
                2
              );
              res.data.data.reviewAmount = parseFloat(
                res.data.data.reviewAmount
              ).toFixed(2);
            }
            let detailDataSourcrs = res.data.data;
            this.detailDataSourcrs = res.data.data;
            this.formBasicList.forEach((item) => {
              item.defaultValue = detailDataSourcrs[item.value];
            });
            this.formDetailList.forEach((item) => {
              if (
                this.rowData.taskDefinKey == "yzr" &&
                item.value == "reviewAmount"
              ) {
                item.disabled = false;
              }
              item.defaultValue = detailDataSourcrs[item.value];
            });
            let AttachFiles = detailDataSourcrs.finExpenseApplyAttachFiles;
            AttachFiles &&
              AttachFiles.forEach((item) => {
                let url = "/admin/sys-file/ssh/" + item.fileName;
                this.AttachFilesImage.push(url);
              });
            if (detailDataSourcrs.taskNodes) {
              this.activityList.forEach((item) => {
                item.description = [];
                detailDataSourcrs.taskNodes.forEach((val) => {
                  if (item.activityName == val.taskName) {
                    item.description.push(val);
                  }
                });
              });
            }
          } else {
            this.$message.error(res.data.msg);
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
/deep/ .el-step__description {
  display: flex;
  justify-content: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.description {
  .list {
    .name {
      margin-left: 10px;
    }
  }
}
</style>
