<template>
  <div class="main-container">
    <dd-form :form-config="formManageList" labelWidth="220px" ref="addPopForm">
    </dd-form>
  </div>
</template>

<script>
import { postApi, getApi, putApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowData: [],
  },
  data() {
    return {
      formManageList: [
        {
          label: "任务生产实施状态",
          value: "produceStatus",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择任务生产实施状态",
          optionsListConfig: {
            code: "code",
            name: "name",
          },
          defaultValue: "",
        },
      ],
      detailData: null,
    };
  },
  created() {},
  mounted() {
    if (this.rowData != null) {
      this.getDataDetail(this.rowData.id);
    }
    this.getDataType();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 生产实施状态
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJFZT" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "produceStatus",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formManageList, options);
          }
        }
      );
    },
    getDataDetail(id) {
      getApi(`/project/assign/${id}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.detailData = data.data;
          this.formManageList.forEach((item) => {
            item.defaultValue = data.data[item.value];
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}
/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
</style>
