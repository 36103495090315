import { render, staticRenderFns } from "./addTaskBook.vue?vue&type=template&id=bc22f412&scoped=true"
import script from "./addTaskBook.vue?vue&type=script&lang=js"
export * from "./addTaskBook.vue?vue&type=script&lang=js"
import style0 from "./addTaskBook.vue?vue&type=style&index=0&id=bc22f412&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc22f412",
  null
  
)

export default component.exports