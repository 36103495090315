<!--
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-08-14 17:41:10
 * @LastEditors: hechen
 * @LastEditTime: 2023-08-17 15:22:32
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-finance-ui\src\view\SystemManagement\Role\components\AddPop.vue
 * @Descripttion: 
-->
<template>
    <div class="main-container">
        <dd-form :form-config="formBasicList" ref="addPopForm">
        </dd-form>
    </div>
  </template>
  
  <script>
  export default {
    name: "add-pop",
    props: {
      dictType: [],
      rowData: []
    },
    data() {
      return {
        formBasicList: [
          {
            label: "角色名称",
            value: "roleName",
            inputType: "text",
            placeholderText: "请输入角色名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入角色名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "角色标识",
            value: "roleCode",
            inputType: "text",
            placeholderText: "请输入角色标识",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入角色标识",
                trigger: "blur",
              },
            ],
          },
          {
            label: "角色描述",
            value: "roleDesc",
            inputType: "text",
            placeholderText: "请输入角色描述",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入角色描述",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    created() {},
    mounted() {
        if(this.rowData != null) {
            this.formBasicList.forEach(item => {
                item.defaultValue = this.rowData[item.value]
            })
        }
    },
    methods: {
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
  