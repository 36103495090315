<template>
  <div class="container">
    <div style="width: 100%; margin: 20px 0">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        show-page
        :isSelection="false"
      >
      </dd-table>
    </div>
    <dd-popup
      width="30%"
      height="40%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="dialogVisible"
      title-name="编辑"
      inPopup
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <updatePop
        v-if="dialogVisible"
        slot="main"
        :rowId="rowId"
        :rowDataList="rowDataList"
        ref="addPop"
      ></updatePop>
    </dd-popup>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import { formatDateTime } from "@/utils/date";
import { mapState, mapActions } from "vuex";
import updatePop from "./components/updatePop.vue";
export default {
  props: {
    id: "",
    type: "",
    standardWorkpackage: null,
  },
  components: { updatePop },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      rowDataList: [],
      rowId: "",
      dialogVisible: false,
      //筛选列表格头部配置
      columnsList: [],
      // 表格数据集合
      tableDataSources: [],
    };
  },
  mounted() {
    this.getDetailInfo();
  },
  methods: {
    //页面信息回显
    getDetailInfo() {
      getApi(`/project/workloadsum/detail/${this.id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let columnsList = [];
          let rowDataList = [];
          data.data.title.map((item) => {
            if (item.key != "id") {
              columnsList.push({
                title: item.value,
                key: item.key,
                align: "center",
              });
            }
            if (item.workMetering) {
              rowDataList.push({
                key: item.value,
                value: item.key,
                workMetering: item.workMetering,
                options: item.options ? item.options : [],
              });
            }
          });
          if (this.type == "add") {
            columnsList.push({
              title: "操作",
              align: "center",
              width: "300px",
              event: [
                {
                  label: "编辑",
                  state: "default",
                  icon: "el-icon-edit",
                  type: "warning",
                  method: (row) => {
                    this.handleAddEdit(row);
                  },
                },
                {
                  label: "作废",
                  state: "default",
                  type: "danger",
                  icon: "el-icon-delete",
                  method: (row) => {
                    this.handleVoid(row);
                  },
                },
                {
                  label: "移出本次汇总",
                  icon: "el-icon-view",
                  state: "default",
                  method: (row) => {
                    this.handleDelete(row);
                  },
                },
              ],
            });
          }
          this.columnsList = columnsList;
          this.rowDataList = rowDataList;
          this.tableDataSources = data.data.dataList;
        }
      });
    },
    //编辑
    handleAddEdit(row) {
      this.rowId = row.id;
      console.log(this.rowDataList);
      this.rowDataList.forEach((item) => {
        if (item.options && item.options.length) {
          item.options.map((val) => {
            if (val.name == row[item.value]) {
              item.defaultValue = val.code;
            }
          });
        } else {
          item.defaultValue = row[item.value];
        }
      });

      this.dialogVisible = true;
    },
    //作废
    handleVoid(row) {
      this.$confirm("是否确定作废？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          getApi(`/project/real/workload/cancelbyid/${row.id}`)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "作废成功!",
                });
                this.getDetailInfo();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 移出
    handleDelete(row) {
      this.$confirm("是否确定移出本次汇总？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          postApi(`/project/workloadsum/remove/${this.id}/${row.id}`)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "移出成功!",
                });
                this.getDetailInfo();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 弹框关闭事件
    handleClose() {
      this.dialogVisible = false;
    },
    // 确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      params.id = this.rowId;
      if (flag) {
        if (this.standardWorkpackage == 1) {
          let list = [];
          Object.entries(params).forEach(([key, value]) => {
            if (key != "id") {
              list.push({
                id: this.rowId,
                key,
                value,
              });
            }
          });
          postApi(`/project/workloadsum/update`, list).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("编辑成功");
              this.handleClose();
              this.getDetailInfo();
            }
          });
        } else {
          postApi(`/project/workloadsum/wbs/update`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("编辑成功");
              this.handleClose();
              this.getDetailInfo();
            }
          });
        }
      }
    },
  },
};
</script>

<style lang='less' scoped>
.base-form-collect-info {
  position: relative;
  .add-workload-btn {
    position: absolute;
    right: 154px;
    bottom: 23px;
  }
}
/deep/ .el-table__header-wrapper {
  background-color: @tableHeaderBg !important;
}
/deep/ .el-table .has-gutter .el-table__cell {
  background-color: @tableHeaderBg;
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor !important;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
</style>