<template>
    <div class="main-container">
        <dd-card title="用户列表">
            <dd-table :columns="tableColumns" :data="tableData" max-height="530" isSort border :isSelection="false"
                :show-page="false" :loading="loading">
            </dd-table>
        </dd-card>
    </div>
</template>
    
<script>
import { getApi } from "@/api/request";
export default {
    props: {
        roleId: ''
    },
    computed: {
    },
    data() {
        return {
            loading: true,
            tableColumns: [
                {
                    title: "工号/账号",
                    key: "username",
                    align: "center",
                },
                {
                    title: "姓名",
                    key: "fullName",
                    align: "center",
                },
                {
                    title: "电话",
                    key: "phone",
                    align: "center",
                },
                {
                    title: "所属机构",
                    key: "orgName",
                    align: "center",
                },
                {
                    title: "所属部门",
                    key: "deptName",
                    align: "center",
                },
            ],
            tableData: [],
        };
    },
    created() { },
    mounted() {
        this.getTableData();
    },
    methods: {
        // 获取表格列表数据
        getTableData() {
            this.loading = true;
            getApi(`/admin/user/getuserlist/${this.roleId}`).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    this.loading = false;
                    this.tableData = data.data;
                }
            });
        },
    },
};
</script>
    
<style lang='less' scoped></style>