<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="工号汇总列表">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        height="400"
        border
        ref="dTableRef"
        tableRef="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        :isSelection="false"
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="pageSize"
            :page-number="pageNumber"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      v-if="dialogVisible"
      width="92%"
      height="90%"
      title-name="原始用车详情"
      :show="dialogVisible"
      :inPopup="true"
      @popup-close="dialogVisible = false"
    >
      <CarSummaryDetail
        slot="main"
        :id="id"
        :payeeName="payeeName"
      ></CarSummaryDetail>
    </dd-popup>
    <el-form :model="{}" ref="ruleForm" class="form_wrap">
      <div
        class="group-height group-center"
        style="position: fixed; bottom: 0; left: 40%"
      >
        <div v-if="type == 'sh'">
          <dd-button slot="button" size="medium" @click="cancel"
            >取消
          </dd-button>
          <dd-button
            slot="button"
            type="primary"
            size="medium"
            @click="submitOrSave(1)"
            >确认
          </dd-button>
          <dd-button
            slot="button"
            type="primary"
            size="medium"
            @click="submitOrSave(0)"
            >退回
          </dd-button>
        </div>
      </div>
    </el-form>
  </div>
</template>
  
<script>
import CarSummaryDetail from "../CarSummaryDetail";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
import { mapState, mapActions } from "vuex";
import qs from "qs";
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
  },
  components: { CarSummaryDetail },
  data() {
    return {
      drawerVisible: false,

      processInstanceId: null,
      loading: false,
      // id: null,
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      applyId: "",
      payeeId: "",
      payeeName: "",
      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "申请人",
          value: "applierName",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            name: "fullName",
            code: "fullName",
          },
          placeholderText: "请选择或搜索收款人",
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "费用承担部门",
          key: "expenseBearDepName",
          align: "center",
        },
        {
          title: "工号",
          key: "payeeCode",
          align: "center",
          overflow: true,
        },
        {
          title: "申请人",
          key: "payeeName",
          align: "center",
        },
        {
          title: "核定费用总计(元)",
          key: "approvedAmountTotal",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetailInfo(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {},
  mounted() {
    this.getPaymentData();
    this.sumId = this.id;
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  computed: {
    ...mapState(["btnAuthorityList"]), //权限标识数组
  },
  methods: {
    //获取收款人数据
    getPaymentData() {
      // postApi(`/admin/user/getuserlist/bydeptid`, {
      //   deptId: sessionStorage.getItem("depId"),
      // }).then((res) => {
      //   let { data } = res;
      //   if (data.code == 0) {
      //     this.formList.forEach((item) => {
      //       if (item.value == "applierName") {
      //         item.optionsList = data.data;
      //       }
      //     });
      //   }
      // });
      postApi(`/admin/user/getver/list`, {}).then((res) => {
        let { data } = res;
        console.log('res', res);
        this.formList.forEach((item) => {
          if (item.value == "applierName") {
            item.optionsList = data.data;
          }
        });
      });
      // postApi(`/middle/user/userByDepId`, {
      //   depId: sessionStorage.getItem("depId"),
      // }).then((res) => {
      //   let { data } = res;
      //   if (data.code == 0) {
      //     this.formList.forEach((item) => {
      //       item.optionsList = data.data;
      //     });
      //   }
      // });
    },
    diaChange(e) {
      this.dialogAddEditVisible = e;
      this.dialogApplyBasicInfoVisible = e;
      this.dialogDetailVisible = e;
      console.log(e);
      this.id = null;
      this.state = null;
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.getApplyList();
    },
    // 列表请求
    getApplyList() {
      this.loading = true;
      let _collectId = this.id;
      let _applierName = this.formData.applierName || "";
      getApi(
        `/hr/rentalcar/collectsheet/statistics/person/page?current=` +
          this.pageNumber +
          `&size=` +
          this.pageSize +
          `&payeeName=` +
          _applierName +
          `&collectId=` +
          _collectId
      ).then((res) => {
        let { data } = res;
        console.log(data);
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.approvedAmountTotal = formatNumber(item.approvedAmountTotal);
          });
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    /* 导出 */
    handleExport() {
      let ids = [];
      this.multipleSelection.length &&
        this.multipleSelection.map((item) => {
          ids.push(item.id);
        });

      let params = {
        formData: {
          current: this.pageNumber,
          size: this.pageSize,
          payeeId: this.formData.payeeId || "",
          collectId: this.id,
          ids: this.multipleSelection.length ? ids.join() : "",
        },
      };
      console.log("params", params);
      loadApi(
        `/hr/rentalcar/collectsheet/export/statistics/person`,
        params
      ).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    handleDetailInfo(row) {
      this.applyId = row.id;
      this.payeeId = row.payeeId;
      this.payeeName = row.payeeName;
      this.dialogVisible = true;
    },
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      this.pageNumber = 1;
      this.getApplyList();
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "approvedKilometerTotal" ||
          column.property == "approvedAmountTotal"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          console.log(values);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              console.log(value);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
            if (column.property == "approvedAmountTotal") {
              sums[index] = formatNumber(sums[index]);
            }
          }
        }
      });
      return sums;
    },
    //弹框取消回调事件
    cancel() {
      this.$emit("diaChange", false);
    },
    //提交1退回0
    submitOrSave(type) {
      getApi(
        `/hr/rentalcar/collectsheet/review?id=` +
          this.sumId +
          `&reviewResult=` +
          type
      )
        .then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success(`${type == 0 ? "退回成功！" : "提交成功！"}`);
            this.$emit("diaChange", false);
          } else {
            this.$message.error(data.msg);
            this.$message({
              message: data.msg,
              type: "error",
              duration: 6000,
            });
          }
        })
        .catch((data) => {
          if (data.response && data.response.data && data.response.data.msg) {
            this.$message({
              message: data.response.data.msg,
              type: "error",
              duration: 6000,
            });
          } else {
            this.$message({
              message: data,
              type: "error",
              duration: 6000,
            });
          }
        });
    },
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.getApplyList();
    },
  },
};
</script>
  
<style lang='less' scoped>
</style>

<style>
.el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>