<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowData: {},
  },
  data() {
    return {
      formDataObj: {},
      formBasicList: [
        {
          label: "生产分管部门",
          value: "receiveDeptId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          defaultValue:'',
          placeholderText: "请选择生产分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
          rules: [
            {
              required: true,
              message: "请选择生产分管部门",
              trigger: "blur",
            },
          ],
          change: this.getPersonList,
        },
        {
          label: "部门",
          value: "receiveSubDeptId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          defaultValue:'',
          placeholderText: "请选择部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          rules: [
            {
              required: true,
              message: "请选择部门",
              trigger: "blur",
            },
          ],
          change: this.getAccountingList,
        },
        {
          label: "责任人",
          value: "leaderId",
          inputType: "select",
          optionsList: [],
          defaultValue:'',
          placeholderText: "请选择责任人",
          optionsListConfig: {
            name: "fullName",
            code: "userId",
          },
          rules: [
            {
              required: true,
              message: "请选择责任人",
              trigger: "blur",
            },
          ],
          change: this.leaderChange,
        },
        {
          label: "核算组",
          value: "areaAccountingId",
          inputType: "select",
          optionsList: [],
          defaultValue:'',
          placeholderText: "请选择核算组",
          optionsListConfig: {
            name: "accounting",
            code: "accountingId",
          },
          rules: [
            {
              required: true,
              message: "请选择核算组",
              trigger: "blur",
            },
          ],
          change: this.accountChange,
        },
      ],
      depList: [],
      leaderList: [],
      childDepList: [],
      accList: [],
    };
  },
  created() {
    this.getDataType();
  },
  mounted() {},
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取分管部门
    getDataType() {
      getApi(`/admin/sysfendept/getList`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "receiveDeptId",
            optionsList: data.data
          };
          this.depList = data.data;
          this.setOPtionsList(this.formBasicList, options);
          
          if (this.rowData != null) {
            this.formDataObj = this.rowData;
            if (this.rowData.receiveDeptId) {
              this.getPersonListGQ(this.rowData.receiveDeptId);
            }
            if (this.rowData.receiveSubDeptId) {
              this.getAccountingListGQ(this.rowData.receiveSubDeptId);
            }
            this.formBasicList.forEach((item) => {
              item.defaultValue = this.rowData[item.value];
            });
          }
        }
      });
    },
    // 获取部门
    getPersonListGQ(deptId){
      if(deptId != ''){
        getApi(`/admin/dept/getbydepid/${deptId}`, {}).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.childDepList = data.data;
            let options = {
              value: "receiveSubDeptId",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        })
      }
    },
    // 获取责任人 与 核算组
    getAccountingListGQ(id) {
      if(id != ''){
        // 责任人
        postApi(`/admin/user/getuserlist/bydeptid`, { deptId: id }).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.leaderList = data.data;
            let options = {
              value: "leaderId",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        });
        // 部门查询核算组
        postApi(`/project/accounting/branch/getBydepId`, {
          deptId: id,
        }).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.accList = [data.data];
            let options = {
              value: "areaAccountingId",
              optionsList: [data.data],
            };
            this.setOPtionsList(this.formBasicList, options);
          }
        });
      }
    },
    // 获取部门
    getPersonList(deptId) {
      if (this.formDataObj.receiveDeptId != deptId) {
        this.formDataObj = {};
      }
      this.depList.forEach((item) => {
        if (item.id == deptId) {
          this.formDataObj.receiveDeptId = deptId;
          this.formDataObj.receiveDeptName = item.name;
        }
      });
      this.formBasicList.forEach((item) => {
        if( item.value == "receiveSubDeptId" || item.value == "leaderId" || item.value == "areaAccountingId"){
          item.defaultValue = ''
        }
        else{
          item.defaultValue = this.$refs.addPopForm.formData[item.value];
        }
      })
      if(deptId != ''){
        getApi(`/admin/dept/getbydepid/${deptId}`, {}).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.childDepList = data.data;
            this.formBasicList.forEach((item) => {
              if (item.value == "downFenDeptId") {
                item.defaultValue = deptId;
              }
              else if(item.value == "receiveSubDeptId" || item.value == "leaderId" || item.value == "areaAccountingId"){
                item.defaultValue = ''
              }
              else{
                item.defaultValue = this.$refs.addPopForm.formData[item.value];
              }
              if(item.value == 'receiveSubDeptId'){
                item.optionsList = data.data;
              }
            })
          }
        })
      }
    },
    // 获取责任人 与 核算组
    getAccountingList(id) {
      if (this.formDataObj.receiveSubDeptId != id) {
        this.formDataObj.areaAccountingId = null;
        this.formDataObj.accounting = null;
      }
      this.childDepList.forEach((item) => {
        if (item.deptId == id) {
          this.formDataObj.receiveSubDeptId = id;
          this.formDataObj.childDepName = item.name;
        }
      });
      this.formBasicList.forEach((item) => {
        if(item.value == "leaderId" || item.value == "areaAccountingId"){
          item.defaultValue = ''
        }
        else{
          item.defaultValue = this.$refs.addPopForm.formData[item.value];
        }
      })
      if(id != ''){
        postApi(`/admin/user/getuserlist/bydeptid`, { deptId: id }).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.leaderList = data.data;
            this.formBasicList.forEach((item) => {
              if (item.value == "receiveSubDeptId") {
                item.defaultValue = id;
              }
              else if(item.value == "leaderId" || item.value == "areaAccountingId"){
                item.defaultValue = ''
              }
              else {
                item.defaultValue = this.formDataObj[item.value];
              }
              if (item.value == "leaderId") {
                item.optionsList = data.data;
              }
            });
          }
        });

        // 部门查询核算组
        postApi(`/project/accounting/branch/getBydepId`, {
          deptId: id,
        }).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            if(data.data != null){
              this.formDataObj.areaAccountingId = data.data.accountingId;
              this.formDataObj.accounting = data.data.accounting;
              this.accList = [data.data];
                this.formBasicList.forEach((item) => {
                  if (item.value == "areaAccountingId") {
                    item.optionsList = [data.data];
                    setTimeout(()=>{
                      item.defaultValue = data.data.accountingId
                    }, 300)
                  }
                });
              }else{
                this.accList = null
            }
          }
        });
      }
    },
    // 责任人
    leaderChange(leaderId) {
      this.leaderList.forEach((item) => {
        if (item.userId == leaderId) {
          this.formDataObj.leaderId = leaderId;
          this.formDataObj.fullName = item.fullName;
        }
      });
      this.formBasicList.forEach((item) => {
        if (item.value == "leaderId") {
          item.defaultValue = leaderId
        }
      });
    },
    // 核算组
    accountChange(id) {
      if(this.accList != null){
        this.accList.forEach((item) => {
          if (item.accountingId == id) {
            this.formDataObj.areaAccountingId = id;
            this.formDataObj.accounting = item.accounting;
          }
        });
        this.formBasicList.forEach((item) => {
          if (item.value == "areaAccountingId") {
            item.defaultValue = id
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped></style>
