<template>
  <div class="container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="记账列表">
      <dd-table
        ref="expenseApplyTable"
        tableRef="expenseApplyTable"
        :columns="columnsList"
        :data="tableDataSources"
        height="450"
        border
        show-page
        :isSelection="false"
        show-summary
        :summary-method="getSummaries"
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import qs from "qs";

export default {
  props: ["row"],
  computed: {},
  data() {
    return {
      activeName: "first",
      tableData: [],
      formData: {},
      proTableData: [],
      manTableData: [],
      saleTableData: [],
      devTableData: [],
      expandRowKeys: [],
      //判断当前用户角色，是否为当前项目经理角色
      isCurPrjCmmManager: false,
      // 当前页码
      current: 1,
      // 总条数
      total: 0,
      // 每页的数据条数
      size: 10,
      tableDataSources: [],
      formList: [
        {
          label: "单号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入单号",
        },
        {
          label: "申请人",
          value: "applier",
          inputType: "text",
          placeholderText: "请输入申请人姓名",
        },
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          placeholderText: "请选择或搜索申请人部门",
        },
        {
          label: "单据状态",
          value: "state",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索单据状态",
        },
        {
          label: "记账日期",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
        },
        {},
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "单号",
          key: "code",
          align: "center",
          overflow: true,
          width: 180,
        },
        {
          title: "业务类别",
          key: "firstExpense",
          align: "center",
          overflow: true,
        },
        {
          title: "二级费用类型",
          key: "twoLevelType",
          align: "center",
          overflow: true,
        },
        {
          title: "费用类型",
          key: "extenseTypeName",
          align: "center",
          overflow: true,
        },
        {
          title: "费用类型编码",
          key: "extenseTypeCode",
          align: "center",
          overflow: true,
        },
        {
          title: "金额(元)",
          key: "amount",
          align: "center",
        },
        {
          title: "核定金额(元)",
          key: "reviewAmount",
          align: "center",
        },
        {
          title: "单据状态",
          key: "stateName",
          align: "center",
        },
        {
          title: "申请人",
          key: "applier",
          align: "center",
        },
        {
          title: "部门",
          key: "applyDep",
          align: "center",
          overflow: true,
        },
        {
          title: "事由",
          key: "subjectMatter",
          align: "center",
          overflow: true,
        },
        {
          title: "附件",
          key: "id",
          align: "center",
          image: true,
          AttachFiles: "finExpenseApplyAttachFiles",
        },
      ],
      columnsList: [],
    };
  },
  created() {},
  mounted() {
    this.columnsList = [...this.tableColumns];
    this.getConditionData();
    this.getDataList({
      projectId: this.projectId,
      expenseTypeCode: this.expenseTypeCode,
    });
  },
  methods: {
    //条件数据查询
    getConditionData() {
      //获取单据状态
      postApi(`/finance/dict/list`, qs.stringify({ typeCode: "xmfysqdzt" }))
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.data) {
              res.data.data = res.data.data.filter((item) => {
                return (
                  item.code === "shz-1" ||
                  item.code === "shtg-1" ||
                  item.code === "yscbzd" ||
                  item.code === "ywcbz"
                );
              });
            }

            this.options = {
              value: "state",
              optionsList: res.data.data,
            };
            this.setOPtionsList(this.formList, this.options);
          }
        })
        .catch(() => {
          this.$message.error("请求出错");
        });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let options = {
          value: "deptId",
          optionsList: res.data.data,
        };
        this.setOPtionsList(this.formList, options);
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleSearchEvent(obj) {
      this.current = 1;
      this.size = 10;
      this.formData = obj;
      this.getDataList(obj);
    },
    cancel() {
      this.$emit("diaChange", false);
    },
    getDataList() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        assignId: this.row.assignId,
        current,
        size,
        // curOrgId: sessionStorage.getItem("orgId"),
        expenseTypeCode: this.row.code ? this.row.code : '',
      };

      postApi(`/finance/expenseApply/audit/accounted`, params).then((res) => {
        let { data } = res.data;
        this.total = data.total;

        if (data.records) {
          data.records.forEach((item) => {
            item.amount = parseFloat(item.amount).toFixed(2);
            item.reviewAmount = parseFloat(item.reviewAmount).toFixed(2);

            item.finExpenseApplyAttachFiles &&
              item.finExpenseApplyAttachFiles.length &&
              item.finExpenseApplyAttachFiles.forEach((itm) => {
                itm.url = `/admin/sys-file/ssh/${itm.fileName}`;
              });
            item.AttachFiles = item.finExpenseApplyAttachFiles;
          });
          this.tableDataSources = data.records;
        } else {
          this.tableDataSources = [];
        }
      });
    },
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getDataList();
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [param];

      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (index === 5 || index === 6) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          } else {
            sums[index] = " ";
          }
        } else {
          sums[index] = " ";
          return;
        }
      });

      sums[5] = parseFloat(sums[5]).toFixed(2);
      sums[6] = parseFloat(sums[6]).toFixed(2);

      return sums;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-form-item .search-form-input {
  width: 33%;
}
</style>
