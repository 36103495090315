<!--
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-08-31 17:56:39
 * @LastEditors: hechen
 * @LastEditTime: 2023-09-01 09:49:54
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\views\Project\ProjectDict\components\AddCatalogPop.vue
 * @Descripttion: 
-->
<template>
    <div class="main-container">
      <dd-form :form-config="formBasicList" ref="addPopForm">
      </dd-form>
    </div>
  </template>
    
  <script>
  export default {
    name: "add-pop",
    props: {
      rowData: [],
      dictType: null
    },
    data() {
      return {
        formBasicList: [
          {
            label: "字典类型",
            value: "dictType",
            inputType: "text",
            placeholderText: "请输入字典类型",
            disabled: true,
            defaultValue: this.dictType,
          },
          {
            label: "字典名称",
            value: "name",
            inputType: "text",
            placeholderText: "请输入字典名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入字典名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "字典编码",
            value: "code",
            inputType: "text",
            placeholderText: "请输入字典编码",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入字典编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "字典描述",
            value: "description",
            inputType: "text",
            placeholderText: "请输入字典描述",
            disabled: false,
            defaultValue: '',
          },
          {
            label: "排序",
            value: "sortOrder",
            inputType: "number",
            min: 0,
            max: 999,
            placeholderText: "请输入排序",
            disabled: false,
            defaultValue: '',
          },
        ],
      };
    },
    created() { },
    mounted() {
      if (this.rowData != null) {
        this.formBasicList.forEach(item => {
            if(item.value != "dictType") {
                item.defaultValue = this.rowData[item.value]
            }
        })
      }
    },
    methods: {
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
    