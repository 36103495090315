<template>
  <div class="detail-container">
    <dd-card title="审批流程" spacer>
      <el-steps :active="activeIndex" align-center>
        <el-step
          v-for="(val, idx) in activityList"
          :key="idx"
          :title="val.activityName"
        >
          <div slot="description" class="description">
            <div
              v-for="(item, index) in val.description"
              :key="index"
              class="list"
            >
              <div class="flex_c">
                {{ item.time }}
                <div class="name">{{ item.userName }} 已审核</div>
              </div>
              <div>意见 {{ item.fullmessage }}</div>
            </div>
          </div>
        </el-step>
      </el-steps>
    </dd-card>
    <dd-card title="基础信息">
      <dd-form
        :form-config="formBasicList"
        :flex="3"
        labelWidth="200px"
        ref="addPopForm"
      >
      </dd-form>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
export default {
  props: {
    rowId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      activeIndex: 0,
      activityList: [],
      formBasicList: [
        {
          label: "节点名称",
          value: "nodeName",
          inputType: "text",
        },
        {
          label: "文档名称",
          value: "documentName",
          inputType: "text",
        },
        {
          label: "文档分类",
          value: "documentTypeName",
          inputType: "text",
        },
        {
          label: "版本号",
          value: "version",
          inputType: "text",
          defaultValue: "",
        },
      ],
      detailData: null,
    };
  },
  created() {},
  mounted() {
    this.getDataDetail(this.rowId);
  },
  methods: {
    getDataDetail(id) {
      getApi(`/activiti/process/viewPage/${id}`).then((res) => {
        if (res.data.code == 0) {
          this.activityList = res.data.data;
        }
      });
      getApi(`/project/subplannode/attachfile/get/processId/${id}`).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.detailData = data.data;
            this.formBasicList.forEach((item) => {
              item.disabled = true;
              item.defaultValue = data.data[item.value];
            });
            if (this.detailData.taskNodes) {
              this.activityList.forEach((item, index) => {
                item.description = [];
                if (item.isCurrentActiviti == 1 ||  item.isHistoricActiviti == 1) {
                  this.activeIndex = index + 1;
                }
                this.detailData.taskNodes.forEach((val) => {
                  if (item.activityName == val.taskName) {
                    item.description.push(val);
                  }
                });
              });
            }
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-range-editor.is-disabled input {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
/deep/ .el-step__description {
  display: flex;
  justify-content: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.description {
  .list {
    .name {
      margin-left: 10px;
    }
  }
}
/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}
/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
</style>
