<!--
 * @version: V1.0.0
 * @Author: hechen
 * @Date: 2023-10-10 14:56:32
 * @LastEditors: hechen
 * @LastEditTime: 2023-10-10 15:03:27
 * @company: 大地测绘
 * @Mailbox: 15291492336@163.com
 * @FilePath: \ssh-eom-web\src\views\Activiti\ModelManagement\components\AddPop.vue
 * @Descripttion: 
-->
<template>
    <div class="main-container">
        <dd-form v-show="formVisible" :form-config="formBasicList" ref="addPopForm">
        </dd-form>
    </div>
  </template>
  
  <script>
  import { getApi, delApi, postApi, putApi, postfromApi } from "@/api/request";
  export default {
    name: "add-pop",
    props: {
      rowData: [],
      dictType: []
    },
    data() {
      return {
        formBasicList: [
          {
            label: "模型标识",
            value: "key",
            inputType: "text",
            placeholderText: "请输入模型标识",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入模型标识",
                trigger: "blur",
              },
            ],
          },
          {
            label: "流程分类",
            value: "category",
            inputType: "cascader",
            optionsList: [],
            prop: {
              value: "id",
              label: "name",
              checkStrictly: true,
              emitPath: false
            },
            showAllSelcet: false,
            placeholderText: "请选择流程分类",
            rules: [
              {
                required: true,
                message: "请选择流程分类",
                trigger: "blur",
              },
            ]
          },
          {
            label: "模型名称",
            value: "name",
            inputType: "text",
            placeholderText: "请输入模型名称",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入模型名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "描述",
            value: "desc",
            inputType: "text",
            placeholderText: "请输入描述",
            disabled: false,
            defaultValue: '',
            rules: [
              {
                required: true,
                message: "请输入描述",
                trigger: "blur",
              },
            ],
          }
        ],
        formVisible: true
      };
    },
    created() {
    },
    mounted() {
      if(this.dictType) {
          this.formBasicList.forEach(item => {
            if(item.value == 'category') {
              item.optionsList = this.dictType
            }
          })
        }
      if(this.rowData != null) {
            this.formBasicList.forEach(item => {
              if(this.poptitleName == "新增") {
                if(item.value == "category") {
                  item.defaultValue = this.rowData.id
                }
              }else {
                item.defaultValue = this.rowData[item.value]
              }
            })
        }
    },
    methods: {
      //条件查询表单--下拉选择数据填充（optionsList）
      setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.radioGroup = options.optionsList;
                    }
                }
            });
        },
    },
  };
  </script>
  <style lang='less' scoped>
  </style>
  