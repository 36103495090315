<template>
  <div class="main-container">
    <dd-card title="基本信息">
      <dd-form
        :form-config="formBasicList"
        :flex="3"
        ref="expenseDetailBasicForm"
        :formRef="formBasicRef"
      >
      </dd-form>
    </dd-card>
    <dd-card title="费用明细">
      <dd-form
        :form-config="formDetailList"
        :flex="3"
        ref="expenseDetailDetailForm"
        :formRef="formDetailRef"
      ></dd-form>
      <el-link
        type="warning"
        :underline="false"
        icon="el-icon-info"
        style="margin-left: 50px"
        >该笔费用的具体用途(时间，地点、数量、规格、型号、具体名称、用途)</el-link
      >
    </dd-card>
    <dd-card title="附件查看">
      <div v-if="AttachFilesImage.length" class="ibox-content">
        <div>
          <el-image
            v-for="(item, index) in AttachFilesImage"
            :key="index"
            style="
              width: 100px;
              height: 90px;
              margin-right: 10px;
              nargin-bottom: 5px;
            "
            :src="item"
            :preview-src-list="AttachFilesImage"
          ></el-image>
        </div>
      </div>
      <div v-else class="ibox-content" style="height: 20px; overflow: auto">
        <div>暂无附件</div>
      </div>
    </dd-card>
    <dd-card title="审批流程">
      <dd-table
        ref="expenseDetail"
        height="300"
        :topBtn="false"
        :isSelection="false"
        :isSort="true"
        :columns="tableColumns"
        :data="tableDataSources"
        border
      ></dd-table>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import qs from "qs"

export default {
  name: "detail-pop",
  props: {
    expenseApply: {},
  },
  data() {
    return {
      formBasicRef: "expenseDetailBasicForm",
      formDetailRef: "expenseDetailDetailForm",
      //附件图片地址url
      AttachFilesImage: [],
      formBasicList: [
        {
          label: "单号",
          value: "code",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "项目名称",
          value: "projectName",
          inputType: "text",
          type: "textarea",
          autosize: true,
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请人",
          value: "applier",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "部门",
          value: "deptName",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "申请日期",
          value: "applyTime",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "费用承担部门",
          value: "expenseBearDeptName",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "费用承担公司",
          value: "expenseBearOrg",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "节点",
          value: "nodeName",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "单据状态",
          value: "state",
          inputType: "select",
          disabled: true,
          defaultValue: "",
          optionsList: [],
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
      ],
      formDetailList: [
        {
          label: "费用类型",
          value: "expenseTypeCode",
          inputType: "cascader",
          optionsList: [],
          prop: {
            value: "code",
            label: "name",
          },
          disabled: true,
        },
        {
          label: "申请金额(元)",
          value: "amount",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "核定金额(元)",
          value: "reviewAmount",
          inputType: "text",
          type: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "事由",
          value: "subjectMatter",
          inputType: "text",
          type: "textarea",
          autosize: true,
          disabled: true,
          defaultValue: "",
        },
      ],
      //审批流程表头
      tableColumns: [
        {
          title: "节点名称",
          key: "taskName",
          align: "center",
        },
        {
          title: "操作时间",
          key: "time",
          align: "center",
        },
        {
          title: "节点操作人",
          key: "userName",
          align: "center",
        },
        {
          title: "审批意见",
          key: "fullmessage",
          align: "center",
        },
      ],
      //审批流程数据
      tableDataSources: [],
    };
  },
  mounted() {
    this.getConditionData();
    this.getDataList();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取字典数据
    getConditionData() {
      //获取费用类型（一、二、三级树）
      getApi(`/finance/expense/getThFinTypeAll`)
      .then((res) => {
        let options = {
          value: "expenseTypeCode",
          optionsList: res.data.data,
        };
        this.setOPtionsList(this.formDetailList, options);
      })
      .catch(() => {
        this.$message.error("请求出错");
      });
      //获取单据状态
      postApi(`/finance/dict/list`, qs.stringify({ typeCode: "xmfysqdzt" }))
      .then((res) => {
        if (res.data.code == 0) {
          let options = {
            value: "state",
            optionsList: res.data.data,
          };
          this.setOPtionsList(this.formBasicList, options);
        }
      })
    },
    //获取数据
    getDataList() {
      //编辑时，通过id获取数据
      let params = {
        id: this.expenseApply.id,
        type: "pc",
      };
      postApi(`/finance/applysheet/detail`, params).then((res) => {
        if (res.data.code == 0 && res.data.data) {
          if (res.data.data) {
            res.data.data.amount = parseFloat(res.data.data.amount).toFixed(2);
            res.data.data.reviewAmount = parseFloat(
              res.data.data.reviewAmount
            ).toFixed(2);
          }
          let detailDataSourcrs = res.data.data;
          console.log(detailDataSourcrs);
          this.formBasicList.forEach((item) => {
            item.defaultValue = detailDataSourcrs[item.value];
          });
          this.formDetailList.forEach((item) => {
            item.defaultValue = detailDataSourcrs[item.value];
          });
          let AttachFiles = detailDataSourcrs.finExpenseApplyAttachFiles;
          AttachFiles &&
            AttachFiles.forEach((item) => {
              let url = "/admin/sys-file/ssh/" + item.fileName;
              this.AttachFilesImage.push(url);
            });
          if(detailDataSourcrs.taskNodes) {
            this.tableDataSources = detailDataSourcrs.taskNodes;
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor !important;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
</style>
