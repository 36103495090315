export default {
    // 更新基础弹窗显隐状态
    basePopupShowFn(state, item) {
        state.basePopupShow = item;
    },
    // 更新按钮权限数据
    btnAuthorityFn(state, item) {
        state.btnAuthorityList = item;
    },
    getOrgList(state, item) {
        state.orgList = item;
    }

}