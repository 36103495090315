<template>
    <div class="main-container">
        <div class="head_wrap">
            <dd-card title="条件搜索" spacer>
                <dd-search-form :form-config="formList" @search-click="handleSearchEvent"
                    ref="ddSearchForm"></dd-search-form>
            </dd-card>
        </div>
        <dd-card title="合同列表" :height="tableHeight">
            <dd-table :columns="columnsList" :data="tableData" border :isSelection="true" show-page :loading="loading">
                <template #table-btn-right>
                    <el-button icon="el-icon-s-operation" circle type="info" size="small" @click="drawerVisible = true"
                        title="列筛选"></el-button>
                </template>
                <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handlePageChange" align="right">
                    </dd-pagination>
                </template>
            </dd-table>
        </dd-card>
        <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
            :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
            @handleColumnsChange="handleColumnsChange">
        </dd-filter-drawer>

        <dd-popup v-if="pageDetailVisible" width="78%" height="80%" showBtn :saveBtn="saveBtn" :submitBtn="false"
            :show="pageDetailVisible" title-name="详情"
            @popup-close="handleClose">
            <pageDetail ref="pageDetail" :pageProp="pageProp" slot="main"></pageDetail>
        </dd-popup>
    </div>
</template>



<script>
import { postApi } from '@/api/request';
import pageDetail from './components/pageDetail.vue'
export default {
    name: "procurementContract",
    $dynamicRoute: {
        name: "金蝶采购合同",
        path: "/contract/procurementContract/index",
    },
    components: { pageDetail },
    data() {
        return {
            formList: [
                {
                    label: "合同编码",
                    value: "fnumber",
                    inputType: "text",
                    placeholderText:
                        "请输入合同编码进行搜索",
                },
                {
                    label: "合同名称",
                    value: "fname",
                    inputType: "text",
                    placeholderText:
                        "请输入合同名称进行搜索",
                },
                {
                    label: "工程项目",
                    value: "fprojectName",
                    inputType: "text",
                    placeholderText:
                        "请输入工程项目进行搜索",
                },
                {
                    label: "合同状态",
                    value: "fcontractstatusName",
                    inputType: "text",
                    placeholderText:
                        "请输入合同状态进行搜索",
                },{
                    label: "合同类型",
                    value: "fcontracttypeName",
                    inputType: "text",
                    placeholderText:
                        "请输入合同类型进行搜索",
                },{
                    label: "合同性质",
                    value: "fcontractkindName",
                    inputType: "text",
                    placeholderText:
                        "请输入合同性质进行搜索",
                },{
                    label: "甲方",
                    value: "fpartaName",
                    inputType: "text",
                    placeholderText:
                        "请输入甲方进行搜索",
                },{
                    label: "乙方",
                    value: "fpartbName",
                    inputType: "text",
                    placeholderText:
                        "请输入乙方进行搜索",
                },{
                    label: "合同属性",
                    value: "cfproperty",
                    inputType: "text",
                    placeholderText:
                        "请输入合同属性进行搜索",
                },{
                    label: "采购子类型",
                    value: "cfproperty1",
                    inputType: "text",
                    placeholderText:
                        "请输入项目名称进行搜索",
                },
                {
                    label: "关联类型",
                    value: "correlationTypeName",
                    inputType: "text",
                    placeholderText:
                        "请输入项目名称进行搜索",
                },
            ],
            pageDetailVisible: false,
            saveBtn: false,
            loading: false,
            pageProp: {},
            drawerVisible: false,
            tableHeight: null,
            tableColumns: [
            {
                    title: "合同编码",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                    width: 180,
                },
                {
                    title: "合同名称",
                    key: "fname",
                    align: "center",
                    overflow: true,
                    width: 180,
                },
                {
                    title: "工程项目",
                    key: "fprojectName",
                    align: "center",
                    overflow: true,
                    width: 180,
                },
                {
                    title: "合同状态",
                    key: "fcontractstatusName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同类型",
                    key: "fcontracttypeName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同属性",
                    key: "fcontractkindName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同总金额",
                    key: "foriginalamount",
                    align: "center",
                    overflow: true,
                    width: 110
                },
                {
                    title: "累计变更金额",
                    key: "bgAmount",
                    align: "center",
                    overflow: true,
                    width: 110
                },
                {
                    title: "变更后总金额",
                    key: "ftotalamount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "累计结算金额",
                    key: "settleFoftax",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "未结算金额",
                    key: "wjsAmount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "累计实付金额",
                    key: "paymentForiginalamount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "未付金额",
                    key: "wfAmount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "累计开票金额",
                    key: "invoiceAllotmentAmount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "已开票未付款金额",
                    key: "ykwfkAmount",
                    align: "center",
                    overflow: true,
                    width: 160
                },
                {
                    title: "开始日期",
                    key: "fbegindate",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "结束日期",
                    key: "ffinishdate",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "经办日期",
                    key: "fbizdate",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "甲方",
                    key: "fpartaName",
                    align: "center",
                    overflow: true,
                    width: 180,
                },
                {
                    title: "乙方",
                    key: "fpartbName",
                    align: "center",
                    overflow: true,
                    width: 180,
                },
                {
                    title: "合同属性",
                    key: "cfproperty",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "采购子类型",
                    key: "cfproperty1",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "关联类型",
                    key: "correlationTypeName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: 'right',
                    overflow: true,
                    width: 100,
                    event: [
                        {
                            state: "default",
                            label: "查看详情",
                            width: 100,
                            method: (row) => {
                                this.handleDetail(row);
                            },
                        },
                    ],
                },
            ],
            columnsList: [],
            // 当前页码
            current: 1,
            // 总条数
            total: 0,
            // 每页的数据条数
            size: 10,
            tableData: [],
            searchData: {},
        }
    },
    created() {
        this.columnsList = [...this.tableColumns];
        this.getTableList();
    },
    mounted() {
        this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
        });
    },
    methods: {
        handleDetail(row) {
            this.pageProp.pageType = 'detail';
            this.pageProp.id = row.id;
            this.pageDetailVisible = true;
        },
        getTableList() {
            postApi(`/contract/contractpurchaseinfo/page/${this.current}/${this.size}`, {...this.searchData}).then(res => {
                if(res.data.code == 0) {
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total;
                }
            })
        },
        handleColumnsChange(val) {
            let list = new Set();
            this.tableColumns.map((item) => {
                val.map((itm) => {
                    if (item.event || item.key == itm.key) {
                        list.add(item);
                    }
                });
            });
            this.columnsList = [...list];
        },
        handleSearchEvent(e) {
            this.searchData = e;
            this.getTableList();
        },
        handleClose() {
            this.pageDetailVisible = false;
        },
        handlePageChange(pageSize, pageNumber) {
            this.size = pageSize;
            this.current = pageNumber;
            this.getTableList()
        },
    }
}
</script>

<style lang="less" scoped>
 /deep/ .container  .tabs_card111  .el-tabs__item {
      width: 17% !important;
    }
</style>