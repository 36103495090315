<template>
  <div class="container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
    <dd-card title="用车汇总列表">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        height="380"
        border
        ref="dTableRef"
        tableRef="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        @select-change="handleSelectionChange"
      >
      </dd-table>
      <div class="form_wrap">
        <div
          class="group-height group-center"
          style="position: fixed; bottom: 15px; right: 35px"
        >
          <dd-button slot="button" size="medium" @click="cancel"
            >取消
          </dd-button>
          <dd-button
            slot="button"
            type="primary"
            size="medium"
            @click="handlePopupSave()"
            >确认
          </dd-button>
        </div>
      </div>
    </dd-card>
    <dd-popup
      v-if="dialogDetailVisible"
      width="90%"
      height="90%"
      title-name="详情"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
      :inPopup="true"
    >
      <AddUpdateDetailInfoPop
        slot="main"
        :type="type"
        :id="id"
        @diaChange="diaChange"
      ></AddUpdateDetailInfoPop>
    </dd-popup>
    <!-- 单图片预览 -->
    <el-image-viewer
      v-if="isspaImgePreview"
      :urlList="[spaImgePreviewUrl]"
      :on-close="closeViewer"
    ></el-image-viewer>
  </div>
</template>
  
<script>
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import AddUpdateDetailInfoPop from "../../../../../PersonalOffice/Hr/WithoutCar/components/AddUpdateDetailInfoPop"; //申请单详情
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { postApi, getApi, loadApi } from "@/api/request";
export default {
  components: { AddUpdateDetailInfoPop, ElImageViewer },
  props: ['collectDepId'],
  data() {
    return {
      dialogDetailVisible: false,
      ruleForm: {},
      id: "",
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      formList: [
        {
          label: "项目名称",
          value: "proName",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
          title: true,
          change: this.handleChange,
          clear: this.handleClear,
        },
        {
          label: "项目编号",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目编号",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
          title: true,
          change: this.handleChange,
          clear: this.handleClear,
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
          format: "yyyy-MM-dd",
        },
        {
          label: "申请人",
          value: "applierName",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索申请人",
          optionsListConfig: {
            name: "fullName",
            code: "userId",
          },
          title: true,
          change: this.handleChange,
          clear: this.handleClear,
        },
        {},
        {},
        {},
      ],
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置

      // 表格头部配置数据
      tableColumns: [
        {
          title: "申请编号",
          key: "applyCode",
          align: "center",
          width: 200,
          fixed: "left",
        },
        {
          title: "填报人",
          key: "applierName",
          align: "center",
          width: 100,
        },
        {
          title: "收款人",
          key: "payeeName",
          align: "center",
          width: 100,
        },
        {
          title: "用车事宜",
          key: "rentalcarMatter",
          align: "center",
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          width: 200,
          overflow: true,
        },
        {
          title: "项目编号",
          key: "projectCode",
          align: "center",
          width: 130,
          overflow: true,
        },
        {
          title: "用车起始日期",
          key: "rentalcarStartDate",
          align: "center",
          width: 100,
        },
        {
          title: "出发地",
          key: "departurePlace",
          align: "center",
        },
        {
          title: "目的地",
          key: "destinationPlace",
          align: "center",
        },
        {
          title: "用车终止日期",
          key: "rentalcarEndDate",
          align: "center",
          width: 100,
        },
        {
          title: "起始公里数(Km)",
          key: "startKilometer",
          align: "center",
          event: [
            {
              type: 'primary',
              href: (row) =>{
                return row.startKilometer
              },
              method: (row) => {
                this.handleImgPreview(row, "startKilometer");
              },
            },
          ],
        },
        {
          title: "结束公里数(Km)",
          key: "endKilometer",
          align: "center",
          event: [
            {
              type: 'primary',
              href: (row) =>{
                return row.endKilometer
              },
              method: (row) => {
                this.handleImgPreview(row, "endKilometer");
              },
            },
          ],
        },
        {
          title: "申请时间",
          key: "applyTime",
          align: "center",
          width: 200,
        },
        {
          title: "核定公里数(Km)",
          key: "useKilometer",
          align: "center",
          width: 100,
        },
        {
          title: "租车金额(元)",
          key: "rentalcarAmount",
          align: "center",
        },
        {
          title: "核定金额(元)",
          key: "approvedAmount",
          align: "center",
        },
        {
          title: "基础租车费用",
          key: "basicFee",
          align: "center",
        },
        {
          title: "超出公里数追加费用",
          key: "additionalFee",
          align: "center",
          width: 100,
        },
        {
          title: "租车计价说明",
          key: "pricingInstruction",
          align: "center",
        },
        {
          title: "付款证明",
          key: "payImg",
          align: "center",
          width: 115,
          event: [
            {
              type: 'primary',
              href: (row) =>{
                return row.payImg
              },
              method: (row) => {
                this.handleImgPreview(row, "payImg");
              },
            },
          ],
        },
        {
          title: "车辆行驶证",
          key: "driverLicenseImg",
          align: "center",
          width: 115,
          event: [
            {
              type: 'primary',
              href: (row) =>{
                return row.driverLicenseImg
              },
              method: (row) => {
                this.handleImgPreview(row, "driverLicenseImg");
              },
            },
          ],
        },
        {
          title: "行驶证",
          key: "driversLicenseImg",
          align: "center",
          width: 115,
          event: [
            {
              type: 'primary',
              href: (row) =>{
                return row.driversLicenseImg
              },
              method: (row) => {
                this.handleImgPreview(row, "driversLicenseImg");
              },
            },
          ],
        },
        {
          title: "操作",
          align: "center",
          width: 100,
          fixed: "right",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetailInfo(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      isspaImgePreview: false,
      spaImgePreviewUrl: "",
    };
  },
  created() {
    this.getApplyList();
  },
  mounted() {
    //获取项目信息
    this.getProject();
    this.getApplierList();
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    diaChange(e) {
      this.dialogAddEditVisible = e;
      this.dialogApplyBasicInfoVisible = e;
      this.dialogDetailVisible = e;
      this.id = null;
      this.state = null;
      this.$refs.dTableRef.$refs.dTableRef.clearSelection();
      this.getApplyList();
    },
    // 预算详情
    handleDetailInfo(row) {
      this.id = row.id;
      this.type = "detail";
      this.dialogDetailVisible = true;
    },
    // 列表请求
    getApplyList() {

      let _prjId = this.formData.projectId || "";
      let _applyTime = this.formData.applyTime || "";
      let _applierName = this.formData.applierName || "";
      let _expenseBearDepId = this.collectDepId || "";
          const params = {
            projectId: _prjId,
            applyTime: _applyTime,
            applierName: _applierName,
            expenseBearDepId: _expenseBearDepId
          }
      postApi(
        `/hr/rentalcar/applysheet/unreported`, params
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.forEach((item) => {
            item.approvedAmount = formatNumber(item.approvedAmount);
            item.rentalcarAmount = formatNumber(item.rentalcarAmount);
            item.basicFee = formatNumber(item.basicFee);
            item.additionalFee = formatNumber(item.additionalFee);
          });
          this.tableDataSources = data.data;
          for (let i = 0; i < this.tableDataSources.length; i++) {
            this.tableDataSources[i].payeeName = data.data[i].applierName;
            this.tableDataSources[i].payImg = "点击查看图片";
            this.tableDataSources[i].driverLicenseImg = "点击查看图片";
            this.tableDataSources[i].driversLicenseImg = "点击查看图片";
          }
        }
      });
    },
    //单图片预览
    handleImgPreview(row, type) {
      if (type == "startKilometer") {
        this.spaImgePreviewUrl = row.hrRentalcarApplySheetFileList[0].url;
      } else if (type == "endKilometer") {
        this.spaImgePreviewUrl = row.hrRentalcarApplySheetFileList[1].url;
      } else if (type == "payImg") {
        this.spaImgePreviewUrl = row.hrRentalcarApplySheetFileList[2].url;
      } else if (type == "driverLicenseImg") {
        this.spaImgePreviewUrl = row.hrRentalcarApplySheetFileList[3].url;
      } else if (type == "driversLicenseImg") {
        this.spaImgePreviewUrl = row.hrRentalcarApplySheetFileList[4].url;
      }
      this.isspaImgePreview = true;
    },
    closeViewer() {
      this.isspaImgePreview = false;
    },
    //监听搜索下拉
    handleChange(e, type) {
      if (type == "proName") {
        this.$refs.ddSearchForm.formData.projectId = e;
      } else if (type == "projectId") {
        this.$refs.ddSearchForm.formData.proName = e;
      }
    },
    //监听下拉清除
    handleClear() {
      if (type == "proName" || type == "projectId") {
        this.$refs.ddSearchForm.formData.proName = "";
        this.$refs.ddSearchForm.formData.projectId = "";
      }
    },
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      this.pageNumber = 1;
      this.getApplyList();
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "approvedAmount" ||
          column.property == "useKilometer" ||
          column.property == "rentalcarAmount" ||
          column.property == "basicFee" ||
          column.property == "additionalFee"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            if (
              column.property == "rentalcarAmount" ||
              column.property == "approvedAmount" ||
              column.property == "basicFee" ||
              column.property == "additionalFee"
            ) {
              sums[index] = formatNumber(sums[index]);
            }
          }
        }
      });

      let list = [...sums];
      list.map((item, index) => {
        if (index == sums.length - 1) {
          sums[index] = formatNumber(sums[index]);
        }
      });

      return sums;
    },
    feedbackMultipleSelection(list) {
      let _this = this;
      setTimeout(() => {
        let arr = list;
        if (arr.length > 0) {
          this.$nextTick(() => {
            console.log("arr", arr);
            console.log("this.tableDataSources", this.tableDataSources);
            arr.map((row) => {
              _this.$refs.dTableRef.$refs.baseTable.toggleRowSelection(
                this.tableDataSources.find((item) => {
                  return row.id == item.id;
                }),
                true
              );
            });
          });
        }
      }, 500);
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.getApplyList();
    },
    // 弹框保存回调事件
    handlePopupSave() {
      this.$emit("handleApplyList", this.multipleSelection);
    },
    //弹框取消回调事件
    cancel() {
      this.$emit("closeApplyPop", false);
    },
    //获取项目信息
    getProject() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        console.log(data);
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "proName" || item.value == "projectId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    //获取申请人列表
    getApplierList() {
      postApi(`/admin/user/getver/list`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "applierName") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
  },
};
</script>
  
<style lang='less' scoped>
.container {
  width: 100%;
  position: relative;
  .form_wrap {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 50px;
    background: #fff;
  }
}
</style>

<style>
.el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>