<template>
  <div class="tab-container">
    <div class="left-tree container-left-tree">
      <el-tree
        ref="tree"
        :data="TreeList"
        accordion
        :props="defaultProps"
        default-expand-all
        highlight-current
        :expand-on-click-node="false"
        node-key="nodeCode"
        :current-node-key="treeNodeId"
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
    <div class="right-table">
      <dd-card height="100%">
        <dd-table
          :columns="tableColumns"
          :data="tableData"
          border
          :isSelection="false"
          show-page
          :loading="loading"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="import"
              @click="handleAdd"
              >上传</dd-button
            >
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
      <!-- 填报弹窗 -->
      <dd-popup
        width="50%"
        height="80%"
        showBtn
        :saveBtn="poptitleName == '审批修改' ? false : true"
        :cancelBtn="true"
        :submitBtn="true"
        :show="addPopupVisible"
        :title-name="poptitleName"
        @popup-close="handleClose"
        @popup-save="handleDocSave('addForm', 'baseForm', 0)"
        @popup-submit="handleDocSave('addForm', 'baseForm', 1)"
      >
        <addDocFilePop
          v-if="addPopupVisible"
          :rowId="rowId"
          :treeList="TreeList"
          :resolveType="resolveType"
          :rowData="rowData"
          :poptitleName="poptitleName"
          slot="main"
          ref="addPop"
        >
        </addDocFilePop>
      </dd-popup>
      <dd-popup
        width="80%"
        height="90%"
        :showBtn="false"
        title-name="文档预览"
        :show="filePreviewVisible"
        @popup-close="filePreviewVisible = false"
      >
        <dd-fileView
          v-if="filePreviewVisible"
          slot="main"
          :fileType="fileType"
          :fileUrl="filesUrl"
        ></dd-fileView>
      </dd-popup>
    </div>
  </div>
</template>

<script>
import { getApi, postApi, loadGetApi, putApi, delApi } from "@/api/request";
import addDocFilePop from "./components/AddDocFilePop.vue";
export default {
  props: {
    resolveType: {
      type: String,
      default: "",
    },
    rowId: {
      type: String,
      default: "",
    },
  },
  components: {
    addDocFilePop,
  },
  data() {
    return {
      tableHeight: null,
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      treeNodeId: "",
      loading: true,
      tableColumns: [
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
        },
        {
          title: "文档名称",
          key: "documentName",
          align: "center",
          overflow: true,
        },
        {
          title: "文档分类",
          key: "documentTypeName",
          align: "center",
        },
        {
          title: "文档状态",
          key: "documentStateName",
          align: "center",
        },
        {
          title: "版本号",
          key: "version",
          align: "center",
        },
        {
          title: "上传者",
          key: "uploaderName",
          align: "center",
        },
        {
          title: "上传时间",
          key: "uploadTime",
          align: "center",
        },
        {
          title: "批准者",
          key: "approverName",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 400,
          event: [
            {
              label: "审批修改",
              icon: "el-icon-check",
              method: (row) => {
                this.handleRowApprove(row);
              },
              type: "primary",
            },
            {
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.documentState == "PRJ-RWJHJDWDZT-ZC") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (row.documentState == "PRJ-RWJHJDWDZT-ZC") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "在线查看",
              icon: "el-icon-view",
              method: (row) => {
                this.handlePreview(row);
              },
            },
            {
              label: "下载申请",
              icon: "el-icon-export",
              method: (row) => {
                this.handleExport(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (row.documentState == "PRJ-RWJHJDWDZT-YX") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      addPopupVisible: false,
      poptitleName: "新增",
      rowData: null,
      filesUrl: "",
      fileType: "",
      filePreviewVisible: false,
      treeAssignId: null,
    };
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.treeAssignId = this.rowId;
    this.getLeftTree();
    this.getTableData();
  },
  methods: {
    // 获取左侧树形数据
    getLeftTree() {
      getApi(`/project/subplan/getsubplanenabletree/${this.rowId}`).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0 && data.data) {
            this.TreeList = data.data;
            this.treeNodeId = data.data[0].nodeCode;
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
            });
          }
        }
      );
    },
    // 获取表格列表数据
    getTableData() {
      let { current, size } = this;
      this.loading = true;
      let params = {
        nodeCode: this.treeNodeId,
      };
      getApi(
        `/project/subplannode/attachfile/page/${this.treeAssignId}/${current}/${size}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    handleNodeClick(data) {
      this.treeNodeId = data.nodeCode;
      this.treeAssignId = data.assignId;
      this.getTableData();
    },
    handleAdd() {
      this.poptitleName = "新增";
      this.addPopupVisible = true;
    },
    handleRowEditor(row) {
      this.rowData = row;
      this.poptitleName = "编辑";
      this.addPopupVisible = true;
    },
    // 删除
    handleRowDel(row) {
      this.$confirm(`确认要删除此数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        delApi(`/project/subplannode/attachfile/${row.id}`).then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.getTableData();
          }
        });
      });
    },
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
    },
    // 附件保存
    handleDocSave(formName, formNameBase, type) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (!flag) {
        return;
      }
      if (this.$refs.addPop.fileList.length == 0) {
        this.$message.warning("请选择文件上传");
        return;
      }
      params.fileName = this.$refs.addPop.fileList[0].fileName;
      params.sourceFileName = this.$refs.addPop.fileList[0].name;
      params.assignId = this.$refs.addPop.assignId;
      if (this.rowData != null && this.poptitleName == "编辑") {
        params.id = this.rowData.id;
        postApi(`/project/subplannode/attachfile/save/${type}`, params).then(
          (res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
            }
          }
        );
      } else if (this.rowData != null && this.poptitleName == "审批修改") {
        params.id = this.rowData.id;
        putApi(`/project/subplannode/attachfile`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("审批修改成功");
            this.handleClose();
            this.getTableData();
          }
        });
      } else {
        postApi(`/project/subplannode/attachfile/save/${type}`, params).then(
          (res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
            }
          }
        );
      }
    },
    // 审批修改
    handleRowApprove(row) {
      this.rowData = row;
      this.poptitleName = "审批修改";
      this.addPopupVisible = true;
    },

    // 导出下载事件
    handleExport(row) {
      this.$confirm(`确认申请下载该附件吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        postApi(`/project/subplannode/attachfile/start/process/${row.id}`).then(
          (res) => {
            let { data } = res;
            if (data && data.code == 0) {
              this.$message.success("申请提交成功待审批");
            }
          }
        );
      });
    },
    // 附件预览
    handlePreview(row) {
      if (
        row.fileType == "docx" ||
        row.fileType == "doc" ||
        row.fileType == "xls" ||
        row.fileType == "xlsx"
      ) {
        loadGetApi(`/admin/sys-file/ssh/${row.fileName}`).then((res) => {
          if (res.data) {
            this.fileType = row.fileType;
            this.filesUrl = res.data;
            this.filePreviewVisible = true;
          }
        });
      } else {
        getApi(`/admin/sys-file/online/ssh/${row.fileName}`).then((res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let url = data.data;
            window.open(url);
          }
        });
      }
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
  },
};
</script>

<style lang="less" scoped>
.tab-container {
  height: 100%;
  display: flex;
  .left-tree {
    width: 20%;
    height: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    border-right: 5px solid #e7eaec;
    overflow-y: auto;
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: @fs14;

      .node-hanlde {
        color: @defaultFontColor;
        i {
          margin: 0 5px;
        }
      }
    }
  }
  .right-table {
    width: 80%;
    height: 100%;
    .table-tab {
      /deep/ .card-contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        .el-tabs {
          width: 100%;
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
    }
  }
}
</style>
