<template>
  <div class="main-container">
    <dd-form
      v-show="formVisible"
      :flex="1"
      :form-config="formBasicList"
      ref="addPopForm"
    >
    </dd-form>
  </div>
</template>
  
  <script>
import { getApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    dictType: [],
    userType: [],
    rowData: [],
  },
  data() {
    return {
      formBasicList: [
        {
          label: "用户类型",
          value: "type",
          inputType: "select",
          optionsList: [],
          filterable: false,
          placeholderText: "请选择用户类型",
          change: this.getUserList,
          optionsListConfig: {
            name: "label",
            code: "value",
          },
          rules: [
            {
              required: true,
              message: "请选择用户类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "姓名",
          value: "fullName",
          inputType: "select",
          optionsList: [],
          placeholderText: "请添加用户姓名",
          change: this.setFormData,
          optionsListConfig: {
            name: "fullName",
            code: "allName",
          },
          rules: [
            {
              required: true,
              message: "用户姓名不能为空",
              trigger: "blur",
            },
          ],
        },
        {
          label: "邮箱",
          value: "email",
          inputType: "text",
          placeholderText: "请输入邮箱",
          disabled: false,
          defaultValue: "",
        },
        {
          label: "电话",
          value: "phone",
          inputType: "text",
          placeholderText: "请输入手机号",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入手机号",
              trigger: "blur",
            },
          ],
        },
        {
          label: "所属单位",
          value: "orgName",
          inputType: "text",
          placeholderText: "请输入所属单位",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入所属单位",
              trigger: "blur",
            },
          ],
        },
        {
          label: "状态",
          value: "lockFlag",
          inputType: "select",
          optionsList: [],
          filterable: false,
          defaultValue: "0",
          placeholderText: "请选择状态",
          optionsListConfig: {
            name: "label",
            code: "value",
          },
          rules: [
            {
              required: true,
              message: "请选择状态",
              trigger: "blur",
            },
          ],
        },
      ],
      deptTree: [],
      postTree: [],
      userList: [],
      localUserList: [],
      selectedUser: null, // 用于保存选中的用户
      formVisible: true,
      fullNameInputType: "text",
    };
  },
  created() {
    this.localUserList = this.userList;
  },
  mounted() {
    let options1 = {
      value: "lockFlag",
      optionsList: this.dictType,
    };
    let options3 = {
      value: "type",
      optionsList: this.userType,
    };
    this.setOPtionsList(this.formBasicList, options1);
    this.setOPtionsList(this.formBasicList, options3);
    if (this.rowData != null) {
      this.formBasicList.forEach((item) => {
        item.defaultValue = this.rowData[item.value];
        console.log(item.defaultValue);
        if (item.value == "type") {
          this.getUserList(item.defaultValue, true);
        }
        // if(item.value == 'post'){
        //   let arr = [];
        //   this.rowData.postList.forEach(val => {
        //     arr.push(val.postId);
        //   })
        //   item.defaultValue = arr;
        // }
      });
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getUserList(type, value) {
      getApi("/admin/external/other-user", { type: type }).then((res) => {
        this.localUserList = res.data.data;
        debugger;
        if (this.localUserList && this.localUserList.length > 0) {
          this.fullNameInputType = "select";
          let options = {
            value: "fullName",
            optionsList: this.localUserList,
          };
          this.setOPtionsList(this.formBasicList, options);
        } else {
          this.fullNameInputType = "text";
        }

        this.formBasicList.forEach((item) => {
          if (value == "type") {
            // 清除 formBasicList 的值
            if (item.value != "lockFlag") {
              item.defaultValue = item.inputType === "select" ? null : "";
            }
            //用户类型
            if (item.value == "type") {
              item.defaultValue = type;
            }
          }
          //用户姓名类型
          if (item.value == "fullName") {
            item.inputType = this.fullNameInputType;
          }
        });
      });
    },
    setFormData(fullName) {
      // 根据选中的用户的 fullName 找到对应的用户
      this.selectedUser = this.localUserList.find(
        (user) => user.allName === fullName
      );
      // 设置其他字段的值
      this.formBasicList.find(
        (item) => item.value === "fullName"
      ).defaultValue = this.selectedUser.allName;
      this.formBasicList.find((item) => item.value === "email").defaultValue =
        this.selectedUser.email;
      this.formBasicList.find((item) => item.value === "phone").defaultValue =
        this.selectedUser.phone;
      this.formBasicList.find((item) => item.value === "orgName").defaultValue =
        this.selectedUser.orgName;
    },
  },
};
</script>
  <style lang='less' scoped>
</style>
  