<template>
  <div>
    <dd-card title="基本信息" spacer>
      <dd-form
        :form-config="formList"
        ref="addPopForm"
        labelWidth="160px"
        :flex="3"
      ></dd-form>
    </dd-card>
    <dd-card title="计划内容">
      <dd-table
        max-height="360"
        ref="expenseApplyTable"
        :columns="tableColumns"
        :data="tableDataSources"
        :topBtn="false"
        :isSelection="false"
        :loading="loading"
        border
      >
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { getNowDate } from "@/utils/date";
import { postApi, getApi } from "@/api/request";
export default {
  name: "add-pop",
  components: {},
  props: {
    assignId: {
      type: String,
      default: "",
    },
    rowData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      depList: [],
      projectList: [],
      loading: false,
      formList: [
        {
          label: "计划类型",
          value: "subplanType",
          inputType: "select",
          optionsList: [],
          filterable: false,
          disabled: true,
          placeholderText: "计划类型",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "计划开始时间",
          value: "subplanStarttime",
          placeholderText: "计划开始时间",
          inputType: "date",
          dateType: "date",
          format: "yyyy-MM-dd",
          defaultValue: getNowDate(),
          disabled: true,
        },
        {
          label: "计划结束时间",
          value: "subplanEndtime",
          placeholderText: "计划结束时间",
          inputType: "date",
          dateType: "date",
          format: "yyyy-MM-dd",
          defaultValue: getNowDate(),
          disabled: true,
        },
        {
          label: "计划执行部门",
          value: "applierDeptId",
          inputType: "select",
          optionsList: [],
          filterable: false,
          disabled: true,
          placeholderText: "计划执行部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
      ],
      tableColumns: [
        {
          title: "所属节点序号",
          key: "nodeCodeParent",
          align: "center",
        },
        {
          title: "所属节点名称",
          key: "nodeNameParent",
          align: "center",
        },
        {
          title: "节点编号",
          key: "nodeCode",
          align: "center",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
        },
        {
          title: "计量单位",
          key: "nodeUnitName",
          align: "center",
        },
        {
          title: "总工作量",
          key: "planWorkloadSum",
          align: "center",
        },
        {
          title: "总标准工天",
          key: "planStandardWorkdaySum",
          align: "center",
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
        },
        {
          title: "已完成量",
          key: "realWorkloadSum",
          align: "center",
        },
        {
          title: "已完成标准工天",
          key: "realStandardWorkdaySum",
          align: "center",
        },
        {
          title: "本期计划",
          align: "center",
          children: [
            {
              title: "计划工作量",
              key: "planWorkload",
              align: "center",
            },
            {
              title: "计划标准工天",
              key: "planStandardWorkday",
              align: "center",
            },
          ],
        },
        {
          title: "负责部门",
          key: "nodeDeptName",
          align: "center",
        },
      ],
      tableDataSources: [],
      //费用申请单显隐
      expenseApplyVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      //是否打开删除确认
      autoConfirm: false,
      //默认选择状态
      filterSelection: [],
      checked: false,
      // 所选申请单集合
      multipleSelectionProject: [],
      selectDataObj: {},
    };
  },
  mounted() {
    this.getDataType();
    if (this.rowData) {
      if (this.rowData.subplanStatus == "PRJ-JDJHZT-JS") {
        this.getResultDetail(this.rowData.id);
      } else {
        this.getDetail(this.rowData.id);
      }
    }
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取下拉选择数据
    getDataType() {
      // 计划类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-JDJHLX" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "subplanType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formList, options);
          }
        }
      );
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "applierDeptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 编辑回显详情查询
    getDetail(id) {
      getApi(`/project/subplan/getprjassignperiodplandetail/${id}`).then(
        (res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formList.forEach((item) => {
              item.defaultValue = data.data[item.value];
            });
            this.tableDataSources = data.data.prjAssignSubplanPeriodNodeVOList;
          }
        }
      );
    },
    getResultDetail(id) {
      getApi(`/project/subplan/getprjassignperiodplandetailresult/${id}`).then(
        (res) => {
          let { data } = res;
          if (data.code == 0) {
            this.formList.forEach((item) => {
              item.defaultValue = data.data[item.value];
            });
            this.tableDataSources = data.data.prjAssignSubplanPeriodNodeVOList;
          }
        }
      );
    },
    //数值合并
    getSummaries(param) {
      this.$nextTick(() => {
        let { expenseApplyTable } = this.$refs.expenseApplyTable.$refs;
        if (expenseApplyTable) {
          expenseApplyTable.doLayout();
        }
      });
      const { columns, data } = param;
      const sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (index === 5 || index === 6) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              console.log(value);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          } else {
            sums[index] = " ";
          }
        } else {
          sums[index] = " ";
          return;
        }
      });
      sums[5] = parseFloat(sums[5]).toFixed(2);
      sums[6] = parseFloat(sums[6]).toFixed(2);
      return sums;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
</style>
