/**
 * @description 加载openlayer图层
 * 
 */

import "ol/ol.css";
import TileLayer from "ol/layer/Tile"; //图层
import XYZ from "ol/source/XYZ";
import TileWMS from 'ol/source/TileWMS'
import VectorLayer from 'ol/layer/Vector.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import VectorSource from "ol/source/Vector.js";
import { bbox } from 'ol/loadingstrategy';
import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import { get as getProjection } from 'ol/proj.js';
import { getTopLeft, getWidth } from 'ol/extent.js';
import { Style, Fill } from "ol/style";
import WMTSCapabilities from 'ol/format/WMTSCapabilities.js';

export {
  // tdtimgLayer,
  // tdtciaLayer,
  // vectorLayer,
  // addWMSLayer,
  // tdtviewImgLayer,
  // tdtviewCiaLayer,
  // addWMTSLayer,
  tdtLayer
}

var key = "0d21d6e9998e6fb62f95746508cbc32a";
var tdtLayers = [
  // {
  //   name: "tdtvecLayer",
  //   title: "tdtvecLayer",
  //   type: "vec",
  //   layer: "",
  // },
  // {
  //   name: "tdtcvaLayer",
  //   title: "tdtcvaLayer",
  //   type: "cva",
  //   layer: "",
  // },
  {
    name: "tdtimgLayer",
    title: "tdtimgLayer",
    type: "img",
    layer: "",
  },
  {
    name: "tdtciaLayer",
    title: "tdtciaLayer",
    type: "cia",
    layer: "",
  },
]
/**
 * 加载天地图---坐标系4326
 */

// // 天地图影像图层source
// var source = new XYZ({
//   url: "https://t{0-7}.tianditu.gov.cn/DataServer?T=img_w&tk=" + key + "&x={x}&y={y}&l={z}",
// });
// // 天地图影像标注图层source
// var sourceMark = new XYZ({
//   url: "https://t{0-7}.tianditu.gov.cn/DataServer?T=cia_w&tk=" + key + "&x={x}&y={y}&l={z}",
// });
// let tdtimgLayer = new TileLayer({
//   title: "影像图层",
//   source: source,
// });
// let tdtciaLayer = new TileLayer({
//   title: "标注图层",
//   source: sourceMark,
// });

// let tdtviewImgLayer = new TileLayer({
//   title: "鹰眼影像图层",
//   source: source,
// });
// let tdtviewCiaLayer = new TileLayer({
//   title: "鹰眼标注图层",
//   source: sourceMark,
// });

// function tdtSource(type) {
//   const source = new XYZ({
//     url: "https://t{0-7}.tianditu.gov.cn/DataServer?T=" + type + "_w&tk=" + key + "&x={x}&y={y}&l={z}",
//   })
//   return source
// }
function tdtTileLayer(layerName, title, type) {
  const layer = new TileLayer({
    zIndex: 99,
    title: title,
    source: new XYZ({
      url: "https://t{0-7}.tianditu.gov.cn/DataServer?T=" + type + "_w&tk=" + key + "&x={x}&y={y}&l={z}",
    })
  })
  layerName = layer;
  let flag = false;
  //默认显示影像
  let layerArray = ["tdtvecLayer", "tdtcvaLayer"]
  //默认显示矢量
  // let layerArray = ["tdtimgLayer", "tdtciaLayer"]
  layerArray.find((item) => {
    if (item == title) {
      flag = true
    }
  })
  if (flag) {
    layerName.setVisible(false)
  }
  return layerName
}
function tdtLayer() {
  const layerList = [];
  tdtLayers.forEach(layer => {
    layer.layer = tdtTileLayer(layer.name, layer.title, layer.type);
    layerList.push(layer.layer)
  });
  return layerList
}

function vectorLayer(map, serverURL, serverWorkSpace, serverName) {
  const vectorLayer = new VectorLayer({
    zIndex: 999,
    source: new VectorSource({
      format: new GeoJSON(),
      projection: "EPSG:4326",
      url: function () {
        return serverURL + "/" + serverWorkSpace + "/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=" + serverWorkSpace + "%3A" + serverName + "&outputFormat=application%2Fjson&srsname=EPSG:4326";
      },
      strategy: bbox
    }),
    style: new Style({
      fill: new Fill({ color: "rgba(0,0,255,0.1)" }),
    }),
  });
  map.addLayer(vectorLayer);
  return vectorLayer
}

function addWMSLayer(map, serverURL, serverWorkSpace, serverName) {
  const layer = new TileLayer({
    source: new TileWMS({
      url: serverURL + "/" + serverWorkSpace + '/wms',
      params: {
        LAYERS: serverWorkSpace + ":" + serverName,
        VERSION: '1.0.0'
      }
    })
  })
  map.addLayer(layer);
  return layer
}

function addWMTSLayer(map, serverURL, serverWorkSpace, serverName) {
  return new Promise((resolve) => {
    const projection = getProjection('EPSG:3857');
    const projectionExtent = projection.getExtent();
    const size = getWidth(projectionExtent);
    const resolutions = [];
    const matrixIds = [];
    for (let z = 0; z < 20; z++) {
      resolutions[z] = size / (256 * Math.pow(2, z));
      matrixIds[z] = 'EPSG:3857_' + serverName + ':' + z;
    }
    console.log(resolutions);
    console.log(matrixIds);

    const wmtsSource = new WMTS({
      url: serverURL + '/gwc/service/wmts',
      layer: serverName,
      matrixSet: 'EPSG:3857_' + serverName,
      format: 'image/png',
      projection: projection,
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds,

      })
    })
    const xmURL = wmtsSource.getUrls()[0] + "?service=WMTS&version=1.0.0&request=GetCapabilities&layers=" + serverName
    let extent = [];
    const parser = new WMTSCapabilities();
    fetch(xmURL).then((response) => {
      return response.text();
    }).then((text) => {
      const result = parser.read(text);
      const Layers = result.Contents.Layer;
      for (let i = 0; i < Layers.length; i++) {
        if (Layers[i].Identifier === serverName) {
          extent = Layers[i].WGS84BoundingBox;
          break
        }
      }
      var WMTSLayer = new TileLayer({
        opacity: 0.7,
        source: wmtsSource,
        extent: extent,
        // style: '',
        wrapX: true,
      });
      map.addLayer(WMTSLayer);
      resolve(true)
    })
  })
}










