import { render, staticRenderFns } from "./OuterCarDetail.vue?vue&type=template&id=c923aac0&scoped=true"
import script from "./OuterCarDetail.vue?vue&type=script&lang=js"
export * from "./OuterCarDetail.vue?vue&type=script&lang=js"
import style0 from "./OuterCarDetail.vue?vue&type=style&index=0&id=c923aac0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c923aac0",
  null
  
)

export default component.exports