<template>
  <div>
      <dd-card title="">
        <dd-form
            :form-config="formDetailList"
            ref="ensureRef"
            :formRef="ensureFormRef"
        ></dd-form>
      </dd-card>
  </div>
</template>

<script>
import { postApi, getApi } from "@/api/request";
import $ from "jquery";

export default {
  props: {
    kindeeCode : {
      type: String,
      default: null,
    },
    ensureRemark:{
      type: String,
      default: null,
    }
  },
  data() {
    return {
      ensureFormRef: 'ensureRef',
      formDetailList: [
        {
          label: "备注",
          value: "remark",
          inputType: "text",
          type: "textarea",
          autosize: { minRows: 7 },
          placeholderText: "请输入内容",
          title: true,
          defaultValue:'',
          rules: [
            {
              required: true,
              message: "请输入确认备注",
              trigger: "blur",
            },
          ],
        },
      ],
    }
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData(){
      this.formDetailList.forEach(item=>{
        if (item.value=='remark'){
            item.defaultValue= this.ensureRemark
        }
      })

    }
  },
};
</script>

<style lang="less" scoped>
  /deep/ .el-form {
    height: 350px;
  }

  /deep/ .el-textarea__inner {
    width: 800px;
  }
</style>